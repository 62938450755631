import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/redux/actions'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

export const isFetching = (status: boolean): AnyAction => {
  return {
    type: 'USER_IS_FETCHING',
    payload: status,
  }
}

export const fetchSuccess = (data: any): AnyAction => {
  return {
    type: 'USER_FETCH_SUCCESS',
    payload: data,
  }
}

export const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'USER_FETCH_ERROR',
  }
}

export const fetchData = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(isFetching(true))
    try {
      const response = await API().request('/manage/users')
      dispatch(isFetching(false))
      dispatch(fetchSuccess(response))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchError(new Error(error)))
    }
  }
}

export const exportData = () => (dispatch: Dispatch) => {
  return API().request('/users/export', {
    method: 'GET',
  }, undefined, undefined, ['content-disposition']).then((result: any) => {
    return result
  })
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const isDetailsFetching = (status: boolean): AnyAction => {
  return {
    type: 'USERDETAILS_IS_FETCHING',
    payload: status,
  }
}

export const fetchDetailsSuccess = (data: any): AnyAction => {
  return {
    type: 'USERDETAILS_FETCH_SUCCESS',
    payload: data,
  }
}

export const fetchDetailsError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'USERDETAILS_FETCH_ERROR',
  }
}

export const fetchSingleData = (id: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(isDetailsFetching(true))
    const url = '/manage/users/' + id
    const response = await API().request(url)

    try {
      if (response) {
        dispatch(isDetailsFetching(false))
        dispatch(fetchDetailsSuccess(response))
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchDetailsError(new Error(error)))
    }
  }
}

export const isSchemaFetching = (status: boolean): AnyAction => {
  return {
    type: 'USERSCHEMA_IS_FETCHING',
    payload: status,
  }
}

export const fetchSchemaSuccess = (data: any): AnyAction => {
  return {
    type: 'USERSCHEMA_FETCH_SUCCESS',
    payload: data,
  }
}

export const fetchSchemaError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'USERSCHEMA_FETCH_ERROR',
  }
}

export const fetchSchema = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(isSchemaFetching(true))
    const addSchema = API().request('/manage/users/add/json-schema-form')
    const addSchemaUi = API().request('/manage/users/add/ui-schema-form')
    const editSchema = API().request('/manage/users/edit/json-schema-form')
    const editSchemaUi = API().request('/manage/users/edit/ui-schema-form')
    const requests = await Promise.all([addSchema, addSchemaUi, editSchema, editSchemaUi])

    try {
      if (requests) {
        const data = {
          add: {
            schema: requests[0],
            ui: requests[1],
          },
          edit: {
            schema: requests[2],
            ui: requests[3],
          },
        }

        dispatch(isSchemaFetching(false))
        dispatch(fetchSchemaSuccess(data))
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchSchemaError(new Error(error)))
    }
  }
}
