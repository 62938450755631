import * as Noty from 'noty'
import React from 'react';
import { popupNotification as showNotificationCore } from '../../inplant-core-fe/functions/notifications'
import { showNotification } from '../../mvlabs-components-fe/ui/components/Notification/Notification';

export const popupNotification = (options: Noty.Options & { content?: string; title?: string }) => {
  const { content, title } = options
  showNotificationCore({ type: getShowNotificationType(options), text: content, title })
}

export const popupNotificationWithAction = (option: Noty.Options & { content?: string; title?: string, actions?: React.ReactNode }) => {
  const { content, title, actions } = option
  showNotification({
    type: getShowNotificationType(option), 
    message: content, 
    title,
    actions
  })
}

function getShowNotificationType(options: Noty.Options): 'success' | 'danger' | 'info' | 'warning' {
  switch (options.type) {
    case 'alert':
    case 'warning':
      return 'warning'
    case 'error':
      return 'danger'
    case 'info':
    case 'information':
      return 'info'
    case 'success':
      return 'success'
    default:
      return 'info'
  }
}