import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ModelNodeData, SectionNodeTypes } from '../../../../types/chronograph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import {
  defaultPlantAnalysisGeneralSettings,
  PlantAnalysisGeneralSettings,
  PlantAnalysisGeneralsSettings,
} from '../../../../types/settings'
import { flatData } from '../../../../functions/model'
import { GeneralData } from '../../../../types/measure'
import { connect } from 'react-redux'
import { Configurations } from '../../../../redux/reducers/configurations'
import { configurationSaveData } from '../../../../redux/actions/configuration'
import { Modal } from '@mv-submodules/inplant-components-fe'

export interface OwnProps {
  isVisible: boolean
  closeModal: () => void
  saveModal: (configuration: PlantAnalysisGeneralSettings) => void
  i18nLabels: { [k: string]: string }
}

export interface OwnState {
  configuration: PlantAnalysisGeneralSettings
  settingsActiveSections: string[]
  sections: string[]
}

interface StateProps {
  plant: any | null
  model: null | GeneralData
  configurations: null | Configurations
}

const mapStateToProps = (state: any): StateProps => ({
  plant: state.plantSelector || null,
  model: state.plantAnalysis.model,
  configurations: state.plantAnalysis.configurations,
})

interface DispatchProps {
  saveConfiguration: (settings: PlantAnalysisGeneralsSettings) => Promise<PlantAnalysisGeneralsSettings>
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    saveConfiguration: settings => dispatch(configurationSaveData(settings)),
  }
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation

class ModalSettings extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      configuration: this.getCurrentConfiguration(),
      settingsActiveSections: ['main'],
      sections: ['aspiratori', 'nastri', 'bunker'],
    }

    this.toggleSettingsSection = this.toggleSettingsSection.bind(this)
    this.toggleAllSectionNodes = this.toggleAllSectionNodes.bind(this)
    this.handleNodeChange = this.handleNodeChange.bind(this)
    this.handleSettingsChange = this.handleSettingsChange.bind(this)
    this.handleSaveModal = this.handleSaveModal.bind(this)
    this.getCurrentConfiguration = this.getCurrentConfiguration.bind(this)
  }

  public render() {
    const { model } = this.props
    const nodes =
      model && !model.fetching && !model.error && model.data
        ? (flatData(model.data.model.content) as ModelNodeData[]).sort((a, b) => (a.code > b.code ? 1 : -1))
        : ({} as ModelNodeData[])

    const nodesJsx = {}

    for (const s in this.state.sections) {
      if (this.state.sections.hasOwnProperty(s)) {
        const section = this.state.sections[s]
        nodesJsx[section] = []

        for (const i in nodes) {
          if (nodes.hasOwnProperty(i)) {
            const node = nodes[i]
            if (SectionNodeTypes[section] && SectionNodeTypes[section].includes(node.nodeType)) {
              nodesJsx[section].push(
                <li className="col-sm-6" key={node.code}>
                  <input
                    type="checkbox"
                    name={node.code}
                    onChange={this.handleNodeChange}
                    value={node.code}
                    checked={this.state.configuration.nodes && this.state.configuration.nodes[node.code] === true}
                  />{' '}
                  {node.label}
                </li>,
              )
            }
          }
        }
      }
    }
    return (
      <Modal
        visible={this.props.isVisible}
        title={this.props.t(this.props.i18nLabels.title)}
        onClose={this.props.closeModal}
        width={50}
        closeLabel={this.props.t(this.props.i18nLabels.close)}
        additionalFooterButtons={[{
          label: this.props.t(this.props.i18nLabels.save),
          type: 'button',
          variant: 'primary',
          onClick: () => this.handleSaveModal()
        }]}
      >
        <>
          <div
            className={
              'graph-header mb-2 ' + (this.state.settingsActiveSections.indexOf('main') === -1 ? 'is-collapsed' : '')
            }
          >
            <h3 onClick={() => this.toggleSettingsSection('main')}>
              {this.state.settingsActiveSections.indexOf('main') === -1 ? (
                <FontAwesomeIcon icon={faCaretUp}/>
              ) : (
                <FontAwesomeIcon icon={faCaretDown}/>
              )}{' '}
              {this.props.t('plantAnalysis.settings.main.title')}
            </h3>
          </div>
          <div
            className={
              'col-12 row-settings-main ' +
              (this.state.settingsActiveSections.indexOf('main') === -1 ? 'collapse' : 'collapse show')
            }
          >
            {
              Object.keys(this.state.configuration.settingsFields)
              .filter(k => !['soglia_under_speed', 'soglia_over_speed'].includes(k))
              .map(( field: string ) => (
                <div className="form-group row" key={field}>
                  <label htmlFor="staticEmail" className="col-sm-6 col-form-label">
                    {this.props.t('plantAnalysis.settings.fields.' + field)}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control"
                      id={field}
                      name={field}
                      value={this.state.configuration.settingsFields[field]}
                      onChange={this.handleSettingsChange}
                    />
                  </div>
                </div>
              ))
            }
          </div>

          <div
            className={
              'graph-header mb-2 ' + (this.state.settingsActiveSections.indexOf('main') === -1 ? 'is-collapsed' : '')
            }
          >
            <h3 onClick={() => this.toggleSettingsSection('nodes')}>
              {this.state.settingsActiveSections.indexOf('nodes') === -1 ? (
                <FontAwesomeIcon icon={faCaretUp}/>
              ) : (
                <FontAwesomeIcon icon={faCaretDown}/>
              )}{' '}
              {this.props.t('plantAnalysis.settings.nodes.title')}
            </h3>
          </div>
          <div
            className={
              'col-12 row-settings-nodes ' +
              (this.state.settingsActiveSections.indexOf('nodes') === -1 ? 'collapse' : 'collapse show')
            }
          >
            {this.state.sections.map(section => (
              <div className="col-12 mb-3 pt-3" key={section}>
                <h4>
                  {section}
                  <button
                    onClick={() => this.toggleAllSectionNodes(section)}
                    className="btn btn-sm btn-secondary float-right"
                  >
                    {this.props.t('plantAnalysis.settings.nodes.all')}
                  </button>
                </h4>
                <div key={section} id={'nodes-section-' + section}>
                  <ul className="row">{nodesJsx[section]}</ul>
                </div>
              </div>
            ))}
          </div>
        </>
      </Modal>
    )
  }

  private getCurrentConfiguration() {
    const { configurations, plant } = this.props
    return (
      (configurations && configurations.data && configurations.data && plant && configurations.data[plant.plant]) ||
      defaultPlantAnalysisGeneralSettings
    )
  }

  private toggleSettingsSection(section: string) {
    const settingsSections = JSON.parse(JSON.stringify(this.state.settingsActiveSections))
    const index = settingsSections.indexOf(section)

    if (index === -1) {
      settingsSections.push(section)
    } else {
      settingsSections.splice(index, 1)
    }

    this.setState({
      settingsActiveSections: settingsSections,
    })
  }

  private toggleAllSectionNodes(section: string) {
    const { model } = this.props
    const configuration = JSON.parse(JSON.stringify(this.state.configuration))
    const nodes =
      model && !model.fetching && !model.error && model.data
        ? (flatData(model.data.model.content) as ModelNodeData[]).sort((a, b) => (a.code > b.code ? 1 : -1))
        : ({} as ModelNodeData[])

    for (const i in nodes) {
      if (nodes.hasOwnProperty(i)) {
        const node = nodes[i]
        if (SectionNodeTypes[section] && SectionNodeTypes[section].includes(node.nodeType)) {
          configuration.nodes[node.code] = configuration.nodes[node.code] ? !configuration.nodes[node.code] : true
        }
      }
    }

    this.setState({ configuration })
  }

  private handleNodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const configuration = JSON.parse(JSON.stringify(this.state.configuration))

    configuration.nodes[event.currentTarget.name] =
      event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : !!event.currentTarget.value
    this.setState({ configuration })
  }

  private handleSettingsChange(event: React.ChangeEvent<HTMLInputElement>) {
    const configuration = JSON.parse(JSON.stringify(this.state.configuration))
    configuration.settingsFields[event.currentTarget.name] = event.currentTarget.value

    this.setState({ configuration })
  }

  private handleSaveModal() {
    const configurations =
      this.props.configurations && this.props.configurations.data
        ? JSON.parse(JSON.stringify(this.props.configurations.data))
        : {}
    configurations[this.props.plant.plant] = this.state.configuration
    this.props.saveConfiguration(configurations).then(() => this.props.closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalSettings))
