import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AssetFile } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Button, HtmlTooltip, Tooltip } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { stringBreaker } from '@mv-submodules/inplant-asset-manager-fe/functions/stringBreaker'


interface StateProps {
  file: AssetFile
  handleDownloadFile: (assetFile: AssetFile) => void
  isFileDownloading: boolean
}

type Props = StateProps & WithTranslation

const truncateText = (value: string, textSizeTruncate: number) => {
  return `${value.slice(0, textSizeTruncate).trimRight()}...`
}

class FileReadOnly extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const { file, t, handleDownloadFile, isFileDownloading } = this.props
    const fileName = stringBreaker(file.name)

    return (
      <Row horizontalAlignment={'between'} verticalAlignment={'center'} spacing={{vertical:false,horizontal:false}}>
        <Column lg={6} md={6} sm={6}>
          {file.name.length > 8 ? <HtmlTooltip
              target={<Button
                variant="light"
                icon={"download"}
                label={fileName}
                isLoading={isFileDownloading}
                onClick={() => handleDownloadFile(file)}
              />} position="top" content={fileName}/>
            :
            <Button
              variant="light"
              icon={"download"}
              label={fileName}
              isLoading={isFileDownloading}
              onClick={() => handleDownloadFile(file)}
            />}

        </Column>
        <Column lg={2} md={2} sm={2}>
          <p className="mb-0">
            {file.description.length > 50 ?
              <Tooltip position="top" target={truncateText(file.description, 50)} content={file.description}/>
              : file.description
            }
          </p>

        </Column>
        <Column  lg={2} md={2} sm={2}>
          <p className="mb-0">{t(`assetManager.forms.${file.type}`, { defaultValue: file.type })}</p>
        </Column>
        <Column lg={2} md={2} sm={2}>
          <p className="mb-0">
            {file.isInHeadquarter ? t('assetManager.forms.inHeadquarter') : t('assetManager.forms.notInHeadquarter')}
          </p>
        </Column>
      </Row>
    )
  }
}

export default withTranslation()(FileReadOnly)
