import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { applyParams, fetchSetupData } from '../../../../redux/actions/titechSetup'
import { DispatchFetchListProps, ListParams } from '../../../../types/store'
import { TitechSetupConfiguration } from '../../../../types/titech'
import { PlantConfig } from '../../../../types/plant'
import {
  Badge,
  Filters,
  PageHeader,
  Table,
  TableRowButtonGroup,
  TableRowInfo,
} from '@mv-submodules/inplant-components-fe'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import TitechSetupModal from '@mv-submodules/inplant-titech-fe-iblu/ui/components/views/TitechSetup/TitechSetupModal'

type OwnProps = RouteComponentProps<any> & WithTranslation

interface OwnState extends ListParams {
  data: TitechSetupConfiguration[],
  isFetching: boolean,
  fetchErrors: boolean,
  page: number
  modalTitechId: null | string
}

const mapStateToProps = (store: any): ListParams & PlantConfig => {
  return {
    plant: store.plantSelector,
    isMultiPlant:
      store.config &&
      store.config.plantSelector &&
      store.config.plantSelector.isMultiPlant,
    ...store.titech.titechList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFetchListProps<TitechSetupConfiguration> => ({
  fetchData: (params, plant) => fetchSetupData(params, plant)(dispatch),
  applyParams: params => dispatch(applyParams(params)),
})

type Props = OwnProps & ListParams & DispatchFetchListProps<TitechSetupConfiguration> & PlantConfig

class TitechSetupPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      isFetching: false,
      fetchErrors: false,
      page: props.page || 1,
      filtered: [],
      pageSize: props.pageSize || 10,
      search: props.search,
      modalTitechId: null
    }

    this.applyTableParams = this.applyTableParams.bind(this)
    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handleTitechConfigureClick = this.handleTitechConfigureClick.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  public componentDidMount() {
    this.fetchData((this.props.isMultiPlant && this.props.plant && this.props.plant.plant) || undefined)
  }

  public componentWillReceiveProps(props: Props) {
    if (
      this.props.isMultiPlant &&
      this.props.plant &&
      props.plant &&
      props.plant.plant !== this.props.plant.plant
    ) {
      this.fetchData(props.plant.plant)
    }
  }

  private fetchData(plant: string | undefined) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props.fetchData(
        undefined,
        (this.props.isMultiPlant && this.props.plant && this.props.plant.plant) || undefined,
      ).then((data) => {
        this.setState({ data })
      })
        .catch((error) => {
          this.setState({ fetchErrors: true })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  public render() {
    const { t, pageSize } = this.props
    const { data, page, isFetching, modalTitechId } = this.state
    const titechs = this.filterData(data)
    const filters: FilterComponent[] = [{
      id: 'titech-setup-search',
      value: this.state.search || '',
      type: 'SearchInput',
      disabled: this.state.isFetching,
      label: t('titech.setup.searchPlaceholder'),
      onChange: (name, value) => this.handleSearchChange(value && value as string || undefined),
    }]

    const badgeType = (type: string) => {
      switch (type) {
        case 'deactivated':
          return 'negative'

        case 'active':
          return 'positive'

        case 'to-be-configured':
          return 'warning'

        case 'to-be-validated':
          return 'warning'

        default:
          return 'neutral'
      }
    }


    return (
      <div className="mv4iot-fe-titech mv4iot-fe-titech__setup">
        <PageHeader title={t('titech.setup.title')}/>
        <TitechSetupModal onClose={this.handleModalClose} titechId={modalTitechId} />

        <div className="content">
          <Filters fields={filters}/>

          <Table
            data={!isFetching ? titechs : undefined}
            columns={[
              {
                Header: t('titech.setup.status'),
                accessor: 'status',
                Cell: ({original}: TableRowInfo) => <Badge
                  type={badgeType(original.status)}
                  value={t('titech.setup.statusLabels.' + original.status.replaceAll('-',''), {defaultValue: original.status})}
                  />
              },
              {
                Header: t('titech.setup.assetCode'),
                accessor: 'assetCode',
              },
              {
                Header: t('titech.setup.positionCode'),
                accessor: 'positionCode',
              },
              {
                Header: t('titech.setup.model'),
                accessor: 'model',
              },
              {
                Header: this.props.t('titech.setup.actions'),
                sortable: false,
                style: {overflow: 'visible'},
                minWidth: 150,
                Cell: (row: TableRowInfo) => {
                  return (
                    <TableRowButtonGroup
                      buttons={[
                        {
                          variant: 'primary',
                          label: this.props.t('titech.setup.configure').toUpperCase(),
                          onClick: () => this.handleTitechConfigureClick(row.original.id)
                        }
                      ]}
                    />
                  )
                },
              },
            ]}
            showPagination={true}
            showPaginationBottom={true}
            noDataText={t('titech.setup.noData')}
            isFetching={this.state.isFetching}
            onPageChange={this.handleTablePageChange}
            onPageSizeChange={this.handleTablePageSizeChange}
            //  onSortedChange={this.handleTableSortedChange}
            page={page - 1}
            manualIsFetching={true}
            pageSize={pageSize}
            defaultSorted={[
              {
                id: 'assetCode',
                desc: false,
              },
            ]}
          />
        </div>
      </div>
    )
  }

  private applyTableParams(params: ListParams) {
    this.props.applyParams(params)
  }

  private filterData(
    data: TitechSetupConfiguration[]): TitechSetupConfiguration[] {
    const { search } = this.state
    if (!search || !data) {
      return data
    }
    const q = search.toLowerCase()
    return data.filter((titech: TitechSetupConfiguration) =>
      ['assetCode', 'model'].reduce(
        (check: boolean, key: string) =>
          check || !!(titech[key] && titech[key].toLowerCase().match(q)),
        false,
      ),
    )
  }

  private handleTablePageChange(page: number) {
    this.setState({ page: page + 1 }, () => this.applyTableParams({
      sorted: this.props.sorted,
      search: this.props.search,
      page: page + 1,
      pageSize: this.props.pageSize,
      filtered: this.props.filtered,
    }))
  }

  private handleTablePageSizeChange(pageSize: number) {
    this.applyTableParams({ page: 1, pageSize })
  }

  private handleSearchChange(search: string | undefined) {
    this.setState({
      search,
    }, () => {
      this.applyTableParams({
        sorted: this.props.sorted,
        search,
        page: this.props.page,
        pageSize: this.props.pageSize,
        filtered: this.props.filtered,
      })
    })
  }

  private handleTitechConfigureClick(modalTitechId: string) {
    this.setState({
      modalTitechId
    })
  }

  private handleModalClose() {
    this.setState({
      modalTitechId: null
    }, () => {
      this.fetchData((this.props.isMultiPlant && this.props.plant && this.props.plant.plant) || undefined)
    })
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(TitechSetupPageView)),
)
