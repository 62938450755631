import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { Moment } from 'moment'

import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import { Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '../../../../functions/series'
import BeltGraphMain from './BeltGraphMain'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  data: { [key: string]: Measure }
  last: any
  lastAfter: any
  isCollapsed: boolean
  brush1Start: number
  brush1End: number
  brush1StartInd: number
  brush1EndInd: number
  mergedData: undefined | any[]
  filteredData: any[]
  startDate: string | number
  endDate: string | number
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  component: any
  inverter: boolean
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  dateFilterStart: Moment
  workshift: Workshift
  plant: string | null
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  plant: state.plantSelector.plant,
})

type Props = StateProps & OwnProps & WithTranslation

const cleanState = {
  isLoading: false,
  fetchErrors: false,
  isCollapsed: false,
  data: {
    aspirato: {
      data: [],
      min: 0,
      max: 0,
    },
    ricetta: {
      data: [],
      min: 0,
      max: 0,
    },
    assorbimento: {
      data: [],
      min: 0,
      max: 0,
    },
    avantiIndietro: {
      data: [],
      min: 0,
      max: 0,
    },
    inverterDiretta: {
      data: [],
      min: 0,
      max: 0,
    },
    manuale: {
      data: [],
      min: 0,
      max: 0,
    },
    setVelocita: {
      data: [],
      min: 0,
      max: 0,
    },
    localeSezionato: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  last: null,
  lastAfter: null,
  brush1Start: 0,
  brush1End: 0,
  brush1StartInd: 0,
  brush1EndInd: 0,
  mergedData: [],
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
}

class BeltGraph extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = cleanState

    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce(this.handleBrush1.bind(this),10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(cleanState)
      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const { workshift } = this.props
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto belt-graph-fetch-errors">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
          {isReady && this.props.inverter && (
            <BeltGraphMain
              filteredData={this.state.filteredData}
              data={this.state.mergedData}
              pauses={(workshift && workshift.pauses) || []}
            />
          )}
        </div>

        {isReady && (
          <GraphLineBarTime
            topMargin={true}
            entry={'manAutoLocSec'}
            colorsId={'manAutoLocSec'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
            paddingRight={70}
          />
        )}

        {isReady && (
          <GraphLineBarTime
            topMargin={true}
            entry={'avantiIndietro'}
            colorsId={'avind'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.avantiIndietro'}
            paddingRight={70}
          />
        )}

        {isReady && <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && <BottomLegend labels={['manAutoLocSec', 'avind', 'localeSezionato']} />}

        {isReady && this.props.inverter && (
          <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1} paddingRight={70}>
            <Line dataKey="assorbimento" type="step" stroke="#A19FF9" dot={false} isAnimationActive={false} />
          </GraphBrush>
        )}

        {isReady && this.props.inverter && (
          <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} paddingRight={60} />
        )}
      </React.Fragment>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false, multiplier?: number) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : multiplier ? datum[1] * multiplier : datum[1],
          h100: 100,
        }
      })
    }

    return [{}]
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const measureLabel = props.component.id

      /*
      const reg = new RegExp(/\[(.*)\] (.*)/);
      const measureDetails = props.component.data.label.match(reg);

      const measureLabel = measureDetails[2] + ' ' + measureDetails[1];
      */

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`
      /*const queryLastAfterStart = ` SELECT first("measure") AS "last_measure" FROM "`
      const queryLastAfterEnd = `" WHERE time > '${endDate}' LIMIT 1`*/

      const queryAvantiIndietro = queryStart + measureLabel + '_BS_DIR' + queryEnd
      const queryInverterDiretta = queryStart + measureLabel + '_BS_BYP_SEL' + queryEnd
      const queryManuale = queryStart + measureLabel + '_BS_MAN' + queryEnd
      const queryAutomatico = queryStart + measureLabel + '_BS_AUTO' + queryEnd
      const queryLocaleSezionato = queryStart + measureLabel + '_BS_LOC' + queryEnd
      const queryLastAvantiIndietro = queryLastStart + measureLabel + '_BS_DIR' + queryLastEnd
      const queryLastInverterDiretta = queryLastStart + measureLabel + '_BS_BYP_SEL' + queryLastEnd
      const queryLastManuale = queryLastStart + measureLabel + '_BS_MAN' + queryLastEnd
      const queryLastAutomatico = queryLastStart + measureLabel + '_BS_AUTO' + queryLastEnd
      const queryLastLocaleSezionato = queryLastStart + measureLabel + '_BS_LOC' + queryLastEnd
      /*const queryLastAfterAvantiIndietro = queryLastAfterStart + measureLabel + '_BS_DIR' + queryLastAfterEnd
      const queryLastAfterInverterDiretta = queryLastAfterStart + measureLabel + '_BS_BYP_SEL' + queryLastAfterEnd
      const queryLastAfterManuale = queryLastAfterStart + measureLabel + '_BS_MAN' + queryLastAfterEnd
      const queryLastAfterAutomatico = queryLastAfterStart + measureLabel + '_BS_AUTO' + queryLastAfterEnd
      const queryLastAfterLocaleSezionato = queryLastAfterStart + measureLabel + '_BS_LOC' + queryLastAfterEnd*/

      const dataAvantiIndietroSrc = await API().request(`/query?${plantQuery}q=` + queryAvantiIndietro)
      const dataInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryInverterDiretta)
      const dataManualeSrc = await API().request(`/query?${plantQuery}q=` + queryManuale)
      const dataAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryAutomatico)
      const dataLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLocaleSezionato)
      const dataLastAvantiIndietroSrc = await API().request(`/query?${plantQuery}q=` + queryLastAvantiIndietro)
      const dataLastInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastInverterDiretta)
      const dataLastManualeSrc = await API().request(`/query?${plantQuery}q=` + queryLastManuale)
      const dataLastAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAutomatico)
      const dataLastLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLastLocaleSezionato)
      /*const dataLastAfterAvantiIndietroSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterAvantiIndietro)
      const dataLastAfterInverterDirettaSrc = await API().request(
        `/query?${plantQuery}q=` + queryLastAfterInverterDiretta
      )
      const dataLastAfterManualeSrc = await API.request(`/query?${plantQuery}q=` + queryLastAfterManuale)
      const dataLastAfterAutomaticoSrc = await API.request(`/query?${plantQuery}q=` + queryLastAfterAutomatico)
      const dataLastAfterLocaleSezionatoSrc = await API.request(
        `/query?${plantQuery}q=` + queryLastAfterLocaleSezionato
      )*/

      let dataAssorbimentoSrc: string = '[]'
      let dataSetVelocitaSrc: string = '[]'
      let dataLastAssorbimentoSrc: string = '[]'
      let dataLastSetVelocitaSrc: string = '[]'
      /*let dataLastAfterAssorbimentoSrc: string = '[]'
      let dataLastAfterSetVelocitaSrc: string = '[]'*/

      const queryAssorbimento = queryStart + measureLabel + '_WS_ACT_CURR' + queryEnd
      const querySetVelocita = queryStart + measureLabel + '_WS_SET_SPD' + queryEnd
      const queryLastAssorbimento = queryLastStart + measureLabel + '_WS_ACT_CURR' + queryLastEnd
      const queryLastSetVelocita = queryLastStart + measureLabel + '_WS_SET_SPD' + queryLastEnd
      /*const queryLastAfterAssorbimento = queryLastAfterStart + measureLabel + '_WS_ACT_CURR' + queryLastAfterEnd
      const queryLastAfterSetVelocita = queryLastAfterStart + measureLabel + '_WS_SET_SPD' + queryLastAfterEnd*/

      dataAssorbimentoSrc = await API().request(`/query?${plantQuery}&q=` + queryAssorbimento)
      dataSetVelocitaSrc = await API().request(`/query?${plantQuery}&q=` + querySetVelocita)
      dataLastAssorbimentoSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAssorbimento)
      dataLastSetVelocitaSrc = await API().request(`/query?${plantQuery}&q=` + queryLastSetVelocita)
      /*dataLastAfterAssorbimentoSrc = await API.request(`/query?${plantQuery}&q=` + queryLastAfterAssorbimento)
      dataLastAfterSetVelocitaSrc = await API.request(`/query?${plantQuery}&q=` + queryLastAfterSetVelocita)*/

      Promise.all([
        dataAssorbimentoSrc,
        dataAvantiIndietroSrc,
        dataInverterDirettaSrc,
        dataManualeSrc,
        dataAutomaticoSrc,
        dataLocaleSezionatoSrc,
        dataSetVelocitaSrc,
        dataLastAssorbimentoSrc,
        dataLastAvantiIndietroSrc,
        dataLastInverterDirettaSrc,
        dataLastManualeSrc,
        dataLastAutomaticoSrc,
        dataLastSetVelocitaSrc,
        dataLastLocaleSezionatoSrc,
        /*dataLastAfterAssorbimentoSrc,
        dataLastAfterAvantiIndietroSrc,
        dataLastAfterInverterDirettaSrc,
        dataLastAfterManualeSrc,
        dataLastAfterAutomaticoSrc,
        dataLastAfterSetVelocitaSrc,
        dataLastAfterLocaleSezionatoSrc,*/
      ]).then(() => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            assorbimento: { data: parseResponseData(dataAssorbimentoSrc) },
            avantiIndietro: { data: parseResponseData(dataAvantiIndietroSrc) },
            inverterDiretta: { data: parseResponseData(dataInverterDirettaSrc) },
            manuale: { data: parseResponseData(dataManualeSrc) },
            automatico: { data: parseResponseData(dataAutomaticoSrc) },
            setVelocita: { data: parseResponseData(dataSetVelocitaSrc) },
            localeSezionato: { data: parseResponseData(dataLocaleSezionatoSrc) },
          }),
          last: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(dataLastAssorbimentoSrc),
            avantiIndietro: parseResponseSingleData(dataLastAvantiIndietroSrc),
            inverterDiretta: parseResponseSingleData(dataLastInverterDirettaSrc),
            manuale: parseResponseSingleData(dataLastManualeSrc),
            automatico: parseResponseSingleData(dataLastAutomaticoSrc),
            setVelocita: parseResponseSingleData(dataLastSetVelocitaSrc),
            localeSezionato: parseResponseSingleData(dataLastLocaleSezionatoSrc),
          }),
          /*lastAfter: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(dataLastAfterAssorbimentoSrc),
            avantiIndietro: parseResponseSingleData(dataLastAfterAvantiIndietroSrc),
            inverterDiretta: parseResponseSingleData(dataLastAfterInverterDirettaSrc),
            manuale: parseResponseSingleData(dataLastAfterManualeSrc),
            automatico: parseResponseSingleData(dataLastAfterAutomaticoSrc),
            setVelocita: parseResponseSingleData(dataLastAfterSetVelocitaSrc),
            localeSezionato: parseResponseSingleData(dataLastAfterLocaleSezionatoSrc),
          }),*/
          startDate: startDateObj.unix(),
          endDate: endDateObj.unix(),
          isLoading: false,
          fetchErrors: false,
        })

        this.prepareData()
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private prepareData() {
    try {
      let aspirato: any = []
      let ricetta: any = []
      let assorbimento: any = []
      let avantiIndietro: any = []
      let inverterDiretta: any = []
      let manuale: any = []
      let automatico: any = []
      let setVelocita: any = []
      const mergedData: any[] = []
      let localeSezionato: any[] = []

      if (this.state.data) {
        aspirato = this.populateSingleData('aspirato')
        ricetta = this.populateSingleData('ricetta')
        assorbimento = this.populateSingleData('assorbimento', false, 0.1)
        avantiIndietro = this.populateSingleData('avantiIndietro', true)
        inverterDiretta = this.populateSingleData('inverterDiretta', true)
        manuale = this.populateSingleData('manuale', true)
        automatico = this.populateSingleData('automatico', true)
        setVelocita = this.populateSingleData('setVelocita')
        localeSezionato = this.populateSingleData('localeSezionato')

        hydrateData(
          {
            aspirato,
            ricetta,
            assorbimento,
            avantiIndietro,
            inverterDiretta,
            manuale,
            automatico,
            setVelocita,
            localeSezionato,
          },
          mergedData,
          'assorbimento',
          0
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) { return -1 }
          if (a.time > b.time) { return 1 }
          return 0
        })

        hydrateTimeData(
          [
            'aspirato',
            'ricetta',
            'assorbimento',
            'avantiIndietro',
            'inverterDiretta',
            'manuale',
            'automatico',
            'setVelocita',
            'localeSezionato',
            'warnArea',
          ],
          mergedData,
          this.state
        )

        populateManAutoLocSec(mergedData)
        fillWarnArea(mergedData, 'assorbimento', 0)
        interpolateTime(mergedData, moment(this.props.workshift.start).unix(), moment(this.props.workshift.end).unix())

        this.setState({
          brush1StartInd: 0,
          brush1EndInd: mergedData.length,
          mergedData,
          filteredData: mergedData,
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex + 1) : [],
    })
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BeltGraph)))
