import Status from '@mv-submodules/inplant-plantstatus-fe/ui/components/views/Status/StatusPageView'
import { Icon } from '../../inplant-components-fe/services/icon'

export interface RouteProps {
  path: string
  exact?: boolean
  i18nkey?: string
  icon?: Icon
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
  counter?: object;
}

const routes: RouteProps = {
  path: '/status',
  i18nkey: 'status.title',
  icon: 'link',
  visible: true,
  component: Status,
}

export default routes
