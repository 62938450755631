// * -------------------------------- NPM --------------------------------------
import * as React from "react";

interface Props {
  data: Array<{
    label: string,
    value: string
  }>,
  title?: string
}

class TabulatedText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {data, title} = this.props
    return (
      <div className="tabulated-text">
        {title && <h3>{title}</h3>}
        <table className="table table-borderless w-auto">
          <tbody>
          {data.map((item, index) => (
            <tr key={`tabulated_list_${index}`}>
              <td>
                {item.label}
              </td>
              <td>
                {item.value}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default TabulatedText