// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE -----------------------------------
import IconComponent from '../MVIcon/Icon'

const Loader = () => (
  <div className="mt-5 text-center">
    <IconComponent icon={"circle-notch"} spin={true} size="2x" className="text-muted" />
  </div>
)

export default Loader