import {  Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { DueDatesOptions } from '../../types/chronoTasks'
import { ChronoTask } from '../../types/chronoTasks'
import { API } from './index'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

// import * as data from './_mockPageDetail.json' // TEMPORARY!!!


export const fetchDetail = (id: string, options: DueDatesOptions) => (dispatch: Dispatch) => {
  return API().request(`/chrono-frame/chrono-tasks/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Content-Language': 'it-IT',
    },
  })
    .then((result: ChronoTask) => result)
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}