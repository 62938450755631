// * -------------------------- NPM --------------------------
import React from 'react'

import Text from '../Text/Text'

export interface SimpleTableColumn<T> {
  cell: (data: T) => React.ReactNode;
  titlePosition?: 'start' | 'center' | 'end'
  title: string;
  width?: string
  className?: (data: T) => string | undefined
}

interface Props<T> {
  data: T[]
  columns: Array<SimpleTableColumn<T>>
}

const SimpleTable = <T, >(props: Props<T>) => {
  const {data, columns} = props

  const base = 'mv-simple-table'

  return (
    <table className={base}>
      <thead>
      <tr className={`${base}__row-header`}>
        {columns.map((c, index) => (
          <th key={index} style={{width: c.width}}>
            <Text alignment={c.titlePosition} text={c.title} />
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {data.map((d, index) => {
        return (
          <tr key={index} className={`${base}__row `}>
            {columns.map((c, idx) => (
              <td className={c.className && c.className(d)} key={idx}>{c.cell(d)}</td>
            ))}
          </tr>
        )
      })}
      </tbody>
    </table>
  )
}

export default SimpleTable
