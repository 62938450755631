import * as React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

export interface PieDataItem {
  name: string
  value: number
}

interface PieDataColor {
  id: string
  color: string
}

interface Props {
  data: PieDataItem[]
  colors: PieDataColor[]
  removeWidth?: boolean
}

class PieSmallView extends React.PureComponent<Props> {
  public render() {
    if (this.props.data.length < 1 || this.props.colors.length < 1) {
      return null
    }

    const RADIAN = Math.PI / 180
    const renderCustomizedLabelPie = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
    }: {
      cx?: any
      cy?: any
      midAngle?: any
      innerRadius?: any
      outerRadius?: any
      percent?: any
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5
      const x = cx + radius * Math.cos(-midAngle * RADIAN)
      const y = cy + radius * Math.sin(-midAngle * RADIAN)

      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )
    }

    return (
      <ResponsiveContainer  width={this.props.removeWidth ? undefined : '100%'} height={150}>
        <PieChart width={this.props.removeWidth ? undefined : 150} height={150}>
          <Pie
            data={this.props.data}
            labelLine={false}
            label={item => renderCustomizedLabelPie(item)}
            outerRadius={70}
            fill="#8884d8"
            dataKey="value"
            cx={75}
            cy={75}
            isAnimationActive={false}
          >
            {this.props.data.map((entry, index) => {
              const color = this.props.colors.find(e => e.id === entry.name)
              return <Cell key={`cell-${index}`} fill={color && color.color} />
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default PieSmallView
