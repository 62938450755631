// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import { mvDate } from '../../../mvfunctions/helpers/dateHelper'

interface Props {
  dateFormat?: string
  dateValueFormat?: string
  id?: string
  label?: string
  onApply?: ReactBootstrapDaterangepicker.EventHandler
  onSelectedChanged?: (selected: string[]) => void
  opens?: 'left' | 'right' | 'center'
  selected: string[]
  timePicker24Hour?: boolean
  timePicker?: boolean
}

class DateRangeInput extends React.Component<Props> {
  private dateFormat: string = this.props.dateFormat || 'dd/MM/yyyy'

  constructor(props: Props) {
    super(props)
    this.onDateRangePickerEvent = this.onDateRangePickerEvent.bind(this)
    this.formatSelected = this.formatSelected.bind(this)
    this.handleOnSelectedChanged = this.handleOnSelectedChanged.bind(this)
    this.getInitialStartDate = this.getInitialStartDate.bind(this)
    this.getInitialEndDate = this.getInitialEndDate.bind(this)
  }

  public onDateRangePickerEvent(event: any, datePicker: any) {
    if (event.type === 'apply') {
      this.handleOnSelectedChanged([
        datePicker.startDate.format(this.props.dateValueFormat || 'YYYY-MM-DD'),
        datePicker.endDate.format(this.props.dateValueFormat || 'YYYY-MM-DD'),
      ])
    }

    if (event.type === 'cancel') {
      datePicker.startDate = mvDate.now()
      datePicker.endDate = mvDate.now()
      this.handleOnSelectedChanged([])
    }
  }

  public render() {
    const { props } = this

    return (
      <DateRangePicker
        endDate={this.getInitialEndDate()}
        locale={{ format: this.dateFormat }}
        onApply={props.onApply}
        onEvent={this.onDateRangePickerEvent}
        opens={props.opens || 'right'}
        startDate={this.getInitialStartDate()}
        timePicker24Hour={props.timePicker24Hour}
        timePicker={props.timePicker}
      >
        {props.label && <label htmlFor={props.id}>{props.label}</label>}
        <div className="input-group">
          <div className="input-group-prepend">
            <IconComponent icon={'calendar'} />
          </div>
          <input
            type="text"
            readOnly={true}
            className="form-control daterangepicker-input"
            value={this.formatSelected(props.selected)}
          />
        </div>
      </DateRangePicker>
    )
  }

  private getInitialStartDate() {
    if (this.props.selected.length === 2) {
      return mvDate.getDateFromString(this.props.selected[0])
    }
  }

  private getInitialEndDate() {
    if (this.props.selected.length === 2) {
      return mvDate.getDateFromString(this.props.selected[1])
    }
  }

  private handleOnSelectedChanged(selected: string[]) {
    const { onSelectedChanged } = this.props
    if (onSelectedChanged) {
      onSelectedChanged(selected)
    }
  }

  private formatSelected(selected: string[]) {
    if (selected.length === 2) {
      const start = mvDate.getDateFromStringWithFormatting(selected[0], this.dateFormat)
      const end = mvDate.getDateFromStringWithFormatting(selected[1], this.dateFormat)
      return `${start} - ${end}`
    } else {
      return '-- --'
    }
  }
}

export default DateRangeInput
