import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import { ComposedChart, ReferenceArea, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import moment from 'moment'
import { AreaSection } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/measure'
import { COLORS } from '@mv-submodules/inplant-plantanalysis-fe-iblu/constants'
import { HHHmmssFromMilliseconds } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { CssTooltip } from '@mv-submodules/inplant-components-fe'

interface OwnProps {
  data: any[]
  dateStart: string
  dateEnd: string
}

type Props = OwnProps & WithTranslation

const PlantHaltsTimeline = ( props: Props ) => {
  const [localData, setLocalData] = useState(props.data)
  const [haltsDuration, setHaltsDuration] = useState({})
  const [activeSerie, setActiveSerie] = useState('')

  useEffect(() => {
    setLocalData(props.data)

    let currState: undefined | number
    let currTime = 0

    const durations = props.data.reduce(( a, c, i ) => {
      const stateEnd = (new Date(c[0])).getTime()
      let diff = 0

      if (i === 0) {
        currState = c[1]
        currTime = stateEnd
      } else if (c[1] !== currState) {
        diff = stateEnd - currTime

        if (a.hasOwnProperty(currState)) {
          // @ts-ignore
          a[currState] += diff
        } else {
          // @ts-ignore
          a[currState] = diff
        }

        currState = c[1]
        currTime = stateEnd
      }


      if (i === props.data.length - 1) {
        const endDay = new Date(c[0])
        endDay.setHours(23)
        endDay.setMinutes(59)
        endDay.setSeconds(59)
        endDay.setMilliseconds(9999)
        diff = endDay.getTime() - stateEnd
        if (a.hasOwnProperty(c[1])) {
          a[c[1]] += diff
        } else {
          a[c[1]] = diff
        }
      }


      return a
    }, {})

    setHaltsDuration(durations)
  }, [props.data])

  const { t } = useTranslation()


  const reduceWarnAreas = ( warnPoints: AreaSection[], colorMap: { [k: number]: string }, color?: string, border?: string ) => {
    if (warnPoints && warnPoints.length > 0) {
      return warnPoints.map(( e: any, i: number ) => {
        const isActive = activeSerie === '' || activeSerie === e.value.toString()
        return (
          <ReferenceArea
            ifOverflow={'visible'}
            key={(e.x1 + i)}
            x1={e.x1}
            x2={e.x2}
            stroke={border || color || colorMap[e.value]}
            fill={color || colorMap[e.value]}
            strokeWidth={1}
            strokeLinejoin={'miter'}
            fillOpacity={isActive ? 1 : 0.2}
            strokeOpacity={isActive ? 1 : 0.2}
          />
        )
      })
    }

    return null
  }

  const getAreaSections = ( dataSrc: any, key: string ) => {
    const out: AreaSection[] = []

    const dataArray = Object.entries(dataSrc)
    dataArray.forEach(( e: any, i: number ) => {
      const currValue = dataArray[i][1] as any
      const running = i < dataArray.length - 1
      const nextValue = dataArray[i + (running ? 1 : 0)][1] as any

      out.push({
        x1: currValue.time,
        x2: running ? nextValue.time : parseInt(moment.unix(currValue.time).set({
          h: 23,
          m: 59,
          s: 59,
        }).format('X'), 10),
        y1: 0,
        y2: 100,
        value: currValue[key],
      })
    })

    return out
  }

  const colors = COLORS.plantHaltsTimes
  const dataPoints = getAreaSections(localData.map(( d: Array<string | number> ) => ({
    time: parseInt(moment(d[0]).format('X'), 10),
    value: d[1],
  })), 'value')

  const dataAreas = reduceWarnAreas(dataPoints, colors)

  const formatTick = ( tick: number ) => moment.unix(tick).format('HH:mm')
  const timeChartMin = localData && localData.length > 0 ? parseInt(moment(localData[0][0]).format('X'), 10) : 'dataMin'
  // const timeChartMin = props.dateStart ? parseInt(moment(props.dateStart).format('X'), 10) : 'dataMin'
  const timeChartMax = localData && localData.length > 0 ? parseInt(moment(localData[localData.length - 1][0]).set({
    h: 23,
    m: 59,
    s: 59,
  }).format('X'), 10) : 'dataMax'
  // const timeChartMax = props.dateEnd ? parseInt(moment(props.dateEnd).set({ h: 23, m: 59, s: 59 }).format('X'), 10) : 'dataMax'

  const legend = localData ?
    localData.reduce(( a: string[], c: [string, number] ) => {
      return a.includes(c[1].toString()) ? a : [...a, ...[c[1].toString()]]
    }, []) :
    []

  return (
    <div className='col-12 chart-planthalts-timeline--chart mb-5'>
      <h5>TEMPI IMPIANTO</h5>
      {
        (!dataPoints || dataPoints.length === 0) &&
        <div className='alert alert-warning w-100 mb-5'>{t('plantAnalysis.noDataAvailable')}</div>
      }
      {
        dataPoints && (dataPoints.length > 0) &&
        <>
          <ResponsiveContainer width={'100%'} height={80}>
            <ComposedChart width={700} height={50} data={dataPoints} barGap={0} barCategoryGap={0}>
              <YAxis label={''} domain={[0, 1]} tick={false} axisLine={false} tickLine={false} width={10} />
              <XAxis
                dataKey='time'
                hide={false}
                domain={[
                  timeChartMin,
                  timeChartMax,
                ]}
                type={'number'}
                interval={'preserveStartEnd'}
                tickFormatter={formatTick}
                tickCount={25}
              />
              {dataAreas}
            </ComposedChart>
          </ResponsiveContainer>

          <div className='row row-legend row-legend-bottom pl-1'>
            <div className='col-12'>
              <ul>
                {legend.map(( name: string, index: number ) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveSerie(name)}
                    onMouseLeave={() => setActiveSerie('')}
                  >
                    <CssTooltip
                      content={
                        t('plantAnalysis.labels.duration') + ' ' +
                        HHHmmssFromMilliseconds(haltsDuration[name], false, true)
                      }
                    >
                    <span
                      className='label-color label-color-square'
                      style={{ backgroundColor: COLORS.plantHaltsTimes[name] }}
                    />
                      <span>
                      {t('plantAnalysis.legend.plantHaltsTimes.' + name)}
                    </span>
                    </CssTooltip>
                  </li>

                ))}
              </ul>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default withTranslation()(PlantHaltsTimeline)
