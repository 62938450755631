import * as React from 'react'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import { Tooltip } from 'react-tippy'
import * as moment from 'moment'
import { DispatchFetchDetailProps } from '../../../../types/store'
import {
  Titech,
  TitechLog,
  TitechRecipeChange,
  // UNUSED TitechSecurityLabels,
} from '../../../../types/titech'
import SimpleTable from '../../widgets/SimpleTable/SimpleTable'
import { RowInfo } from 'react-table'
import { PlantConfig } from '../../../../types/plant'
import Timeout = NodeJS.Timeout
import { fetchDetail, fetchLogs } from '@mv-submodules/inplant-titech-fe-iblu/redux/actions/titech'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { Button, Modal, PageHeader } from '@mv-submodules/inplant-components-fe'

type OwnProps = RouteComponentProps<any> & WithTranslation

const mapStateToProps = (
  store: any,
): PlantConfig => {
  return {
    plant: store.plantSelector,
    isMultiPlant:
      store.config &&
      store.config.plantSelector &&
      store.config.plantSelector.isMultiPlant,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFetchDetailProps => ({
  fetchDetail: (id, plant) => fetchDetail(id, plant)(dispatch),
  fetchLogs: (id, from, to) => fetchLogs(id, from, to)(dispatch),
})

type Props = OwnProps & DispatchFetchDetailProps & PlantConfig

interface OwnState {
  isLogFetching: boolean
  logFetchingErrors: boolean
  logs: TitechLog[] | null
  logsDate: moment.Moment | null
  logsDateDisplay: string
  dateInputDebounce: Timeout | null
  showRecipes: boolean
  showCalibrations: boolean
  data?: Titech
  isFetchingTitech: boolean
  fetchingTitechError: FetchError | undefined
}

const TitechDetailSectionTitle = ({ children }: any) => (
  <h4 className="section-title text-uppercase">{children}</h4>
)

const TitechDetailValue = ({ label, value, className }: any) => (
  <div>
    <span className="text-secondary mr-2">{label}:</span>
    <span className={className || ''}>{value}</span>
  </div>
)

interface TitechModalProps {
  title: string
  onClose: (event?: React.MouseEvent<HTMLButtonElement>) => void
  children: any
  closeModal: string
}

class TitechModal extends React.Component<TitechModalProps, any> {
  constructor(props: TitechModalProps) {
    super(props)
    this.handleModalClick = this.handleModalClick.bind(this)
  }

  public render() {
    const { title, onClose, children, closeModal } = this.props

    return (
      <Modal
        visible={true}
        onClose={() => onClose()}
        width={50}
        title={title}
        closeLabel={closeModal}
        >
        {children}
      </Modal>
    )
  }

  private handleModalClick(e: React.MouseEvent<any>) {
    if (e.currentTarget === e.target) {
      this.props.onClose()
    }
  }
}

const TitechVariants = ({ changes, t }: any) => (
  <ul className="list-group list-group-flush">
    {changes.map((variant: TitechRecipeChange) => (
      <li className="list-group-item" key={variant.name}>
        <strong className="mr-2">{variant.name}</strong>
        {variant.from == null
          ? t('titech.detail.changes.added', { variant: variant.to! })
          : variant.to == null
            ? t('titech.detail.changes.removed', { variant: variant.from! })
            : t('titech.detail.changes.changed', {
              from: variant.from!,
              to: variant.to!,
            })}
      </li>
    ))}
  </ul>
)

class TitechDetailPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: undefined,
      fetchingTitechError: undefined,
      isLogFetching: false,
      logs: null,
      logsDate: null,
      logsDateDisplay: '',
      dateInputDebounce: null,
      showRecipes: false,
      showCalibrations: false,
      isFetchingTitech: false,
      logFetchingErrors: false,
    }

    this.handleRecipesHistoryToggleButton = this.handleRecipesHistoryToggleButton.bind(
      this,
    )
    this.handleCalibrationsHistoryToggleButton = this.handleCalibrationsHistoryToggleButton.bind(
      this,
    )
    this.fetchLogs = this.fetchLogs.bind(this)
    this.handleLast24hLogsButton = this.handleLast24hLogsButton.bind(this)
    this.handleDatepickerOnChange = this.handleDatepickerOnChange.bind(this)
    this.handleDatepickerOnInput = this.handleDatepickerOnInput.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public async componentDidMount() {
    this.fetchData(
      this.props.match.params.id,
      (this.props.isMultiPlant && this.props.plant && this.props.plant.plant) ||
      undefined,
    )
    if (!this.props.isMultiPlant) {
      this.fetchLogs(moment().subtract(1, 'day'))
    }
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any,
  ) {
    if (this.props.isMultiPlant) {
      if (
        nextProps.plant &&
        this.props.plant &&
        nextProps.plant.plant !== this.props.plant.plant
      ) {
        this.props.history.push('/titech')
      }
    }
  }

  private fetchData(id: string, plant?: string) {
    if (!this.state.isFetchingTitech) {
      this.setState({ isFetchingTitech: true, fetchingTitechError: undefined })
      this.props.fetchDetail(id, plant)
        .then(data => {
          this.setState({ data })
        })
        .catch(error => {
          console.log(error) //tslint:disable-line
          this.setState({ fetchingTitechError: error })
        })
        .finally(() => {
          this.setState({ isFetchingTitech: false })
        })
    }
  }

  public render() {
    const { t } = this.props
    const isMultiPlant = JSON.parse(`${this.props.isMultiPlant}`)
    const { data, isFetchingTitech } = this.state
    return !data || isFetchingTitech ? (
      <div className="mv4iot-fe-titech h-50 d-flex justify-content-center align-items-center">
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin={true}
          size="2x"
          className="text-muted"
        />
      </div>
    ) : (
      <div className="mv4iot-fe-titech">
        <PageHeader
          backButton={true}
          backUrl={this.getBackUrl()}
          backButtonOnClick={() => window.history.back()}
          title={t('titech.detail.title', {
            title: data ? `${data.code} ${data.modelName}` : '',
          })}
          subtitle={
            data.lastCheckDate && (
              <TitechDetailValue
                label={t('titech.detail.lastCheck')}
                value={
                  data.lastCheckDate
                    ? data.lastCheckDate.format('DD/MM/YYYY HH:mm:ss')
                    : '-'
                }
                className={data.hasLastCheckWarning() && 'text-danger'}
              />
            )
          }
        />

        {data.recipeChanges && this.state.showRecipes ? (
          <TitechModal
            title={t('titech.detail.recipesHistory')}
            onClose={this.handleRecipesHistoryToggleButton}
            closeModal={t('titech.detail.close')}
          >
            <SimpleTable
              isFetching={false}
              data={data.recipeChanges}
              rows={25}
              noDataText={t('titech.list.noData')}
              ofText={t('titech.list.page.of')}
              rowsText={t('titech.list.page.rows')}
              pageText={t('titech.list.page.page')}
              columns={[
                {
                  Cell: ({ original }: RowInfo) =>
                    original.changedAtDate.format('DD/MM/YYYY HH:mm:ss'),
                  Header: t('titech.detail.log.date'),
                  accessor: 'date',
                },
                {
                  Cell: ({ original }: RowInfo) => (
                    <>
                      <span className={original.reboot ? 'text-warning' : ''}>
                        {original.name}
                      </span>
                      {!original.changes || !original.changes.length ? null : (
                        <div className="float-right">
                          <Tooltip
                            trigger="click"
                            position="left"
                            arrow={true}
                            html={
                              <TitechVariants
                                changes={
                                  original.changes as TitechRecipeChange[]
                                }
                                t={t}
                              />
                            }
                          >
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm rounded-circle"
                            >
                              <FontAwesomeIcon icon={faInfo} size="xs"/>
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  ),
                  Header: t('titech.detail.recipe'),
                  accessor: 'name',
                },
              ]}
            />
          </TitechModal>
        ) : null}

        {data.calibrations && this.state.showCalibrations ? (
          <TitechModal
            title={t('titech.detail.calibrationsHistory')}
            onClose={this.handleCalibrationsHistoryToggleButton}
            closeModal={t('titech.detail.close')}
          >
            <SimpleTable
              isFetching={false}
              data={data.calibrations}
              rows={25}
              noDataText={t('titech.list.noData')}
              ofText={t('titech.list.page.of')}
              rowsText={t('titech.list.page.rows')}
              pageText={t('titech.list.page.page')}
              columns={[
                {
                  Cell: ({ original }: RowInfo) =>
                    original.dueAtDate.format('DD/MM/YYYY HH:mm:ss'),
                  Header: t('titech.detail.log.date'),
                },
              ]}
            />
          </TitechModal>
        ) : null}

        <div className="content">
          {data.recipeChanges && (
            <section>
              <TitechDetailSectionTitle>
                {t('titech.detail.recipes')}
              </TitechDetailSectionTitle>
              {data.lastRecipe &&
              data.lastRecipe.date && (
                <TitechDetailValue
                  label={t('titech.detail.lastRecipe')}
                  value={
                    data.lastRecipe.date.format('DD/MM/YYYY HH:mm:ss') +
                    ' – ' +
                    data.lastRecipe.name
                  }
                />
              )}
              {!(isMultiPlant) && (
                <Button
                  variant='secondary-alternate'
                  size='sm'
                  label={t('titech.detail.recipesHistory')}
                  spacing={{horizontal:false}}
                  onClick={this.handleRecipesHistoryToggleButton}
                />
              )}
            </section>
          )}

          {data.calibrations && (
            <section className="mt-3">
              <TitechDetailSectionTitle>
                {t('titech.detail.calibrations')}
              </TitechDetailSectionTitle>
              {data.lastCalibrationDate && (
                <TitechDetailValue
                  label={t('titech.detail.lastCalibration')}
                  value={data.lastCalibrationDate.format('DD/MM/YYYY HH:mm:ss')}
                  className={data.hasLastCalibrationWarning() && 'text-danger'}
                />
              )}
              {data.nextCalibrationDate && (
                <TitechDetailValue
                  label={t('titech.detail.nextCalibration')}
                  value={data.nextCalibrationDate.format('DD/MM/YYYY HH:mm:ss')}
                  className={data.hasNextCalibrationWarning() && 'text-danger'}
                />
              )}
              {!isMultiPlant && (
                <Button
                  variant='secondary-alternate'
                  size='sm'
                  label={t('titech.detail.calibrationsHistory')}
                  spacing={{horizontal:false}}
                  onClick={this.handleCalibrationsHistoryToggleButton}
                />
              )}
            </section>
          )}

          {!isMultiPlant && (
            <section className="mt-3">
              <div className="d-flex justify-content-between">
                <TitechDetailSectionTitle>
                  {t('titech.detail.checkLogs')}{' '}
                  {this.state.logsDate
                    ? this.state.logsDate.format('DD/MM/YYYY')
                    : t('titech.detail.last24h')}
                </TitechDetailSectionTitle>
                <div className="d-flex">
                  <Button
                    variant={!this.state.logsDate
                      ? 'secondary'
                      : 'secondary-alternate'}
                    onClick={this.handleLast24hLogsButton}
                    spacing={{vertical:false,horizontal:false}}
                    label={t('titech.detail.last24h').toLowerCase()}
                  />
                  <div className="btn-group datepicker-filter">
                    <DatePicker
                      className="form-control"
                      disabledKeyboardNavigation={true}
                      maxDate={moment().toDate()}
                      onChange={this.handleDatepickerOnChange}
                      onChangeRaw={this.handleDatepickerOnInput}
                      popperPlacement="top-end"
                      locale="it"
                      selected={this.state.logsDate ? this.state.logsDate.toDate() : null}
                      value={this.state.logsDateDisplay}
                      placeholderText="––/––/––"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendar}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SimpleTable
                isFetching={this.state.isLogFetching}
                data={this.state.logs || []}
                noDataText={t('titech.list.noData')}
                ofText={t('titech.list.page.of')}
                rowsText={t('titech.list.page.rows')}
                pageText={t('titech.list.page.page')}
                columns={[
                  {
                    Cell: ({ original }: RowInfo) => {
                      if (!original.severity) {
                        return '-'
                      } else {
                        const color =
                          original.severity === 'Highest'
                            ? 'danger'
                            : original.severity === 'High'
                            ? 'warning'
                            : 'info'
                        return (
                          <div className={`badge badge-${color}`}>
                            {t(
                              `titech.detail.log.severityLevel.${
                                original.severity
                              }`,
                            )}
                          </div>
                        )
                      }
                    },
                    Header: t('titech.detail.log.severity'),
                    accessor: 'severity',
                    width: 150,
                  },
                  {
                    Cell: ({ original }: RowInfo) =>
                      original.logDate.format('DD/MM/YYYY HH:mm:ss'),
                    Header: t('titech.detail.log.date'),
                    accessor: 'date',
                  },
                  {
                    Cell: ({ original }: RowInfo) => (
                      <div className={"d-flex justify-content-between align-items-center"}>
                        {original.message || '-'}
                          <Tooltip
                            trigger="mouseenter click"
                            position="top"
                            arrow={true}
                            title={original.raw}
                          >
                            <Button
                              spacing={{vertical:false}}
                              type="button"
                              variant={"secondary"}
                              icon={"info"}
                              iconSize={"sm"}
                              size={"sm"}
                            />
                          </Tooltip>
                      </div>
                    ),
                    Header: t('titech.detail.log.errors'),
                    accessor: 'message',
                  },
                ]}
              />
            </section>
          )}
        </div>
      </div>
    )
  }

  private getBackUrl() {
    return window.location.pathname.replace(
      `/${this.props.match.params.id}`,
      '',
    )
  }

  private handleRecipesHistoryToggleButton(
    event?: React.MouseEvent<HTMLButtonElement>,
  ) {
    if (event) {
      event.preventDefault()
    }
    this.setState(currentState => ({ showRecipes: !currentState.showRecipes }))
  }

  private handleCalibrationsHistoryToggleButton(
    event?: React.MouseEvent<HTMLButtonElement>,
  ) {
    if (event) {
      event.preventDefault()
    }
    this.setState(currentState => ({
      showCalibrations: !currentState.showCalibrations,
    }))
  }

  private async fetchLogs(date: moment.Moment, logsDate?: moment.Moment) {
    if (!this.state.isLogFetching) {
      if (this.state.dateInputDebounce) {
        clearTimeout(this.state.dateInputDebounce)
      }
      this.setState({
        isLogFetching: true,
        logFetchingErrors: false,
        logs: null,
        logsDate: logsDate || null,
        logsDateDisplay: logsDate ? logsDate.format('DD/MM/YYYY') : '',
        dateInputDebounce: null,
      })
      this.props.fetchLogs(
        this.props.match.params.id,
        date.toJSON(),
        moment(date)
          .add(1, 'days')
          .toJSON(),
      ).then(logs => {
        this.setState({ logs })
      })
        .catch(error => {
          console.log(error) //tslint:disable-line
          this.setState({ logFetchingErrors: true })
        })
        .finally(() => {
          this.setState({ isLogFetching: false })
        })
    }
  }

  private handleLast24hLogsButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.fetchLogs(moment())
  }

  private handleDatepickerOnChange(date: Date | null) {
    if (date) {
      const dateMoment = moment(date).set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)

      this.fetchLogs(dateMoment, dateMoment)
    }
  }

  private handleDatepickerOnInput(event: React.FocusEvent<HTMLInputElement>) {
    const { value } = event.currentTarget

    if (this.state.dateInputDebounce) {
      clearTimeout(this.state.dateInputDebounce)
    }
    const dateInputDebounce = setTimeout(() => {
      const match = value.match(
        /^([0-3]?[0-9])[\/|-]([0-1]?[0-9])[\/|-]([0-9]{4})$/,
      )
      if (match) {
        const date = moment()
          .set('year', parseInt(match[3], 10))
          .set('month', parseInt(match[2], 10) - 1)
          .set('date', parseInt(match[1], 10))
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)
        this.fetchLogs(date, date)
      }
    }, 300)
    this.setState({ logsDateDisplay: value, dateInputDebounce })
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(TitechDetailPageView)),
)
