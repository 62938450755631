// * -------------------------------- MODULE --------------------------------------
import NodeLogic, { NodeMultiSelectUp, NodeSingleNode, NodeSingleSelectDown } from './Node'
import { LightNode, NodeData, ProcessedNodeData } from '../types'
import { getAllChildren, getNodeFromString, isNodeInTree } from '../function'

type Id = string

abstract class SelectionLogic {
  get listSelected(): LightNode[] {
    return Object.values(this._listSelected)
  }

  protected root: NodeData
  protected _listSelected: Record<Id, LightNode>
  protected abstract node: NodeLogic

  get nodeLogic() {
    return this.node
  }

  constructor(root: NodeData, initialSelected: Array<LightNode | string> = []) {
    this.root = root
    this._listSelected = initialSelected.reduce((acc: Record<Id, LightNode>, curr) => {
      if (typeof curr === 'string') {
        const node = getNodeFromString(root, curr)
        if (node) {
          return { ...acc, [node.id]: node }
        }
      } else {
        const node = isNodeInTree(root, curr)
        if (node) {
          return { ...acc, [curr.id]: curr }
        }
      }
      return acc
    }, {})
  }

  protected getAllParentSelectable = (node: ProcessedNodeData): Record<Id, LightNode> => {
    let nodeToIterate = node
    let result: Record<Id, LightNode> = {}
    while (nodeToIterate.parent) {
      const { parent } = nodeToIterate
      if (!parent.disabled) {
        result = { ...result, [parent.id]: { id: parent.id, label: parent.label } }
      }
      nodeToIterate = parent
    }
    return result
  }

  protected firstParentSelected = (node: ProcessedNodeData): LightNode | undefined => {
    let nodeToIterate = node
    while (nodeToIterate.parent) {
      const { parent } = nodeToIterate
      if (this._listSelected[parent.id]) {
        return parent
      }
      nodeToIterate = parent
    }
    return undefined
  }

  public minimumSetOfNode = (node: NodeData): LightNode[] => {
    return this.minimumSetOfNodeImpl(this._listSelected, node)
  }
  protected abstract minimumSetOfNodeImpl: (listSelected: Record<Id, LightNode>, node: NodeData) => LightNode[]

  public onSelectionNode = (node: ProcessedNodeData, state: boolean): LightNode[] => {
    this._listSelected = this.onSelectionNodeLogic({ ...this._listSelected }, node, state)
    return Object.values(this._listSelected)
  }
  protected abstract onSelectionNodeLogic: (
    listSelected: Record<Id, LightNode>,
    node: ProcessedNodeData,
    state: boolean
  ) => Record<Id, LightNode>
}

export class SingleNodeLogic extends SelectionLogic {
  protected node: NodeLogic = new NodeSingleNode()

  public minimumSetOfNodeImpl: (listSelected: Record<Id, LightNode>, node: NodeData) => LightNode[] = (
    listSelected,
    _node
  ) => {
    return Object.values(listSelected)
  }

  protected onSelectionNodeLogic: (
    listSelected: Record<Id, LightNode>,
    node: ProcessedNodeData,
    state: boolean
  ) => Record<Id, LightNode> = (listSelected, node, state) => {
    let newListSelected = listSelected
    if (state) {
      newListSelected = { [node.id]: { id: node.id, label: node.label } }
    } else {
      // element not found
      if (!newListSelected[node.id]) {
        newListSelected = { [node.id]: { id: node.id, label: node.label } }
      } else {
        newListSelected = {}
      }
    }
    return newListSelected
  }
}

export class SingleSelectDownLogic extends SelectionLogic {
  protected node: NodeLogic = new NodeSingleSelectDown()
  public minimumSetOfNodeImpl: (listSelected: Record<Id, LightNode>, node: NodeData) => LightNode[] = (
    listSelected,
    _node
  ) => {
    return Object.values(listSelected)
  }

  protected onSelectionNodeLogic: (
    listSelected: Record<Id, LightNode>,
    node: ProcessedNodeData,
    state: boolean
  ) => Record<Id, LightNode> = (listSelected, node, state) => {
    let newListSelected = listSelected
    if (state) {
      newListSelected = { [node.id]: { id: node.id, label: node.label } }
    } else {
      // element not found
      if (!newListSelected[node.id]) {
        newListSelected = { [node.id]: { id: node.id, label: node.label } }
      } else {
        newListSelected = {}
      }
    }
    return newListSelected
  }
}

export class MultiSelectUpLogic extends SelectionLogic {
  private _minimumSetListSelected: Record<Id, LightNode> = this._listSelected

  protected node: NodeLogic = new NodeMultiSelectUp()
  public minimumSetOfNodeImpl: (listSelected: Record<Id, LightNode>, node: NodeData) => LightNode[] = (
    listSelected,
    _node
  ) => {
    const listToReturn: LightNode[] = []
    Object.values(listSelected).forEach(el => {
      if (this._minimumSetListSelected[el.id]) {
        listToReturn.push(el)
      }
    })
    return listToReturn
  }

  protected onSelectionNodeLogic: (
    listSelected: Record<Id, LightNode>,
    node: ProcessedNodeData,
    state: boolean
  ) => Record<Id, LightNode> = (listSelected, node, state) => {
    let newListSelected = listSelected
    if (state) {
      const parents = this.getAllParentSelectable(node)
      this._minimumSetListSelected = { ...this._minimumSetListSelected, [node.id]: { id: node.id, label: node.label } }
      newListSelected = { ...this._minimumSetListSelected, [node.id]: { id: node.id, label: node.label }, ...parents }
    } else {
      const nodeToRemove = newListSelected[node.id]
      const firstParentSelected = this.firstParentSelected(node)
      if (nodeToRemove) {
        const children = getAllChildren(node)
        delete newListSelected[node.id]

        if (firstParentSelected) {
          this._minimumSetListSelected = {...this._minimumSetListSelected,[firstParentSelected.id]:firstParentSelected}
          newListSelected = {...newListSelected,[firstParentSelected.id]:firstParentSelected}
        }

        delete this._minimumSetListSelected[node.id]
        Object.values(children).forEach(p => {
          delete this._minimumSetListSelected[p.id]
          delete newListSelected[p.id]
        })
      }
    }
    return newListSelected
  }
}

export default SelectionLogic
