import { IconDefinition, faCalendar, faWrench } from '@fortawesome/free-solid-svg-icons'
import TaskList from './components/views/TaskList/TaskListPageView'
import TaskDetail from './components/views/TaskDetail/TaskDetailPageView'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes' 

// import TaskCalendar from './components/TaskCalendar/TaskCalendar'

// export interface RouteProps {
//   path: string
//   exact?: boolean
//   i18nkey?: string
//   icon?: Icon
//   iconOverride?: string
//   visible: boolean
//   component?: any
//   children?: RouteProps[]
//   counter?: any
// }

// const pageSlugs = ['manager', 'ongoing', 'overdue', 'to-validate', 'archive',]

export type Module = 'scadenziario' | 'anomalie'

function generateRoutes(module: Module, pageSlugs: string[]): RouteProps {
  return {
    path: `/${module}/${pageSlugs[0]}`,
    i18nkey: `chronoframe.dueDateModule.${module}`,
    icon: generateRoute(module),
    visible: true,
    children: pageSlugs.reduce(
      (acc: RouteProps[], pageSlug) =>
        acc.concat(
          {
            path: `/${module}/${pageSlug}`,
            exact: true,
            i18nkey: `chronoframe.pageSlug.${module}.${pageSlug}`,
            component: TaskList,
            visible: true,
            counter: {
              storeKey: `chronoframe.pageConfig.sidebarCounters`,
              pageSlug,
            },
          },
          {
            path: `/${module}/${pageSlug}/:id`,
            exact: true,
            component: TaskDetail,
            visible: false,
          },
        ),
      [],
    ),
  }
}

function generateRoute(module: Module): IconDefinition {
  switch (module) {
    case 'anomalie':
      return faWrench
    case 'scadenziario':
      return faCalendar
    default:
      return faCalendar
  }
}

export default generateRoutes
export { generateRoutes }
