// * -------------------------------- MODULE --------------------------------------
import { instanceOf } from './helpers/objectHelper'

// * ----------------------------------------------------------------------
// * ------------------------------- SPACE --------------------------------
// * ----------------------------------------------------------------------
export type Margin = SpaceTRBL | SpaceXY | Space
export type Padding = SpaceTRBL | SpaceXY | Space

/**
 * 0 --> spacer * 0
 *
 * 1 --> spacer * .25
 *
 * 2 --> spacer * .5
 *
 * 3 --> spacer * 1
 *
 * 4 --> spacer * 1.5
 *
 * 5 --> spacer * 3
 */
type PossibleSize = 0 | 1 | 2 | 3 | 4 | 5

export interface SpaceTRBL {
  top?: PossibleSize
  bottom?: PossibleSize
  left?: PossibleSize
  right?: PossibleSize
}

export interface SpaceXY {
  x?: PossibleSize
  y?: PossibleSize
}

export interface Space {
  val?: PossibleSize
}

export enum SpaceType {
  margin = 'm',
  padding = 'p',
}

export const generateSpace = (spaceType: SpaceType, type: SpaceTRBL | SpaceXY | Space) => {
  if (instanceOf<SpaceTRBL>(type, ['top', 'bottom', 'left', 'right'], 'Or')) {
    const top = type.top ? `${spaceType}t-${type.top}` : ''
    const bottom = type.bottom ? `${spaceType}b-${type.bottom}` : ''
    const left = type.left ? `${spaceType}l-${type.left}` : ''
    const right = type.right ? `${spaceType}r-${type.right}` : ''
    return `${top} ${bottom} ${left} ${right}`
  }
  if (instanceOf<SpaceXY>(type, ['x', 'y'], 'Or')) {
    const x = type.x ? `${spaceType}x-${type.x}` : ''
    const y = type.y ? `${spaceType}y-${type.y}` : ''
    return `${x} ${y} `
  }
  if (instanceOf<Space>(type, 'val')) {
    const val = type.val ? `${spaceType}-${type.val}` : ''
    return `${val}`
  }
}
// * ------------------------------- UTILITY SPACE--------------------------------
export const mdEqualSpace = () => generateSpace(SpaceType.padding, { val: 3 })

// * ----------------------------------------------------------------------
// * ------------------------------- SIZE --------------------------------
// * ----------------------------------------------------------------------
export enum SizeType {
  width = 'w',
  height = 'h',
  vWidth = 'vw',
  vHeight = 'vh',
}

export type Percentage = 25 | 50 | 60 | 70 | 75 | 80 | 85 | 90 | 95 | 100

/**
 * @param sizeType v stand for viewport
 */
export const generateSize = (sizeType: SizeType, percentage: Percentage) => {
  return `${sizeType}-${percentage}`
}

// * ----------------------------------------------------------------------
// * ------------------------------- UTILITY --------------------------------
// * ----------------------------------------------------------------------
export enum Utility {
  disableContent = 'disableContent',
}
