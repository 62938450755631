import {ActionCreator, AnyAction, Dispatch} from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { pageConfigReconciliation, pageConfigUpdateFormFields } from './pageConfigReconciliation'
import { DueDatesConfig, SidebarCounter } from '../../types/pageConfig'
import { API } from './index'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

// import * as mock from './_mockPageConfig.json' // TEMP

export const CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING = 'CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING'
export const CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS = 'CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS'
export const CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE = 'CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE'
export const CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS = 'CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS'
export const CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS = 'CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS'

interface PageConfigFetching {
  type: typeof CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING,
  pageSlug: string
}


interface PageConfigSuccess {
  type: typeof CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS,
  data: DueDatesConfig
}

interface PageConfigError {
  type: typeof CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE,
  error: Error
}

interface PageConfigUpdateStructureSuccess {
  type: typeof CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS,
  data: object
}

interface CountersUpdateSuccess {
  type: typeof CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS,
  data: SidebarCounter[]
}

export const pageConfig = (pageSlug: string) => async (dispatch: Dispatch) => {
  dispatch(pageConfigFetching(pageSlug))

  try {
    const result: DueDatesConfig = await API().request(`/chrono-frame/page-config/${pageSlug}`, {
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
    })
    const data = pageConfigReconciliation(result)
    // const data = pageConfigReconciliation(conf as DueDatesConfig) // TEMPORARY!!!
    // await new Promise(resolve => setTimeout(resolve, 2000)) // TEMPORARY!!!
    dispatch(pageConfigSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(pageConfigError(error))
    }
    throw error
  }
}

export const pageConfigNoRedux = (pageSlug: string) => (dispatch: Dispatch<AnyAction>, getState: Function) => {
  return API().request(`/chrono-frame/page-config/${pageSlug}`)
    .then((data: any) => pageConfigReconciliation(data))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}


// currently it's used just this because there isn't a clearly separation from the form data and the form structure
// (everything is tied up in the pageConfigReconciliation function, it needs to be rethinked)
export const pageConfigUpdateData = (pageSlug: string, destination: string) => async (dispatch: Dispatch) => {
  try {
    const result: DueDatesConfig = await API().request(
      destination === ''
        ? `/chrono-frame/page-config/${pageSlug}`
        : `/chrono-frame/page-config/${pageSlug}?destination=${destination}`, // both this api and the one in the pageConfigUpdateData needs to be updated to the new one
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'it-IT',
        },
      },
    )
    // here it's the same data as the pageConfigUpdateData, but it needs only the 'values' value
    // a further development of the API endpoint is needed, or otherwise another endpoint

    // const data = result.values // in the future this will be only result
    // dispatch(pageConfigUpdateSuccess(data))
    const data = pageConfigReconciliation(result) // it overwrites everyting that was there before
    dispatch(pageConfigSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(pageConfigError(error))
    }
    throw error
  }
}

export const pageConfigUpdateDataNoRedux = (pageSlug: string, destination?: string) => (dispatch: Dispatch) => {
  return API().request(
    !destination
      ? `/chrono-frame/page-config/${pageSlug}`
      : `/chrono-frame/page-config/${pageSlug}?destination=${destination}`, // both this api and the one in the pageConfigUpdateData needs to be updated to the new one
    {
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
    },
  )
    .then((data: DueDatesConfig) => pageConfigReconciliation(data))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const pageConfigUpdateStructure = (pageSlug: string, destination: string) => async (dispatch: Dispatch) => {
  try {
    const result: DueDatesConfig = await API().request(
      `/chrono-frame/page-config/${pageSlug}?destination=${destination}`, // both this api and the one in the pageConfigUpdateData needs to be updated to the new one
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'it-IT',
        },
      },
    )
    // here it's the same data as the pageConfigUpdateData, but it needs only the form structure
    const data = pageConfigUpdateFormFields(result)
    dispatch(pageConfigUpdateStructureSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(pageConfigError(error))
    }
    throw error
  }
}

export const updateSidebarCounters = (configuration: SidebarCounter[]) => async (dispatch: Dispatch) => {
  const counters: SidebarCounter[] = []
  configuration.forEach(async element => {
    const { pageSlug, color, alwaysVisible } = element
    try {
      const count: any = await API().request(`/chrono-frame/chrono-tasks/counter?pageSlug=${pageSlug}`, {
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'it-IT',
        },
      })

      if ((count && count.number > 0) || (count && alwaysVisible)) {
        counters.push({
          pageSlug,
          color,
          count: count.number,
        })
      }
      dispatch(countersUpdateSuccess(counters))
    } catch (error) {
      console.log(error) // tslint:disable-line
    }
  })
}

const pageConfigFetching:ActionCreator<PageConfigFetching> = (pageSlug: string): PageConfigFetching => ({
  type: CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING,
  pageSlug,
})

const pageConfigSuccess:ActionCreator<PageConfigSuccess> = (data: DueDatesConfig): PageConfigSuccess => ({
  type: CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS,
  data,
})

const pageConfigError:ActionCreator<PageConfigError> = (error: Error): PageConfigError => ({
  type: CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE,
  error,
})

// this is used to update/overwrite form structure
const pageConfigUpdateStructureSuccess:ActionCreator<PageConfigUpdateStructureSuccess> = (data: object): PageConfigUpdateStructureSuccess => ({
  type: 'CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS',
  data,
})

const countersUpdateSuccess:ActionCreator<CountersUpdateSuccess> = (counters: SidebarCounter[]): CountersUpdateSuccess => ({
  type: 'CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS',
  data: counters,
})

export type  PageConfigActions = PageConfigFetching | PageConfigSuccess | PageConfigError | PageConfigUpdateStructureSuccess | CountersUpdateSuccess


