// * -------------------------------- MODULE -----------------------------------
import { FetchWrapper } from '../../../../../../types/fetchWrapperInterface'
import {
  FilterTable,
  ListColumnSize,
  ListFilters,
  ListPagination,
  ParseResultNoPagination,
  ParseResultWithPagination,
} from '../../types/table'
import { stringify } from '../../../../../../functions/apiFormatter'
import { IDispatch } from '../../../../../../services/stateManager'

interface SetPagination {
  type: typeof SET_PAGINATION
  params: Record<string, ListPagination>
}

interface SetFilters {
  type: typeof SET_FILTERS
  params: Record<string, ListFilters>
}

interface SetColumnSize {
  type: typeof SET_COLUMN_SIZE
  params: Record<string, ListColumnSize>
}

export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_COLUMN_SIZE = 'SET_COLUMN_SIZE'

export const setPagination = (slug: string, params: ListPagination): SetPagination => ({
  type: SET_PAGINATION,
  params: { [slug]: params },
})

export const setFilters = (slug: string, params: ListFilters): SetFilters => ({
  type: SET_FILTERS,
  params: { [slug]: params },
})

export const setColumnSize = (slug: string, params: ListColumnSize): SetColumnSize => ({
  type: SET_COLUMN_SIZE,
  params: { [slug]: params },
})

/**
 *
 * @param fetchWrapper an instance of the fetchWrapper
 * @param request baseUrl of the request without searchParams needed for pagination and filters
 * @param pagination
 * @param filters
 * @param f filter interface used to retrieve the stringify function
 * @param parseResult
 * @param abortController
 * @returns return the result of the request after the parseResult
 * @throws error if url is not correct or fetch request goes wrong
 */
export const fetchTableData = <T, K>(
  fetchWrapper: FetchWrapper,
  request: string,
  pagination: ListPagination | undefined,
  filters: ListFilters,
  f: FilterTable[] | undefined,
  parseResult: ParseResultWithPagination<T, K> | ParseResultNoPagination<T, K>,
  abortController: AbortController,
  headers?: Record<string, any>
) => async (
  dispatch: IDispatch<any>
): Promise<{ parsedData: ReturnType<typeof parseResult>; dateReturnedFromFetchRequest: K }> => {
  try {
    const url = new URL(request, 'http://localhost')

    if (pagination) {
      Object.entries(pagination).forEach(([key, value]) => {
        if (value !== undefined && value !== '' && key !== 'count') {
          url.searchParams.set(key, value)
        }
      })
    }

    let stringified = ''
    let searchParams
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== '') {
        const customStringify = f?.find(el => el.filterId === key)?.stringify
        if (customStringify) {
          stringified = customStringify(key, value)
        } else {
          stringified = stringify({ [key]: value })
        }
      }
      searchParams = new URLSearchParams(stringified)
      searchParams.forEach((v, k) => {
        url.searchParams.set(k, v)
      })
    })

    return fetchWrapper
      .request(url.pathname + url.search, {
        signal: abortController.signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(headers || {}),
        },
      })
      .then((data: K) => {
        return {
          parsedData: parseResult(data),
          dateReturnedFromFetchRequest: data,
        }
      })
  } catch (e) {
    throw e
  }
}

export type TableAction = SetPagination | SetFilters | SetColumnSize
