import { CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import * as moment from 'moment'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { getAreaPauses, getAreaSections, reduceWarnAreas } from '../../../../../functions/shared'
import { AreaSection } from '../../../../../types/measure'
import GraphTimelineView from '../../GraphTimeline/GraphTimelineView'
import {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'

export interface OwnProps {
  data: any
  filteredData: any
  pauses?: Array<{ start: number; end: number }>
}


type Props = OwnProps & WithTranslation

class BagGraphMainView extends React.Component<Props,{}> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const { pauses, filteredData } = this.props

    const warnPoints: AreaSection[] | undefined =
      (filteredData as [{}]) && getAreaSections(filteredData, 'warnArea').filter(e => e.value === 100)

    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), undefined, COLORS.pauses)
    const warnAreas = reduceWarnAreas(warnPoints)

    return (
      <TableRowFullWrapper>
        <ResponsiveContainer width='100%' height={400}>
          <ComposedChart
            width={700}
            height={400}
            data={filteredData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            barGap={0}
            barCategoryGap={0}
          >
            <XAxis
              hide={true}
              dataKey='time'
              label={''}
              height={30}
              axisLine={false}
              tickLine={false}
              domain={['dataMin', 'dataMax']}
              tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
              type={'number'}
            />
            <YAxis domain={[0, 100]} axisLine={false}>
              <Label value={'%'} position={'insideTopLeft'} />
            </YAxis>
            <CartesianGrid stroke='#f5f5f5' />
            {warnAreas}
            {warnPauses}
            <Line
              dataKey='performance'
              stroke={COLORS.performance}
              dot={false}
              strokeWidth={2}
              type='step'
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <GraphTimelineView data={filteredData} colWidth={12} xAxisNumber={true} preserveStartEnd={true} />
      </TableRowFullWrapper>
    )
  }
}

export default withTranslation()(BagGraphMainView)
