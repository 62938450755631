// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import { FetchWrapper } from '../../../types/fetchWrapperInterface'
import { download } from '../../../mvfunctions/download'
import { logWarn } from '../../../functions/log'

interface Props {
  className?: string
  path: string
  fileName?: string
  fetchWrapper: FetchWrapper
  children?: React.ReactNode
}

const DownloadButton = (props: Props) => {
  const className = props.className || 'btn btn-link'

  // TODO: moving with defined Button
  return (
    <button
      className={className}
      onClick={async () => {
        try {
          const file: any = await props.fetchWrapper.request(props.path, {}, true)
          return download(file, props.fileName!)
        } catch (error) {
          logWarn('File download error', error)
        }
      }}
    >
      {props.children || 'Download'}
    </button>
  )
}
export default DownloadButton
