import { AnyAction } from 'redux'
import { ChronoTask } from '../../types/chronoTasks'

interface PageDetailState {
  isFetching: boolean
  isUpdating: boolean
  data?: ChronoTask
  formData?: ChronoTask
  error?: Error
}

const initialState: PageDetailState = {
  isFetching: false,
  isUpdating: false,
}

export function pageDetail(
  state: PageDetailState = initialState,
  action: AnyAction
): PageDetailState {
  switch (action.type) {

    case 'CHRONO_FRAME_TASKS_ASSIGNING':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: true,
        }
      }
      return state

    case 'CHRONO_FRAME_TASKS_ASSIGNMENT_SUCCESS':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: false,
          data: { ...state.data, assigneeUser: action.assigneeUser },
          formData: { ...state.data, assigneeUser: action.assigneeUser.id },
        }
      }
      return state

    case 'CHRONO_FRAME_TASKS_ASSIGNMENT_FAILURE':

    default:
      return state
  }
}
