import moment from 'moment'

type UnixTime = number
type UnixTimeAsString = string

type MeasureSlug = string

const mergeMeasuresToSingleObject = <T extends string>(datas: Record<MeasureSlug, Array<{ x: UnixTime, y: number, h100: 100 }>>): Array<Record<MeasureSlug, number>> => {
  const result: Array<Record<string, any>> = []

  /// mergio tutti i dati presenti in datas per costruire result
  Object.entries<any[]>(datas).map(([key, value]) => {
    value.map((data) => {
      const existing = result.find(e => e.time === data.x)

      if (!existing) {
        result.push({ h100: data.h100, time: data.x, [key]: data.y })
      } else {
        existing[key] = data.y
      }
    })
  })

  return result
}

const fillMeasuresInUncompletedRecords = <T extends string>(keys: string[], datas: Array<Record<string, any>>): Array<Record<MeasureSlug, number>> => {

  const last: any[] = []

  const result = Object.values(datas).sort((a, b) => (a.time < b.time ? -1 : 1))

  // first last for each key
  keys.map(key => {
    const elementFound = result.find(element => !!element[key])
    if (elementFound) {
      last[key] = elementFound[key]
    }
  })

  /// fill each record with effective value or last value
  return result.map((record) => {

    const filledRecord: Record<string, any> = { ...record }

    keys.map(key => {
      if (record[key] !== undefined) {
        last[key] = record[key]
      } else {
        filledRecord[key] = last[key]
      }
    })

    return filledRecord
  })

}


/**
 * from: {x:[values1],y:[values2]}
 * to: [{x:values1,y:values2},{x:values1,y:values2}]
 */
export const composeData = <T extends string>(datas: Record<MeasureSlug, Array<{ x: UnixTime, y: number, h100: 100 }>>): Array<Record<MeasureSlug, number>> => {

  const result = mergeMeasuresToSingleObject(datas)

  return fillMeasuresInUncompletedRecords(Object.keys(datas), result)
}


export const fillTime = (mergedData: Array<Record<string, any>>, timeStart: number, timeEnd: number,minTimeGap?:number): Array<Record<string, any>> => {

  const mergedDataWithFilledTimeEntries: Record<number, any> = mergedData.reduce((acc, curr) => ({
    ...acc,
    [curr.time]: curr,
  }), {})

  if (mergedData.length > 0) {
    const timeDiff = timeEnd - timeStart

    if (timeDiff <= 0) {
      return Object.values(mergedDataWithFilledTimeEntries).sort((a, b) => (a.time < b.time ? -1 : 1))
    }


    if (minTimeGap !== undefined) {
      for (let time = timeStart; time <= timeEnd; time += minTimeGap) {
        if (!mergedDataWithFilledTimeEntries[time]) {
          mergedDataWithFilledTimeEntries[time] = {}
        }
      }
    }


    if (!mergedDataWithFilledTimeEntries[timeEnd]) {
      mergedDataWithFilledTimeEntries[timeEnd] = {}
    }

    // sort data by time to maintain consistency between data and created data
    const arrayMergedDataWithFilledTimeEntries: Array<[UnixTimeAsString,any]> = Object.entries(mergedDataWithFilledTimeEntries).sort(([aKey, aValue], [bKey, bValue]) => (Number(aValue.time) < Number(bValue.time) ? -1 : 1))

    let lastElement = mergedData[0]

    arrayMergedDataWithFilledTimeEntries.forEach(([time, value]) => {

      if (value.time === undefined) {
        lastElement = { ...lastElement, time: Number(time) }
      } else {
        lastElement = value
      }
      mergedDataWithFilledTimeEntries[lastElement.time] = lastElement
    })

    return Object.values(mergedDataWithFilledTimeEntries).sort((a, b) => (a.time < b.time ? -1 : 1))

  }
  return mergedData
}

/// imposta il valore della y con l'indice 1 dell'oggetto che arriva da influx
export const populateSingleDataFrom = (data: any[], isBoolean: boolean = false, isTripleState: boolean = false): Array<{ x: number, y: number, h100: 100 }> => {

  if (data) {
    return data.map((datum: any) => {
      const time = moment(datum[0]).unix()

      let y = datum[1]
      if (isBoolean) {
        y = (datum[1] ? 1 : 0)
      }
      if (isTripleState) {
        y = (datum[1] ? 0 : datum[2] ? 1 : 2)
      }

      return {
        x: time,
        y,
        h100: 100,
      }
    })
  }

  return []
}
