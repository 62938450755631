// * -------------------------------- NPM --------------------------------------
import { useEffect, useMemo, useState } from 'react'
import { debounce } from '../../../functions/helpers'
import { Changeable } from '../../../types/onFunction'
import { InitialValue } from '../Input/types'

interface Props extends InitialValue, Changeable<[value:string,event?:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>]> {
  throttle?: number
}

const useWithDebounce = (props: Props): [typeof handleChange,typeof value] => {
  const { onChange, initialValue, throttle } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {setValue(initialValue)},[initialValue])

  const handler = (newValue: string) => {
    onChange?.(newValue)
  }

  const propagateOnChange = useMemo(() => debounce(handler, throttle || 200), [props.onChange])

  useEffect(() => {
    return () => {
      propagateOnChange.cancel()
    }
  }, [])


  function handleChange(newValue: string) {
    setValue(newValue)
    propagateOnChange(newValue)
  }

  return [handleChange, value]
}

export default useWithDebounce
