// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Text, { TextProps } from '../Text/Text'
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'

interface Props {
  direction?: 'row' | 'column'
  split?: '2-8' | '3-7' | '4-6' | '5-5'
  label: TextProps
}

const LabelWithValue: React.FC<Props & IFlexItem> = props => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const lv = 'mv-label-value'
  const { label, direction, split } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={`${lv} ${lv}--${direction} ${lv}--${split} ${renderFlexItem(props as IFlexItem)} `}>
      <div className={`${lv}__label`}>
        <Text {...label} />
      </div>
      <div className={`${lv}__text`}>{props.children}</div>
    </div>
  )
}

LabelWithValue.defaultProps = {
  split: '3-7',
  direction: 'row',
}

export default LabelWithValue
