import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Column, RowInfo, Resize } from 'react-table'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as moment from 'moment'
import {
  PageHeader,
  Table,
  Button,
  Loader,
  Tooltip,
  HtmlTooltip,
  ButtonGroupHeader,
  TableRowButtonGroup,
  Badge,
  Link,
} from '@mv-submodules/inplant-components-fe'
import TaskListFilters from './TaskListFilters'
import TaskFormModal from '../../widgets/TaskFormModal/TaskFormModal'
import TaskTitle from '../../widgets/TaskFields/TaskTitle'
/*
import TaskAssigneeColumn from '../../widgets/TaskFields/TaskAssignee'
*/
import { getInputValueFromData } from '../../widgets/TaskFormModal/utils'
import {
  pageConfigNoRedux,
  pageConfigUpdateDataNoRedux,
  updateSidebarCounters,
} from '../../../../redux/actions/pageConfig'
import {
  exportData,
  saveLastAppliedFilter,
  saveSearchString,
  updateFilteredIds,
  updateFilterModal,
  setFiltersPageList,
  fetchListWithPagination,
  applyChronoframeListPagination,
  setResizeColumn,
} from '../../../../redux/actions/pageList'
import { submitData, submitTemplate } from '../../../../redux/actions/pageForm'
import { assignTask, changeStatusMultiple } from '../../../../redux/actions/pageActions'
import { DueDatesConfig, PageConfig, SidebarCounter } from '../../../../types/pageConfig'
import { DueDatesOptions, Target } from '../../../../types/chronoTasks'
import { TableColumn, ColumnType, ListPagination } from '../../../../types/pageList'
import { DetailField } from '../../../../types/pageDetail'
import { User, ChronoTasks, ChronoTask } from '../../../../types/chronoTasks'
import { DoneBatchForm, FormData, RescheduleBatchForm, ValidateBatchForm } from '../../../../types/pageForm'
import { getBadgeType } from '../../../../functions/shared'
import TaskActionModal from '@mv-submodules/inplant-chronoframe-fe/ui/components/views/TaskDetail/TaskActionModal'
import ActionDropdown from '@mv-submodules/inplant-components-fe/ui/components/Table/ActionDropdown'
import { DropdownAction } from '@mv-submodules/inplant-components-fe/ui/components/Button/DropdownButton'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import DownloadCsvButton from '@mv-submodules/inplant-components-fe/ui/components/Button/DownloadCsvButton'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { getDataFromObject } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import { showNotification } from '../../../../../inplant-components-fe/ui/components/MVNotification/Notification'

interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

interface OwnState {
  pageSlug: string
  module: string
  showFormModal: boolean
  tableColumns: any[]
  search: string | null
  pageConfig?: PageConfig<DueDatesOptions>
  isExporting: boolean
  data?: ChronoTasks
  isFetchingConfig: boolean
  fetchErrorsConfig: boolean
  isFetchingList: boolean
  fetchErrorsList: boolean
  selectedTasks: {
    [k: string]: {
      lastActionId: string
      status: string
      rolesWithValidation: string[]
      availableActions: string[]
    }
  }
  showActionModal: boolean
  currentAction?: string
  pagination: ListPagination
}

interface StateProps {
  currentUser: User
  forbiddenActions: string[]
  isConfigLoading: boolean
  appliedFilters?: FormData
  detailTitle: DetailField[]
  resized: Resize[]
  lastAppliedFilter: string
  savedSearchString: string
  updateFilterModalValue: boolean
  columnSize: Record<string, Record<string, any>>
  pagination: {
    [key: string]: ListPagination
  }
}

interface DispatchProps {
  submitData: (data: FormData) => Promise<FormData>
  submitTemplate: (data: FormData, options: DueDatesOptions) => Promise<FormData>
  assignTask: (assignee: User, taskIds: string[]) => Promise<void>
  pageConfig: (pageSlug: string) => Promise<DueDatesConfig>
  exportData: (pageSlug: string, filters?: FormData) => Promise<void>
  fetchListWithPagination: (
    pageSlug: string,
    pagination: ListPagination,
    abortController: AbortController,
    filters?: FormData
  ) => Promise<ChronoTasks>
  setFiltersPageList: (pageSlug: string, filters?: FormData) => void
  saveLastAppliedFilter: (appliedFilter: string) => Promise<void>
  changeStatusMultiple: (action: string, data: DoneBatchForm[] | ValidateBatchForm[] | RescheduleBatchForm[]) => Promise<void>
  saveSearchString: (searchString: string) => Promise<void>
  updateFilteredIds: (ids: string[]) => Promise<void>
  updateFilterModal: () => Promise<void>
  pageConfigUpdateData: (pageSlug: string, destination?: string) => any
  updateSidebarCounters: (configuration: SidebarCounter[]) => any
  applyChronoframeListPagination: (pageSlug: string, params: ListPagination) => void
  handleResizeColumn: (tableId: string, column: Resize[]) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation

const mapStateToProps = ({ chronoframe, auth }: any) => {
  return {
    currentUser: { ...auth.user, id: auth.user.uuid },
    forbiddenActions: auth.user.forbiddenActions,
    isConfigLoading: chronoframe.pageConfig.isFetching,
    appliedFilters: chronoframe.pageList.filters,
    listIsFetching: chronoframe.pageList.isFetching,
    resized: chronoframe.pageList.resized,
    pageSize: chronoframe.pageList.pageSize,
    pageNumber: chronoframe.pageList.pageNumber,
    lastAppliedFilter: chronoframe.pageList.lastAppliedFilter,
    savedSearchString: chronoframe.pageList.filters.searchString,
    updateFilterModalValue: chronoframe.pageList.updateFilterModal,
    pagination: chronoframe.pageList.pagination,
    columnSize: chronoframe.pageList.columnResize,
  }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  // submitData: (data) => submitData(data)(dispatch),
  submitData: async data => {
    try {
      await submitData(data)(dispatch)
      // await fetchList(filters)(dispatch) // I need to update the view with correct embedded values
    } catch (error) {
      console.warn('submitData', error) // tslint:disable-line
    }
    return data
  },
  handleResizeColumn: (tableId: string, column: Resize[]) => setResizeColumn(tableId, column)(dispatch),
  submitTemplate: (data, options) => submitTemplate(data, options)(dispatch),
  assignTask: (assignee, taskIds) => assignTask(assignee, taskIds)(dispatch),
  pageConfig: pageSlug => dispatch(pageConfigNoRedux(pageSlug)),
  exportData: (pageslug, filters) => exportData(pageslug, filters)(dispatch),
  fetchListWithPagination: (
    pageSlug: string,
    pagination: ListPagination,
    abortController: AbortController,
    filters?: FormData
  ) => dispatch(fetchListWithPagination(pageSlug, pagination, abortController, filters)),
  setFiltersPageList: (pageSlug: string, filter?: FormData) => setFiltersPageList(pageSlug, filter)(dispatch),
  saveLastAppliedFilter: (appliedFilter: string) => saveLastAppliedFilter(appliedFilter)(dispatch),
  changeStatusMultiple: (action: string, data: DoneBatchForm[] | ValidateBatchForm[]) =>
    changeStatusMultiple(action, data)(dispatch),
  saveSearchString: (searchString: string) => saveSearchString(searchString)(dispatch),
  updateFilteredIds: (ids: string[]) => updateFilteredIds(ids)(dispatch),
  updateFilterModal: () => updateFilterModal()(dispatch),
  pageConfigUpdateData: (pageSlug: string, destination?: string) =>
    pageConfigUpdateDataNoRedux(pageSlug, destination)(dispatch),
  updateSidebarCounters: (configuration: SidebarCounter[]) => updateSidebarCounters(configuration)(dispatch),
  applyChronoframeListPagination: (pageSlug: string, params: ListPagination) =>
    dispatch(applyChronoframeListPagination(pageSlug, params)),
})

class TaskListPageView extends React.Component<Props, OwnState> {
  public tableState: any

  private abortController = new AbortController()
  private timerDebounce: any

  constructor(props: Props) {
    super(props)
    this.state = {
      pageSlug: this.getPageSlug(),
      module: this.getModule(),
      showFormModal: false,
      tableColumns: [],
      search:
        (props.appliedFilters &&
          props.appliedFilters[this.getPageSlug()] &&
          props.appliedFilters[this.getPageSlug()].searchString) ||
        null,
      isExporting: false,
      isFetchingConfig: false,
      fetchErrorsConfig: false,
      fetchErrorsList: false,
      isFetchingList: false,
      selectedTasks: {},
      showActionModal: false,
      pagination:
        this.props.pagination !== undefined && this.props.pagination[this.getPageSlug()] !== undefined
          ? { ...this.props.pagination[this.getPageSlug()] }
          : {
              limit: 50,
              offset: 0,
              sort: '',
            },
    }
    this.fetchData = this.fetchData.bind(this)
    this.refreshPageConfigData = this.refreshPageConfigData.bind(this)
    this.toggleFormModal = this.toggleFormModal.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getList = this.getList.bind(this)
    this.getAppliedFilters = this.getAppliedFilters.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
    this.parseColumns = this.parseColumns.bind(this)
    this.selectAllPageTasks = this.selectAllPageTasks.bind(this)
    this.renderTableCell = this.renderTableCell.bind(this)
    this.handleApplyFilters = this.handleApplyFilters.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.exportData = this.exportData.bind(this)
    this.handleSelectionMultipleTask = this.handleSelectionMultipleTask.bind(this)
    this.submitMultipleTasks = this.submitMultipleTasks.bind(this)
    this.handleCloseTaskActionModal = this.handleCloseTaskActionModal.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onSortingChange = this.onSortingChange.bind(this)
    this.getModule = this.getModule.bind(this)
    this.handleResizeColumn = this.handleResizeColumn.bind(this)
  }

  private handleResizeColumn(newResized: Resize[], event: any) {
    clearTimeout(this.timerDebounce)
    this.timerDebounce = setTimeout(() => {
      this.props.handleResizeColumn(this.state.pageSlug, newResized)
    }, 300)
  }

  public async componentDidMount() {
    this.setState({ isFetchingConfig: true })
    this.props
      .pageConfig(this.state.pageSlug)
      .then(pageConfig => {
        this.props.updateSidebarCounters(pageConfig.counters)
        this.setState(
          {
            pageConfig,
            tableColumns: this.parseColumns(pageConfig.tableColumns),
          },
          () => this.fetchData(this.getAppliedFilters())
        )
      })
      .catch((error: any) => {
        console.log(error) //tslint:disable-line
        this.setState({ fetchErrorsConfig: true })
      })
      .finally(() => {
        this.setState({
          isFetchingConfig: false,
        })
      })
  }

  private refreshPageConfigData(destination?: string) {
    this.props
      .pageConfigUpdateData(this.state.pageSlug, destination)
      .then((pageConfig: DueDatesConfig) => {
        this.setState({ pageConfig })
      })
      .catch((error: any) => {
        console.log(error) //tslint:disable-line
        this.setState({ fetchErrorsConfig: true })
      })
  }

  private fetchData(filters: FormData) {
    if (this.state.isFetchingList) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }
    this.setState({ isFetchingList: true, selectedTasks: {} })
    this.props
      .fetchListWithPagination(this.state.pageSlug, this.state.pagination, this.abortController, filters)
      .then(listData => {
        this.setState({
          data: listData,
          isFetchingList: false,
        })
      })
      .catch((error: any) => {
        console.log(error) //tslint:disable-line
        if (!(error.name && error.name === 'AbortError')) {
          this.setState({ fetchErrorsList: true })
        }
      })
  }

  private toggleFormModal() {
    this.setState(currentState => ({
      showFormModal: !currentState.showFormModal,
    }))
  }

  private getColumnWidth(slug: string, type: ColumnType) {

    
    if (this.props.columnSize) {
      const tableColumns = Object.entries(this.props.columnSize).find(([key]) => key === this.state.pageSlug)
      if(tableColumns) {
        const columnSizeSaved = Object.entries(tableColumns[1]).find(([key]) => key === slug)
        if (columnSizeSaved) {
          return columnSizeSaved[1] as number
        }
      }
    }

    switch (slug) {
      case 'status':
      case 'customProperties.ambito':
      case 'plantId':
        return 80
      case 'dueAtDate':
        return 90
      case 'isAcknowledged':
      case 'plannedDate':
      case 'customProperties.costCenter':
        return 120
      case 'batchActions':
        return 40
      case 'lastNotes':
        return 50
      case 'tableGroupsButton':
      case 'description':
        if (type === 'text-wrap') {
          return 300
        }
        return 60
    }
  }

  private parseColumns(tableColumns: TableColumn[]): Column[] {
    return tableColumns.map(
      (column: TableColumn): Column => ({
        Header: () => {
          const { data } = this.state
          if (column.slug[0] === 'batchActions') {
            if (data && data.results) {
              const { selectedTasks } = this.state
              const initialIndex = 0
              const endIndex = initialIndex + data.results.length
              const list = data.results.slice(initialIndex, endIndex)
              const selected = !list.some(task => selectedTasks[task.id] === undefined)
              return (
                <div className="rt-td-cell" onClick={this.selectAllPageTasks}>
                  <input
                    type="checkbox"
                    checked={selected && list.length > 0}
                    readOnly={true}
                    className="pt-1 pb-1 pl-1 pr-1"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              )
            } else {
              return (
                <div className="rt-td-cell" onClick={this.selectAllPageTasks}>
                  <input
                    type="checkbox"
                    checked={false}
                    readOnly={true}
                    className="pt-1 pb-1 pl-1 pr-1"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              )
            }
          }
          return column.slug.reduce(
            (acc: string, slug: string) => (acc === '' ? this.renderLabel(slug) : `${acc} - ${this.renderLabel(slug)}`),
            ''
          )
        },
        Cell: ({ original }: RowInfo) =>
          column.slug.map((slug: string, index: number) => (
            <div key={`${slug}_${index}`} className="rt-td-row">
              {this.renderTableCell(slug, column.type[index], original, index, column)}
            </div>
          )),
        width: this.getColumnWidth(column.slug[0], column.type[0]),
        minWidth: column.slug[0] === 'batchActions' ? 40 : 120,
        id: column.slug[0],
        accessor: row => {
          if (column.slug[0] === 'status') {
            return Math.abs(moment().diff(moment(row.dueAtDate), 'days'))
          }
          /*  if (column.slug[0] === 'target') {
              return row.target && row.target[0] !== undefined && row.target[0].description ? row.target[0].description.replace(/\[.*\]/, '').replace(' ', '') : ''
            } */
          return column.slug[0].split('.').reduce((obj, b) => (typeof obj === 'object' && obj[b] ? obj[b] : ''), row)
        },
        sortable: column.sortable || column.slug[0] === 'target',
        style:
          column.type[0] === 'assignee'
            ? { overflow: 'visible' }
            : column.type[0] === 'text-wrap'
            ? { whiteSpace: 'unset' }
            : undefined,
      })
    )
  }

  private renderLabel(slug: string) {
    const t = this.props.t
    const slugPath = slug.split('.')
    if (slug === 'tableGroupsButton') {
      return ''
    }
    return t(
      `chronoframe.dueDates.tableList.${
        slugPath[0] === 'customProperties' ? `${slugPath[0]}.${slugPath[1]}` : slugPath[0]
      }`
    )
  }

  private renderTableCell(slug: string, type: ColumnType, data: ChronoTask, index: number, column: TableColumn) {
    const t = this.props.t
    switch (type) {
      case 'checkmark-icon':
        return (
          <span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={`${getInputValueFromData(slug, data) ? 'green' : 'lightgray'}`}
            />
          </span>
        )
      case 'text':
      case 'number':
      case 'text-wrap':
        if (slug === 'plantId') {
          return (
            <Link
              onClick={() => this.onRowClick(data)}
              label={getInputValueFromData(slug, data) || (!index ? '-' : '')}
            />
            // <a onClick={() => this.onRowClick(data)} className="pointer">
            //   {getInputValueFromData(slug, data) || (!index ? '-' : '')}
            // </a>
          )
        }
        return getInputValueFromData(slug, data) || (!index ? '-' : '')
      case 'boolean':
        return t(`chronoframe.dueDates.tableList.${getInputValueFromData(slug, data) ? 'yes' : 'no'}`)
      case 'tooltip':
        const value = getInputValueFromData(slug, data)
        let tooltipIcon
        switch (column.icon) {
          case 'users':
            tooltipIcon = faUsers
          default:
            tooltipIcon = faInfo
        }
        const targetComponent = column.icon ? (
          <button type="button" className="btn btn-sm btn-link text-dark pl-1">
            <FontAwesomeIcon icon={tooltipIcon} size="sm" />
          </button>
        ) : (
          <button type="button" className="btn btn-sm btn-light rounded-circle p-0" style={{ width: 24, height: 24 }}>
            <FontAwesomeIcon icon={faInfo} size="xs" />
          </button>
        )
        return value != null && <Tooltip content={`${value}`} target={targetComponent} />
      case 'date':
        const date = getInputValueFromData(slug, data)
        return (
          <>
            {date ? (
              <>
                <div className="rt-td-cell">{moment(date).format('DD/MM/YYYY')}</div>
              </>
            ) : !index ? (
              '-'
            ) : (
              ''
            )}
          </>
        )
      case 'deadlineDate':
        const deadlineDate = getInputValueFromData(slug, data)
        const repetitionRule = data.automaticRenewal && getInputValueFromData('repetitionRule', data)
        let timeline
        if (repetitionRule && repetitionRule.period === 'days') {
          timeline = t(`chronoframe.dueDates.tableList.multipleDays`)
        } else if (repetitionRule && (repetitionRule.period === 'month' || repetitionRule.period === 'months')) {
          timeline =
            repetitionRule.count > 1
              ? t('chronoframe.dueDates.tableList.multipleMonths')
              : t('chronoframe.dueDates.tableList.singleMonth')
        } else if (repetitionRule && (repetitionRule.period === 'year' || repetitionRule.period === 'years')) {
          timeline =
            repetitionRule.count > 1
              ? t('chronoframe.dueDates.tableList.multipleYears')
              : t('chronoframe.dueDates.tableList.singleYear')
        }
        return (
          <>
            {deadlineDate ? (
              // <Tooltip
              //   content={`${moment(date).format('DD/MM/YYYY')}`}
              //   target={dateWrapper}
              // />
              <>
                <div className="rt-td-cell">{moment(deadlineDate).format('DD/MM/YYYY')}</div>
                {timeline && repetitionRule && (
                  <div className="rt-td-cell" style={{ fontWeight: 600 }}>
                    {`${t('chronoframe.dueDates.tableList.every')} ${repetitionRule.count} ${timeline}`}
                  </div>
                )}
              </>
            ) : !index ? (
              '-'
            ) : (
              ''
            )}
          </>
        )
      case 'repetition-rule':
        const renewalTime = getInputValueFromData(slug, data)
        const renewalRule = data.automaticRenewal && getInputValueFromData('repetitionRule', data)
        let time
        if (renewalRule && renewalRule.period.toLowerCase() === 'days') {
          time = t(`chronoframe.dueDates.tableList.multipleDays`)
        } else if (
          renewalRule &&
          (renewalRule.period.toLowerCase() === 'month' || renewalRule.period.toLowerCase() === 'months')
        ) {
          time =
            renewalRule.count > 1
              ? t('chronoframe.dueDates.tableList.multipleMonths')
              : t('chronoframe.dueDates.tableList.singleMonth')
        } else if (renewalRule && (renewalRule.period === 'year' || renewalRule.period === 'years')) {
          time =
            renewalRule.count > 1
              ? t('chronoframe.dueDates.tableList.multipleYears')
              : t('chronoframe.dueDates.tableList.singleYear')
        }
        return (
          <>
            {renewalTime ? (
              // <Tooltip
              //   content={`${moment(date).format('DD/MM/YYYY')}`}
              //   target={dateWrapper}
              // />
              <>
                {time && renewalRule && (
                  <div className="rt-td-cell">
                    {renewalRule && !!renewalRule.dayOfMonth
                      ? `${t('chronoframe.dueDates.tableList.the')} ${renewalRule.dayOfMonth}, ${t(
                          'chronoframe.dueDates.tableList.every'
                        )} ${renewalRule.count} ${time} `
                      : `${t('chronoframe.dueDates.tableList.every')} ${renewalRule.count}`}
                  </div>
                )}
              </>
            ) : !index ? (
              '-'
            ) : (
              ''
            )}
          </>
        )
      case 'batchActions':
        const selected = !!(this.state && this.state.selectedTasks && this.state.selectedTasks[data.id])
        return (
          <div
            className="rt-td-cell"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => this.handleSelectionMultipleTask(event, data)}
          >
            <input
              type="checkbox"
              checked={selected}
              readOnly={true}
              className="pt-1 pb-1 pl-1 pr-1"
              style={{ cursor: 'pointer' }}
            />
          </div>
        )
      case 'attachment':
        const attachmentValue = getInputValueFromData(slug, data)
        return attachmentValue && <FontAwesomeIcon icon={faPaperclip} size="sm" />
      case 'target':
        const assets = getInputValueFromData(slug, data)
        const extendedTargets = data && data.extendedTarget
        const returnValue = assets.map((asset: any) => {
          const group = extendedTargets && extendedTargets[asset.group]
          return {
            group: group ? asset.description : null,
            assets: group ? Object.keys(group).map(k => group[k]) : [asset.description],
          }
        })
        if (assets.length > 0) {
          const simpleList =
            returnValue &&
            returnValue
              .map((asset: any) => (asset.group ? [asset.group] : asset.assets))
              .reduce((acc: string[], current: string[]) => acc.concat(current))
              .join(', ') + '.'
          if (returnValue && (returnValue.length > 1 || returnValue[0].assets.length > 1)) {
            return (
              <span className="rt-td-cell">
                <HtmlTooltip
                  position={'left'}
                  content={
                    <>
                      {returnValue.map((asset: any) => (
                        <div className="text-left" key={`assets_${asset.assets.join(',')}`}>
                          {asset.group && (
                            <span>
                              <b>{asset.group}: </b>
                            </span>
                          )}
                          {asset.assets.join(', ') + '.'}
                        </div>
                      ))}
                    </>
                  }
                  target={<span className="text-truncate">{simpleList}</span>}
                />
              </span>
            )
          } else if (simpleList) {
            return <span className="rt-td-cell">{simpleList}</span>
          }
        }
        return <span>-</span>
      case 'note':
        const noteWrapper =
          data[slug] && getInputValueFromData(`${slug}.notes`, data) ? <FontAwesomeIcon icon={faClipboard} /> : ''
        return data[slug] ? (
          <HtmlTooltip
            content={
              data[slug] ? (
                <div style={{ textAlign: 'left' }}>
                  <div>"{getInputValueFromData(`${slug}.notes`, data)}"</div>
                  <div style={{ fontWeight: 600 }}>{getInputValueFromData(`${slug}.user`, data)}</div>
                  <div style={{ fontWeight: 600 }}>
                    {moment(getInputValueFromData(`${slug}.date`, data)).format('DD/MM/YYYY HH:mm')}
                  </div>
                </div>
              ) : (
                ''
              )
            }
            target={noteWrapper}
          />
        ) : (
          <></>
        )
      case 'text-tooltip':
        return data[slug] ? (
          <HtmlTooltip
            content={
              data[slug] ? (
                <div style={{ textAlign: 'left' }}>
                  <div>{getInputValueFromData(slug, data)}</div>
                </div>
              ) : (
                ''
              )
            }
            target={<FontAwesomeIcon icon={faClipboard} />}
          />
        ) : (
          <></>
        )
      case 'detailTitle':
        return TaskTitle({ data, fields: this.props.detailTitle })
      case 'status':
        const statusId = getInputValueFromData(slug, data)
        const dueAtDate = getInputValueFromData('dueAtDate', data)
        const status = (this.state.pageConfig &&
          this.state.pageConfig.values.statuses.find(({ id }) => id === statusId)) || { name: '–' }

        const deadlineDateDiff = moment(dueAtDate).diff(moment().startOf('day'), 'days')
        return (
          <>
            <div className="rt-td-cell">
              <Badge
                type={getBadgeType(statusId)}
                value={t(`chronoframe.status.${status.name.toLowerCase()}`)}
                subvalue={
                  deadlineDateDiff === 0
                    ? t(`chronoframe.dueDates.tableList.today`)
                    : deadlineDateDiff === 1
                    ? t(`chronoframe.dueDates.tableList.tomorrow`)
                    : deadlineDateDiff === -1
                    ? t(`chronoframe.dueDates.tableList.yesterday`)
                    : deadlineDateDiff > 0
                    ? deadlineDateDiff < 10
                      ? `${deadlineDateDiff} ${t(`chronoframe.dueDates.tableList.multipleDays`)}`
                      : deadlineDateDiff < 32
                      ? `${deadlineDateDiff} ${t(`chronoframe.dueDates.tableList.multipleDays`)}`
                      : deadlineDateDiff < 366
                      ? `${Math.floor(deadlineDateDiff / 31)} ${
                          Math.floor(deadlineDateDiff / 31) === 1
                            ? t(`chronoframe.dueDates.tableList.singleMonth`)
                            : t(`chronoframe.dueDates.tableList.multipleMonths`)
                        }`
                      : `${Math.floor(deadlineDateDiff / 365)} ${
                          Math.floor(deadlineDateDiff / 365) === 1
                            ? t(`chronoframe.dueDates.tableList.singleYear`)
                            : t(`chronoframe.dueDates.tableList.multipleYears`)
                        }`
                    : -deadlineDateDiff < 32
                    ? `${-deadlineDateDiff} ${t(`chronoframe.dueDates.tableList.multipleDays`)}`
                    : -deadlineDateDiff < 366
                    ? `${Math.floor(-deadlineDateDiff / 31)} ${
                        Math.floor(-deadlineDateDiff / 31) === 1
                          ? t(`chronoframe.dueDates.tableList.singleMonth`)
                          : t(`chronoframe.dueDates.tableList.multipleMonths`)
                      }`
                    : `${Math.floor(-deadlineDateDiff / 365)} ${
                        Math.floor(-deadlineDateDiff / 365) === 1
                          ? t(`chronoframe.dueDates.tableList.singleYear`)
                          : t(`chronoframe.dueDates.tableList.multipleYears`)
                      }`
                }
              />
            </div>
          </>
        )
      /*case 'assignee':
        const canAssignToMe = this.hasPermission('operational.chronoTask.assignToMe')
        const canAssignTo = this.hasPermission('operational.chronoTask.assignTo')
        const assignee = getInputValueFromData(slug, data)
        const taskId = getInputValueFromData('id', data)
        const currentUser = {
          ...this.props.currentUser,
          displayName: t('chronoframe.dueDates.me'),
        }
        const assignableUsers = canAssignTo ? [currentUser] : canAssignToMe ? [currentUser] : undefined
        const handleTaskAssignment = (assigneeUser: User) =>
          this.props.assignTask(assigneeUser, [taskId]).catch(console.warn) // tslint:disable-line
        return (
          <TaskAssigneeColumn
            assignee={assignee}
            assignable={canAssignTo}
            assignableUsers={assignableUsers}
            onClick={handleTaskAssignment}
            // assigneeLabel={t(`chronoframe.dueDates.tableList.${canAssignToMe ? 'assignToMe' : 'assignTo'}`)}
            assigneeLabel={t('chronoframe.dueDates.tableList.assignTo')}
          />
        )*/
      case 'tableGroupsButton':
        return (
          <TableRowButtonGroup
            buttons={[
              {
                variant: 'secondary-alternate',
                icon: 'eye',
                onClick: () => this.onRowClick(data),
              },
            ]}
          />
        )
      default:
        console.warn('Column type', type, 'not supported') // tslint:disable-line
        return !index ? '---' : ''
    }
  }

  private async handleSearch(search: string | null) {
    const searchString = search ? search : ''
    /*if (!this.state.isFetchingList) {*/
    this.setState(
      {
        search: searchString,
        pagination: { ...this.state.pagination, offset: 0 },
      },
      () => {
        this.props.applyChronoframeListPagination(this.state.pageSlug, this.state.pagination)
        const filters = {
          ...this.getAppliedFilters(),
          searchString,
        }
        this.props.setFiltersPageList(this.state.pageSlug, filters)
        this.fetchData(filters)
      }
    )

    /* }*/
  }

  private handleSubmit(data: FormData) {
    return this.props
      .submitData(data)
      .then(result => {
        this.fetchData(this.getAppliedFilters())
        return result
      })
      .catch((error: FetchError) => {
        throw error
      })
  }

  private getList(): ChronoTask[] {
    const search = this.state.search && this.state.search.toLowerCase()
    const doesStringMatches = (value: any): boolean => {
      let result: boolean | null | '' = null
      try {
        result = typeof value === 'string' && search && value.toLowerCase().includes(search)
        return !!result
      } catch (e) {
        return false
      }
    }
    const slugs =
      this.state.pageConfig &&
      this.state.pageConfig.tableColumns
        .map(column => column.slug)
        .reduce((acc, current) => {
          acc = acc.concat(current)
          return acc
        }, [])
    return search && this.state.data
      ? this.state.data.results.filter(row =>
          Object.keys(row).some(
            (k: any) =>
              slugs &&
              slugs.includes(k) &&
              (doesStringMatches(row[k]) ||
                (row[k] instanceof Object && Object.values(row[k]).some(doesStringMatches)) ||
                (row[k] instanceof Array &&
                  row[k].some(
                    (v: any) =>
                      doesStringMatches(v) || (v instanceof Object && Object.values(v).some(doesStringMatches))
                  )))
          )
        )
      : this.state.data !== undefined && this.state.data.results !== undefined
      ? this.state.data.results
      : []
  }

  private removeFromAppliedFilters = async (filter: any) => {
    const appliedFilters = this.props.appliedFilters && this.props.appliedFilters[this.state.pageSlug]
    if (filter.substring(0, 6) === 'target') {
      const newAppliedFilters = appliedFilters
      if (newAppliedFilters) {
        newAppliedFilters.target = getDataFromObject('target', appliedFilters, [])
        newAppliedFilters.target.splice(filter.split('.')[1], 1)
        if (newAppliedFilters.target && newAppliedFilters.target.length === 0) {
          delete newAppliedFilters.target
        }
        await this.props.updateFilterModal()
        this.fetchData(newAppliedFilters)
      }
    } else if (getDataFromObject('filter', appliedFilters)) {
      const newAppliedFilters = appliedFilters
      if (newAppliedFilters) {
        delete newAppliedFilters[filter]
      }
      await this.props.updateFilterModal()
      this.fetchData(newAppliedFilters)
    } else {
      if (getDataFromObject(filter, appliedFilters.customProperties)) {
        const newAppliedFilters = appliedFilters
        if (newAppliedFilters) {
          delete newAppliedFilters.customProperties[filter]
        }
        await this.props.updateFilterModal()
        this.fetchData(newAppliedFilters)
      }
      if (getDataFromObject(filter, appliedFilters)) {
        const newAppliedFilters = appliedFilters
        if (newAppliedFilters) {
          delete newAppliedFilters[filter]
        }
        await this.props.updateFilterModal()
        this.fetchData(newAppliedFilters)
      }
    }
  }

  private async handleApplyFilters(filters?: FormData) {
    const validFilters = filters
    if (validFilters && validFilters.target) {
      const validTarget = validFilters.target.filter((target: any) => target.group)
      validFilters.target = validTarget.length > 0 ? validTarget : null
    }

    const activeFilters = validFilters
      ? Object.keys(validFilters).reduce((acc: FormData, key: string) => {
          if (validFilters[key] != null) {
            acc[key] = validFilters[key]
          }
          return acc
        }, {})
      : {}

    this.setState(
      {
        pagination: { ...this.state.pagination, offset: 0 },
      },
      () => {
        this.props.applyChronoframeListPagination(this.state.pageSlug, this.state.pagination)
        this.props.setFiltersPageList(this.state.pageSlug, {
          ...activeFilters,
        })
        this.fetchData(activeFilters)
      }
    )

    await this.props.updateFilterModal()
    return activeFilters
  }

  private getPageSlug() {
    return window.location.pathname.split('/')[2] // /scadenziario/slug/:id
  }

  private getModule() {
    return window.location.pathname.split('/')[1] // /scadenziario/slug/:id
  }

  private getAppliedFilters() {
    return this.props.appliedFilters ? this.props.appliedFilters[this.state.pageSlug] : undefined
  }

  private async onRowClick(data: ChronoTask) {
    await this.props.updateFilteredIds(getDataFromObject('props.data', this.tableState, []).map((row: any) => row.id))
    await this.props.history.push(`${window.location.pathname}/${data.id}`)
  }

  private onSortingChange(sorting: string[]) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          sort: sorting[0],
        },
      },
      () => {
        this.props.applyChronoframeListPagination(this.state.pageSlug, this.state.pagination)
        this.fetchData(this.getAppliedFilters())
      }
    )
  }

  private onPageSizeChange(pageSize: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          limit: pageSize,
          offset: 0,
        },
      },
      () => {
        this.props.applyChronoframeListPagination(this.state.pageSlug, this.state.pagination)
        this.fetchData(this.getAppliedFilters())
      }
    )
  }

  private onPageChange(pageNumber: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          offset: this.state.pagination !== undefined ? this.state.pagination.limit * pageNumber : 0,
        },
      },
      () => {
        this.props.applyChronoframeListPagination(this.state.pageSlug, this.state.pagination)
        this.fetchData(this.getAppliedFilters())
      }
    )
  }

  private hasPermission(...actions: string[]) {
    // TEMPORARY!!!
    const permittedActions =
      this.state.pageSlug === 'manager'
        ? [
            'scadenziario.manager.chronoTask.create',
            'scadenziario.manager.chronoTask.edit',
            'scadenziario.manager.chronoTask.reject',
            'scadenziario.manager.chronoTask.cancel',
          ]
        : [
            'scadenziario.operational.chronoTask.assignToMe',
            'scadenziario.operational.chronoTask.assignTo',
            'scadenziario.operational.chronoTask.reschedule',
            // 'scadenziario.operational.chronoTask.done',
            'scadenziario.operational.chronoTask.validated',
          ]
    return actions.some(action => permittedActions.includes(`scadenziario.${action}`))
    // return !actions.some(action => this.props.forbiddenActions.includes(`scadenziario.${action}`))
  }

  private async exportData() {
    this.setState({
      isExporting: true,
    })
    try {
      await this.props.exportData(this.state.pageSlug, this.getAppliedFilters())
    } catch (e) {
      console.log(e) //tslint:disable-line
    } finally {
      this.setState({ isExporting: false })
    }
  }

  private handleSelectionMultipleTask(event: React.MouseEvent<HTMLDivElement>, data: ChronoTask) {
    event.stopPropagation()
    const { selectedTasks } = this.state
    if (selectedTasks[data.id]) {
      delete selectedTasks[data.id]
    } else {
      selectedTasks[data.id] = {
        status: data.status,
        lastActionId: data.lastActionId,
        rolesWithValidation: data.rolesWithValidation || [],
        availableActions: data.availableActions,
      }
    }
    this.setState({ selectedTasks })
  }

  private selectAllPageTasks() {
    const { data } = this.state
    if (data && data.results) {
      const { selectedTasks } = this.state
      const initialIndex = 0
      const endIndex = data.results.length
      const list = data.results.slice(initialIndex, endIndex)
      const remove = list.every(task => selectedTasks[task.id] !== undefined)
      list.forEach(task => {
        if (remove) {
          delete selectedTasks[task.id]
        } else {
          selectedTasks[task.id] = {
            status: task.status,
            rolesWithValidation: task.rolesWithValidation || [],
            lastActionId: task.lastActionId,
            availableActions: task.availableActions,
          }
        }
      })
      this.setState({ selectedTasks })
    }
  }

  private submitMultipleTasks(data: FormData) {
    const { currentAction, selectedTasks } = this.state
    if (currentAction === 'done' && selectedTasks) {
      const doneData: DoneBatchForm[] = Object.keys(selectedTasks).reduce((acc: DoneBatchForm[], key) => {
        acc.push({
          chronoTaskId: key,
          lastActionId: selectedTasks[key].lastActionId,
          notes: data.notes || null,
          date: data.date,
        })
        return acc
      }, [])
      return this.props.changeStatusMultiple(currentAction.split('-')[0], doneData).then(() => {
        showNotification({
          type: 'success',
          message: this.props.t('chronoframe.tasks.actions.successBatchActionText'),
          title: this.props.t('chronoframe.tasks.actions.successBatchActionTitle'),
        })
        if (this.state.pageConfig) {
          this.props.updateSidebarCounters(this.state.pageConfig.counters)
        }
      })
    } else if (currentAction === 'validate' && selectedTasks) {
      const validatData: ValidateBatchForm[] = Object.keys(selectedTasks).reduce((acc: ValidateBatchForm[], key) => {
        acc.push({
          chronoTaskId: key,
          lastActionId: selectedTasks[key].lastActionId,
          notes: data.notes || null,
          date: data.date,
        })
        return acc
      }, [])
      return this.props.changeStatusMultiple(currentAction.split('-')[0], validatData).then(() => {
        showNotification({
          type: 'success',
          message: this.props.t('chronoframe.tasks.actions.successBatchActionText'),
          title: this.props.t('chronoframe.tasks.actions.successBatchActionTitle'),
        })
        if (this.state.pageConfig) {
          this.props.updateSidebarCounters(this.state.pageConfig.counters)
        }
      })
    } else if(currentAction === 'reschedule' && selectedTasks) {
      const rescheduleData: RescheduleBatchForm[] = Object.keys(selectedTasks).reduce((acc: RescheduleBatchForm[], key) => {
        acc.push({
          chronoTaskId: key,
          lastActionId: selectedTasks[key].lastActionId,
          notes: data.notes || null,
          date: data.date
        })
        return acc
      }, [])
      return this.props.changeStatusMultiple(currentAction.split('-')[0], rescheduleData).then(() => {
        showNotification({
          type: 'success',
          message: this.props.t('chronoframe.tasks.actions.successBatchActionText'),
          title: this.props.t('chronoframe.tasks.actions.successBatchActionTitle'),
        })
        if (this.state.pageConfig) {
          this.props.updateSidebarCounters(this.state.pageConfig.counters)
        }
      })
    }
    return Promise.reject()
  }

  private handleCloseTaskActionModal(update?: boolean) {
    // TODO TEMP, because some callbacks are passing events as value
    if (update === true) {
      this.fetchData(this.getAppliedFilters())
    }
    this.setState({ currentAction: undefined, showActionModal: false })
  }

  public render() {
    const t = this.props.t
    const appliedFilters = (this.props.appliedFilters && this.props.appliedFilters[this.state.pageSlug]) || undefined
    const {
      pageSlug,
      pageConfig,
      isFetchingList,
      isFetchingConfig,
      fetchErrorsConfig,
      fetchErrorsList,
      selectedTasks,
      data,
      pagination,
      module,
    } = this.state
    const { showFormModal, tableColumns, isExporting } = this.state
    const listData = this.getList()

    const pages = data ? Math.ceil(data.metadata.resultset.count / data.metadata.resultset.limit) : null

    const canCreate = pageConfig && pageConfig.values && this.hasPermission('manager.chronoTask.create')
    const initialData = {
      effectiveFromDate: moment().format('YYYY-MM-DD'),
    }

    const allowBatchActions = pageConfig && pageConfig.batchActions && pageConfig.batchActions.length > 0

    const assetsList: any =
      pageConfig &&
      pageConfig.tableAdvancedFilters &&
      getDataFromObject('[1].fields[0].fields[0].fields[1].options', pageConfig.tableAdvancedFilters, [])

    const assetsGroupList: any =
      pageConfig &&
      pageConfig.tableAdvancedFilters &&
      getDataFromObject('[1].fields[0].fields[0].fields[0].options', pageConfig.tableAdvancedFilters, [])

    const availableBatchActions: DropdownAction[] = []

    pageConfig?.batchActions.forEach(action => {
      if (Object.keys(selectedTasks).every(key => selectedTasks[key].availableActions.includes(action))) {
        availableBatchActions.push({
          label: t(`chronoframe.tasks.actions.${action}-batch`),
          onClick: () => this.setState({ showActionModal: true, currentAction: action }),
        })
      }
    })

    const showData = !isFetchingConfig && !fetchErrorsConfig && pageConfig

    return (
      <div className="mv4iot-fe-chrono-frame" style={{ position: 'relative' }}>
        {showData && (
          <TaskFormModal
            visible={showFormModal}
            title={t('chronoframe.dueDates.newDueDates')}
            data={initialData}
            fields={pageConfig!.formFields}
            onClose={this.toggleFormModal}
            onSubmit={this.handleSubmit}
            submitTemplate={formData => this.props.submitTemplate(formData, pageConfig!.values)}
            pageConfigUpdateData={this.refreshPageConfigData}
            type={'create'}
          />
        )}
        <PageHeader
          title={t(`chronoframe.pageSlug.${module}.${pageSlug}`)}
          rightButtons={
            <ButtonGroupHeader>
              <DownloadCsvButton
                disabled={isFetchingConfig || isFetchingList || fetchErrorsConfig || fetchErrorsList}
                isLoading={isExporting}
                label={t('chronoframe.dueDates.exportData')}
                onClick={this.exportData}
              />
              {canCreate && (
                <Button
                  variant="primary"
                  disabled={isFetchingConfig || isFetchingList || fetchErrorsConfig || fetchErrorsList}
                  onClick={() => this.toggleFormModal()}
                  label={t('chronoframe.dueDates.createNew')}
                />
              )}
            </ButtonGroupHeader>
          }
        />
        {isFetchingConfig && <Loader />}
        {showData && (
          <>
            <div className="content">
              <TaskListFilters
                appliedFilters={appliedFilters}
                onSearch={this.handleSearch}
                onSubmit={this.handleApplyFilters}
                simpleFiltersFields={pageConfig!.tableFilters} // inizio, fine, status
                advancedFiltersFields={pageConfig!.tableAdvancedFilters}
                savedSearchString={appliedFilters && appliedFilters.searchString}
                currentAppliedFilter={pageSlug}
                lastAppliedFilter={this.props.lastAppliedFilter}
                pageConfigUpdateData={this.refreshPageConfigData}
                updateFilterModalValue={this.props.updateFilterModalValue}
              />
              {appliedFilters && (
                <div className="mb-2">
                  {Object.keys(appliedFilters)
                    .filter(
                      filter =>
                        filter !== 'pageSlug' &&
                        filter !== 'statusArray' &&
                        filter !== 'startDueAtDate' &&
                        filter !== 'searchString' &&
                        filter !== 'endDueAtDate'
                    )
                    .map((filter, index) => {
                      if (Object.prototype.toString.call(appliedFilters[filter]) === '[object Object]') {
                        // CUSTOMPROPERTIES
                        return Object.keys(appliedFilters[filter]).map((customFilter, i) => {
                          return (
                            <span className="mx-2" key={i}>
                              <div className="btn-group btn-group-sm my-1" role="group" aria-label="tile">
                                <button
                                  type="button"
                                  className="btn btn-secondary disabled-button"
                                  style={{
                                    borderRight: '0px',
                                    cursor: 'initial',
                                  }}
                                >
                                  {t(
                                    `chronoframe.dueDates.tableList.customProperties.${customFilter.replace(
                                      'Array',
                                      ''
                                    )}`
                                  ) +
                                    ': ' +
                                    appliedFilters[filter][customFilter]}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  style={{ borderLeft: '0px' }}
                                  onClick={() => this.removeFromAppliedFilters(customFilter)}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                            </span>
                          )
                        })
                      } else {
                        // TARGETS
                        if (Object.prototype.toString.call(appliedFilters[filter][0]) === '[object Object]') {
                          return appliedFilters[filter].map((target: Target, j: number) => {
                            if (target.asset && target.asset.length > 0) {
                              return (
                                <span className="mx-2" key={j}>
                                  <div className="btn-group btn-group-sm my-1" role="group" aria-label="tile">
                                    <button
                                      type="button"
                                      className="btn btn-secondary disabled-button"
                                      style={{
                                        borderRight: '0px',
                                        cursor: 'initial',
                                      }}
                                    >
                                      {getDataFromObject(
                                        'label',
                                        assetsList.find((asset: any) => asset.value === target.asset),
                                        'Cespite'
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      style={{ borderLeft: '0px' }}
                                      onClick={() => this.removeFromAppliedFilters(filter + '.' + j)}
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                  </div>
                                </span>
                              )
                            } else {
                              return (
                                <span className="mx-2" key={j}>
                                  <div className="btn-group btn-group-sm my-1" role="group" aria-label="tile">
                                    <button
                                      type="button"
                                      className="btn btn-secondary disabled-button"
                                      style={{
                                        borderRight: '0px',
                                        cursor: 'initial',
                                      }}
                                    >
                                      {getDataFromObject(
                                        'label',
                                        assetsGroupList.find((asset: any) => asset.value === target.group),
                                        'Gruppo Cespite'
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      style={{ borderLeft: '0px' }}
                                      onClick={() => this.removeFromAppliedFilters(filter + '.' + j)}
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                  </div>
                                </span>
                              )
                            }
                          })
                        } else {
                          // DESTINATIONARRAY etc.
                          return (
                            <span className="mx-2" key={index}>
                              <div className="btn-group btn-group-sm my-1" role="group" aria-label="tile">
                                <button
                                  type="button"
                                  className="btn btn-secondary disabled-button"
                                  style={{
                                    borderRight: '0px',
                                    cursor: 'initial',
                                  }}
                                >
                                  {t(`chronoframe.dueDates.formField.${filter.replace('Array', '')}`) +
                                    ': ' +
                                    appliedFilters[filter][0]}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  style={{ borderLeft: '0px' }}
                                  onClick={() => this.removeFromAppliedFilters(filter)}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                            </span>
                          )
                        }
                      }
                    })}
                </div>
              )}

              <Table
                data={listData}
                pages={pages ? pages : 0}
                isFetching={isFetchingList}
                onPageChange={this.onPageChange}
                onPageSizeChange={this.onPageSizeChange}
                pageSize={pagination ? pagination.limit : 50}
                onResizedChange={this.handleResizeColumn}
                page={pagination ? pagination.offset / pagination.limit : 0}
                ref={(r: any) => (this.tableState = r)}
                showPaginationTop={true}
                manual={!!this.state.pagination}
                onSortingChange={this.onSortingChange}
                columns={tableColumns}
                noDataText={t(`chronoframe.tasks.table.${this.state.search ? 'noItemFound' : 'noData'}`)}
                pageText={t('chronoframe.tasks.table.page')}
                ofText={t('chronoframe.tasks.table.of')}
                rowsText={t('chronoframe.tasks.table.rows')}
                resized={this.props.resized}
                leftComponent={
                  <ActionDropdown
                    actionsText={t('chronoframe.tasks.batchActions.actions')}
                    ofText={t('chronoframe.tasks.table.of')}
                    rowsText={t('chronoframe.tasks.table.rows')}
                    tooltipContent={t(
                      `chronoframe.tasks.batchActions.${
                        Object.keys(selectedTasks).length === 0 ? 'selectTask' : 'noActionAvailable'
                      }`
                    )}
                    tooltipPosition="right"
                    items={listData.length}
                    variant="primary"
                    disableLight={true}
                    disabled={availableBatchActions.length === 0 || !allowBatchActions}
                    selectedItems={Object.keys(selectedTasks).length}
                    actions={availableBatchActions}
                  />
                }
                currentAppliedFilter={pageSlug}
                lastAppliedFilter={this.props.lastAppliedFilter}
                lastSearchString={this.state.search || ''}
              />

              {this.state.showActionModal && this.state.currentAction && (
                <TaskActionModal
                  visible={true}
                  action={this.state.currentAction}
                  onClose={this.handleCloseTaskActionModal}
                  onSubmit={this.submitMultipleTasks}
                />
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskListPageView)))
