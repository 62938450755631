// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../../Icon/Icon'

interface Props {
  baseClassName: string
  isRoot?: boolean
  hasChildren?: boolean
  isOpened?: boolean
  onClick?: () => void
}

const NodeIcon = (props: Props) => {
  const { isRoot, isOpened, onClick, baseClassName, hasChildren } = props
  if (!isRoot && !hasChildren) {
    return null
  }
  if (isRoot) {
    return (
      <div
        className={`${baseClassName}__home`}
      >
        <IconComponent
          size={'sm'}
          icon={'home'}
          onClick={hasChildren ? onClick : undefined}
          semantic={hasChildren ? '' : 'secondary'}
        />
      </div>
    )
  }
  return (
    <div className={`${baseClassName}__toggle`}>
      <IconComponent
        size={'lg'}
        icon={isOpened ? 'caret-square-up' : ['regular', 'caret-square-down']}
        onClick={onClick}
      />
    </div>
  )
}

export default NodeIcon
