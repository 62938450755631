import { Icon } from '../../inplant-components-fe/services/icon'
import PlantAnalysis from './components/views/PlantAnalysis/PlantAnalysis'
import PlantAnalysisGeneral from './components/views/PlantAnalysisGeneral/PlantAnalysisGeneralPageView'
import DecicatedGraphPageView from './components/views/DedicatedGraph/DecicatedGraphPageView'
import PlantAnalysisGeneralPrint from './components/views/PlantAnalysisGeneral/PlantAnalysisGeneralPrintPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  i18nkey?: string
  icon?: Icon
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
  counter?: object;
}

const routes: RouteProps = {
  path: '/plant-analysis',
  i18nkey: 'plantAnalysis.navigation.plantAnalysis',
  icon: 'chart-area',
  visible: true,
  children: [
    {
      path: '/plant-analysis',
      exact: true,
      i18nkey: 'plantAnalysis.navigation.plantAnalysisList',
      component: PlantAnalysis,
      visible: true,
    },
    {
      path: '/plant-analysis/list',
      exact: true,
      i18nkey: 'plantAnalysis.navigation.plantAnalysisGeneral',
      component: PlantAnalysisGeneral,
      visible: true,
    },
    {
      path: '/plant-analysis/dedicated-graph',
      exact: true,
      i18nkey: 'plantAnalysis.navigation.dedicatedGraph',
      component: DecicatedGraphPageView,
      visible: false
    },
    {
      path: '/plant-analysis/list-print',
      exact: true,
      i18nkey: 'plantAnalysis.navigation.plantAnalysisGeneral',
      component: PlantAnalysisGeneralPrint,
      visible: false,
    },
  ],
}

export const routeOnlyGeneral: RouteProps = {
  path: '/plant-analysis/list',
  i18nkey: 'plantAnalysis.navigation.plantAnalysis',
  icon: 'chart-area',
  visible: true,
  children: [
    {
      path: '/plant-analysis/list',
      exact: true,
      i18nkey: 'plantAnalysis.navigation.plantAnalysisGeneral',
      component: PlantAnalysisGeneral,
      visible: true,
    },
  ],
}

export default routes
