import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FetchAssetsListResponse,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import {
  AssetsListElement,
  AssetsListFilters,
  CostCenter,
  ListPagination,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {
  fetchAssetsList,
  fetchSitesGroupsModules,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import AssetsTable from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetsTable/AssetsTable'

interface OwnState {
  assetsList?: FetchAssetsListResponse
  isFetching: boolean
  fetchErrors: boolean
  filters: AssetsListFilters
  pagination: ListPagination
  plants: Array<{ code: string, name: string }>
  costCenters: Array<{ plant: string, costCenters: CostCenter[] }>
}

interface StateProps {
  filters: AssetsListFilters
  pagination: ListPagination
  handleRowAssetClick?: (data: AssetsListElement) => void
}

type Props = WithTranslation & StateProps

class CopyTasksAssetsList extends React.Component<Props, OwnState> {

  private abortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = {
      assetsList: undefined,
      isFetching: false,
      fetchErrors: false,
      filters: {
        ...this.props.filters,
      },
      pagination: {
        ...this.props.pagination,
      },
      plants: [],
      costCenters: [],
    }
    this.applyFiltersTable = this.applyFiltersTable.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSortingChange = this.onSortingChange.bind(this)
    this.fetchAssetsList = this.fetchAssetsList.bind(this)
  }

  public componentDidMount() {
    fetchSitesGroupsModules()
      .then((sitesData) => {
        const plantsArray: Array<{ code: string, name: string }> = sitesData.sites.reduce((acc: Array<{ code: string, name: string }>, sites) => {
          acc = acc.concat(sites.plants.reduce((plants: Array<{ code: string, name: string }>, plant) => {
            plants.push({
              code: plant.code,
              name: plant.name,
            })
            return plants
          }, []))
          return acc
        }, [])
        const costCenters: Array<{ plant: string, costCenters: CostCenter[] }> = sitesData.sites.reduce((acc: Array<{ plant: string, costCenters: CostCenter[] }>, site) => {
          return acc.concat(site.plants.reduce((acc2: Array<{ plant: string, costCenters: CostCenter[] }>, plant) => {
            acc2.push({
              plant: plant.code,
              costCenters: plant.costCenters,
            })
            return acc2
          }, []))
        }, [])
        this.setState({
          plants: plantsArray,
          costCenters,
        })
      })
      .catch((error: FetchError) => {
        console.log(error) //tslint:disable-line
      })
    this.fetchAssetsList()
  }

  public render() {
    const { isFetching, assetsList, filters, pagination, costCenters, plants } = this.state
    const pages = assetsList ? Math.ceil(assetsList.metadata.count / assetsList.metadata.pageSize) : null

    return (
      <AssetsTable
        data={!isFetching && assetsList ? assetsList.result : []}
        onRowClick={(data) => {
          if (this.props.handleRowAssetClick) {
            this.props.handleRowAssetClick(data)
          }
        }}
        pages={pages ? pages : 0}
        assetStatus={['active', 'deactivation-to-validate', 'creation-to-validate']}
        filters={filters}
        isFetching={isFetching}
        onPageChange={this.onPageChange}
        onPageSizeChange={this.onPageSizeChange}
        pagination={pagination}
        updateFilters={this.applyFiltersTable}
        hiddenCols={['select', 'timeActive']}
        plants={plants}
        costCenters={costCenters}
        manual={!!this.state.pagination}
        onSortingChange={this.onSortingChange}
        sortableCols={['status', 'assetCode', 'positionCode', 'assetDescription', 'costCenter.name', 'activatedOn']}
      />
    )
  }

  private applyFiltersTable(filters: AssetsListFilters) {
    if (!this.state.isFetching) {
      this.setState({
        filters, pagination: {
          ...this.state.pagination,
          pageNumber: 0,
        },
      }, this.fetchAssetsList)
    }
  }

  private async fetchAssetsList() {
    if (this.state.isFetching) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }
    this.setState({ isFetching: true, fetchErrors: false })
    fetchAssetsList('list-active', this.abortController, this.state.filters, this.state.pagination)
      .then((data) => {
        this.setState({
          isFetching: false,
          assetsList: {
            ...data,
            metadata: {
              ...data.metadata,
              pageNumber: data.metadata.pageNumber - 1,
            },
          },
          pagination: {
            ...this.state.pagination,
            pageSize: data.metadata.pageSize,
            pageNumber: data.metadata.pageNumber - 1,
            sortField: data.metadata.sortField,
            sortDirection: data.metadata.sortDirection,
          },
        })
      })
      .catch((error: FetchError) => {
        if (!(error.name && error.name === 'AbortError')) {
          this.setState({ fetchErrors: true, isFetching: false })
        }
      })


  }

  private onPageSizeChange(pageSize: number) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageSize,
      },
    }, this.fetchAssetsList)
  }

  private onPageChange(pageNumber: number) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageNumber: pageNumber + 1,
      },
    }, this.fetchAssetsList)
  }

  private onSortingChange(sortField: string, sortDirection: string) {
    if (!this.state.isFetching && (this.state.pagination.sortDirection !== sortDirection || this.state.pagination.sortField !== sortField)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          sortField,
          sortDirection,
        },
      }, this.fetchAssetsList)
    }
  }
}

export default (withTranslation()(CopyTasksAssetsList))