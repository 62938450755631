const en = {
  auth: {
    username: {
      label: 'Username',
      placeholder: 'Enter your username...',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password...',
    },
    submitText: 'Accedi',
    logout: 'Logout',
  },
  api: {
    errors: {
      error: 'API Error',
      error_400: 'The server cannot or will not process the request due to an apparent client error',
      error_401: 'The user does not have the necessary credentials',
      error_403: 'The user might not have the necessary permissions for accessing the resource',
      error_404: 'The requested resource could not be found',
      error_405: 'The request method is not supported for the requested resource',
      error_406: 'Validation error',
      error_415: 'Unsupported Media Type',
      error_422: 'The request is unprocessable',
      error_500: 'The server failed to fulfil the request',
    },
  },
  plantSelectorWidget: {
    options: {
      disabled: 'Module is disabled for this plant',
      null: 'Select plant...',
    },
    plantChangedToAllowed:
      "I dati per l'impianto di {{fromPlant}} non sono disponibili.\nVengono visualizzati i dati dell'impianto di {{toPlant}}",
  },
  notification: {
    alert: 'Alert',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
    info: 'Info',
    information: 'Info',
  },
}

export default en
