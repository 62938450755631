// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Step from "./Step";

interface Props {
  steps: Array<{
    label: string,
    cliccable: boolean
  }>
  curranteStep: string | number,
  onclick?: (id: number) => void
}

class Steps extends React.Component<Props>{
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className="row step">
        {this.props.steps.map((step, index) => (
          <Step
            label={step.label}
            cliccable={step.cliccable}
            index={index+1}
            onclick={this.props.onclick}
            showEndLine={index!==(this.props.steps.length-1)}
            active={(index+1)<=this.props.curranteStep}
            key={`step_${index}`}
          />
        ))}
      </div>
    )
  }
}

export default Steps