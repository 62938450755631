// * -------------------------------- MODULE --------------------------------------
import { Color } from '../Utils/utils'

export const renderBackgroundColor = (color: Color): string => {
  return `bg-${color}`
}

export const renderBorderColor = (color: Color): string => {
  return `border-${color}`
}
