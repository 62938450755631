import React, { useEffect } from "react"
import { useState } from "react"

interface Prosp {
  sideBarToggle: (open:boolean) => void
  isOpen: boolean
}
const SideBarOverlay = (props: Prosp) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setIsOpen(false)
      },300)
    }
  })

  return (
    <div
        className={`sidebar-overlay${isOpen ? ' is-open' : ''}`}
        onClick={() => props.sideBarToggle(false)}
      />
  )
}

export default SideBarOverlay