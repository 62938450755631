import store from '../../../store/configureStore'
import * as moment from 'moment'

export const getWorkshiftTotalTimes = (workshifts: Array<[string, number]>) => {
  const state = store.getState()
  const {dateFilterEnd} =  state.plantAnalysis.common

  let rangeStarted: boolean = false
  let rangeEnded: boolean = false
  let currentW: number
  let currentT: moment.Moment
  const w: {[k: number]: number} = {}

  const dayAfterRange = moment(dateFilterEnd).endOf('day').utc()

  const addWorkshift = (ws: [string, number]) => {
    if(!w.hasOwnProperty(currentW)) {
      w[currentW] = 0
    }

    w[currentW] += moment(ws[0]).diff(currentT)

    currentT = moment(ws[0])
    currentW = ws[1]
  }

  workshifts.forEach((t, index) => {
    if(!rangeEnded) {
      if (!rangeStarted && t[1] === 1) {
        // first workshift of first day, start counting
        currentT = moment(t[0])
        currentW = t[1]

        rangeStarted = true
      } else if (
        t[1] === 1 &&
        moment(t[0]).isAfter(dayAfterRange)
      ) {
        // workshift number 1 outside selected range, stop
        rangeEnded = true
      } else if(rangeStarted) {
        if(t[1] !== currentW) {
          // workshift changed to another workshift or pause, count
          addWorkshift(t)
        }
      }
    }
  })

  return w
}
