import { Reducer } from 'redux'
import {
  CHANGE_ASSETS_SUCCESS,
  THEME_MANAGER_IS_LOADING,
  THEME_MANAGER_SUCCESS,
  ThemeManagerActions,
} from '../actions/branding'
import { Theme } from '../../types/theme'
import { IAssets, defaultIAssets } from '../../types/assets'

export interface BrandingManagerStore {
  theme?: Theme
  assets: IAssets
  status: 'loading' | 'success'
}

export const themeManagerInitialState: BrandingManagerStore = {
  assets: {
    favicon: defaultIAssets.favicon,
    logoLogin: defaultIAssets.logoLogin,
    logoTopBar: defaultIAssets.logoTopBar,
    slug: defaultIAssets.slug,
  },
  status: 'success',
}

export const brandingManager: Reducer<BrandingManagerStore, ThemeManagerActions> = (
  state: BrandingManagerStore = themeManagerInitialState,
  action: ThemeManagerActions
) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      if (action.payload) {
        return {
          ...state,
          theme: action.payload?.core?.brandingManager?.theme || state.theme,
          assets: action.payload?.core?.brandingManager?.assets || state.assets,
        }
      } else {
        return {
          ...state,
        }
      }
    case THEME_MANAGER_SUCCESS:
      return {
        ...state,
        theme: action.payload,
        status: 'success',
      }
    case THEME_MANAGER_IS_LOADING:
      return {
        ...state,
        status: 'loading',
      }
    case CHANGE_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload,
      }
    default:
      return state
  }
}
