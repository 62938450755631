import * as React from 'react'
import { connect } from 'react-redux'
import { PlantStatus, StatusPage } from '../../../../redux/reducers/plantStatus'
import { Dispatch } from 'redux'
import { fetchInplantsStatus } from '../../../../redux/actions/plantStatus'
import { Table, PageHeader, Tooltip } from '@mv-submodules/inplant-components-fe'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Column } from 'react-table'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers'

const mapStateToProps = ({ plantStatus }: any): StatusPage => {
  console.log('mapStateToProps', plantStatus) // tslint:disable-line
  return {
    isFetching: plantStatus.isFetching,
    data: plantStatus.data,
    error: plantStatus.error,
  }
}

interface DispatchProps {
  fetchInplantsStatus: () => Promise<PlantStatus[]>
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchInplantsStatus: () => fetchInplantsStatus()(dispatch),
})

type Props = DispatchProps & StatusPage & WithTranslation

class StatusPageView extends React.Component<Props, {}> {
  private intervalId: any

  private columns: Column[] = [
    {
      Header: this.props.t(`status.columns.plantName`),
      accessor: 'plantName',
      Cell: row => <span style={{ fontWeight: 600 }}>{row.value.toUpperCase()}</span>,
    },
    {
      Header: this.props.t(`status.columns.status`),
      accessor: 'status',
      Cell: row => {
        let backgroundColor
        switch (row.value) {
          case 'ToDo':
          case 'slow':
            backgroundColor = 'orange'
            break
          case 'unstable':
            backgroundColor = ' Gold'
            break
          case 'optimal':
            backgroundColor = ' MediumSeaGreen'
            break
          case 'sufficient':
            backgroundColor = ' LightSeaGreen'
            break
          case 'offline':
            backgroundColor = ' black'
            break
          default:
            backgroundColor = ' Gray'
            break
        }
        return (
          <span className="badge badge-info task-status rt-td" style={{ backgroundColor }}>
            {this.props.t(`status.statuses.${row.value}`)}
          </span>
        )
      },
    },
    {
      Header: this.props.t(`status.columns.lastConnection`),
      accessor: 'lastConnection',
      Cell: row => {
        return (
          <span style={{ color: row.row.status === 'offline' ? 'red' : 'black' }}>
            {mvDate.getDateFromStringWithFormatting(row.value, 'dd/MM/yyyy - HH:mm')}
          </span>
        )
      },
    },
    {
      Header: this.props.t(`status.columns.latency`),
      accessor: 'latency',
      Cell: row => {
        const targetComponent = <>{`${Math.ceil(row.value)} ms`}</>
        return (
          <Tooltip
            content={`${this.props.t(`status.packageLoss`)}${row.original.packetLoss}%`}
            target={targetComponent}
          />
        )
      },
    },
  ]

  public componentDidMount() {
    this.props.fetchInplantsStatus()
    this.refreshData()
  }

  private refreshData = async () => {
    this.intervalId = setInterval(this.props.fetchInplantsStatus, 60000)
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  public render() {
    console.log('props', this.props) // tslint:disable-line
    return (
      <>
        <PageHeader title={this.props.t(`status.title`)} />
        <div className="content mt-2 status-table inplant-plantstatus">
          <Table
            data={this.props.data}
            columns={this.columns}
            isFetching={this.props.isFetching}
            manualIsFetching={true}
            noDataText={this.props.t(`status.noDataText`)}
            ofText={this.props.t(`status.ofText`)}
            rowsText={this.props.t(`status.rowsText`)}
            pageSize={100}
          />
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StatusPageView))
