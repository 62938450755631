import {
  InplantStatusActions, STATUS_FETCHING_COMPLETE, STATUS_FETCHING_ERROR,
  STATUS_FETCHING_LOADING,
} from '@mv-submodules/inplant-plantstatus-fe/redux/actions/plantStatus'

export interface StatusPage {
  isFetching: boolean
  data: PlantStatus[]
  error?: Error
}

export interface PlantStatus {
  id: number
  plantCode: string
  plantName: string
  packetLoss: number
  latency: number
  lastConnection: string
  status: string
}

const initialState: StatusPage = {
  isFetching: false,
  data: [],
}

type PlantStatusReducer = (
  state: StatusPage,
  action: InplantStatusActions,
) => StatusPage

export const plantStatus:PlantStatusReducer = (state: StatusPage = initialState, action: InplantStatusActions) => {
  switch (action.type) {
    case STATUS_FETCHING_LOADING:
      return {
        ...state,
        isFetching: true,
      }
    case STATUS_FETCHING_COMPLETE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      }
    case STATUS_FETCHING_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
