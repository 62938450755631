import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { isJSON, Loader } from '../../../../functions/shared'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  generateElementsArrayForTableRowLayout, generateTableRowFromArrayOfElements,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/generateTableRowLayout'
import {
  TableRowFullWrapperWithCondition
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'

interface OwnState {
  isFetching: boolean,
  fetchErrors: boolean
  data?: InfluxResponse
}

interface MotorAbsorptionType {
  name: string
  thresold: number
  totalTimeOverThresold: number
  numberOfIntervalsOverThresold: number
  maximumDurationInterval: number
}

interface InfluxResponse {
  time: string[]
  dummy: number[]
  measure: { [k: string]: MotorAbsorptionType }
}

interface StateProps {
  dateEnd: string
  dateStart: string
  plant: any | null
}

const cleanState = {
  isFetching: false,
  fetchErrors: false,
}

type Props = StateProps & WithTranslation

class MotorAbsorptionView extends React.Component<Props, OwnState> {
  // @ts-ignore
  private mounted: boolean
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = cleanState
    this.getData = this.getData.bind(this)
    this.convertTime = this.convertTime.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.state.isFetching &&
      nextProps &&
      this.props &&
      !nextProps.plant.isLoading &&
      (nextProps.plant.plant !== this.props.plant.plant ||
        (nextProps.dateStart && nextProps.dateStart !== this.props.dateStart) ||
        (nextProps.dateEnd && nextProps.dateEnd !== this.props.dateEnd)
      )
    ) {
      this.setState(cleanState, () => this.getData(this.props))
    }
  }

  public render() {
    const { isFetching, fetchErrors, data } = this.state
    const { t } = this.props
    return (
      <>
        <TableRowFullWrapperWithCondition condition={isFetching}>
          <div
            className='alert alert-secondary w-100 mx-auto motorAbsorption-graph-fetch-loading alert-local'>
            {t('plantAnalysis.loading')}
            <Loader />
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={!isFetching && !data}>
          <div className='alert alert-warning w-100 mx-auto motorAbsorption-graph-fetch-warning alert-local'>
            {t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={!isFetching && fetchErrors}>
          <div className='alert alert-danger w-100 mx-auto motorAbsorption-graph-fetch-error alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>
        <WithConditionWrapper
          condition={!isFetching && !fetchErrors}>
          {this.generateLayout()}
        </WithConditionWrapper>
      </>
    )
  }

  private generateLayout() {
    if (this.state.data && this.state.data.measure) {
      const elements = Object.keys(this.state.data.measure).sort().map((name: string) => this.generateSingleElement((this.state.data?.measure as { [p: string]: MotorAbsorptionType }), name))
      return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 6))
    }
  }

  private generateSingleElement(measures: { [k: string]: MotorAbsorptionType }, name: string) {
    const measure = measures[name]
    const hasProblems = measure.maximumDurationInterval && measure.maximumDurationInterval >= 300
    return (
      <TableColumnWrapper className={'single-motor-absorption text-center p-2'} col={2} key={`motorAbsorption_element_${name}`}>
        <div className={`${hasProblems ? 'has-errors' : 'normal'}`}>
          <h3 className='strong'>{measure.name}</h3>
          <span className='small-text color-light'>
          {this.props.t('plantAnalysis.labels.motorAbsorption.max', { value: measure.thresold / 10 })}
            </span>
          <span className='small-text color-light mt-3'>
          {this.props.t('plantAnalysis.labels.motorAbsorption.totaltTimeOverThresold')}
            </span>
          <h3 className='strong'>{this.convertTime(measure.totalTimeOverThresold)}</h3>
          <span className='small-text color-light mt-3'>
          {this.props.t('plantAnalysis.labels.motorAbsorption.numberOfIntervalsOverThresold')}
            </span>
          <h3 className='strong'>{measure.numberOfIntervalsOverThresold}</h3>
          <span className='small-text color-light mt-3'>
          {this.props.t('plantAnalysis.labels.motorAbsorption.maximumDurationInterval')}
            </span>
          <h3 className='strong'>{this.convertTime(measure.maximumDurationInterval)}</h3>
        </div>
      </TableColumnWrapper>
    )
  }

  private async getData(props: Props) {
    if (!this.state.isFetching && props && props.dateStart && props.dateEnd) {
      this.setState({ isFetching: true, data: undefined })
      try {
        const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''
        const query = `SELECT * FROM vMotorAbsorption WHERE time >= '${props.dateStart}' and time <= '${props.dateEnd}'`
        const result = await API().request(`/query?${plantQuery}q=${query}`, {
          signal: this.abortController.signal,
        })
        const json = result && isJSON(result) && JSON.parse(result)
        const values: [] =
          json &&
          json.results &&
          json.results[0] &&
          json.results[0].series &&
          json.results[0].series[0] &&
          json.results[0].series[0].values
        const columns: string[] =
          json &&
          json.results &&
          json.results[0] &&
          json.results[0].series &&
          json.results[0].series[0] &&
          json.results[0].series[0].columns
        const indexOfDummy = columns && columns.indexOf('dummy')
        const indexOfMeasure = columns && columns.indexOf('measure')
        const indexOfTime = columns && columns.indexOf('time')
        if (values && !isNaN(indexOfDummy) && !isNaN(indexOfMeasure) && !isNaN(indexOfTime)) {
          const data: InfluxResponse = values.reduce(
            (acc: InfluxResponse, value: any) => {
              acc.time.push(value[indexOfTime])
              acc.dummy.push(value[indexOfDummy])
              const measure: any = (isJSON(value[indexOfMeasure]) && JSON.parse(value[indexOfMeasure])) || []
              if (measure) {
                measure.forEach((measureData: any) => {
                  if (!acc.measure[measureData.name]) {
                    acc.measure[measureData.name] = {
                      name: measureData.name,
                      maximumDurationInterval: measureData.maximumDurationInterval,
                      numberOfIntervalsOverThresold: measureData.numberOfIntervalsOverThresold,
                      thresold: measureData.thresold,
                      totalTimeOverThresold: measureData.totalTimeOverThresold,
                    }
                  } else {
                    acc.measure[measureData.name] = {
                      name: measureData.name,
                      maximumDurationInterval: Math.max(
                        measureData.maximumDurationInterval,
                        acc.measure[measureData.name].maximumDurationInterval,
                      ),
                      numberOfIntervalsOverThresold:
                        acc.measure[measureData.name].numberOfIntervalsOverThresold +
                        measureData.numberOfIntervalsOverThresold,
                      thresold: measureData.thresold,
                      totalTimeOverThresold:
                        acc.measure[measureData.name].totalTimeOverThresold + measureData.totalTimeOverThresold,
                    }
                  }
                })
              }
              return acc
            },
            { time: [], measure: {}, dummy: [] },
          )
          if (this.mounted) {
            this.setState({ data })
          }
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isFetching: false,
            fetchErrors: error.name === 'FetchError',
            data: undefined,
          })
        }
      } finally {
        if (this.mounted) {
          this.setState({
            isFetching: false,
          })
        }
      }
    }
  }

  private convertTime(seconds: number) {
    const padNum = (n: number) => (n < 10 ? '0' : '') + n
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - hours * 3600) / 60)
    const mm = padNum(minutes)
    const ss = padNum(Math.floor(seconds - hours * 3600 - minutes * 60))
    if (hours > 0) {
      return this.props.t('plantAnalysis.labels.motorAbsorption.timeHHMM', { hh: hours, mm })
    } else {
      return this.props.t('plantAnalysis.labels.motorAbsorption.timeMMSS', { mm: minutes, ss })
    }
  }

}

export default (withTranslation()(MotorAbsorptionView))