import { ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import * as moment from 'moment'
import * as React from 'react'

export interface Props {
  data: any
  paddingRight?: number
  colWidth?: number
  preserveStartEnd?: boolean
  xAxisNumber?: boolean
}
class GraphTimelineView extends React.PureComponent<Props> {
  public render() {
    return (
      <div className="row row-timeline">
        <div
          className={'mt-1 col-md-' + (this.props.colWidth ? this.props.colWidth : 10)}
          style={this.props.paddingRight ? { paddingRight: this.props.paddingRight } : {}}
        >
          <ResponsiveContainer width="100%" height={40}>
            <ComposedChart width={700} height={40} data={this.props.data}>
              <YAxis label={{ value: '' }} tick={false} />
              <XAxis
                dataKey="time"
                tickLine={false}
                axisLine={false}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
                interval={this.props.preserveStartEnd ? 'preserveStartEnd' : 'preserveEnd'}
                type={this.props.xAxisNumber ? 'number' : 'category'}
                tickCount={this.props.xAxisNumber ? 21 : undefined}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default GraphTimelineView
