import * as moment from 'moment-timezone'

export interface Workshift {
  name: string
  value: string | number
  type?: string
  start: string
  startIndex: number
  end: string
  endIndex: number
  pause?: string
  pauses?: any[]
  slots?: any[]
  resume?: string
  h?: string
  totals?: {[k: number]: number}
}

export const defaultWorkshiftNumber: number = 1

export interface DateRangeProps {
  dateFilterStart: moment.Moment
  dateFilterEnd: moment.Moment
  days: number
  isDateFilterRange: boolean
}
