// * -------------------------------- NPM --------------------------------------
import React, { Suspense } from 'react'

// * -------------------------------- MODULE --------------------------------------
import CenterLoader from '../../Loaders/CenterLoader'
import Flex, { Fit } from '../../Flex/Flex'
import NodeIcon from './NodeIcon'
import NodeLogic from './Node'
import Text from '../../Text/Text'
import { LightNode, ProcessedNodeData } from '../types'
import ButtonFactory from '../../Buttons/ButtonFactory'
import { ButtonVariants } from '../../Buttons/types'
import { TreeOtherActions } from '../Tree'
import { IsReadonly } from '../../../../types/base'
import { LocationDescriptor } from '../../Link/Linkable'

interface Props extends TreeOtherActions, IsReadonly {
  nodeLogic: NodeLogic
  isRoot: boolean
  node: ProcessedNodeData
  listOpened: LightNode[]
  listSelected: LightNode[]
  onToggle: (node: ProcessedNodeData, state: boolean) => void
  onSelectionNode: (node: ProcessedNodeData, state: boolean) => void
  selectedAsConsequence: boolean
}

export const Node = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { isRoot, listOpened, node, listSelected, nodeLogic } = props
  const v = 'mv-treeview__node'
  const n = 'mv-node'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const checkIfOpened = () => {
    return listOpened.some(s => s.id === node.id)
  }

  const checkIfSelected = () => {
    return listSelected.some(s => s.id === node.id)
  }

  const checkNumberOfChildSelected = (procN: ProcessedNodeData): [number, boolean] => {
    let count = 0
    let showAsDerivate: boolean = false

    listSelected.forEach(s => {
      if (procN.children) {
        let isDirectChildSelected: boolean = false
        let areSomeDerivateChildrenSelected: boolean = false
        procN.children.forEach(c => {
          if (c.id === s.id) {
            isDirectChildSelected = true
          }
          if (checkNumberOfChildSelected(c)[0] > 0 || checkNumberOfChildSelected(c)[1]) {
            areSomeDerivateChildrenSelected = true
          }
        })
        if (isDirectChildSelected) {
          count += 1
        }
        if (areSomeDerivateChildrenSelected) {
          showAsDerivate = areSomeDerivateChildrenSelected
        }
      }
    })
    return [count, showAsDerivate]
  }

  const isOpened = checkIfOpened()
  const isSelected = checkIfSelected()
  const numberOfChildSelected = checkNumberOfChildSelected(props.node)

  const createClassNameSelection = () => {
    let className: string = nodeLogic.classNameDefault
    if (isSelected) {
      className += ` ${nodeLogic.classNameIsSelected} `
    }
    if (props.selectedAsConsequence) {
      className += `${nodeLogic.classNameSelectedAsConsequence} `
    }
    if (numberOfChildSelected[0] > 0 || numberOfChildSelected[1]) {
      className += ` ${nodeLogic.classNameSelectedAsParent} `
    }
    if (props.node.disabled) {
      className += `${nodeLogic.classNameDisable} `
    }
    if (props.node.readonly) {
      className += `${nodeLogic.classNameReadonly} `
    }
    return className
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------

  function renderContent() {
    return (
      <Flex spaceSize={'none'}>
        <div
          className={createClassNameSelection()}
          onClick={
            !props.node.disabled && !props.isReadonly
              ? () => {
                  props.onSelectionNode(node, !isSelected)
                }
              : undefined
          }
        >
          <Text text={node.label} />
        </div>
        {props.otherActions && (
          <div className={nodeLogic.classNameActions}>
            <ButtonFactory
              action={'group'}
              semantic={'secondary'}
              buttons={props.otherActions.map(b => {
                return {
                  ...b,
                  variant: ButtonVariants.outline,
                  to: (loc: any) => {
                    return b.to?.(loc, props.node) as LocationDescriptor<any>
                  },
                  isDisable: b.isDisable?.(props.node) || false,
                  onClick: () => {
                    b.onClick?.(props.node)
                  },
                }
              })}
            />
          </div>
        )}
      </Flex>
    )
  }

  return (
    <li className={` ${v} ${isRoot ? nodeLogic.classNameRoot : ``}`} data-testid={node.id}>
      <Flex
        className={`${n} ${isRoot ? nodeLogic.classNameRoot : ``} ${nodeLogic.classNameVisible}`}
        fit={Fit.multiLine}
      >
        <NodeIcon
          baseClassName={n}
          hasChildren={props.node.children && props.node.children.length > 0}
          isOpened={isOpened}
          isRoot={isRoot}
          onClick={() => {
            props.onToggle(props.node, !isOpened)
          }}
        />
        {renderContent()}
        {isOpened && (
          <ul className={'mv-node__subtree'}>
            {node.children?.map(c => {
              return (
                <Suspense key={c.id} fallback={<CenterLoader key={c.id} />}>
                  <Node
                    {...props}
                    key={c.id}
                    nodeLogic={nodeLogic}
                    isRoot={false}
                    selectedAsConsequence={(isSelected || props.selectedAsConsequence) && nodeLogic.consequenceEnable}
                    listOpened={listOpened}
                    listSelected={listSelected}
                    node={{
                      ...c,
                      readonly: props.isReadonly,
                      parent: { id: node.id, label: node.label, parent: node.parent, disabled: node.disabled },
                    }}
                    onSelectionNode={props.onSelectionNode}
                    onToggle={props.onToggle}
                  />
                </Suspense>
              )
            })}
          </ul>
        )}
      </Flex>
    </li>
  )
}

export default React.memo(Node, (prevProps, nextProps) => {
  if (
    prevProps.isRoot === nextProps.isRoot &&
    prevProps.listOpened === nextProps.listOpened &&
    prevProps.listSelected === nextProps.listSelected &&
    prevProps.node === nextProps.node
  ) {
    return true
  }
  return false
})
