class ServiceError extends Error {
  constructor(message: string) {
    super(message)
    this.name = "ServiceError"
  }
}

export class TranslationServiceError extends ServiceError {
  constructor(message: string) {
    super(message)
    this.name = "TranslationServiceError"
  }
}

export class ComponentServiceError extends ServiceError {
  constructor(message: string) {
    super(message)
    this.name = "ComponentServiceError"
  }
}

export class StateManagerServiceError extends ServiceError {
  constructor(message: string) {
    super(message)
    this.name = "StateManagerServiceError"
  }
}