// * -------------------------------- NPM --------------------------------------
import React, { Suspense } from 'react'

// * -------------------------------- MODULE --------------------------------------
import CenterLoader from '../../Loaders/CenterLoader'
import Flex, { Fit } from '../../Flex/Flex'
import IconComponent from '../../Icon/Icon'
import NodeIcon from '../PrivateComponent/NodeIcon'
import Text from '../../Text/Text'
import { LightNode, ProcessedNodeData } from '../types'

export type SelectionTypes = 'Node' | 'OnlyChildren' | 'NodeAndChildren'

interface Props {
  isRoot: boolean
  node: ProcessedNodeData
  listOpened: LightNode[]
  listSelected: Record<string, LightNode>
  onToggle: (node: ProcessedNodeData, state: boolean) => void
  onSelectionNode: (node: ProcessedNodeData, state: boolean, selection: SelectionTypes) => void
}

const Node = (props: Props) => {
  const { isRoot, listOpened, node, listSelected } = props
  const v = 'mv-treeview__node'
  const n = 'mv-node'

  const checkIfOpened = () => {
    // TODO DUPLICATO
    return listOpened.some(s => s.id === node.id)
  }

  const checkIfSelected = () => {
    // TODO DUPLICATO
    return listSelected[node.id] !== undefined
  }

  const checkIsDisable = () => {
    return node.disabled
  }

  // ! TODO faccio schifo ho bisogno di essere cambiato
  const checkNumberOfChildSelected = (procN: ProcessedNodeData): [number, boolean] => {
    let countDirectChildSelected = 0
    let showAsDerivate: boolean = false

    if (procN.children) {
      let areSomeDerivateChildrenSelected: boolean = false
      procN.children.forEach(c => {
        if (listSelected?.[c.id] !== undefined) {
          countDirectChildSelected += 1
        }
        if (checkNumberOfChildSelected(c)[0] > 0 || checkNumberOfChildSelected(c)[1]) {
          areSomeDerivateChildrenSelected = true
        }
      })

      if (areSomeDerivateChildrenSelected) {
        showAsDerivate = areSomeDerivateChildrenSelected
      }
    }

    return [countDirectChildSelected, showAsDerivate]
  }

  const isOpened = checkIfOpened()
  const isSelected = checkIfSelected()
  const isDisabled = checkIsDisable()
  const [numberOfChildSelected, isNodeToShowAsDerivate] = checkNumberOfChildSelected(props.node)

  const classNameArrowDown = () => {
    const base = `${n}__arrow-down ${isDisabled ? `${n}__label--disable` : ''}`
    if (
      props.node.children &&
      props.node.children.length > 0 &&
      numberOfChildSelected === props.node.children.length &&
      props.node.children
        .map(c => checkNumberOfChildSelected(c)[0] === c.children?.length)
        .reduce((acc, curr) => acc && curr, true)
    ) {
      return `${base} ${n}__arrow-down--selected`
    }
    if (numberOfChildSelected >= 1 || isNodeToShowAsDerivate) {
      return `${base} ${n}__arrow-down--selected-parent`
    }
    
    return base
  }

  return (
    <li className={` ${v} ${isRoot ? `${v}--root` : ``}`}>
      <Flex className={`${n} ${isRoot ? `${n}--root` : ``} ${n}--visible`} fit={Fit.multiLine}>
        <NodeIcon
          baseClassName={n}
          hasChildren={props.node.children && props.node.children.length > 0}
          isOpened={isOpened}
          isRoot={isRoot}
          onClick={() => {
            props.onToggle(props.node, !isOpened)
          }}
        />
        <Flex spaceSize={props.node.children !== undefined && props.node.children.length > 0 ? 'none' : undefined}>
          <Flex
            fit={Fit.multiLine}
            className={`${n}__label ${isSelected ? `${n}__label--selected` : ''} ${
              isDisabled ? `${n}__label--disable` : ''
            }`}
            onClick={isDisabled ? undefined : () => {
              props.onSelectionNode(node, !isSelected, 'Node')
            }}
          >
            <Text text={node.label} />
          </Flex>
          {props.node.children !== undefined && props.node.children.length > 0 && (
            <Flex
              className={classNameArrowDown()}
              fit={Fit.multiLine}
              onClick={
                isDisabled
                  ? undefined
                  : () => {
                      props.onSelectionNode(
                        node,
                        !(props.node.children && numberOfChildSelected === props.node.children.length),
                        'OnlyChildren'
                      )
                    }
              }
            >
              <IconComponent icon={'arrow-down'} />
            </Flex>
          )}
        </Flex>
        {isOpened && (
          <ul className={'mv-node__subtree'}>
            {node.children?.map(c => {
              return (
                <Suspense key={c.id} fallback={<CenterLoader />}>
                  <Node
                    key={c.id}
                    isRoot={false}
                    listOpened={listOpened}
                    listSelected={listSelected}
                    node={{ ...c, parent: { id: node.id, label: node.label, parent: node.parent } }}
                    onSelectionNode={props.onSelectionNode}
                    onToggle={props.onToggle}
                  />
                </Suspense>
              )
            })}
          </ul>
        )}
      </Flex>
    </li>
  )
}

export default React.memo(Node, (prevProps, nextProps) => {
  if (
    prevProps.isRoot === nextProps.isRoot &&
    prevProps.listOpened === nextProps.listOpened &&
    prevProps.listSelected === nextProps.listSelected &&
    prevProps.node === nextProps.node
  ) {
    return true
  }
  return false
})
