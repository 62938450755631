import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { Moment } from 'moment'
import { COLORS } from '../../../../constants'

import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import { Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '../../../../functions/series'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphBrush from '../GraphBrush/GraphBrush'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import BunkerGraphMain from './BunkerGraphMain'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  data: { [key: string]: Measure }
  last: any
  lastAfter: any
  showTooltip: boolean
  tooltipData: any
  isCollapsed: boolean
  brush1Start: number
  brush1End: number
  brush1StartInd: number
  brush1EndInd: number
  mergedData: undefined | any[]
  filteredData: any[]
  startDate: string | number
  endDate: string | number
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  component: any
  inverter: boolean
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  dateFilterStart: Moment
  workshift: Workshift
  plant: string | null
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  plant: state.plantSelector.plant,
})

const cleanState = {
  isLoading: false,
  fetchErrors: false,
  showTooltip: false,
  tooltipData: null,
  isCollapsed: false,
  data: {
    inverterDiretta: {
      data: [],
      min: 0,
      max: 0,
    },
    manuale: {
      data: [],
      min: 0,
      max: 0,
    },
    automatico: {
      data: [],
      min: 0,
      max: 0,
    },
    nopienoPieno: {
      data: [],
      min: 0,
      max: 0,
    },
    localeSezionato: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  last: null,
  lastAfter: null,
  brush1Start: 0,
  brush1End: 0,
  brush1StartInd: 0,
  brush1EndInd: 0,
  mergedData: [],
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
}

type Props = StateProps & OwnProps & WithTranslation

class BunkerGraphs extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = cleanState

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce(this.handleBrush1.bind(this),10)
  }

  /*
  UNUSED
  private static parseResponseData(response: string) {
    const dataSrc = JSON.parse(response)

    return dataSrc && dataSrc.results[0] && dataSrc.results[0].series ? dataSrc.results[0].series[0].values : []
  }
  */

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(cleanState)

      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading

    const { workshift } = this.props

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-fetch-errors">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
          {isReady && this.props.inverter && (
            <BunkerGraphMain
              filteredData={this.state.filteredData}
              data={this.state.mergedData}
              pauses={(workshift && workshift.pauses) || []}
            />
          )}
        </div>

        {isReady && (
          <React.Fragment>
            <GraphLineBarTime
              i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
              filteredData={this.state.filteredData}
              colorsId={'manAutoLocSec'}
              entry={'manAutoLocSec'}
              topMargin={true}
            />

            {this.props.inverter && (
              <GraphLineBarTime
                i18nTitle={'plantAnalysis.labels.inverterDiretta'}
                filteredData={this.state.filteredData}
                colorsId={'invdir'}
                entry={'inverterDiretta'}
                topMargin={true}
              />
            )}

            <GraphLineBarTime
              i18nTitle={'plantAnalysis.labels.nopienoPieno'}
              filteredData={this.state.filteredData}
              colorsId={'nopienoPieno'}
              entry={'nopienoPieno'}
              topMargin={true}
            />

            <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} />

            <BottomLegend
              labels={
                this.props.inverter ? ['manAutoLocSec', 'invdir', 'nopienoPieno'] : ['manAutoLocSec', 'nopienoPieno']
              }
            />

            <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1} />
            <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false, multiplier?: number) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : multiplier ? datum[1] * multiplier : datum[1],
          h100: 100,
        }
      })
    }

    return [{}]
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true, fetchErrors: false })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const measureLabel = props.component.id

      /*
      const reg = new RegExp(/\[(.*)\] (.*)/);
      const measureDetails = props.component.data.label.match(reg);

      const measureLabel = measureDetails[2] + ' ' + measureDetails[1];
       */

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`
      /*const queryLastAfterStart = ` SELECT first("measure") AS "last_measure" FROM "`
      const queryLastAfterEnd = `" WHERE time > '${endDate}' LIMIT 1`*/

      const queryInverterDiretta = queryStart + measureLabel + '_BS_BYP_SEL' + queryEnd
      const queryManuale = queryStart + measureLabel + '_BS_MAN' + queryEnd
      const queryAutomatico = queryStart + measureLabel + '_BS_AUTO' + queryEnd
      const queryLocaleSezionato = queryStart + measureLabel + '_BS_LOC' + queryEnd
      const queryNopienoPieno = queryStart + measureLabel + '_BS_MAT_LEV_FULL_DLY' + queryEnd
      const queryLastInverterDiretta = queryLastStart + measureLabel + '_BS_BYP_SEL' + queryLastEnd
      const queryLastManuale = queryLastStart + measureLabel + '_BS_MAN' + queryLastEnd
      const queryLastAutomatico = queryLastStart + measureLabel + '_BS_AUTO' + queryLastEnd
      const queryLastLocaleSezionato = queryLastStart + measureLabel + '_BS_LOC' + queryLastEnd
      const queryLastNopienoPieno = queryLastStart + measureLabel + '_BS_MAT_LEV_FULL_DLY' + queryLastEnd
      /*const queryLastAfterInverterDiretta = queryLastAfterStart + measureLabel + '_BS_BYP_SEL' + queryLastAfterEnd
      const queryLastAfterManuale = queryLastAfterStart + measureLabel + '_BS_MAN' + queryLastAfterEnd
      const queryLastAfterAutomatico = queryLastAfterStart + measureLabel + '_BS_AUTO' + queryLastAfterEnd
      const queryLastAfterLocaleSezionato = queryLastAfterStart + measureLabel + '_BS_LOC' + queryLastAfterEnd
      const queryLastAfterNopienoPieno = queryLastAfterStart + measureLabel + '_BS_MAT_LEV_FULL_DLY' + queryLastAfterEnd*/

      const dataInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryInverterDiretta)
      const dataManualeSrc = await API().request(`/query?${plantQuery}q=` + queryManuale)
      const dataAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryAutomatico)
      const dataLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLocaleSezionato)
      const dataNopienoPienoSrc = await API().request(`/query?${plantQuery}q=` + queryNopienoPieno)
      const dataLastInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastInverterDiretta)
      const dataLastManualeSrc = await API().request(`/query?${plantQuery}q=` + queryLastManuale)
      const dataLastAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAutomatico)
      const dataLastLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLastLocaleSezionato)
      const dataLastNopienoPienoSrc = await API().request(`/query?${plantQuery}q=` + queryLastNopienoPieno)
      /* const dataLastAfterInverterDirettaSrc = await API().request(
        `/query?${plantQuery}q=` + queryLastAfterInverterDiretta
      )
      const dataLastAfterManualeSrc = await API.request(`/query?${plantQuery}q=` + queryLastAfterManuale)
      const dataLastAfterAutomaticoSrc = await API.request(`/query?${plantQuery}q=` + queryLastAfterAutomatico)
      const dataLastAfterLocaleSezionatoSrc = await API.request(
        `/query?${plantQuery}q=` + queryLastAfterLocaleSezionato
      )
      const dataLastAfterNopienoPienoSrc = await API.request(`/query?${plantQuery}q=` + queryLastAfterNopienoPieno)*/

      let dataAssorbimentoSrc: string = '[]'
      let dataLastAssorbimentoSrc: string = '[]'

      const queryAssorbimento = queryStart + measureLabel + '_WS_ACT_CURR' + queryEnd
      const queryLastAssorbimento = queryLastStart + measureLabel + '_WS_ACT_CURR' + queryLastEnd

      dataAssorbimentoSrc = await API().request(`/query?${plantQuery}&q=` + queryAssorbimento)
      dataLastAssorbimentoSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAssorbimento)

      Promise.all([
        dataAssorbimentoSrc,
        dataInverterDirettaSrc,
        dataManualeSrc,
        dataAutomaticoSrc,
        dataNopienoPienoSrc,
        dataLocaleSezionatoSrc,
        dataLastAssorbimentoSrc,
        dataLastInverterDirettaSrc,
        dataLastManualeSrc,
        dataLastAutomaticoSrc,
        dataLastNopienoPienoSrc,
        dataLastLocaleSezionatoSrc,
        /*dataLastAfterInverterDirettaSrc,
        dataLastAfterManualeSrc,
        dataLastAfterAutomaticoSrc,
        dataLastAfterNopienoPienoSrc,
        dataLastAfterLocaleSezionatoSrc,*/
      ]).then(() => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            assorbimento: { data: parseResponseData(dataAssorbimentoSrc) },
            inverterDiretta: { data: parseResponseData(dataInverterDirettaSrc) },
            manuale: { data: parseResponseData(dataManualeSrc) },
            automatico: { data: parseResponseData(dataAutomaticoSrc) },
            nopienoPieno: { data: parseResponseData(dataNopienoPienoSrc) },
            localeSezionato: { data: parseResponseData(dataLocaleSezionatoSrc) },
          }),
          last: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(dataLastAssorbimentoSrc),
            inverterDiretta: parseResponseSingleData(dataLastInverterDirettaSrc),
            manuale: parseResponseSingleData(dataLastManualeSrc),
            automatico: parseResponseSingleData(dataLastAutomaticoSrc),
            nopienoPieno: parseResponseSingleData(dataLastNopienoPienoSrc),
            localeSezionato: parseResponseSingleData(dataLastLocaleSezionatoSrc),
          }),
          /*lastAfter: Object.assign({}, this.state.data, {
            inverterDiretta: parseResponseSingleData(dataLastAfterInverterDirettaSrc),
            manuale: parseResponseSingleData(dataLastAfterManualeSrc),
            automatico: parseResponseSingleData(dataLastAfterAutomaticoSrc),
            nopienoPieno: parseResponseSingleData(dataLastAfterNopienoPienoSrc),
            localeSezionato: parseResponseSingleData(dataLastAfterLocaleSezionatoSrc),
          }),*/
          startDate: startDateObj.unix(),
          endDate: endDateObj.unix(),
          isLoading: false,
          fetchErrors: false,
        })

        this.prepareData()
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private prepareData() {
    try {
      let inverterDiretta: any = []
      let manuale: any = []
      let assorbimento: any = []
      let automatico: any = []
      let nopienoPieno: any = []
      const mergedData: any[] = []
      let localeSezionato: any[] = []

      if (this.state.data) {
        inverterDiretta = this.populateSingleData('inverterDiretta', true)
        manuale = this.populateSingleData('manuale', true)
        assorbimento = this.populateSingleData('assorbimento', false, 0.1)
        automatico = this.populateSingleData('automatico', true)
        nopienoPieno = this.populateSingleData('nopienoPieno', true)
        localeSezionato = this.populateSingleData('localeSezionato', true)

        hydrateData(
          {
            inverterDiretta,
            manuale,
            assorbimento,
            automatico,
            nopienoPieno,
            localeSezionato,
          },
          mergedData,
          'assorbimento',
          0
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) { return -1 }
          if (a.time > b.time) { return 1 }
          return 0
        })

        hydrateTimeData(
          ['inverterDiretta', 'manuale', 'assorbimento', 'automatico', 'nopienoPieno', 'localeSezionato'],
          mergedData,
          this.state
        )

        populateManAutoLocSec(mergedData)
        fillWarnArea(mergedData, 'assorbimento', 0)
        interpolateTime(mergedData, moment(this.props.workshift.start).unix(), moment(this.props.workshift.end).unix())

        this.setState({
          isLoading: false,
          fetchErrors: false,
          brush1StartInd: 0,
          brush1EndInd: mergedData.length,
          mergedData,
          filteredData: mergedData,
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      let curData = this.state.mergedData && this.state.mergedData.find((e: any) => e.time === args.activeLabel)

      const curNopienoPieno = curData.nopienoPieno
      const curManAutoLocSez = curData.manAutoLocSez
      const curInverterDiretta = curData.inverterDiretta


      this.setState({
        showTooltip: true,
        tooltipData: {
          time: curData.time,
          nopienoPieno: curNopienoPieno !== undefined ? curNopienoPieno : '--',
          manAutoLocSez: curManAutoLocSez !== undefined ? curManAutoLocSez : '--',
          inverterDiretta: curInverterDiretta !== undefined ? curInverterDiretta : '--',
          // @ts-ignore
          manualeColor: curManAutoLocSez ? COLORS.bagRecipe[curManAutoLocSez] : 'transparent',
          inverterDirettaColor: curInverterDiretta ? COLORS.invdir[curInverterDiretta] : 'transparent',
          nopienoPienoColor: curNopienoPieno ? COLORS.nopienoPieno[curNopienoPieno] : 'transparent',
        },
      })

      curData = null
    }
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex + 1) : [],
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BunkerGraphs)))
