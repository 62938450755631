import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import { PageHeader, Button, ButtonGroupHeader, MainPageContent } from '@mv-submodules/inplant-components-fe'
import {
  AssetManagerAcl,
  AssetsListElement,
  AssetsListFilters,
  AssetStatus,
  CostCenter,
  ListPagination,
} from '../../../../types/asset'
import {
  applyAssetsListFilters,
  applyAssetsListPagination, exportData,
  fetchAssetsList, fetchCountToValidate,
  fetchSitesGroupsModules,
} from '../../../../redux/actions/assetsList'
import {
  FetchAssetsListResponse,
  FetchSitesGrousModulesResponse,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import { connect } from 'react-redux'
import AssetsTable from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetsTable/AssetsTable'
import DownloadCsvButton from '@mv-submodules/inplant-components-fe/ui/components/Button/DownloadCsvButton'
import moment from 'moment'
import { download } from '@mv-submodules/inplant-components-fe/mvfunctions/download'

interface OwnState {
  data?: FetchAssetsListResponse,
  assetStatus: {
    'list-active': AssetStatus[], 'list-archive': AssetStatus[], 'list-to-validate': AssetStatus[], 'list-deactivated-assets': AssetStatus[]
  },
  isFetching: boolean,
  filters: AssetsListFilters,
  pagination: ListPagination,
  plants: Array<{ code: string, name: string }>
  costCenters: Array<{ plant: string, costCenters: CostCenter[] }>
  pageType: string
  isExporting: boolean
}

interface StateProps {
  filters: AssetsListFilters
  pagination: ListPagination
  forbiddenActions: string[]
}

const mapStateToProps = (store: any): StateProps => {
  return {
    filters: store.assetManager.assetsList.filters,
    pagination: store.assetManager.assetsList.pagination,
    forbiddenActions: store.auth.user.forbiddenActions,
  }
}

interface DispatchProps {
  applyAssetsListFilters: (params: AssetsListFilters) => void
  applyAssetsListPagination: (params: ListPagination) => void
  exportData: (pageType: string, filters?: AssetsListFilters) => any
  fetchCountToValidate: () => void

}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  applyAssetsListFilters: (params: AssetsListFilters) => dispatch(applyAssetsListFilters(params)),
  applyAssetsListPagination: (params: ListPagination) => dispatch(applyAssetsListPagination(params)),
  exportData: (pageType: string, filters?: AssetsListFilters) => dispatch(exportData(pageType, filters)),
  fetchCountToValidate: () => dispatch(fetchCountToValidate()),
})

type Props = StateProps & DispatchProps & RouteComponentProps & WithTranslation

class AssetListPageView extends React.Component<Props, OwnState> {

  private abortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = {
      data: undefined,
      assetStatus: {
        'list-active': ['active', 'deactivation-to-validate', 'creation-to-validate'],
        'list-archive': ['deactivated'],
        'list-to-validate': ['deactivation-to-validate', 'creation-to-validate', 'replace-to-validate', 'documents-to-validate'],
        'list-deactivated-assets': [],
      },
      isFetching: false,
      filters: {
        ...this.props.filters,
      },
      pagination: {
        ...this.props.pagination,
      },
      plants: [],
      costCenters: [],
      pageType: '',
      isExporting: false,
    }
    this.handleCreateAssetClick = this.handleCreateAssetClick.bind(this)
    this.applyFiltersTable = this.applyFiltersTable.bind(this)
    this.applyPaginationListTable = this.applyPaginationListTable.bind(this)
    this.handleRowAssetClick = this.handleRowAssetClick.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSortingChange = this.onSortingChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.exportData = this.exportData.bind(this)
  }

  public componentDidMount() {
    let pageType: string = this.props.location.pathname as string
    pageType = pageType.split('/')[2]
    if (!this.state.assetStatus[pageType].includes(this.state.filters.status) && this.state.filters.status) {
      this.setState({
        pageType,
        filters: {
          ...this.state.filters,
          status: null,
        },
      }, () => this.applyFiltersTable(this.state.filters))
    } else {
      this.setState({ pageType }, this.fetchData)
    }
    this.props.fetchCountToValidate()
  }

  public render() {
    const { t } = this.props
    const {
      assetStatus,
      data,
      isFetching,
      isExporting,
      filters,
      pagination,
      plants,
      costCenters,
      pageType,
    } = this.state
    const pages = data ? Math.ceil(data.metadata.count / data.metadata.pageSize) : null

    const hiddenCols: {
      'list-active': string[],
      'list-archive': string[],
      'list-to-validate': string[],
      'list-deactivated-assets': string[],
    } = {
      'list-active': ['select', 'timeActive'],
      'list-archive': ['select', 'status'],
      'list-to-validate': ['select', 'timeActive'],
      'list-deactivated-assets': ['select', 'status', 'groups'],
    }

    return (
      <div className='inplant-asset-manager-fe'>
        {/* Header Section*/}
        <PageHeader title={t(`assetManager.navigation.${pageType}`)}
                    rightButtons={(
                      <ButtonGroupHeader>
                        <DownloadCsvButton
                          disabled={!(data && data.result && data.result.length > 0)}
                          isLoading={isFetching || isExporting}
                          label={t('chronoframe.dueDates.exportData')}
                          onClick={() => this.exportData()}
                        />
                        {pageType === 'list-active' && (!this.props.forbiddenActions.includes(AssetManagerAcl.create)) ? (
                          (
                            <Button type='button'
                                    label={t('assetManager.actions.createAsset')}
                                    onClick={this.handleCreateAssetClick}
                                    variant='primary'
                            />
                          )
                        ) : null}
                      </ButtonGroupHeader>
                    )}
        />
        { /* End Header Section*/}
        { /* Content Section*/}
        <MainPageContent>
          { /* Table Section*/}
          <AssetsTable
            data={!isFetching && data ? data.result : []}
            onRowClick={this.handleRowAssetClick}
            pages={pages ? pages : 0}
            assetStatus={assetStatus[pageType] || []}
            filters={filters}
            isFetching={isFetching}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            pagination={pagination}
            updateFilters={this.applyFiltersTable}
            hiddenCols={hiddenCols[pageType] || []}
            plants={plants}
            costCenters={costCenters}
            manual={!!this.state.pagination}
            onSortingChange={this.onSortingChange}
            sortableCols={['status', 'assetCode', 'positionCode', 'assetDescription', 'costCenter.name', 'activatedOn']}
          />
          { /* End Table Section*/}
        </MainPageContent>
        { /* End Content Section*/}
      </div>
    )
  }


  private applyFiltersTable(filters: AssetsListFilters) {
    this.setState({
      filters, pagination: {
        ...this.state.pagination,
        pageNumber: 0,
      },
    })
    this.props.applyAssetsListFilters(filters)
    this.applyPaginationListTable()

  }

  private applyPaginationListTable() {
    this.props.applyAssetsListPagination(this.state.pagination)
    this.fetchData()
  }

  private async fetchData() {
    if (this.state.isFetching) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }

    try {
      this.setState({
        isFetching: true,
      })
      const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
      const plantsArray: Array<{ code: string, name: string }> = sitesData.sites.reduce((acc: Array<{ code: string, name: string }>, sites) => {
        acc = acc.concat(sites.plants.reduce((plants: Array<{ code: string, name: string }>, plant) => {
          plants.push({
            code: plant.code,
            name: plant.name,
          })
          return plants
        }, []))
        return acc
      }, [])
      const costCenters: Array<{ plant: string, costCenters: CostCenter[] }> = sitesData.sites.reduce((acc: Array<{ plant: string, costCenters: CostCenter[] }>, site) => {
        return acc.concat(site.plants.reduce((acc2: Array<{ plant: string, costCenters: CostCenter[] }>, plant) => {
          acc2.push({
            plant: plant.code,
            costCenters: plant.costCenters,
          })
          return acc2
        }, []))
      }, [])
      const data = await fetchAssetsList(this.state.pageType, this.abortController, this.state.filters, this.state.pagination)
      this.setState({
        plants: plantsArray,
        costCenters,
        isFetching: false,
        data: {
          ...data,
          metadata: {
            ...data.metadata,
            pageNumber: data.metadata.pageNumber - 1,
          },
        },
        pagination: {
          ...this.state.pagination,
          pageSize: data.metadata.pageSize,
          pageNumber: data.metadata.pageNumber - 1,
          sortField: data.metadata.sortField,
          sortDirection: data.metadata.sortDirection,
        },
      })
    } catch (error:any) {
      if (!(error.name && error.name === 'AbortError')) {
        console.warn('error', error) // tslint:disable-line
        this.setState({
          isFetching: false,
        })
      }
    }
  }

  private exportData() {
    if (!this.state.isExporting && !this.state.isFetching) {
      this.setState({ isExporting: true })
      this.props.exportData(this.state.pageType, this.state.filters)
        .then((result: any) => {
          const filename = result['content-disposition'] || `asset_export_${moment().format('YYYYMMDD')}.csv`
          download(result.result, filename)
        })
        .catch((error: any) => {
          console.log(error) //tslint:disable-line
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    }
  }

  private onPageSizeChange(pageSize: number) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageSize,
      },
    }, this.applyPaginationListTable)
  }

  private onPageChange(pageNumber: number) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageNumber: pageNumber + 1,
      },
    }, this.applyPaginationListTable)
  }

  private handleCreateAssetClick() {
    this.props.history.push('/asset-manager/create-asset')
  }

  private handleRowAssetClick(data: AssetsListElement) {
    this.props.history.push(`/asset-manager/detail-asset/${data.id}`, {
      from: this.props.location.pathname,
    })
  }

  private onSortingChange(sortField: string, sortDirection: string) {
    if (!this.state.isFetching && (this.state.pagination.sortDirection !== sortDirection || this.state.pagination.sortField !== sortField)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          sortField,
          sortDirection,
        },
      }, this.applyPaginationListTable)
    }
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
  (withTranslation()(AssetListPageView)))