import { AnyAction } from 'redux'

const generalInitialState: any = {
  workshifts: {
    data: {},
    fetching: false,
    error: false,
  },
  fullDay: null,
}

export const general = (state = generalInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_WORKSHIFT_IS_FETCHING':
      return {
        ...state,
        workshifts: {
          fetching: true,
          error: false,
          data: {},
        },
        fullDay: null,
      }
    case 'PLANTANALYSIS_WORKSHIFT_FETCH_SUCCESS':
      return {
        ...state,
        workshifts: {
          data: action.payload.workshifts,
          fetching: false,
          error: false,
        },
        fullDay: action.payload.fullDay,
      }
    case 'PLANTANALYSIS_WORKSHIFT_FETCH_ERROR':
      return {
        ...state,
        workshifts: {
          fetching: false,
          error: true,
          data: {},
        },
        fullDay: null,
      }

    /****/

    case 'PLANTANALYSIS_WORKSHIFTVALIDITY_IS_FETCHING':
      return {
        ...state,
        workshiftsValidity: {
          fetching: true,
          error: false,
          data: {},
        },
      }
    case 'PLANTANALYSIS_WORKSHIFTVALIDITY_FETCH_SUCCESS':
      return {
        ...state,
        workshiftsValidity: {
          data: action.payload.workshiftsValidity,
          fetching: false,
          error: false,
        },
      }
    case 'PLANTANALYSIS_WORKSHIFTVALIDITY_FETCH_ERROR':
      return {
        ...state,
        workshiftsValidity: {
          fetching: false,
          error: true,
          data: {},
        },
      }
    default:
      return state
  }
}

export default general
