import { ModelNode, ModelNodeData } from '../types/chronograph'

export const flatData = (data: ModelNode) => {
  const out: ModelNodeData[] = []

  const recursiveSearch = (node: ModelNode) => {
    if (
      node.data &&
      node.data.nodeType &&
      node.data.nodeType !== 'NoNodeType' &&
      node.data.nodeType !== 'PLANT_DATA_NOT_USED'
    ) {
      out.push(node.data)
    }

    if (node.subcomponents && node.subcomponents.length > 0) {
      node.subcomponents.forEach(subNode => recursiveSearch(subNode))
    }
  }

  recursiveSearch(data)

  return out.sort((a: ModelNodeData, b: ModelNodeData) => (a.code > b.code ? -1 : 1))
}

export const plantQuery = (getState: Function) => {
  const { plant } = getState().plantSelector // @todo refactor

  return plant && plant !== '' ? 'plant=' + plant + '&' : ''
}
