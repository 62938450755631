import * as React from 'react'
import * as moment from 'moment'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TFunction } from 'i18next'
import { HHHmmssFromMilliseconds, parseInfluxResponse } from '../../../../functions/shared'

interface OwnProps {
  operation: 'sum' | 'count' | 'mean'
  measure: string
  i8nLabel: string
  condition: string
  dateStart: string
  dateEnd: string
  nodeId: string
  plant?: any
  unit?: string
  t: TFunction
  withoutMeasurePrefix?: boolean
  select?: string
  formatter?: string
  noDataLabel?: string
}

interface OwnState {
  data: any
  fetchErrors: boolean
  isLoading: boolean
}

type Props = OwnProps

class SingleMeasure extends React.Component<Props, OwnState> {
  // @ts-ignore
  private mounted: boolean
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      data: null,
      isLoading: false,
      fetchErrors: false,
    }

    this._getData = this._getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this._getData()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    if (
      this.props.dateStart &&
      this.props.dateEnd &&
      (prevProps.dateStart !== this.props.dateStart || prevProps.dateEnd !== this.props.dateEnd)
    ) {
      this._getData()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public render() {
    const { i8nLabel, t, unit, formatter, noDataLabel } = this.props
    const { data, isLoading } = this.state

    return (
      <div className="col-md-3">
        <h6 className="generic-sub-title">
          {t(i8nLabel)}:{' '}
          {isLoading ? (
            <FontAwesomeIcon icon={faCircleNotch} size="1x" spin={true} />
          ) : data && (data.data_measure || data.data) ? (
            this._formatter(formatter, data.data_measure || data.data) + '' + (unit || '')
          ) : noDataLabel ? (
            t(noDataLabel)
          ) : (
            ' -- '
          )}
        </h6>
      </div>
    )
  }

  private _getData() {
    const {
      nodeId,
      plant,
      measure,
      operation,
      condition,
      dateStart,
      dateEnd,
      withoutMeasurePrefix,
      select,
    } = this.props
    // tslint:disable-next-line:no-console
    if (dateStart && dateEnd) {
      if (this.mounted) {
        this.setState({
          isLoading: true,
          data: null,
          fetchErrors: false,
        })
      }
      const plantQueryString = plant && plant.plant !== '' ? 'plant=' + plant.plant + '&' : ''
      const startDateObj = moment(dateStart)
      const endDateObj = moment(dateEnd)

      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}' ${
        condition ? `AND measure ${condition}` : ''
      }`
      const selectedMeasure = operation ? `${operation}(*)` : `${select || 'measure'}`
      const query =
        `${plantQueryString}q=SELECT ${selectedMeasure} as data FROM ${
          withoutMeasurePrefix ? measure : `${nodeId}_${measure}`
        }` + queryEnd


      try {
        API().request(`/query?${query}`, {
          signal: this.abortController.signal,
        })
          .then((results: any) => {
            if (this.mounted) {
              const measureData = parseInfluxResponse(results)
              this.setState({
                fetchErrors: false,
                data: measureData && measureData[0] && measureData[0].data && measureData[0].data[0],
                isLoading: false,
              })
            }
          })
          .catch((e: any) => {
            console.log(e) // tslint:disable-line
            if (this.mounted) {
              this.setState({
                fetchErrors: true,
                data: [],
                isLoading: false,
              })
            }
          })
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log(error) // tslint:disable-line
        if (this.mounted) {
          this.setState({
            fetchErrors: true, // mean?
            data: [],
            isLoading: false,
          })
        }
        throw error
      }
    }
  }

  private _formatter(formatter: string | undefined, measure: any) {
    if (formatter) {
      switch (formatter) {
        case 'duration':
          return HHHmmssFromMilliseconds(measure * 1000, false, true, true)
      }
    }

    return measure
  }
}

export default SingleMeasure
