import FetchWrapper from "../../inplant-coreadapter-fe/functions/fetch-wrapper"

const API_BASE_URL = (window as any).API_BASE_URL || process.env.REACT_APP_API_BASE_URL
const APIbaseURL = `${API_BASE_URL}/v1`

const initFetchWrapper = (i18n: any) => {
  FetchWrapper.configInstance('auth', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('plantAnalysis', `${API_BASE_URL}/v1/influx`, true, i18n)
  FetchWrapper.configInstance('plantAnalysisAPI', `${APIbaseURL}/plant-analysis`, true, i18n)
  FetchWrapper.configInstance('plantAnalysisGW', `${APIbaseURL}/plant-analysis`, true, i18n)
  FetchWrapper.configInstance('user', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('chronoframe', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('plantHalts', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('titech', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('model', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('assetManager', `${APIbaseURL}/asset-manager`, true, i18n)
  // FetchWrapper.configInstance('maintenance', APIbaseURL, true, i18n)
  // FetchWrapper.configInstance('mcs', `${APIbaseURL}/mcs`, true, i18n)
  // FetchWrapper.configInstance('checklist', `${APIbaseURL}/checklist-compiler`, true, i18n)
  FetchWrapper.configInstance('file', '', true, i18n)
}

export default initFetchWrapper
