import { Brush, ComposedChart, RechartsFunction, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import * as moment from 'moment'
import * as React from 'react'

export interface Props {
  data: any
  brushOnChange: RechartsFunction
  paddingRight?: number
  colWidth?: number
}

class GraphBrush extends React.PureComponent<Props> {
  public render() {
    return (
      <div className="mt-5 row">
        <div className={`col-md-${this.props.colWidth ?? '10'}`} style={this.props.paddingRight ? { paddingRight: this.props.paddingRight } : {}}>
          <ResponsiveContainer width="100%" height={80}>
            <ComposedChart
              width={700}
              height={80}
              data={this.props.data}
              // syncId={this.props.component.id}
              barGap={0}
              barCategoryGap={0}
            >
              <YAxis label={{ value: '' }} tick={false} domain={['dataMin', 'dataMax + 10']} />
              <XAxis
                dataKey="time"
                hide={false}
                type={'number'}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
              />
              <Brush
                height={80}
                dataKey="time"
                y={0}
                tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
                onChange={this.props.brushOnChange}
              >
                <ComposedChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} barGap="0" barCategoryGap="0">
                  {this.props.children}
                </ComposedChart>
              </Brush>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default GraphBrush
