import { DueDatesConfig, SidebarCounter } from '../../types/pageConfig'
import {
  CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE,
  CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING,
  CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS,
  CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS,
  CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS, PageConfigActions
} from "@mv-submodules/inplant-chronoframe-fe/redux/actions/pageConfig";

interface PageConfigState {
  pageSlug: string | null
  isFetching: boolean
  data?: DueDatesConfig | object // needs to be tested
  error?: Error
  sidebarCounters?: SidebarCounter[]
}

const initialState: PageConfigState = {
  pageSlug: null,
  isFetching: false,
}

export function pageConfig(state: PageConfigState = initialState, action: PageConfigActions): PageConfigState {
  switch (action.type) {
    case CHRONO_FRAME_PAGE_CONFIGURATION_IS_FETCHING:
      return {
        ...state,
        pageSlug: action.pageSlug,
        isFetching: true,
        data: undefined,
        error: undefined,
      }

    case CHRONO_FRAME_PAGE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      }

    case CHRONO_FRAME_PAGE_CONFIGURATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }

    case CHRONO_FRAME_PAGE_CONFIGURATION_STRUCTURE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          formFields: action.data,
        },
      }

    case CHRONO_FRAME_UPDATE_COUNTERS_SUCCESS:
      return {
        ...state,
        sidebarCounters: action.data,
      }

    default:
      return state
  }
}
