import * as React from 'react'
import * as moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { Workshift } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/workshift'
import { GeneralData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/measure'
import { generalFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/general'
import {
  resetWorkshiftFilter,
  updateDateFilter,
  updateWorkshiftFilter,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/common'
import { modelFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/model'
import { flatData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/model'
import WorkshiftSelector from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/WorkShiftSelector/WorkshiftSelector'
import GraphWrapper from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GraphWrapper/GraphWrapperView'
import GeneralGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GeneralGraphs/GeneralGraphsView'
import { ModelNodeData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/chronograph'
import BeltGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltGraphs'
import BunkerGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BunkerGraph/BunkerGraphs'
import FanGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/FanGraph/FanGraphs'
import BagGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BagGraph/BagGraphs'
import MultiSeriesLineCharts from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/MultiSeriesLineCharts/MultiSeriesLineCharts'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import BeltBinarySrcGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltBinarySrcGraphs'
import BeltBinarySrcGenGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltBinarySrcGenGraphs'
import BufferFillDetail from '../../widgets/Vecoplant/BufferFill/BufferFillDetail'

library.add(faCircleNotch, faInfoCircle, faEyeDropper, faHourglassHalf)

let ticking = false

interface OwnState {
  startDate?: moment.Moment
  endDate?: moment.Moment
  currentGraph: string[]
  lastScrollY: number
}

interface StateProps {
  dateFilterStart: moment.Moment
  workshift: Workshift | null
  lavorazioneAttuale: null | GeneralData
  ricettaAttuale: null | GeneralData
  plant: any | null
  model: null | GeneralData
  chartConfigurations: any | null
  workshifts: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  lavorazioneAttuale: state.plantAnalysis.general.lavorazione,
  ricettaAttuale: state.plantAnalysis.general.ricetta,
  plant: state.plantSelector || null,
  model: state.plantAnalysis.model,
  chartConfigurations: state.config && state.config.plantAnalysis && state.config.plantAnalysis.chartConfigurations,
  workshifts: state.plantAnalysis.workshifts.workshifts,
})

interface DispatchProps {
  generalFetchData: (workshift: Workshift) => void
  resetWorkshift: () => void
  modelFetchData: (plant?: string) => void
  updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) => void
  updateDateFilter: (dateStart: moment.Moment, dateEnd: moment.Moment, isManual?: boolean) => void
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    generalFetchData: (workshift: Workshift) => dispatch(generalFetchData(workshift)),
    resetWorkshift: (workshift?: Workshift) => dispatch(resetWorkshiftFilter(workshift)),
    modelFetchData: (plant?: string) => dispatch(modelFetchData(plant)),
    updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) =>
      dispatch(updateWorkshiftFilter(workshift, workshifts)),
    updateDateFilter: (dateStart, dateEnd, isManual) => dispatch(updateDateFilter(dateStart, dateEnd, isManual)),
  }
}

export type Props = WithTranslation & RouteComponentProps<any> & DispatchProps & StateProps

const itemRefs: any[] = []

class PlantAnalysis extends React.Component<Props, OwnState> {
  // @ts-ignore
  private selectDateText: string
  // @ts-ignore
  private mounted: boolean

  constructor(props: Props) {
    super(props)

    this.selectDateText = this.props.t('plantAnalysis.actions.selectDateText')

    this.state = {
      startDate: moment(this.props.dateFilterStart),
      endDate: moment(this.props.dateFilterStart),
      currentGraph: ['M001'],
      lastScrollY: 0,
    }

    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      (nextProps.dateFilterStart !== this.props.dateFilterStart || nextProps.workshift !== this.props.workshift) &&
      nextProps.workshift &&
      moment.isMoment(nextProps.dateFilterStart)
    ) {
      this.props.generalFetchData(nextProps.workshift)
    }

    if (nextProps.plant.plant !== this.props.plant.plant && !this.props.plant.isLoading) {
      this.props.modelFetchData(nextProps.plant.plant)
    }
  }

  public componentDidMount() {
    this.mounted = true
    window.addEventListener('scroll', this.handleScroll)
    /****/
    const url = new URL(window.location.href)
    const manualDate = url.searchParams.get('manualDate')
    // const manualWorkshift = url.searchParams.get('manualWorkshift')
    if (manualDate && moment(manualDate).isValid()) {
      this.props.updateDateFilter(moment(manualDate), moment(manualDate), true)
    }
    /****/

    if (this.props.dateFilterStart && this.props.workshift && this.props.plant) {
      this.props.generalFetchData(this.props.workshift)
    }

    if (this.props.plant && this.props.plant.plant && !this.props.plant.isLoading) {
      this.props.modelFetchData(this.props.plant.plant)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  public render() {
    const { workshift, model, t, plant, chartConfigurations } = this.props
    const { currentGraph } = this.state

    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const timeStart = (workshift && workshift.start && moment(workshift.start).format('HH:mm')) || null
    const timeEnd =
      (workshift &&
        workshift.end &&
        workshift.start &&
        workshift.start !== workshift.end &&
        moment(workshift.end).format('HH:mm')) ||
      null
    const notEnded = workshift && !workshift.end
    const running =
      workshift &&
      workshift.end &&
      timeEnd &&
      moment(workshift.end).isSame(moment.now(), 'day') &&
      timeEnd === moment().format('HH:mm')

    const url = new URL(window.location.href)
    const allOpened = url.searchParams.get('allOpened')

    return (
      <div
        className={'mv4iot-fe-plant-analysis plant-analysis-list' + (this.state.lastScrollY > 48 ? 'is-scrolling' : '')}
      >
        <header>
          <div className={'flex-row justify-content-between'}>
            <Row horizontalAlignment={'between'} spacing={{ horizontal: false, vertical: false }}>
              <Column md={10}>
                <h1 className="title mb-3">{t('plantAnalysis.navigation.plantAnalysis')}</h1>
                <ul className="top-details">
                  <li>
                    <span className="label black">{t('plantAnalysis.labels.plantH')}</span> {workshift && workshift.h}
                  </li>
                  <li>
                    <span className="label">{t('plantAnalysis.labels.workshift.start')}</span> {timeStart}
                  </li>
                  <li>
                    <span className="label">{t('plantAnalysis.labels.workshift.end')}</span>
                    {running
                      ? t('plantAnalysis.labels.workshift.running')
                      : notEnded
                      ? t('plantAnalysis.labels.workshift.notEnded')
                      : timeEnd}
                  </li>
                </ul>
              </Column>
              <Column md={2}>
                <Row horizontalAlignment={'end'}>
                  <WorkshiftSelector isRenderVisible={true} />
                </Row>
              </Column>
            </Row>
          </div>
        </header>
        <div className="content">
          <div id="container">
            <React.Fragment>
              {model && !model.fetching && (!nodes || nodes.length === 0) ? (
                <Row horizontalAlignment={'center'}>
                  <Column sm={6}>
                    <div className="alert alert-danger w-100">{t('plantAnalysis.noNodes')}</div>
                  </Column>
                </Row>
              ) : !['ro', 'ric-sg'].includes(plant.plant) ? (
                // @ts-ignore
                <div className="graph-wrapper" ref={e => (itemRefs.general = e)}>
                  <GraphWrapper
                    active={currentGraph}
                    component={{ id: 'general', label: t('plantAnalysis.labels.general') }}
                    toggleCollapse={this.toggleCollapse}
                    subtitle={'graph2.subTitle'}
                  >
                    <GeneralGraphs
                      component={{ id: 'general', label: 'general' }}
                      active={currentGraph}
                      toggleCollapse={this.toggleCollapse}
                      forceFetch={true}
                    />
                  </GraphWrapper>
                </div>
              ) : (
                false
              )}

              {nodes
                // components
                .sort((a: ModelNodeData, b: ModelNodeData) => {
                  if (a.code < b.code) {
                    return -1
                  }
                  if (a.code > b.code) {
                    return 1
                  }
                  return 0
                })
                .map((c: any) => {
                  switch (c.nodeType) {
                    case 'PLANT_DATA_ROT_SCR':
                    case 'PLANT_DATA_BELT':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={''}
                          >
                            <BeltGraphs t={true} component={c} inverter={false} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_ROT_SCR_INVERTER':
                    case 'PLANT_DATA_BELT_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph2.subTitle'}
                          >
                            <BeltGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_BNK':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph3.subTitle'}
                          >
                            <BunkerGraphs t={true} component={c} inverter={false} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_BNK_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph3.subTitle'}
                          >
                            <BunkerGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_ASP_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                          >
                            <FanGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_ASP':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                          >
                            <FanGraphs t={true} component={c} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_BRT':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <BagGraphs t={true} component={c} />
                          </GraphWrapper>
                        </div>
                      )

                    case 'PLANT_DATA_BELT_LOADER':
                    case 'PLANT_DATA_DEC':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                            zeroPadding={true}
                            manuallyOpened={!!(allOpened && allOpened === '1')}
                          >
                            <MultiSeriesLineCharts
                              component={c}
                              measures={
                                chartConfigurations &&
                                plant &&
                                chartConfigurations[plant.plant] &&
                                chartConfigurations[plant.plant][c.id]
                              }
                            />
                          </GraphWrapper>
                        </div>
                      )

                    /** BLUEAIR */
                    case 'SG2_PLANT_DATA_BELT_INVERTER_MONO':
                    case 'SG2_PLANT_DATA_BELT_INVERTER':
                    case 'SG2_PLANT_DATA_BELT_MONO':
                    case 'SG2_PLANT_DATA_BELT':
                    case 'SG2_PLANT_TRITURATORE':
                    case 'SG2_PLANT_MULINO':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={c.nodeType.indexOf('INVERTER') >= 0 ? 'graph2.subTitle' : null}
                          >
                            <BeltBinarySrcGraphs
                              t={true}
                              component={c}
                              measureActCurrentName={
                                c.nodeType === 'SG2_PLANT_TRITURATORE' ? '_MAIN_DRIVERS_CURRENT' : undefined
                              }
                              mono={['SG2_PLANT_DATA_BELT_INVERTER_MONO', 'SG2_PLANT_DATA_BELT_MONO'].includes(
                                c.nodeType
                              )}
                              inverter={[
                                'SG2_PLANT_DATA_BELT_INVERTER_MONO',
                                'SG2_PLANT_DATA_BELT_INVERTER',
                                'SG2_PLANT_TRITURATORE',
                                'SG2_PLANT_MULINO',
                              ].includes(c.nodeType)}
                              hideAvind={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(c.nodeType)}
                              hideSpeed={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(c.nodeType)}
                            />
                          </GraphWrapper>
                        </div>
                      )

                    case 'SG2_PLANT_DEC':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                          >
                            <BeltBinarySrcGenGraphs
                              t={true}
                              component={c}
                              inverter={['SG2_PLANT_DEC'].includes(c.nodeType)}
                              measureActCurrentName={'_MT_DEC_ACT_CUR'}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    case 'SG2_PLANT_BUFFER': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                          >
                            <BufferFillDetail />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    default:
                      return null
                  }
                })}
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }

  private toggleCollapse(id: string) {
    this.setState({
      currentGraph: id && this.state.currentGraph.indexOf(id) > -1 ? [] : [id],
    })

    if (id && this.state.currentGraph.indexOf(id) === -1) {
      setTimeout(() => {
        // itemRefs[id].scrollIntoView()
        // window.scrollBy(0, -203)
      }, 300)
    }
  }

  private handleScroll() {
    const lastScrollY = window.scrollY

    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.setState({
          lastScrollY,
        })
        ticking = false
      })

      ticking = true
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlantAnalysis)))
