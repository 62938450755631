import { APIModel } from '.'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { manageRequestErrors } from '../../../inplant-core-fe/functions'
import FetchError from '../../../inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

export const modelIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_MODEL_IS_FETCHING',
  }
}

export const modelFetchSuccess = (model: any, plant?: string): AnyAction => {
  return {
    type: 'PLANTANALYSIS_MODEL_FETCH_SUCCESS',
    payload: {
      model,
      lastUpdate: new Date().getTime(),
      plant,
    },
  }
}

export const modelFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_MODEL_FETCH_ERROR',
    payload: error,
  }
}

export const modelFetchData = (
  plant?: string
): ((dispatch: Dispatch) => Promise<{ model: any; plant?: string }>) => {
  return async dispatch => {
    dispatch(modelIsFetching())
    return APIModel().request(`/model` + (plant ? '?plantName=' + plant : ''))
      .then((model:any) => {
        dispatch(modelFetchSuccess(model, plant))
        return {
          model,
          plant,
        }
    }).catch((error:FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(modelFetchError(error))
      manageRequestErrors(error, dispatch)
    })
  }
}
