import React from "react";
import { PieLabelRenderProps } from "recharts";

export const renderCustomizedLabel = (o: PieLabelRenderProps) => { 
  const RADIAN = Math.PI / 180
  const {innerRadius, outerRadius, cx, cy, percent, midAngle} = o
  if(innerRadius !== undefined && typeof innerRadius !== 'string' && 
    outerRadius !== undefined && typeof outerRadius !== 'string' && 
    cx !== undefined && typeof cx !== 'string' && 
    cy !== undefined && typeof cy !== 'string' && 
    midAngle !== undefined && typeof midAngle !== 'string' 
    && percent !== undefined && typeof percent !== 'string' ) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    if((percent * 100).toFixed(0) !== '0') {
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  }
};