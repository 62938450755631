import { Reducer } from 'redux'
import { SettingsActions } from '../actions/settings'

export interface SettingsStore {
  isMenuCollapsed?: boolean
}

export const settingsInitialState: SettingsStore = {
  isMenuCollapsed: (window as any).INITIAL_COLLAPSED_MENU || process.env.REACT_APP_INITIAL_COLLAPSED_MENU,
}

export const settingsManager: Reducer<SettingsStore, SettingsActions> = (state=settingsInitialState, action) => {
  switch (action.type) {
    case 'TOGGLE_COLLAPSE_MENU':
      return {
        ...state,
        isMenuCollapsed: action.payload,
      }
    case 'persist/REHYDRATE':
      return {
        ...state,
        isMenuCollapsed: action.payload?.core?.settingsManager?.isMenuCollapsed || false
      }
    default:
      return state
  }
}