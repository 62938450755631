import { COLORS } from '../../../../../constants'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface OwnProps {
  hideTitle?: boolean
  hideZero?: boolean
  labels: string[]
  refLabel?: string | number | null
  row?: boolean
  showTooltip?: { [k: string]: string }
}

type Props = OwnProps & WithTranslation

class BottomLegendView extends React.Component<Props> {
  public render() {
    return (
      <>
        {this.props.labels &&
          this.props.labels.map((legend: string, id: number) => {
            const hasTooltip = this.props.showTooltip && Object.keys(this.props.showTooltip).indexOf(legend) > -1

            return (
              COLORS[legend] && (
                <React.Fragment key={id}>
                  {!this.props.hideTitle && <h3>{this.props.t('plantAnalysis.legendTitles.' + legend)}</h3>}
                  {Object.keys(COLORS[legend]).map((name: string, index: number) => (
                    (
                      !this.props.hideZero ||
                      (this.props.hideZero && name !== '0')
                    ) && <span
                      key={index}
                      className={'mx-3'}
                      title={hasTooltip ? this.props.t(this.props.showTooltip![legend] + name) : name}
                    >
                          <span
                            className='label-color label-color-square'
                            style={{ backgroundColor: COLORS[legend][name] }}
                          />
                        <span>
                            {this.props.t('plantAnalysis.legend.' + legend + '.' + name) + (this.props.refLabel || '')}
                          </span>
                      </span>
                  ))}
                </React.Fragment>
              )
            )
          })}
      </>
    )
  }
  }

  export
  default

  withTranslation()

(
  BottomLegendView,
)
