import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries, Loader } from '../../../../../functions/shared'
import BottomLegend from '../../BottomLegend/BottomLegend/BottomLegendView'
import BeltGraphRowItem from '../BeltGraphRowItem/BeltGraphRowItemView'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'

interface OwnState {
  components: NodeComponent[]
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  measures: string[]
  component: any
  metrics: string[]
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  isFetching: boolean
  fetchErrors: boolean
  configuration: PlantAnalysisGeneralSettings
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class BeltGraphRowView extends React.Component<Props, OwnState> {
  public render() {
    const { model, days } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isFetching
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        [
          'PLANT_DATA_BLT',
          'PLANT_DATA_BELT',
          'PLANT_DATA_BELT_INVERTER',
          'PLANT_DATA_ROT_SCR',
          'PLANT_DATA_ROT_SCR_INVERTER',
        ].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code)
    )

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.props.isFetching && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.props.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-autom belt-graph-row-error">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 mb-3 page-break-inside-avoid">
            <div className="row row-legends pb-1">
              <div className="col-md-1" />
              <div className="col-6 col-md-3">
                <BottomLegend row={true} labels={['marciaFermo']} hideTitle={true} />
              </div>
              <div className="col-6 col-md-3">
                <BottomLegend row={true} labels={['avind']} hideTitle={true} />
              </div>
              <div className="col-md-5">
                <BottomLegend row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </div>
            </div>
          </div>

          {componentsToRender.map(
            (c: ModelNodeData) =>
              (this.props.configuration.nodes === {} ||
                (this.props.configuration.nodes[c.code] && this.props.configuration.nodes[c.code] === true)) && (
                <BeltGraphRowItem
                  data={getInfluxMultiSeries(this.props.data, c.code)}
                  days={days || 1}
                  name={c.label}
                  id={c.code}
                  key={c.code}
                  currentOverrun={getComponentCurrent(c.code, this.props.dataCurrent)}
                  currentLimit={this.props.configuration.settingsFields.sforamento_assorbimento_nastri}
                />
              )
          )}
        </div>
        {/* )} */}
      </React.Fragment>
    )
  }
}

const getComponentCurrent = (id: string, dataCurrent: any) => {
  let out
  if (dataCurrent && dataCurrent.length) {
    const current = dataCurrent.find((e: { name: string }) => e.name.indexOf(id) > -1)

    if (current && current.values && current.values[0]) {
      out = current.values[0][1]
    }
  }

  return out
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BeltGraphRowView)))
