import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { fillWarnArea, hydrateData, hydrateTimeData, populateManAutoLocSec } from '../../../../../functions/series'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
  ComposedChart,
  XAxis,
  CartesianGrid,
  YAxis,
  Line,
  Tooltip,
  LineChart,
} from 'recharts'
import { COLORS } from '../../../../../constants'
import { Loader } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { extractResponseData } from '../../../../../functions/series'
import moment from 'moment'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import { renderCustomizedLabel } from '../../../../../functions/renderCustomizedLabelPie'

export type LineTorqueBeltType = 'LineTorqueBelt'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: LineTorqueBeltType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  torqueBelt: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataTorqueBelt: PieData | null
  fetching: boolean
  error: boolean
  errorDensifier: boolean
  tooltipData: any
  showTooltipLine1: boolean
  showTooltipLine2: boolean
  showTooltipLine3: boolean
  dataDensifier: any
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class TorqueBeltView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      dataDensifier: null,
      torqueBelt: {},
      fetching: false,
      error: false,
      errorDensifier: false,
      dataTorqueBelt: null,
      showTooltipLine3: true,
      showTooltipLine2: true,
      showTooltipLine1: true,
      tooltipData: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: [],
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter(graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3') {
    switch (graph) {
      case 'showTooltipLine1':
        this.setState({
          showTooltipLine1: true,
        })
        return
      case 'showTooltipLine2':
        this.setState({
          showTooltipLine2: true,
        })
        return
      case 'showTooltipLine3':
        this.setState({
          showTooltipLine3: true,
        })
        return
    }
  }

  private handleMouseLeave(graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3') {
    switch (graph) {
      case 'showTooltipLine1':
        this.setState({
          showTooltipLine1: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine2':
        this.setState({
          showTooltipLine2: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine3':
        this.setState({
          showTooltipLine3: false,
          tooltipData: null,
        })
        return
    }
  }

  private handleMouseMove(args: any, graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3') {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curLine1Threshold = curData.line1Threshold !== undefined ? curData.line1Threshold : '--'
        const curLine1Values = curData.line1Values !== undefined ? curData.line1Values : '--'
        const curLine1SpeedValues = curData.line1SpeedValues !== undefined ? curData.line1SpeedValues : '--'
        const curLine2Threshold = curData.line2Threshold !== undefined ? curData.line2Threshold : '--'
        const curLine2Values = curData.line2Values !== undefined ? curData.line2Values : '--'
        const curLine2SpeedValues = curData.line2SpeedValues !== undefined ? curData.line2SpeedValues : '--'
        const curLine3Threshold = curData.line3Threshold !== undefined ? curData.line3Threshold : '--'
        const curLine3Values = curData.line3Values !== undefined ? curData.line3Values : '--'
        const curLine3SpeedValues = curData.line3SpeedValues !== undefined ? curData.line3SpeedValues : '--'

        this.setState({
          showTooltipLine1: graph === 'showTooltipLine1',
          showTooltipLine2: graph === 'showTooltipLine2',
          showTooltipLine3: graph === 'showTooltipLine3',
          tooltipData: {
            line1Threshold: curLine1Threshold,
            line1Values: curLine1Values,
            line1SpeedValues: curLine1SpeedValues,
            line2Threshold: curLine2Threshold,
            line2Values: curLine2Values,
            line2SpeedValues: curLine2SpeedValues,
            line3Threshold: curLine3Threshold,
            line3Values: curLine3Values,
            line3SpeedValues: curLine3SpeedValues,
            line1ThresholdColor: COLORS.lines.torque.thr,
            line1ValuesColor: COLORS.lines.torque.line1,
            line1SpeedValuesColor: COLORS.lines.torque.line1Speed,
            line2ThresholdColor: COLORS.lines.torque.thr,
            line2ValuesColor: COLORS.lines.torque.line2,
            line2SpeedValuesColor: COLORS.lines.torque.line2Speed,
            line3ThresholdColor: COLORS.lines.torque.thr,
            line3ValuesColor: COLORS.lines.torque.line3,
            line3SpeedValuesColor: COLORS.lines.torque.line3Speed,
            time: curData.time,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public render() {
    const { t, hiddenCharts } = this.props
    const { fetching, error, errorDensifier } = this.state

    return (
      <div className="halts-graphs page-break-before-always d-print-block">
        {!fetching && error && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifierTitle')}</h3>
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}

        {!fetching && errorDensifier && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifierTitle')}</h3>
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}
        {!fetching && !errorDensifier && ((this.state.data && this.state.data.length === 0) || !this.state.data) && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifierTitle')}</h3>
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
          </>
        )}
        {!errorDensifier && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('LineTorqueBelt')) &&
              (this.state.fetching ? (
                <Row>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifierTitle')}</h3>
                  <Loader />
                </Row>
              ) : (
                this.state.data && (
                  <div className="">
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifierTitle')}</h3>
                    <div className='d-flex d-flex-row  d-print-block page-break-inside-avoid'>
                      <div className={`col-md-12 col-sm-12 col-lg-12`}>
                        <Row>
                          <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                            <h5 className={'w-100'}>
                              {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine1')}
                            </h5>
                            <ResponsiveContainer width="100%" height={400}>
                              <LineChart
                                data={this.state.filteredData}
                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                onMouseEnter={() => this.handleMouseEnter('showTooltipLine1')}
                                onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine1')}
                                onMouseLeave={() => this.handleMouseLeave('showTooltipLine1')}
                                barGap={0}
                                barCategoryGap={0}
                              >
                                <XAxis
                                  key={'line1'}
                                  hide={false}
                                  dataKey="time"
                                  label={''}
                                  height={30}
                                  axisLine={true}
                                  tickLine={false}
                                  tick={true}
                                  interval={'preserveStartEnd'}
                                  minTickGap={50}
                                  domain={['dataMin', 'dataMax']}
                                  tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                  type={'category'}
                                />
                                <CartesianGrid stroke="#f5f5f5" />

                                <Tooltip content={TorqueBeltView.nullTooltipContent} />
                                <YAxis
                                  domain={[0, 100]}
                                  orientation={'left'}
                                  yAxisId={'meters'}
                                  axisLine={false}
                                  tickFormatter={val => parseInt(val, 10)}
                                />
                                <YAxis
                                  domain={[0, 100]}
                                  orientation={'right'}
                                  yAxisId={'rotation'}
                                  axisLine={false}
                                  tickFormatter={val => parseInt(val, 10)}
                                />
                                <Line
                                  dataKey="line1Threshold"
                                  stroke={COLORS.lines.torque.thr}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'meters'}
                                />
                                <Line
                                  dataKey="line1Values"
                                  stroke={COLORS.lines.torque.line1}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'meters'}
                                />
                                <Line
                                  dataKey="line1SpeedValues"
                                  stroke={COLORS.lines.torque.line1Speed}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'rotation'}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                            <Row>
                              <div className="d-none d-print-inline-flex">
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{
                                        backgroundColor: COLORS.lines.torque.line1,
                                      }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Values')}</span>
                                  </span>
                                </Row>
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{
                                        backgroundColor: COLORS.lines.torque.thr,
                                      }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Threshold')}</span>
                                  </span>
                                </Row>
                              </div>
                            </Row>
                          </div>
                          <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                            {this.state.showTooltipLine1 && this.state.tooltipData !== null && (
                              <ul className="side-tooltip">
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                  <br />
                                  <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                                </li>

                                {this.state.tooltipData.line1Threshold !== null && (
                                  <li>
                                    <strong>
                                      {t('plantAnalysis.lines.engineSpeedDensifier.label.line1Threshold')}
                                    </strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.line1ThresholdColor}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.line1Threshold).toFixed(2)} %</span>
                                  </li>
                                )}

                                {this.state.tooltipData.line1Values !== null && (
                                  <li>
                                    <strong>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Values')}</strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.line1ValuesColor}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.line1Values).toFixed(2)} %</span>
                                  </li>
                                )}
                                {this.state.tooltipData.line1SpeedValues !== null && (
                                  <li>
                                    <strong>
                                      {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line1SpeedValues')}
                                    </strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.line1SpeedValuesColor}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.line1SpeedValues).toFixed(2)} rpm</span>
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        </Row>
                      </div>
                    </div>
                    <div className={'col-lg-12 col-md-12 col-sm-12'}>
                      <div>
                        <span className='mr-5'>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine1') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line1')
                                    ].find((v: { key: string; value: number }) => v.key.includes('percent')).value
                                  ).toFixed(2)}
                        </span>
                        <span>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine1') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line1')
                                    ].find((v: { key: string; value: number }) => v.key.includes('count'))?.value
                                  ).toFixed(0)}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex d-flex-row  d-print-block page-break-inside-avoid'>
                      <div className={`col-md-12 col-sm-12 col-lg-12`}>
                        <div className={'graph-spacing column-break-before'}>
                          <Row>
                            <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                              <h5 className={'w-100'}>
                                {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine2')}
                              </h5>
                              <ResponsiveContainer width="100%" height={400}>
                                <ComposedChart
                                  width={700}
                                  height={400}
                                  data={this.state.filteredData}
                                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                  onMouseEnter={() => this.handleMouseEnter('showTooltipLine2')}
                                  onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine2')}
                                  onMouseLeave={() => this.handleMouseLeave('showTooltipLine2')}
                                  barGap={0}
                                  barCategoryGap={0}
                                >
                                  <XAxis
                                    key={'line2'}
                                    hide={false}
                                    dataKey="time"
                                    label={''}
                                    height={30}
                                    axisLine={true}
                                    tickLine={false}
                                    tick={true}
                                    interval={'preserveStartEnd'}
                                      minTickGap={50}
                                    domain={['dataMin', 'dataMax']}
                                    tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                    type={'category'}
                                  />
                                  <CartesianGrid stroke="#f5f5f5" />

                                  <Tooltip content={TorqueBeltView.nullTooltipContent} />
                                  <YAxis
                                    domain={[0, 100]}
                                    orientation={'left'}
                                    yAxisId={'meters'}
                                    axisLine={false}
                                    tickFormatter={val => parseInt(val, 10)}
                                  />
                                  <YAxis
                                    domain={[0, 100]}
                                    orientation={'right'}
                                    yAxisId={'rotation'}
                                    axisLine={false}
                                    tickFormatter={val => parseInt(val, 10)}
                                  />
                                  <Line
                                    dataKey="line2Threshold"
                                    stroke={COLORS.lines.torque.thr}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'meters'}
                                  />
                                  <Line
                                    dataKey="line2Values"
                                    stroke={COLORS.lines.torque.line2}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'meters'}
                                  />
                                  <Line
                                    dataKey="line2SpeedValues"
                                    stroke={COLORS.lines.torque.line2Speed}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'rotation'}
                                  />
                                </ComposedChart>
                              </ResponsiveContainer>
                              <div className="d-none d-print-inline-flex">
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{ backgroundColor: COLORS.lines.torque.line2 }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Values')}</span>
                                  </span>
                                </Row>
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{ backgroundColor: COLORS.lines.torque.thr }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Threshold')}</span>
                                  </span>
                                </Row>
                              </div>
                            </div>
                            <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                              {this.state.showTooltipLine2 && this.state.tooltipData !== null && (
                                <ul className="side-tooltip">
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                    <br />
                                    <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                                  </li>

                                  {this.state.tooltipData.line2Threshold !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line2Threshold')}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line2ThresholdColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line2Threshold).toFixed(2)} %</span>
                                    </li>
                                  )}

                                  {this.state.tooltipData.line2Values !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line2Values')}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line2ValuesColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line2Values).toFixed(2)} %</span>
                                    </li>
                                  )}
                                  {this.state.tooltipData.line2SpeedValues !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t(
                                          'plantAnalysis.lines.engineSpeedDensifier.label.line2SpeedValues'
                                        )}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line2SpeedValuesColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line2SpeedValues).toFixed(2)} rpm</span>
                                    </li>
                                  )}
                                </ul>
                              )}
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className={'col-lg-12 col-md-12 col-sm-12'}>
                      <div>
                        <span className='mr-5'>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine2') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line2')
                                    ].find((v: { key: string; value: number }) => v.key.includes('percent')).value
                                  ).toFixed(2)}
                        </span>
                        <span>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine2') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line2')
                                    ].find((v: { key: string; value: number }) => v.key.includes('count'))?.value
                                  ).toFixed(0)}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex d-flex-row  d-print-block page-break-inside-avoid'>
                      <div className={`col-md-12 col-sm-12 col-lg-12`}>
                        <div className={'graph-spacing column-break-before'}>
                          <Row>
                            <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                              <h5 className={'w-100'}>
                                {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine3')}
                              </h5>
                              <ResponsiveContainer width="100%" height={400}>
                                <ComposedChart
                                  width={700}
                                  height={400}
                                  data={this.state.filteredData}
                                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                  onMouseEnter={() => this.handleMouseEnter('showTooltipLine3')}
                                  onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine3')}
                                  onMouseLeave={() => this.handleMouseLeave('showTooltipLine3')}
                                  barGap={0}
                                  barCategoryGap={0}
                                >
                                  <XAxis
                                    key={'line3'}
                                    hide={false}
                                    dataKey="time"
                                    label={''}
                                    height={30}
                                    axisLine={true}
                                    tickLine={false}
                                    tick={true}
                                    interval={'preserveStartEnd'}
                                    minTickGap={50}
                                    domain={['dataMin', 'dataMax']}
                                    tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                    type={'category'}
                                  />
                                  <CartesianGrid stroke="#f5f5f5" />

                                  <Tooltip content={TorqueBeltView.nullTooltipContent} />
                                  <YAxis
                                    domain={[0, 100]}
                                    orientation={'left'}
                                    yAxisId={'meters'}
                                    axisLine={false}
                                    tickFormatter={val => parseInt(val, 10)}
                                  />
                                  <YAxis
                                    domain={[0, 100]}
                                    orientation={'right'}
                                    yAxisId={'rotation'}
                                    axisLine={false}
                                    tickFormatter={val => parseInt(val, 10)}
                                  />

                                  <Line
                                    dataKey="line3Threshold"
                                    stroke={COLORS.lines.torque.thr}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'meters'}
                                  />
                                  <Line
                                    dataKey="line3Values"
                                    stroke={COLORS.lines.torque.line3}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'meters'}
                                  />
                                  <Line
                                    dataKey="line3SpeedValues"
                                    stroke={COLORS.lines.torque.line3Speed}
                                    dot={false}
                                    strokeWidth={2}
                                    type="step"
                                    isAnimationActive={false}
                                    yAxisId={'rotation'}
                                  />
                                </ComposedChart>
                              </ResponsiveContainer>
                              <div className="d-none d-print-inline-flex">
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{ backgroundColor: COLORS.lines.torque.line3 }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Values')}</span>
                                  </span>
                                </Row>
                                <Row verticalAlignment="center">
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    <span
                                      className="label-color-square"
                                      style={{ backgroundColor: COLORS.lines.torque.thr }}
                                    />
                                    <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Threshold')}</span>
                                  </span>
                                </Row>
                              </div>
                            </div>
                            <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                              {this.state.showTooltipLine3 && this.state.tooltipData !== null && (
                                <ul className="side-tooltip">
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                    <br />
                                    <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                                  </li>

                                  {this.state.tooltipData.line3Threshold !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line3Threshold')}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line3ThresholdColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line3Threshold).toFixed(2)} %</span>
                                    </li>
                                  )}

                                  {this.state.tooltipData.line3Values !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line3Values')}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line3ValuesColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line3Values).toFixed(2)} %</span>
                                    </li>
                                  )}

                                  {this.state.tooltipData.line3SpeedValues !== null && (
                                    <li>
                                      <strong>
                                        {this.props.t(
                                          'plantAnalysis.lines.engineSpeedDensifier.label.line3SpeedValues'
                                        )}
                                      </strong>
                                      <br />
                                      <span
                                        className="label-color label-color-line"
                                        style={{ backgroundColor: `${this.state.tooltipData.line3SpeedValuesColor}` }}
                                      />
                                      <span>{parseFloat(this.state.tooltipData.line3SpeedValues).toFixed(2)} rpm</span>
                                    </li>
                                  )}
                                </ul>
                              )}
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className={'col-lg-12 col-md-12 col-sm-12'}>
                      <div>
                        <span className='mr-5'>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine3') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line3')
                                    ].find((v: { key: string; value: number }) => v.key.includes('percent')).value
                                  ).toFixed(2)}
                        </span>
                        <span>
                          <b>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine3') + ': '}
                          </b>
                            {this.state.data &&
                                  parseFloat(
                                    this.state.data?.[
                                      this.getKeyFromLine('line3')
                                    ].find((v: { key: string; value: number }) => v.key.includes('count'))?.value
                                  ).toFixed(0)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              ))}
          </React.Fragment>
        )}
        <hr />
        {!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data) && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineTorqueDensifierTitle')}</h3>
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
          </>
        )}
        {!error && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('LineTorqueBelt')) &&
              (this.state.fetching ? (
                <Row>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineTorqueDensifierTitle')}</h3>
                  <Loader />
                </Row>
              ) : (
                this.state.data && (
                  <div
                    className={`col-md-12 col-sm-12 col-lg-12 halts-graphs halts-graphs__vertical`}
                  >
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineTorqueDensifierTitle')}</h3>
                    <div className={'graph-spacing column-break-before'}>
                      <Row>
                        {/* <Column md={10}> */}
                        {this.state.data &&
                          Object.entries(this.state.data).map(([key, value], index) => {
                            const filteredData = (value as any[]).filter((v: any) => !v.key.includes('threshold'))
                            return (
                              <Column key={index} sm={3} md={3} lg={3}>
                                <ResponsiveContainer className={'mx-2'} key={index} width="100%" height={250}>
                                  <PieChart /* width={350} */ height={250}>
                                    <Pie
                                      nameKey={'key'}
                                      dataKey={'value'}
                                      data={filteredData}
                                      cx={130}
                                      cy={125}
                                      isAnimationActive={false}
                                      outerRadius={100}
                                      labelLine={false}
                                      label={renderCustomizedLabel}
                                    >
                                      {(value as any[])
                                        .filter(entry => !entry.key.includes('threshold'))
                                        .map((entry, valueIndex) => {
                                          const label = entry.key.split(`${key}_`)[1]
                                          return <Cell key={entry.key} fill={COLORS.lines.torque[label]} />
                                        })}
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                <h5>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.engines.${key}`)}</h5>
                              </Column>
                            )
                          })}

                        {/* </Column> */}
                      </Row>
                      <Row>
                        <div className="d-flex d-print-inline-flex">
                          <Row verticalAlignment="center">
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <span
                                className="label-color-square"
                                style={{ backgroundColor: COLORS.lines.torque.from_zero_to_seventy_five_percent }}
                              />
                              <span>0 - 75%</span>
                            </span>
                          </Row>
                          <Row verticalAlignment="center">
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <span
                                className="label-color-square"
                                style={{
                                  backgroundColor: COLORS.lines.torque.from_seventy_five_to_eighty_five_percent,
                                }}
                              />
                              <span>75 - 85%</span>
                            </span>
                          </Row>
                          <Row verticalAlignment="center">
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <span
                                className="label-color-square"
                                style={{ backgroundColor: COLORS.lines.torque.from_eighty_five_to_hundred_percent }}
                              />
                              <span>+ 85 %</span>
                            </span>
                          </Row>
                        </div>
                      </Row>
                    </div>
                  </div>
                )
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  private getKeyFromLine(line: 'line1' | 'line2' | 'line3') {
    switch (line) {
      case 'line1':
        return 'dec_f019'
      case 'line2':
        return 'dec_f028'
      case 'line3':
        return 'dec_f057'
      default:
        return ''
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.dataDensifier }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let line1Threshold: any = []
      let line1Values: any = []
      let line1SpeedValues: any = []
      let line2Threshold: any = []
      let line2Values: any = []
      let line2SpeedValues: any = []
      let line3Threshold: any = []
      let line3Values: any = []
      let line3SpeedValues: any = []
      const mergedData: any[] = []
      if (this.state.dataDensifier) {
        line1Threshold = this.populateSingleData('line1Threshold')
        line1Values = this.populateSingleData('line1Values')
        line1SpeedValues = this.populateSingleData('line1SpeedValues')
        line2Threshold = this.populateSingleData('line2Threshold')
        line2Values = this.populateSingleData('line2Values')
        line2SpeedValues = this.populateSingleData('line2SpeedValues')
        line3Threshold = this.populateSingleData('line3Threshold')
        line3Values = this.populateSingleData('line3Values')
        line3SpeedValues = this.populateSingleData('line3SpeedValues')
      }
      hydrateData(
        {
          line1Threshold,
          line1Values,
          line1SpeedValues,
          line2Threshold,
          line2Values,
          line2SpeedValues,
          line3Threshold,
          line3Values,
          line3SpeedValues,
        },
        mergedData,
        undefined,
        0
      )

      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })

      hydrateTimeData(
        [
          'line1Threshold',
          'line1Values',
          'line1SpeedValues',
          'line2Threshold',
          'line2Values',
          'line2SpeedValues',
          'line3Threshold',
          'line3Values',
          'line3SpeedValues',
        ],
        mergedData,
        this.state
      )

      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          fetching: false,
          errorDensifier: false,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          errorDensifier: false,
          data: null,
        })
      }
    }
  }

  private formatData(data: any) {
    const resultData = [
      data.columns
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {}),
    ]
    this.generateKey(resultData[0])
  }

  private generateKey(data: Record<string, any>) {
    try {
      const keys = Object.keys(data)
      const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
      const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
      this.setState({
        keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
        data: splitKey
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(key => ({
            [key]: Object.entries(data)
              .filter(([keyFilter, value]) => keyFilter.includes(key))
              .map(([keyMap, value]) => {
                return {
                  value,
                  key: keyMap,
                }
              }),
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      })
    } catch (error) {
      this.setState({
        torqueBelt: {},
        data: null,
        fetching: false,
        error: false,
      })
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const startDate = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate = workshift?.end ? mvDate.getDateFromString(workshift.end.toString()).toISOString() : endOfDay
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryEndShiftTime = ` WHERE time <= '${startDate}' ORDER BY desc LIMIT 1`
      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const line1Label = 'IBSGN5PHK0N0DCJP'
        const line2Label = 'IBSGN5PHK0OBEPNL'
        const line3Label = 'IBSGN5PKA065B3AF'

        const queryStart = `SELECT * FROM `
        const query = queryStart + `vDecSpeedTorque` + queryEndShift

        const line1Threshold = queryStart + `"${line1Label}_TSHLD_TORQUE_MT_DEC"` + queryEnd
        const line1ThresholdBeforeShift = queryStart + `"${line1Label}_TSHLD_TORQUE_MT_DEC"` + queryEndShiftTime
        const line1VAlues = queryStart + `"${line1Label}_MT_DEC_TORQUE"` + queryEnd
        const line1SpeeValues = queryStart + `"${line1Label}_SPEED_VITI_DEC"` + queryEnd

        const line2Threshold = queryStart + `"${line2Label}_TSHLD_TORQUE_MT_DEC"` + queryEnd
        const line2ThresholdBeforeShift = queryStart + `"${line2Label}_TSHLD_TORQUE_MT_DEC"` + queryEndShiftTime
        const line2VAlues = queryStart + `"${line2Label}_MT_DEC_TORQUE"` + queryEnd
        const line2SpeeValues = queryStart + `"${line2Label}_SPEED_VITI_DEC"` + queryEnd

        const line3Threshold = queryStart + `"${line3Label}_TSHLD_TORQUE_MT_DEC"` + queryEnd
        const line3ThresholdBeforeShift = queryStart + `"${line3Label}_TSHLD_TORQUE_MT_DEC"` + queryEndShiftTime
        const line3VAlues = queryStart + `"${line3Label}_MT_DEC_TORQUE"` + queryEnd
        const line3SpeeValues = queryStart + `"${line3Label}_SPEED_VITI_DEC"` + queryEnd

        const dataTorqueBelt = API().request(`/query?${plantQueryString}q=` + query, {
          signal: this.abortController.signal,
        })

        const dataLine1Threshold = API().request(`/query?${plantQueryString}q=` + line1Threshold, {
          signal: this.abortController.signal,
        })
        const dataLine1ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + line1ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataLine1VAlues = API().request(`/query?${plantQueryString}q=` + line1VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine1SpeedValues = API().request(`/query?${plantQueryString}q=` + line1SpeeValues, {
          signal: this.abortController.signal,
        })
        const dataLine2Threshold = API().request(`/query?${plantQueryString}q=` + line2Threshold, {
          signal: this.abortController.signal,
        })
        const dataLine2ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + line2ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataLine2VAlues = API().request(`/query?${plantQueryString}q=` + line2VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine2SpeedValues = API().request(`/query?${plantQueryString}q=` + line2SpeeValues, {
          signal: this.abortController.signal,
        })
        const dataLine3Threshold = API().request(`/query?${plantQueryString}q=` + line3Threshold)
        const dataLine3ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + line3ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataLine3VAlues = API().request(`/query?${plantQueryString}q=` + line3VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine3SpeedValues = API().request(`/query?${plantQueryString}q=` + line3SpeeValues, {
          signal: this.abortController.signal,
        })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataTorqueBelt,
          dataLine1Threshold,
          dataLine1ThresholdBeforeShift,
          dataLine1VAlues,
          dataLine2Threshold,
          dataLine2ThresholdBeforeShift,
          dataLine2VAlues,
          dataLine3Threshold,
          dataLine3ThresholdBeforeShift,
          dataLine3VAlues,
          dataLine1SpeedValues,
          dataLine2SpeedValues,
          dataLine3SpeedValues,
        ])
          .then(
            ([
              dataTorqueBeltResult,
              dataLine1ThresholdResult,
              dataLine1ThresholdBeforeShiftResult,
              dataLine1VAluesResult,
              dataLine2ThresholdResult,
              dataLine2ThresholdBeforeShiftResult,
              dataLine2VAluesResult,
              dataLine3ThresholdResult,
              dataLine3ThresholdBeforeShiftResult,
              dataLine3VAluesResult,
              dataLine1SpeedValuesResult,
              dataLine2SpeedValuesResult,
              dataLine3SpeedValuesResult,
            ]) => {
              const dataTorqueBeltResultValue =
                typeof dataTorqueBeltResult === 'string' ? JSON.parse(dataTorqueBeltResult) : dataTorqueBeltResult
              if (this.mounted) {
                try {
                  const line1ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataLine1ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine1ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataLine1ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataLine1ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine1ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const line2ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataLine2ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine2ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataLine2ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataLine2ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine2ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const line3ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataLine3ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine3ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataLine3ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataLine3ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataLine3ThresholdBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  this.setState(
                    {
                      dataDensifier: Object.assign({}, this.state.dataDensifier, {
                        line1Threshold: { data: line1ThresholdAgrValue },
                        line1Values: { data: extractResponseData(dataLine1VAluesResult) },
                        line1SpeedValues: { data: extractResponseData(dataLine1SpeedValuesResult) },
                        line2Threshold: { data: line2ThresholdAgrValue },
                        line2Values: { data: extractResponseData(dataLine2VAluesResult) },
                        line2SpeedValues: { data: extractResponseData(dataLine2SpeedValuesResult) },
                        line3Threshold: { data: line3ThresholdAgrValue },
                        line3Values: { data: extractResponseData(dataLine3VAluesResult) },
                        line3SpeedValues: { data: extractResponseData(dataLine3SpeedValuesResult) },
                      }),
                      fetching: false,
                      error: false,
                    },
                    () => {
                      this.constructData()
                    }
                  )
                } catch (error) {
                  this.setState({
                    torqueBelt: {},
                    fetching: false,
                    data: null,
                    errorDensifier: false,
                  })
                }
                try {
                  this.formatData(dataTorqueBeltResultValue.results[0].series[0])
                } catch (error) {
                  this.setState({
                    torqueBelt: {},
                    fetching: false,
                    error: false,
                    data: null,
                  })
                }
              }
              if (dataTorqueBeltResultValue.results[0].error) {
                this.setState({
                  data: null,
                  fetching: false,
                  error: true,
                })
              }
            }
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              torqueBelt: {},
              fetching: false,
              error: true,
              errorDensifier: true,
            })
          })
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          torqueBelt: {},
          fetching: false,
          error: true,
          errorDensifier: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(TorqueBeltView))
