// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Node from './NodeComponent'
import SelectionLogic from './SelectionLogic'
import { LightNode, NodeData, ProcessedNodeData } from '../types'
import { useDidUpdate } from '../../../../functions/hooks/hooksHelper'
import { TreeOtherActions } from '../Tree'
import { IsReadonly } from '../../../../types/base'

interface TreeProps extends TreeOtherActions, IsReadonly {
  data: NodeData
  onChange?: (ids: LightNode[]) => void
  selectionLogic: SelectionLogic
  scrollable?: boolean
  isNewDidMount: boolean
}

const TreeComponent = ({ scrollable = false, ...props }: TreeProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { data } = props
  const [listOpened, setListOpened] = useState<LightNode[]>([{ id: props.data.id, label: props.data.label }])
  const [listSelected, setListSelected] = useState<LightNode[]>(props.selectionLogic.listSelected || [])
  const t = 'mv-treeview'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (listSelected.length > 0 || props.isNewDidMount) {
      props.onChange?.(props.selectionLogic.minimumSetOfNode(props.data))
    }
  }, [])

  useDidUpdate(() => {
    props.onChange?.(props.selectionLogic.minimumSetOfNode(props.data))
  }, [listSelected])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleNodeSelection = (node: ProcessedNodeData, state: boolean) => {
    if (state) {
      setListOpened(prev => [...prev, { id: node.id, label: node.label }])
    } else {
      setListOpened(prev => {
        let newList = [...prev]
        newList = newList.filter(el => el.id !== node.id)
        return newList
      })
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={`${t} ${scrollable ? `${t}--scrollable` : ''}`}>
      <ul className={`${t}__rootlist`}>
        <Node
          {...props}
          key={props.data.id}
          selectedAsConsequence={false}
          nodeLogic={props.selectionLogic.nodeLogic}
          isRoot={true}
          node={{ ...data, parent: undefined, disabled: data.disabled, readonly: props.isReadonly }}
          onSelectionNode={(node, state) => {
            setListSelected(props.selectionLogic.onSelectionNode(node, state))
          }}
          onToggle={handleNodeSelection}
          listOpened={listOpened}
          listSelected={listSelected}
        />
      </ul>
    </div>
  )
}

export default TreeComponent
