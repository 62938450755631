import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { titechFetchData } from '../../../../redux/actions/titech'
import * as moment from 'moment'
import { TitechBeltStatus, TitechItem } from '../../../../types/titech'
import { ChartStatusBadges } from '../../../../functions/shared'

interface OwnProps {
  plant: any | null
}

interface DispatchProps {
  fetchTitechData: (dateFilter: string, signal: AbortSignal) => Promise<any>
}

interface StateProps {
  days: number
  titech: {
    fetching: boolean
    error: boolean
    data: TitechItem[]
  }
  // plant: any | null
  dateFilterStart: null | string
}

type Props = DispatchProps & StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  titech: state.plantAnalysis.titech,
  // plant: state.plantSelector || null,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchTitechData: (dateFilter, signal) => dispatch(titechFetchData(dateFilter, signal)),
  }
}

class TitechView extends React.Component<Props> {
  private abortController: AbortController = new AbortController()
  constructor(props: Props) {
    super(props)
    this.getCalibrationStatus = this.getCalibrationStatus.bind(this)
    this.getProblems = this.getProblems.bind(this)
    this.getTitechBeltStatus = this.getTitechBeltStatus.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (
      nextProps.dateFilterStart &&
      !this.props.plant.isLoading &&
      nextProps.plant.plant &&
      (nextProps.dateFilterStart !== this.props.dateFilterStart || nextProps.plant.plant !== this.props.plant.plant)
    ) {
      this.props.fetchTitechData(nextProps.dateFilterStart, this.abortController.signal)
    }
  }
  
  public componentWillUnmount(): void {
    this.abortController.abort()
  }

  public componentDidMount() {
    if (this.props.plant && this.props.dateFilterStart) {
      this.props.fetchTitechData(this.props.dateFilterStart,this.abortController.signal)
    }
  }

  public render() {
    const { t } = this.props
    const { data, fetching, error } = this.props.titech
    const noData = !fetching && !error && (data === null || data.length === 0)

    return (
      <div className="titech-graphs">
        <div className="row avoid-page-break">
          {ChartStatusBadges('multi-series-line-chart', fetching, error, noData, t)}
          {!fetching &&
          !error &&
          data &&
          data.map((d, i) => {
            const calibration = this.getCalibrationStatus(d)
            const problems = this.getProblems(d)
            const getTitechBeltStatus = d.titechBeltStatus && this.getTitechBeltStatus(d.titechBeltStatus)

            return (
              <div
                className={
                  'single-titech col-lg-3 col-md-6 col-sm-6 mb-4 page-break-inside-avoid' +
                  //  (d.isCalibrationExpired ? ' has-problems' : '') +
                  ' ' +
                  problems.join(' ')
                }
                key={i}
              >
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>{d.code}</h3>
                    {problems.length > 0 && (
                      <span className="color-danger">
                        {t('plantAnalysis.titech.problems', { count: problems.length })}
                      </span>
                    )}
                  </div>
                  <span className="small-text color-light">Cambi Ricetta: {d.recipeChangesCount}</span>
                  {d.mostUsedRecipe && (
                    <div className="d-flex justify-content-between">
                      <span className="small-text font-weight-bold">{d.mostUsedRecipe.name}</span>
                      <span className="small-text color-light">
                          {t('plantAnalysis.titech.mostUsedRecipe', { count: d.mostUsedRecipe.percentage })}
                        </span>
                    </div>
                  )}
                  {!d.mostUsedRecipe && d.lastRecipe && (
                    <div className="d-flex justify-content-between">
                      <span className="small-text font-weight-bold">{d.lastRecipe.name}</span>
                      <span className="small-text color-light">{t('plantAnalysis.titech.lastRecipe')}</span>
                    </div>
                  )}
                  {
                    <span className={`boxed ${calibration.classes} fullspan text-center`}>
                        {t('plantAnalysis.titech.calibration.status.' + calibration.i18nLabel)}
                      </span>
                  }
                  {d.recipeParametersChangesCount > 0 && (
                    <span className="small-text color-light fullspan">
                        {t('plantAnalysis.titech.parameterChanges', { count: d.recipeParametersChangesCount })}
                      </span>
                  )}
                  {!d.lastSuccesfullConnection && (
                    <span className={'color-danger fullspan'}>
                        {t('plantAnalysis.titech.calibration.status.lastCheckNull')}
                      </span>
                  )}
                  {d.lastSuccesfullConnection && (
                    <>
                      <span className="small-text color-light mr-1">{t('plantAnalysis.titech.lastCheck')}</span>
                      <span className="small-text color-light font-weight-bold">
                          <strong>{moment(d.lastSuccesfullConnection).format('DD/MM/YY HH:mm')}</strong>
                        </span>
                    </>
                  )}
                  {getTitechBeltStatus}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  private getCalibrationStatus(t: TitechItem): { classes: string; i18nLabel: string } {
    const cssClasses: string[] = []
    let i18nLabel = 'calibrated'

    if (t.isCalibrationExpired) {
      cssClasses.push('calibration-status-is-expired')
      i18nLabel = 'expired'
    }

    if (t.nextCalibrationDate && moment(t.nextCalibrationDate).isSame(new Date(), 'day')) {
      cssClasses.push('calibration-status-next-calibration-today')
      i18nLabel = 'calibrationToday'
    }

    if (t.lastCheckDate === null) {
      cssClasses.push('calibration-status-last-check-null')
      i18nLabel = 'lastCheckNull'
    }

    return {
      classes: cssClasses.join(' '),
      i18nLabel,
    }
  }

  private getProblems(t: TitechItem): string[] {
    const out: string[] = []

    if (t.maxSeverityValue && t.maxSeverityCount && t.maxSeverityValue >= 50 && t.maxSeverityCount > 0) {
      out.push('has-severity-counters')
    }

    return out
  }

  private getTitechBeltStatus(t: TitechBeltStatus[]) {
    const data: any[] = []
    const getWidthFromMilliseconds = (n: number) => ((n) * 100) / 86400000
    const titechOffAndBeltOn: { time: number, events: number, timeString: string } = {
      time: 0,
      events: 0,
      timeString: '00:00',
    }
    const beltOff: { time: number, events: number, timeString: string } = {
      time: 0,
      events: 0,
      timeString: '00:00',
    }
    const lastValue: {titech?: boolean, belt?: boolean} = {}
    for (let i = 0; i < t.length; i++) {
      let diffMilliseconds = 0
      if (i === (t.length - 1)) {
        const end = (moment(t[i].dateTime).utc()).add(1, 'days').startOf('day')
        diffMilliseconds = end.diff(moment(t[i].dateTime).utc(), 'milliseconds')
      } else {
        diffMilliseconds = moment(t[i + 1].dateTime).utc().diff(moment(t[i].dateTime).utc(), 'milliseconds')
      }
      let className = 'belt-and-titech-on-div'
      if (t[i].belt && !t[i].titech) {
        className = 'belt-on-titech-off-div'
        titechOffAndBeltOn.events = titechOffAndBeltOn.events + 1
        titechOffAndBeltOn.time = titechOffAndBeltOn.time + diffMilliseconds
      } else if (!t[i].belt) {
        className = 'belt-off-div'
        beltOff.events = beltOff.events + 1
        beltOff.time = beltOff.time + diffMilliseconds
      }
      if (lastValue.titech === t[i].titech && lastValue.belt === t[i].belt){
        const lastSeries = data[data.length-1]
        data[data.length-1] = {
          width: lastSeries.width + getWidthFromMilliseconds(diffMilliseconds),
          value: className
        }
      }
      else {
        data.push({
          width: getWidthFromMilliseconds(diffMilliseconds),
          value: className,
        })
        lastValue.belt = t[i].belt
        lastValue.titech = t[i].titech
      }
    }
    if (beltOff.time > 0) {
      beltOff.timeString = beltOff.time > 86399000 ? '24:00:00' : moment.utc(beltOff.time).format('HH:mm:ss')
    }
    if (titechOffAndBeltOn.time > 0) {
      titechOffAndBeltOn.timeString = titechOffAndBeltOn.time > 86399000 ? '24:00:00' : moment.utc(titechOffAndBeltOn.time).format('HH:mm:ss')
    }
    const hours = Array.apply(0, new Array(24)).map((i, index) => index)
    return (
      <>
        <div className="d-flex time-label">
          {hours.map((curr, index) => {
            const showHour = index === 0 || index % 6 === 0
            return (
              <div className={`time-line-tick ${index === 0 && 'has-margin-left' || ''}`} key={`hours_${index}`}>
                <div><span
                  className={`light-gray-text ${showHour && 'font-weight-bold' || ''}`}>{showHour && (index > 9 ? index : `0${index}`) || ''}</span>
                </div>
              </div>
            )
          })}
          <div className="has-margin-right">
            <div><span className="light-gray-text font-weight-bold">00</span></div>
          </div>
        </div>
        <div className="d-flex time-label">
          {hours.map((curr, index) => {
            const bold = index === 0 || index % 6 === 0
            return (
              <div className="time-line-tick" key={`ticks_${index}`}>
                <div><span className={`light-gray-text ${bold && 'bold-tick' || ''}`}>|</span></div>
              </div>
            )
          })}
          <div>
            <div><span className="light-gray-text bold-tick">|</span></div>
          </div>
        </div>
        <div className="time-line-container">
          {data.map((d, index) => (
            <div className={d.value} style={{ width: `${d.width}%` }} key={`titech_${index}_time_line`}/>
          ))}
        </div>
        <div className="d-flex smallest-text align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center">
            <div className="belt-on-titech-off"/>
            {this.props.t('plantAnalysis.titech.beltOnTitechOff')}
          </div>
          <div className="text-right">
            <span className="mr-1">{`${this.props.t('plantAnalysis.titech.events')} `}
              <b>{titechOffAndBeltOn.events}</b></span><span>{`${this.props.t('plantAnalysis.titech.totalTime')} `}<b>{titechOffAndBeltOn.timeString}</b></span>
          </div>
        </div>
        <div className="d-flex smallest-text align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center">
            <div className="belt-off"/>
            {this.props.t('plantAnalysis.titech.beltOff')}
          </div>
          <div className="text-right">
            <span className="mr-1">{`${this.props.t('plantAnalysis.titech.events')} `}
              <b>{beltOff.events}</b></span><span>{`${this.props.t('plantAnalysis.titech.totalTime')} `}<b>{beltOff.timeString}</b></span>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TitechView))
