import { Container } from 'inversify'
import React, { useContext, useRef } from 'react'

export const getContainer: () => Container = () => new Container()

function useLazyRef<T>(resolveValue: () => T): T {
  const ref = useRef<{ v: T }>()
  if (!ref.current) {
    ref.current = { v: resolveValue() }
  }
  return ref.current.v
}

export const TYPES = {
  TranslationService: Symbol.for('TranslationService'),
  IconComponentService: Symbol.for('IconComponentService'),
  StateManagerService: Symbol.for('StateManagerService'),
}

interface ContainerContext {
  container: Container | null
}

const InversifyContext = React.createContext<ContainerContext>({ container: null })

export function useInversifyContainer<T>(): Container
export function useInversifyContainer<T>(resolve: (container: Container) => T): T
export function useInversifyContainer<T>(resolve?: (container: Container) => T): T | Container {
  const { container } = useContext(InversifyContext)
  if (!container) {
    throw new Error(
      'Cannot find Inversify container on React Context. ' + '`Provider` component is missing in component tree.'
    )
  }
  return resolve ? useLazyRef(() => resolve(container)) : container
}

export function useInjection<T>(id: symbol): T {
  return useInversifyContainer(container => container.get<T>(id))
}

interface ContainerProviderPros {
  container: Container
}

/**
 * **Example of implementation**
 * ```js
 *   const container = getContainer()
 *   container.bind<TranslationService>(TYPES.TranslationService).toConstantValue(new TranslationService(i18next))
 *   container.bind<IconComponentService>(TYPES.IconComponentService).toConstantValue(new IconComponentService(FontAwesomeIcon))
 *   <ContainerProvider container={container}>
 *     {MyApplication}
 *   </ContainerProvider>
 * ```
 */

export const ContainerProvider: React.FC<ContainerProviderPros> = ({ container, children }) => (
  <InversifyContext.Provider value={{ container }}>{children}</InversifyContext.Provider>
)
