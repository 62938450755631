import * as React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface BarData {
  [k: string]: number
}

interface BarDataColor {
  [k: string]: string
}

interface OwnProps {
  data: BarData[]
  colors: BarDataColor
}

type Props = OwnProps & WithTranslation

class BarsView extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height={180}>
        <BarChart
          width={130}
          height={200}
          data={this.props.data}
          margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
          barCategoryGap={0}
        >
          <YAxis label={undefined} hide={true} domain={[0, 100]} />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {Object.keys(this.props.data[0]).map((datum, index) => {
            return (
              <Bar
                key={'bar-' + index}
                dataKey={datum}
                stackId={datum.substr(0, 4)}
                fill={this.props.colors[datum]}
                isAnimationActive={false}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default withTranslation()(BarsView)
