import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetGroup,
  AssetsListElement,
  AssetsListFilters,
  AssetStatus,
  CostCenter,
  ListPagination,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Checkbox, Filters, Table, TableRowButtonGroup } from '@mv-submodules/inplant-components-fe'
import { RowInfo } from 'react-table'
import { renderStatus } from '@mv-submodules/inplant-asset-manager-fe/functions/shared'
import moment from 'moment'
import { Tooltip } from 'react-tippy'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'

interface StateProps {
  data: AssetsListElement[]
  isFetching: boolean
  assetStatus: AssetStatus[]
  filters: AssetsListFilters
  pagination?: ListPagination
  pages?: number
  updateFilters: (filters: AssetsListFilters) => void
  onRowClick?: (asset: AssetsListElement) => void
  onPageSizeChange?: (size: number) => void
  onPageChange?: (page: number) => void
  hiddenCols: string[]
  sortableCols?: string[]
  selectedAssets?: string[]
  updateSelectedAsset?: (value: string) => void
  plants: Array<{ code: string; name: string }>
  costCenters: Array<{ plant: string; costCenters: CostCenter[] }>
  manual: boolean
  onSortingChange?: (sortField: string, sortDirection: string) => void
}

interface OwnState {
  pageNumber: number
  pageSize: number
}

type Props = StateProps & WithTranslation

class AssetsTable extends React.Component<Props, OwnState> {

  constructor(props: Props) {
    super(props)

    this.state = {
      pageNumber: (this.props.pagination && this.props.pagination.pageNumber) || 0,
      pageSize: (this.props.pagination && this.props.pagination.pageSize) || 25,
    }

    this.updateFilters = this.updateFilters.bind(this)
    this.updateSearchString = this.updateSearchString.bind(this)
    this.onFetchData = this.onFetchData.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
  }

  public render() {
    const {
      filters,
      t,
      plants,
      assetStatus,
      costCenters,
      data,
      pagination,
      onRowClick,
      pages,
      isFetching,
      hiddenCols,
      sortableCols,
      selectedAssets,
      updateSelectedAsset,
      manual,
    } = this.props
    // const {updateFilters} = this

    const availableCostCenter =
      costCenters && filters.plant && costCenters.find(costCenter => costCenter.plant === filters.plant)

    const filterElements: FilterComponent[] = [
      {
        type: 'SearchInput',
        value: filters.searchString,
        name: 'search-asset',
        id: 'search-asset',
        label: t('assetManager.filters.searchPlaceholderAsset'),
        disabled: isFetching && !filters.searchString,
        onChange: (name, value) => this.updateSearchString((value && (value as string)) || null),
        throttle: 500,
      },
      {
        type: 'Select',
        value: filters.plant,
        name: 'plant',
        id: 'select-plant',
        label: t('assetManager.filters.allPlants'),
        disabled: isFetching,
        onChange: (name, value) => this.updateFilters(name, (value && (value as string)) || null),
        options: {
          defaultOptionDisabled: false,
          defaultOptionLabel: t('assetManager.filters.allPlants'),
          items: plants.map(plant => ({
            value: plant.code,
            label: plant.name,
          })),
        },
      },
      {
        type: 'Select',
        value: filters.costCenter,
        name: 'costCenter',
        id: 'search-cost-center',
        label: t('assetManager.filters.allCostCenter'),
        disabled: isFetching || !filters.plant,
        onChange: (name, value) => this.updateFilters(name, (value && (value as string)) || null),
        options: {
          defaultOptionDisabled: false,
          defaultOptionLabel: t('assetManager.filters.allCostCenter'),
          items:
            (availableCostCenter &&
              availableCostCenter.costCenters.map(costCenter => ({
                value: costCenter.code,
                label: costCenter.name,
              }))) ||
            [],
        },
      },
      {
        type: 'Select',
        value: filters.status,
        name: 'status',
        id: 'select-status',
        label: t('assetManager.filters.allStates'),
        disabled: isFetching,
        onChange: (name, value) => this.updateFilters(name, (value && (value as string)) || null),
        options: {
          defaultOptionDisabled: false,
          defaultOptionLabel: t('assetManager.filters.allStates'),
          items: assetStatus.map(status => ({
            value: status,
            label: t(`assetManager.status.${status}`),
          })),
        },
      },
    ]
    return (
      <>
        <Filters fields={filterElements} />
        <Table
          manualIsFetching={true}
          columns={[
            {
              Cell: ({ original }: RowInfo) => (
                <Checkbox
                  label={''}
                  checked={selectedAssets ? selectedAssets.includes(original.id) : false}
                  value={original.id}
                  onChange={() => updateSelectedAsset!(original.id)}
                />
              ),
              Header: ({ original }: RowInfo) => (
                <Checkbox
                  label={''}
                  checked={selectedAssets && selectedAssets.length ? selectedAssets.length === data.length : false}
                  value={'all'}
                  onChange={() => updateSelectedAsset!('all')}
                />
              ),
              accessor: 'select',
              sortable: false,
              show: !hiddenCols.includes('select'),
            },
            {
              Cell: ({ original }: RowInfo) => <div className="rt-td-cell">{renderStatus(original.status)}</div>,
              Header: t('assetManager.table.header.status'),
              accessor: 'status',
              sortable: !!(sortableCols && sortableCols.includes('status')),
              show: !hiddenCols.includes('status'),
            },
            {
              Cell: ({ original }: RowInfo) => onRowClick !== undefined ? (
                <a onClick={() => onRowClick(original)} className="pointer">
                  {original.assetCode}
                </a>
                ) : original.assetCode,
              Header: t('assetManager.table.header.machineCode'),
              accessor: 'assetCode',
              sortable: !!(sortableCols && sortableCols.includes('assetCode')),
              id: 'assetCode',
              show: !hiddenCols.includes('machineCode'),
            },
            {
              Cell: ({ original }: RowInfo) => original.positionCode,
              Header: t('assetManager.table.header.plantCode'),
              accessor: 'positionCode',
              id: 'positionCode',
              sortable: !!(sortableCols && sortableCols.includes('positionCode')),
              show: !hiddenCols.includes('positionCode'),
            },
            {
              Cell: ({ original }: RowInfo) => original.assetDescription,
              Header: t('assetManager.table.header.assetName'),
              accessor: 'assetDescription',
              sortable: !!(sortableCols && sortableCols.includes('assetDescription')),
              show: !hiddenCols.includes('assetDescription'),
            },
            {
              Cell: ({ original }: RowInfo) => original?.assetData?.model,
              Header: t('assetManager.table.header.assetData.model'),
              accessor: 'assetData.model',
              sortable: !!(sortableCols && sortableCols.includes('assetData.model')),
              show: !hiddenCols.includes('assetData.model'),
            },
            {
              Cell: ({ original }: RowInfo) => original?.assetData?.serialNumber,
              Header: t('assetManager.table.header.assetData.serialNumber'),
              accessor: 'assetData.serialNumber',
              sortable: !!(sortableCols && sortableCols.includes('assetData.serialNumber')),
              show: !hiddenCols.includes('assetData.serialNumber'),
            },
            {
              Cell: ({ original }: RowInfo) => original.costCenter.name,
              Header: t('assetManager.table.header.costCenter'),
              accessor: 'costCenter.name',
              sortable: !!(sortableCols && sortableCols.includes('costCenter.name')),
              show: !hiddenCols.includes('costCenter.name'),
            },
            {
              Cell: ({ original }: RowInfo) =>
                original.activatedOn && moment(original.activatedOn).format('DD/MM/YYYY'),
              Header: t('assetManager.table.header.attivationDate'),
              accessor: 'activatedOn',
              sortable: !!(sortableCols && sortableCols.includes('activatedOn')),
              show: !hiddenCols.includes('activatedOn'),
            },
            {
              Cell: ({ original }: RowInfo) =>
                original.activatedOn &&
                original.deactivatedOn &&
                `${moment(original.activatedOn).format('DD/MM/YYYY')} - ${moment(original.deactivatedOn).format(
                  'DD/MM/YYYY'
                )}`,
              Header: t('assetManager.table.header.timeActive'),
              accessor: 'activatedOn',
              sortable: !!(sortableCols && sortableCols.includes('timeActive')),
              show: !hiddenCols.includes('timeActive'),
            },
            {
              Cell: ({ original }: RowInfo) => {
                const groups =
                  original && original.groups && original.groups.length > 0
                    ? original.groups
                        .reduce((acc: string[], group: AssetGroup) => {
                          acc.push(group.name)
                          return acc
                        }, [])
                        .join(',')
                    : ''
                return (
                  <Tooltip className="rt-td" title={groups} disabled={!groups} arrow={true}>
                    {original.groups.length}
                  </Tooltip>
                )
              },
              Header: t('assetManager.table.header.groups'),
              accessor: 'groups',
              sortable: !!(sortableCols && sortableCols.includes('groups')),
              show: !hiddenCols.includes('groups'),
            },
            {
              Header: '',
              sortable: false,
              show: onRowClick !== undefined,
              Cell: ({ original}: RowInfo) => onRowClick && (
                <TableRowButtonGroup
                  buttons={[{
                    variant: 'secondary-alternate',
                    icon: "eye",
                    onClick: () => onRowClick(original)
                  }]}
                />
              )
            }
          ]}
          isFetching={isFetching}
          noDataText={t(
            `assetManager.table.${Object.keys(filters).some(key => !filters[key]) ? 'noItemFound' : 'noData'}`
          )}
          ofText={t('assetManager.table.of')}
          rowsText={t('assetManager.table.rows')}
          pageText={t('assetManager.table.page')}
          data={data}
          pageSize={pagination ? pagination.pageSize : 10}
          page={pagination ? pagination.pageNumber : this.state.pageNumber}
          pages={pages}
          manual={manual}
          showPaginationTop={true}
          onPageSizeChange={this.onPageSizeChange}
          onPageChange={this.onPageChange}
          onFetchData={this.onFetchData}
          defaultSorted={
            this.props.pagination && this.props.pagination.sortField && this.props.pagination.sortDirection
              ? [
                  {
                    id: this.props.pagination.sortField,
                    desc: this.props.pagination.sortDirection === 'desc',
                  },
                ]
              : undefined
          }
        />
      </>
    )
  }

  private updateFilters(name: string, value: string | null) {
    if (name === 'plant') {
      this.props.updateFilters({
        ...this.props.filters,
        [name]: value,
        costCenter: null,
      })
    } else {
      this.props.updateFilters({
        ...this.props.filters,
        [name]: value,
      })
    }
  }

  private updateSearchString(searchString: string | null) {
    this.props.updateFilters({
      ...this.props.filters,
      searchString,
    })
  }

  private onFetchData(state: any, instance: any) {
    const [sorting] = state.sorted
    if (this.props.onSortingChange && !this.props.isFetching && sorting) {
      this.props.onSortingChange(sorting.id, sorting.desc ? 'desc' : 'asc')
    }
  }

  private onPageChange(page: number) {
    if (this.props.onPageChange) {
      this.props.onPageChange(page)
    } else {
      this.setState({
        pageNumber: page,
      })
    }
  }

  private onPageSizeChange(pageSize: number) {
    if (this.props.onPageSizeChange) {
      this.props.onPageSizeChange(pageSize)
    } else {
      this.setState({
        pageSize,
      })
    }
  }
}

export default withTranslation()(AssetsTable)
