import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import BagGraphMeasureDistribution from '../../BagGraph/BagGraphMeasureDistribution/BagGraphMeasureDistributionView'
import PercentsPie, { PieDataItem } from '../../charts/PercentsPie/PercentsPieView'
import { COLORS } from '../../../../../constants'
import { API } from '../../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { isJSON, Loader } from '../../../../../functions/shared'
import { MeasureDistributionBarData } from '../../charts/MeasureDistributionBar/MeasureDistributionBarView'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnProps {
  measure: "vDailyBrt3081" | "vDailyBrt3082" | "vDailyBrt"
  dateEnd: string
  dateStart: string
  days: number
  plant: any | null
}

interface OwnState {
  measureDistributionData: MeasureDistributionBarData[] | undefined
  deviazioneData: PieDataItem[] | undefined
  isFetching: boolean
  fetchErrors: boolean
}

const cleanState = {
  measureDistributionData: undefined,
  deviazioneData: undefined,
  fetchErrors: false,
  isFetching: false,
}

type Props = OwnProps & WithTranslation

class BagSpeedDistributionGeneralView extends React.Component<Props, OwnState> {
  private mounted: boolean = false
  private abortController: AbortController = new AbortController()
  constructor(props: Props) {
    super(props)
    this.state = cleanState
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.state.isFetching &&
      nextProps &&
      this.props &&
      !nextProps.plant.isLoading &&
      (nextProps.plant.plant !== this.props.plant.plant ||
        (nextProps.dateStart && nextProps.dateStart !== this.props.dateStart))
    ) {
      this.getData(this.props)
    }
  }

  public render() {
    const { t } = this.props
    const { measureDistributionData, deviazioneData, fetchErrors, isFetching } = this.state
    return (
      <React.Fragment>
        <h5 className="measure-distribution-graph-title mt-5">
          {t('plantAnalysis.labels.bagGraphMeasureDistributionBar.title')}
        </h5>
        <Row>
          {isFetching && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto bagspeed-graph-fetch-loading alert-local">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {!(
            measureDistributionData &&
            deviazioneData &&
            measureDistributionData.length > 0 &&
            deviazioneData.length > 0
          ) &&
            !isFetching &&
            !fetchErrors && (
              <div className="alert alert-warning w-100 col-sm-6 mx-auto bagspeed-graph-fetch-warning alert-local">
                {this.props.t('plantAnalysis.noDataAvailable')}
              </div>
            )}
          {!isFetching && fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bagspeed-graph-fetch-error alert-local">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
          <>
          {measureDistributionData &&
            deviazioneData &&
            measureDistributionData.length > 0 &&
            deviazioneData.length > 0 && (
              <React.Fragment>
                <Column md={6}>
                  <BagGraphMeasureDistribution data={measureDistributionData} customSettingsFieldsCode={this.props.measure === "vDailyBrt3081" || this.props.measure === "vDailyBrt3082" ? "308*" : undefined} />
                </Column>
                <Column md={6}>
                  <PercentsPie data={deviazioneData} customSettingsFieldsCode={this.props.measure === "vDailyBrt3081" || this.props.measure === "vDailyBrt3082" ? "308*" : undefined} />
                </Column>
              </React.Fragment>
            )}
          </>
        </Row>
      </React.Fragment>
    )
  }

  private async getData(props: Props) {
    if (!this.state.isFetching && props && props.dateStart) {
      this.setState({ isFetching: true })
      try {
        const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''
        const query =
          this.props.days > 1
            ? `SELECT * FROM ${props.measure} WHERE time >= '${props.dateStart}' and time <= '${props.dateEnd}' `
            : `SELECT * FROM ${props.measure} WHERE time = '${props.dateStart}'`
        const result = await API().request(`/query?${plantQuery}q=${query}`, {
          signal: this.abortController.signal,
        })
        const data = isJSON(result) && JSON.parse(result)
        const mockMeasureDistributionData = [
          { key: 'low', color: '#720707', chartLabel: 'bagGraphMeasureDistributionBar' },
          {
            key: 'mid',
            color: '#c7f5bc',
            chartLabel: 'bagGraphMeasureDistributionBar',
          },
          { key: 'high', color: '#fbc109', chartLabel: 'bagGraphMeasureDistributionBar' },
        ]
        const mockDeviazioneData = [
          { key: 'under', color: COLORS.deviazione.under, chartLabel: 'deviazione' },
          {
            key: 'over',
            color: COLORS.deviazione.over,
            chartLabel: 'deviazione',
          },
        ]
        // @todo change for range
        if (data.results && data.results[0] && data.results[0].series && data.results[0].series[0]) {
          const columns = data.results[0].series[0].columns as string[]
          const values = data.results[0].series[0].values as Array<Array<number | null>>

          const calculatedValues: Array<null | number> = []

          values.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
              if (typeof cell === 'string') {
                calculatedValues[cellIndex] = null
              } else if (typeof cell === 'number') {
                if (!calculatedValues[cellIndex]) {
                  calculatedValues[cellIndex] = cell
                } else {
                  // @ts-ignore
                  calculatedValues[cellIndex] = calculatedValues[cellIndex] + cell
                }
              }
            })
          })

          calculatedValues.forEach((cell, cellIndex) => {
            if (columns[cellIndex].indexOf('_perc') !== -1 && calculatedValues[cellIndex]) {
              // @ts-ignore
              calculatedValues[cellIndex] = calculatedValues[cellIndex] / props.days
            }
          })

          const measureDistributionData: MeasureDistributionBarData[] = BagSpeedDistributionGeneralView.prepareData(
            mockMeasureDistributionData,
            columns,
            calculatedValues
          )
          const deviazioneData: PieDataItem[] = BagSpeedDistributionGeneralView.prepareData(
            mockDeviazioneData,
            columns,
            calculatedValues
          )

          // @todo percents per range
          // @todo check mounted
          if (this.mounted) {
            this.setState({
              measureDistributionData,
              deviazioneData,
              isFetching: false,
            })
          }
        } else {
          if (this.mounted) {
            this.setState({
              measureDistributionData: [],
              deviazioneData: [],
              isFetching: false,
            })
          }
        }
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isFetching: false,
            fetchErrors: true,
          })
        }
      }
    } else {
      if (this.mounted) {
        this.setState({
          isFetching: false,
        })
      }
    }
  }

  private static prepareData(mockData: any, columns: string[], values: Array<null | number>) {
    const data: any = []
    mockData.forEach((obj: { key: string; color: string; chartLabel?: string, value?: string | number }) => {
      const durationIndex = columns.indexOf(obj.key + '_duration')
      const percIndex = columns.indexOf(obj.key + '_perc')
      if (durationIndex && percIndex) {
        data.push({
          key: obj.key,
          chartLabel: obj.chartLabel,
          color: obj.color,
          duration: values[durationIndex] as number,
          perc: values[percIndex] as number,
          value: obj.value || null
        })
      }
    })
    return data.find((value: any) => value.duration > 0) ? data : undefined
  }
}

export default withTranslation()(BagSpeedDistributionGeneralView)
