import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { DoneBatchForm, FormData, ValidateBatchForm } from '../../types/pageForm'
import { User } from '../../types/chronoTasks'
import { API } from './index'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

const isAssigningTask = (ids: string[]): AnyAction => ({
  type: 'CHRONO_FRAME_TASKS_ASSIGNING',
  ids,
})

const taskAssignmentSuccess = (assigneeUser: User, ids: string[]): AnyAction => ({
  type: 'CHRONO_FRAME_TASKS_ASSIGNMENT_SUCCESS',
  assigneeUser,
  ids,
})

const taskAssignmentError = (error: Error, ids: string[]): AnyAction => ({
  type: 'CHRONO_FRAME_TASKS_ASSIGNMENT_FAILURE',
  error,
  ids,
})

export const assignTask = (assigneeUser: User, ids: string[]) => async (dispatch: Dispatch) => {
  dispatch(isAssigningTask(ids))

  try {
    // // const result: FormData = await API().request(
    // await API().request(
    //   `/chrono-frame/chrono-tasks/assignement`,
    //   {
    //     method: 'POST',
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Content-Language": "it-IT",
    //     },
    //     body: JSON.stringify({
    //       ids,
    //       assigneeUser,
    //     }),
    //   }
    // )
    await new Promise(resolve => setTimeout(resolve, 500))
    dispatch(taskAssignmentSuccess(assigneeUser, ids))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(taskAssignmentError(error, ids))
    }
    throw error
  }
}

// const isChangingStatus = (ids: string[]): AnyAction => ({
//   type: 'CHRONO_FRAME_TASKS_STATUS_ARE_CHANGING',
//   ids,
// })
//
// const changeStatusSuccess = (action: string, data: FormData, ids: string[]): AnyAction => ({
//   type: 'CHRONO_FRAME_TASKS_STATUS_CHANGE_SUCCESS',
//   action,
//   data,
//   ids,
// })
//
// const changeStatusError = (error: Error, ids: string[]): AnyAction => ({
//   type: 'CHRONO_FRAME_TASKS_STATUS_CHANGE_FAILURE',
//   error,
//   ids,
// })

export const changeStatus = (action: string, data: FormData) => async (dispatch: Dispatch) => {
  return await API().request(`/chrono-frame/chrono-tasks/${action}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Content-Language': 'it-IT',
    },
    body: JSON.stringify({
      ...data
    }),
  })
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
  // here it's the api call from the actionModal,
  // in theory every button added it's already sending data to the right endpoint, with all the informations needed
  // try {
  //   // const result: FormData = await API().request(
  //   await API().request(`/chrono-frame/chrono-tasks/${action}`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Content-Language': 'it-IT',
  //     },
  //     body: JSON.stringify({
  //       ...data,
  //       ids,
  //     }),
  //   })
  //   // await new Promise(resolve => setTimeout(resolve, 2000))
  //   dispatch(changeStatusSuccess(action, data, ids))
  // } catch (error) {
  //
  // }
}

export const changeStatusMultiple = (action: string, data: DoneBatchForm[] | ValidateBatchForm[]) => (dispatch: Dispatch) => {
  return API().request(`/chrono-frame/chrono-tasks/batch/${action}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Content-Language': 'it-IT',
    },
    body: JSON.stringify(data),
  })
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}
