const it = {
  navigation: {
    titech: 'Titech',
    list: 'Lista',
    setup: 'Setup'
  },
  pageHeader: {
    back: 'Indietro',
  },
  setup: {
    title: "Setup Titech",
    status: "Stato",
    assetCode: "Cod. Macchina",
    beltId: "Nastro Collegato",
    positionCode: "Cod. Impianto",
    ipAddress: "Indirizzo IP",
    username: "Username",
    password: "Password",
    logFileFolder: "Cartella File Log",
    recipeFileName: "Cartella File Ricetta",
    calibrationFrequency: "Frequenza Calibrazione",
    model: "Modello",
    noData: 'Nessun dato da visualizzare',
    searchPlaceholder: 'Cerca per codice o modello',
    actions: '',
    configure: 'Configura',
    statusLabels: {
      tobeconfigured: "DA CONFIGURARE",
      deactivated: "DISATTIVATO",
      active: "ATTIVO E CONFIGURATO",
      tobevalidated: "ATTESA VALIDAZIONE",
      undefined: "UNDEFINED"
    },
    modal:{
      title: 'Modifica configurazione asset',
      close: 'Annulla',
      save: 'Salva',
      checkConnection: 'Verifica',
      validateBeforeSave: 'Verifica i dati prima di salvare'
    },
    notifications: {
      valid: 'La verifica ha avuto esito positivo',
      invalid: 'La verifica ha avuto esito negativo',
      saved: 'Configurazione salvata',
      notSaved: 'C\'è stato un errore salvando la configurazione'
    },
  },
  list: {
    title: 'Lista Titech',
    searchPlaceholder: 'Cerca per codice o modello',
    noData: 'Nessun dato da visualizzare',
    viewDetails: 'Dettagli',
    numberOfLogsInLast24h: 'Segnalazioni 24h',
    numberOfLogInLast24h: '{{count}} segnalazione',
    numberOfLogInLast24h_plural: '{{count}} segnalazioni',
    page: {
      of: 'di',
      rows: '{{rows}} righe',
      page: 'Pagina',
    },
  },
  detail: {
    title: 'Titech: {{title}}',
    codeAbbr: 'Cod.',
    model: 'Modello',
    numberOfLogsInLast24h: 'Segnalazioni 24h',
    logsCount: 'Segnalazioni',
    lastCheck: 'Ultimo controllo',
    lastRecipe: 'Ultima ricetta',
    recipesHistory: 'Storico eventi',
    lastCalibration: 'Ultima taratura',
    nextCalibration: 'Prossima taratura',
    calibrationsHistory: 'Storico tarature',
    close: 'Chiudi',
    checkLogs: 'Log segnalazioni',
    last24h: 'Ultime 24 ore',
    recipe: 'Ricetta',
    recipes: 'Eventi',
    calibrations: 'Tarature',
    number: 'Numero',
    logSeveritiesCounts: 'Contatori gravità',
    log: {
      severity: 'Gravità',
      severityLevel: {
        Highest: 'massima',
        High: 'alta',
        Medium: 'media',
        Normal: 'normale',
      },
      date: 'Data',
      errors: 'Errori',
    },
    changes: {
      added: 'aggiunto {{variant}}',
      removed: 'rimosso {{variant}}',
      changed: 'da {{from}} a {{to}}',
    },
  },
}

export default { titech: it }
