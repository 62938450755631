import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { User } from '../../../../auth'
import {Badge} from "@mv-submodules/inplant-components-fe";
import UserProfileMenu from '../UserProfileMenu/UserProfileMenu'
import { RoutePropsCoreWithLegacy } from '../../../../types/routes'
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export interface TopBarStateProps {
  loginSuccess: boolean
  mobile: boolean
  user: User | null
}

export interface TopBarOwnProps {
  routes: RoutePropsCoreWithLegacy[]
}

export type TopBarProps = TopBarStateProps & TopBarOwnProps

const mapStateToProps = (state: any): TopBarStateProps => ({
  loginSuccess: state.auth.loggedIn,
  mobile: state.auth.mobile,
  user: state.auth.user,
})

export class TopBar extends React.Component<TopBarProps> {
  private getUserProfileLinks(routeProps: RoutePropsCoreWithLegacy[]): RoutePropsCoreWithLegacy[] {
    const { user } = this.props
    const result = routeProps
      .map((route: RoutePropsCoreWithLegacy) => {
        if (route.children) {
          return this.getUserProfileLinks(route.children)
        }
        if (
          (route.aclActionKey &&
            user &&
            !user.forbiddenActions.includes(route.aclActionKey) &&
            route.visibleInUserProfile) ||
          (!route.aclActionKey && route.visibleInUserProfile)
        ) {
          return route
        }
        return null
      })
      .filter(route => route !== null)
      .reduce(
        (acc: RoutePropsCoreWithLegacy[], cur: RoutePropsCoreWithLegacy[] | RoutePropsCoreWithLegacy | null) => acc.concat(cur as RoutePropsCoreWithLegacy | RoutePropsCoreWithLegacy[]),
        []
      )

    return result as RoutePropsCoreWithLegacy[]
  }

  public render() {
    if (this.props.mobile) {
      return null
    }
    let showBannerTestMode = false

    if (REACT_APP_API_BASE_URL && ( REACT_APP_API_BASE_URL.includes('-test') || REACT_APP_API_BASE_URL.includes('wip'))) {
      showBannerTestMode = true
    }

    return (
      <nav
        id="topBar"
        className="topbar d-flex flex-row justify-content-between justify-content-start align-items-center"
      >
        {this.props.loginSuccess && <div id="widget-area-topbar-right" />}
        {showBannerTestMode && <div className={"ml-3"}><Badge type={'informative'} value={'TESTING'} subvalue={`BASE_URL: ${REACT_APP_API_BASE_URL}`} /></div>}
        {this.props.loginSuccess ? (
          <div className="ml-auto" id="navbarNav">
            <UserProfileMenu links={this.getUserProfileLinks(this.props.routes)} />
          </div>
        ) : null}
      </nav>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps)(TopBar))
