// * -------------------------------- NPM --------------------------------------
import React from 'react'

const InplantWrapper = <P extends object>(Component: React.ComponentType<P>) => (props: P) => {
  return (
    <div className={'inplant-wrapper'}>
      <Component {...props} />
    </div>
  )
}

function attachInplantWrapperTo<T extends { component?: any; children?: T[] }>(route: T): T {
  return {
    ...route,
    component: route.component ? InplantWrapper(route.component) : undefined,
    children: route?.children?.map(attachInplantWrapperTo),
  }
}

export default attachInplantWrapperTo
