const it = {
  chronoframe: {
    dueDateModule: {
      scadenziario: 'Scadenziario',
      anomalie: 'Anomalie',
    },
    pageSlug: {
      scadenziario: {
        manager: 'Impostazioni scadenze',
        ongoing: 'Scadenze in essere',
        overdue: 'Scadenze in ritardo',
        'to-validate': 'Scadenze da validare',
        archive: 'Archivio',
        rejected: 'Scadenze rifiutate',
        calendar: 'Calendario',
        example: 'Esempio',
        'user-tasks': 'Le mie scadenze',
      },
      anomalie: {
        'anomalie-ongoing': 'Anomalie in essere',
        'anomalie-overdue': 'Anomalie in ritardo',
        'anomalie-archive': 'Archivio',
        'anomalie-rejected': 'Anomalie rifiutate',
      },
    },
    status: {
      done: 'FATTO',
      overdue: 'SCADUTO',
      planned: 'DA FARE',
      unhandled: 'DA CONFERMARE',
      postponed: 'DA FARE',
      rejected: 'RIFIUTATO',
      todo: 'DA FARE',
      tovalidate: 'DA VALIDARE',
      canceled: 'ELIMINATO',
      differentDays: {
        postponed: 'entro {{days}} gg',
        todo: 'entro {{days}} gg',
        planned: 'entro {{days}} gg',
        overdue: 'da {{days}} gg',
      },
    },
    fetching: 'Caricamento',
    periodicity: {
      '1_DAY': '1 giorno',
      '1_WEEK': '1 settimana',
      '2_WEEKS': '2 settimane',
      '1_MONTH': '1 mese',
      '2_MONTHS': '2 mesi',
      '3_MONTHS': '3 mesi',
      '6_MONTHS': '6 mesi',
      '1_YEAR': '1 anni',
      '2_YEARS': '2 anni',
    },
    jobType: {
      custom: 'Puntuale',
      maintenance: 'Adempimento',
      anomaly: 'Anomalia checklist',
    },
    scheduledJobDetail: {
      title: 'Dettagli intervento',
      renewal: 'Rinnovo',
      fromRenewal: 'Da rinnovo',
      buttons: {
        markAsDone: 'Segna come fatto',
        reschedule: 'Riprogramma',
        reject: 'Rifiuta',
      },
      images: 'Immagini',
    },
    errors: {
      somethingGoneWrong: 'Spiacente — qualcosa è andato storto.',
      contactOurTeam: 'Si prega di contattare il nostro team per notificare il problema',
      targetNotFound: 'Il target selezionato sembra non essere presente oppure eliminato',
    },
    tasks: {
      table: {
        advancedFilters: 'Filtri avanzati',
        searchPlaceholder: 'Cerca fra i valori nella tabella',
        noData: 'Dati non disponibili',
        noItemFound: 'Nessun elemento trovato',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        actions: 'Azioni',
        applyFilters: 'Filtra',
        cancel: 'Annulla',
        resetFilters: 'Azzera filtri',
        checkAll: 'Seleziona tutto',
        uncheckAll: 'Deseleziona tutto',
      },
      form: {
        cancel: 'Annulla',
        save: 'Salva',
      },
      actions: {
        reject: 'Rifiuta',
        cancel: 'Elimina',
        reschedule: 'Riprogramma',
        done: 'Segna come fatto',
        'done-batch': 'Segna come fatti',
        'reschedule-batch': 'Riprogramma in blocco',
        validated: 'Segna come validato',
        'validate-batch': 'Segna come validati',
        'fail-validation': 'Rifiuta validazione',
        validate: 'Valida',
        acknowledge: 'Conferma',
        undo: 'Annulla ultima azione', // rivedere traduzione
        'add-note': 'Aggiungi nota',
        addNoteOrAttachment: 'Aggiungi nota o allegati',
        actionsButton: 'Azioni',
        edit: 'Modifica',
        create: 'Crea a partire da questa',
        successBatchActionTitle:
          'Richiesta avvenuta con successo.',
        successBatchActionText: 'Il sistema la sta elaborando e a breve verrà completata.',
      },
      batchActions: {
        actions: 'Azioni',
        selectTask: 'Seleziona scadenza',
        noActionAvailable: 'Non ci sono azioni multiple disponibili per le scadenze selezionate',
      },
    },
    dueDates: {
      title: 'Scadenze in essere',
      createNew: 'Crea nuova scadenza',
      exportData: 'ESPORTA DATI',
      newDueDates: 'Nuova scadenza',
      newDueDatesFrom: 'Nuova scadenza a partire da {{name}}',
      me: 'Me',
      tableList: {
        dueAtDate: 'Scadenza',
        doneAtDate: 'Data esecuzione',
        effectiveFromDate: 'Emissione',
        status: 'Stato',
        category: 'Categoria',
        automaticRenewal: 'Rinnovo',
        attachments: 'Allegati',
        inChargeUser: 'Responsabile',
        target: 'Cespite',
        lastNotes: 'Note',
        creatorNotes: 'Descrizione',
        description: 'Descrizione',
        activity: 'Attività',
        assigneeUser: 'Assegnatario',
        assignToMe: 'Prendi in carico',
        assignTo: 'Assegna a...',
        plannedDate: 'Pianificato',
        every: 'OGNI',
        the: 'Il giorno',
        yes: 'Sì',
        no: 'No',
        today: 'OGGI',
        isAcknowledged: 'Confermato',
        yesterday: 'IERI',
        tomorrow: 'DOMANI',
        singleDay: 'GIORNO',
        multipleDays: 'GG',
        singleMonth: 'MESE',
        multipleMonths: 'MESI',
        singleYear: 'ANNO',
        multipleYears: 'ANNI',
        from: 'DA',
        in: 'TRA',
        plantId: 'ID',
        hasClosingAttachments: '',
        repetitionRule: 'Rinnovo automatico',
        dayOfMonth: "Giorno del mese",
        customProperties: {
          costCenter: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
          checklist: 'Checklist',
          nomeOperatore: 'Nome Operatore',
          noteOperatore: 'Note Operatore',
        },
      },
      formField: {
        validationError: {
          title: 'Errore di validazione',
          description: 'Verificare la correttezza dei dati immessi',
          invalidField: 'Il valore non è corretto',
        },
        dateValidationError: {
          title: 'Date non valide',
          description: 'Verificare la correttezza delle date immesse',
          invalidField: 'Deve essere successiva alla data di emissione',
        },
        repetitionDaysValidationError: {
          title: 'Valore non ammesso',
          description: 'Inserire un valore valido',
          invalidField: 'Valore non ammesso',
        },
        destination: 'Impianto',
        description: 'Descrizione',
        dueAtDate: 'Data scadenza',
        rescheduleDate: 'Data pianificazione',
        startDueAtDate: 'Inizio data di scadenza',
        endDueAtDate: 'Fine data di scadenza',
        status: 'Stato',
        effectiveFromDate: 'Data emissione',
        date: 'Data esecuzione',
        activity: 'Attività',
        context: 'Ambito',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        target: {
          group: 'Gruppo cespiti',
          asset: 'Cespiti',
          dueDate: 'Data scadenza',
        },
        splitTargets: 'Crea una scadenza per ogni cespite',
        rolesWithVisibility: 'Visibilità ruoli',
        rolesWithAction: 'Ruoli Assegnatari',
        inChargeUser: 'Responsabile',
        mustBeValidated: 'Richiede validazione',
        rolesWithValidation: 'Ruolo validatore',
        automaticRenewal: 'Rinnovo automatico',
        repetitionFromDueDate: 'Rinnovo alla data di scadenza',
        repetitionFromDoneDate: 'Rinnovo alla data in cui è stato fatto',
        repetitionRule: {
          method: 'Rinnovo automatico',
          period: 'Periodo di rinnovo',
          days: 'A distanza di giorni',
          daysNumber: 'Giorni',
          monthsNumber: 'Mesi',
          dayOfMonth: 'Giorno del mese',
          months: 'A distanza di mesi',
        },
        previousRepetition: 'Rinnovo prec.',
        noticeMessage: 'Invia preavviso',
        notificationDaysAdvance: 'Notifica giorni prima della scadenza',
        creatorNotes: 'Note',
        notes: 'Note',
        dateAlert: 'Non può essere antecedente alla data di emissione',
        customProperties: {
          costCenter: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
          checklist: 'Checklist',
          nomeOperatore: 'Nome Operatore',
          noteOperatore: 'Note Operatore',
        },
      },
      detail: {
        edit: 'Modifica scadenza',
        destination: 'Impianto',
        details: 'Dettagli',
        plannedDate: 'Pianificato',
        acknowledged: 'Confermato',
        rescheduled: 'Riprogrammato',
        create: 'Nuova scadenza a partire da questa',
        actionHistory: 'Storico azioni e note',
        edited: 'Scadenza modificata',
        created: 'Scadenza creata',
        done: 'Scadenza completata',
        doneDate: 'Scadenza eseguita',
        canceled: 'Scadenza eliminata',
        rejected: 'Scadenza rifiutata',
        validated: 'Scadenza validata',
        validationFailed: 'Validazione rifiutata',
        addNote: 'Aggiunta nota',
        actions: 'Azioni',
        cancelAction: 'Annulla operazione',
        canceledAction: "Quest'operazione è stata annullata",
        prevTask: 'PRECEDENTE IN LISTA',
        nextTask: 'SUCCESSIVA IN LISTA',
        dueAtDate: 'Scadenza',
        effectiveFromDate: 'Emissione',
        lastNotes: {
          notes: 'Ultima nota',
        },
        isAcknowledged: 'Confermato',
        activity: 'Attività',
        status: 'Stato',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        repetitionRule: 'Rinnovo automatico',
        childTask: {
          dueAtDate: 'Scadenza successiva',
        },
        addAttachments: 'Aggiunti allegati',
        addNoteWithAttachments: 'Aggiunta nota e allegati',
        target: 'Cespiti',
        attachments: 'Allegati',
        doneAttachments: "Allegati 'done'",
        validationAttachments: 'Allegati validazione',
        attachmentPosition: 'Originale',
        inChargeUser: 'Responsabile',
        assigneeUser: 'Assegnatario',
        creatorNotes: 'Note',
        rolesWithVisibility: 'Visibilità ruoli',
        rolesWithAction: 'Ruoli Assegnatari',
        rolesWithValidation: 'Ruolo validatore',
        mustBeValidated: 'Validazione richiesta',
        yes: 'Sì',
        no: 'No',
        renewalPeriod: 'Rinnovo a distanza di',
        dayOfMonth: ', fissato al giorno',
        or: 'o',
        day: 'giorno',
        days: 'giorni',
        month: 'mese',
        months: 'mesi',
        hours: 'ore',
        fromDueDate: 'dalla data di scadenza',
        fromDoneDate: 'dalla data in cui è stato fatto',
        plantId: 'ID',
        customProperties: {
          costCenter: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
          checklist: 'Checklist',
          nomeOperatore: 'Nome Operatore',
          noteOperatore: 'Note Operatore',
        },
        action: {
          done: 'Imposta come fatto',
          donebatch: 'Imposta come fatti',
          reschedule: 'Riprogramma',
          acknowledge: 'Conferma',
          reject: 'Rifiuta il task',
          cancel: 'Elimina il task',
          validation: 'Valida il completamento del task',
          validate: 'Valida il task', // ???
          validatebatch: 'Valida le scadenze',
          'fail-validation': 'Rifiuta validazione',
          undo: "Annulla l'ultima operazione",
          'add-note': 'Aggiungi nota o allegati al task',
        },
        doneAtDate: 'Data esecuzione',
        upload: 'Aggiungi file',
        description: 'Descrizione',
      },
    },
  },
}

export default it
