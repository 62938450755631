import * as moment from 'moment'

type TitechId = string
type TitechReportSeverity = 'Highest' | 'High' | 'Medium'

export interface TitechRecipeChange {
  name: string
  from: string | null
  to: string | null
}

interface TitechRecipeProps {
  addedValues: any[]
  changedAtDate: string
  changedValues: any[]
  id: string
  insertedDate: string
  isReboot: boolean
  name: string
  oldName: string
  removedValues: any[]
  titechId: string
}

export interface TitechLogProps {
  id: string
  insertedDate: string
  logDate: string
  message: string
  originFilename: string
  raw: string
  severity: string
  severityInt: number
  titechId: string
}

export interface TitechCalibrationProps {
  dueAtDate: string
  id: string
  insertedDate: string
  originFilename: string
  raw: string
  titechId: string
}

export interface TitechProps {
  id: TitechId
  code: string
  modelName: string
  severity: number | null
  numberOfLogInLast24h: number
  lastCheckDate: string | null
  lastRecipe: TitechRecipeProps | null
  lastCalibrationDate: string | null
  nextCalibrationDate: string | null
  logSeveritiesCount: Array<{ id: number; count: number }>
  maxSeverityCount: number | null
  maxSeverityValue: number | null
}

export interface TitechDetailProps extends TitechProps {
  recipeChanges: TitechRecipeProps[]
  calibrations: string[]
}

export interface TitechSeverityCounter {
  id: number
  counts: number
  label?: string
}

function parseTitechReportSeverity(
  value: number | null
): TitechReportSeverity | null {
  switch (value) {
    case 100:
      return 'Highest'
    case 50:
      return 'High'
    case 30:
      return 'Medium'
    default:
      return null
  }
}

export const TitechSecurityLabels = {
  "100": 'Highest',
  "50": 'High',
  "30": 'Medium',
  "0": 'Normal',
}

export class TitechLog {
  // @ts-ignore
  public id: string
  // @ts-ignore
  public insertedDate: moment.Moment
  // @ts-ignore
  public logDate: moment.Moment
  // @ts-ignore
  public message: string
  // @ts-ignore
  public originFilename: string
  // @ts-ignore
  public raw: string
  // @ts-ignore
  public severity: TitechReportSeverity | null
  // @ts-ignore
  public severityInt: number
  // @ts-ignore
  public titechId: string

  constructor(data?: TitechLogProps) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (!data[key]) {
          this[key] = null
        } else if (key.match(/Date$/)) {
          this[key] = moment(data[key]).utc()
        } else if (key === 'severityInt') {
          this.severity = parseTitechReportSeverity(data[key])
        } else {
          this[key] = data[key]
        }
      })
    }
  }
}

export class TitechCalibration {
  // @ts-ignore
  public dueAtDate: moment.Moment
  // @ts-ignore
  public id: string
  // @ts-ignore
  public insertedDate: moment.Moment
  // @ts-ignore
  public originFilename: string
  // @ts-ignore
  public raw: string
  // @ts-ignore
  public titechId: string

  constructor(data?: TitechCalibrationProps) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (!data[key]) {
          this[key] = null
        } else if (key.match(/Date$/)) {
          this[key] = moment(data[key])
        } else {
          this[key] = data[key]
        }
      })
    }
  }
}

export class Titech {
  // added ts ignore since managed by constructor
  // @ts-ignore
  public id: TitechId | null
  // @ts-ignore
  public code: string | null
  // @ts-ignore
  public modelName: string | null
  // @ts-ignore
  public severity: TitechReportSeverity | null
  // @ts-ignore
  public numberOfLogInLast24h: number | null
  // @ts-ignore
  public lastCheckDate: moment.Moment | null
  // @ts-ignore
  public lastRecipe: TitechRecipe | null
  // @ts-ignore
  public lastCalibrationDate: moment.Moment | null
  // @ts-ignore
  public nextCalibrationDate: moment.Moment | null
  // @ts-ignore
  public recipeChanges: TitechRecipe[] | null
  // @ts-ignore
  public calibrations: TitechCalibrationProps[] | null
  // @ts-ignore
  public logSeveritiesCount: TitechSeverityCounter[] | null
  // @ts-ignore
  public maxSeverityCount: number | null
  // @ts-ignore
  public maxSeverityValue: number | null
  // @ts-ignore
  public logs: TitechLog[] | null

  constructor(data: TitechProps | TitechDetailProps | undefined) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (typeof data[key] === 'undefined' || data[key] === null ) {
          this[key] = null
        } else if (key === 'lastRecipe') {
          this[key] = new TitechRecipe(data[key]!)
        } else if (key === 'recipeChanges') {
          this[key] = data[key].map(
            (recipe: TitechRecipeProps) => new TitechRecipe(recipe)
          )
        } else if (key === 'logs') {
          this[key] = data[key].map(
            (log: TitechLogProps) => new TitechLog(log)
          )
        } else if (key === 'calibrations') {
          this[key] = data[key].map(
            (calibration: TitechCalibrationProps) => new TitechCalibration(calibration)
          )
        } else if (key === 'calibrations') {
          this[key] = data[key].map((date: string) => moment(date))
        } else if (key === 'severity') {
          this[key] = parseTitechReportSeverity(data[key])
        } else if (key.match(/Date$/)) {
          this[key] = moment(data[key])
        } else {
          this[key] = data[key]
        }
      })
    }
  }

  public hasLastCheckWarning(): boolean {
    return (
      !!this.lastCheckDate && moment().diff(this.lastCheckDate) > 3 * 3600000
    )
  }

  public hasLastCalibrationWarning(): boolean {
    return false
  }

  public hasNextCalibrationWarning(): boolean {
    return (
      !!this.nextCalibrationDate && moment().diff(this.nextCalibrationDate) > 0
    )
  }
}

class TitechRecipe {
  // @ts-ignore
  public name: string
  // @ts-ignore
  public date: moment.Moment
  // @ts-ignore
  public changes: TitechRecipeChange | null
  // @ts-ignore
  public reboot: boolean

  constructor(data?: TitechRecipeProps) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (!data[key]) {
          this[key] = null
        } else if (key.match(/date$/i)) {
          this[key] = moment(data[key])
        } else {
          this[key] = data[key]
        }
      })
    }
  }
}

export interface TitechSetupConfigurationProps {
  assetCode: "to-be-configured" | "deactivated" | "actived" | "to-be-validated" | "undefined"
  calibrationFrequency?: string | null
  id: string
  ipAddress?: string | null
  logFileFolder?: string | null
  model: string | null
  password?: string | null
  positionCode: string | null
  recipeFilename?: string | null
  status: string
  username?: string | null
}

export class TitechSetupConfiguration {
  // added ts ignore since managed by constructor
  // @ts-ignore
  public assetCode: "to-be-configured" | "deactivated" | "actived" | "to-be-validated" | "undefined" | null
  // @ts-ignore
  public calibrationFrequency: string | null
  // @ts-ignore
  public id: string
  // @ts-ignore
  public ipAddress: string | null
  // @ts-ignore
  public logFileFolder: string | null
  // @ts-ignore
  public model: string | null
  // @ts-ignore
  public password: string | null
  // @ts-ignore
  public positionCode: string | null
  // @ts-ignore
  public recipeFilename: string | null
  // @ts-ignore
  public status: string
  // @ts-ignore
  public username: string | null
  // @ts-ignore
  public beltId: string | null

  constructor( data?: TitechSetupConfigurationProps ) {
    if(data) {
      Object.keys(data).forEach(key => {
        if (!data[key]) {
          this[key] = null
        }  else {
          this[key] = data[key]
        }
      })
    } else {
      this.assetCode = null
      this.calibrationFrequency = null
      this.id = ""
      this.ipAddress = null
      this.logFileFolder = null
      this.model = null
      this.password = null
      this.positionCode = null
      this.recipeFilename = null
      this.status = ""
      this.username = null
      this.beltId = null
    }
  }
  /* tslint:enable */
}
