abstract class NodeLogic {
  private v = 'mv-treeview__node'
  private n = 'mv-node'
  // private baseLabel = `${this.n}__label ${this.n}__label--no-arrow`
  private baseLabel = `${this.n}__label`
  // private label = `${this.n}__label`

  public classNameActions = `${this.n}__actions`

  public classNameIsSelected = ` ${this.baseLabel} ${this.baseLabel}--selected `

  public classNameDisable = ` ${this.baseLabel} ${this.baseLabel}--disable `

  public classNameReadonly = ` ${this.baseLabel} ${this.baseLabel}--readonly `

  public classNameDefault = ` ${this.baseLabel} `

  public classNameSelectedAsConsequence = ` ${this.baseLabel} ${this.baseLabel}--selected-reverb `

  public classNameSelectedAsParent = ` ${this.baseLabel} ${this.baseLabel}--selected-parent `

  public classNameRoot = `${this.v}--root`

  public classNameVisible = `${this.n}--visible`

  public abstract consequenceEnable: boolean
}

export class NodeMultiSelectUp extends NodeLogic {
  public consequenceEnable: boolean = false
}

export class NodeSingleSelectDown extends NodeLogic {
  public consequenceEnable: boolean = true
}

export class NodeSingleNode extends NodeLogic {
  public consequenceEnable: boolean = false
}

export default NodeLogic
