// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { Link } from 'react-router-dom'
import { IsDisable } from '../../../types/base'
import { Clickable, ToLinkable } from '../../../types/onFunction';

// * -------------------------------- MODULE --------------------------------------
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'

export interface Location<S> {
  pathname: string;
  search: string;
  state: S;
}

export type LocationDescriptor<S> = Location<S> | string

export interface LinkableProps<S = any> extends IFlexItem, IsDisable, Clickable<[event: React.MouseEvent<HTMLElement>]>, ToLinkable<S> { 
  href?: string
  replace?: boolean
}

const Linkable = <P extends Object>(Component: React.ComponentType<P>) =>
  class LinkComponent extends React.Component<P & LinkableProps> {
    public render() {
      const { to, href, onClick, replace, ...props } = this.props

      const flexProps = renderFlexItem({ ...this.props })

      if (props.isDisable) {
        return <Component {...(props as P)} />
      }

      // let the case separated for a cleaner comprehension
      if (to) {
        return (
          <Link className={`mv-anchor-reset ${flexProps}`} to={to} replace={replace}>
            <Component {...(props as P)} />
          </Link>
        )
      }

      if (href) {
        return (
          <a href={href} className={` ${flexProps}`}>
            <Component {...(props as P)} />
          </a>
        )
      }

      if (onClick) {
        return (
          <a onClick={onClick} className={` ${flexProps}`}>
            <Component {...(props as P)} />
          </a>
        )
      }

      return (
        <a className={` ${flexProps}`}>
          <Component {...(props as P)} />
        </a>
      )
    }
  }

export default Linkable
