import { CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import * as moment from 'moment'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { getAreaPauses, getAreaSections, reduceWarnAreas } from '../../../../../functions/shared'
import { AreaSection } from '../../../../../types/measure'
import GraphTimelineView from '../../GraphTimeline/GraphTimelineView'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

export interface OwnProps {
  data: any
  filteredData: any
  pauses?: Array<{ start: number; end: number }>
}

export interface OwnState {
  showTooltip: boolean
  tooltipData: any
}

type Props = OwnProps & WithTranslation

class BagGraphMainView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showTooltip: false,
      tooltipData: null,
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      const curData = this.props.data && this.props.data.find((e: any) => e.time === args.activeLabel)

      const curPerformance = curData.performance !== undefined ? curData.performance : '--'
      const curRicetta = curData.ricetta !== undefined ? curData.ricetta : '--'

      this.setState({
        showTooltip: true,
        tooltipData: {
          time: curData.time,
          ricetta: curRicetta,
          performance: curPerformance,
          ricettaColor: curRicetta ? COLORS.ricetta : null,
          performanceColor: curPerformance ? COLORS.performance : null,
        },
      })
    }
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public render() {
    const { pauses, filteredData } = this.props

    const warnPoints: AreaSection[] | undefined =
      (filteredData as [{}]) && getAreaSections(filteredData, 'warnArea').filter(e => e.value === 100)

    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), undefined, COLORS.pauses)
    const warnAreas = reduceWarnAreas(warnPoints)

    return (
      <React.Fragment>
        <Column md={10}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              onMouseEnter={this.handleMouseEnter}
              onMouseMove={this.handleMouseMove}
              onMouseLeave={this.handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                hide={true}
                dataKey="time"
                label={''}
                height={30}
                axisLine={false}
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'number'}
              />
              <YAxis domain={[0, 100]} axisLine={false}>
                <Label value={'%'} position={'insideTopLeft'} />
              </YAxis>
              <CartesianGrid stroke="#f5f5f5" />

              {warnAreas}
              {warnPauses}

              <Tooltip content={BagGraphMainView.nullTooltipContent} />

              <Line
                dataKey="performance"
                stroke={COLORS.performance}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <GraphTimelineView data={filteredData} colWidth={12} xAxisNumber={true} preserveStartEnd={true} />
        </Column>

        <Column md={2}>
          {this.state.showTooltip && this.state.tooltipData !== null && (
            <ul className="side-tooltip">
              <li>
                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                <br />
                <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
              </li>

              {this.state.tooltipData.performance !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.performance')}</strong>
                  <br />
                  <span className="label-color label-color-line" style={{ backgroundColor: '#FFC000' }} />
                  <span>{this.state.tooltipData.performance} %</span>
                </li>
              )}

              <li>
                <strong>{this.props.t('plantAnalysis.labels.pauses')}</strong>{' '}
                <span
                  className="label-color label-color-line label-color-line-right"
                  style={{ backgroundColor: COLORS.pauses }}
                />
              </li>
            </ul>
          )}
        </Column>
      </React.Fragment>
    )
  }
}

export default withTranslation()(BagGraphMainView)
