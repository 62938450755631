// * -------------------------------- NPM --------------------------------------
import * as React from "react";

// * -------------------------------- MODULE --------------------------------------
import Alert from "../Alert/Alert";
import IconComponent from "../MVIcon/Icon";

interface Props {
    loading?: boolean
    error?: string
    children: React.ReactNode
}

class MainPageContent extends React.Component<Props>{
    public render() {
        const { loading, children, error } = this.props
        return (
            <div className="content">
                {loading && (
                    <div className="d-flex justify-content-center">
                        <IconComponent className="spinner" icon={"circle-notch"} spin={true} size="4x"/>
                    </div>
                )}
                {!loading && !error && (children)}
                {error && (
                  <Alert type={'danger'} >{error}</Alert>
                )}
            </div>
        )
    }
}

export default MainPageContent