import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  AssetDetail, AssetDocumentsValidationPayload,
  AssetFileCreation, AssetFileUpload,
  AssetForm as AssetFormType, AssetFormStatus, AssetManagerAcl, AssetValidation,
  CostCenter, AssetValidateActionAvailabilty,
  Module,
  Site,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { GroupsListElement } from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  FetchSitesGrousModulesResponse, FetchSuccessCreateAsset, FetchUploadDocumentSuccess,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import { fetchSitesGroupsModules } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import moment from 'moment'
import {
  checkActivationAvailability,
  checkDeactivationAvailability,
  fetchDetailAsset, fetchUploadFIle,
  fetchValidateAsset, getAssetFormRules, validateAssetDocuments, validateReplaceAsset,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import {Alert, Button, Link, Loader, PageHeader} from '@mv-submodules/inplant-components-fe'
import AssetForm from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetForm/AssetForm'
import { connect } from 'react-redux'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnState {
  assetData: AssetFormType,
  assetDetail?: AssetDetail
  assetReplacingDetail?: AssetDetail
  assetValidateActionAvailability?: AssetValidateActionAvailabilty
  isFetchingActionValidateAvailabilty: boolean
  assetId: string
  plants: Array<{ code: string, name: string }>,
  sites: Site[],
  costCenters: Array<{ plant: string, costCenters: CostCenter[] }>,
  groups: GroupsListElement[],
  modules: Module[],
  documents: AssetFileCreation[]
  isSubmitting: boolean
  isFetching: boolean
  fetchError: boolean
  formStatus?: AssetFormStatus
  editableAssets: string[]
}

interface StateProps {
  forbiddenActions: string[]
}

const mapStateToProps = (store: any): StateProps => {
  return {
    forbiddenActions: store.auth.user.forbiddenActions,
  }
}

type Props = RouteComponentProps & WithTranslation & StateProps

class AssetValidationRoute extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      assetData: {
        assetCode: '',
        activationDate: moment().format('YYYY-MM-DD'),
        positionCode: '', // Codice Impianto
        assetDescription: '', // Descrizione asset
        assetNotes: '', // Note
        siteCode: '', // Sito
        plantCode: '', // Impianto
        costCenterCode: '', // Centro di costo
        assetBrand: '', // Marcca
        ownership: '',
        assetModel: '', // Model
        assetSerialNumber: '',  // Matricola
        groups: [], // gruppo cespiti
        modules: [], // moduli
        documents: [],
      },
      assetId: '',
      documents: [],
      plants: [],
      costCenters: [],
      groups: [],
      sites: [],
      modules: [],
      isSubmitting: false,
      isFetching: false,
      fetchError: false,
      isFetchingActionValidateAvailabilty: false,
      editableAssets: [],
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchDeactivationAvailabilty = this.fetchDeactivationAvailabilty.bind(this)
    this.fetchActivationAvailability = this.fetchActivationAvailability.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateAssetData = this.updateAssetData.bind(this)
    this.submitValidateCreation = this.submitValidateCreation.bind(this)
    this.getInputRule = this.getInputRule.bind(this)
    this.submitValidateReplace = this.submitValidateReplace.bind(this)
    this.submitValidateAssetDocumentsValidation = this.submitValidateAssetDocumentsValidation.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] //tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list')
    }
    this.setState({
      assetId,
    }, () => this.fetchData())
  }

  public render() {
    const { t, forbiddenActions } = this.props
    const {
      assetData, formStatus, assetReplacingDetail, isFetchingActionValidateAvailabilty,
      assetValidateActionAvailability, sites, plants, costCenters, groups, modules, isSubmitting,
      documents, isFetching, editableAssets,
    } = this.state
    return (
      <div className="inplant-asset-manager-fe">
        {
          isFetching ? (
              <Loader/>
            )
            :
            (
              <form onSubmit={this.handleSubmit}>
                {/* Header Section*/}
                <PageHeader
                  title={t(formStatus === 'validate' ?
                    'assetManager.navigation.validateAsset' : formStatus === 'documents-to-validate' ?
                      'assetManager.navigation.validateDocuments' : 'assetManager.navigation.validateChangeAsset')}
                  backButtonOnClick={() => this.props.history.goBack()} backButton={true}
                  rightButtons={
                    ((formStatus === 'validate' && !forbiddenActions.includes(AssetManagerAcl.validateCreation)) ||
                      (formStatus === 'documents-to-validate' && !forbiddenActions.includes(AssetManagerAcl.validateDocuments)) ||
                      ((formStatus === 'validate-replacing-with-deactivated-asset' || formStatus === 'validate-replacing-with-new-asset') && !forbiddenActions.includes(AssetManagerAcl.validateReplacing))
                    ) ?
                      (
                        <Button variant={"primary"} type="submit"
                                disabled={isSubmitting || isFetchingActionValidateAvailabilty ||
                                (formStatus === 'validate' && assetValidateActionAvailability && !assetValidateActionAvailability.allowed) ||
                                (assetValidateActionAvailability && assetReplacingDetail && !assetValidateActionAvailability.allowed && (assetReplacingDetail.status === 'replace-with-deactivation-to-validate' || assetReplacingDetail.status === 'replace-with-scrapping-to-validate'))
                                }
                                label={formStatus === 'documents-to-validate' ? t('assetManager.actions.validateDocuments') : t('assetManager.actions.validate')}
                                isLoading={isSubmitting || isFetchingActionValidateAvailabilty}
                        />
                      )
                      : undefined
                  }
                />
                {/* End Header Section*/}
                {/* Content Section*/}
                {!isFetching && formStatus && (
                  <div className="content">
                    <Row>
                      <Column md={8}>
                        {assetValidateActionAvailability && !assetValidateActionAvailability.allowed && assetValidateActionAvailability.showMessage && (
                          <Alert type={'danger'} title={assetValidateActionAvailability.message.mainText}>
                            {assetValidateActionAvailability.message.subElements && (
                              <ul>
                                {assetValidateActionAvailability.message.subElements.map((subelement, index) => (
                                  <li key={`task_to_close_${index}`}>
                                    <Link theme="dark" onClick={() => this.props.history.push(subelement.link || '')}
                                          label={subelement.text}
                                    />
                                  </li>
                                ))}
                              </ul>
                            )}
                            {/*{t('assetManager.status.needToValidateAllTasks')}*/}
                          </Alert>
                        )}
                        {assetReplacingDetail && (
                          <>
                            <Alert type={'warning'}>
                              {t('assetManager.status.needToValidateAllTasksToSubstitute', {
                                oldAsset: assetReplacingDetail.assetCode,
                                newAsset: assetData.assetCode || '-',
                              })}
                            </Alert>
                            <Row >
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.machineCode')}</label>
                                <p><strong>{assetReplacingDetail.assetCode || '-'}</strong></p>
                              </Column>
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.activationDate')}</label>
                                <p
                                  className="light-gray-text-bold">{moment(assetReplacingDetail.activatedOn).format('DD/MM/YYYY')}</p>
                              </Column>
                            </Row>
                            <hr/>
                            <Row>
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.plant')}</label>
                                <p><strong>{assetReplacingDetail.plant.name}</strong></p>
                              </Column>
                              <Column md={3} >
                                <label className="small-label-readonly">{t('assetManager.forms.costCenter')}</label>
                                <p><strong>{assetReplacingDetail.costCenter.name}</strong></p>
                              </Column>
                            </Row>
                            <hr/>
                            <Row >
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.brand')}</label>
                                <p><strong>{assetReplacingDetail.assetData.brand || '-'}</strong></p>
                              </Column>
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.serialNumber')}</label>
                                <p><strong>{assetReplacingDetail.assetData.serialNumber || '-'}</strong></p>
                              </Column>
                              <Column md={3}>
                                <label className="small-label-readonly">{t('assetManager.forms.model')}</label>
                                <p><strong>{assetReplacingDetail.assetData.model || '-'}</strong></p>
                              </Column>
                            </Row>
                            <Row >
                              <Column md={9} >
                                <label className="small-label-readonly">{t('assetManager.forms.notes')}</label>
                                <p><strong>{assetReplacingDetail.assetData.notes || '-'}</strong></p>
                              </Column>
                            </Row>
                            <hr/>
                          </>
                        )}
                        <AssetForm
                          assetData={assetData}
                          groups={groups}
                          costCenters={costCenters}
                          documents={documents}
                          modules={modules}
                          plants={plants}
                          sites={sites}
                          getInputRule={this.getInputRule}
                          updateAssetData={this.updateAssetData}
                          type={formStatus}
                          editableDocuments={editableAssets}
                        />
                      </Column>
                    </Row>
                  </div>
                )}
                {/* End Content Section*/}
              </form>
            )
        }
      </div>
    )
  }

  private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    const documents: AssetFileUpload[] = []
    for (const file of this.state.documents) {
      const result: FetchUploadDocumentSuccess = await fetchUploadFIle(file.file!)
      const assetFile: AssetFileUpload = {
        fileId: result.id,
        name: result.name,
        isInHeadquarter: file.isInHeadquarter || false,
        description: file.description || '',
        type: file.type || '',
      }
      documents.push(assetFile)
    }
    const { assetData } = this.state
    const assetValidation: AssetValidation = {
      documents: assetData.documents.concat(documents),
      assetNotes: assetData.assetNotes,
      assetModel: assetData.assetModel,
      assetBrand: assetData.assetBrand,
      ownership: assetData.ownership,
      assetDescription: assetData.assetDescription,
      assetCode: assetData.assetCode,
      modules: assetData.modules,
      groups: assetData.groups.filter((group: string) => !(group === '')),
      assetSerialNumber: assetData.assetSerialNumber,
      positionCode: assetData.positionCode,
    }
    const data = JSON.parse(JSON.stringify(assetValidation))
    Object.keys(data).forEach(k => {
      if (typeof data[k] === 'string' && !data[k]) {
        data[k] = null
      }
    })
    if (this.state.formStatus === 'validate') {
      this.submitValidateCreation(data)
    } else if (this.state.formStatus === 'documents-to-validate') {
      delete data.assetCode
      this.submitValidateAssetDocumentsValidation(data)
    } else {
      this.submitValidateReplace(data)
    }
  }

  private async submitValidateReplace(assetData: AssetValidation) {
    validateReplaceAsset(assetData, this.state.assetId).then((result: FetchSuccessCreateAsset) => {
      this.props.history.goBack()
    }).catch((error: any) => {
      console.log(error) //tslint:disable-line
    })
  }

  private async submitValidateCreation(assetData: AssetValidation) {
    fetchValidateAsset(assetData, this.state.assetId).then((result: FetchSuccessCreateAsset) => {
      this.props.history.goBack()
    }).catch((error: any) => {
      console.log(error) //tslint:disable-line
    })
  }

  private async submitValidateAssetDocumentsValidation(assetData: AssetDocumentsValidationPayload) {
    validateAssetDocuments(assetData, this.state.assetId).then((result: FetchSuccessCreateAsset) => {
      this.props.history.goBack()
    }).catch((error: any) => {
      console.log(error) //tslint:disable-line
    })
  }

  private async fetchData() {
    if (!this.state.isFetching) {
      try {
        this.setState({
          isFetching: true,
        })
        const assetDetail: AssetDetail = await fetchDetailAsset(this.state.assetId)
        console.log(assetDetail) //tslint:disable-line
        // TODO
        const assetData: AssetFormType = {
          assetCode: assetDetail.assetCode || '',
          plantCode: assetDetail.plant.code,
          activationDate: moment(assetDetail.activatedOn).format('YYYY-MM-DD'),
          assetBrand: assetDetail.assetData.brand,
          assetDescription: assetDetail.assetDescription,
          ownership: assetDetail.assetData.ownership,
          assetModel: assetDetail.assetData.model,
          assetNotes: assetDetail.assetData.notes,
          assetSerialNumber: assetDetail.assetData.serialNumber,
          costCenterCode: assetDetail.costCenter.code,
          groups: assetDetail.groups.reduce((acc: string[], group) => {
            acc.push(group.id)
            return acc
          }, []),
          documents: assetDetail.assetData.documents,
          modules: assetDetail.modules.reduce((acc: string[], module) => {
            acc.push(module.id)
            return acc
          }, []),
          positionCode: assetDetail.positionCode,
          siteCode: assetDetail.site.code,
        }
        const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
        const plantsArray: Array<{ code: string, name: string }> = sitesData.sites.reduce((acc: Array<{ code: string, name: string }>, sites) => {
          acc = acc.concat(sites.plants.reduce((plants: Array<{ code: string, name: string }>, plant) => {
            plants.push({
              code: plant.code,
              name: plant.name,
            })
            return plants
          }, []))
          return acc
        }, [])
        const costCenters: Array<{ plant: string, costCenters: CostCenter[] }> = sitesData.sites.reduce((acc: Array<{ plant: string, costCenters: CostCenter[] }>, site) => {
          return acc.concat(site.plants.reduce((acc2: Array<{ plant: string, costCenters: CostCenter[] }>, plant) => {
            acc2.push({
              plant: plant.code,
              costCenters: plant.costCenters,
            })
            return acc2
          }, []))
        }, [])
        let assetReplacingDetail: AssetDetail | undefined
        if (assetDetail.isReplacing && assetDetail.isReplacing.id) {
          assetReplacingDetail = await fetchDetailAsset(assetDetail.isReplacing.id)
          this.fetchDeactivationAvailabilty(assetDetail.isReplacing.id)
        }
        if (assetDetail && assetDetail.status === 'creation-to-validate') {
          this.fetchActivationAvailability(assetDetail.id)
        }
        this.setState({
          sites: sitesData.sites,
          groups: sitesData.groups,
          modules: sitesData.modules,
          plants: plantsArray,
          assetDetail,
          costCenters,
          assetReplacingDetail,
          // TODO TEMP, WRITE BETTER CONDITION
          formStatus: assetReplacingDetail && assetDetail && assetDetail.assetCode ?
            'validate-replacing-with-deactivated-asset' : assetReplacingDetail ?
              'validate-replacing-with-new-asset' : assetDetail.status === 'documents-to-validate' ? 'documents-to-validate' : 'validate',
          isFetching: false,
          assetData,
          editableAssets: assetDetail.assetData.documents.reduce((acc: string[], doc) => {
            if (doc.editable) {
              acc.push(doc.fileId)
            }
            return acc
          }, []),
        })
      } catch (error) {
        console.warn('error', error) //tslint:disable-line
      } finally {
        this.setState({
          isFetching: false,
        })
      }
    }
  }

  private async fetchDeactivationAvailabilty(assetId: string) {
    if (!this.state.isFetchingActionValidateAvailabilty) {
      this.setState({ isFetchingActionValidateAvailabilty: true })
      try {
        const deactivationAvailability: AssetValidateActionAvailabilty = await checkDeactivationAvailability(assetId)
        this.setState({
          assetValidateActionAvailability: deactivationAvailability,
          isFetchingActionValidateAvailabilty: false,
        })
      } catch (e) {
        this.setState({ fetchError: true })
        console.log('error', e)//tslint:disable-line
      }
    }
  }

  private async fetchActivationAvailability(assetId: string) {
    if (!this.state.isFetchingActionValidateAvailabilty) {
      this.setState({ isFetchingActionValidateAvailabilty: true })
      try {
        const deactivationAvailability: AssetValidateActionAvailabilty = await checkActivationAvailability(assetId)
        this.setState({
          assetValidateActionAvailability: deactivationAvailability,
          isFetchingActionValidateAvailabilty: false,
        })
      } catch (e) {
        this.setState({ fetchError: true })
        console.log('error', e)//tslint:disable-line
      }
    }
  }

  private getInputRule(name: string, action: string): boolean | undefined {
    const { formStatus, assetDetail } = this.state

    if (formStatus) {
      if (formStatus === 'validate' && name === 'positionCode' && assetDetail && (
        (assetDetail.temporaryPlantCode && (action === 'disabled' || action === 'readOnly')) ||
        (!assetDetail.temporaryPlantCode && action === 'adviseKey')
      )) {
        return false
      }
      return getAssetFormRules(name, action, formStatus)
    }
  }

  private updateAssetData(assetData: AssetFormType) {
    this.setState({
      assetData,
    })
  }
}


export default withRouter<any, any>(
  connect(
    mapStateToProps,
    {},
  )
  (withTranslation()(AssetValidationRoute)),
)