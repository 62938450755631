import * as React from 'react'
import { Route } from 'react-router-dom'
import { Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import ErrorPage from '../Errors/ErrorPage'
import { aclRoutes, aclRedirect } from '@mv-submodules/inplant-config/config/routes'
import { webviewRoute } from '../../../../auth/routes'
import Webview from '../Webview/Webview'
import Login from '../Login/Login'
import PrivateRoute from '../PrivateRoute/PrivateRoute'
import { RoutePropsCoreWithLegacy } from '../../../../types/routes'
import { CenterLoader } from '../../../../../inplant-components-fe/ui/components'

export interface MainStateProps {
  forbiddenActions: string[]
  loginSuccess: boolean
  loginSuccessPath: string
}

export interface OwnProps {
  routes: RoutePropsCoreWithLegacy[]
}

export type Props = MainStateProps & OwnProps

const mapStateToProps = (state: any): MainStateProps => ({
  loginSuccess: state.auth.loggedIn,
  forbiddenActions: state.auth.user ? state.auth.user.forbiddenActions : [],
  loginSuccessPath: state.config.generic.loginSuccessPath,
})

function composeRoutes(moduleRoutes: RoutePropsCoreWithLegacy[]): React.ReactNode | void {
  return moduleRoutes.map(route => {
    if (!route.children) {
      if(!route.legacy?.unAuthorizedRoute) {
        return <PrivateRoute key={route.path} exact={route.exact} path={route.path} component={route.component} />
      }
      return <Route path={route.path} key={route.path} exact={route.exact} component={route.component} />
    }
    return composeRoutes(route.children)
  })
}

function Main(props: Props) {
  const { loginSuccess, forbiddenActions, routes, loginSuccessPath } = props
  return (
    <main className={`main-container ${loginSuccess ? '' : 'is-fullwidth'}`}>
      <React.Suspense fallback={<CenterLoader />}>
        <Switch>
          <Route
            key="/"
            exact={true}
            path="/"
            render={() => <Login successPath={aclRedirect(loginSuccessPath)} image="/logo-login.png" />}
            />
            {composeRoutes(aclRoutes(routes, forbiddenActions, loginSuccessPath))}
          <Route exact={true} path={webviewRoute.path} component={Webview} />
          <Route render={() => <ErrorPage code={404} />} />
        </Switch>
      </React.Suspense>
    </main>
  )
}

export default withRouter<any, any>(connect(mapStateToProps)(Main))
