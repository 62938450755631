import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { COLORS } from '../../../../../constants'
import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries, Loader } from '../../../../../functions/shared'
import BottomLegendPrint from '../../BottomLegend/BottomLegend/BottomLegendPrint'
import BunkerBarStack3Print from '../../BunkerBar/BunkerBarStack3Print'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'
import TableRowWrapper
  , {
  TableRowFullWrapper, TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  generateElementsArrayForTableRowLayout,
  generateTableRowFromArrayOfElements,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/generateTableRowLayout'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface OwnState {
  components: NodeComponent[]
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  measures: string[]
  component: any
  data: Array<{ [key: string]: number }>
  isFetching: boolean
  fetchErrors: boolean
  configuration: PlantAnalysisGeneralSettings
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class BunkerGraphRowView extends React.Component<Props, OwnState> {

  private unloaded = [
    {
      workshift: 1,
      qta: 2,
    },
    {
      workshift: 2,
      qta: 32,
    },
    {
      workshift: 3,
      qta: 22,
    },
  ]

  private colors = {
    bar1_1: COLORS.nopienoPieno[0],
    bar1_2: COLORS.nopienoPieno[1],
    bar2_1: COLORS.invdir[0],
    bar2_2: COLORS.invdir[1],
    bar3_1: COLORS.manAutoLocSec[0],
    bar3_2: COLORS.manAutoLocSec[1],
    bar3_3: COLORS.manAutoLocSec[2],
    bar3_4: COLORS.manAutoLocSec[3], // @todo verificare corrispondenza codici/valori
  }

  public render() {
    const { model, configuration } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isFetching
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        ['PLANT_DATA_BNK', 'PLANT_DATA_BNK_INVERTER'].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code),
    )


    return (
      <>
        <TableRowFullWrapperWithCondition condition={noData}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto alert-local'>
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.isFetching}>
          <div className='alert alert-secondary w-100 col-sm-6 mx-auto alert-local'>
            {this.props.t('plantAnalysis.loading')}
            <Loader />
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.fetchErrors}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>

        <TableRowFullWrapper className={'no-page-break'}>
          <TableWrapperUtilities hideHeader={true}>
            <TableRowWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-left'}>
                <BottomLegendPrint row={true} labels={['nopienoPieno']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['invdir']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </TableColumnWrapper>
            </TableRowWrapper>
          </TableWrapperUtilities>
        </TableRowFullWrapper>
        {this.generateLayout(componentsToRender, configuration)}
      </>
    )
  }

  private generateLayout(componentsToRender: ModelNodeData[], configuration: PlantAnalysisGeneralSettings) {
    const elements = componentsToRender.map((c) => this.generateSingleElement(c, configuration))
    return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 8), true, true)
  }

  private generateSingleElement(c: ModelNodeData, configuration: PlantAnalysisGeneralSettings): JSX.Element {
    return (configuration?.nodes === {} ||
        (configuration?.nodes[c.code] && configuration?.nodes[c.code] === true)) &&
      <BunkerBarStack3Print
        data={getInfluxMultiSeries(this.props.data, c.code)}
        days={this.props.days || 1}
        name={c.label}
        id={c.code}
        unloaded={this.unloaded}
        colors={this.colors}
        key={c.code}
        hideInverterDiretta={c.nodeType === 'PLANT_DATA_BNK'}
      />
  }

}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BunkerGraphRowView)))
