import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries } from '../../../../../functions/shared'
import BottomLegendPrint from '../../BottomLegend/BottomLegend/BottomLegendPrint'
import BeltGraphRowItemPrint from '../BeltGraphRowItem/BeltGraphRowItemPrint'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import TableRowWrapper
  , {
  TableRowFullWrapper, TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface OwnState {
  components: NodeComponent[]
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  measures: string[]
  component: any
  metrics: string[]
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  isFetching: boolean
  fetchErrors: boolean
  configuration: PlantAnalysisGeneralSettings
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class BeltGraphRowView extends React.Component<Props, OwnState> {
  public render() {
    const { model, days } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isFetching
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        [
          'PLANT_DATA_BLT',
          'PLANT_DATA_BELT',
          'PLANT_DATA_BELT_INVERTER',
          'PLANT_DATA_ROT_SCR',
          'PLANT_DATA_ROT_SCR_INVERTER',
        ].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code),
    )

    return (
      <>
        <TableRowFullWrapperWithCondition condition={noData}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.isFetching}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.fetchErrors}>
          <div className='alert alert-danger w-100 col-sm-6 mx-autom belt-graph-row-error'>
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>

        <TableRowFullWrapper className={'no-page-break'} >
          <TableWrapperUtilities hideHeader={true}>
            <TableRowWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-left'}>
                <BottomLegendPrint row={true} labels={['marciaFermo']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['avind']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </TableColumnWrapper>
            </TableRowWrapper>
          </TableWrapperUtilities>
        </TableRowFullWrapper>

        {componentsToRender.map(
          (c: ModelNodeData) =>
            (this.props.configuration.nodes === {} ||
              (this.props.configuration.nodes[c.code] && this.props.configuration.nodes[c.code] === true)) && (
              <BeltGraphRowItemPrint
                data={getInfluxMultiSeries(this.props.data, c.code)}
                days={days || 1}
                name={c.label}
                id={c.code}
                key={c.code}
                currentOverrun={getComponentCurrent(c.code, this.props.dataCurrent)}
                currentLimit={this.props.configuration.settingsFields.sforamento_assorbimento_nastri}
              />
            ),
        )}
      </>
    )
  }
}

const getComponentCurrent = (id: string, dataCurrent: any) => {
  let out
  if (dataCurrent && dataCurrent.length) {
    const current = dataCurrent.find((e: { name: string }) => e.name.indexOf(id) > -1)

    if (current && current.values && current.values[0]) {
      out = current.values[0][1]
    }
  }

  return out
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BeltGraphRowView)))
