import { ActionCreator, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
// import * as queryString from 'query-string'
import { ChronoTasks } from '../../types/chronoTasks'
import { FormData } from '../../types/pageForm'
import { API } from './index'
import * as moment from 'moment'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { ListPagination } from '@mv-submodules/inplant-chronoframe-fe/types/pageList'
import { download } from '@mv-submodules/inplant-components-fe/mvfunctions/download'
import { stringify } from '../../../inplant-components-fe/mvfunctions/apiFormatter'
import { Resize } from 'react-table'

export const CHRONO_FRAME_TASK_LIST_SET_FILTERS = 'CHRONO_FRAME_TASK_LIST_SET_FILTERS'
export const CHRONO_FRAME_SAVE_LAST_APPLIED_FILTER = 'CHRONO_FRAME_SAVE_LAST_APPLIED_FILTER'
export const CHRONO_FRAME_SAVE_SEARCH_STRING = 'CHRONO_FRAME_SAVE_SEARCH_STRING'
export const CHRONO_FRAME_UPDATE_FILTERED_IDS = 'CHRONO_FRAME_UPDATE_FILTERED_IDS'
export const CHRONO_FRAME_UPDATE_FILTER_MODAL = 'CHRONO_FRAME_UPDATE_FILTER_MODAL'
export const CHRONO_FRAME_LIST_SET_PAGINATION = 'CHRONO_FRAME_LIST_SET_PAGINATION'
export const CHRONO_FRAME_LIST_SET_RESIZE_COLUMN = 'CHRONO_FRAME_LIST_SET_RESIZE_COLUMN'

interface ApplyChronoFrameListPagination {
  type: typeof CHRONO_FRAME_LIST_SET_PAGINATION
  pagination: { [key: string]: ListPagination }
}

export const fetchListWithPagination = (
  pageSlug: string,
  pagination: ListPagination,
  abortController: AbortController,
  filters?: FormData
) => (dispatch: Dispatch, getState: Function): ChronoTasks => {
  const paginationParams = Object.keys(pagination)
    .reduce((acc: string[], key: string) => {
      if (pagination[key]) {
        acc.push(`${key}=${pagination[key]}`)
      }
      return acc
    }, [])
    .join('&')

  let filtersToQuery = filters ? JSON.parse(JSON.stringify(filters)) : undefined
  if (filtersToQuery && (filtersToQuery.searchString === null || filtersToQuery.searchString === '')) {
    delete filtersToQuery.searchString
  }
  filtersToQuery = {
    pageSlug,
    ...(filters ? filtersToQuery : undefined),
  }
  const querystring = stringify(filtersToQuery)
  const request = `/chrono-frame/chrono-tasks/list?${filtersToQuery ? `${querystring}` : ''}&${paginationParams}`
  console.log('request', request) //tslint:disable-line
  return API()
    .request(request, { signal: abortController.signal })
    .then((data: ChronoTasks) => data)
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const exportData = (pageSlug: string, filters?: FormData) => async (dispatch: Dispatch) => {
  let filtersToQuery = filters

  if (filtersToQuery) {
    delete filtersToQuery.searchString
  }

  filtersToQuery = {
    pageSlug,
    ...(filters ? filtersToQuery : undefined),
  }

  try {
    const result: string = await API().request('/chrono-frame/chrono-tasks/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
      body: filtersToQuery ? JSON.stringify(filtersToQuery) : null,
    })
    return download(result, 'export_' + moment().format('YYYYMMDD') + '.csv')
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

export const applyChronoframeListPagination: ActionCreator<ApplyChronoFrameListPagination> = (
  slug: string,
  params: ListPagination
): ApplyChronoFrameListPagination => ({
  type: CHRONO_FRAME_LIST_SET_PAGINATION,
  pagination: { [slug]: params },
})

export const setFiltersPageList = (slug: string, filters?: FormData) => (dispatch: Dispatch) => {
  dispatch({
    type: CHRONO_FRAME_TASK_LIST_SET_FILTERS,
    filters: { [slug]: filters },
  })
}

export const setResizeColumn = (tableId: string, columSize: Resize[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: CHRONO_FRAME_LIST_SET_RESIZE_COLUMN,
    columnSize: { tableId, columSize },
  })
}

// TODO UNDERSTAND IF THIS FUNCTIONS ARE USEFULLY
export const saveLastAppliedFilter = (appliedFilter: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: CHRONO_FRAME_SAVE_LAST_APPLIED_FILTER,
    appliedFilter,
  })
}

export const saveSearchString = (searchString: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: CHRONO_FRAME_SAVE_SEARCH_STRING,
    searchString,
  })
}

export const updateFilteredIds = (ids: string[]) => async (dispatch: Dispatch) => {
  dispatch({
    type: CHRONO_FRAME_UPDATE_FILTERED_IDS,
    ids,
  })
}

export const updateFilterModal = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CHRONO_FRAME_UPDATE_FILTER_MODAL,
  })
}
