export const plantChartConfig = {
  ro: {
    'IBROV2M86F5NBOFH': {
      'MAIN_TORQUE': {
        lineColor: '#476FC2',
        referenceLines: [70, 90],
        unit: '%',
        showMean: true,
        footerMeasures: [
          {
            operation: 'count',
            measure: 'SCND_LIMIT_TORQUE_ALARM',
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.alarmsSecondLimit',
            condition: '= true',
            unit: ''
          },
          {
            select: 'DEC1_cnt',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopCount',
            unit: '',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          },
          {
            select: 'DEC1_duration',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopDuration',
            unit: '',
            formatter: 'duration',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          }
        ],
        footerCounters: [
          {
            limit: 0,
            operator: '<=',
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.mainTorqueZero',
            showAbsolute: true,
            showPercent: true,
          }
        ]
      },
      'TEMP_MELT': {
        lineColor: '#71279E',
        referenceLines: [220, 225],
        unit: '°C',
        domain: ['dataMin -10', 'dataMax + 10'],
        showMean: true,
      },
      'PISTON_POS_1': {
        lineColor: '#18AEEE',
        referenceLines: [35, 42],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      },
    },
    'IBROV2M86F5NBUUR': {
      'MAIN_TORQUE': {
        lineColor: '#476FC2',
        referenceLines: [70, 90],
        unit: '%',
        showMean: true,
        footerMeasures: [
          {
            operation: 'count',
            measure: 'SCND_LIMIT_TORQUE_ALARM',
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.alarmsSecondLimit',
            condition: '= true'
          },
          {
            select: 'DEC2_cnt',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopCount',
            unit: '',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          },
          {
            select: 'DEC2_duration',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopDuration',
            unit: '',
            formatter: 'duration',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          }
        ],
        footerCounters: [{
          limit: 0,
          operator: '<=',
          i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.mainTorqueZero',
          showAbsolute: true,
          showPercent: true,
        }]
      },
      'TEMP_MELT': {
        lineColor: '#71279E',
        referenceLines: [230, 235],
        unit: '°C',
        domain: ['dataMin -10', 'dataMax + 10'],
        showMean: true,
      },
      'PISTON_POS_1': {
        lineColor: '#18AEEE',
        referenceLines: [30, 36],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      },
    },
    'IBROV2M86F5NC4LC': {
      'MAIN_TORQUE': {
        lineColor: '#476FC2',
        referenceLines: [70, 90],
        unit: '%',
        showMean: true,
        footerMeasures: [
          {
            operation: 'count',
            measure: 'SCND_LIMIT_TORQUE_ALARM',
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.alarmsSecondLimit',
            condition: '= true'
          },
          {
            select: 'DEC3_cnt',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopCount',
            unit: '',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          },
          {
            select: 'DEC3_duration',
            measure: 'vDecStops',
            withoutMeasurePrefix: true,
            i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.stopDuration',
            unit: '',
            formatter: 'duration',
            noDataLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.noData'
          }],
        footerCounters: [{
          limit: 0,
          operator: '<=',
          i8nLabel: 'plantAnalysis.multiLineChartsLabels.footerLabels.mainTorqueZero',
          showAbsolute: true,
          showPercent: true,
        }]
      },
      'TEMP_MELT': {
        lineColor: '#71279E',
        referenceLines: [230, 235],
        unit: '°C',
        domain: ['dataMin -10', 'dataMax + 10'],
        showMean: true,
      },
      'PISTON_POS_1': {
        lineColor: '#18AEEE',
        referenceLines: [30, 36],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      },
    },
    'IBROV2M86F5NI2PX': {
      'BELT_SPD': {
        lineColor: '#00B050',
        referenceLines: [28, 35],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      }
    },
    'IBROV2M86F5NI9YP': {
      'BELT_SPD': {
        lineColor: '#00B050',
        // referenceLines: [28, 35],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      }
    },
    'IBROV2M86F5NJANW': {
      'BELT_SPD': {
        lineColor: '#00B050',
        // referenceLines: [230, 235],
        unit: '%',
        domain: [0, 100],
        showMean: true,
      }
    }
  }
}
