import { RoutePropsCoreWithLegacy, RoutePropsLegacy } from "../types/routes";
import { RouteProps as RoutePropsCore } from '../../inplant-core-fe/types'

export function mapRoutePropsCoreToRoutePropsAdapter(coreRoutes: RoutePropsCore): RoutePropsLegacy {
  return {
    path: coreRoutes.path,
    visible: coreRoutes.visible,
    exact: coreRoutes.exact,
    icon: coreRoutes.icon,
    aclActionKey: coreRoutes.aclActionKey,
    children: coreRoutes.children?.map(mapRoutePropsCoreToRoutePropsAdapter),
    component: coreRoutes.component,
    counter: coreRoutes.counter,
    visibleInUserProfile: coreRoutes.visibleInUserProfile,
    external: coreRoutes.external,
    hiddenMobile: coreRoutes.hiddenMobile,
    i18nkey: coreRoutes.i18nkey,
  }
}

export function mapRoutePropsLegacyToRoutePropsCoreWithLegacy(legacyRoutes: RoutePropsLegacy): RoutePropsCoreWithLegacy {
  return {
    i18nkey: legacyRoutes.i18nkey || '',
    path: legacyRoutes.path,
    visible: legacyRoutes.visible,
    aclActionKey: legacyRoutes.aclActionKey,
    aclsRequired: !!legacyRoutes.aclActionKey ? [legacyRoutes.aclActionKey] : undefined,
    children: legacyRoutes.children?.map(mapRoutePropsLegacyToRoutePropsCoreWithLegacy),
    component: legacyRoutes.component,
    breadcrumbPath: '',
    counter: legacyRoutes.counter,
    exact: legacyRoutes.exact,
    external: legacyRoutes.external,
    hiddenMobile: legacyRoutes.hiddenMobile,
    icon: legacyRoutes.icon,
    visibleInUserProfile: legacyRoutes.visibleInUserProfile,
    iconOverride: legacyRoutes.iconOverride,
    legacy: {
      ...legacyRoutes
    }
  }
}
