import * as React from 'react'
import { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ChartPlaceholder, ChartStatusBadges } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import { parseResponseSingleData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import { convertSecondsToString } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/time'

interface BearingItem {
  AssetName: string
  BearingNumber: number
  WarningThresold: number
  StopThresold: number
  TotalTimeOverWarning: number
  TotalTimeOverStop: number
  IntervalsOverWarning: number
  IntervalsOverStop: number
  MaxDurationWarning: number
  MaxDurationStop: number
  statusWarning?: boolean
  statusStop?: boolean
}

interface OwnProps {
  dateEnd: string
  dateStart: string
  days: number
  plant: any | null
}

interface FetchStatus {
  fetching: boolean
  error: boolean
}

type Props = OwnProps & WithTranslation

const BearingsGraphMainView = (props: Props ) => {
  const [status, setStatus] = useState<FetchStatus>({
    fetching: false,
    error: false,
  })

  const [data, setData] = useState<BearingItem[]>([])

  const getData = async () => {
    setStatus({ fetching: true, error: false })
    const plantQuery = props.plant ? 'plant=' + props.plant.plant + '&' : ''
    const query = `SELECT measure FROM vBearingsTemperatures WHERE time >= '${props.dateStart}' AND time <= '${props.dateEnd}'`

    try {
      const bearingsData = await API().request(`/query?${plantQuery}q=${query}`)
      const parsedData = parseResponseSingleData(bearingsData)
      if (parsedData && parsedData[1]) {
        setData(parsedData && parsedData[1] ? JSON.parse(parsedData[1]) : [])
      }
      setStatus({ fetching: false, error: false })
    } catch (e) {
      setStatus({ fetching: false, error: true })
    }
  }

  useEffect(() => {
    if (!status.fetching && !status.error) {
      getData()
    }
  }, [props.dateEnd, props.dateStart, props.days, props.plant.plant])

  const itemStatusStop = ( item: BearingItem ) => !!(item.IntervalsOverStop || item.MaxDurationStop || item.TotalTimeOverStop)
  const itemStatusWarning = ( item: BearingItem ) => !!(item.IntervalsOverWarning || item.MaxDurationWarning || item.TotalTimeOverWarning)

  const { fetching, error } = status
  const { t } = props

  const itemsToShow = data ? data.map(i => {
    i.statusWarning = itemStatusWarning(i)
    i.statusStop = itemStatusStop(i)
    return i
  }).filter(i => i.statusWarning || i.statusStop) : []

  const noData = !fetching && !error && (itemsToShow === [])

  return (
    <div className="bearing-graphs">
      {ChartStatusBadges('multi-series-line-chart', fetching, error, noData, t)}
      <div className="row avoid-page-break text-center">

        {
          !error && !fetching &&
          itemsToShow.map(( item ) => {
            return <div
              key={item.AssetName + item.BearingNumber}
              className={
                `single-bearing mb-4 page-break-inside-avoid ${(item.statusStop ? 'status-2' : '') + ' ' + (item.statusWarning ? 'status-1' : '')}` +
                ` ${item.statusStop && item.statusWarning ? 'col-md-6 col-lg-4' : 'col-6 col-md-3 col-lg-2'}`
              }
            >
              <div>
                <h3
                  className={item.statusStop && item.statusWarning ? 'col-md-6' : 'col-md-12'}>{item.AssetName}{item.BearingNumber ? ' - ' + item.BearingNumber : ''}</h3>

                <div className="row">
                  {
                    item.statusStop &&
                    <div className="col single-bearing__status stop">
                      <div>
                        <span>{t('plantAnalysis.bearings.threshold')}
                          <strong> {t('plantAnalysis.bearings.stop')}</strong> {item.StopThresold} °C</span>
                        <span>{t('plantAnalysis.bearings.totalTime')}
                          <strong> {t('plantAnalysis.bearings.stop')}</strong><br/><strong
                            className="value">{convertSecondsToString(item.TotalTimeOverStop * 60, t)}</strong></span>
                        <span>{t('plantAnalysis.bearings.intervalsNumber')}<br/><strong
                          className="value">{item.IntervalsOverStop}</strong></span>
                        <span>{t('plantAnalysis.bearings.maxIntervalDuration')}<br/><strong
                          className="value">{convertSecondsToString(item.MaxDurationStop * 60, t)}</strong></span>
                      </div>
                    </div>
                  }
                  {
                    item.statusWarning &&
                    <div className="col single-bearing__status warning">
                      <div>
                        <span>Soglia <strong>allerta</strong> {item.WarningThresold} °C</span>
                        <span>{t('plantAnalysis.bearings.totalTime')} <strong>allerta</strong><br/><strong
                          className="value">{convertSecondsToString(item.TotalTimeOverWarning * 60, t)}</strong></span>
                        <span>{t('plantAnalysis.bearings.intervalsNumber')}<br/><strong
                          className="value">{item.IntervalsOverWarning}</strong></span>
                        <span>{t('plantAnalysis.bearings.maxIntervalDuration')}<br/><strong
                          className="value">{convertSecondsToString(item.MaxDurationWarning * 60, t)}</strong></span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          })
        }

        {
          itemsToShow.length === 0 && !fetching && !error &&
          <div className="col-md-4 mx-auto">
            {ChartPlaceholder(t('plantAnalysis.bearings.noWarnings'))}
          </div>
        }

      </div>
    </div>
  )

}

export default withTranslation()(BearingsGraphMainView)
