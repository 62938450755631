import * as React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import TaskListAdvancedFilters from './TaskListAdvancedFilters'
import {parseFieldValue, getInputValueFromData} from '../../widgets/TaskFormModal/utils'
import {Fieldset, FieldsetField, FormData} from '../../../../types/pageForm'
import {FilterComponent} from "@mv-submodules/inplant-components-fe/ui/components/Filters/Filters";
import {Filters} from "@mv-submodules/inplant-components-fe";

interface OwnProps {
  appliedFilters?: FormData
  onSearch: (value: string | null) => void
  onSubmit: (filters?: FormData) => Promise<any>
  simpleFiltersFields: FieldsetField[]
  advancedFiltersFields: Fieldset[]
  savedSearchString: string
  currentAppliedFilter: string
  lastAppliedFilter: string
  saveLastAppliedFilter?: (appliedFilter: string) => void
  updateFilterModalValue: boolean,
  pageConfigUpdateData?: (destination?: string) => any
}

interface OwnState {
  showAdvancedFilters: boolean
  lastAppliedFilter: string
  currentAppliedFilter: string
}

type Props = OwnProps & WithTranslation

class TaskListFilters extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showAdvancedFilters: false,
      lastAppliedFilter: this.props.lastAppliedFilter,
      currentAppliedFilter: this.props.currentAppliedFilter,
    }
    this.getFilterValue = this.getFilterValue.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleAdvancedFiltersSubmit = this.handleAdvancedFiltersSubmit.bind(this)
    this.handleToggleAdvancedFilters = this.handleToggleAdvancedFilters.bind(this)
  }

  public render() {
    const t = this.props.t
    const props = this.props
    const {showAdvancedFilters} = this.state
    const beFilers: FilterComponent[] = props.simpleFiltersFields.map((field) => ({
      type: field.type === 'date' && 'Date' || 'Select',
      value: this.getFilterValue(field['slug']), //tslint:disable-line
      options: {
        defaultOptionLabel: '-',
        defaultOptionDisabled: false,
        items: field['options'], //tslint:disable-line
      },
      name: field['slug'], //tslint:disable-line
      label: t(`chronoframe.dueDates.formField.${field['slug']}`), //tslint:disable-line
      id: `filter-${field['slug']}`, //tslint:disable-line
      onChange: (name, value) => this.handleFilterChange(name, value)
    }))
    const filters: FilterComponent[] = [
      ...beFilers,
      {
        type: 'SearchInput',
        value: this.props.savedSearchString,
        onChange: (name, value) => this.props.onSearch(value && value as string || null),
        label: t('chronoframe.tasks.table.searchPlaceholder'),
        throttle: 500
      }]
    return (
      <>
        <TaskListAdvancedFilters // modale filtri avanzati
          visible={showAdvancedFilters}
          data={props.appliedFilters}
          fields={props.advancedFiltersFields}
          onClose={this.handleToggleAdvancedFilters}
          onSubmit={this.handleAdvancedFiltersSubmit}
          pageConfigUpdateData={this.props.pageConfigUpdateData}
          updateFilterModalValue={this.props.updateFilterModalValue}
        />
        <Filters fields={filters} advancedFilters={{
          onClick: () => this.handleToggleAdvancedFilters(),
          label: t('chronoframe.tasks.table.advancedFilters')
        }}/>
      </>
    )
    //  range di date
    //   <div className="col d-flex justify-content-start align-items-end">
    //   {props.simpleFiltersFields.length ? <GenericFieldset
    //     fields={props.simpleFiltersFields}
    //     className="row"
    //     childrenClass="col"
    //     getInputValue={this.getFilterValue}
    //     onInputChange={this.handleFilterChange}
    //   /> : null}
    // </div>
    /*
              <div className="row d-flex align-items-end">
     <div className="col-md-4">
     <SearchInput
     onChange={props.onSearch}
     placeholder={}
     savedSearchString={this.props.savedSearchString}
     currentAppliedFilter={this.props.currentAppliedFilter}
     lastAppliedFilter={this.props.lastAppliedFilter}
     />
     </div>
     <div className="col d-flex justify-content-start align-items-end">
     {props.simpleFiltersFields.length ? (
              <GenericFieldset
                fields={props.simpleFiltersFields} // inizio, fine, status
                className="row"
                childrenClass="col align-self-end"
                getInputValue={this.getFilterValue}
                onInputChange={this.handleFilterChange}
              />
            ) : null}
     <Button variant="info" label={t('chronoframe.tasks.table.advancedFilters')}
     onClick={() => this.handleToggleAdvancedFilters()}/>
     </div>
     </div>
     */
  }

  private handleToggleAdvancedFilters() {
    this.setState(currentState => ({
      showAdvancedFilters: !currentState.showAdvancedFilters,
    }))
  }

  private handleFilterChange(slug: string, value: any) {
    let newSlug = slug
    let newValue = value

    if (slug === 'status') {
      newSlug = 'statusArray'
      newValue = [value]
    }

    const filters: FormData = {
      ...this.props.appliedFilters,
      ...parseFieldValue(newSlug, newValue, this.props.appliedFilters),
    }

    if (filters.statusArray && filters.statusArray[0] === '') {
      delete filters.statusArray
    }
    this.props.onSubmit(filters)
  }

  private async handleAdvancedFiltersSubmit(filters?: FormData) {
    await this.props.onSubmit(filters)
    return filters
  }

  private getFilterValue(slug: string) {
    let newSlug = slug
    if (this.state.lastAppliedFilter === '' || this.state.currentAppliedFilter === this.state.lastAppliedFilter) {
      if (slug === 'status') {
        newSlug = 'statusArray'
      }
      return getInputValueFromData(newSlug, this.props.appliedFilters)
    } else {
      // slug has changed
      const newFilters = this.props.appliedFilters || {}
      this.setState(
        {
          lastAppliedFilter: this.props.currentAppliedFilter,
        },
        () => {
          // clears filters
          delete newFilters.destinationArray
          delete newFilters.statusArray
          delete newFilters.startDueAtDate
          delete newFilters.endDueAtDate
          delete newFilters.customProperties
          delete newFilters.target
          return getInputValueFromData(newSlug, newFilters)
        }
      )
    }
  }
}

export default withTranslation()(TaskListFilters)
