import React from 'react'

interface Props {
  condition: boolean
  onError?: JSX.Element

}

const WithConditionHOC = <P extends object>(Component: React.ComponentType<P>) => (props: P & Props) => {
  const { condition, onError, ...otherProps } = props
  if (condition) {
    return <Component {...(otherProps as P)} />
  }
  return onError || null
}

interface WithConditionWrapperProps extends Props {
  children: React.ReactNode
}

export const WithConditionWrapper = (props: WithConditionWrapperProps) => {
  const { condition, onError, children } = props

  if (condition) {
    return <>{children}</>
  }
  return onError || null
}
export default WithConditionHOC