// * -------------------------------- MODULE --------------------------------------
import { Icon, IconComponentService, IconComponentServiceProps, IconSize } from '../../../services/icon'
import { TYPES } from '../../../services/binding'
import { useComponent } from '../../../services/component'

const IconComponent = <IconGenerics extends string | string[] = Icon, IconSizeGeneric extends string = IconSize>({
  className = '',
  ...props
}: IconComponentServiceProps<IconGenerics, IconSizeGeneric>) => {
  const component = useComponent<IconComponentService<IconGenerics, IconSizeGeneric>>(TYPES.IconComponentService)
  const { semantic, ...iconServiceProps } = props
  const mvIcon = 'mv-icon'
  return (
    component?.render({
      ...iconServiceProps,
      className: `${mvIcon} ${semantic ? `${mvIcon}--${semantic}` : ''} ${
        iconServiceProps.onClick ? `${mvIcon}--clickable` : ''
      } ${className}`,
    }) || null
  )
}

export default IconComponent
