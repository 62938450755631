import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../redux/actions'
import { GeneralData } from '../../../../types/measure'
import { Workshift } from '../../../../types/workshift'
import { PieData } from '../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../constants'
import { Loader } from '../../../../functions/shared'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'
import {
  /*hydrateTimeData,*/
  parseData,
} from '../../../../functions/series'
import moment from 'moment'
import GraphLineBarWithTimelinePrint from '../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelinePrint'
import {
  composeData,
  fillTime,
  populateSingleDataFrom,
} from 'src/mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import {
  TableRowFullWrapper,
  TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'

export type ScrubberStatusType = 'scrubberStatus'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: ScrubberStatusType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  scrubberState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataScrubberState: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class PlantStatus extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      keyCollection: [],
      scrubberState: {},
      fetching: false,
      error: false,
      dataScrubberState: null,
      data: null,
      filteredData: [],
      mergedData: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData(this.props.processing)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    return (
      <>
        <TableRowFullWrapperWithCondition condition={!fetching && error}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition
          condition={!fetching && !error && (this.state.data && (this.state.data.length === 0) || !this.state.data)}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
        </TableRowFullWrapperWithCondition>
        <WithConditionWrapper condition={!error}>
          <WithConditionWrapper
            condition={(!hiddenCharts || !hiddenCharts.includes('scrubberStatus'))}
            onError={
              <TableRowFullWrapper>
                <Loader />
              </TableRowFullWrapper>
            }>
            <WithConditionWrapper condition={this.state.data}>
              <TableRowFullWrapper>
                <h3 className={'w-100'}><b>{t('plantAnalysis.plantStatus.productivityManagement')}</b></h3>
                <WithConditionWrapper
                  condition={!this.state.data?.vecoplan1ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.vecoplan1ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'vecoplan1ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.vecoplan1ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.fan316ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.fan316ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'fan316ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.fan316ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.line1ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.line1ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'line1ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.line1ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.line2ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.line2ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'line2ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.line2ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.line3ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.line3ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'line3ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.line3ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
              </TableRowFullWrapper>
              <TableRowFullWrapper>
                <h3 className={'w-100'}><b>{t('plantAnalysis.plantStatus.smokeManagement')}</b></h3>
                <WithConditionWrapper
                  condition={!this.state.data?.scrubber1ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.scrubber1ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'scrubber1ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.scrubber1ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.scrubber2ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.scrubber2ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'scrubber2ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.scrubber2ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.fanF306ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.fanF306ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'fanF306ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.fanF306ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={!this.state.data?.fanF314ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.fanF314ActiveStatus')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'fanF314ActiveStatus'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.fanF314ActiveStatus'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
              </TableRowFullWrapper>
              <TableRowFullWrapper>
                <h3 className={'w-100'}><b>{t('plantAnalysis.plantStatus.efficiency')}</b></h3>
                <WithConditionWrapper
                  condition={!this.state.data?.fanF306ActiveStatusNoData}
                  onError={
                    <>
                      <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.efficiency')}</h5>
                      <div
                        className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
                    </>
                  }
                >
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'efficiency'}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.plantStatus.efficiency'}
                    lineHeight={60}
                    hideTitle={true}
                  />
                </WithConditionWrapper>
                <div className={'graph-spacing column-break-before'}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{ backgroundColor: COLORS.scrubber.scrubberStatus.active }}
                    />
                    <span>{t('plantAnalysis.scrubber.scrubberStatus.active')}</span>
                    </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{ backgroundColor: COLORS.scrubber.scrubberStatus.inactive }}
                    />
                    <span>{t('plantAnalysis.scrubber.scrubberStatus.inactive')}</span>
                    </span>
                </div>
              </TableRowFullWrapper>
            </WithConditionWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </>
    )
  }


  private constructData() {
    try {
      const line1ActiveStatus = populateSingleDataFrom(this.state.data?.line1ActiveStatus?.data, true)
      const line2ActiveStatus = populateSingleDataFrom(this.state.data?.line2ActiveStatus?.data, true)
      const line3ActiveStatus = populateSingleDataFrom(this.state.data?.line3ActiveStatus?.data, true)
      const fan316ActiveStatus = populateSingleDataFrom(this.state.data?.fan316ActiveStatus?.data, true)
      const vecoplan1ActiveStatus = populateSingleDataFrom(this.state.data?.vecoplan1ActiveStatus?.data, true)
      const scrubber1ActiveStatus = populateSingleDataFrom(this.state.data?.scrubber1ActiveStatus?.data, true)
      const scrubber2ActiveStatus = populateSingleDataFrom(this.state.data?.scrubber2ActiveStatus?.data, true)
      const fanF314ActiveStatus = populateSingleDataFrom(this.state.data?.fanF314ActiveStatus?.data, true)
      const fanF306ActiveStatus = populateSingleDataFrom(this.state.data?.fanF306ActiveStatus?.data, true)
      const efficiency = populateSingleDataFrom(this.state.data?.efficiency?.data, true)

      let mergedData = composeData(
        {
          line1ActiveStatus,
          line2ActiveStatus,
          line3ActiveStatus,
          fan316ActiveStatus,
          vecoplan1ActiveStatus,
          scrubber1ActiveStatus,
          scrubber2ActiveStatus,
          fanF314ActiveStatus,
          fanF306ActiveStatus,
          efficiency,
        },
      )


      const start = moment(this.props.workshift.start.toString()).unix()
      const end = moment(this.props.workshift.end.toString()).unix()

      mergedData = fillTime(
        mergedData,
        start,
        end,
      )

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift) {


      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.getDateFromString(workshift.start.toString()).toISOString()

      const endOfDay = mvDate.getDateFromString(workshift.end.toString()).toISOString()
      const queryEndShift = ` WHERE ${
        workshift && workshift.value !== 99 ? `shift = ${workshift.value} AND ` : ''
      } time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const queryStart = `SELECT * FROM `

        const line1ActiveStatus = `vLineL1ActiveTimeLine`
        const line2ActiveStatus = `vLineL2ActiveTimeLine`
        const line3ActiveStatus = `vLineL3ActiveTimeLine`
        const fan316ActiveStatus = `vFanF316ActiveTimeLine`
        const vecoplan1ActiveStatus = `vVecoplanV1ActiveTimeLine`
        const scrubber1ActiveStatus = `vScrubberS1ActiveTimeLine`
        const scrubber2ActiveStatus = `vScrubberS2ActiveTimeLine`
        const fanF314ActiveStatus = `vFanF314ActiveTimeLine`
        const fanF306ActiveStatus = `vFanF306ActiveTimeLine`
        const efficiency = `vEfficiency`

        const line1ActiveStatusQuery = queryStart + line1ActiveStatus + queryEndShift
        const line2ActiveStatusQuery = queryStart + line2ActiveStatus + queryEndShift
        const line3ActiveStatusQuery = queryStart + line3ActiveStatus + queryEndShift
        const fan316ActiveStatusQuery = queryStart + fan316ActiveStatus + queryEndShift
        const vecoplan1ActiveStatusQuery = queryStart + vecoplan1ActiveStatus + queryEndShift
        const scrubber1ActiveStatusQuery = queryStart + scrubber1ActiveStatus + queryEndShift
        const scrubber2ActiveStatusQuery = queryStart + scrubber2ActiveStatus + queryEndShift
        const fanF314ActiveStatusQuery = queryStart + fanF314ActiveStatus + queryEndShift
        const fanF306ActiveStatusQuery = queryStart + fanF306ActiveStatus + queryEndShift
        const efficiencyQuery = queryStart + efficiency + queryEndShift

        const dataLine1ActiveStatus = API().request(`/query?${plantQueryString}q=` + line1ActiveStatusQuery, { signal: this.abortController.signal })
        const dataLine2ActiveStatus = API().request(`/query?${plantQueryString}q=` + line2ActiveStatusQuery, { signal: this.abortController.signal })
        const dataLine3ActiveStatus = API().request(`/query?${plantQueryString}q=` + line3ActiveStatusQuery, { signal: this.abortController.signal })
        const dataFan316ActiveStatus = API().request(`/query?${plantQueryString}q=` + fan316ActiveStatusQuery, { signal: this.abortController.signal })
        const dataVecoplan1ActiveStatus = API().request(`/query?${plantQueryString}q=` + vecoplan1ActiveStatusQuery, { signal: this.abortController.signal })
        const dataScrubber1ActiveStatus = API().request(`/query?${plantQueryString}q=` + scrubber1ActiveStatusQuery, { signal: this.abortController.signal })
        const dataScrubber2ActiveStatus = API().request(`/query?${plantQueryString}q=` + scrubber2ActiveStatusQuery, { signal: this.abortController.signal })
        const dataFanF314ActiveStatus = API().request(`/query?${plantQueryString}q=` + fanF314ActiveStatusQuery, { signal: this.abortController.signal })
        const dataFanF306ActiveStatus = API().request(`/query?${plantQueryString}q=` + fanF306ActiveStatusQuery, { signal: this.abortController.signal })
        const dataEfficiency = API().request(`/query?${plantQueryString}q=` + efficiencyQuery, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataLine1ActiveStatus,
          dataLine2ActiveStatus,
          dataLine3ActiveStatus,
          dataFan316ActiveStatus,
          dataVecoplan1ActiveStatus,
          dataScrubber1ActiveStatus,
          dataScrubber2ActiveStatus,
          dataFanF314ActiveStatus,
          dataFanF306ActiveStatus,
          dataEfficiency,
        ])
          .then(
            ([
               dataLine1ActiveStatusResult,
               dataLine2ActiveStatusResult,
               dataLine3ActiveStatusResult,
               dataFan316ActiveStatusResult,
               dataVecoplan1ActiveStatusResult,
               dataScrubber1ActiveStatusResult,
               dataScrubber2ActiveStatusResult,
               dataFanF314ActiveStatusResult,
               dataFanF306ActiveStatusResult,
               dataEfficiencyResult,
             ]) => {
              if (this.mounted) {
                const parsedDataLine1ActiveStatusResult = typeof dataLine1ActiveStatusResult === 'string' ? JSON.parse(dataLine1ActiveStatusResult) : dataLine1ActiveStatusResult
                const parsedDataLine2ActiveStatusResult = typeof dataLine2ActiveStatusResult === 'string' ? JSON.parse(dataLine2ActiveStatusResult) : dataLine2ActiveStatusResult
                const parsedDataLine3ActiveStatusResult = typeof dataLine3ActiveStatusResult === 'string' ? JSON.parse(dataLine3ActiveStatusResult) : dataLine3ActiveStatusResult
                const parsedDataFan316ActiveStatusResult = typeof dataFan316ActiveStatusResult === 'string' ? JSON.parse(dataFan316ActiveStatusResult) : dataFan316ActiveStatusResult
                const parsedDataVecoplan1ActiveStatusResult = typeof dataVecoplan1ActiveStatusResult === 'string' ? JSON.parse(dataVecoplan1ActiveStatusResult) : dataVecoplan1ActiveStatusResult
                const parsedDataScrubber1ActiveStatusResult = typeof dataScrubber1ActiveStatusResult === 'string' ? JSON.parse(dataScrubber1ActiveStatusResult) : dataScrubber1ActiveStatusResult
                const parsedDataScrubber2ActiveStatusResult = typeof dataScrubber2ActiveStatusResult === 'string' ? JSON.parse(dataScrubber2ActiveStatusResult) : dataScrubber2ActiveStatusResult
                const parsedDataFanF314ActiveStatusResult = typeof dataFanF314ActiveStatusResult === 'string' ? JSON.parse(dataFanF314ActiveStatusResult) : dataFanF314ActiveStatusResult
                const parsedDataFanF306ActiveStatusResult = typeof dataFanF306ActiveStatusResult === 'string' ? JSON.parse(dataFanF306ActiveStatusResult) : dataFanF306ActiveStatusResult
                const parsedDataEfficiencyResult = typeof dataEfficiencyResult === 'string' ? JSON.parse(dataEfficiencyResult) : dataEfficiencyResult
                try {
                  const parsedDataLine1ActiveStatus = parseData(parsedDataLine1ActiveStatusResult)
                  const parsedDataLine2ActiveStatus = parseData(parsedDataLine2ActiveStatusResult)
                  const parsedDataLine3ActiveStatus = parseData(parsedDataLine3ActiveStatusResult)
                  const parsedDataFan316ActiveStatus = parseData(parsedDataFan316ActiveStatusResult)
                  const parsedDataVecoplan1ActiveStatus = parseData(parsedDataVecoplan1ActiveStatusResult)
                  const parsedDataScrubber1ActiveStatus = parseData(parsedDataScrubber1ActiveStatusResult)
                  const parsedDataScrubber2ActiveStatus = parseData(parsedDataScrubber2ActiveStatusResult)
                  const parsedDataFanF314ActiveStatus = parseData(parsedDataFanF314ActiveStatusResult)
                  const parsedDataFanF306ActiveStatus = parseData(parsedDataFanF306ActiveStatusResult)
                  const parsedDataEfficiency = parseData(parsedDataEfficiencyResult)

                  this.setState({
                    data: Object.assign({}, this.state.data, {
                      line1ActiveStatus: { data: parsedDataLine1ActiveStatus },
                      line1ActiveStatusNoData: parsedDataLine1ActiveStatus.length === 0,
                      line2ActiveStatus: { data: parsedDataLine2ActiveStatus },
                      line2ActiveStatusNoData: parsedDataLine2ActiveStatus.length === 0,
                      line3ActiveStatus: { data: parsedDataLine3ActiveStatus },
                      line3ActiveStatusNoData: parsedDataLine3ActiveStatus.length === 0,
                      fan316ActiveStatus: { data: parsedDataFan316ActiveStatus },
                      fan316ActiveStatusNoData: parsedDataFan316ActiveStatus.length === 0,
                      vecoplan1ActiveStatus: { data: parsedDataVecoplan1ActiveStatus },
                      vecoplan1ActiveStatusNoData: parsedDataVecoplan1ActiveStatus.length === 0,
                      scrubber1ActiveStatus: { data: parsedDataScrubber1ActiveStatus },
                      scrubber1ActiveStatusNoData: parsedDataScrubber1ActiveStatus.length === 0,
                      scrubber2ActiveStatus: { data: parsedDataScrubber2ActiveStatus },
                      scrubber2ActiveStatusNoData: parsedDataScrubber2ActiveStatus.length === 0,
                      fanF314ActiveStatus: { data: parsedDataFanF314ActiveStatus },
                      fanF314ActiveStatusNoData: parsedDataFanF314ActiveStatus.length === 0,
                      fanF306ActiveStatus: { data: parsedDataFanF306ActiveStatus },
                      fanF306ActiveStatusNoData: parsedDataFanF306ActiveStatus.length === 0,
                      efficiency: { data: parsedDataEfficiency },
                      efficiencyNoData: parsedDataEfficiency.length === 0,
                    }),
                    fetching: false,
                    error: false,
                  }, () => {
                    this.constructData()
                  })
                } catch (error) {
                  this.setState({
                    scrubberState: {},
                    fetching: false,
                    data: null,
                  })
                }
              }
            },
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              scrubberState: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        consoleLog('error', error)
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          scrubberState: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(PlantStatus))
