import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { TableParams } from '../../types/table'
import { TitechSetupConfiguration, TitechSetupConfigurationProps } from '../../types/titech'
import { ListParams } from '@mv-submodules/inplant-titech-fe-iblu/types/store'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { API } from '@mv-submodules/inplant-titech-fe-iblu/redux/actions/index'

export const fetchSetupData = ( params?: ListParams, plant?: string ):
  (( dispatch: Dispatch ) => Promise<TitechSetupConfiguration[]>) => ( dispatch: Dispatch ) => {
  return API().request(
    `/titech/setup${plant && plant !== '' ? '?plant=' + plant : ''}`, /* @todo refactoring */
  )
  .then(( data: TitechSetupConfigurationProps[] ) => data.map(raw => new TitechSetupConfiguration(raw)))
  .catch(( error: FetchError ) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  })
}

export const applyParams = ( params: TableParams ): AnyAction => {
  return {
    type: 'CHECKLIST_SETUP_PAGE_APPLY_PARAMS',
    params,
  }
}
