export const stringBreaker = (str:string):string => {
    const words = str.split(" ");
    let answer = "";
    words.forEach((word) => {
      if (word.length >= 25) {
        console.log("dentro",word) //tslint:disable-line
        answer = answer + chunk(word,25).join(' ')
      } else {
        answer = answer + word + " "
      }
    })
    return answer
  }
  
  export const chunk = (str:string, n:number) => {
    const ret:string[] = [];
      for(let i = 0, len = str.length; i < len; i += n) {
        ret.push(str.substr(i, n))
      }
  
    return ret
  }