import * as durationFns from 'duration-fns'

export type DurationUnit = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'

export interface MVDuration {
  years: number
  months: number
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

export type PartialMVDuration = Partial<MVDuration>

interface MVDurationType {
  getDuration(duration: PartialMVDuration): MVDuration
  negate(duration: PartialMVDuration): MVDuration
  normalize(duration: PartialMVDuration | number): PartialMVDuration
  parse(durationString: string): MVDuration
  toDays(duration: PartialMVDuration): number
  toHours(duration: PartialMVDuration): number
  toMilliseconds(duration: PartialMVDuration): number
  toMinutes(duration: PartialMVDuration): number
  toMonths(duration: PartialMVDuration): number
  toMonths(duration: PartialMVDuration): number
  toSeconds(duration: PartialMVDuration): number
  toUnit(duration: PartialMVDuration, unit: DurationUnit): number
  toWeeks(duration: PartialMVDuration): number
  toYears(duration: PartialMVDuration): number
}

let MVDurationClass: MVDurationType
MVDurationClass = class {
  private static durationFnsToPartialMVDuration(duration: durationFns.Duration): PartialMVDuration {
    return {
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      months: duration.months,
      seconds: duration.seconds,
      weeks: duration.weeks,
      years: duration.years,
      milliseconds: duration.milliseconds,
    }
  }

  private static durationFnsToMVDuration(duration: durationFns.Duration): MVDuration {
    return this.getDuration(this.durationFnsToPartialMVDuration(duration))
  }

  public static parse(durationString: string): MVDuration {
    return this.durationFnsToMVDuration(durationFns.parse(durationString))
  }

  public static negate(duration: PartialMVDuration): MVDuration {
    return this.durationFnsToMVDuration(durationFns.negate(duration))
  }

  public static normalize(duration: PartialMVDuration | number): PartialMVDuration {
    return durationFns.normalize(duration)
  }

  public static getDuration = ({
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  }: PartialMVDuration): MVDuration => {
    return {
      years: years ?? 0,
      months: months ?? 0,
      weeks: weeks ?? 0,
      days: days ?? 0,
      hours: hours ?? 0,
      minutes: minutes ?? 0,
      seconds: seconds ?? 0,
      milliseconds: milliseconds ?? 0,
    }
  }

  public static toDays(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'days')
  }

  public static toHours(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'hours')
  }

  public static toMilliseconds(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'milliseconds')
  }

  public static toMinutes(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'minutes')
  }

  public static toMonths(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'months')
  }

  public static toSeconds(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'seconds')
  }

  public static toUnit(duration: PartialMVDuration, unit: DurationUnit): number {
    return durationFns.toUnit(duration, unit)
  }

  public static toWeeks(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'weeks')
  }

  public static toYears(duration: PartialMVDuration): number {
    return this.toUnit(duration, 'years')
  }
}

export const mvDuration = MVDurationClass
