// * -------------------------------- MODULE --------------------------------------
import { tableReducer, TableStore } from '../../ui/components/Table'

const componentsReducersMap = {
  tableReducer,
}

const combineReducers = (reducers: typeof componentsReducersMap) => {
  const entries = Object.entries(reducers)
  return (state = {}, action: any) => {
    return entries.reduce((_state, [key, reducer]) => {
      _state[key] = reducer(state[key], action)
      return _state
    }, {})
  }
}

const componentsReducers = combineReducers(componentsReducersMap)

export default componentsReducers

export interface ComponentsReducers {
  tableReducer: TableStore
}
