import React, { Component } from 'react'
import BottomLegend from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BottomLegend/BottomLegend/BottomLegendPrint'
import { COLORS } from '@mv-submodules/inplant-plantanalysis-fe-iblu/constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import { API } from '../../../../../redux/actions'
import * as moment from 'moment'
import { Loader, parseInfluxResponse } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import PercentsPiePrint
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/charts/PercentsPie/PercentsPiePrint'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  generateElementsArrayForTableRowLayout,
  generateTableRowFromArrayOfElements,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/generateTableRowLayout'
import {
  TableRowFullWrapper, TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'

export interface AspiratedAreaDataType {
  AssetName: string
  BeltOnAspOnDuration: number
  BeltOnAspOnPerc: number
  BeltOnAspOffDuration: number
  BeltOnAspOffPerc: number
  BeltOffDuration: number
  BeltOffPerc: number
  TotalDuration: number
}

interface OwnProps {
  dateEnd: string
  dateStart: string
  days: number
  plant: any | null
}

interface OwnState {
  data: AspiratedAreaDataType[]
  fetchErrors: boolean
  isLoading: boolean
}

type Props = OwnProps & WithTranslation


class AspiratedAreaGraphRowView extends Component<Props, OwnState> {
  private mounted: boolean = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      fetchErrors: false,
    }

    this._fetchData = this._fetchData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this._fetchData()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    if (
      this.props.dateStart &&
      this.props.dateEnd &&
      (prevProps.dateStart !== this.props.dateStart || prevProps.dateEnd !== this.props.dateEnd) ||
      this.props.plant !== prevProps.plant
    ) {
      this._fetchData()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public render() {
    const { data, fetchErrors, isLoading } = this.state
    const { t } = this.props

    return (
      <>
        <TableRowFullWrapper className={'text-center'}>
          <BottomLegend row={true} labels={['aspiredZone']} hideTitle={true} />
        </TableRowFullWrapper>
        <TableRowFullWrapperWithCondition condition={isLoading && !fetchErrors}>
          <div className='alert alert-secondary w-100 mx-auto'>
            {t('plantAnalysis.loading')}
            <Loader />
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={!isLoading && !fetchErrors && data && (data.length === 0)}>
          <div className='alert alert-warning w-100 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
        </TableRowFullWrapperWithCondition>
        <WithConditionWrapper condition={!isLoading && fetchErrors}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto fan-graph-fetch-errors'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </WithConditionWrapper>
        <WithConditionWrapper condition={!isLoading && !fetchErrors}>
          {this.generateLayout()}
        </WithConditionWrapper>
      </>
    )
  }

  private generateLayout() {
    const elements = this.state.data.map(this.generateSingleElement)
    return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 3))
  }

  private generateSingleElement(item: AspiratedAreaDataType) {
    return <TableColumnWrapper col={'1/3'}>
      <h6 className='generic-sub-title text-center'>{item.AssetName}</h6>
      <PercentsPiePrint
        data={[
          {
            chartLabel: '',
            color: COLORS.aspiredZone.BeltOnAspOnPerc,
            duration: item.BeltOnAspOnDuration,
            perc: item.BeltOnAspOnPerc,
            key: 'BeltOnAspOnPerc',
          },
          {
            chartLabel: '',
            color: COLORS.aspiredZone.BeltOnAspOffPerc,
            duration: item.BeltOnAspOffDuration,
            perc: item.BeltOnAspOffPerc,
            key: 'BeltOnAspOffPerc',
          },
          {
            chartLabel: '',
            color: COLORS.aspiredZone.BeltOffPerc,
            duration: item.BeltOffDuration,
            perc: item.BeltOffPerc,
            key: 'BeltOffPerc',
          },
        ]}
        hideLabels={true}
      />
    </TableColumnWrapper>
  }

  private _fetchData() {
    const {
      plant,
      dateStart,
      dateEnd,
    } = this.props

    if (dateStart && dateEnd) {
      if (this.mounted) {
        this.setState({
          isLoading: true,
          data: [],
          fetchErrors: false,
        })
      }
      const plantQueryString = plant && plant.plant !== '' ? 'plant=' + plant.plant + '&' : ''
      const startDateObj = moment(dateStart)
      const endDateObj = moment(dateEnd)

      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}' `
      const selectedMeasure = `(*)`
      const query =
        `${plantQueryString}q=SELECT ${selectedMeasure} as data FROM vAspiratorZone` + queryEnd

      try {
        API().request(`/query?${query}`, {
          signal: this.abortController.signal,
        })
          .then((results: any) => {
            if (this.mounted) {
              const measureData = parseInfluxResponse(results)

              this.setState({
                fetchErrors: false,
                data: measureData && measureData[0] && measureData[0].data && measureData[0].data[0] &&
                  measureData[0].data[0].hasOwnProperty('measure') &&
                  JSON.parse(measureData[0].data[0].measure) || [],
                isLoading: false,
              })
            }
          })
          .catch((e: any) => {
            console.log(e) // tslint:disable-line
            if (this.mounted) {
              this.setState({
                fetchErrors: true,
                data: [],
                isLoading: false,
              })
            }
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log(error) // tslint:disable-line
        if (this.mounted) {
          this.setState({
            fetchErrors: true, // mean?
            data: [],
            isLoading: false,
          })
        }
        throw error
      }
    }
  }
}

export default withTranslation()(AspiratedAreaGraphRowView)
