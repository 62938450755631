import { i18next as i18n, i18nextInit } from '../../inplant-core-fe/i18n/i18n'
import auth from '../../inplant-core-fe/i18n'
import chronoframe from '../../inplant-chronoframe-fe/i18n'
import director from '../i18n'
import overwrites from '../i18n/overwrite'
import fermiimpianto from '../../inplant-fermiimpianto-fe-iblu/i18n'
import fetchWrapper from '../../inplant-core-fe/i18n'
import titech from '../../inplant-titech-fe-iblu/i18n'
import user from '../../inplant-user-fe/i18n'
import plantStatus from '../../inplant-plantstatus-fe/i18n'
import assetManager  from '../../inplant-asset-manager-fe/i18n'
import plantAnalysis from '../../inplant-plantanalysis-fe-iblu/i18n'
import { extend } from '../../inplant-core-fe/functions/objects'
import components from '../../inplant-components-fe/i18n'
import coreAdapter from '../../inplant-coreadapter-fe/i18n'
import core from '../../inplant-core-fe/i18n'

i18nextInit({
  // @ts-ignore
  resources: extend(true, {
    en: {
      translation: {
        ...components.en,
        ...core.en,
        ...director.en,
        ...auth.en,
        ...assetManager.en,
        ...fetchWrapper.en,
        ...chronoframe.en,
        ...fermiimpianto.en,
        ...plantAnalysis.en,
        ...user.en,
        ...titech.en,
        ...plantStatus.en,
        ...coreAdapter.en
      },
    },
    it: {
      translation: {
        ...components.it,
        ...core.it,
        ...director.it,
        ...auth.it,
        ...assetManager.it,
        ...fetchWrapper.it,
        ...chronoframe.it,
        ...fermiimpianto.it,
        ...plantAnalysis.it,
        ...user.it,
        ...titech.it,
        ...plantStatus.it,
        ...coreAdapter.it,
      },
    },
  }, {
    en: {
      translation: {
        ...overwrites.en,
      }
    },
    it: {
      translation: {
        ...overwrites.it,
      }
    }
  })
})

export default i18n
