import TableRowWrapper
  , {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import * as React from 'react'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

export function generateTableRowFromArrayOfElements(elements: JSX.Element[][], withFullInnerTableInside?: boolean, hideHeader?:boolean) {
  const result: JSX.Element[] = []
  elements.forEach(elementsSet => {
    if (withFullInnerTableInside) {
      result.push(<TableRowFullWrapper>
        <TableWrapperUtilities hideHeader={hideHeader}>
          <TableRowWrapper>
            {elementsSet.map(element => element)}
          </TableRowWrapper>
        </TableWrapperUtilities>
      </TableRowFullWrapper>)
    } else {

      result.push(<TableRowWrapper>
        {elementsSet.map(element => element)}
      </TableRowWrapper>)
    }
  })
  return result
}

export function generateElementsArrayForTableRowLayout(elements: JSX.Element[], numberOfElementForRow: number) {
  const result: JSX.Element[][] = []
  let numberOfRow = 0
  elements.filter(element => typeof element === 'function' || typeof element === 'object').forEach((element, index) => {
    if (!result[numberOfRow]) {
      result.push([])
    }
    result[numberOfRow].push(element)
    if ((index + 1) % numberOfElementForRow === 0) {
      numberOfRow = numberOfRow + 1
    }
  })
  return result
}