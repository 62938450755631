import { GroupsListFilters } from '../../types/groups'
import { ListPagination } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {
  GroupListAction,
  GROUPS_LIST_SET_FILTERS,
  GROUPS_LIST_SET_PAGINATION,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/groupsList'

const initialState:GroupsListState = {
  filters: {
    searchString: null
  },
  pagination: {
    pageSize: 50,
    pageNumber: 1
  }
}

export interface GroupsListState {
  filters: GroupsListFilters,
  pagination: ListPagination
}

type GroupsListReducer = (
  state: GroupsListState,
  action: GroupListAction,
) => GroupsListState

export const groupsList: GroupsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUPS_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.params
      }
    case GROUPS_LIST_SET_PAGINATION:
      return {
        ...state,
        pagination: action.params
      }
    default:
      return state
  }
}