// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import DatePicker from 'react-datepicker'

// * -------------------------------- MODULE --------------------------------------
import InvalidFeedback from './InvalidFeedback'
import { mvDate } from '../../../mvfunctions/helpers/dateHelper'
import IconComponent from '../MVIcon/Icon'

interface Props {
  id?: string
  label?: string
  name?: string
  value?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  placeholder?: string
  min?: string
  max?: string
  isClearable?: boolean
  error?: string
  onChange: (value: string) => void
}

const datePattern = '^([0-3]?[0-9])[/|-]([0-1]?[0-9])[/|-]([0-9]{4})$'
const dateRegExp = new RegExp(datePattern)

const DateInput = React.forwardRef((props: Props, ref: React.Ref<any>) => {
  const handleChange = (value: Date) => {
    props.onChange(value && mvDate.format(value, 'yyyy-MM-dd'))
  }
  const handleInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const {value} = event.currentTarget
    const match = value && value.match(dateRegExp)
    if (match) {
      let date = mvDate.now()
      date = mvDate.setYear(date, parseInt(match[3], 10))
      date = mvDate.setMonth(date, parseInt(match[2], 10) - 1)
      date = mvDate.setDay(date, parseInt(match[1], 10))
      date = mvDate.setHours(date, 0)
      date = mvDate.setMinutes(date, 0)
      date = mvDate.setSeconds(date, 0)
      handleChange(date)
    }
  }
  let className = 'form-control'
  if (props.error != null) {
    className += ' is-invalid'
  }
  const momentValue = props.value ? mvDate.getDateFromString(props.value) : undefined

  return (
    <div className="form-group">
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}
          {props.required && <abbr className="text-danger ml-1">*</abbr>}
        </label>
      )}
      <div className="input-group date-input">
        <div className="input-group-prepend">
          <IconComponent
            icon={"calendar"}
          />
        </div>
        <div>
          <DatePicker
            id={props.id}
            ref={ref}
            className={className}
            selected={momentValue}
            dateFormat={'dd/MM/yyyy'}
            required={props.required}
            disabled={props.disabled}
            wrapperClassName="form-control w-auto"
            readOnly={props.readOnly}
            placeholderText={props.placeholder}
            minDate={props.min ? mvDate.getDateFromString(props.min) : (undefined)}
            maxDate={props.max ? mvDate.getDateFromString(props.max) : (undefined)}
            customInput={<input pattern={datePattern}/>}
            onChange={handleChange}
            onChangeRaw={handleInput}
            isClearable={props.isClearable}
            disabledKeyboardNavigation={true}
            autoComplete="off"
          />
        </div>
        {props.error && <InvalidFeedback error={props.error}/>}
      </div>
    </div>
  )
})

export default DateInput
