import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AssetsBatchTask, AssetTaskForCopy } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import moment from 'moment'
import { DateInput } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface StateProps {
  data: {
    form: AssetsBatchTask
    taskData: AssetTaskForCopy
  }
  handleDateChange: (date: string, propName: 'creationDate' | 'dueAtDate') => void
}

type Props = WithTranslation & StateProps

class TasksData extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const { data, t } = this.props
    const { form, taskData } = data
    return (
      <div>
        <h6 className='font-weight-bold'>{taskData.category}</h6>
        <Row>
          <Column sm={2}> <label className={'small-label-readonly'}>{t('assetManager.forms.creationDate')}</label>
          </Column>
          <Column sm={10}>
            <DateInput
              min={moment().format('YYYY-MM-DD')}
              required={true}
              value={form.creationDate}
              onChange={value => this.props.handleDateChange(value, 'creationDate')}
            />
          </Column>
          {!taskData.recurrent && (
            <>
              <Column sm={2}> <label className={'small-label-readonly'}>{t('assetManager.forms.dueAtDate')}</label>
              </Column>
              <Column sm={10}>
                <DateInput
                  min={form.creationDate}
                  required={true}
                  value={form.dueAtDate}
                  onChange={value => this.props.handleDateChange(value, 'dueAtDate')}
                />
              </Column>
            </>
          )}
          <Column sm={2}> <label className={'small-label-readonly'}>{t('assetManager.table.header.deadline')} </label>
          </Column>
          <Column sm={10}>{moment(taskData.date).format('DD-MM-YYYY')}</Column>
          <Column sm={2}> <label className={'small-label-readonly'}> {t('assetManager.table.header.ambit')} </label>
          </Column>
          <Column sm={10}>{taskData.ambit || '-'}</Column>
          <Column sm={2}> <label className={'small-label-readonly'}> {t('assetManager.table.header.category')}</label>
          </Column>
          <Column sm={10}>{taskData.category || '-'}</Column>
          <Column sm={2}> <label className={'small-label-readonly'}>{t('assetManager.table.header.subCategory')}</label>
          </Column>
          <Column sm={10}>{taskData.subCategory || '-'}</Column>
          <Column sm={2}> <label
            className={'small-label-readonly'}>{t('assetManager.table.header.rifLegislativo')}</label> </Column>
          <Column sm={10}>{taskData.legislativeReference || '-'}</Column>
          <Column sm={2}> <label className={'small-label-readonly'}>{t('assetManager.table.header.activity')}</label>
          </Column>
          <Column sm={10}>{taskData.activity || '-'}</Column>
        </Row>
        <hr />
      </div>
    )
  }
}

export default withTranslation()(TasksData)
