import { isJSON } from '../functions'

interface HealthCheckerLocalStorage {
  feVersion: string
}

export class HealthChecker {
  private KEY_HEALTH_CHECKER = 'health-checker'
  private _frontEndVersion: string

  public get frontEndVersion() {
    return this._frontEndVersion
  }

  constructor() {
    const healthChecker: Partial<HealthCheckerLocalStorage> =
      isJSON(localStorage.getItem(this.KEY_HEALTH_CHECKER)) &&
      JSON.parse(localStorage.getItem(this.KEY_HEALTH_CHECKER) ?? '')
    this._frontEndVersion = healthChecker?.feVersion || ''
  }

  public update(feVersion: string) {
    localStorage.setItem(this.KEY_HEALTH_CHECKER, JSON.stringify({ feVersion }))
    this._frontEndVersion = feVersion
  }
}
