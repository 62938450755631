// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import FilterInput from './FilterInput'
import { IFlexItem } from '../Flex/FlexItem'
import { IInputComponent } from '../Input/types'
import useWithDebounce from './useWithDebounce'

export interface ISearchFilter extends IInputComponent {
  throttle?: number
}

type Props = ISearchFilter & IFlexItem

const SearchFilter = ({ clearable = true, ...props }: Props) => {
  const [handleChange, value] = useWithDebounce({
    initialValue: props.initialValue,
    onChange: props.onChange,
    throttle: props.throttle,
  })

  return <FilterInput {...props} clearable={clearable} initialValue={value} onChange={handleChange} kind={'input'} />
}

export default SearchFilter
