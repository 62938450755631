import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetDetail,
  AssetFileCreation, AssetFileUpload,
  AssetForm as AssetFormType, AssetUpdate,
  CostCenter,
  Module,
  Site,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { GroupsListElement } from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import moment from 'moment'
import {
  fetchDetailAsset, fetchUpdateAsset, fetchUploadFIle,
  getAssetFormRules,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import {
  FetchSitesGrousModulesResponse, FetchSuccessCreateAsset, FetchUploadDocumentSuccess,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import { fetchSitesGroupsModules } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import { Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import AssetForm from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetForm/AssetForm'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnState {
  assetData: AssetFormType,
  assetId: string
  plants: Array<{ code: string, name: string }>,
  sites: Site[],
  costCenters: Array<{ plant: string, costCenters: CostCenter[] }>,
  groups: GroupsListElement[],
  modules: Module[],
  documents: AssetFileCreation[]
  isSubmitting: boolean
  isFetching: boolean
  editableDocuments: string[]
}

type Props = RouteComponentProps & WithTranslation

class UpdateAssetPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      assetData: {
        assetCode: '',
        activationDate: moment().format('YYYY-MM-DD'),
        positionCode: '', // Codice Impianto
        assetDescription: '', // Descrizione assrt
        assetNotes: '', // Note
        siteCode: '', // Sito
        plantCode: '', // Impianto
        costCenterCode: '', // Centro di costo
        assetBrand: '', // Marcca
        assetModel: '', // Model
        ownership: '',
        assetSerialNumber: '',  // Matricola
        groups: [], // gruppo cespiti
        modules: [], // moduli
        documents: [],
      },
      assetId: '',
      documents: [],
      plants: [],
      costCenters: [],
      groups: [],
      sites: [],
      modules: [],
      isSubmitting: false,
      isFetching: false,
      editableDocuments: []
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateAssetData = this.updateAssetData.bind(this)
    this.goToDetail = this.goToDetail.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] //tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list-active')
    }
    this.setState({
      assetId,
    }, () => this.fetchData())
  }

  public render() {
    const { t } = this.props
    const { assetData, sites, plants, costCenters, groups, modules, isSubmitting, documents, isFetching } = this.state
    return (
      <div className="inplant-asset-manager-fe">
        {
          isFetching ? (
            <Loader />
          )
            :
            (
              <form onSubmit={this.handleSubmit}>
                {/* Header Section*/}
                <PageHeader title={t('assetManager.navigation.updateAsset')}
                  backButtonOnClick={() => this.props.history.goBack()} backButton={true}
                  rightButtons={(
                    <>
                      <button className="btn btn-outline-primary" type="button" disabled={isSubmitting}
                        onClick={this.goToDetail}>
                        {t('assetManager.actions.cancel')}
                      </button>
                      <button className="btn btn-primary ml-2" type="submit" disabled={isSubmitting}>
                        {
                          isSubmitting ? (
                            <FontAwesomeIcon icon={faCircleNotch} color={'white'} spin={true} size="1x" />
                          )
                            :
                            t('assetManager.actions.save')
                        }
                      </button>
                    </>
                  )}
                />
                {/* End Header Section*/}
                {/* Content Section*/}
                <div className="content">
                  <Row >
                    <Column md={8}>
                      {
                        !isFetching && (
                          <>
                            <AssetForm
                              assetData={assetData}
                              groups={groups}
                              costCenters={costCenters}
                              documents={documents}
                              modules={modules}
                              plants={plants}
                              sites={sites}
                              updateAssetData={this.updateAssetData}
                              type="update"
                              getInputRule={(name: string, action: string) => getAssetFormRules(name, action, "update")}
                              editableDocuments={this.state.editableDocuments}
                            />
                            <div className="row justify-content-end mt2">
                              <button className="btn btn-outline-primary" type="button" disabled={isSubmitting}
                                onClick={this.goToDetail}>
                                {t('assetManager.actions.cancel')}
                              </button>
                              <button className="btn btn-primary ml-2 mr-1 " type="submit" disabled={isSubmitting}>
                                {
                                  isSubmitting ? (
                                    <FontAwesomeIcon icon={faCircleNotch} color={'white'} spin={true} size="1x" />
                                  )
                                    :
                                    t('assetManager.actions.save')
                                }
                              </button>
                            </div>
                          </>
                        )
                      }
                    </Column>
                  </Row>
                </div>
                {/* End Content Section*/}
              </form>
            )
        }
      </div>
    )
  }

  private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    const documents: AssetFileUpload[] = []
    for (const file of this.state.documents) {
      const result: FetchUploadDocumentSuccess = await fetchUploadFIle(file.file!)
      const assetFile: AssetFileUpload = {
        fileId: result.id,
        name: result.name,
        isInHeadquarter: file.isInHeadquarter || false,
        description: file.description || '',
        type: file.type || '',
      }
      documents.push(assetFile)
    }
    const { assetData } = this.state
    const assetUpdate: AssetUpdate = {
      documents: [...assetData.documents, ...documents],
      assetNotes: assetData.assetNotes,
      assetModel: assetData.assetModel,
      assetBrand: assetData.assetBrand,
      ownership: assetData.ownership,
      assetDescription: assetData.assetDescription,
      modules: assetData.modules,
      groups: assetData.groups.filter((group: string) => !(group === '')),
      assetSerialNumber: assetData.assetSerialNumber,
    }
    const data = JSON.parse(JSON.stringify(assetUpdate))
    Object.keys(data).forEach(k => {
      if (typeof data[k] === 'string' && !data[k]) {
        data[k] = null
      }
    })
    fetchUpdateAsset(data, this.state.assetId).then((result: FetchSuccessCreateAsset) => {
      this.goToDetail()
    }).catch((error: any) => {
      console.log(error) //tslint:disable-line
    })
  }

  private async fetchData() {
    this.setState({
      isFetching: true,
    })
    const assetDetail: AssetDetail = await fetchDetailAsset(this.state.assetId)
    const assetData: AssetFormType = { // TODO
      assetCode: assetDetail.assetCode,
      plantCode: assetDetail.plant.code,
      activationDate: moment(assetDetail.activatedOn).format('YYYY-MM-DD'),
      assetBrand: assetDetail.assetData.brand,
      assetDescription: assetDetail.assetDescription,
      assetModel: assetDetail.assetData.model,
      assetNotes: assetDetail.assetData.notes,
      assetSerialNumber: assetDetail.assetData.serialNumber,
      ownership: assetDetail.assetData.ownership,
      costCenterCode: assetDetail.costCenter.code,
      groups: assetDetail.groups.reduce((acc: string[], group) => {
        acc.push(group.id)
        return acc
      }, []),
      documents: assetDetail.assetData.documents,
      modules: assetDetail.modules.reduce((acc: string[], module) => {
        acc.push(module.id)
        return acc
      }, []),
      positionCode: assetDetail.positionCode,
      siteCode: assetDetail.site.code,
    }
    const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
    const plantsArray: Array<{ code: string, name: string }> = sitesData.sites.reduce((acc: Array<{ code: string, name: string }>, sites) => {
      acc = acc.concat(sites.plants.reduce((plants: Array<{ code: string, name: string }>, plant) => {
        plants.push({
          code: plant.code,
          name: plant.name,
        })
        return plants
      }, []))
      return acc
    }, [])
    const costCenters: Array<{ plant: string, costCenters: CostCenter[] }> = sitesData.sites.reduce((acc: Array<{ plant: string, costCenters: CostCenter[] }>, site) => {
      return acc.concat(site.plants.reduce((acc2: Array<{ plant: string, costCenters: CostCenter[] }>, plant) => {
        acc2.push({
          plant: plant.code,
          costCenters: plant.costCenters,
        })
        return acc2
      }, []))
    }, [])
    this.setState({
      sites: sitesData.sites,
      groups: sitesData.groups,
      modules: sitesData.modules,
      plants: plantsArray,
      costCenters,
      isFetching: false,
      assetData,
      editableDocuments: assetDetail.assetData.documents.reduce((acc: string[], doc) => {
        if (doc.editable) {
          acc.push(doc.fileId)
        }
        return acc
      }, [])
    })
  }

  private updateAssetData(assetData: AssetFormType) {
    this.setState({
      assetData,
    })
  }

  private goToDetail() {
    this.props.history.goBack()
    // push(`/asset-manager/detail-asset/${this.state.assetId}`)
  }
}

export default withRouter<any, any>((withTranslation())(UpdateAssetPageView))