import { combineReducers, ReducersMapObject } from 'redux'
import common from './common'
import general from './general'
import nastro from './nastro'
import model from './model'
import workshifts from './workshift'
import configurations from './configurations'
import halts from './halts'
import titech from './titech'

const plantAnalysisReducersMap: ReducersMapObject = {
  common,
  general,
  nastro,
  model,
  workshifts,
  configurations,
  halts,
  titech,
}

export default combineReducers(plantAnalysisReducersMap)
