import React, { useEffect, useState } from 'react'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import {
  extractResponseData,
  hydrateData,
  hydrateTimeData, populateManAutoLocSec,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import moment from 'moment/moment'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Loader } from '@mv-submodules/inplant-components-fe'

interface Props extends WithTranslation {
  title: string
  measure: {
    absorption: string
  }
  startDate: string
  endDate: string
}

const PLANT = 'ric-sg'
const QUERY_START = `SELECT * FROM`

const MillsGraph = (props: Props) => {
  const { measure, title, endDate, startDate } = props
  const QUERY_END = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`

  const [filteredData, setFilteredData] = useState<{ data: any, isLoading: boolean }>({
    data: undefined,
    isLoading: false,
  })
  const [data, setData] = useState<{
    absorption?: any,
  }>()

  const absorptionQuery = `${QUERY_START} "${measure.absorption}" ${QUERY_END}`


  const requestAbsorption = API().request(`/query?plant=${PLANT}&q=${absorptionQuery}`)

  function populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  useEffect(() => {
    if (data) {
      constructData()
    }
  }, [data])

  function constructData() {
    let absorption: any = []
    const mergedData: any[] = []
    if (data) {
      absorption = populateSingleData('absorption')
    }
    hydrateData(
      {
        absorption,
      },
      mergedData,
      undefined,
      0,
    )
    mergedData.sort((a, b) => {
      if (a.time < b.time) {
        return -1
      }
      if (a.time > b.time) {
        return 1
      }
      return 0
    })
    hydrateTimeData(
      [
        'torque',
        'speed',
        'closeOpen',
      ],
      mergedData,
      {
        startDate: new Date(startDate).getTime() / 1000,
        endDate: new Date(endDate).getTime() / 1000,
        last: {},
      },
    )
    populateManAutoLocSec(mergedData)

    setFilteredData({ data: mergedData, isLoading: false })
  }

  useEffect(() => {
    setFilteredData(prev => ({
      ...prev,
      isLoading: true,
    }))
    Promise.all([
      requestAbsorption,
    ])
      .then(([
               dataRequestAbsorption,
             ]) => {
        setData({
          absorption: {
            data: extractResponseData(dataRequestAbsorption),
          },
        })
      })
  }, [startDate, endDate])

  return <>
    <h3>{title}</h3>
    {filteredData.isLoading && <Loader />}
    {filteredData.data &&
      <ResponsiveContainer width='90%' height={400}>
        <LineChart
          height={400}
          data={filteredData.data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barGap={0}
          barCategoryGap={0}
        >
          <XAxis
            key={'line1'}
            hide={false}
            dataKey='time'
            label={''}
            height={30}
            axisLine={true}
            tickLine={false}
            tick={true}
            domain={['dataMin', 'dataMax']}
            tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
            type={'category'}
          />
          <Tooltip content={(d: any) => tooltipContent(d, props.t)} />
          <CartesianGrid stroke='#f5f5f5' />
          <YAxis
            orientation={'left'}
            yAxisId={'A'}
            axisLine={false}
            tickFormatter={val => parseInt(val, 10)}
          />
          <Line
            dataKey='absorption'
            stroke={'#FED283'}
            dot={false}
            strokeWidth={2}
            type='step'
            isAnimationActive={false}
            yAxisId={'A'}
            unit={''}
          />
        </LineChart>
      </ResponsiveContainer>
    }
    <div>
       <span style={{ whiteSpace: 'nowrap' }}>
        <span
          className='label-color-square mx-3'
          style={{ backgroundColor: '#FED283' }}
        />
        <span>{props.t(`plantAnalysis.dedicatedGraph.absorption`)}</span>
    </span>
    </div>
  </>
}

const tooltipContent = (data: any, t: Function) => {
  return <div className={'tooltip-container'}>
    <h3>{moment.unix(data.label).format('HH:mm')}</h3>
    {data.payload.map((valuePayload: any) => (
      <div>
      <span style={{ whiteSpace: 'nowrap' }}>
      <span
        className='label-color-square mx-3'
        style={{ backgroundColor: valuePayload.stroke }}
      />
        <span>{t(`plantAnalysis.dedicatedGraph.${valuePayload.dataKey}`)}: {valuePayload.value.toFixed(2)} A</span>
      </span>
      </div>
    ))}
  </div>
}
export default withTranslation()(MillsGraph)