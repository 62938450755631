// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE -----------------------------------
import IconComponent from '../MVIcon/Icon'

interface Props {
  id?: string
  label?: string
  name?: string
  selectedValues: string[]
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  options: Array<{ value: string, label: string }>,
  headingLabel: string
  selectAllLabel: string
  allSelectedLabel: string
  onClickSelectAll: () => void
  onClickItem: (value: string) => void
  error?: string
}

interface OwnState {
  expanded: boolean,
  selectBoxRef: React.RefObject<any>

}

class MultiSelect extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      expanded: false,
      selectBoxRef: React.createRef(),
    }
    this.toggleExpanded = this.toggleExpanded.bind(this)
    this.getSelectedLabels = this.getSelectedLabels.bind(this)
    this.renderHeading = this.renderHeading.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
    this.onClickSelectAll = this.onClickSelectAll.bind(this)
  }

  public componentDidMount() {
    if (this.state.selectBoxRef.current) {
      this.state.selectBoxRef.current.addEventListener('click', this.toggleExpanded, true)
    }
    document.addEventListener('click', ev => this.handleDocumentClick(ev), false)
  }

  public componentWillUnmount() {
    if (this.state.selectBoxRef.current) {
      this.state.selectBoxRef.current.removeEventListener('click', this.toggleExpanded, true)
    }
    document.removeEventListener('click', ev => this.handleDocumentClick(ev), false)
  }

  private handleDocumentClick(event: MouseEvent) {
    if (this.state.expanded && !event.defaultPrevented && !event.stopPropagation) {
      this.setState({ expanded: false })
    }
  }

  private toggleExpanded(event: React.MouseEvent<HTMLDivElement>) {
    this.setState({ expanded: !this.state.expanded })
    event.stopPropagation()
  }

  private getSelectedLabels() {
    return `(${this.props.selectedValues.length}) ${this.props.selectedValues.length === this.props.options.length ? this.props.allSelectedLabel : `${this.props.options.filter(o => this.props.selectedValues.includes(o.value)).map(o => o.label).join(', ')}.`}`
  }

  private renderHeading() {
    return this.props.selectedValues.length === 0 ? this.props.headingLabel : this.getSelectedLabels()
  }

  private onClickItem(event: React.MouseEvent<HTMLInputElement>, value: string) {
    event.stopPropagation()
    this.props.onClickItem(value)
  }

  private onClickSelectAll(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation()
    this.props.onClickSelectAll()
  }

  public render() {
    return (
      <div className='multi-select'>
        <div className='select-box form-group' ref={this.state.selectBoxRef}>
          {this.props.label && (
            <label htmlFor={this.props.id}>{this.props.label}</label>
          )}
          <div className='input-group'>
            <div className='input-group-prepend'>
              <IconComponent
                icon={"filter"}
              />
            </div>
            <select className='form-control'>
              <option>{this.renderHeading()}</option>
            </select>
          </div>
          <div className='over-select' />
        </div>
        {this.state.expanded && (
          <div className='checkboxes'>
            <div className='checkboxes-container'>
              <label
              >
                <input type='checkbox'
                       readOnly={true}
                       id='multi_select_option_select_all'
                       checked={this.props.selectedValues.length === this.props.options.length}
                       onClick={(event) => this.onClickSelectAll(event)}
                />
                <label htmlFor={`multi_select_option_select_all`}>{this.props.selectAllLabel}</label>
              </label>
              {this.props.options.map((option, index) => (
                <label key={`multi_select_option_${index}`}>
                  <input type='checkbox'
                         id={`multi_select_option_${index}`}
                         readOnly={true}
                         checked={this.props.selectedValues.includes(option.value)}
                         onClick={(event) => this.onClickItem(event, option.value)}
                  /> <label htmlFor={`multi_select_option_${index}`}>{option.label}</label></label>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default MultiSelect