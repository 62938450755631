import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DateRangeProps, Workshift } from '../../../../types/workshift'
import { GeneralData, Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ChartPlaceholder, HHHmmssFromMilliseconds, isJSON, Loader } from '../../../../functions/shared'
import { hydrateData, hydrateTimeData, parseResponseData, parseResponseSingleData } from '../../../../functions/series'
import GraphTimelinePrint from '../GraphTimeline/GraphTimelinePrint'
import GraphLineBarTimePrint from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimePrint'
import { COLORS } from '@mv-submodules/inplant-plantanalysis-fe-iblu/constants'
import NamedBottomLegendPrint
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BottomLegend/NamedBottomLegend/NamedBottomLegendPrint'
import {
  TableRowFullWrapper,
  TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'

interface OwnState {
  brush1End: number
  brush1EndInd: number
  brush1Start: number
  brush1StartInd: number
  data: { [key: string]: Measure }
  dayEnd: string | null
  dayStart: string | null
  endDate: string | number
  fetchErrors: boolean
  filteredData: any[]
  isCollapsed: boolean
  isLoading: boolean
  last: any
  lastAfter: any
  mergedData: undefined | any[]
  recipeNames: string[]
  showTooltip: boolean
  startDate: string | number
  tooltipData: any
}

interface OwnProps {
  active: string
  component: any
  dateEnd: string
  dateStart: string
  forceFetch?: boolean
  showFullDay?: boolean
  toggleCollapse: (id: string) => void
}

interface StateProps {
  aspirato: GeneralStore
  fullDay: Workshift | null
  model: null | GeneralData
  plant: string | null
  recipeTables: string | null
  recipeConfig: any | null
  ricetta: GeneralStore
  workshift: Workshift | null
}

const mapStateToProps = (state: any): StateProps & Partial<DateRangeProps> => ({
  aspirato: state.plantAnalysis.general.aspirato,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  model: state.plantAnalysis.model,
  plant: state.plantSelector.plant,
  recipeTables: (state.config && state.config.plantAnalysis && state.config.plantAnalysis.recipeTables) || null,
  recipeConfig:
    (state.config &&
      state.config.plantAnalysis &&
      state.config.plantAnalysis.recipeConfig &&
      isJSON(state.config.plantAnalysis.recipeConfig) &&
      JSON.parse(state.config.plantAnalysis.recipeConfig)) ||
    null,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
})

const cleanState = {
  brush1End: 0,
  brush1EndInd: 0,
  brush1Start: 0,
  brush1StartInd: 0,
  data: {
    ricetta: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  dayEnd: null,
  dayStart: null,
  endDate: 'auto',
  fetchErrors: false,
  filteredData: [],
  isCollapsed: false,
  isLoading: false,
  last: null,
  lastAfter: null,
  mergedData: [],
  recipeNames: [],
  showTooltip: false,
  startDate: 'auto',
  tooltipData: null,
}

type Props = StateProps & OwnProps & DateRangeProps & WithTranslation

class NamedRecipeGraphView extends React.Component<Props, OwnState> {
  private mounted: boolean = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = cleanState
  }

  public componentDidMount() {
    this.mounted = true
    this.getDayRange(this.props)
  }

  private getDayRange(props: Props) {
    const { dateStart, dateEnd, model } = props
    const shifts = model?.data?.model?.content?.data?.shifts as Array<{ to: string, from: string, n: number }>

    if (dateStart && shifts && shifts.length > 0) {
      const firstWorkshift = shifts.find(s => s.n === 1)
      if (firstWorkshift) {
        const firstWorkshiftTime = firstWorkshift.from.split(':')

        if (firstWorkshiftTime) {
          const lastWorkshift = shifts.reduce((acc, cur: { to: string, from: string, n: number }) => {
            if (!acc || (acc && acc.n < cur.n)) {
              acc = cur
            }

            return acc
          })

          const endsTheDayAfter = lastWorkshift && (parseInt(lastWorkshift.to, 10) < parseInt(lastWorkshift.from, 10))

          this.setState({
            dayStart: moment(dateStart).utc().set({
              hours: parseInt(firstWorkshiftTime[0], 10),
              minutes: parseInt(firstWorkshiftTime[1], 10),
              seconds: 0,
            }).format('YYYY-MM-DD HH:mm:ss').toString(),
            dayEnd: moment(dateEnd).utc().add({ day: endsTheDayAfter ? 1 : 0 }).set({
              hours: parseInt(firstWorkshiftTime[0], 10),
              minutes: parseInt(firstWorkshiftTime[1], 10),
              seconds: 0,
            }).subtract({
              seconds: 1,
            }).format('YYYY-MM-DD HH:mm:ss').toString(),
          }, () => {
            this.getData(props)
          })
        }
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      (
        nextProps.active === this.props.component.id ||
        nextProps.plant !== this.props.plant ||
        nextProps.dateStart !== this.props.dateStart ||
        nextProps.dateEnd !== this.props.dateEnd ||
        nextProps.model && (!nextProps.model.fetching && (!this.props.model || this.props.model.fetching))
      )
    ) {
      this.getDayRange(nextProps)
    }
  }

  public render() {
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading
    const { isDateFilterRange, showFullDay } = this.props
    const { recipeNames } = this.state

    const recipeColors: { [k: string]: string } = {}

    recipeNames.forEach((n, i) => {
      recipeColors[n] = COLORS.globalRicetta[i]
    })

    const ricetteTime: {} = {}

    if (this.state.filteredData && this.state.filteredData.length > 0) {
      const startTime = this.props.fullDay
        ? parseInt(moment(this.props.fullDay.start).format('X'), 10)
        : this.state.filteredData[0].time
      const endTime = this.props.fullDay
        ? parseInt(moment(this.props.fullDay.end).format('X'), 10)
        : this.state.filteredData[this.state.filteredData.length - 1].time

      let currentTime = this.state.filteredData[0].time
      let currentRecipe = this.state.filteredData[0].ricetta || 0
      const totalTime = endTime - startTime

      this.state.filteredData.forEach((e: { time: number; ricetta: number }, index: number) => {
        const diffTime = e.time - currentTime

        if (!ricetteTime[currentRecipe]) {
          ricetteTime[currentRecipe] = {
            time: diffTime,
          }
        } else {
          ricetteTime[currentRecipe] = {
            time: ricetteTime[currentRecipe].time + diffTime,
          }
        }

        currentTime = e.time
        currentRecipe = e.ricetta
      })

      Object.keys(ricetteTime).forEach(k => {
        ricetteTime[k] = {
          time: ricetteTime[k].time,
          perc: ricetteTime[k].time / totalTime,
        }
      })
    }

    return (
      <>
        <WithConditionWrapper
          condition={!isDateFilterRange}
        >
          <TableRowFullWrapperWithCondition condition={noData}>
            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          </TableRowFullWrapperWithCondition>
          <TableRowFullWrapperWithCondition condition={this.state.isLoading}>
            <div className='alert alert-secondary w-100 col-sm-6 mx-auto'>
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          </TableRowFullWrapperWithCondition>
          <TableRowFullWrapperWithCondition condition={this.state.fetchErrors}>
            <div className='alert alert-danger w-100 col-sm-6 mx-auto general-graph-fetch-errors'>
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          </TableRowFullWrapperWithCondition>
        </WithConditionWrapper>

        <WithConditionWrapper
          condition={!isDateFilterRange}
          onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}>
          <WithConditionWrapper condition={isReady}>
            <TableRowFullWrapper>
              <GraphLineBarTimePrint
                filteredData={this.state.filteredData}
                forcedColors={recipeColors}
                topMargin={true}
                entry={'ricetta'}
                colorsId={'named'}
                i18nTitle={'plantAnalysis.labels.ricetta'}
                i18nLabelPrefix={'plantAnalysis.ricettaAttuale.'}
                tooltip={true}
              />

              <GraphTimelinePrint data={this.state.filteredData} preserveStartEnd={true} xAxisNumber={true} />

              <NamedBottomLegendPrint
                name={'recipe'}
                labelColors={recipeColors}
              />

              <div className='mt-3'>
                {showFullDay && <h5>&nbsp;</h5>}
                <table className='table table-striped table-sm recipe-stats '>
                  <thead>
                  <tr>
                    <th>{this.props.t('plantAnalysis.labels.recipe')}</th>
                    <th>%</th>
                    <th>{this.props.t('plantAnalysis.labels.duration')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Object.keys(ricetteTime).map((k: string | number) => {
                    if (ricetteTime.hasOwnProperty(k)) {
                      const timeData = moment.duration(ricetteTime[k].time, 'seconds')
                      return (
                        <tr key={k}>
                          <th>
                            {k !== '0' && k !== 0 && k !== undefined ? k : 'Non definito'}
                          </th>
                          <td>{(ricetteTime[k].perc * 100).toFixed(2)}</td>
                          <td>
                            {HHHmmssFromMilliseconds(timeData.asMilliseconds(), false, true, false)}
                          </td>
                        </tr>
                      )
                    }
                    return null
                  })}
                  </tbody>
                </table>
              </div>
            </TableRowFullWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false): Array<{ x: number, y: number, name: string, h100: number } | {}> {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1] as number,
          h100: 100,
        }
      })
    }

    return [{}]
  }

  private async getData(props: Props) {
    const { isLoading, dayEnd, dayStart } = this.state
    if (!isLoading) {
      try {
        this.setState({ isLoading: true, fetchErrors: false })

        const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

        const queryStart = `SELECT "measure" FROM "`
        const queryEnd = `" WHERE time >= '${dayStart}' AND time <= '${dayEnd}'`
        const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
        const queryLastEnd = `" WHERE time < '${dayStart}' LIMIT 1`

        const queryRicetta = queryStart + 'CURRENT_REC_ACT_NAME' + queryEnd
        const queryLastRicetta = queryLastStart + 'CURRENT_REC_ACT_NAME' + queryLastEnd

        const dataRicettaSrc = await API().request(`/query?${plantQuery}q=` + queryRicetta, {
          signal: this.abortController.signal,
        })

        const dataLastRicettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastRicetta, {
          signal: this.abortController.signal,
        })

        Promise.all([
          dataRicettaSrc,
          dataLastRicettaSrc,
        ]).then(() => {
          if (this.mounted) {
            this.setState({
              data: Object.assign({}, this.state.data, {
                ricetta: { data: parseResponseData(dataRicettaSrc) },
              }),
              last: Object.assign({}, this.state.data, {
                ricetta: parseResponseSingleData(dataLastRicettaSrc),
              }),
              startDate: moment(dayStart).unix(),
              endDate: moment(dayEnd).unix(),
              isLoading: false,
              fetchErrors: false,
            })

            this.prepareData()
          }
        })
      } catch (error: any) {
        console.log(error) // tslint:disable-line
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isLoading: false,
            fetchErrors: true,
          })
        }
      }
    }
  }

  private prepareData() {
    try {
      let ricettaSrc: any = []
      let ricetta: any = []
      const mergedData: any[] = []
      const recipeNames: string[] = []

      const { dayStart, dayEnd, startDate, last } = this.state

      if (this.state.data) {
        ricettaSrc = this.populateSingleData('ricetta', false)
        ricetta = ricettaSrc.filter((i: any) => moment.unix(i.x).isBetween(dayStart, dayEnd))

        if (ricetta.length === 0 && last.ricetta) {
          // no data, let's use last info
          ricetta = [{
            y: last.ricetta[1],
            x: startDate,
            h100: 100,
          }]
        }

        hydrateData(
          {
            ricetta,
          },
          mergedData,
        )

        if (mergedData.length > 0) {
          mergedData.sort((a, b) => {
            if (a.time < b.time) {
              return -1
            }
            if (a.time > b.time) {
              return 1
            }
            return 0
          })

          hydrateTimeData(
            ['ricetta'],
            mergedData,
            this.state,
            true,
            undefined,
            undefined,
            undefined,
            true,
          )
        }

        mergedData.forEach((r: { ricetta: number | string }) => {
          if (typeof r.ricetta === 'string' && !recipeNames.includes(r.ricetta)) {
            recipeNames.push(r.ricetta)
          }
        })

        if (this.mounted) {
          this.setState({
            brush1EndInd: mergedData.length,
            brush1StartInd: 0,
            fetchErrors: false,
            filteredData: mergedData,
            isLoading: false,
            mergedData,
            recipeNames,
          })
        }
      }
    } catch (error: any) {
      console.log(error) // tslint:disable-line
      if (this.mounted) {
        this.setState({
          fetchErrors: true,
          isLoading: false,
        })
      }
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(NamedRecipeGraphView)))
