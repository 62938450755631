import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { Pie, PieChart, ResponsiveContainer, Cell } from 'recharts'
import { COLORS } from '../../../../../constants'
import { Loader } from '../../../../../functions/shared'
import { renderCustomizedLabel } from '../../../../../functions/renderCustomizedLabelPie'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'
import TableRowWrapper, {
  TableRowFullWrapper, TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

export type LineLoadingBeltType = 'LineLoadingBelt'

const measuresToFetch = [
  'buffer_f057_over_one_hundred_percent',
  'buffer_f057_from_seventy_five_to_one_hundred_percent',
  'buffer_f057_from_fifty_to_seventy_five_percent',
  'buffer_f057_from_twenty_five_to_fifty_percent',
  'buffer_f057_from_zero_to_twenty_five_percent',
  'buffer_f019_over_one_hundred_percent',
  'buffer_f019_from_seventy_five_to_one_hundred_percent',
  'buffer_f019_from_fifty_to_seventy_five_percent',
  'buffer_f019_from_twenty_five_to_fifty_percent',
  'buffer_f019_from_zero_to_twenty_five_percent',
  'buffer_f028_over_one_hundred_percent',
  'buffer_f028_from_seventy_five_to_one_hundred_percent',
  'buffer_f028_from_fifty_to_seventy_five_percent',
  'buffer_f028_from_twenty_five_to_fifty_percent',
  'buffer_f028_from_zero_to_twenty_five_percent',
]

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: LineLoadingBeltType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  loadingBelt: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataLoadingBelt: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class LineLoadingBelt extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      loadingBelt: {},
      fetching: false,
      error: false,
      dataLoadingBelt: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state


    return (
      <>
        <TableRowFullWrapperWithCondition condition={!fetching && error}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition
          condition={!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data)}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
        </TableRowFullWrapperWithCondition>
        <WithConditionWrapper condition={!error}>
          <WithConditionWrapper condition={!hiddenCharts || !hiddenCharts.includes('LineLoadingBelt')}>
            <WithConditionWrapper condition={!this.state.fetching} onError={
              <TableRowFullWrapper>
                <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
                <Loader />
              </TableRowFullWrapper>
            }>
              <WithConditionWrapper condition={this.state.data}>
                <TableRowFullWrapper>
                  <TableWrapperUtilities>
                    <TableRowFullWrapper>
                      <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
                    </TableRowFullWrapper>
                    <TableRowWrapper>
                      {this.state.data &&
                        Object.entries(this.state.data).map(([key, value], index) => {
                          return (
                            <TableColumnWrapper key={key} col={'1/3'}>
                              <ResponsiveContainer className={'mx-2'} key={index} width='100%' height={250}>
                                <PieChart /* width={350} */ height={250}>
                                  <Pie
                                    nameKey={'key'}
                                    dataKey={'value'}
                                    data={value as any[]}
                                    cx={130}
                                    cy={125}
                                    isAnimationActive={false}
                                    outerRadius={100}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                  >
                                    {(value as any[]).map((entry, valueIndex) => {
                                      const label = entry.key.split(`${key}_`)[1]
                                      return (
                                        <Cell
                                          textRendering='auto'
                                          key={valueIndex}
                                          fill={COLORS.lines.loading[label] || 'gray'}
                                        />
                                      )
                                    })}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                              <h3>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.${key}`)}</h3>
                            </TableColumnWrapper>
                          )
                        })}
                    </TableRowWrapper>
                    <TableRowFullWrapper>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: COLORS.lines.loading.from_zero_to_twenty_five_percent,
                      }}
                    />
                    <span>0 - 25%</span>
                  </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: COLORS.lines.loading.from_twenty_five_to_fifty_percent,
                      }}
                    />
                    <span>25 - 50%</span>
                  </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: COLORS.lines.loading.from_fifty_to_seventy_five_percent,
                      }}
                    />
                    <span>50 - 75%</span>
                  </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: COLORS.lines.loading.from_seventy_five_to_one_hundred_percent,
                      }}
                    />
                    <span>75 - 100 %</span>
                  </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{ backgroundColor: COLORS.lines.loading.over_one_hundred_percent }}
                    />
                    <span>+ 100 %</span>
                  </span>
                    </TableRowFullWrapper>
                  </TableWrapperUtilities>
                </TableRowFullWrapper>
              </WithConditionWrapper>
            </WithConditionWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </>
    )
  }

  private formatData(data: any) {
    try {
      const resultData = [
        data.columns
          .map((value: any, index: number) => {
            if (value !== 'time' && value !== 'shift') {
              return {
                [value]: data.values[0][index],
              }
            }
          })
          .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {}),
      ]
      this.generateKey(resultData[0])
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: true,
      })
    }
  }

  private generateKey(data: Record<string, any>) {
    try {
      const keys = Object.keys(data)
      const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
      const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
      this.setState({
        keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
        data: splitKey
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(key => ({
            [key]: Object.entries(data)
              .filter(([keyFilter, value]) => keyFilter.includes(key))
              .map(([keyMap, value]) => {
                return {
                  value,
                  key: keyMap,
                }
              }),
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      })
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: true,
      })
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const queryEnd = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const queryStart = `SELECT ${measuresToFetch.map((m, index) => `"${m}"`)} FROM `
        const bufferStateQuery = queryStart + `vLoadingBeltsSpeed` + queryEnd

        const dataBufferState = API().request(`/query?${plantQueryString}q=` + bufferStateQuery, { signal: this.abortController.signal })

        Promise.all([dataBufferState])
          .then(([dataBufferStateResult]) => {
            try {
              const dataBufferStateResultValue =
                typeof dataBufferStateResult === 'string' ? JSON.parse(dataBufferStateResult) : dataBufferStateResult
              if (this.mounted && dataBufferStateResultValue.results[0].series[0]) {
                this.formatData(dataBufferStateResultValue.results[0].series[0])
                this.setState({
                  fetching: false,
                  error: false,
                })
              }
              if (dataBufferStateResultValue.results[0].error) {
                this.setState({
                  data: null,
                  fetching: false,
                  error: false,
                })
              }
            } catch (error) {
              this.setState({
                data: null,
                fetching: false,
                loadingBelt: {},
                error: false,
              })
            }
          })
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              loadingBelt: {},
              fetching: false,
              error: true,
            })
          })

      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          loadingBelt: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(LineLoadingBelt))
