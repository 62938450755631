import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { PlantAnalysisGeneralsSettings } from '../../types/settings'

export const configurationIsSaving = (status: boolean): AnyAction => {
  return {
    type: 'PLANTANALYSIS_CONFIGURATION_IS_SAVING',
    payload: status,
  }
}

export const configurationSaveSuccess = (configuration: PlantAnalysisGeneralsSettings): AnyAction => {
  return {
    type: 'PLANTANALYSIS_CONFIGURATION_SAVE_SUCCESS',
    payload: configuration,
  }
}

export const configurationSaveError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_CONFIGURATION_SAVE_ERROR',
    payload: error,
  }
}

export const configurationSaveData = (
  configurations: PlantAnalysisGeneralsSettings
): ((dispatch: Dispatch) => Promise<PlantAnalysisGeneralsSettings>) => {
  return async dispatch => {
    dispatch(configurationIsSaving(true))
    // const plantQueryString = plantQuery(getState)
    try {
      dispatch(configurationSaveSuccess(configurations))
      dispatch(configurationIsSaving(false))

      return configurations
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(configurationIsSaving(false))
      dispatch(configurationSaveError(error))
      throw error
    }
  }
}
