// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

// * -------------------------------- MODULE --------------------------------------
import Flex from '../../Flex/Flex'
import IconComponent from '../../Icon/Icon'
import Linkable from '../../Link/Linkable'
import { Badge } from '../../Badges/Badge'
import { BadgeSemantics } from '../../Badges/Badge'
import { RouteProps, translateRouteKey } from './SideBar'
import { getUniqueId } from '../../../../functions/helpers/stringHelper'
import { handleClickLocation } from '../../../../functions/handleEvents'
import { logger } from '../../../../functions/logs'
import { useComponentsTranslation } from '../../../../services/translation'

interface OwnProps {
  active: boolean
  open: boolean
  collapsed: boolean
  baseClassName: string
  tabIndex?: number
  sidebarCounters?: { [key: string]: SidebarCounter }
  onClickItem: () => void
}

export interface SideBarItemProps extends RouteProps, OwnProps {}

type Props = SideBarItemProps

export interface RouteCounter {
  // the key where is stored the counter in the localStorage
  storeKey: string
}

export interface SidebarCounter extends RouteCounter {
  color: BadgeSemantics
  count: number
  alwaysVisible: boolean
}

const SideBarItem = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const translation = useComponentsTranslation()
  const location = useLocation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const mvi = `${props.baseClassName}__item${props.collapsed ? '--collapsed' : ''}`
  const mvs = `${props.baseClassName}__subitem`
  const [uniqueId] = useState(getUniqueId(props.i18nkey))

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [props])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------

  const renderRightComponent = () => {
    return (
      <div className={'item__arrow'}>
        {props.children && props.children.length > 0 && !props.collapsed ? (
          <IconComponent icon={props.open ? 'angle-up' : 'angle-down'} />
        ) : (
          renderCounterBadge()
        )}
      </div>
    )
  }

  const renderCounterBadge = (sidebarCounter?: SidebarCounter) => {
    return (
      (sidebarCounter && (
        <Badge kind={'notification'} counter={sidebarCounter.count} semantic={sidebarCounter.color || 'danger'} />
      )) ||
      null
    )
  }

  function handleClick(event: MouseEvent) {
    const specifiedElement: HTMLElement | null = document.getElementById(uniqueId) as HTMLElement | null
    if (props.open && props.collapsed) {
      handleClickLocation(specifiedElement, event, () => props.onClickItem())
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------

  // ? External link
  if (props.external) {
    try {
      const href = new URL(props.path)
      if (props.requiredAuth?.required) {
        href.searchParams.set('token', props.requiredAuth.token)
      }

      return (
        <li
          id={uniqueId}
          tabIndex={props.tabIndex}
          className={`${mvi} ${(props.open && `${mvi}--open`) || ''} ${(props.active && `${mvi}--active`) || ''}`}
        >
          {(props.collapsed && (
            <Flex onClick={props.onClickItem}>
              <Item {...props} tabIndex={props.tabIndex} icon={props.icon} />
            </Flex>
          )) || <LinkItem tabIndex={props.tabIndex} {...props} href={href.toString()} to={undefined} />}
          {(props.collapsed && (
            <ul className={`${props.baseClassName}__${props.collapsed ? 'collapsed-' : ''}submenu`}>
              <li className={`${mvs}`} tabIndex={props.tabIndex}>
                <a tabIndex={props.tabIndex} href={href.toString()}>
                  <span>{translateRouteKey(translation, props)}</span>
                </a>
              </li>
            </ul>
          )) ||
            null}
        </li>
      )
    } catch (e) {
      logger('SideBarItem.tsx', 'manage URL externalLink', e)
      return <></>
    }
  }

  return (
    <li
      id={uniqueId}
      tabIndex={props.tabIndex}
      className={`${mvi} ${(props.open && `${mvi}--open`) || ''} ${(props.active && `${mvi}--active`) || ''}`}
    >
      <div onClick={props.onClickItem}>
        <Item {...props} />
        {renderRightComponent()}
      </div>
      {props.children !== undefined ? (
        <ul className={`${props.baseClassName}__${props.collapsed ? 'collapsed-' : ''}submenu`}>
          {props.children.map((item, index) => {
            const sidebarCounter =
              (item.counter?.storeKey && props.sidebarCounters?.[item.counter?.storeKey]) || undefined
            return (
              <li
                key={index}
                tabIndex={props.tabIndex}
                className={`${mvs} ${location.pathname.includes(item.path) ? `${mvs}--active` : ''}`}
              >
                <Link tabIndex={props.tabIndex} to={item.path}>
                  <span>{translateRouteKey(translation, item)}</span>
                  {renderCounterBadge(sidebarCounter)}
                </Link>
              </li>
            )
          })}
        </ul>
      ) : null}
    </li>
  )
}

const Item = (props: Props ) => {
  const translation = useComponentsTranslation()

  return (
    <div className={'item__label'}>
      {props.iconOverride ? (
        <img width={18} height={18} src={props.iconOverride} />
      ) : props.icon ? (
        <IconComponent icon={props.icon} />
      ) : null}
      {(!props.collapsed && <span>{translateRouteKey(translation, props)}</span>) || null}
    </div>
  )
}

const LinkItem = Linkable(Item)

export default SideBarItem
