const it = {
  dashboard: {
    module: 'Riepilogo',
    title: 'Riepilogo',
  },
  auth: {
    username: {
      label: 'Nome utente',
      placeholder: 'Inserisci il nome utente...',
      empty: 'Inserisci il nome utente',
    },
    password: {
      label: 'Password',
      placeholder: 'Inserisci la password...',
      empty: 'Inserisci la password',
    },
    passwordRecovery: 'Recupera password',
    submitText: 'Accedi',
    logout: 'Logout',
  },
  api: {
    errors: {
      error: 'Errore',
      error_400: 'Il server non può elaborare la richiesta',
      error_401: "L'utente non ha le credenziali per accedere",
      error_403: "L'utente non ha i permessi necessari per accedere alla risorsa",
      error_404: 'La risorsa richiesta non è stata trovata',
      error_405: 'Il metodo non è supportato per la risorsa richiesta',
      error_406: 'Errore di validazione',
      error_415: 'Tipo di media non supportato',
      error_422: 'La richiesta non è valida',
      error_500: 'Il server non è stato in grado di rispondere',
    },
    newVersion: {
      message: "E' presente una nuova versione",
      update: 'Aggiorna',
    },
    newVersionInstalled: {
      message: 'Il sistema è stato aggiornato con successo',
    },
  },
  plantSelectorWidget: {
    options: {
      disabled: 'Modulo non attivo per questo impianto',
      null: 'Seleziona impianto...',
    },
    plantChangedToAllowed:
      "I dati per l'impianto di {{fromPlant}} non sono disponibili.\nVengono visualizzati i dati dell'impianto di {{toPlant}}",
  },
  topbar: {
    actions: {
      selectTheme: 'Seleziona tema',
      light: 'Light',
      dark: 'dark',
    },
  },
  ieDetected: {
    title: 'Browser non supportato',
    subTitle: 'Il portale al momento non supporta questa versione del browser.',
    description: "Per usufruire di tutte le potenzialità del portale si consiglia di utilizzare l'ultima versione di",
    or: 'o di',
  },
  notification: {
    alert: 'Attenzione',
    success: 'Successo',
    warning: 'Attenzione',
    error: 'Errore',
    info: 'Info',
    information: 'Info',
  },
}

export default it
