import { PlantAnalysisGW } from '.'
import { manageRequestErrors } from '../../../inplant-core-fe/functions/fetchWrapper/FetchError'

interface RequestResponse {
  name: string
  columns?: Record<string, string>
  value?: Array<Record<string, any>>
}

export interface InfluxRequestResponse {
  name: string
  columns: string[]
  values: any[][]
}


export type ResponseParsing = 'onlyValues' | 'normal' | 'influxResponse'

function genericMeasureFetch(
  chartName: string,
  plant: string,
  responseParsingType: 'onlyValues',
  dateStart?: string,
  dateEnd?: string,
): any[][]
function genericMeasureFetch(
  chartName: string,
  plant: string,
  responseParsingType: 'influxResponse',
  dateStart?: string,
  dateEnd?: string,
): InfluxRequestResponse
function genericMeasureFetch(
  chartName: string,
  plant: string,
  responseParsingType?: 'normal',
  dateStart?: string,
  dateEnd?: string,
): RequestResponse
function genericMeasureFetch(
  chartName: string,
  plant: string,
  responseParsingType?: ResponseParsing | undefined,
  dateStart?: string | undefined,
  dateEnd?: string  | undefined,
) {
  return PlantAnalysisGW()
    .request(`/chart/${chartName}?plant=${plant}${dateStart ? `&startDate=${dateStart}` : ''}${dateEnd ? `&endDate=${dateEnd}` : ''}`)
    .then((res: RequestResponse) => handleReturnResult(res, responseParsingType))
    .catch(manageRequestErrors)
}

function handleReturnResult(res: RequestResponse, type?: ResponseParsing) {
  if (!type) {
    return res
  }

  switch (type) {
    case 'influxResponse': 
      return {
        name: res.name,
        columns: res.columns ? getArrayColumns(res.columns) : [],
        values: res.value ? getArrayValues(res.value) : []
      }
    case 'onlyValues':
      if (!res.value) {
        return []
      }
      return getArrayValues(res.value)
    case 'normal':
    default:
      return res
  }
}

function getArrayValues(values: Array<Record<string, any>>) {
  return values.map(val => Object.values(val).map(v => v))
}

function getArrayColumns(columns: Record<string, string>) {
  return Object.values(columns).map(val => val)
}

export default genericMeasureFetch
