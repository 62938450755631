import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetDetail,
  AssetFileCreation,
  AssetFileUpload,
  AssetForm as AssetFormType,
  AssetSubsititutionForm,
  CostCenter,
  AssetValidateActionAvailabilty,
  Module,
  Site,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {
  checkDeactivationAvailability,
  fetchDetailAsset,
  fetchUploadFIle,
  getAssetFormRules,
  replaceAsset,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import { Alert, DateInput, Loader, PageHeader, TextArea } from '@mv-submodules/inplant-components-fe'
import moment from 'moment'
import { fetchDeactivatedAssetListForPlant } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import AssetForm from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetForm/AssetForm'
import {
  FetchSitesGrousModulesResponse,
  FetchUploadDocumentSuccess,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import { fetchSitesGroupsModules } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import { GroupsListElement } from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import SearchableSelect from '@mv-submodules/inplant-components-fe/ui/components/Input/SearchableSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import Radio from '@mv-submodules/inplant-components-fe/ui/components/Input/Radio'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface DeactivatedAsset {
  brand: string | null
  code: string
  description: string
  id: string
  model: string | null
  serialNumber: string | null
}

interface OwnState {
  assetDetail: AssetDetail | null
  isFetching: boolean
  fetchError: boolean
  previousPath: string
  assetSubstitutionData: AssetSubsititutionForm
  substituteWith: 'substitution-new-asset' | 'substitution-with-deactivated'
  plants: Array<{ code: string; name: string }>
  sites: Site[]
  costCenters: Array<{ plant: string; costCenters: CostCenter[] }>
  groups: GroupsListElement[]
  modules: Module[]
  documents: AssetFileCreation[]
  deactivatedAssets: DeactivatedAsset[]
  selectedDeactivatedAsset?: DeactivatedAsset
  isSubmitting: boolean
  isFetchingDeactivationAvailabilty: boolean
  deactivationAvailability?: AssetValidateActionAvailabilty
}

type Props = RouteComponentProps & WithTranslation

class SubstituteAssetPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      assetDetail: null,
      isFetching: false,
      fetchError: false,
      previousPath: '',
      assetSubstitutionData: { replacedAction: 'scrap', replacedDate: moment().format('YYYY-MM-DD') },
      substituteWith: 'substitution-new-asset',
      documents: [],
      plants: [],
      costCenters: [],
      groups: [],
      sites: [],
      modules: [],
      deactivatedAssets: [],
      isSubmitting: false,
      isFetchingDeactivationAvailabilty: false,
    }
    this.fetchAssetDetail = this.fetchAssetDetail.bind(this)
    this.fetchDeactivatedAssets = this.fetchDeactivatedAssets.bind(this)
    this.fetchDeactivationAvailabilty = this.fetchDeactivationAvailabilty.bind(this)
    this.fetchSitesGroupsModules = this.fetchSitesGroupsModules.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeSubstitutionOption = this.handleChangeSubstitutionOption.bind(this)
    this.handleInputSelectDeactivatedAssetChange = this.handleInputSelectDeactivatedAssetChange.bind(this)
    this.updateAssetData = this.updateAssetData.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getInputRule = this.getInputRule.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] //tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list-active')
    }
    this.setState({
      previousPath: `/asset-manager/detail-asset/${assetId}`,
    })
    this.fetchAssetDetail(assetId)
  }

  public render() {
    const { t } = this.props
    const {
      isFetching,
      isSubmitting,
      isFetchingDeactivationAvailabilty,
      deactivationAvailability,
      fetchError,
      assetDetail,
      previousPath,
      assetSubstitutionData,
      substituteWith,
      groups,
      costCenters,
      plants,
      sites,
      modules,
      documents,
    } = this.state
    return (
      <div className="inplant-asset-manager-fe">
        {isFetching && <Loader />}
        {fetchError && !isFetching && (
          <Alert type={'danger'} >
            {t('assetManager.fetchErrors')}
          </Alert>
        )}
        {!isFetching && !fetchError && assetDetail && (
          <form onSubmit={this.onSubmit}>
            {/* Header Section*/}
            <PageHeader
              title={`${assetDetail.positionCode} ${assetDetail.assetDescription}`}
              backUrl={previousPath}
              backButton={true}
              rightButtons={
                <>
                  <button className="btn btn-outline-primary ml-2">{t('assetManager.actions.cancel')}</button>
                  <button
                    className="btn btn-primary ml-2"
                    type="submit"
                    disabled={isSubmitting || isFetchingDeactivationAvailabilty}
                  >
                    {isSubmitting || isFetchingDeactivationAvailabilty ? (
                      <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                    ) : (
                      t('assetManager.actions.save')
                    )}
                  </button>
                </>
              }
            />
            {/* End Header Section*/}
            {/* Content Section*/}
            <div className="content asset-substitute">
              {deactivationAvailability && !deactivationAvailability.allowed && (
                <Alert type={'primary'} >
                  {t('assetManager.status.needToValidateAllTasks')}
                </Alert>
              )}
              {/* MachineCode - PlantCode - AssetDescrtion*/}
              <Row>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.machineCode')}</label>
                  <p>
                    <strong>{assetDetail.assetCode || '-'}</strong>
                  </p>
                </Column>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.plantCode')}</label>
                  <p>
                    <strong>{assetDetail.positionCode}</strong>
                  </p>
                </Column>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.assetDescription')}</label>
                  <p>
                    <strong>{assetDetail.assetDescription}</strong>
                  </p>
                </Column>
              </Row>
              {/* End MachineCode - PlantCode - AssetDescrtion*/}
              {/* Disable Data*/}
              <Row >
                <Column md={3} formGroup={true} >
                    <DateInput
                      name="replacedDate"
                      value={assetSubstitutionData.replacedDate}
                      onChange={value =>
                        this.handleInputChange(undefined, { value: value as string, name: 'replacedDate' })
                      }
                      required={true}
                      id="form-endDate"
                    />
                </Column>
                <Column md={6}>
                  <Radio
                    name="replacedAction"                     
                    value={assetSubstitutionData.replacedAction}
                    onChange={(value) => this.handleInputChange(undefined, {value, name: "replacedAction" })}
                    labelPosition="RIGHT"
                    options= {{
                      items: [
                        {
                          label: t('assetManager.forms.scrap'),
                          value: "scrap",
                          id: "actionScrap"
                        },
                        {
                          label: t('assetManager.forms.deactivate'),
                          value: "deactivate",
                          id: "actionDeactivate"
                        }

                      ]
                    }}
                  />
                </Column>
              </Row>
              <Row >
                <Column md={9} formGroup={true}>
                    <TextArea
                      id="form-asset-notes"
                      value={assetSubstitutionData.replacedNote}
                      name="replacedNote"
                      onChange={value =>
                        this.handleInputChange(undefined, { value: value as string, name: 'replacedNote' })
                      }
                      label={t('assetManager.forms.notes')}
                    />
                </Column>
              </Row>
              {/* End disable Data*/}
              <Row>
                <Column md={9}>
                  <hr />
                </Column>
              </Row>
              <Row >
                <Column md={6}>

                <Radio
                    name="substituteWith"                     
                    value={substituteWith}
                    onChange={(value) => this.handleChangeSubstitutionOption(undefined, {value})}
                    labelPosition="RIGHT"
                    options= {{
                      items: [
                        {
                          label: t('assetManager.forms.substituteWithNewAsset'),
                          value: "substitution-new-asset",
                          id: "actionNewAsset"
                        },
                        {
                          label: t('assetManager.forms.substituteWithScrapAsset'),
                          value: "substitution-with-deactivated",
                          id: "actionDeactivatedAsset"
                        }

                      ]
                    }}
                  />
                </Column>
              </Row>
              {substituteWith === 'substitution-with-deactivated' && this.state.deactivatedAssets && (
                <Row>
                  <Column  md={6}>
                    <SearchableSelect
                      label={t('assetManager.forms.deactivatedAsset')}
                      labelClassName="font-weight-bold"
                      onChange={this.handleInputSelectDeactivatedAssetChange}
                      value={
                        (this.state.selectedDeactivatedAsset && this.state.selectedDeactivatedAsset.description) || ''
                      }
                      options={this.state.deactivatedAssets.map(deactivatedasset => {
                        return {
                          label: `${deactivatedasset.code} - ${deactivatedasset.description} ${
                            deactivatedasset.serialNumber ? `- Matricola: ${deactivatedasset.serialNumber}` : ''
                          }`,
                          value: deactivatedasset.id,
                        }
                      })}
                    />
                  </Column>
                </Row>
              )}
              {assetSubstitutionData.assetData &&
                (substituteWith !== 'substitution-with-deactivated' || this.state.selectedDeactivatedAsset) && (
                  <Row >
                    <Column md={9}>
                      <AssetForm
                        type={substituteWith}
                        groups={groups}
                        costCenters={costCenters}
                        plants={plants}
                        assetData={assetSubstitutionData.assetData}
                        sites={sites}
                        modules={modules}
                        documents={documents}
                        updateAssetData={this.updateAssetData}
                        getInputRule={this.getInputRule}
                      />
                    </Column>
                  </Row>
                )}
            </div>
            {/* End Content Section*/}
          </form>
        )}
      </div>
    )
  }

  private async fetchAssetDetail(id: string) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchError: false })
      try {
        const assetDetail: AssetDetail = await fetchDetailAsset(id)
        this.setState({ assetDetail })
        this.fetchDeactivationAvailabilty(id)
        this.fetchSitesGroupsModules()
      } catch (e) {
        console.log(e) //tslint:disable-line
        this.setState({ fetchError: true })
      } finally {
        this.setState({ isFetching: false })
      }
    }
  }

  private async fetchDeactivatedAssets() {
    if (this.state.assetDetail) {
      try {
        const deactivatedAssets: DeactivatedAsset[] = await fetchDeactivatedAssetListForPlant(this.state.assetDetail.plant.code)
        this.setState({ deactivatedAssets })
      } catch (e) {
        console.log(e) //tslint:disable-line
      }
    }
  }

  private async fetchDeactivationAvailabilty(assetId: string) {
    if (!this.state.isFetchingDeactivationAvailabilty) {
      this.setState({ isFetchingDeactivationAvailabilty: true })
      try {
        const deactivationAvailability: AssetValidateActionAvailabilty = await checkDeactivationAvailability(assetId)
        this.setState({ deactivationAvailability, isFetchingDeactivationAvailabilty: false })
      } catch (e) {
        this.setState({ fetchError: true })
        console.log('error', e) //tslint:disable-line
      }
    }
  }

  private handleInputChange(
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    state?: { value: string; name: string }
  ) {
    const { name, value } = event ? event.target : state!
    const { assetSubstitutionData } = this.state
    if (assetSubstitutionData) {
      switch (name) {
        case 'replacedDate':
          assetSubstitutionData[name] = moment(value).format('YYYY-MM-DD')
          break
        default:
          assetSubstitutionData[name] = value
      }
      this.setState({ assetSubstitutionData })
    }
  }

  private handleChangeSubstitutionOption(
    event?: React.ChangeEvent<HTMLInputElement>,
    state?: { value: string }
    ) {
    const { value } = event && event.target || state!
    this.setState(
      {
        selectedDeactivatedAsset: undefined,
        substituteWith: value as 'substitution-new-asset' | 'substitution-with-deactivated',
      },
      () => {
        if (this.state.substituteWith === 'substitution-with-deactivated') {
          this.fetchDeactivatedAssets()
        } else if (this.state.assetSubstitutionData.assetData) {
          this.setState({
            assetSubstitutionData: {
              ...this.state.assetSubstitutionData,
              assetData: {
                ...this.state.assetSubstitutionData.assetData,
                assetCode: '',
              },
            },
          })
        }
      }
    )
  }

  private async fetchSitesGroupsModules() {
    this.setState({
      isFetching: true,
    })
    const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
    const plantsArray: Array<{ code: string; name: string }> = sitesData.sites.reduce(
      (acc: Array<{ code: string; name: string }>, sites) => {
        acc = acc.concat(
          sites.plants.reduce((plants: Array<{ code: string; name: string }>, plant) => {
            plants.push({
              code: plant.code,
              name: plant.name,
            })
            return plants
          }, [])
        )
        return acc
      },
      []
    )
    const costCenters: Array<{ plant: string; costCenters: CostCenter[] }> = sitesData.sites.reduce(
      (acc: Array<{ plant: string; costCenters: CostCenter[] }>, site) => {
        return acc.concat(
          site.plants.reduce((acc2: Array<{ plant: string; costCenters: CostCenter[] }>, plant) => {
            acc2.push({
              plant: plant.code,
              costCenters: plant.costCenters,
            })
            return acc2
          }, [])
        )
      },
      []
    )
    this.setState({
      sites: sitesData.sites,
      groups: sitesData.groups,
      modules: sitesData.modules,
      plants: plantsArray,
      costCenters,
      assetSubstitutionData: {
        ...this.state.assetSubstitutionData,
        assetData: {
          assetCode: '',
          activationDate: moment().format('YYYY-MM-DD'),
          positionCode: (this.state.assetDetail && this.state.assetDetail.positionCode) || '', // Codice Impianto
          assetDescription: '', // Descrizione assrt
          assetNotes: '', // Note
          assetBrand: '', // Marca
          assetModel: '', // Model
          assetSerialNumber: '', // Matricola
          ownership: '',
          groups: [], // gruppo cespiti
          modules:
            (this.state.assetDetail &&
              this.state.assetDetail.modules.reduce((acc: string[], module) => {
                acc.push(module.id)
                return acc
              }, [])) ||
            [], // moduli
          documents: [],
          plantCode: (this.state.assetDetail && this.state.assetDetail.plant.code) || '',
          siteCode: (this.state.assetDetail && this.state.assetDetail.site.code) || '',
          costCenterCode: (this.state.assetDetail && this.state.assetDetail.costCenter.code) || '',
        },
      },
      isFetching: false,
    })
  }

  private updateAssetData(assetData: AssetFormType) {
    this.setState({
      assetSubstitutionData: {
        ...this.state.assetSubstitutionData,
        assetData,
      },
    })
  }

  private handleInputSelectDeactivatedAssetChange(assetId: string | null) {
    const selectedDeactivatedAsset: DeactivatedAsset | undefined =
      this.state.deactivatedAssets.find(obj => obj.id === assetId) || undefined
    if (selectedDeactivatedAsset) {
      this.setState({
        assetSubstitutionData: {
          ...this.state.assetSubstitutionData,
          assetData: {
            ...this.state.assetSubstitutionData.assetData!,
            assetCode: selectedDeactivatedAsset.code,
            assetBrand: selectedDeactivatedAsset.brand || '',
            assetDescription: selectedDeactivatedAsset.description,
            assetModel: selectedDeactivatedAsset.model || '',
            assetSerialNumber: selectedDeactivatedAsset.serialNumber || '',
          },
        },
      })
    }
    this.setState({ selectedDeactivatedAsset })
  }

  private async onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    const documents: AssetFileUpload[] = []
    for (const file of this.state.documents) {
      const result: FetchUploadDocumentSuccess = await fetchUploadFIle(file.file!)
      const assetFile: AssetFileUpload = {
        fileId: result.id,
        name: result.name,
        isInHeadquarter: file.isInHeadquarter || false,
        description: file.description || '',
        type: file.type || '',
      }
      documents.push(assetFile)
    }
    const data = JSON.parse(JSON.stringify(this.state.assetSubstitutionData))
    Object.keys(data).forEach(k => {
      if (!data[k] && typeof data[k] === 'string') {
        data[k] = null
      }
    })

    data.activatedDate = data.assetData.activationDate
    delete data.assetData.activationDate
    if (this.state.selectedDeactivatedAsset) {
      data.assetId = this.state.selectedDeactivatedAsset.id
    }
    delete data.assetData.assetCode
    delete data.assetData.positionCode
    delete data.assetData.costCenterCode
    delete data.assetData.plantCode
    delete data.assetData.siteCode
    Object.keys(data.assetData).forEach(k => {
      if (!data.assetData[k] && typeof data.assetData[k] === 'string') {
        data.assetData[k] = null
      }
    })
    data.assetData.documents = documents
    try {
      await replaceAsset(data, this.state.assetDetail!.id)
      this.props.history.push('/asset-manager/list-to-validate')
    } catch (e) {
      console.log(e) //tslint:disable-line
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  private getInputRule(name: string, action: string): any {
    if (name === 'activationDate' && action === 'min' && this.state.assetSubstitutionData) {
      return this.state.assetSubstitutionData.replacedDate
    }
    return getAssetFormRules(name, action, this.state.substituteWith)
  }
}

export default withRouter<any, any>(withTranslation()(SubstituteAssetPageView))
