import * as React from 'react'
import { Bar, BarChart, ResponsiveContainer, YAxis } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface BarData {
  name: string
  bar1_1: number | boolean
  bar1_2: number | boolean
  bar2_1: number | boolean
  bar2_2: number | boolean
  bar3_1: number | boolean
  bar3_2: number | boolean
  bar3_3: number | boolean
  bar3_4: number | boolean
  bar3_x: number | undefined | false
}

interface BarDataColor {
  [k: string]: string
}

interface OwnProps {
  data: BarData[]
  colors: BarDataColor
}

type Props = OwnProps & WithTranslation

class BarStack3View extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={150}
          height={300}
          data={this.props.data}
          margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
          barCategoryGap={0}
        >
          <YAxis label={undefined} hide={true} domain={[0, 100]} />
          {Object.keys(this.props.data[0])
            .filter(e => e !== 'name')
            .map((key, index) => {
              if (key !== 'bar3_x' || this.props.data[0].bar3_x) {
                return (
                  <Bar
                    key={'bar-' + index}
                    dataKey={key}
                    stackId={key.substr(0, 4)}
                    fill={this.props.colors[key]}
                    isAnimationActive={false}
                  />
                )
              }
              return false
            })}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default withTranslation()(BarStack3View)
