import { AnyAction, Dispatch } from 'redux'
import routes from '../../../inplant-config/config/routes'
import { API } from '../../../inplant-core-fe/redux/actions'
import { hasErrored, isFetching, setMobileFlag, success } from '../../../inplant-core-fe/redux/actions/auth'
import { User } from '../../../inplant-core-fe/types'

export const loginUser = (username: string, password: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching())
    try {
      await API().request('/auth/jwt', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      }, false, (response:any) => {
        if(response.status === 401) {
          let headers = {}
          response.headers.forEach((value: any, key:string, parent: any) => {
            headers = Object.assign({}, headers, {
              [key]: value
            })
          })
          if (Object.entries(headers).find(([key, value]) => key === 'password-expired')?.[1] && routes([]).find(r => r.children?.find(c => c.path === '/user/change-password'))) {
              window.location.href = '/user/update-password'
              return true
            }
          }
        return false
      })
      const userParams = await API().request('/user')
      const user = new User(userParams)
      dispatch(success(user))
    } catch (error: any) {
      dispatch(hasErrored(error))
    }
  }
}

export const loginUserByJwt = (jwt: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      localStorage.setItem('jwt', jwt)
      const userParams = await API().request('/user')
      const user = new User(userParams)
      dispatch(success(user))
      dispatch(setMobileFlag())
    } catch (error) {
      throw error
    }
  }
}

export { logoutUser } from '../../../inplant-core-fe/redux/actions/auth'
