import { faStopCircle } from '@fortawesome/free-solid-svg-icons/faStopCircle'
import PlantHaltList from './components/views/PlantHaltList/PlantHaltListPageView'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes'

const routes: RouteProps = {
  path: '/plant-halts/unchecked',
  i18nkey: 'plantHalts.navigation.plantHalts',
  icon: faStopCircle,
  visible: true,
  children: [
    {
      path: '/plant-halts/unchecked',
      i18nkey: 'plantHalts.navigation.plantHaltsToCheck',
      exact: true,
      component: PlantHaltList,
      visible: true,
      counter: {
        storeKey: `plantHalts.plantHalts.sidebarCounters`,
        pageSlug: "unchecked"
      }
    },
    {
      path: '/plant-halts',
      i18nkey: 'plantHalts.navigation.plantHaltsList',
      exact: true,
      component: PlantHaltList,
      visible: true,
    },
  ],
}

export default routes