// * -------------------------------- NPM --------------------------------------
import React from "react"
import { IconComponentServiceProps } from "../../../../services/icon"

// * -------------------------------- MODULE --------------------------------------
import IconComponent from "../../Icon/Icon"

// used to clear the input or for the password eye
interface AppendIconProps {
  handleVisibilityPassword?: (setVisible: boolean) => void
  customAppendIcon?: IconComponentServiceProps
  value?: string
  showPassword?: boolean
  type?: string
  dataTestId?:string
}
const AppendIcon = (props: AppendIconProps) => {
  const { type,  handleVisibilityPassword, value, showPassword, customAppendIcon } = props
  const base = "mv-form-control__icon-append"
  const className = `${base} ${!value ? `${base}--hide` : ""}`

  if (type === "password") {
    return (
      <span onClick={() => handleVisibilityPassword?.(!showPassword)} className={className}>
        <IconComponent icon={!showPassword ? "eye" : "eye-slash"} />
      </span>
    )
  }

  if (customAppendIcon) {
    const { onClick, ...otherProps } = customAppendIcon
    return (
      <span data-testid={props.dataTestId} onClick={customAppendIcon.onClick} className={className}>
        <IconComponent {...otherProps} />
      </span>
    )
  }

  return null
}

export default AppendIcon
