// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import IconComponent from '../MVIcon/Icon'

// * -------------------------------- MODULE -----------------------------------

interface Props {
  id?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  onChange: (value: string | null) => void
  throttle?: number
  savedSearchString?: string
  currentAppliedFilter?: string
  lastAppliedFilter?: string
  clearable?: boolean
}

interface OwnState {
  value: string | null
}

class SearchInput extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value:
        this.props.currentAppliedFilter === this.props.lastAppliedFilter &&
        props.savedSearchString
          ? props.savedSearchString
          : null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

 /* public componentDidMount() {
    // this.props.onChange('')
    if (
      this.props.currentAppliedFilter === this.props.lastAppliedFilter &&
      this.state.value
    ) {
      this.props.onChange(this.state.value)
    }
  }
*/
  public componentWillUnmount() {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
  }

  public render() {
    const props = this.props
    const { value } = this.state

    return (
      <div className="form-group">
        {props.label && <label htmlFor={props.id}>{props.label}</label>}
        <div className="input-group">
          <div className="input-group-prepend">
            <IconComponent
                icon={"search"}
                size="sm"
            />
          </div>
          <input
            id={props.id}
            className="form-control"
            type="text"
            value={value || ''}
            disabled={props.disabled}
            placeholder={props.placeholder}
            onChange={this.handleChange}
          />
          {value && props.clearable && (
              <div className="input-group-append">
                <button className="btn btn-link">
                  <IconComponent
                      icon={"times-circle"}
                      size="sm"
                      onClick={this.handleClear}
                  />
                </button>
              </div>
          )}
        </div>
      </div>
    )
  }

  private debounce: any

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    const value = event.currentTarget.value || null
    this.setState({ value })

    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(
      () => this.props.onChange(value),
      this.props.throttle || 200
    )
  }

  private handleClear() {
    const value = null
    this.setState({ value })
    this.props.onChange(value)
  }
}

export default SearchInput
