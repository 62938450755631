// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'

// * -------------------------------- MODULE -----------------------------------
import Filters, { Components } from '../../../Filters/Filters'
import Flex, { AlignItems, Direction, Fit } from '../../../Flex/Flex'
import FlexItem from '../../../Flex/FlexItem'
import Modal from '../../../Modal/Modal'
import { DATE_REQUEST_FORMAT, mvDate } from '../../../../../functions/helpers/dateHelper'
import { FilterTable, ListFilters } from '../types/table'
import { WithTranslation } from '../../../../../types/base'
import { logger } from '../../../../../functions/logs'

interface Props extends WithTranslation {
  filters: FilterTable[] | undefined
  advancedFilters?: React.ReactNode
  listFilters: ListFilters
  changeFilter: (filterId: string, value: string | string[] | null | undefined) => void
}

export const initFiltersInState = (
  storeFilters: ListFilters,
  filters: FilterTable[] | undefined
): ListFilters | undefined => {
  let listFilters: ListFilters | undefined = storeFilters
  if (!listFilters) {
    // is undefined only the first time and will be set the initialValues of the filters given as props
    listFilters = filters?.reduce((acc, curr) => {
      let initialValue
      switch (curr.kind) {
        case 'date':
          initialValue =
            curr.initialValue && mvDate.format(mvDate.getDateFromString(curr.initialValue), DATE_REQUEST_FORMAT)
          break
        case 'select':
          initialValue = !curr.options.defaultOption.disable && curr.options.defaultOption.value
          break
        case 'multiSelect':
          initialValue = !curr.options.items.map(i => (i.selected && i) || null).filter(i => i)
          break
        case 'search':
        case 'tree':
          break
      }
      if (initialValue) {
        curr?.onChangeValue?.(initialValue)
        return { ...acc, [curr.filterId]: initialValue }
      }
      return acc
    }, {})
  }

  Object.entries(listFilters || {}).forEach(([keyFilterStore, valueFilterStore]) => {
    const filterProp = filters?.find(f => f.filterId === keyFilterStore)
    filterProp?.onChangeValue?.(valueFilterStore)
  })

  return listFilters
}

const TableFilters = (props: Props) => {
  const { filters, listFilters, changeFilter, advancedFilters } = props
  const [showModal, setShowModal] = useState(false)

  const { t, base } = props.translation

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  
  const filterComponent: Array<Components | null> =
    (filters &&
      filters.map(filter => {
        if (!filter) {
          return null
        }
        switch (filter.kind) {
          case 'search':
            return {
              ...filter,
              id: filter.filterId,
              kind: 'input',
              initialValue: listFilters[filter.filterId] as string,
              throttle: 500,
              clearable: true,
              hideInfoFeedback: true,
              onChange: value => {
                filter?.onChangeValue?.(value)
                changeFilter(filter.filterId, value)
              },
            }
          case 'select':
            const items = [...filter.options.items]
            return {
              type: 'singleSelect',
              kind: 'select',
              overrideValue: true,
              hideInfoFeedback: true,
              id: filter.filterId,
              onChange: (value: string) => {
                if (listFilters[filter.filterId] !== value) {
                  filter?.onChangeValue?.(value)
                  changeFilter(filter.filterId, value)
                }
              },
              label: filter.label,
              options: {
                items,
                defaultOption:
                  (listFilters[filter.filterId] && {
                    // label: listFilters[filter.filterId],
                    value: listFilters[filter.filterId] as string,
                    disable: false,
                  }) ||
                  filter.options.defaultOption,
              },
            }
          case 'multiSelect':
            const multiSelectFilter = filter
            const multiItems = multiSelectFilter.options.items.map(o => ({
              ...o,
              selected: (listFilters[filter.filterId] && listFilters[filter.filterId]?.includes(o.value)) || false,
            }))
            return {
              type: 'multiSelect',
              kind: 'select',
              hideInfoFeedback: true,
              id: filter.filterId,
              onChange: values => {
                const valuesSelected = values.filter(v => v.selected).map(m => m.value)
                filter?.onChangeValue?.(valuesSelected)
                changeFilter(filter.filterId, valuesSelected)
              },
              label: multiSelectFilter.label,
              options: {
                items: multiItems,
              },
            }
          case 'date':
            return {
              ...filter,
              id: filter.filterId,
              initialValue: listFilters[filter.filterId] as string,
              kind: 'date',
              type: 'singleDate',
              hideInfoFeedback: true,
              clearable: true,
              onChange: value => {
                const dateValue = (value && mvDate.format(value, DATE_REQUEST_FORMAT)) || ''
                filter?.onChangeValue?.(dateValue)
                changeFilter(filter.filterId, dateValue)
              },
            }
          case 'tree':
            return {
              ...filter,
              kind: 'tree',
              id: filter.filterId,
              hideInfoFeedback: true,
              title: filter.label,
              initialSelected: Array.isArray(listFilters[filter.filterId])
                ? (listFilters[filter.filterId] as string[])
                : [],
              selection: 'MultiSelect',
              onChange: values => {
                const valuesIds = values.map(v => v.id)
                filter?.onChangeValue?.(valuesIds)
                changeFilter(filter.filterId, valuesIds)
              },
            }
          default:
            logger('Table.tsx', 'renderFilters()', 'no matched filter type')
            return null
        }
      })) ||
    []

  if (filterComponent.length > 0 || advancedFilters) {
    return (
      <>
        {showModal && (
          <Modal onClose={handleCloseModal} title={t(`${base}.advancedFilters.title`)} visible={true}>
            {advancedFilters}
          </Modal>
        )}
        <FlexItem className={'mv-table-filters'}>
          <Flex direction={Direction.column} alignItems={AlignItems.start} fit={Fit.oneLine}>
            <Filters
              fields={filterComponent}
              advancedFilters={
                (advancedFilters && {
                  label: t(`${base}.advancedFilters.title`),
                  onClick: handleOpenModal,
                }) ||
                undefined
              }
            />
          </Flex>
        </FlexItem>
      </>
    )
  }

  return null
}

export default TableFilters
