import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AssetFileCreation } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Button, Input, Checkbox, Select } from '@mv-submodules/inplant-components-fe'
import { assetDocumentTypes } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import { SelectOptions } from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'
import FileInput from '@mv-submodules/inplant-components-fe/ui/components/Input/FileInput'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

interface StateProps {
  file: AssetFileCreation
  index: number
  removeDocument: (index: number) => void
  updateFile: (file: AssetFileCreation, index: number) => void
}

type Props = StateProps & WithTranslation

class FileUpload extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.updateFile = this.updateFile.bind(this)
    this.updateFileDescription = this.updateFileDescription.bind(this)
    this.updateType = this.updateType.bind(this)
    this.updateHeadQuarter = this.updateHeadQuarter.bind(this)
  }

  public render() {
    const { t, file, index } = this.props
    return (
      <Row verticalAlignment={'center'} >
        <Column md={3} formGroup={true}>
          <div className={`${file.file ? 'items-center' : ''}`}>
            <div className='custom-file items-center'>
              {file.file ? (
                <label className='mb-0'>
                  <strong>{file.file.name}</strong>
                </label>
              ) : (
                <FileInput
                  lang='it'
                  required={true}
                  onChange={(files) => this.updateFile(files, this.props.file)}
                  label={t('assetManager.forms.browse')}
                />
              )}
            </div>
          </div>
        </Column>
        <Column md={3} formGroup={true}>

          <Input
            label={t('assetManager.forms.description')}
            type={'text'}
            id={'form-file-description'}
            onChange={value => this.updateFileDescription({ value: value as string })}
            required={true}
            value={file.description || ''}
          />

        </Column>
        <Column md={3} >
          <div className={"mt-2"}>
            <Select
              value={file.type}
              options={this.getSelectOptions(assetDocumentTypes)}
              onChange={value => this.updateType(value as string)}
            />
          </div>

        </Column>
        <Column md={2} formGroup={true}>
          <div className='items-center'>
            <div className='form-check'>
              <Checkbox
                onChange={event => this.updateHeadQuarter(event as HTMLInputElement)}
                checked={file.isInHeadquarter || false}
                id={`isInHeadquarter_document`}
                label={t('assetManager.forms.inHeadquarter')}
              />
            </div>
          </div>
        </Column>
        <Column md={1} formGroup={true}>

          <Button variant='danger-alternate' icon={"trash"} onClick={() => this.props.removeDocument(index)} />

        </Column>
      </Row>
    )
  }

  private updateFile(files: FileList, file: AssetFileCreation) {
    file = {
      ...file,
      file: files[0],
    }
    console.log(files) //tslint:disable-line
    this.props.updateFile(file, this.props.index)
  }

  private updateFileDescription(state: { value: string }) {
    const description = state.value
    let { file } = this.props
    file = {
      ...file,
      description,
    }
    this.props.updateFile(file, this.props.index)
  }

  private updateType(value: string) {
    const type = value
    let { file } = this.props
    file = {
      ...file,
      type,
    }
    this.props.updateFile(file, this.props.index)
  }

  private updateHeadQuarter(event: HTMLInputElement) {
    const isInHeadquarter = (this.props.file && this.props.file.isInHeadquarter) || false
    let { file } = this.props
    file = {
      ...file,
      isInHeadquarter: !isInHeadquarter,
    }
    this.props.updateFile(file, this.props.index)
  }

  private getSelectOptions(sources: string[], defaultOption?: string): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }
    sources.map(source => {
      options.items.push({
        label: this.props.t(`assetManager.forms.${source}`),
        value: source,
      })
    })
    return options
  }
}

export default withTranslation()(FileUpload)
