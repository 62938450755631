import { combineReducers } from 'redux'

import { pageConfig } from './pageConfig'
import { pageList } from './pageList'
import { pageDetail } from './pageDetail'

const chronoReducers = combineReducers({
  pageConfig,
  pageList,
  pageDetail,
})

export default chronoReducers
export type ChronoReducers = ReturnType<typeof chronoReducers>