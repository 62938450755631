// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { Tooltip } from 'react-tippy'

export interface Props {
  content: string
  target: React.ReactNode
  position?: 'top' | 'bottom' | 'left' | 'right'
  trigger?: string
}

const Popover = (props: Props) => (
  <Tooltip
    trigger={props.trigger || 'mouseenter click'}
    position={props.position}
    arrow={true}
    title={props.content}
    className="rt-td-cell"
  >
    {props.target}
  </Tooltip>
)

export default Popover
