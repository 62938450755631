// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Linkable, { LinkableProps } from '../Link/Linkable'
import PlaceholderLoader from '../Loaders/PlaceholderLoader'
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'
import { IsLoading, TextSemantics } from '../../../types/base'
import {
  backSpaceStringRegExp,
  boldStartEndRegExp,
  boldStrikeEndRegExp,
  boldStrikeStartRegExp,
  boldStrikeStringRegExp,
  boldStringRegExp,
  italicStartEndRegExp,
  italicStringRegExp,
  linkStartEndRegExp,
  linkStringRegExp,
  metaChar,
  strikeStartEndRegExp,
  strikeStringRegExp,
  underlineStartEndRegExp,
  underlineStringRegExp,
} from '../../../functions/regExp'

type Sizes = 'sm' | 'nm' | 'lg'

export interface HyperTextProps extends IsLoading {
  text: string
  title?: string
  links?: LinkableProps[]
  size?: Sizes
  semantic?: TextSemantics
  alignment?: 'start' | 'center' | 'end' // TODO move to stylebootstrap
  keepSpaces?: boolean
}

/**
 *
 * @param {boolean} keepSpaces default `true`
 *
 * How to write text:
 *   ```
 *   - |**myBoldString**|
 *   - |***myLinkString***|
 *   - |*myItalicString*|
 *   - |__myUnderlineString__|
 *   - |**~myBoldStrikeString~**|
 *   - |~myStrikeString~|
 *   - |\n| line-break
 *  ```
 * For each link-text add a link object
 *
 */
const Text = ({ text = '', keepSpaces = true, ...props }: HyperTextProps & IFlexItem) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { links } = props
  const array: React.ReactNode[] = []
  let indexLink = 0
  const arrayString: string[] = (text && typeof text === 'string' && text.split(metaChar)) || []

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  arrayString.forEach((str, index) => {
    if (linkStringRegExp.test(str)) {
      if (links && links[indexLink]) {
        array.push(<LinkableText {...links[indexLink]} key={index} text={str.replace(linkStartEndRegExp, '')} />)
        indexLink++
      }
    } else if (boldStrikeStringRegExp.test(str)) {
      array.push(
        <strong key={index}>
          <s>{str.replace(boldStrikeEndRegExp, '').replace(boldStrikeStartRegExp, '')}</s>
        </strong>,
      )
    } else if (strikeStringRegExp.test(str)) {
      array.push(<s key={index}>{str.replace(strikeStartEndRegExp, '')}</s>)
    } else if (boldStringRegExp.test(str)) {
      array.push(<strong key={index}>{str.replace(boldStartEndRegExp, '')}</strong>)
    } else if (underlineStringRegExp.test(str)) {
      array.push(<u key={index}>{str.replace(underlineStartEndRegExp, '')}</u>)
    } else if (italicStringRegExp.test(str)) {
      array.push(<i key={index}>{str.replace(italicStartEndRegExp, '')}</i>)
    } else if (backSpaceStringRegExp.test(str)) {
      array.push(str.replace(backSpaceStringRegExp, ''))
      array.push(<br key={index} />)
    } else {
      array.push(str)
    }
  })

  const renderSize = () => {
    switch (props.size) {
      case 'sm':
        return (
          <>
            <small>{array}</small>
          </>
        )
      case 'lg':
        return (
          <>
            <big>{array}</big>
          </>
        )
      default:
        return <>{array}</>
    }
  }

  function renderPossibleCase() {
    if (props.isLoading) {
      return <PlaceholderLoader />
    }
    return (
      <p
        style={{ textAlign: props.alignment }}
        title={props.title || ''}
        className={`${props.semantic || ''} ${renderFlexItem(props)} ${keepSpaces && 'keep-spaces'}`}
      >
        {renderSize()}
      </p>
    )
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return renderPossibleCase()
}

export default Text

export interface TextProps {
  text: string
  semantic?: TextSemantics
  size?: Sizes
}

const TextComponent = (props: TextProps) => {
  return <>{props.text}</>
}

// * ------------------------------------------
// * Helpers Component that pre-fill some props
// * ------------------------------------------
export const LinkableText = Linkable(TextComponent)
