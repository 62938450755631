import React from 'react'
import WithConditionHOC
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'

export type ColumnDimensionType =
  | '1/4'
  | '1/3'
  | 'half'
  | '3/4'
  | '2/3'
  | 'full'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12

interface Props {
  col: ColumnDimensionType
  row?: number
  className?: string
  isHeader?: boolean
  children: React.ReactNode
}

export const MAX_TABLE_COLUMN = 12

const TableColumnWrapper = (props: Props) => {

  const { col, row, isHeader, children, className } = props

  if (isHeader) {
    return <th className={`${className} table-head-dimentions`} rowSpan={row} colSpan={getColSpanFromColumnDimensionType(col)}>
      {children}
    </th>
  }

  return <td className={className} rowSpan={row} colSpan={getColSpanFromColumnDimensionType(col)}>
    {children}
  </td>
}

export default TableColumnWrapper

export function getColSpanFromColumnDimensionType(col: ColumnDimensionType): number {
  switch (col) {
    case '1/4':
      return MAX_TABLE_COLUMN / 4
    case '1/3':
      return MAX_TABLE_COLUMN / 3
    case 'half':
      return MAX_TABLE_COLUMN / 2
    case '3/4':
      return MAX_TABLE_COLUMN * (3 / 4)
    case '2/3':
      return MAX_TABLE_COLUMN * (2 / 3)
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      return col
    case 'full':
    default:
      return MAX_TABLE_COLUMN
  }
}

export const TableColumnWrapperWithCondition = WithConditionHOC(TableColumnWrapper)