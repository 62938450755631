import AssetList from './components/views/AssetList/AssetListPageView'
import AssetsGroupsList from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/AssetsGroupsList/AssetsGroupsListPageView'
import CreateAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CreateAsset/CreateAssetPageView'
import DetailAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/DetailAsset/DetailAssetPageView'
import AssetValidationRoute from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/AssetValidation/AssetValidationPageView'
import AssetGroupDetail from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/AssetGroupDetail/AssetGroupDetailPageView'
import UpdateAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/UpdateAsset/UpdateAssetPageView'
import DisableAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/DisableAsset/DisableAssetPageView'
import SubstituteAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/SubstituteAsset/SubstituteAssetPageView'
import AssetGroupForm from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/AssetGroupForm/AssetGroupFormPageView'
import DeactivatedAssetList from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/DeactivatedAssetList/DeactivatedAssetListPageView'
import CopyTasks from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/CopyTasksPageView'
import { Icon } from '../../inplant-components-fe/services/icon'

export interface RouteProps {
  path: string
  exact?: boolean
  i18nkey?: string
  icon?: Icon
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[],
  counter?: object
}

const routes: RouteProps = {
  path: '/asset-manager/list-active',
  i18nkey: 'assetManager.navigation.asset',
  icon: 'truck-loading',
  visible: true,
  children: [
    {
      path: '/asset-manager/list-active',
      i18nkey: 'assetManager.navigation.list-active',
      exact: true,
      component: AssetList,
      visible: true,
    },
    {
      path: '/asset-manager/list-archive',
      i18nkey: 'assetManager.navigation.list-archive',
      exact: true,
      component: AssetList,
      visible: true,
    },
    {
      path: '/asset-manager/list-deactivated-assets',
      i18nkey: 'assetManager.navigation.freezedAsset',
      exact: true,
      component: DeactivatedAssetList,
      visible: true,
    },
    {
      path: '/asset-manager/list-to-validate',
      i18nkey: 'assetManager.navigation.list-to-validate',
      exact: true,
      component: AssetList,
      visible: true,
      counter: {
        storeKey: `assetManager.assetsList.sidebarCounters`,
        pageSlug: "list-to-validate"
      }
    },
    {
      path: '/asset-manager/groups',
      i18nkey: 'assetManager.navigation.assetsGroups',
      exact: true,
      component: AssetsGroupsList,
      visible: true,
    },
    {
      path: '/asset-manager/create-asset',
      component: CreateAsset,
      visible: false,
    },
    {
      path: '/asset-manager/detail-asset/:assetId',
      component: DetailAsset,
      visible: false,
    },
    {
      path: '/asset-manager/asset-validation/:assetId',
      component: AssetValidationRoute,
      visible: false,
    },
    {
      path: '/asset-manager/update-asset/:assetId',
      component: UpdateAsset,
      visible: false,
    },
    {
      path: '/asset-manager/disable-asset/:assetId',
      component: DisableAsset,
      visible: false,
    },
    {
      path: '/asset-manager/substitute-asset/:assetId',
      component: SubstituteAsset,
      visible: false,
    },
    {
      path: '/asset-manager/groups/create',
      component: AssetGroupForm,
      visible: false,
    },
    {
      path: '/asset-manager/groups/update/:groupId',
      component: AssetGroupForm,
      visible: false
    },
    {
      path: '/asset-manager/groups/detail/:groupId',
      component: AssetGroupDetail,
      visible: false,
    },
    {
      path: '/asset-manager/copy-tasks/:assetId',
      component: CopyTasks,
      visible: false
    }
  ],
}

export default routes
