import * as React from 'react'
import {
  MaintenanceScheduledJob,
  MaintenanceJobType,
  MaintenanceStatus,
  ModuleConfig,
} from '../types/maintenance'
import i18n from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { MaintenanceJobPeriodicity } from '../types/jobs'
import {BadgeType} from "@mv-submodules/inplant-components-fe/ui/components/Badge/Badge";

export const presentTarget = (
  scheduledJob: MaintenanceScheduledJob,
  callback: Function,
  showTargetNameOnNullTarget = false
) => {
  const path: String[] = []
  const targetName = scheduledJob.targetName ? scheduledJob.targetName : null

  if (scheduledJob.macroarea) {
    path.push(scheduledJob.macroarea.name)
  }

  if (scheduledJob.component) {
    path.push(scheduledJob.component.name)
  }
  const pathEl = path.length > 0 ? <small>({path.join(' > ')})</small> : null

  const clickHandler = () => {
    if (scheduledJob.target) {
      callback(scheduledJob.target.id)
    }
  }

  return (
    <React.Fragment>
      {(scheduledJob.target) ? (<a href="javascript:void(0)"
                                   onClick={clickHandler}>{scheduledJob.target.name}</a>) : (showTargetNameOnNullTarget) ? targetName : null} {pathEl}
    </React.Fragment>
  )
}

export const haveTargetHours = (scheduledJob: MaintenanceScheduledJob) => {
  const target = scheduledJob.target
  return target && target.usageHours && target.usageHoursDate
}

export const presentTargetHours = (scheduledJob: MaintenanceScheduledJob) => {
  const target = scheduledJob.target
  if (target && target.usageHours && target.usageHoursDate) {
    return (
      <React.Fragment>
        {target.usageHours} <small>updated to {target.usageHoursDate.format('YYYY-MM-DD HH:mm')}</small>
      </React.Fragment>
    )
  }
  return null
}

export const ReactTablePreviousButton = (props: any) => (
  <button type="button" {...props} className="-btn -btn-custom">
    <FontAwesomeIcon icon={faChevronLeft} />
  </button>
)

export const ReactTableNextButton = (props: any) => (
  <button type="button" {...props} className="-btn -btn-custom">
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
)

export const Loader = () => (
  <div key={Date.now()} className="col-lg text-center">
    <FontAwesomeIcon icon={faCircleNotch} size="2x" spin={true} />
  </div>
)

export const Error = () => (
  <div key={Date.now()} className="col-lg text-center">
    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" spin={false} color="red" />
  </div>
)

export const capitalizeFirstLetter = (value: string) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}

export const capitalizeName = (obj: any) => {
  obj.name = capitalizeFirstLetter(obj.name)
  return obj
}

export const getScheduleJobUIStatus = (status: MaintenanceStatus) => {
  if (status === MaintenanceStatus.DONE) {
    return 'done'
  } else if (status === MaintenanceStatus.REJECTED) {
    return 'rejected'
  } else if (status === MaintenanceStatus.OVERDUE) {
    return 'overdue'
  } else if (status === MaintenanceStatus.POSTPONED) {
    return 'postponed'
  } else if (status === MaintenanceStatus.PLANNED) {
    return 'planned'
  } else if (status === MaintenanceStatus.UNHANDLED) {
    return 'unhandled'
  }

  return 'todo'
}

export const getBadgeType = (status: string): BadgeType => {
  switch (status) {
    case "ToDo":
    case "Postponed":
      return 'warning'
    case "Overdue":
      return 'negative'
    case "Done":
      return 'positive'
    case "ToValidate":
      return 'informative'
    default:
      return 'neutral'
  }
}

export const presentJobType = (scheduledJob: MaintenanceScheduledJob, showMaintenanceInfos = true) => {
  const { jobType, maintenance } = scheduledJob
  const label = i18n.t(`chronoframe.jobType.${scheduledJob.jobType}`)

  if (jobType === MaintenanceJobType.MAINTENANCE) {
    const chunks:string[] = []
    if (maintenance && maintenance.periodicity) {
      chunks.push(i18n.t('chronoframe.periodicity.' + MaintenanceJobPeriodicity[maintenance.periodicity]))
    }
    if (maintenance && maintenance.totalHours) {
      chunks.push(`h ${maintenance.totalHours}`)
    }

    const maintenanceInfos = showMaintenanceInfos && chunks.length ? <small>{chunks.join(' / ')}</small> : null

    return (
      <React.Fragment>
        <FontAwesomeIcon icon={faSyncAlt} rotation={90} /> {label} {maintenanceInfos}
      </React.Fragment>
    )
  }
  return label
}

export const getDateLocale = (): string => {
  return i18n.language ? i18n.language : 'en'
}

export const getDateFormat = (): string => {
  switch (getDateLocale()) {
    case 'en':
      return 'YYYY/MM/DD'

    case 'it':
      return 'DD/MM/YYYY'

    default:
      return 'YYYY/MM/DD'
  }
}

export const trunc = (value: string, limit: number = 50) => {
  return value.length > limit ? value.substr(0, limit - 1) + ' ...' : value
}

export const getConfigParam = (config: ModuleConfig, key: string, defaultValue: any) => {
  return config.maintenance && config.maintenance[key] ? config.maintenance[key] : defaultValue
}

export const showScheduledJobCol = (config: ModuleConfig, colName: string): boolean => {
  const hiddenCols =
    config.maintenance && config.maintenance.scheduledJobsHiddenCols ? config.maintenance.scheduledJobsHiddenCols : []
  return !hiddenCols.includes(colName)
}

export const presentStatusBadge = (scheduledJob: MaintenanceScheduledJob) => {
  return (
    <span className="badge badge--status" style={{ backgroundColor: scheduledJob.color, color: '#FFF' }}>
      {i18n.t(`chronoframe.status.${getScheduleJobUIStatus(scheduledJob.status)}`)}
    </span>
  )
}
