// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from "../MVIcon/Icon";
import { normalizeComponent } from './utils'
import { thActionClassName } from './TableWithActions'

interface ThComponentProps {
  className: string
  style: { [key: string]: string }
  toggleSort: (e: React.MouseEvent) => void
  children: React.ReactNode
}

const ThComponent = (props: ThComponentProps) => {
  const isSortable = props.className.match('-cursor-pointer')
  const isSortAsc = props.className.match('-sort-asc')
  const isSortDesc = props.className.match('-sort-desc')
  const className = props.className.replace(/ -sort-\w+/, '')
  const [Header, Resizer]: any = props.children
  const thStyle = props.className.match(thActionClassName) ? { ...props.style, overflow: 'visible' } : props.style
  const rtStyle = props.className.match(thActionClassName) ? { overflow: 'visible' } : undefined

  return (
    <div className={`rt-th ${className}`} style={thStyle} onClick={props.toggleSort}>
      <div className="rt-resizable-header-content d-flex align-items-center" style={rtStyle}>
        {isSortable && (
          <IconComponent
            icon={isSortAsc ? "sort-up" : isSortDesc ? "sort-down" : "sort"}
            className={`mr-1 ${isSortAsc || isSortDesc ? 'text-secondary' : 'text-muted'}`}
            size="xs"
          />
        )}
        {normalizeComponent(Header)}
        {normalizeComponent(Resizer)}
      </div>
    </div>
  )
}

export default ThComponent
