export interface PlantAnalysisGeneralsSettings {
  [k: string]: PlantAnalysisGeneralSettings
}

export interface PlantAnalysisGeneralSettings {
  settingsFields: { [k: string]: string }
  nodes: any
}

export const defaultPlantAnalysisGeneralSettings: PlantAnalysisGeneralSettings = {
  settingsFields: {
    //   soglia_deviazione: '55',
    sforamento_assorbimento_vent: '0',
    sforamento_assorbimento_nastri: '0',
    //   soglia_under_speed: '35',
    //   soglia_over_speed: '95',
    // limite_amperaggio: '50',
    // velocita_riferimento: '50',
  },
  nodes: {},
}

export interface PlantAnalysisDetailsConfiguration {
  [k: string] : {
    binaryMapping: BinaryMappingConfiguration
  }
}

export interface BinaryMappingConfiguration {
  avanti: number,
  indietro: number,
  inverter: number,
  automatico: number,
  manuale: number,
  locale: number,
  sezionato: number
}
