// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from '../Buttons/Button'
import FilterInput from './FilterInput'
import Flex, { AlignItems, Fit, JustifyContent } from '../Flex/Flex'
import SearchFilter, { ISearchFilter } from './SearchFilter'
import TreeFilter, { TreeComponents, TreeFilterProps } from './TreeFilter'
import {
  IDateComponent,
  IMultiSelectComponent,
  ISelectComponent,
  ISingleDateComponent,
  ISingleSelectComponent,
} from '../Input/types'
import { IFlexItem } from '../Flex/FlexItem'
import { TreeMultiSelectProps } from '../Tree/Tree'
import { getUniqueId } from '../../../functions/helpers/stringHelper'
import { useComponentsTranslation } from '../../../services/translation'

type InputComponents = (ISearchFilter | ISelectComponent | IDateComponent ) & IFlexItem

export type Components = InputComponents | TreeComponents

export interface FiltersProps {
  fields: Array<Components | null>
  advancedFilters?: {
    label: string
    onClick: () => void
  }
}

const Filters: React.FC<FiltersProps> = props => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = useComponentsTranslation()
  const base = 'components.filters'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { fields, advancedFilters } = props
  const [ids] = useState(fields.map(f => getUniqueId(f?.id)))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const renderSearchInput = (component: ISearchFilter & IFlexItem) => {
    return (
      <SearchFilter
        {...component}
        kind={'input'}
        initialValue={component.initialValue}
        id={component.id}
        label={component.label}
        throttle={component.throttle}
        clearable={component.clearable}
        onChange={component.onChange}
        key={component.id}
        disabled={component.disabled}
        grow={component.grow || 0}
        basis={component.basis || 25}
        shrink={component.shrink || 1}
      />
    )
  }

  const renderGenericInputComponent = (component: InputComponents) => {
    return <FilterInput {...component} key={component.id} />
  }

  const renderFilter = (component: Components) => {
    if (component.kind === 'tree') {
      return <TreeFilter key={component.id} {...component} translation={{ t, base }} />
    }

    if (component.kind === 'input') {
      return renderSearchInput(component)
    }

    return renderGenericInputComponent(component)
  }
  const fieldRendered = fields.map((field, index) =>
    field
      ? renderFilter({
          ...field,
          id: ids[index],
          grow: field.grow || 0,
          basis: field.basis || 22,
          shrink: field.shrink || 1,
        })
      : false
  )

  return (
    <Flex
      alignItems={AlignItems.end}
      fit={Fit.multiLine}
      justifyContent={JustifyContent.start}
      className={'mv-filters'}
      style={{ width: '100%' }}
      spaceSize={'lg'}
    >
      <>
        {fieldRendered}
        {advancedFilters && (
          <Flex alignItems={AlignItems.center}>
            <Button semantic={'secondary'} label={advancedFilters.label} onClick={() => advancedFilters.onClick()} />
          </Flex>
        )}
      </>
    </Flex>
  )
}

Filters.defaultProps = {}

// * Minimals interfaces of input exposed to create filters when values and changes are managed inside other components (for example filters in table)
export type SearchType = Pick<ISearchFilter, 'label' | 'placeholder' | 'readonly'> & {
  kind: 'search'
}

export type SelectType = Pick<ISingleSelectComponent, 'label' | 'options'> & {
  kind: 'select'
}

export type MultiSelectType = Pick<IMultiSelectComponent, 'label' | 'options'> & {
  kind: 'multiSelect'
}

export type DateType = Pick<ISingleDateComponent, 'label' | 'max' | 'min' | 'initialValue'> & {
  kind: 'date'
}

export type MultiTreeType = Pick<TreeFilterProps, 'label' | 'buttonLabel' | 'buttonIcon' | 'placeholder'> &
  Pick<TreeMultiSelectProps, 'data' | 'flow'> & {
    kind: 'tree'
  }

export type FilterType = (SelectType | SearchType | DateType | MultiSelectType | MultiTreeType) & {
  filterId: string
}

export default Filters
