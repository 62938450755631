import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as moment from 'moment'
import * as React from 'react'
import { COLORS } from '../../../../constants'
import { AreaSection } from '../../../../types/measure'
import { getAreaPauses, getAreaSections, reduceWarnAreas } from '../../../../functions/shared'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface AdditionalChartLineData {
  label: string
  measure: string
  type: AdditionalChartLineDataType,
  unit?: string
}

export type AdditionalChartLineDataType = 'perc' | 'value' | 'byte'

export interface OwnProps {
  additionalChartLines?: AdditionalChartLineData[]
  data: any
  filteredData: any
  hideSpeed?: boolean
  pauses?: Array<{ start: number; end: number }>
}

export interface OwnState {
  showTooltip: boolean
  tooltipData: any
}

type Props = OwnProps & WithTranslation

class BeltGraphMain extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showTooltip: false,
      tooltipData: null,
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private static nullTooltipContent({ active }: { active: boolean }) {
    if (active) {
      return <div className="null-tooltip-content" />
    }

    return null
  }

  public render() {
    const { additionalChartLines, hideSpeed, pauses, filteredData } = this.props
    const warnPoints: AreaSection[] | undefined =
      (this.props.data as [{}]) && getAreaSections(this.props.data, 'warnArea').filter(e => e.value === 100)

    const warnAreas = reduceWarnAreas(warnPoints, 'axis_perc')
    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), 'axis_perc', COLORS.pauses)
    const hasPercAxis = !hideSpeed || (additionalChartLines && additionalChartLines.findIndex(ac => ac.type === 'perc') >= 0 )

    return (
      <React.Fragment>
        <div className="col-md-10">
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              // syncId={this.id}
              onMouseEnter={this.handleMouseEnter}
              onMouseMove={this.handleMouseMove}
              onMouseLeave={this.handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                hide={true}
                dataKey="time"
                label={{ value: '', position: 'insideBottomRight', offset: 0 }}
                height={30}
                axisLine={false}
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                // domain={['auto', 'auto']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'number'}
              />

              <YAxis label={hasPercAxis ? '%' : ''} domain={[0, 'auto']} axisLine={false} yAxisId={'axis_perc'} orientation={'left'} tickCount={hasPercAxis ? undefined : 0} />
              <YAxis label={'A'} domain={[0, 25]} axisLine={false} yAxisId={'axis_current'} orientation={'right'} />

              <CartesianGrid stroke="#f5f5f5" />

              {warnAreas}
              {warnPauses}

              <Tooltip content={BeltGraphMain.nullTooltipContent} />
              <Line
                isAnimationActive={false}
                dataKey="assorbimento"
                stroke="#A19FF9"
                dot={false}
                strokeWidth={2}
                type="step"
                yAxisId={'axis_current'}
                strokeOpacity={0.8}
              />
              {
                additionalChartLines &&
                additionalChartLines.map(ac =>
                  <Line
                    key={ac.measure}
                    isAnimationActive={false}
                    dataKey={ac.measure}
                    stroke={COLORS[ac.measure]}
                    dot={false}
                    strokeWidth={2}
                    type="step"
                    yAxisId={ac.type === 'perc' ? 'axis_perc' : 'axis_current'}
                    strokeOpacity={0.8}
                  />
                )
              }

              {
                !hideSpeed &&
                <Line
                  isAnimationActive={false}
                  dataKey="setVelocita"
                  stroke="#5AD6FD"
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  yAxisId={'axis_perc'}
                  strokeOpacity={0.8}
                />
              }
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="col-md-2">
          {this.state.showTooltip && this.state.tooltipData !== null && (
            <ul className="side-tooltip">
              <li>
                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                <br />
                <span>{moment(this.state.tooltipData.time, 'X').format('HH:mm')}</span>
              </li>
              {!hideSpeed && this.state.tooltipData.setVelocita !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.setVelocita')}</strong>
                  <br />
                  <span
                    className="label-color label-color-line"
                    style={{ backgroundColor: this.state.tooltipData.setVelocitaColor }}
                  />
                  <span>{this.state.tooltipData.setVelocita} %</span>
                </li>
              )}
              {this.state.tooltipData.assorbimento !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.assorbimento')}</strong>
                  <br />
                  <span
                    className="label-color label-color-line"
                    style={{ backgroundColor: this.state.tooltipData.assorbimentoColor }}
                  />
                  <span>{this.state.tooltipData.assorbimento} A</span>
                </li>
              )}
              {
                (this.props.pauses && this.props.pauses.length > 0) &&
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.pauses')}</strong>{' '}
                  <span
                    className="label-color label-color-line label-color-line-right"
                    style={{ backgroundColor: COLORS.pauses }}
                  />
                </li>
              }

              {
                additionalChartLines &&
                additionalChartLines.map(ac =>
                  <li key={ac.measure}>
                    <strong>{this.props.t('plantAnalysis.labels.' + ac.measure)}</strong>
                    <br />
                    <span
                      className="label-color label-color-line"
                      style={{ backgroundColor: COLORS[ac.measure] }}
                    />
                    <span>{this.state.tooltipData[ac.measure]} {ac.unit}</span>
                  </li>
                )
              }
            </ul>
          )}
        </div>
      </React.Fragment>
    )
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      let curData = this.props.data && this.props.data.find((e: any) => e.time === args.activeLabel)

      if (curData) {
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            recipe: curData.ricetta !== undefined ? curData.ricetta : '--',
            aspired: curData.aspirato !== undefined ? curData.aspirato : '--',
            assorbimento: curData.assorbimento ? curData.assorbimento.toFixed(2) : '--',
            power: curData.power ? curData.power.toFixed(2) : '--',
            position: curData.position ? curData.position.toFixed(2) : '--',
            tempMelt: curData.tempMelt ? curData.tempMelt.toFixed(2) : '--',
            torque: curData.torque ? curData.torque.toFixed(2) : '--',
            setVelocita: curData.setVelocita !== undefined ? curData.setVelocita.toFixed(2) : '--',
            sforamentoAssorbimento:
              curData.sforamentoAssorbimento !== undefined ? curData.sforamentoAssorbimento : '--',
            recipeColor: curData.ricetta ? COLORS.manAutoLocSec[curData.ricetta] : null,
            aspiredColor: curData.aspirato ? COLORS.aspi[curData.aspirato] : null,
            setVelocitaColor: COLORS.setVelocita,
            assorbimentoColor: COLORS.assorbimento,
          },
        })
      }

      curData = null
    }
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }
}

export default withTranslation()(BeltGraphMain)
