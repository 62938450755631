import ReactECharts from 'echarts-for-react'
import React from 'react'
import { MVCharts } from './types/instance'
import Event from './types/events'
import { EChartsOption } from 'echarts'
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'

interface GraphsProps {
  style?: React.CSSProperties
  option?: EChartsOption
  onEvents?: Record<Event, Function>
  showLoading?: boolean
}

// example ref https://git.hust.cc/echarts-for-react/examples/api
const Graphs = React.forwardRef((props: GraphsProps & IFlexItem, ref: React.Ref<MVCharts>) => {
  const { option, onEvents, style, ...otherProps } = props

  return (
    <ReactECharts
      style={style}
      ref={ref}
      className={`${renderFlexItem(props)}`}
      option={option}
      onEvents={onEvents}
      {...otherProps}
    />
  )
})
export default Graphs
