const it = {
  auth: {
    usernameLabel: 'Nome utente',
    usernamePlaceholder: 'Inserisci il nome utente...',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Inserisci la password...',
    submitText: 'Accedi',
    logout: 'Logout',
    passwordRecovery: 'Recupera password'
  },
  api: {
    errors: {
      error: 'Errore API',
      error_400: 'Il server non può elaborare la richiesta',
      error_401: "L'utente non ha le credenziali per accedere",
      error_403: "L'utente non ha i permessi necessari per accedere alla risorsa",
      error_404: 'La risorsa richiesta non è stata trovata',
      error_405: 'Il metodo non è supportato per la risorsa richiesta',
      error_406: 'Errore di validazione',
      error_422: 'La richiesta non è valida',
      error_500: 'Il server non è stato in grado di rispondere',
    },
  },
  plantSelectorWidget: {
    options: {
      disabled: 'Modulo non attivo per questo impianto',
      'null': 'Seleziona impianto...'
    },
    plantChangedToAllowed: "I dati per l'impianto di {{fromPlant}} non sono disponibili.\nVengono visualizzati i dati dell'impianto di {{toPlant}}"
  }
}

export default it
