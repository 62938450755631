import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries, Loader } from '../../../../../functions/shared'
import BottomLegendPrint from '../../BottomLegend/BottomLegend/BottomLegendPrint'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import FanGraphRowItemPrint from '../FanGraphRowItem/FanGraphRowItemPrint'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'
import TableRowWrapper, {
  TableRowFullWrapper, TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  last: any
  lastAfter: any
  isCollapsed: boolean
  filteredData: any[]
  startDate: string | number
  endDate: string | number
  components: NodeComponent[]
  metrics: string[]
}

interface OwnProps {
  active: string
  component: any
  configuration: PlantAnalysisGeneralSettings
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  fetchErrors: boolean
  isLoading: boolean
  measures: string[]
  toggleCollapse: (id: string) => void
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})
/*
const cleanState = {
  isLoading: false,
  fetchErrors: false,
  showTooltip: false,
  tooltipData: null,
  isCollapsed: false,
  data: [],
  dataCurrent: [],
  last: null,
  lastAfter: null,
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
  components: [],
  metrics: ['marciaFermo', 'driveBypass', 'manAutoLocSec'],
}*/

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class FanGraphRowPrint extends React.Component<Props, OwnState> {
  public render() {
    const { model, days } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isLoading
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        ['PLANT_DATA_ASP', 'PLANT_DATA_ASP_INVERTER'].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code),
    )

    return (
      <>
        <TableRowFullWrapperWithCondition condition={noData}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.isLoading}>
          <div className='alert alert-secondary w-100 col-sm-6 mx-auto'>
            {this.props.t('plantAnalysis.loading')}
            <Loader />
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={this.props.fetchErrors}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto fan-grah-row-fetch-errors'>
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>

        <TableRowFullWrapper className={'no-page-break'}>
          <TableWrapperUtilities hideHeader={true}>
            <TableRowWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-left'}>
                <BottomLegendPrint row={true} labels={['marciaFermo']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['driveBypass']} hideTitle={true} />
              </TableColumnWrapper>
              <TableColumnWrapper col={'1/3'} className={'bunker-graph-header text-right'}>
                <BottomLegendPrint row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </TableColumnWrapper>
            </TableRowWrapper>
          </TableWrapperUtilities>
        </TableRowFullWrapper>

        {componentsToRender.map(
          (c: ModelNodeData) =>
            (this.props.configuration.nodes === {} ||
              (this.props.configuration.nodes[c.code] && this.props.configuration.nodes[c.code] === true)) && (
              <FanGraphRowItemPrint
                data={getInfluxMultiSeries(this.props.data, c.code)}
                days={days || 1}
                name={c.label}
                id={c.code}
                key={c.code}
                node={c}
                currentOverrun={getComponentCurrent(c.code, this.props.dataCurrent)}
                currentLimit={this.props.configuration.settingsFields.sforamento_assorbimento_vent}
              />
            ),
        )}
      </>
    )
  }
}

const getComponentCurrent = (id: string, dataCurrent: any) => {
  let out
  if (dataCurrent && dataCurrent.length) {
    const current = dataCurrent.find((e: { name: string }) => e.name.indexOf(id) > -1)

    if (current && current.values && current.values[0]) {
      out = current.values[0][1]
    }
  }

  return out
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(FanGraphRowPrint)))
