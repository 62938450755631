import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../constants'
import * as React from 'react'
import { AreaSection } from '../../../../types/measure'
import { getAreaPauses, getAreaSections, reduceWarnAreas } from '../../../../functions/shared'
import * as moment from 'moment'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface OwnProps {
  data: any
  filteredData: any
  pauses?: Array<{ start: number; end: number }>
}

export interface OwnState {
  showTooltip: boolean
  tooltipData: any
}

type Props = OwnProps & WithTranslation

class FanGraphMain extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showTooltip: false,
      tooltipData: null,
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public render() {
    const { pauses, filteredData } = this.props
    const warnPoints: AreaSection[] | undefined =
      (this.props.data as [{}]) && getAreaSections(this.props.data, 'warnArea').filter(e => e.value === 100)

    const warnAreas = reduceWarnAreas(warnPoints, 'axis_current')
    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), 'axis_perc', COLORS.pauses)

    return (
      <React.Fragment>
        <div className="col-md-10">
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              // syncId={this.id}
              onMouseEnter={this.handleMouseEnter}
              onMouseMove={this.handleMouseMove}
              onMouseLeave={this.handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                hide={true}
                dataKey="time"
                type={'number'}
                label={''}
                height={30}
                axisLine={false}
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                interval="preserveStartEnd"
              />
              <YAxis label={'%'} domain={[0, 180]} axisLine={false} yAxisId={'axis_perc'} orientation={'left'} />
              <YAxis label={'A'} domain={[0, 80]} axisLine={false} yAxisId={'axis_current'} orientation={'right'} />
              <CartesianGrid stroke="#f5f5f5" />
              {warnAreas}
              {warnPauses}

              <Tooltip content={FanGraphMain.nullTooltipContent} />
              <Line
                dataKey="assorbimento"
                stroke={COLORS.assorbimento}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'axis_current'}
              />
              <Line
                dataKey="setVelocita"
                stroke={COLORS.setVelocita}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'axis_perc'}
              />
              <Line
                dataKey="temperatura"
                stroke={COLORS.temperatura}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'axis_perc'}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="col-md-2">
          {this.state.showTooltip && this.state.tooltipData !== null && (
            <ul className="side-tooltip">
              <li>
                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                <br />
                <span>{moment(this.state.tooltipData.time, 'X').format('HH:mm')}</span>
              </li>
              {this.state.tooltipData.setVelocita !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.setVelocita')}</strong>
                  <br />
                  <span
                    className="label-color label-color-line"
                    style={{ backgroundColor: this.state.tooltipData.setVelocitaColor }}
                  />
                  <span>{this.state.tooltipData.setVelocita} %</span>
                </li>
              )}
              {this.state.tooltipData.assorbimento !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.assorbimento')}</strong>
                  <br />
                  <span
                    className="label-color label-color-line"
                    style={{ backgroundColor: this.state.tooltipData.assorbimentoColor }}
                  />
                  <span>{this.state.tooltipData.assorbimento} A</span>
                </li>
              )}
              {this.state.tooltipData.temperatura !== null && (
                <li>
                  <strong>{this.props.t('plantAnalysis.labels.temperatura')}</strong>
                  <br />
                  <span
                    className="label-color label-color-line"
                    style={{ backgroundColor: this.state.tooltipData.temperaturaColor }}
                  />
                  <span>{this.state.tooltipData.temperatura} °C</span>
                </li>
              )}

              <li>
                <strong>{this.props.t('plantAnalysis.labels.pauses')}</strong>{' '}
                <span
                  className="label-color label-color-line label-color-line-right"
                  style={{ backgroundColor: COLORS.pauses }}
                />
              </li>
            </ul>
          )}
        </div>
      </React.Fragment>
    )
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      let curData = this.props.data && this.props.data.find((e: any) => e.time === args.activeLabel)

      const curAssorbimento = curData.assorbimento !== undefined ? curData.assorbimento : '---'
      const curSetVelocita = curData.setVelocita !== undefined ? curData.setVelocita : '---'
      const curTemperatura = curData.temperatura !== undefined ? curData.temperatura : '---'

      this.setState({
        showTooltip: true,
        tooltipData: {
          time: curData.time,
          setVelocita: curSetVelocita,
          assorbimento: curAssorbimento,
          temperatura: curTemperatura,
          setVelocitaColor: COLORS.setVelocita,
          assorbimentoColor: COLORS.assorbimento,
          temperaturaColor: COLORS.temperatura,
        },
      })

      curData = null
    }
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }
}

export default withTranslation()(FanGraphMain)
