// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button, { ButtonDefinition } from '../Button/Button'
import IconComponent from '../MVIcon/Icon'
import { Color } from '../Utils/utils'

interface Props {
  visible: boolean
  title?: string
  onClose: () => void
  closeOnFocusOut?: boolean
  children: React.ReactNode
  customModalBody?: boolean
  width?: 25 | 50 | 75 | 100 | 'auto'
  additionalFooterButtons?: ButtonDefinition[]
  additionalFooterContent?: React.ReactNode
  additionalHeaderContent?: React.ReactNode
  hideFooterCloseButton?: boolean
  closeLabel?: string
  id?: string
}

class Modal extends React.Component<Props> {
  public render() {
    const props = this.props
    return props.visible ? (
      <div className="inplant-modal">
        <div className="modal mv-modal mv-fade mv-show" role="dialog" id={props.id} onClick={this.handleClickOutside}>
          <div className={`modal-dialog w-${props.width || 'auto'}`} role="document">
            <div className="modal-content">
              <div className="modal-header">
                {props.title && (
                  <h5 className="modal-title">
                    {props.title} {props.additionalHeaderContent}{' '}
                  </h5>
                )}
                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>
                  <IconComponent icon={'times'} size="sm" />
                </button>
              </div>
              {props.customModalBody ? props.children : <div className="modal-body">{props.children}</div>}
              {!props.customModalBody && (
                <div className="modal-footer">
                  {props.additionalFooterContent}
                  {!props.hideFooterCloseButton && (
                    <Button
                      variant="secondary-alternate"
                      label={props.closeLabel}
                      type="button"
                      onClick={this.handleClose}
                    />
                  )}
                  {props.additionalFooterButtons &&
                    props.additionalFooterButtons.map((button, index) => (
                      <Button {...button} key={`additional_button_${index}`} />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null
  }

  private handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    this.props.onClose()
  }

  private handleClickOutside = (event: any) => {
    event.persist()
    if (event.target.className === 'modal') {
      event.preventDefault()
    }
    if (this.props.closeOnFocusOut && event.currentTarget === event.target) {
      this.props.onClose()
    }
  }
}

interface SubmitButtonFormDefinition {
  label: string
  disabled?: boolean
  isLoading?: boolean
  variant?: Color
}

interface FormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | Promise<void>
  submitButton: SubmitButtonFormDefinition
}

export const FormModal = (props: Props & FormProps) => {
  const { onSubmit, submitButton, additionalFooterButtons, ...otherProps } = props
  return (
    <form onSubmit={onSubmit}>
      <Modal
        {...otherProps}
        children={otherProps.children}
        additionalFooterButtons={[
          ...((additionalFooterButtons && additionalFooterButtons) || []),
          ...[
            {
              variant: submitButton.variant ? submitButton.variant : 'success',
              type: 'submit',
              ...submitButton,
            } as ButtonDefinition,
          ],
        ]}
      />
    </form>
  )
}

export default Modal
