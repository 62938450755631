// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from "../Icon/Icon";

const Loader = () => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <span className={'mv-btn__loader'}>
      <span className={'mv-btn__centerer'}>
        <IconComponent icon={"spinner"} spin={true} />
      </span>
    </span>
  )
}

export default Loader