// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { AlignItems, Direction, FlexSpace } from '../Flex/Flex'
import IconComponent from "../Icon/Icon";
import Text from '../Text/Text'
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'
import { getUniqueId } from '../../../functions/helpers/stringHelper'
import { Changeable } from '../../../types/onFunction';

interface CheckboxValue {
  selected: boolean
  value: string
}

interface CheckboxProps extends Changeable<[values: CheckboxValue[]]> {
  items: CheckboxItemProps[]
  itemsDirection?: Direction
  itemSpace?: FlexSpace
  label?: string
  required?: boolean
}

const Checkbox = (props: CheckboxProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [checkboxValues, setCheckboxValues] = useState<CheckboxValue[]>(
    props.items.map(i => ({ selected: i.initialSelected || false, value: i.value }))
  )

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    props.onChange?.(checkboxValues)
  }, [checkboxValues])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <>
      {(props.label || props.required) && <label htmlFor={props.label}>
        {props.label}
        {props.required && <abbr className="required"> *</abbr>}
      </label>}
      <Flex
        alignItems={AlignItems.stretch}
        direction={props.itemsDirection || Direction.row}
        className={`mv-radio-group`}
        spaceSize={props.itemSpace || 'lg'}
      >
        {props.items.map((i, index) => (
          <CheckboxItem
            key={index}
            {...i}
            onChange={checked => {
              setCheckboxValues(prev => {
                const newValues = [...prev]
                newValues[index] = { selected: checked, value: i.value }
                return newValues
              })
            }}
          />
        ))}
      </Flex>
    </>
  )
}

export interface CheckboxItemProps {
  label?: string
  value: string
  bold?: boolean
  initialSelected?: boolean
  name?: string
}

interface HiddenProps extends IFlexItem,Changeable<[checked: boolean, value: string]> {
  direction?: Direction
}

export const CheckboxItem = (props: CheckboxItemProps & HiddenProps): JSX.Element => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { bold, label } = props
  const [uniqueId] = useState(getUniqueId(props.label))
  const [checked, setChecked] = useState(props.initialSelected)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    setChecked(props.initialSelected)
  }, [props.initialSelected])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={`mv-checkbox ${renderFlexItem(props)}`}>
      <input
        type="checkbox"
        name={props.name}
        id={`${uniqueId}`}
        onChange={e => {
          setChecked(e.currentTarget.checked)
          props.onChange?.(e.currentTarget.checked, props.value)
        }}
        checked={!!checked}
      />
      <label htmlFor={`${uniqueId}`}>
        <IconComponent className={'icon icon--unselected'} icon={["regular","square"]} />
        <IconComponent className={'icon icon--selected'} icon={"check-square"} />
        {(label && <Text text={bold ? `|**${label}**|` : label} />) || null}
      </label>
    </div>
  )
}

export default Checkbox
