// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import { FetchWrapper } from '../../../types/fetchWrapperInterface'
import { IconSize } from '../../../services/icon'
import { logWarn } from '../../../functions/log'

export interface OwnProps {
  className?: string
  path: string
  fetchWrapper: FetchWrapper
  iconSize?: IconSize
}

export interface WithObserverProps {
  className?: string
  iconSize?: IconSize
  src: string
  onError: (event: React.SyntheticEvent<any>) => void
}

export interface OwnState {
  imageRef: React.RefObject<any>
  isFetching: boolean
  hasError: boolean
  fetchController: AbortController
  observer: IntersectionObserver | null
  src?: string
}

function withObserver<P extends object>(WrappedComponent: React.ComponentType<P>) {
  type HocProps = P & OwnProps
  type ChildProps = P & WithObserverProps

  return class extends React.Component<HocProps, OwnState> {
    constructor(props: HocProps) {
      super(props)
      this.state = {
        imageRef: React.createRef(),
        isFetching: true,
        hasError: false,
        fetchController: new AbortController(),
        observer: new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting && this.state.isFetching) {
            this.getURL()
              .then(src =>
                this.setState(currentState => {
                  currentState.observer!.unobserve(currentState.imageRef.current)
                  return {
                    ...currentState,
                    isFetching: false,
                    src,
                    observer: null,
                  }
                })
              )
              .catch(this.handleError)
          }
        }),
      }
      this.getURL = this.getURL.bind(this)
      this.handleError = this.handleError.bind(this)
    }

    public componentDidMount() {
      this.state.observer!.observe(this.state.imageRef.current)
    }

    public componentWillUnmount() {
      this.state.fetchController.abort()
      if (this.state.observer) {
        // this.state.observer.unobserve(this.state.imageRef.current)
      }
    }

    public render() {
      const { imageRef, isFetching, src, hasError } = this.state
      const { className, iconSize = 'sm' } = this.props
      const innerProps = {
        ...(this.props as object),
        className,
        iconSize,
        src: src as string,
        onError: this.handleError,
      } as ChildProps

      return isFetching ? (
        <div ref={imageRef} className={`d-flex justify-content-center align-items-center ${className}`}>
          <IconComponent icon={'circle-notch'} size={iconSize} spin={true} />
        </div>
      ) : hasError ? (
        <div className={`d-flex justify-content-center align-items-center ${className}`}>
          <IconComponent icon={'eye-slash'} size={iconSize} />
        </div>
      ) : (
        <WrappedComponent {...innerProps} />
      )
    }

    public async getURL() {
      const result: Blob = await this.props.fetchWrapper.request(this.props.path, {
        signal: this.state.fetchController.signal,
      })
      return URL.createObjectURL(result)
    }

    public handleError(error: React.SyntheticEvent<any>) {
      logWarn('Image fetch error', this.props.path, error)

      this.setState({
        isFetching: false,
        hasError: true,
      })
    }
  }
}

export default withObserver
