const en = {
  chronoframe: {
    dueDateModule: 'TODO',
    pageSlug: {
      manager: 'TODO',
      ongoing: 'TODO',
      overdue: 'TODO',
      'to-validate': 'TODO',
      archive: 'TODO',
      rejected: 'TODO',
      calendar: 'TODO',
      'user-tasks': 'TODO'
    },
    fetching: 'Fetching',
    periodicity: {
      '1_DAY': '1 Day',
      '1_WEEK': '1 Week',
      '2_WEEKS': '2 Weeks',
      '1_MONTH': '1 Month',
      '2_MONTHS': '2 Months',
      '3_MONTHS': '3 Months',
      '6_MONTHS': '6 Months',
      '1_YEAR': '1 Year',
      '2_YEARS': '2 Years',
    },
    status: {
      done: 'FATTO',
      overdue: 'SCADUTO',
      planned: 'DA FARE',
      unhandled: 'DA CONFERMARE',
      postponed: 'DA FARE',
      rejected: 'RIFIUTATO',
      todo: 'DA FARE',
      tovalidate: 'DA VALIDARE',
      canceled: 'ELIMINATO',
      differentDays: {
        postponed: 'entro {{days}} gg',
        todo: 'entro {{days}} gg',
        planned: 'entro {{days}} gg',
        overdue: 'da {{days}} gg'
      }
    },
    jobType: {
      custom: 'On Condition',
      maintenance: 'Periodic',
      anomaly: 'Anomaly',
    },
    scheduledJobDetail: {
      title: 'Intervention details',
      buttons: {
        markAsDone: 'Mark as done',
        reschedule: 'Reschedule',
        reject: 'Reject',
      },
      modals: {
        markAsDone: {
          title: 'Mark as done intervention',
          dueDate: 'Due Date',
          cost: 'Cost',
          time: 'Time',
          hours: 'Hours',
          notes: 'Notes',
          confirm: 'Confirm',
        },
        reschedule: {
          title: 'Reschedule intervention',
          dueDate: 'Due Date',
          notes: 'Notes',
          confirm: 'Confirm',
        },
        reject: {
          title: 'Reject intervention',
          notes: 'Notes',
          confirm: 'Confirm',
        }
      },
      recap: {
        title: 'Maintenance recap',
        macroarea: 'Macro Area',
        section: 'Section',
        component: 'Component',
        target: 'Target',
        hours: 'Hours',
        sinceLastMaintenance: 'since last maintenance',
        operation: 'Operation',
        type: 'Operation Type',
        operatorName: 'Operator name',
        dueDate: 'Due date',
        plannedDate: 'Planned date',
        status: 'Status',
        cost: 'Cost',
        time: 'Time',
      },
      historyActions: {
        on: 'on',
        type: {
          created: 'created',
          acknowledged: 'acknowledged',
          done: 'done',
          rescheduled: 'rescheduled',
          rejected: 'rescheduled'
        }
      },
      history: {
        title: 'History',
        user: 'User',
        action: 'Action',
        date: 'Date',
        notes: 'Notes',
      },
      relatedJobs: {
        title: 'Periodic maintenance history',
        hours: 'hours',
        user: 'User',
        action: 'Action',
        date: 'Date',
        notes: 'Notes',
        noHistoryAvailable: 'No history data available',
        late: 'late'
      },
      images: 'Images',
    },
    errors: {
      somethingGoneWrong: 'We\'re sorry — something\'s gone wrong.',
      contactOurTeam: 'Plase contact our team to notify the problem',
      targetNotFound: 'Selected target seems not present or deleted',
    },
    tasks: {
      table: {
        advancedFilters: 'TODO',
        searchPlaceholder: 'TODO',
        noData: 'Dati non disponibili',
        noItemFound: 'Nessun elemento trovato',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        actions: 'Azioni',
        applyFilters: 'Filtra',
        cancel: 'Annulla',
        resetFilters: 'Azzera filtri',
        checkAll: 'Seleziona tutto',
        uncheckAll: 'Deseleziona tutto',
      },
      form: {
        cancel: 'TODO',
        save: 'Salva',
      },
      actions: {
        reject: 'Rifiuta',
        cancel: 'Elimina',
        reschedule: 'Riprogramma',
        done: 'Segna come fatto',
        'done-batch': 'Segna come fatti',
        validated: 'Segna come validato',
        'validate-batch': 'Segna come validati',
        failValidation: 'Rifiuta validazione',
        validate: 'Valida',
        undo: 'Annulla ultima azione', // rivedere traduzione
        addNote: 'Aggiungi nota',
        addNoteOrAttachment: 'Aggiungi nota o allegati',
        actionsButton: 'Azioni'
      },
      batchActions: {
        actions: 'Azioni',
        selectTask: 'Seleziona scadenza',
        noActionAvailable: 'Non ci sono azioni multiple disponibili per le scadenze selezionate',

      }
    },
    dueDates: {
      title: 'Scadenze in essere',
      createNew: 'Crea nuova scadenza',
      exportData: 'ESPORTA DATI',
      newDueDates: 'Nuova scadenza',
      me: 'Me',
      tableList: {
        dueAtDate: 'Scadenza',
        doneAtDate: 'Data esecuzione',
        effectiveFromDate: 'Emissione',
        status: 'Stato',
        category: 'Categoria',
        automaticRenewal: 'Rinnovo',
        attachments: 'Allegati',
        inChargeUser: 'Responsabile',
        target: 'Cespite',
        lastNotes: 'Note',
        creatorNotes: 'Descrizione',
        description: 'Descrizione',
        activity: 'Attività',
        assigneeUser: 'Assegnatario',
        assignToMe: 'Prendi in carico',
        assignTo: 'Assegna a...',
        every: 'OGNI',
        yes: 'Sì',
        no: 'No',
        today: 'OGGI',
        yesterday: 'IERI',
        tomorrow: 'DOMANI',
        singleDay: 'GIORNO',
        multipleDays: 'GG',
        singleMonth: 'MESE',
        multipleMonths: 'MESI',
        singleYear: 'ANNO',
        multipleYears: 'ANNI',
        from: 'DA',
        in: 'TRA',
        plantId: 'ID',
        hasClosingAttachments: '',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
      },
      formField: {
        validationError: {
          title: 'Errore di validazione',
          description: 'Verificare la correttezza dei dati immessi',
          invalidField: 'Il valore non è corretto',
        },
        dateValidationError: {
          title: 'Date non valide',
          description: 'Verificare la correttezza delle date immesse',
          invalidField: 'Deve essere successiva alla data di emissione',
        },
        repetitionDaysValidationError: {
          title: 'Valore non ammesso',
          description: 'Inserire un valore valido',
          invalidField: 'Valore non ammesso',
        },
        destination: 'Impianto',
        description: 'Descrizione',
        dueAtDate: 'Data scadenza',
        startDueAtDate: 'Inizio data di scadenza',
        endDueAtDate: 'Fine data di scadenza',
        status: 'Stato',
        effectiveFromDate: 'Data emissione',
        date: 'Data esecuzione',
        activity: 'Attività',
        context: 'Ambito',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        target: {
          group: 'Gruppo cespiti',
          asset: 'Cespiti',
          dueDate: 'Data scadenza',
        },
        splitTargets: 'Crea una scadenza per ogni cespite',
        rolesWithVisibility: 'Visibilità ruoli',
        inChargeUser: 'Responsabile',
        mustBeValidated: 'Richiede validazione',
        rolesWithValidation: 'Ruolo validatore',
        automaticRenewal: 'Rinnovo automatico',
        repetitionFromDueDate: 'Rinnovo alla data di scadenza',
        repetitionFromDoneDate: 'Rinnovo alla data in cui è stato fatto',
        repetitionRule: {
          method: 'Rinnovo automatico',
          period: 'Periodo di rinnovo',
          days: 'A distanza di giorni',
          daysNumber: 'Giorni',
          monthsNumber: 'Mesi',
          dayOfMonth: 'Giorno del mese',
          months: 'A distanza di mesi',
        },
        previousRepetition: 'Rinnovo prec.',
        noticeMessage: 'Invia preavviso',
        notificationDaysAdvance: 'Notifica giorni prima della scadenza',
        creatorNotes: 'Note',
        notes: 'Note',
        dateAlert: 'Non può essere antecedente alla data di emissione',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
      },
      detail: {
        edit: 'Modifica scadenza',
        destination: 'Impianto',
        details: 'Dettagli',
        actionHistory: 'Storico azioni e note',
        edited: 'Scadenza modificata',
        created: 'Scadenza creata',
        done: 'Scadenza completata',
        doneDate: 'Scadenza eseguita il {{date}}',
        canceled: 'Scadenza eliminata',
        rejected: 'Scadenza rifiutata',
        validated: 'Scadenza validata',
        validationFailed: 'Validazione rifiutata',
        addNote: 'Aggiunta nota',
        actions: 'Azioni',
        cancelAction: 'Annulla operazione',
        canceledAction: "Quest'operazione è stata annullata",
        prevTask: 'PRECEDENTE IN LISTA',
        nextTask: 'SUCCESSIVA IN LISTA',
        dueAtDate: 'Scadenza',
        effectiveFromDate: 'Emissione',
        activity: 'Attività',
        status: 'Stato',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        repetitionRule: 'Rinnovo automatico',
        childTask: {
          dueAtDate: 'Scadenza successiva',
        },
        addAttachments: 'Aggiunti allegati',
        addNoteWithAttachments: 'Aggiunta nota e allegati',
        target: 'Cespiti',
        attachments: 'Allegati',
        doneAttachments: "Allegati 'done'",
        validationAttachments: 'Allegati validazione',
        attachmentPosition: 'Originale',
        inChargeUser: 'Responsabile',
        assigneeUser: 'Assegnatario',
        creatorNotes: 'Note',
        rolesWithVisibility: 'Visibilità ruoli',
        rolesWithValidation: 'Ruolo validatore',
        mustBeValidated: 'Validazione richiesta',
        yes: 'Sì',
        no: 'No',
        renewalPeriod: 'Rinnovo a distanza di',
        dayOfMonth: ', fissato al giorno',
        or: 'o',
        day: 'giorno',
        days: 'giorni',
        month: 'mese',
        months: 'mesi',
        hours: 'ore',
        fromDueDate: 'dalla data di scadenza',
        fromDoneDate: 'dalla data in cui è stato fatto',
        plantId: 'ID',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
        action: {
          done: 'Imposta come fatto',
          donebatch: 'Imposta come fatti',
          reschedule: 'Riprogramma',
          reject: 'Rifiuta il task',
          cancel: 'Elimina il task',
          validation: 'Valida il completamento del task',
          validate: 'Valida il task', // ???
          validatebatch: 'Valida le scadenze',
          failvalidation: 'Rifiuta validazione',
          undo: "Annulla l'ultima operazione",
          addnote: 'Aggiungi nota o allegati al task',
        },
        doneAtDate: 'Data esecuzione',
        upload: 'Aggiungi file',
        description: 'Descrizione',
      },
    }
  }
}

export default en
