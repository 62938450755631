import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AssetDetail, AssetTaskForCopy } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Loader } from '@mv-submodules/inplant-components-fe'
import TasksData from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/TasksData'
import { AssetsTasksCopyForm } from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/CopyTasksPageView'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface StateProps {
  selectedTasks: AssetTaskForCopy[]
  assetDetail?: AssetDetail
  isFetching: boolean
  fetchErrors: boolean
  assetsTask: AssetsTasksCopyForm
  updateAssetsTasks: (data: AssetsTasksCopyForm) => void
}

type Props = WithTranslation & StateProps

class CopyTasksSummary extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleChangeDateTask = this.handleChangeDateTask.bind(this)
  }

  public render() {
    const { fetchErrors, isFetching, t, assetDetail } = this.props
    if (isFetching) {
      return <Loader />
    }
    if (fetchErrors) {
      return <p>error</p>
    }

    if (assetDetail) {
      return (
        <>
          <Row>
            <Column md={6}>
              <Row>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.machineCode')} </label>
                </Column>
                <Column sm={10}>{assetDetail.assetCode}</Column>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.plantCode')}</label>
                </Column>
                <Column sm={10}>{assetDetail.positionCode}</Column>
                <Column sm={2}> <label
                  className='small-label-readonly'>{t('assetManager.forms.assetDescription')}</label> </Column>
                <Column sm={10}>{assetDetail.assetDescription}</Column>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.assetsGroups')}</label>
                </Column>
                <Column sm={10}>
                  {assetDetail.groups.map(g => (
                    <div key={g.id}>{g.name}</div>
                  ))}
                  {assetDetail.groups.length === 0 && '-'}
                </Column>
              </Row>
            </Column>
            <Column md={6}>
              <Row>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.brand')}</label>
                </Column>
                <Column sm={10}>{assetDetail.assetData.brand || '-'}</Column>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.serialNumber')}</label>
                </Column>
                <Column sm={10}>{assetDetail.assetData.serialNumber || '-'}</Column>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.model')}</label>
                </Column>
                <Column sm={10}>{assetDetail.assetData.model || '-'}</Column>
                <Column sm={2}> <label className='small-label-readonly'>{t('assetManager.forms.notes')}</label>
                </Column>
                <Column sm={10}>{assetDetail.assetData.notes || '-'}</Column>
              </Row>
            </Column>
          </Row>
          <header className='page-header pl-0'>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='d-flex justify-content-start'>
                <hgroup>
                  <h1 className='title'>{t('assetManager.labels.tasksToCopy')}</h1>
                </hgroup>
              </div>
            </div>
          </header>
          {Object.keys(this.props.assetsTask).map(key => (
            <TasksData
              data={this.props.assetsTask[key]}
              key={key}
              handleDateChange={(date, propName) => this.handleChangeDateTask(date, propName, key)}
            />
          ))}
        </>
      )
    }

    return null
  }

  private handleChangeDateTask(date: string, propName: 'creationDate' | 'dueAtDate', key: string) {
    const { assetsTask } = this.props
    assetsTask[key].form[propName] = date
    this.props.updateAssetsTasks(assetsTask)
  }
}

export default withTranslation()(CopyTasksSummary)
