import { getWorkingMode } from './shared'
import * as moment from 'moment'
import { BinaryMappingConfiguration } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/settings'

const calcPreItem = (
  keys: string[],
  state: {last: {[k: string]: any[]}},
  fromBinary?: boolean,
  binaryMapping?: BinaryMappingConfiguration
) => {
  const preItem: {[k:string]: string | number | undefined} = {}
  keys.map((e: string) => {
    if(fromBinary && state.last.status && binaryMapping) {
      preItem[e] = getValueFromBinaryStatus(e, state.last.status[1], binaryMapping)
    } else {
      preItem[e] = state.last[e]
        ? typeof state.last[e][1] === 'boolean'
          ? state.last[e][1]
            ? 1
            : 0
          : e === 'assorbimento'
            ? state.last[e][1] / 10
            : state.last[e][1]
        : undefined
    }
  })

  return preItem
}

export interface SeriesResponse {
  name: string
  columns: string[]
  values: any[]
}

export const hydrateData = (
  tempData: any,
  mergedData: any[],
  warnMetric?: string,
  warnMinLevel?: number,
  additionalKeysToMerge?: string[],
) => {

  Object.keys(tempData).map((key: string) => {
    tempData[key].map((dato: any) => {
      const data: any = {}
      const existing = mergedData.find(e => e.time === dato.x)

      if (!existing) {
        data.h100 = dato.h100
        data[key] = dato.y
        data.time = dato.x
        if(additionalKeysToMerge) {
          additionalKeysToMerge.forEach(k => {
            if (dato.hasOwnProperty(k)) {
              data[k] = dato[k]
            }
          })
        }
        if (
          (warnMetric && key === warnMetric && key === warnMetric) ||
          (key === 'assorbimento' || key === 'performance' || key === 'marcia')
        ) {
          // @todo remove performance, only test
          data.warnArea =
            (warnMetric && warnMinLevel && parseInt(dato.y, 10) <= warnMinLevel) || (dato.y === '0' || dato.y === 0)
              ? 100
              : 0
        }

        mergedData.push(data)
      } else {
        existing[key] = dato.y
      }
    })
  })
}

export const fillWarnArea = (mergedData: any[], warnKey: string, warnMinLevel: number) => {
  return mergedData.map((e: any, i: number) => {
    if (e.hasOwnProperty(warnKey) && e[warnKey] <= warnMinLevel) {
      mergedData[i].warnArea = 100
    }

    return e
  })
}

export const hydrateTimeData = (
  keys: string[],
  mergedData: any[],
  state: any,
  tail = true,
  fromBinary?: boolean,
  validUndefinedKeys?: string[],
  binaryMapping?: BinaryMappingConfiguration,
  singleDay?: boolean
) => {
  const last: any[] = []
  mergedData.map((e: any) => {
    keys.map(key => {
      if (
        e[key] === undefined && validUndefinedKeys?.includes(key)
        || e[key] !== undefined
      ) {
        last[key] = e[key]
      }
    })

    keys.map(key => {
      e[key] = last[key]
    })

    return e
  })

  // !singleDay || moment(state.last[k][0]).isSame(moment(mergedData[0].time), 'day')

  if (state.startDate !== undefined) {
    if (mergedData.length > 0) {
      const spaceBefore = state.startDate ? mergedData[0].time - state.startDate : 0
      const spaceAfter = state.endDate ? state.endDate - mergedData[mergedData.length - 1].time : 0
      // const lastValue = mergedData[mergedData.length - 1]

      if (spaceBefore > 0) {
        const itemsBefore: Array<{ [k: string]: string | number | undefined }> = []

        for (let t = state.startDate; t < mergedData[0].time; t += 300) {
          const preItem = calcPreItem(keys, state, fromBinary, binaryMapping)

          preItem.time = t
          preItem.h100 = 100

          itemsBefore.push(preItem)
        }

        itemsBefore.reverse().map((i: any) => {
          mergedData.unshift(i)
        })
      } else {
        // console.log('space before === 0') // tslint:disable-line
      }

      if (tail) {
        if (state.endDate !== undefined && spaceAfter > 0) {
          const itemsAfter: Array<{[k:string]: string | number}> = []
          const lastItem = mergedData[mergedData.length - 1]
          for (let t = mergedData[mergedData.length - 1].time + 1; t < (mergedData[mergedData.length - 1].time + spaceAfter - 100); t += 500) {
            const postItem: {[k:string]: string | number} = {}
            keys.map((e: string) => {
              postItem[e] =
                lastItem && (
                  lastItem[e] === undefined && validUndefinedKeys?.includes(e) ||
                  lastItem[e] !== undefined
                )
                  ? moment.unix(state.endDate).isBefore(lastItem.time)
                    ? undefined
                    : typeof lastItem[e] === 'boolean'
                    ? lastItem[e]
                      ? 1
                      : 0
                    : lastItem[e]
                  : undefined
            })

            postItem.time = t
            postItem.h100 = 100

            itemsAfter.push(postItem)
          }

          itemsAfter.map((i: any) => {
            mergedData.push(i)
          })

          if(mergedData[mergedData.length - 1].time < state.endDate) {
            mergedData.push({
              ...mergedData[mergedData.length - 1],
              ...{
                time: state.endDate
              }
            })
          }
        }
      }
      // mergedData.splice(mergedData.length - 1)

    } else {
      const preItem = calcPreItem(keys, state, fromBinary, binaryMapping)

      mergedData.unshift(preItem)
    }
  }

  // const filledFromStartKeys: string[] = []
  mergedData.slice(1).map((e: any, index: number) => {
    /*mergedData.map(( e: any, index: number ) => {*/
    if (
      e.time <
      moment()
        .utc()
        .unix()
    ) {
      keys.map((k: string) => {
        if (
          e[k] === undefined &&
          ((state.last && state.last[k] && (typeof state.last[k][1]=== 'boolean')) || k === 'ricetta' || k === 'setVelocita')
        ) {
          mergedData[index + 1][k] = state.last[k][1]
            ? state.last[k] && (typeof state.last[k][1] === 'boolean')
              ? 1
              : state.last[k][1]
            : 0
        }
      })
    }
  })
}

export const parseData = (dataSrc: any) => {
  return dataSrc && dataSrc.results && dataSrc.results[0] && dataSrc.results[0].series
    ? dataSrc.results[0].series[0].values
    : []
}

export const parseResponseData = (response: string) => {
  const dataSrc = JSON.parse(response)

  return parseData(dataSrc)
}

export const parseResponseSingleData = (response: string) => {
  const dataSrc = JSON.parse(response)

  return dataSrc && dataSrc.results && dataSrc.results[0] && dataSrc.results[0].series
    ? dataSrc.results[0].series[0].values[0]
    : []
}

export const extractResponseData = (dataSrc: any) => {
  let data = dataSrc
  if(typeof dataSrc === 'string') {
    data = JSON.parse(dataSrc)
  }
  return data && data.results && data.results[0] && data.results[0].series
    ? data.results[0].series[0].values
    : []
}

export const extractResponseSingleData = (dataSrc: any) => {
  return dataSrc && dataSrc.results && dataSrc.results[0] && dataSrc.results[0].series
    ? dataSrc.results[0].series[0].values[0]
    : []
}

export const parseResponseSeries = (id: string, response: string): { [k: string]: SeriesResponse } => {
  const dataSrc = JSON.parse(response)
  return (
    (dataSrc &&
      dataSrc.results &&
      dataSrc.results[0] &&
      dataSrc.results[0].series &&
      dataSrc.results[0].series[0] &&
      dataSrc.results[0].series[0]) ||
    null
  )
}

export const newParseResponseSeries = (response: string): SeriesResponse => {
  const dataSrc = JSON.parse(response)
  return (
    (dataSrc &&
      dataSrc.results &&
      dataSrc.results[0] &&
      dataSrc.results[0].series &&
      dataSrc.results[0].series[0] &&
      dataSrc.results[0].series[0]) ||
    null
  )
}

export const populateManAutoLocSec = (mergedData: any, useAutoForManual?: boolean) => {
  mergedData.map((data: any, index: number) => {
    mergedData[index].manAutoLocSec = getWorkingMode(
      useAutoForManual ? (data.automatico === undefined ? undefined : data.automatico === 1 ? 0 : 1) : data.manuale,
      data.automatico,
      data.localeSezionato
    )
  })
}

export const interpolateTime = (mergedData: any[], timeStart: number, timeEnd: number) => {
  const minTimeGap = 600 // seconds
  
  if (mergedData.length > 0) {
    // UNUSED const dataTimeStart = mergedData[0].time
    // UNUSED const dataTimeEnd = mergedData[mergedData.length - 1].time
    const timeDiff = timeEnd - timeStart

    if (timeDiff > 0 && timeDiff / minTimeGap > mergedData.length) {
      let lastElement = mergedData[0]
      let tempData: null | any = {}
      mergedData.forEach((e, i) => {
        tempData[e.time] = e
        mergedData.splice(i, 1)
      })

      for (let time = timeStart; time <= timeEnd; time += minTimeGap) {
        if (!tempData[time]) {
          tempData[time] = {}
        }
      }

      Object.keys(tempData).forEach(id => {
        if (!tempData.hasOwnProperty(id) || !tempData[id].hasOwnProperty('time')) {
          lastElement = { ...lastElement, time: parseInt(id, 10) }
        } else {
          lastElement = tempData[id]
        }

        mergedData.push(lastElement)
      })
      mergedData = mergedData.sort((a, b) => (a.time < b.time ? -1 : 1))

      tempData = null
      lastElement = null
    }
  }
}

export const getValueFromBinaryStatus = (key: string, value: number, binaryMapping: BinaryMappingConfiguration ) => {
  switch (key) {
    case 'avantiIndietro':
      // tslint:disable-next-line:no-bitwise
      return value & binaryMapping.avanti ?
        1 :
        // tslint:disable-next-line:no-bitwise
        // value & binaryMapping.indietro ?
          0 // :
         // 2
      break

    case 'inverterDiretta':
      // tslint:disable-next-line:no-bitwise
      return value & binaryMapping.inverter ? 1 : 0
      break

    case 'manuale':
      // tslint:disable-next-line:no-bitwise
      return value & binaryMapping.manuale ? 1 : 0
      break

    case 'automatico':
      // tslint:disable-next-line:no-bitwise
      return value & binaryMapping.automatico ? 1 : 0
      break;

    case 'localeSezionato':
      // tslint:disable-next-line:no-bitwise
      return value & binaryMapping.locale ?
      1 :
      // tslint:disable-next-line:no-bitwise
      value & binaryMapping.sezionato ?
        0 :
        undefined
      break

    default:
      return
      break
  }
}
