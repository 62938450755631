import { NullPie, RowLegend } from '../../../../../functions/shared'
import PieSmallPrint from '../../charts/PieSmall/PieSmallPrint'
import * as React from 'react'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import BarsPrint from '../../charts/Bars/BarsPrint'
import TableRowWrapper, {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'

interface OwnProps {
  data: any
  days: number
  name: string
  id: string
  currentOverrun: any
  currentLimit: null | string
  node: any
}

type Props = OwnProps & WithTranslation

class FanGraphRowItemView extends React.PureComponent<Props> {
  public render() {
    const { days } = this.props
    const dataAuto: any = this.props.data.find((e: any) => e.name === 'vAspiratorAuto')
    const dataMan: any = this.props.data.find((e: any) => e.name === 'vAspiratorManual')
    const dataLoc: any = this.props.data.find((e: any) => e.name === 'vAspiratorLocal')
    const dataSez: any = this.props.data.find((e: any) => e.name === 'vAspiratorSectioned')

    const dataArresto: any = this.props.data.find((e: any) => e.name === 'vAspiratorStop')
    const dataDiretta: any = this.props.data.find((e: any) => e.name === 'vAspiratorBypass')

    const currentOverrun = this.props.currentOverrun ? parseInt(this.props.currentOverrun, 10) : null
    const currentLimit = this.props.currentLimit ? parseInt(this.props.currentLimit, 10) : null

    const currentValue =
      currentOverrun && currentLimit && currentOverrun > currentLimit
        ? (((currentOverrun - currentLimit) / currentLimit) * 100).toFixed(2) + '%'
        : 0

    return (
      <TableRowFullWrapper>
        <TableWrapperUtilities hideHeader={true}>
          <TableRowFullWrapper>
            <h3>{RowLegend(this.props.name)}</h3>
          </TableRowFullWrapper>
          <TableRowWrapper>
            <TableColumnWrapper col={'1/4'}>
              <WithConditionWrapper condition={dataArresto !== undefined}>
                <PieSmallPrint
                  data={[
                    { name: 'true', value: dataArresto?.value / days },
                    { name: 'false', value: 100 - dataArresto?.value / days },
                  ]}
                  colors={[
                    { id: 'false', color: COLORS.marciaFermo[1] },
                    { id: 'true', color: COLORS.marciaFermo[0] },
                  ]}
                />
              </WithConditionWrapper>
              <WithConditionWrapper condition={dataArresto === undefined}>
                <NullPie />
              </WithConditionWrapper>
            </TableColumnWrapper>
            <TableColumnWrapper
              col={'1/4'}
            >
              <WithConditionWrapper
                condition={this.props.node && this.props.node.nodeType.indexOf('_INVERTER') > -1 && dataDiretta !== undefined}>
                <PieSmallPrint
                  data={[
                    { name: 'true', value: dataDiretta?.value / days },
                    { name: 'false', value: 100 - dataDiretta?.value / days },
                  ]}
                  colors={[
                    { id: 'false', color: COLORS.driveBypass[0] },
                    { id: 'true', color: COLORS.driveBypass[1] },
                  ]}
                />
              </WithConditionWrapper>
              <WithConditionWrapper condition={dataDiretta === undefined}>
                <NullPie />
              </WithConditionWrapper>
            </TableColumnWrapper>
            <TableColumnWrapper col={'1/4'}>
              <WithConditionWrapper
                condition={dataAuto !== undefined && dataMan !== undefined && dataLoc !== undefined}>
                <BarsPrint
                  data={[
                    {
                      man: dataMan?.value ? parseFloat(dataMan.value.toFixed(2)) / days : 0,
                      auto: dataAuto?.value ? parseFloat(dataAuto.value.toFixed(2)) / days : 0,
                      loc: dataLoc?.value ? parseFloat(dataLoc.value.toFixed(2)) / days : 0,
                      sec: dataSez?.value ? parseFloat(dataSez.value.toFixed(2)) / days : 0,
                    },
                  ]}
                  colors={{
                    man: COLORS.manAutoLocSec[0],
                    auto: COLORS.manAutoLocSec[1],
                    loc: COLORS.manAutoLocSec[2],
                    sec: COLORS.manAutoLocSec[3],
                  }}
                />
              </WithConditionWrapper>
            </TableColumnWrapper>
            <TableColumnWrapper col={'1/4'}>
              <WithConditionWrapper condition={currentValue !== 0}>
                {RowLegend(
                  'Sforamento Assorbimento',
                  currentValue,
                  null,
                  <React.Fragment>
                    <strong>{this.props.t('plantAnalysis.labels.mean')}</strong>: {currentOverrun}
                    <br />
                    <strong>{this.props.t('plantAnalysis.labels.limit')}</strong>: {currentLimit}
                  </React.Fragment>,
                )}
              </WithConditionWrapper>
            </TableColumnWrapper>
          </TableRowWrapper>
        </TableWrapperUtilities>
      </TableRowFullWrapper>
    )
  }
}

export default withTranslation()(FanGraphRowItemView)
