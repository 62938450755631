import React from 'react'
import TableRowWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

interface Props {
  children: React.ReactNode
  className?: string
  hideHeader?: boolean
}

const TableWrapperUtilities = (props: Props) => {
  const { children, className, hideHeader } = props

  return <table className={`w-100 ${className ? className : ''}`}>
    {!hideHeader && get12TableHeadColumns()}
    {children}
  </table>
}

export function get12TableHeadColumns() {
  return (
    <thead>
    <TableRowWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
      <TableColumnWrapper isHeader={true} col={1}><></>
      </TableColumnWrapper>
    </TableRowWrapper>
    </thead>
  )
}

export default TableWrapperUtilities