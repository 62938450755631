import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import { Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ChartPlaceholder, HHHmmssFromMilliseconds, isJSON, Loader } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import { hydrateData, hydrateTimeData, parseResponseData, parseResponseSingleData } from '../../../../functions/series'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import { GeneralData } from '../../../../types/measure'
import { DateRangeProps } from '../../../../types/workshift'


const validOperators = ['===', '==', '!==', '!=', '<', '<=', '>', '>='] as const
type ValidOperators = typeof validOperators[number]
interface SingleDataValue {
  x: number
  y: number | undefined
  h100: number
}

interface OwnState {
  isLoading: boolean
  isLoadingRecipe: boolean
  fetchErrors: boolean
  fetchErrorsRecipe: boolean
  data: { [key: string]: Measure }
  dataRecipe: {
    [k: number]: any
  }
  last: any
  lastAfter: any
  showTooltip: boolean
  tooltipData: any
  isCollapsed: boolean
  brush1Start: number
  brush1End: number
  brush1StartInd: number
  brush1EndInd: number
  mergedData: undefined | any[]
  filteredData: any[]
  startDate: string | number
  endDate: string | number
}

interface BackgroundConditions {
  value: string
  attribute: string
  operator: string
  className: string
}

interface RecipeConfig {
  [k: number]: {
    backgroundConditions: BackgroundConditions[]
  }
}

interface OwnProps {
  active: string
  component: any
  forceFetch?: boolean
  isNamedRecipesPlant?: boolean
  showFullDay?: boolean
  toggleCollapse: (id: string) => void
}

interface StateProps {
  aspirato: GeneralStore
  fullDay: Workshift | null
  model: null | GeneralData
  plant: string | null
  recipeTables: string | null
  recipeConfig: any | null
  ricetta: GeneralStore
  workshift: Workshift | null
}

const mapStateToProps = (state: any): StateProps & Partial<DateRangeProps> => ({
  aspirato: state.plantAnalysis.general.aspirato,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  model: state.plantAnalysis.model,
  plant: state.plantSelector.plant,
  recipeTables: (state.config && state.config.plantAnalysis && state.config.plantAnalysis.recipeTables) || null,
  recipeConfig:
    (state.config &&
      state.config.plantAnalysis &&
      state.config.plantAnalysis.recipeConfig &&
      isJSON(state.config.plantAnalysis.recipeConfig) &&
      JSON.parse(state.config.plantAnalysis.recipeConfig)) ||
    null,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
})

const cleanState = {
  isLoading: false,
  isLoadingRecipe: false,
  fetchErrors: false,
  fetchErrorsRecipe: false,
  showTooltip: false,
  tooltipData: null,
  isCollapsed: false,
  data: {
    ricetta: {
      data: [],
      min: 0,
      max: 0,
    },
    zonaAspirato: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  dataRecipe: [],
  last: null,
  lastAfter: null,
  brush1Start: 0,
  brush1End: 0,
  brush1StartInd: 0,
  brush1EndInd: 0,
  mergedData: [],
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
}

type Props = StateProps & OwnProps & DateRangeProps & WithTranslation

// const recipeTables = [13, 10]

class GeneralGraphsView extends React.Component<Props, OwnState> {
  private mounted: boolean = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = cleanState
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workshift && this.props.forceFetch) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !nextProps.showFullDay &&
      (this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id ||
        nextProps.plant !== this.props.plant) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else if (
      nextProps.showFullDay &&
      nextProps.active === this.props.component.id &&
      (this.props.fullDay !== nextProps.fullDay ||
        nextProps.plant !== this.props.plant ||
        (nextProps.model &&
          nextProps.model.data &&
          nextProps.model.data.model &&
          (!this.props.model ||
            !this.props.model.data ||
            !this.props.model.data.model ||
            nextProps.model.data.model.content.data.id !== this.props.model.data.model.content.data.id))) &&
      nextProps.fullDay
    ) {
      this.getData(nextProps, true)
      const recTables = nextProps.recipeTables && isJSON(nextProps.recipeTables) ? JSON.parse(nextProps.recipeTables) : []
      this.getDataRecipe(nextProps, recTables, true)
    }
  }

  public render() {
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading
    const ricetteTime: {} = {}
    const { showFullDay, workshift, recipeTables, recipeConfig, isDateFilterRange } = this.props
    const recipeTimeConfig: RecipeConfig = (recipeConfig && recipeConfig.recipeTimeConfig) || null
    const recipeTablesIDsConfig: RecipeConfig = (recipeConfig && recipeConfig.recipeTablesIDs) || null

    const recipeTablesIDs: number[] = recipeTables && isJSON(recipeTables) ? JSON.parse(recipeTables) : []

    if (this.state.filteredData && this.state.filteredData.length > 0) {
      const startTime = this.props.fullDay
        ? moment(this.props.fullDay.start).format('X')
        : this.state.filteredData[0].time
      const endTime = this.props.fullDay
        ? moment(this.props.fullDay.end).format('X')
        : this.state.filteredData[this.state.filteredData.length - 1].time

      let currentTime = startTime
      let currentRecipe = this.state.filteredData[0].ricetta
      const totalTime = endTime - startTime

      this.state.filteredData.forEach((e: { time: number; ricetta: number }, index: number) => {
        if (index > 0) {
          const diffTime = e.time - currentTime

          if (!ricetteTime[currentRecipe]) {
            ricetteTime[currentRecipe] = {
              time: diffTime,
            }
          } else {
            ricetteTime[currentRecipe] = {
              time: ricetteTime[currentRecipe].time + diffTime,
            }
          }

          currentTime = e.time
          currentRecipe = e.ricetta
        }
      })

      Object.keys(ricetteTime).forEach(k => {
        ricetteTime[k] = {
          time: ricetteTime[k].time,
          perc: ricetteTime[k].time / totalTime,
        }
      })
    }

    const hasDayJump: boolean[] = []

    if (showFullDay && recipeTablesIDs) {
      recipeTablesIDs.map((recipe: number) => {
        if (this.state.dataRecipe[recipe]) {
          let day: undefined | number
          this.state.dataRecipe[recipe].map((d: { time: moment.Moment; duration: string }, i: number) => {
            if (!day) {
              day = d.time.get('d')
            } else {
              if (day !== d.time.get('d')) {
                hasDayJump[recipe] = true
                day = d.time.get('d')
              }
            }
          })
        }
      })
    }

    return (
      <React.Fragment>
        <div className='row'>
          {noData && (
            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <div className='alert alert-secondary w-100 col-sm-6 mx-auto'>
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.state.fetchErrors && (
            <div className='alert alert-danger w-100 col-sm-6 mx-auto general-graph-fetch-errors'>
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
        </div>

        {isReady && (
          <React.Fragment>
            {isDateFilterRange ? (
              ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))
            ) : (
              <>
                <GraphLineBarTime
                  filteredData={this.state.filteredData}
                  topMargin={true}
                  entry={'ricetta'}
                  colorsId={'globalRicetta'}
                  i18nTitle={'plantAnalysis.labels.ricetta'}
                  i18nLabelPrefix={'plantAnalysis.ricettaAttuale.'}
                  tooltip={true}
                  pauses={(workshift && workshift.pauses) || []}
                />

                <GraphLineBarTime
                  filteredData={this.state.filteredData}
                  topMargin={true}
                  entry={'zonaAspirato'}
                  colorsId={'zonaAspirato'}
                  i18nTitle={'plantAnalysis.labels.zonaAspirato'}
                />

                <GraphTimelineView data={this.state.filteredData} preserveStartEnd={true} xAxisNumber={true} />

                <BottomLegend
                  labels={['globalRicetta', 'zonaAspirato']}
                  showTooltip={{ globalRicetta: 'plantAnalysis.ricettaAttuale.' }}
                  hideZero={!this.props.isNamedRecipesPlant}
                />
              </>
            )}

            <div className='row mt-5 page-break-inside-avoid'>
              <div className='col-12 col-md-4 col-lg-4 pr-5 mt-3'>
                {showFullDay && <h5>&nbsp;</h5>}
                <table className='table table-striped table-sm recipe-stats '>
                  <thead>
                  <tr>
                    <th>{this.props.t('plantAnalysis.labels.recipe')}</th>
                    <th>%</th>
                    <th>{this.props.t('plantAnalysis.labels.duration')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Object.keys(ricetteTime).map((k: string) => {
                    if (ricetteTime.hasOwnProperty(k)) {
                      const timeData = moment.duration(ricetteTime[k].time, 'seconds')
                      let className = ''
                      if (recipeTimeConfig && recipeTimeConfig[k] && recipeTimeConfig[k].backgroundConditions) {
                        recipeTimeConfig[k].backgroundConditions.forEach((conf: BackgroundConditions) => {
                          className = this.handleRecipeConfig(
                            conf.attribute,
                            ricetteTime[k][conf.attribute],
                            conf.value,
                            conf.operator as ValidOperators,
                            className,
                            conf.className,
                          )
                        })
                      }
                      return (
                        <tr key={k} className={k}>
                          <th className={className}>
                            {(k && k !== 'undefined' ? k + ' - ' : '') +
                              this.props.t('plantAnalysis.ricettaAttuale.' + k, { defaultValue: 'Non definito' })}
                          </th>
                          <td className={className}>{(ricetteTime[k].perc * 100).toFixed(2)}</td>
                          <td className={className}>
                            {HHHmmssFromMilliseconds(timeData.asMilliseconds(), false, true, false)}
                          </td>
                        </tr>
                      )
                    }
                    return null
                  })}
                  </tbody>
                </table>
              </div>
              {showFullDay &&
                recipeTablesIDs.map((recipe: number) => {
                  return (
                    <div className='col-6 col-md-4 col-lg-2 mt-3 pl-4 recipe-tables' key={recipe}>
                      <h5>
                        {this.props.t('plantAnalysis.labels.recipe')} {recipe}
                      </h5>
                      <table className='table table-striped table-sm w-100 '>
                        <thead>
                        <tr>
                          <th>{this.props.t('plantAnalysis.labels.start')}</th>
                          <th>{this.props.t('plantAnalysis.labels.duration')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {isDateFilterRange ? (
                          <tr>
                            <td colSpan={2}>
                              {ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                            </td>
                          </tr>
                        ) : (
                          this.state.dataRecipe &&
                          this.state.dataRecipe[recipe] &&
                          this.state.dataRecipe[recipe].map(
                            (d: { time: moment.Moment; duration: string }, i: number) => {
                              let className = ''
                              const k = recipe.toString()
                              if (
                                recipeTablesIDsConfig &&
                                recipeTablesIDsConfig[k] &&
                                recipeTablesIDsConfig[k].backgroundConditions
                              ) {
                                recipeTablesIDsConfig[k].backgroundConditions.forEach(
                                  (conf: BackgroundConditions) => {
                                    className = this.handleRecipeConfig(
                                      conf.attribute,
                                      d[conf.attribute],
                                      conf.value,
                                      conf.operator as ValidOperators,
                                      className,
                                      conf.className,
                                    )
                                  },
                                )
                              }
                              return (
                                <tr key={i}>
                                  <td className={className}>
                                    {d.time.format(hasDayJump[recipe] ? 'DD/MM HH:mm:ss' : 'HH:mm:ss')}
                                  </td>
                                  <td className={className}>{d.duration}</td>
                                </tr>
                              )
                            },
                          )
                        )}
                        </tbody>
                      </table>
                    </div>
                  )
                })}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }
    const lastData = { ...this.state.last }

    const result: SingleDataValue[] = []

    if (lastData && lastData[key]) {
      const workshift = this.props.showFullDay ? this.props.fullDay : this.props.workshift
      const startTime = workshift!.start
      const time = moment(startTime).unix()
      result.push({
        x: time,
        y: isBoolean ? (lastData[key][1] ? 1 : 0) : lastData[key][1],
        h100: 100,
      })
    }

    if (stateData && stateData[key] && stateData[key].data) {
      stateData[key].data.forEach((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }
        result.push({
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        })
      })
    }

    return result
  }

  private handleEvaluation(firstValue: string, secondValue: string, condition: ValidOperators) {
    switch (condition) {
      case '!==':
        return firstValue !== secondValue
      case '!=':
        // tslint:disable-next-line:triple-equals
        return firstValue != secondValue
      case '<':
        return firstValue < secondValue
      case '<=':
        return firstValue <= secondValue
      case '==':
        // tslint:disable-next-line:triple-equals
        return firstValue == secondValue
      case '===':
        return firstValue === secondValue
      case '>':
        return firstValue > secondValue
      case '>=':
        return firstValue >= secondValue
      default:
        return false
    }
  }

  private handleRecipeConfig(
    attribute: string,
    valueCompare: string,
    valueToCompare: string,
    operator: ValidOperators,
    originalValue: string,
    desiredValue: string,
  ) {

    if (!validOperators.includes(operator) || isNaN(parseFloat(valueCompare))) {
      return originalValue
    }
    if (attribute === 'perc' && this.handleEvaluation((parseFloat(valueCompare) * 100).toFixed(2).toString(), valueToCompare, operator)) {
      return desiredValue
    } else if (attribute === 'duration' && this.handleEvaluation(moment.duration(valueCompare).asSeconds().toString(), valueToCompare, operator)) {
      return desiredValue
    }
    return originalValue
  }

  /**
   * Get recipes durations and save them to state
   */
  private async getDataRecipe(props: Props, recipes: number[], fullDay?: boolean) {
    const cutOffHour = props.model && props.model.data && props.model.data.model.content.data.cutOffHour
    if (fullDay && props.fullDay && cutOffHour && !this.state.isLoadingRecipe) {
      try {
        this.setState({
          isLoadingRecipe: true,
          fetchErrorsRecipe: false,
          dataRecipe: {},
        })

        const workshift = props.fullDay
        const startTime = workshift.start
        const endTime = workshift.end
        const cutOffHourParts = cutOffHour.split(':')
        const cutOffStartH = parseInt(cutOffHourParts[0], 10) - Math.round(moment().utcOffset() / 60)
        const cutOffStartM = parseInt(cutOffHourParts[1], 10)
        let cutOffEndH = cutOffStartH
        let cutOffEndM = cutOffStartM - 1
        let dayOffset = 1 // the day after if H:M different from midnight

        if (cutOffStartM === 0) {
          cutOffEndM = 59

          if (cutOffEndH > 0) {
            cutOffEndH--
          } else {
            cutOffEndH = 23
            dayOffset = 0 // same day
          }
        }

        const startDateObj = moment(startTime)
        const endDateObj = moment(endTime)
        const startDate = startDateObj
          .utc()
          .set({ hour: cutOffStartH, minute: cutOffStartM, second: 0, millisecond: 0 })
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()
        const endDate = endDateObj
          .utc()
          .add(dayOffset, 'days')
          .set({ hour: cutOffEndH, minute: cutOffEndM, second: 59, millisecond: 999 })
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()

        const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

        const queryStart = `SELECT "measure" FROM "`
        const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
        const queryRicetta = queryStart + 'CURRENT_REC_ACT' + queryEnd
        const dataRicettaSrc = await API().request(`/query?${plantQuery}q=` + queryRicetta, {
          signal: this.abortController.signal,
        })
        let state = Object.assign({}, this.state)

        Promise.all([dataRicettaSrc]).then(() => {
          const recData = parseResponseData(dataRicettaSrc)

          recipes.forEach(r => {
            let last: null | any[] = null

            const recipeData = recData.reduce((acc: any[], cur: any[], index: number, arr: any[]) => {
              if (last === null) {
                last = cur
                return acc
              } else {
                if (cur[1] !== r && last[1] === r) {
                  // use array param
                  const lastMoment = moment(last[0])
                  const duration = moment.utc(moment.duration(moment(cur[0]).diff(lastMoment)).as('milliseconds'))
                  last = cur
                  return acc.concat({
                    time: lastMoment,
                    duration: duration.format('HH:mm:ss'),
                  })
                } else if (cur[1] === r && index === arr.length - 1) {
                  // last item, duration calculated against cutoff time
                  const lastMoment = moment(last[0])
                  const duration = moment.utc(moment.duration(moment.utc(endDate).diff(lastMoment)).as('milliseconds'))
                  last = cur
                  return acc.concat({
                    time: lastMoment,
                    duration: duration.format('HH:mm:ss'),
                  })
                }

                last = cur
                return acc
              }
            }, [])

            recipeData.sort((a: any, b: any) => (a.time < b.time ? -1 : 1))

            state = {
              ...state,
              dataRecipe: {
                ...state.dataRecipe,
                [r]: recipeData,
              },
              isLoadingRecipe: false,
              fetchErrorsRecipe: false,
            }
          })

          if (this.mounted) {
            this.setState(state)
          }
        })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isLoadingRecipe: false,
            fetchErrorsRecipe: true,
            dataRecipe: {},
          })
        }
      }
    } else {
      this.setState({
        isLoadingRecipe: false,
        fetchErrorsRecipe: false,
        dataRecipe: {},
      })
    }
  }

  private async getData(props: Props, fullDay?: boolean) {
    if (((!fullDay && props.workshift) || (fullDay && props.fullDay)) && !this.state.isLoading) {
      try {
        this.setState({ isLoading: true, fetchErrors: false })

        const workshift = fullDay ? props.fullDay : props.workshift
        const startTime = workshift!.start
        const endTime = workshift!.end

        const startDateObj = moment(startTime)
        const endDateObj = moment(endTime)
        const startDate = startDateObj
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()
        const endDate = endDateObj
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()

        const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

        const queryStart = `SELECT "measure" FROM "`
        const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
        const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
        const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`
        // const queryLastAfterStart = ` SELECT first("measure") AS "last_measure" FROM "`
        // const queryLastAfterEnd = `" WHERE time > '${endDate}' LIMIT 1`

        const queryRicetta = queryStart + 'CURRENT_REC_ACT' + queryEnd
        const queryZonaAspirato = queryStart + 'CYC_ASP_ENAB' + queryEnd
        const queryLastRicetta = queryLastStart + 'CURRENT_REC_ACT' + queryLastEnd
        const queryLastZonaAspirato = queryLastStart + 'CYC_ASP_ENAB' + queryLastEnd
        // const queryLastAfterRicetta = queryLastAfterStart + 'CURRENT_REC_ACT' + queryLastAfterEnd
        // const queryLastAfterZonaAspirato = queryLastAfterStart + 'CYC_ASP_ENAB' + queryLastAfterEnd

        const queryDataRicettaSrc = API().request(`/query?${plantQuery}q=` + queryRicetta, {
          signal: this.abortController.signal,
        })
        const queryDataZonaAspiratoSrc = API().request(`/query?${plantQuery}q=` + queryZonaAspirato, {
          signal: this.abortController.signal,
        })
        const queryDataLastRicettaSrc = API().request(`/query?${plantQuery}q=` + queryLastRicetta, {
          signal: this.abortController.signal,
        })
        const queryDataLastZonaAspiratoSrc = API().request(`/query?${plantQuery}q=` + queryLastZonaAspirato, {
          signal: this.abortController.signal,
        })
        // const dataLastAfterRicettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterRicetta, {signal: this.abortController.signal})
        // const dataLastAfterZonaAspiratoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterZonaAspirato, {signal: this.abortController.signal})

        Promise.all([
          queryDataRicettaSrc,
          queryDataZonaAspiratoSrc,
          queryDataLastRicettaSrc,
          queryDataLastZonaAspiratoSrc,
          // dataLastAfterRicettaSrc,
          // dataLastAfterZonaAspiratoSrc,
        ]).then(([
          dataRicettaSrc,
          dataZonaAspiratoSrc,
          dataLastRicettaSrc,
          dataLastZonaAspiratoSrc,
        ]) => {
          if (this.mounted) {
            this.setState({
              data: Object.assign({}, this.state.data, {
                ricetta: { data: parseResponseData(dataRicettaSrc) },
                zonaAspirato: { data: parseResponseData(dataZonaAspiratoSrc) },
              }),
              last: Object.assign({}, this.state.data, {
                ricetta: parseResponseSingleData(dataLastRicettaSrc),
                zonaAspirato: parseResponseSingleData(dataLastZonaAspiratoSrc),
              }),
              /*lastAfter: Object.assign({}, this.state.data, {
                                                          ricetta: parseResponseSingleData(dataLastAfterRicettaSrc),
                                                          zonaAspirato: parseResponseSingleData(dataLastAfterZonaAspiratoSrc),
                                                        }),*/
              startDate: startDateObj.unix(),
              endDate: endDateObj.unix(),
              isLoading: false,
              fetchErrors: false,
            })

            this.prepareData()
          }
        })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log(error) // tslint:disable-line
        if (this.mounted) {
          this.setState({
            isLoading: false,
            fetchErrors: true,
          })
        }
      }
    }
  }

  private prepareData() {
    try {
      let ricetta: any = []
      let zonaAspirato: any = []
      const mergedData: any[] = []

      if (this.state.data) {
        ricetta = this.populateSingleData('ricetta', false)
        zonaAspirato = this.populateSingleData('zonaAspirato', true)

        hydrateData(
          {
            ricetta,
            zonaAspirato,
          },
          mergedData,
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) {
            return -1
          }
          if (a.time > b.time) {
            return 1
          }
          return 0
        })

        hydrateTimeData(['ricetta', 'zonaAspirato'], mergedData, this.state, true)

        if (this.mounted) {
          this.setState({
            isLoading: false,
            fetchErrors: false,
            brush1StartInd: 0,
            brush1EndInd: mergedData.length - 1,
            mergedData,
            filteredData: mergedData.slice(0, mergedData.length - 1),
          })
        }
      }
    } catch (err) {
      console.log(err) // tslint:disable-line
      if (this.mounted) {
        this.setState({
          isLoading: false,
          fetchErrors: true,
        })
      }
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(GeneralGraphsView)))
