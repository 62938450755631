import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { API } from '.'
import { plantQuery } from '../../functions/model'
import * as moment from 'moment'
import { parseResponseSeries } from '../../functions/series'
import { HaltSerie, HaltSerieShiftZone } from '../../types/hatls'
import { Workshift } from '../../types/workshift'

export const HaltsSeries: HaltSerie[] = [
  'vDailyPlantTimeReport',
  'vDailyBulkyStopImpact',
  'vDailyHaltCauses',
  'vDailyBulkyStops',
  'vDailyOpenGates',
  'vDailyManualStops',
  'vDailyUnjustifiedStops',
  'vDailyJustifiedBy',
]

export const HaltSeriesShiftZone : HaltSerieShiftZone[] = [
  'vBulkyStopImpact',
  'vBulkyStops',
  'vHaltCauses',
  'vManualStops',
  'vOpenGates',
  'vPlantTimeReport',
  'vUnjustifiedStops',
  'vAssetsCausingClogging',
  'vJustifiedBy'
]

export const mapColumns = {
  vDailyPlantTimeReport:
    'time, morning_delay_duration,  morning_delay_events, morning_delay_perc, empty_duration, empty_events, empty_perc, pause_delay_duration, pause_delay_events, pause_delay_perc,bulky_duration, bulky_events, bulky_perc, other_duration, other_events, other_perc, runningTime_duration, runningTime_perc',
  vDailyHaltCauses:
    'time, ManualStop_duration, ManualStop_events, ManualStop_perc, DelayedStart_duration,  DelayedStart_events,  DelayedStart_perc, Alarm_duration, Alarm_events, Alarm_perc, OpenGate_duration, OpenGate_events, OpenGate_perc, ' +
    'Clog_duration, Clog_events, Clog_perc, BunkerFull_duration, BunkerFull_events, BunkerFull_perc, Empty_duration, Empty_events, Empty_perc, Pause_duration, Pause_events, Pause_perc, Emergency_duration, Emergency_events, Emergency_perc,PaidBreak_duration,PaidBreak_events, PaidBreak_perc, Unhandled_duration,Unhandled_events,Unhandled_perc,dummy',
  vDailyManualStops:
    'time, ManSftyLock_duration, ManSftyLock_events, ManHmiStp_duration, ManHmiStp_events, ManSelClean_duration, ManSelClean_events, ManHmiPause_duration, ManHmiPause_events, ManLoadRec_duration, ManLoadRec_events, ManDevStp_duration, ManDevStp_events, None_duration, None_events,dummy',
  vDailyUnjustifiedStops:
    'time, dummy, unjustified_duration, unjustified_events, unjustified_perc, other_duration, other_events, other_perc',
  vDailyJustifiedBy:
    'time, dummy, justified_by_system_duration, justified_by_system_events, justified_by_system_perc, justified_by_users_duration, justified_by_users_events, justified_by_users_perc',
}

export const mapColumnsShiftZone = {

}
export const haltsIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_HALTS_IS_FETCHING',
  }
}

export const haltsFetchSuccess = (halts: any): AnyAction => {
  return {
    type: 'PLANTANALYSIS_HALTS_FETCH_SUCCESS',
    payload: halts,
  }
}

export const haltsFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_HALTS_FETCH_ERROR',
    payload: error,
  }
}

export const haltsFetchData = (
  dateFilterSrc: Workshift, // moment.Moment
  processing?: number
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  return async (dispatch, getState) => {
    const plantQueryString = plantQuery(getState)
    const startDate = moment(dateFilterSrc.start)
        .utc()
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    const endDate = moment(dateFilterSrc.end)
        .utc()
        .set({ h: 23, m: 59, s: 59 })
        // .add(fetchDayAfter ? 1 : 0, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

    const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`

    const queries = HaltsSeries.map(
      e => `${plantQueryString}q=SELECT ${mapColumns[e] ? mapColumns[e] : '*'} FROM ` + `"${e + (processing ? '_' + processing : '')}"` + queryEnd
    )

    dispatch(haltsIsFetching())
    try {
      return Promise.all(queries.map(q => API().request(`/query?${q}`))).then(results => {
        const out = {}
        HaltsSeries.forEach((e, i) => {
          out[e] = parseResponseSeries(e, results[i])
        })
        dispatch(haltsFetchSuccess(out))

        return out
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(haltsFetchError(error))
      throw error
    }
  }
}
