import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AssetModulesStatus, AssetTask } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Table } from '@mv-submodules/inplant-components-fe'
import { RowInfo } from 'react-table'
import * as moment from 'moment'
import { renderStatus } from '@mv-submodules/inplant-asset-manager-fe/functions/shared'

interface OwnState {
  currentValue: string
}

interface StateProps {
  assetModulesStatus: AssetModulesStatus,
  clickAssetTask?: (assetTask: AssetTask) => void
}

type Props = StateProps & WithTranslation

class AssetModulesStatusComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentValue: 'chrono-frame',
    }
    this.handleChangeModule = this.handleChangeModule.bind(this)
  }

  public render() {
    const { t, assetModulesStatus } = this.props
    const { currentValue } = this.state
    return (
      <div className="card card-asset-tasks-status mt-2">
        <ul className="nav nav-tabs">
          {Object.keys(assetModulesStatus).map((key: string) => {
            if (assetModulesStatus[key]) {
              return (
                <li className="nav-item" key={`tab_head_${key}`}>
                  <a
                    onClick={() => this.handleChangeModule(key)}
                    className={`nav-link ${key === currentValue ? 'active' : 'nav-asset-modules-tab-not-active'}`}
                  >
                    {assetModulesStatus[key].name}
                  </a>
                </li>
              )
            }
            return null
          })}
        </ul>
        <div className="card-body">
          {
            currentValue === 'chrono-frame' ? (
              <Table
                data={assetModulesStatus['chrono-frame'] && assetModulesStatus['chrono-frame'].values || []}
                columns={[
                  {
                    Cell: ({ original }: RowInfo) => original.date && moment(original.date).format('DD/MM/YYYY') || '',
                    Header: t('assetManager.table.header.deadline'),
                    accessor: 'date',
                    sortable: true,
                    maxWidth: 150,
                    id: 'date',
                  },
                  {
                    Cell: ({ original }: RowInfo) => {
                      const dueAtDate = original.date
                      const deadlineDateDiff = moment(dueAtDate).diff(
                        moment().startOf('day'),
                        'days',
                      )
                      return (
                        <div
                          className="rt-td-cell"
                        >
                          {renderStatus(original.status, deadlineDateDiff === 0 ? t(`chronoframe.dueDates.tableList.today`) :
                            deadlineDateDiff === 1 ? t(`chronoframe.dueDates.tableList.tomorrow`) :
                              deadlineDateDiff === -1 ? t(`chronoframe.dueDates.tableList.yesterday`) :
                                deadlineDateDiff > 0 ? (
                                  deadlineDateDiff < 10 ? `${deadlineDateDiff} ${t(
                                    `chronoframe.dueDates.tableList.multipleDays`,
                                  )}` : deadlineDateDiff < 32 ? `${deadlineDateDiff} ${t(
                                    `chronoframe.dueDates.tableList.multipleDays`,
                                  )}` : deadlineDateDiff < 366 ? `${Math.floor(deadlineDateDiff / 31)} ${
                                    Math.floor(deadlineDateDiff / 31) === 1
                                      ? t(`chronoframe.dueDates.tableList.singleMonth`)
                                      : t(`chronoframe.dueDates.tableList.multipleMonths`)
                                  }` : `${Math.floor(deadlineDateDiff / 365)} ${
                                    Math.floor(deadlineDateDiff / 365) === 1
                                      ? t(`chronoframe.dueDates.tableList.singleYear`)
                                      : t(`chronoframe.dueDates.tableList.multipleYears`)
                                  }`
                                ) : -deadlineDateDiff < 32 ? `${-deadlineDateDiff} ${t(
                                  `chronoframe.dueDates.tableList.multipleDays`,
                                )}` : -deadlineDateDiff < 366 ? `${Math.floor(-deadlineDateDiff / 31)} ${
                                  Math.floor(-deadlineDateDiff / 31) === 1
                                    ? t(`chronoframe.dueDates.tableList.singleMonth`)
                                    : t(`chronoframe.dueDates.tableList.multipleMonths`)
                                }` : `${Math.floor(-deadlineDateDiff / 365)} ${
                                  Math.floor(-deadlineDateDiff / 365) === 1
                                    ? t(`chronoframe.dueDates.tableList.singleYear`)
                                    : t(`chronoframe.dueDates.tableList.multipleYears`)
                                }`)}
                        </div>
                      )
                    },
                    Header: t('assetManager.table.header.status'),
                    accessor: 'status',
                    maxWidth: 150,
                    sortable: true,
                    id: 'status',
                  },
                  {
                    Cell: ({ original }: RowInfo) => original.taskId,
                    Header: t('assetManager.table.header.id'),
                    accessor: 'taskId',
                    sortable: true,
                    maxWidth: 175,
                    id: 'taskId',
                  },
                  {
                    Cell: ({ original }: RowInfo) => original.ambit || '',
                    Header: t('assetManager.table.header.ambit'),
                    accessor: 'ambit',
                    sortable: true,
                    maxWidth: 200,
                    id: 'ambit',
                  },
                  {
                    Cell: ({ original }: RowInfo) => original.category || '',
                    Header: t('assetManager.table.header.category'),
                    accessor: 'category',
                    sortable: true,
                    id: 'category',
                  },
                  {
                    Cell: ({ original }: RowInfo) => original.subCategory || '',
                    Header: t('assetManager.table.header.subCategory'),
                    accessor: 'category',
                    sortable: true,
                    id: 'category',
                  },
                  {
                    Cell: ({ original }: RowInfo) => original.activity || '',
                    Header: t('assetManager.table.header.activity'),
                    accessor: 'activity',
                    sortable: true,
                    id: 'activity',
                  },
                ]}
                isFetching={false}
                noDataText={t(`assetManager.table.noData`)}
                ofText={t('assetManager.table.of')}
                pageText={t('assetManager.table.page')}
                onRowClick={this.props.clickAssetTask ? this.props.clickAssetTask : undefined}
                rowsText={t('assetManager.table.rows')}
                manual={false}
                pages={0}
                pageSize={10}
                showPagination={true}
              />
            ) : (<p className="text-center">{t('assetManager.table.noData')}</p>)
          }
        </div>
      </div>
    )
  }

  private handleChangeModule(currentValue: string) {
    this.setState({ currentValue })
  }
}

export default (withTranslation()(AssetModulesStatusComponent))