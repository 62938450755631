import React, { useEffect, useState } from 'react'
import { Loader } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'

export interface TabItem {
  label: string
  disabled?: boolean
  forceEnabled?: boolean
  component?: React.ReactNode
}

export const TabbedWrapper = (props: {
  changedKey?: string
  component?: string,
  loading?: boolean,
  tabs: TabItem[],
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [currentKey, setCurrentKey] = useState('')
  const url = new URL(window.location.href)
  const allOpened = url.searchParams.get('allOpened')
  const {tabs, loading, changedKey} = props

  const changeActiveTab = (t: number) => {
    setActiveTab(t)
    return false
  }

  useEffect(() => {
    if(activeTab > tabs.length || currentKey !== changedKey ) {
      setActiveTab(0)
    }
    if(changedKey !== undefined && currentKey !== changedKey) {
      setCurrentKey(changedKey)
    }
  }, [tabs, changedKey]);

  return (
    <div className={'tabbed-wrapper ' + (allOpened ? 'all-opened' : '')}>
      <Tabs tabs={tabs} currentTabID={activeTab} clickCallback={changeActiveTab} loading={loading} />
      <TabContent tabs={tabs} currentTabID={activeTab} />
    </div>
  )
}

const Tabs = (props: {
  clickCallback: (tab: number) => void,
  currentTabID?: number,
  loading?: boolean,
  tabs: TabItem[],
}) => {
  const {tabs, currentTabID, clickCallback, loading} = props

  return (
    tabs && tabs.length > 0 ? <ul className='nav nav-tabs all-opened__closed'>
      {
        tabs.map((t, i) =>
          <li className='nav-item' key={t.label + i}>
            <a
              className={
                'nav-link ' +
                (i === currentTabID ? 'active' : '') +
                (t.disabled ? 'disabled' : '')
              }
              aria-current={i === currentTabID ? 'page' : undefined}
              aria-disabled={i === currentTabID ? 'true' : undefined}
              onClick={!t.disabled ? () => clickCallback(i) : undefined}
            >{t.label}</a>
          </li>
        )
      }
      {
        loading &&  <span className="mt-2"><Loader /></span>
      }
    </ul> : null
  )
}

const TabContent = (props: {
  currentTabID?: number,
  tabs: TabItem[],
}) => {
  const {tabs, currentTabID} = props

  return (
    tabs && tabs.length > 0 ?
      <div
        className="tabbed-wrapper__content"
      >
        {
          tabs.map((tab, i) => <div
            key={tab.label + i}
            className={"page-break-inside-avoid tab-content " + (currentTabID === i ? 'd-block' : 'tab-content__hide all-opened__open')}
          >
            <div className="py-4">
              <h3 className="d-none all-opened__open sub-title">{tab.label}</h3>
              {tab.component}
            </div>
          </div>)
        }
      </div> : null
  )
}
