import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import {
  extractResponseData,
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  populateManAutoLocSec,
} from '../../../../../functions/series'
import { AreaSection, GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import moment from 'moment'
import { getAreaSections, HHHmmssFromMilliseconds, Loader, reduceWarnAreas } from '../../../../../functions/shared'
import { logger } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { API } from '../../../../../redux/actions'
import FetchError from '../../../../../../inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableRowWrapper
  , {} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

export type VecoplantTypes = 'test'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: VecoplantTypes[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  mills: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataMills: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
})

class MillsView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      mills: {},
      fetching: false,
      error: false,
      dataMills: null,
      data: null,
      filteredData: [],
      mergedData: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    const warnPoints: AreaSection[] | undefined = ([{}] as [{}]) &&
      getAreaSections(this.state.filteredData, 'warnArea').filter(e => e.value === 100)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <>
        <WithConditionWrapper condition={!fetching && error}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </WithConditionWrapper>
        <WithConditionWrapper
          condition={!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data)}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
        </WithConditionWrapper>
        <WithConditionWrapper condition={!error}>
          <WithConditionWrapper condition={!hiddenCharts || !hiddenCharts.includes('test')}>
            <WithConditionWrapper condition={!this.state.fetching} onError={<><h3
              className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
              <Loader /></>}>
              <WithConditionWrapper condition={this.state.data !== undefined}>
                <TableRowWrapper>
                  <TableColumnWrapper className={'py-2'} col={10}>
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.mills.mill1')}</h3>
                    <ResponsiveContainer width='100%' height={400}>
                      <LineChart
                        height={400}
                        data={this.state.filteredData}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <XAxis
                          key={'line1'}
                          hide={false}
                          dataKey='time'
                          label={''}
                          height={30}
                          axisLine={true}
                          tickLine={false}
                          tick={true}
                          interval={'preserveStartEnd'}
                          minTickGap={50}
                          domain={['dataMin', 'dataMax']}
                          tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                          type={'category'}
                        />
                        <CartesianGrid stroke='#f5f5f5' />

                        {warnAreas}
                        <YAxis
                          orientation={'left'}
                          yAxisId={'A'}
                          axisLine={false}
                          tickFormatter={val => parseInt(val, 10)}
                        />
                        <Line
                          dataKey='mill1Thr1'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill1Thr2'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill1Thr3'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill1Absorption'
                          stroke={COLORS.lines.mills.mill}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className='d-print-inline-flex'>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.lines.mills.mill }}
                          />
                          <span>{t('plantAnalysis.lines.mills.label.mill1Absorption')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer1Threshold')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer2Threshold')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer5Threshold')}</span>
                        </span>
                    </div>
                  </TableColumnWrapper>
                  <TableColumnWrapper className={'py-2'} col={2}>
                    <table
                      className={
                        'table table-borderless table-sm w-50 mw-300 font-size-12 table-first-column-color'
                      }
                    >
                      <thead>
                      <tr>
                        <th />
                        <th className='text-right'>{t('plantAnalysis.lines.mills.table.overValue')}</th>
                        <th className='text-right'>{t('plantAnalysis.lines.mills.table.overTimeValue')}</th>
                      </tr>
                      </thead>
                      {this.state.data?.mills && (
                        <tbody>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr1')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f024_count_over_first_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f024_seconds_over_first_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr2')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f024_count_over_second_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f024_seconds_over_second_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr3')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f024_count_over_third_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f024_seconds_over_third_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        </tbody>
                      )}
                    </table>
                  </TableColumnWrapper>
                </TableRowWrapper>
                <TableRowWrapper>
                  <TableColumnWrapper className={'py-2'} col={10}>
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.mills.mill2')}</h3>
                    <ResponsiveContainer width='100%' height={400}>
                      <LineChart
                        // width={700}
                        height={400}
                        data={this.state.filteredData}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <XAxis
                          key={'line1'}
                          hide={false}
                          dataKey='time'
                          label={''}
                          height={30}
                          axisLine={true}
                          tickLine={false}
                          tick={true}
                          interval={'preserveStartEnd'}
                          minTickGap={50}
                          domain={['dataMin', 'dataMax']}
                          tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                          type={'category'}
                        />
                        <CartesianGrid stroke='#f5f5f5' />

                        {warnAreas}
                        <YAxis
                          orientation={'left'}
                          yAxisId={'A'}
                          axisLine={false}
                          tickFormatter={val => parseInt(val, 10)}
                        />
                        <Line
                          dataKey='mill2Thr1'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill2Thr2'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill2Thr3'
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey='mill2Absorption'
                          stroke={COLORS.lines.mills.mill}
                          dot={false}
                          strokeWidth={2}
                          type='step'
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className='d-none d-print-inline-flex'>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.lines.mills.mill }}
                          />
                          <span>{t('plantAnalysis.lines.mills.label.mill2Absorption')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer1Threshold')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer2Threshold')}</span>
                        </span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            className='label-color-square mx-3'
                            style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                          />
                          <span>{t('plantAnalysis.labels.buffer5Threshold')}</span>
                        </span>
                    </div>
                  </TableColumnWrapper>
                  <TableColumnWrapper className={'py-2'} col={2}>
                    <table
                      className={
                        'table table-borderless table-sm w-50 mw-300 font-size-12 table-first-column-color'
                      }
                    >
                      <thead>
                      <tr>
                        <th />
                        <th className='text-right'>{t('plantAnalysis.lines.mills.table.overValue')}</th>
                        <th className='text-right'>{t('plantAnalysis.lines.mills.table.overTimeValue')}</th>
                      </tr>
                      </thead>
                      {this.state.data?.mills && (
                        <tbody>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr1')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f033_count_over_first_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f033_seconds_over_first_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr2')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f033_count_over_second_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f033_seconds_over_second_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr3')}</td>
                          <td className='text-right'>
                            {parseInt(
                              this.state.data?.mills[0]?.['mill_f033_count_over_third_threshold'] as string, // tslint:disable-line
                              10,
                            )}
                          </td>
                          <td className='text-right'>
                            {HHHmmssFromMilliseconds(
                              (this.state.data?.mills[0]?.['mill_f033_seconds_over_third_threshold'] as number) * 1000, // tslint:disable-line
                              false,
                              true,
                              false,
                            )}
                          </td>
                        </tr>
                        </tbody>
                      )}
                    </table>
                  </TableColumnWrapper>
                </TableRowWrapper>
              </WithConditionWrapper>
            </WithConditionWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let mill1Thr1: any = []
      let mill1Thr2: any = []
      let mill1Thr3: any = []
      let mill1Absorption: any = []
      let mill2Thr1: any = []
      let mill2Thr2: any = []
      let mill2Thr3: any = []
      let mill2Absorption: any = []
      const mergedData: any[] = []
      if (this.state.data) {
        mill1Thr1 = this.populateSingleData('mill1Thr1')
        mill1Thr2 = this.populateSingleData('mill1Thr2')
        mill1Thr3 = this.populateSingleData('mill1Thr3')
        mill1Absorption = this.populateSingleData('mill1Absorption')
        mill2Thr1 = this.populateSingleData('mill2Thr1')
        mill2Thr2 = this.populateSingleData('mill2Thr2')
        mill2Thr3 = this.populateSingleData('mill2Thr3')
        mill2Absorption = this.populateSingleData('mill2Absorption')
      }
      hydrateData(
        {
          mill1Thr1,
          mill1Thr2,
          mill1Thr3,
          mill1Absorption,
          mill2Thr1,
          mill2Thr2,
          mill2Thr3,
          mill2Absorption,
        },
        mergedData,
        undefined,
        0,
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(
        [
          'mill1Thr1',
          'mill1Thr2',
          'mill1Thr3',
          'mill1Absorption',
          'mill2Thr1',
          'mill2Thr2',
          'mill2Thr3',
          'mill2Absorption',
        ],
        mergedData,
        this.state,
      )
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          fetching: false,
          error: false,
        })
      }
    } catch (error) {
      logger('MillsView.tsx', 'constructData', `Error calculation:  ${error}`, 'server')
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private formatData(data: any) {
    const resultData = data.columns
    try {
      const result = resultData
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
              // [value]: data.values[0][index]
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
      return [result]
    } catch (error) {
      return []
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workShift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const startDate = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const endDate = workshift?.end
        ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
        : endOfDay
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryEndShiftTime = ` WHERE time <= '${startDate}' ORDER BY desc LIMIT 1`
      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {

        const mill1ThrLabel = 'IBSGN5PHL2L16TOG'
        const mill1AbsorptionLable = 'IBSGN5PHL2L16TOG-1'
        const mill2ThrLabel = 'IBSGN5PHL2LHTFBK'
        const mill2AbsorptionLable = 'IBSGN5PHL2LHTFBK-1'
        const millsLabel = 'vMills'

        const queryStart = `SELECT "measure" FROM `
        const queryStartFillingBuffer = `SELECT * FROM `

        const mill1Thr1Values = queryStart + `"${mill1ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill1Thr1ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill1Thr2Values = queryStart + `"${mill1ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill1Thr2ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill1Thr3Values = queryStart + `"${mill1ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill1Thr3ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill1AbsorptionValues = queryStart + `"${mill1AbsorptionLable}_ACT_CUR_calc"` + queryEnd

        const mill2Thr1Values = queryStart + `"${mill2ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill2Thr1ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill2Thr2Values = queryStart + `"${mill2ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill2Thr2ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill2Thr3Values = queryStart + `"${mill2ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill2Thr3ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill2AbsorptionValues = queryStart + `"${mill2AbsorptionLable}_ACT_CUR_calc"` + queryEnd

        const millsValue = queryStartFillingBuffer + `${millsLabel}` + queryEndShift

        const dataMill1Thr1Values = API().request(`/query?${plantQueryString}q=` + mill1Thr1Values, { signal: this.abortController.signal })
        const dataMill1Thr2Values = API().request(`/query?${plantQueryString}q=` + mill1Thr2Values, { signal: this.abortController.signal })
        const dataMill1Thr3Values = API().request(`/query?${plantQueryString}q=` + mill1Thr3Values, { signal: this.abortController.signal })
        const dataMill1AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill1AbsorptionValues, { signal: this.abortController.signal })
        const dataMill2Thr1Values = API().request(`/query?${plantQueryString}q=` + mill2Thr1Values, { signal: this.abortController.signal })
        const dataMill2Thr2Values = API().request(`/query?${plantQueryString}q=` + mill2Thr2Values, { signal: this.abortController.signal })
        const dataMill2Thr3Values = API().request(`/query?${plantQueryString}q=` + mill2Thr3Values, { signal: this.abortController.signal })
        const dataMill1Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr1ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill1Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr2ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill1Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr3ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill2Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr1ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill2Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr2ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill2Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr3ValuesBeforeShift, { signal: this.abortController.signal },
        )
        const dataMill2AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill2AbsorptionValues, { signal: this.abortController.signal })

        const dataMillsValues = API().request(`/query?${plantQueryString}q=` + millsValue, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataMill1Thr1Values,
          dataMill1Thr2Values,
          dataMill1Thr3Values,
          dataMill1Thr1ValuesBeforeShift,
          dataMill1Thr2ValuesBeforeShift,
          dataMill1Thr3ValuesBeforeShift,
          dataMill1AbsorptionValues,
          dataMill2Thr1Values,
          dataMill2Thr2Values,
          dataMill2Thr3Values,
          dataMill2Thr1ValuesBeforeShift,
          dataMill2Thr2ValuesBeforeShift,
          dataMill2Thr3ValuesBeforeShift,
          dataMill2AbsorptionValues,
          dataMillsValues,
        ])
          .then(
            ([
               dataMill1Thr1ValuesResult,
               dataMill1Thr2ValuesResult,
               dataMill1Thr3ValuesResult,
               dataMill1Thr1ValuesBeforeShiftResult,
               dataMill1Thr2ValuesBeforeShiftResult,
               dataMill1Thr3ValuesBeforeShiftResult,
               dataMill1AbsorptionValuesResult,
               dataMill2Thr1ValuesResult,
               dataMill2Thr2ValuesResult,
               dataMill2Thr3ValuesResult,
               dataMill2Thr1ValuesBeforeShiftResult,
               dataMill2Thr2ValuesBeforeShiftResult,
               dataMill2Thr3ValuesBeforeShiftResult,
               dataMill2AbsorptionValuesResult,
               dataMillsValuesResult,
             ]) => {
              if (this.mounted) {
                try {
                  const mill1Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill1Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill1Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  this.setState({
                    data: Object.assign({}, this.state.data, {
                      mill1Thr1: { data: mill1Thr1 },
                      mill1Thr2: { data: mill1Thr2 },
                      mill1Thr3: { data: mill1Thr3 },
                      mill1Absorption: { data: extractResponseData(dataMill1AbsorptionValuesResult) },
                      mill2Thr1: { data: mill2Thr1 },
                      mill2Thr2: { data: mill2Thr2 },
                      mill2Thr3: { data: mill2Thr3 },
                      mill2Absorption: { data: extractResponseData(dataMill2AbsorptionValuesResult) },
                      mills: this.formatData(JSON.parse(dataMillsValuesResult).results[0].series[0]),
                    }),
                  }, () => {
                    this.constructData()
                  })
                } catch (error) {
                  logger('MillsView.tsx', 'getData parsingResult', `Error on parsing result: ${error}`, 'server')
                  this.setState({
                    data: null,
                    mills: {},
                    fetching: false,
                    error: false,
                  })
                }
              }
            },
          )
          .catch((error: FetchError) => {
            logger('MillsView.tsx', 'getData', `Error on fetch: ${error.message}, StatusCode: ${error.statusCode}, Errors:${error.errors}`, 'server')
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              mills: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        logger('MillsView.tsx', 'getData', `Error on try catch line:  ${error}`, 'server')
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          mills: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(MillsView))
