import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import FormModal from './FormModal'
import { FormData, Fieldset } from '../../../../types/pageForm'

interface OwnProps {
  visible: boolean
  title: string
  fields: Fieldset[]
  type: 'create' | 'modify'
  onClose: (udpate?: boolean) => void
  onSubmit: (data: FormData) => Promise<FormData>
  submitTemplate?: (data: FormData) => Promise<any>
  data?: FormData
  pageConfigUpdateData?: (destination?: string) => any
}

type Props = OwnProps & WithTranslation

const TaskFormModal = (props: Props) => {
  return (
    <FormModal
      {...props}
      submitLabel={props.t('chronoframe.tasks.form.save')}
      cancelLabel={props.t('chronoframe.tasks.form.cancel')}
      pageConfigUpdateData={props.pageConfigUpdateData}
      type={props.type}
    />
  )
}

export default withTranslation()(TaskFormModal)
