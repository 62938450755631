import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'

interface OwnProps {
  data: any
}

type Props = OwnProps & WithTranslation

class BunkerGraphHeaderView extends React.PureComponent<Props> {
  public render() {
    const labels = [
      ['LAST_BUK_CLEAN1', 'LAST_BUK_CLEAN2', 'LAST_BUK_CLEAN3'],
      ['LAST_BUK_GATE_OPN1', 'LAST_BUK_GATE_OPN2', 'LAST_BUK_GATE_OPN3'],
    ]

    if (!this.props.data || (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object)) {
      return null
    }

    return (
      <header className="bunker-graph-header">
        <div className="flex-row justify-content-between">
          <div className="row">
            {labels.map((section: any[], labelIndex: number) => (
              <div className="col-md-4" key={labelIndex}>
                <ul>
                  {section.map((l: string, index: number) => {
                    const data = this.props.data.find((d: any) => d[0] && d[0].name === l)
                    return (
                      <li key={index}>
                        <span className="label">
                          {this.props.t('plantAnalysis.labels.bunker.header.' + l, { defaultValue: l })}
                        </span>
                        <span className="value">
                          {data &&
                            data[0] &&
                            data[0].values &&
                            data[0].values[0] &&
                            moment.utc(data[0].values[0][1]).format('DD/MM/YYYY HH:mm:ss')}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </header>
    )
  }
}

export default withTranslation()(BunkerGraphHeaderView)
