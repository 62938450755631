import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import * as qs from 'qs'
import {
  Titech,
  TitechDetailProps,
  TitechLog,
  TitechLogProps,
} from '../../types/titech'
import { API } from './index'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'


export const fetchDetail = (id: string, plant?: string):
  ((dispatch: Dispatch) => Promise<Titech>) => (dispatch: Dispatch) => API().request(
  `/titech/${id}${plant && plant !== '' ? '?plant=' + plant : ''}`,
).then((data: TitechDetailProps) => new Titech(data))
  .catch((error: FetchError) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  })

export const fetchLogs = (id: string, from: string, to: string):
  ((dispatch: Dispatch) => Promise<TitechLog[]>) => (dispatch: Dispatch) => API().request(
  `/titech/${id}/logs?${qs.stringify({ from, to })}`,
).then((logs: TitechLogProps[]) => logs.map(l => new TitechLog(l)))
  .catch((error: FetchError) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  })