import * as moment from 'moment'
import { getInputValueFromData } from '../TaskFormModal/utils'
import { ChronoTask } from '../../../../types/chronoTasks'
import { DetailField } from '../../../../types/pageDetail'

interface Props {
  data?: ChronoTask
  fields: DetailField[]
}

const TaskTitle = ({ data, fields }: Props): string => {
  return data ? fields.map(field => {
    switch(field.type) {
      case "text":
      case "number":
        return getInputValueFromData(field.slug, data) || ''
      case "date":
        const date = getInputValueFromData(field.slug, data)
        return date ? moment(date).format('DD/MM/YYYY') : ''
      default:
        return ''
    }
  }).join(' ') : '-'
}

export default TaskTitle
