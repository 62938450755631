import { AnyAction } from 'redux'
import { ChronoTask } from '../../types/chronoTasks'
import { FormData } from '../../types/pageForm'
import { Resize } from 'react-table'
import {
  CHRONO_FRAME_LIST_SET_PAGINATION,
  CHRONO_FRAME_SAVE_LAST_APPLIED_FILTER,
  CHRONO_FRAME_SAVE_SEARCH_STRING,
  CHRONO_FRAME_TASK_LIST_SET_FILTERS,
  CHRONO_FRAME_UPDATE_FILTER_MODAL,
  CHRONO_FRAME_UPDATE_FILTERED_IDS,
  CHRONO_FRAME_LIST_SET_RESIZE_COLUMN,
} from '@mv-submodules/inplant-chronoframe-fe/redux/actions/pageList'
import { ListPagination } from '@mv-submodules/inplant-chronoframe-fe/types/pageList'

interface PageListState {
  isFetching: boolean
  data?: ChronoTask[]
  filters?: {
    [k: string]: FormData
  }
  error?: Error
  resized: Resize[]
  pageSize: {
    [k: string]: number
  }
  pageNumber: {
    [k: string]: number
  }
  pagination:
    | {
        [k: string]: ListPagination | undefined
      }
    | undefined
  lastAppliedFilter: string
  // searchString: string
  tableFilteredIds?: string[]
  updateFilterModal?: boolean
  columnResize?: Record<string, Record<string, any>>
}

const initialState: PageListState = {
  isFetching: false,
  resized: [],
  pageSize: {},
  pageNumber: {},
  lastAppliedFilter: '',
  tableFilteredIds: [],
  filters: {},
  pagination: undefined,
  updateFilterModal: false,
}

export function pageList(state: PageListState = initialState, action: AnyAction): PageListState {
  switch (action.type) {
    case CHRONO_FRAME_LIST_SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      }
    case CHRONO_FRAME_TASK_LIST_SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }

    case 'CHRONO_FRAME_TASKS_ASSIGNMENT_SUCCESS':
      return {
        ...state,
        data:
          state.data &&
          state.data.map(task =>
            action.ids.includes(task.id) ? { ...task, assigneeUser: action.assigneeUser } : task
          ),
      }

    case CHRONO_FRAME_SAVE_LAST_APPLIED_FILTER:
      return {
        ...state,
        lastAppliedFilter: action.appliedFilter,
      }

    case CHRONO_FRAME_SAVE_SEARCH_STRING:
      const newFilters = state.filters || {}
      newFilters.searchString = action.searchString
      return {
        ...state,
        filters: newFilters,
      }

    case CHRONO_FRAME_UPDATE_FILTERED_IDS:
      return {
        ...state,
        tableFilteredIds: action.ids,
      }

    case CHRONO_FRAME_UPDATE_FILTER_MODAL:
      return {
        ...state,
        updateFilterModal: !state.updateFilterModal,
      }

    case CHRONO_FRAME_LIST_SET_RESIZE_COLUMN:
      const currentSettings = state.columnResize?.[action.columnSize.tableId]
        ? state.columnResize?.[action.columnSize.tableId]
        : {}
      return {
        ...state,
        columnResize: {
          ...state.columnResize,
          [action.columnSize.tableId]: {
            ...currentSettings,
            ...action.columnSize.columSize.reduce(
              (acc: Record<string, any>, curr: Resize) => ({ ...acc, [curr.id]: curr.value }),
              {}
            ),
          },
        },
      }
    default:
      return state
  }
}
