import { AnyAction } from "redux";
import { ListStoreSlice } from "../../types/store";
// import { TableParams } from "../../types/table";
import {
  PlantHalts,
  PlantHalt,
  PlantHaltProps,
  PlantHaltCause,
  PlantHaltFilters
} from "../../types/plantHalts";
import { APIError } from "../../types/api";

const initialState = {
  causes: null,
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false,
  submitErrors: null,
  sidebarCounters: [
    {
      pageSlug: "unchecked",
      color: "danger",
      count: 0
    }
  ]
};

interface PlantHaltsStoreSlice
  extends ListStoreSlice<PlantHalts, PlantHaltFilters> {
  causes: PlantHaltCause[] | null;
  submitErrors: null | APIError;
}

type PlantHaltsReducer = (
  state: PlantHaltsStoreSlice,
  action: AnyAction
) => PlantHaltsStoreSlice;

export const plantHalts: PlantHaltsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      try {
        const oldState = action.payload.plantHalts.plantHalts;
        return {
          ...oldState,
          data: oldState.data
            ? Object.assign(oldState.data, {
              halts: oldState.data.halts.map(
                (raw: PlantHaltProps) => new PlantHalt(raw)
              )
            })
            : null
        };
      } catch {
        return state;
      }
    case "PLANT_HALT_LIST_IS_FETCHING":
      return {
        ...state,
        params: action.params,
        data: null,
        isFetching: true
      };
    case "PLANT_HALT_LIST_FETCH_SUCCESS":
      const dataWithCauses = state.causes
        ? {
          ...action.data,
          halts: action.data.halts.map((halt: PlantHalt) =>
            Object.assign(halt, {
              cause: state.causes!.find(
                ({ key }: PlantHaltCause) => halt.causeKey === key
              )
            })
          )
        }
        : action.data;
      return {
        ...state,
        data: dataWithCauses,
        error: null,
        isFetching: false
      };
    case "PLANT_HALT_LIST_FETCH_ERROR":
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    case "PLANT_HALT_LIST_APPLY_PARAMS":
      return {
        ...state,
        params: action.params
      };
    case "PLANT_HALT_CAUSES_FETCH_SUCCESS":
      const dataWithHalts = state.data && {
        ...state.data,
        halts: state.data.halts.map((halt: PlantHalt) =>
          Object.assign(halt, {
            cause: action.causes.find(
              ({ key }: PlantHaltCause) => halt.causeKey === key
            )
          })
        )
      };
      return {
        ...state,
        causes: action.causes,
        data: dataWithHalts
      };
    case "PLANT_HALT_SUBMIT_ERROR":
      return {
        ...state,
        submitErrors: action.error
      };

    case "PLANT_HALT_SUBMIT_SUCCESS":
      if (state.params && state.params.confirmed === false) {
        return {
          ...state,
          submitErrors: null,
          data: {
            ...state.data,
            halts: state.data!.halts.filter(
              (halt: PlantHalt) => halt.id !== action.data.id
            )
          }
        };
      }
      return {
        ...state,
        submitErrors: null,
        data: {
          ...state.data,
          halts: state.data!.halts.map(
            (halt: PlantHalt) =>
              halt.id === action.data.id ? action.data : halt
          )
        }
      };
    case "PLANT_HALT_FETCH_UNJUSTIFIED_COUNTER":
      return {
        ...state,
        sidebarCounters: [
          {
            pageSlug: "unchecked",
            color: "danger",
            count: action.data
          }
        ]
      };
    default:
      return state;
  }
};
