const en = {
  navigation: {
    titech: 'Titech',
    list: 'Lista',
    setup: 'Setup'
  },
  pageHeader: {
    back: 'Back',
  },
  setup: {
    title: "Setup Titech",
    status: "Status",
    assetCode: "Code",
    beltId: "Belt",
    positionCode: "Plant",
    ipAddress: "IP Address",
    username: "Username",
    password: "Password",
    logFileFolder: "Log file folder",
    recipeFileName: "Recipe file folder",
    calibrationFrequency: "Calibration Frequency",
    model: "Model",
    noData: 'No data to display',
    searchPlaceholder: 'Search by code or model',
    actions: '',
    configure: 'Configure',
    statusLabels: {
      tobeconfigured: "TO BE CONFIGURED",
      deactivated: "DEACTIVATED",
      active: "ACTIVE AND CONFIGURED",
      tobevalidated: "TO BE VALIDATE",
      undefined: "UNDEFINED"
    },
    modal:{
      title: 'Change asset configuration',
      close: 'Cancel',
      save: 'Save',
      checkConnection: 'Check',
      validateBeforeSave: 'Check configuration before Save'
    },
    notifications: {
      valid: 'The test was successful',
      invalid: 'The verification failed',
      saved: 'Configuration saved',
      notSaved: 'There was an error saving the configuration'
    },
  },
  list: {
    title: 'Titech List',
    searchPlaceholder: 'Search by code or model',
    noData: 'No data to display',
    viewDetails: 'Details',
    numberOfLogsInLast24h: 'Logs 24h',
    numberOfLogInLast24h: '{{count}} log',
    numberOfLogInLast24h_plural: '{{count}} logs',
    page: {
      label: 'Page',
      of: 'of',
      nRows: '{{rows}} rows',
    },
  },
  detail: {
    title: 'Titech: {{title}}',
    codeAbbr: 'Code',
    model: 'Model',
    numberOfLogsInLast24h: 'Logs 24h',
    logsCount: 'Logs Count',
    lastCheck: 'Last check',
    lastRecipe: 'Last recipe',
    recipesHistory: 'Events history',
    lastCalibration: 'Last calibration',
    nextCalibration: 'Next calibration',
    calibrationsHistory: 'Calibrations history',
    checkLogs: 'Check logs',
    last24h: 'Last 24h',
    recipe: 'Recipe',
    recipes: 'Events',
    calibrations: 'Calibrations',
    number: 'Number',
    logSeveritiesCounts: 'Severity counters',
    log: {
      severity: 'Severity',
      severityLevel: {
        Highest: 'highest',
        High: 'high',
        Medium: 'warning',
        Normal: 'normal',
      },
      date: 'Date',
      errors: 'Errors',
    },
    changes: {
      added: 'added {{variant}}',
      removed: 'removed {{variant}}',
      changed: 'from {{from}} to {{to}}',
    },
  },
}

export default { titech: en }
