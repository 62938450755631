// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import { Margin, renderMargin } from '../Utils/utils'

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isPrinting?: boolean
  label?: string
  disabled?: boolean
  size?: 'btn-sm' | 'btn-lg'
  additionalClass?: string
  spacing?: Margin
}

class PrintButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <button
        type="button"
        className={`btn btn-outline-secondary ${this.props.size || ''} ${this.props.additionalClass ||
          ''} ${renderMargin(this.props.spacing)}`}
        disabled={this.props.isPrinting || this.props.disabled}
        onClick={this.props.onClick}
      >
        <IconComponent
          icon={this.props.isPrinting ? 'circle-notch' : 'print'}
          className={`${(!this.props.isPrinting && this.props.label && 'mr-1') || ''}`}
          spin={this.props.isPrinting}
        />
        {(!this.props.isPrinting && this.props.label) || null}
      </button>
    )
  }
}

export default PrintButton
