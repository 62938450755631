import { File } from '../types/pageForm'
// find a way to make this more generic instead of using File type

// this function manages only insertion and delete
export const manageArray = (store: File[], file: File, remove?: boolean) => {
  // if remove is true, remove reference from the internal array to mimic the outer array that is contained in the parent
  if (remove) {
    return store.filter((element: File) => element.id !== file.id)
  } else {
    // if the element is not found add the new element to mimic the outer array that is contained in the parent
    // if the element is present and needs to be updated those thing are managed by the outer array and the com
    if (store.findIndex((element: File) => element.id === file.id) === -1) {
      store.push(file)
    }
    return store
  }
}

// this function manages insertion, deletion and update of elements in the array
export const manageArrayFull = (
  store: File[],
  file: File,
  remove?: boolean
) => {
  // remove element
  if (remove) {
    return [...store.filter((element: File) => element.id !== file.id)]
  } else {
    // update element
    if (
      store.length > 0 &&
      store.findIndex((element: File) => element.id === file.id) !== -1
    ) {
      return [
        ...store.map((element: File) => {
          if (element.id === file.id) {
            return file
          } else {
            return element
          }
        }),
      ]
    } else {
      // add element
      return [...store, file]
    }
  }
  return store
}