
export interface IAssets {
  favicon: string
  logoLogin: string
  logoTopBar: string
  slug: string
}

export const defaultIAssets: IAssets = {
  favicon: 'favicon.ico',
  logoLogin: 'logo-login.png',
  logoTopBar: 'logo.png',
  slug: '',
}

class Assets implements IAssets {
  private readonly _favicon: string

  public get favicon(): string {
    return this._favicon
  }

  private readonly _logoLogin: string
  public get logoLogin(): string {
    return `${this._logoLogin}`
  }

  private readonly _logoTopBar: string
  public get logoTopBar(): string {
    return `${this._logoTopBar}`
  }

  private readonly _slug: string
  public get slug(): string {
    return `${this._slug}`
  }

  private isAssetAvailable = async (asset?: string): Promise<boolean> => {
    if (!asset) {
      return false
    }
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = `${window.location.origin}/${asset}`
      image.onload = () => {
        resolve(true)
      }
      image.onerror = () => {
        reject()
      }
    }).then(() => {
      return true
    }).catch(() => {
      return false
    })
  }

  public get interface(): IAssets {
    return { favicon: this._favicon, logoLogin: this._logoLogin, logoTopBar: this._logoTopBar, slug: this._slug }
  }

  public changeAssets = async (asset?: Partial<IAssets>, ifNotAvailableSetDefault?: boolean): Promise<Assets> => {
    const newAsset = this.interface
    const defaultAssets = new Assets()
    if (asset) {
      for (const [key, value] of Object.entries(asset)) {
        if (key === 'slug') {
          continue
        }
        const available = await this.isAssetAvailable(value)
        if (available) {
          newAsset[key] = value
        }
        if (!available && ifNotAvailableSetDefault) {
          newAsset[key] = defaultAssets[key]
        }
      }
      newAsset.slug = asset.slug || defaultAssets.slug
    }
    return new Assets(newAsset)
  }

  public static async init(slug: string = ''): Promise<Assets> {
    let s = ""
    if (slug) {
      s = slug !== 'default' ? `-${slug}` : ''
    }
    const iAssets: IAssets = {
      favicon: `favicon${s}.ico`,
      logoLogin: `logo-login${s}.png`,
      logoTopBar: `logo${s}.png`,
      slug,
    }
    const result = await new Assets({}).changeAssets(iAssets, true)
    return result
  }

  public constructor(assets?: Partial<IAssets>) {
    this._favicon = assets?.favicon || defaultIAssets.favicon
    this._logoLogin = assets?.logoLogin || defaultIAssets.logoLogin
    this._logoTopBar = assets?.logoTopBar || defaultIAssets.logoTopBar
    this._slug = assets?.slug || defaultIAssets.slug
  }
}

export default Assets
