// * -------------------------------- NPM -----------------------------------
import React from 'react'

// * -------------------------------- MODULE -----------------------------------
import Button from '../../../Buttons/Button'
import DropdownButton from '../../../Buttons/DropdownButton'
import Flex, { JustifyContent } from '../../../Flex/Flex'
import Input from '../../../Input/Input'
import Text from '../../../Text/Text'
import { BatchAction, DataSelected, ListPagination, TableState } from '../types/table'
import { ButtonVariants } from '../../../Buttons/types'
import { WithTranslation } from '../../../../../types/base'

interface Props<T> extends WithTranslation {
  page: number
  numberOfPages: number
  batchActions: Array<BatchAction<T>> | undefined
  dataSelected: DataSelected<T>
  data: T[] | undefined
  tableState: TableState
  pagination: ListPagination
  previousPage: () => void
  nextPage: () => void
  changePageSize: (page: number) => void
  changePageWithDebounce: (page: number) => void
}

const TableNavigation = <T, >(props: Props<T>) => {
  const { page, numberOfPages, batchActions, tableState, data, pagination } = props
  const { t, base } = props.translation
  const pageNumber = page + 1
  const numberOfPagesVisualize = Math.max(numberOfPages, 1) // ? if there aren't data show 1 not 0

  const dataSelected = props.dataSelected.map(d => d.data)
  const actions =
    batchActions &&
    batchActions.filter(a => {
      // not set
      if (!a.disable) {
        return true
      }
      // bool
      if (typeof a.disable === 'boolean') {
        return a.disable
      }

      // function
      const available = !a.disable(dataSelected)
      return available
    })

  return (
    <Flex
      className={`mv-table-navigation ${(tableState !== TableState.loadSuccess || data?.length === 0) ? 'mv-table-navigation--hide' : ''}`}
      justifyContent={(batchActions && JustifyContent.between) || JustifyContent.end}
    >
      {(batchActions && (
        <Flex>
          <DropdownButton
            size={'md'}
            label={t(`${base}.navigation.actions`)}
            semantic={'primary'}
            isDisable={
              tableState !== TableState.loadSuccess || dataSelected.length <= 0 || (actions && actions.length <= 0)
            }
            variant={ButtonVariants.outline}
            actions={
              (actions &&
                actions.map(a => ({
                  kind: 'action',
                  label: a.action,
                  onClick: () => {
                    a.onAction(dataSelected)
                  },
                }))) ||
              []
            }
          />
          {(data && (
            <Text
              text={t(`${base}.navigation.info`, {
                value: dataSelected.length,
                valueof: data?.length,
              })}
            />
          )) ||
          null}
        </Flex>
      )) ||
      null}
      <Flex>
        <Text text={t(`${base}.navigation.page`)} />
        <Input
          disabled={tableState !== TableState.loadSuccess}
          key={1}
          kind={'input'}
          type={'number'}
          overrideValue={true}
          initialValue={pageNumber.toString()}
          onChange={value => {
            props.changePageWithDebounce(Number(value) - 1)
          }}
        />
        <Text
          text={`${t(`${base}.navigation.of`)} ${tableState !== TableState.loadSuccess ? '-' : numberOfPagesVisualize}`}
        />
        <Input
          disabled={tableState !== TableState.loadSuccess}
          kind={'select'}
          overrideValue={true}
          type={'singleSelect'}
          options={{
            defaultOption: {
              disable: false,
              value: pagination.limit.toString(),
            },
            items: ['10', '20', '50', '100', '200'].map(nRows => ({
              label: t(`${base}.navigation.row`, { value: nRows }),
              value: nRows,
            })),
          }}
          onChange={(value: string) => {
            if (pagination.limit !== Number(value)) {
              props.changePageSize(Number(value))
            }
          }}
        />
        <Button
          isDisable={page === 0 || tableState !== TableState.loadSuccess}
          semantic={'secondary'}
          variant={ButtonVariants.flat}
          icon={'chevron-left'}
          onClick={() => {
            props.previousPage()
          }}
        />
        <Button
          isDisable={page >= numberOfPages - 1 || tableState !== TableState.loadSuccess}
          semantic={'secondary'}
          variant={ButtonVariants.flat}
          icon={'chevron-right'}
          onClick={() => {
            props.nextPage()
          }}
        />
      </Flex>
    </Flex>
  )
}

export default TableNavigation
