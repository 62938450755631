import * as React from 'react'
import { ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { AreaSection } from '../../../../../types/measure'
import { getAreaPauses } from '../../../../../functions/shared'
import { TFunction } from 'i18next'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'

export interface OwnProps {
  filteredData: any
  i18nTitle: string
  i18nLabelPrefix?: string
  tooltip?: boolean
  t: TFunction
  topMargin?: boolean
  entry: string
  colorsId: string
  paddingRight?: number
  pauses?: Array<{ start: number; end: number }>
  forcedColors?: { [k: string]: string }
  lineHeight?: number
}

type Props = WithTranslation & OwnProps

const reduceWarnAreas = (warnPoints: AreaSection[], colorMap: any[], color?: string, border?: string) => {
  if (warnPoints && warnPoints.length > 0) {
    return warnPoints.map((e: any, i) => {
      return (
        <ReferenceArea
          ifOverflow={'visible'}
          key={i + e.x1}
          x1={e.x1}
          x2={e.x2}
          stroke={border || color || colorMap[e.value]}
          fill={color || colorMap[e.value]}
          strokeWidth={1}
          strokeLinejoin={'miter'}
          fillOpacity={1}
          strokeOpacity={1}
        />
      )
    })
  }

  return null
}

const getAreaSections = (dataSrc: any, key: string) => {
  const out: AreaSection[] = []

  const dataArray = Object.entries(dataSrc)
  dataArray.forEach((e: any, i: number) => {
    const currValue = dataArray[i][1] as any
    const nextValue = dataArray[i + (i < dataArray.length - 1 ? 1 : 0)][1] as any

    out.push({
      x1: currValue.time,
      x2: nextValue.time,
      y1: 0,
      y2: 100,
      value: currValue[key],
    })
  })

  return out
}

class GraphLineBarTimeView extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)

    this.renderTooltip = this.renderTooltip.bind(this)
  }

  public render() {
    try {
      const { pauses, forcedColors } = this.props
      const colors = forcedColors ? forcedColors : COLORS[this.props.colorsId]
      
      const dataPoints = getAreaSections(this.props.filteredData, this.props.entry)
      const dataAreas = reduceWarnAreas(dataPoints, colors)
      const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), [], undefined, '#ff00ff')
      return (
        <div className={'row row-bar-chart row-bar-chart' + (this.props.topMargin ? '-under' : '')}>
          <div className="col-md-10" style={this.props.paddingRight ? { paddingRight: this.props.paddingRight } : {}}>
            <h5>{this.props.t(this.props.i18nTitle)}</h5>
            <ResponsiveContainer width="100%" height={this.props.lineHeight ? this.props.lineHeight : 20}>
              <ComposedChart width={700} height={180} data={this.props.filteredData} barGap={0} barCategoryGap={0}>
                <YAxis label={''} domain={[0, 100]} tick={false} axisLine={false} tickLine={false} />
                <XAxis dataKey="time" hide={true} domain={['dataMin', 'dataMax']} type={'number'} interval={0} />
  
                {dataAreas}
                {warnPauses}
  
                {this.props.tooltip && (
                  <Tooltip wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} isAnimationActive={false} />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    } catch (error) {
      consoleLog('error', error)
    }
  }

  private renderTooltip(a: any) {
    const current = this.props.filteredData.find((e: any) => e.time === a.label)
    if (a.active) {
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
          <div
            className="recharts-default-tooltip"
            style={{
              margin: 0,
              padding: 10,
              backgroundColor: 'rgb(255, 255, 255)',
              border: '1px solid rgb(204, 204, 204)',
              whiteSpace: 'nowrap',
            }}
          >
            <p className="recharts-tooltip-label time-label" style={{ margin: 0 }}>
              {moment.unix(a.label).format('DD/MM/YYYY HH:mm:ss')}
            </p>
            {current && current.hasOwnProperty(this.props.entry) && this.props.i18nLabelPrefix
              ? current[this.props.entry] +
                ' - ' +
                this.props.t(this.props.i18nLabelPrefix + current[this.props.entry], { defaultValue: 'Ricetta' })
              : null}{' '}
            {
              // current[this.props.entry] @todo check
            }
          </div>
        </div>
      )
    }

    return null
  }
}

export default withTranslation()(GraphLineBarTimeView)
