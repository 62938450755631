import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { Alert, Form, Modal, ModalFullContent /*, Form, Alert*/ } from '@mv-submodules/inplant-components-fe'
import GenericFieldset from './GenericFieldset'
import {
  getInputValueFromData,
  getInputFieldsRefs,
  appendRelFields,
  parseFieldValue,
  clearRelFields,
  stringifyData,
  RelFields,
} from './utils'
import { FormData, Fieldset, FieldsetField, FormFieldsMap, File } from '../../../../types/pageForm'
import { manageArrayFull as manageArray } from '../../../../functions/utils'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { getDataFromObject } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import { consoleLog } from '../../../../../mvlabs-components-fe/functions/logs'

interface OwnProps {
  visible: boolean
  title: string
  fields: Fieldset[]
  onClose: (update?: boolean) => void
  onSubmit: (data: FormData) => Promise<any>
  submitTemplate?: (data: FormData) => Promise<any>
  submitLabel: string
  cancelLabel: string
  moreButtons?: React.ReactNode
  data?: FormData
  updateFilterModalValue?: boolean
  pageConfigUpdateData?: (destination?: string) => any
  type: 'filter' | 'create' | 'action' | 'modify'
}

interface OwnState {
  error?: FetchError
  data: FormData
  fieldRefs: FormFieldsMap<React.RefObject<any>>
  relFields: RelFields
}

type Props = OwnProps & WithTranslation

class FormModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: props.data || {},
      error: undefined,
      fieldRefs: props.fields.reduce(
        (acc, fieldset) => ({
          ...acc,
          ...getInputFieldsRefs(fieldset.fields),
        }),
        {}
      ),
      relFields: props.fields.reduce(appendRelFields, {}),
    }
    this.getInputValue = this.getInputValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleError = this.handleError.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.handleUploadFiles = this.handleUploadFiles.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.updateFilterModalValue !== this.props.updateFilterModalValue) {
      this.updateFilterModal()
    }
  }

  public render() {
    const props = this.props
    const errorMessage = this.state && this.state.error && this.state.error.message
    if (!props.visible) {
      return null
    }
    if ((props.type === 'create' || props.type === 'modify') && props.visible) {
      return (
        <ModalFullContent visible={props.visible} customModalBody={true} onClose={this.onClose} title={props.title}>
          <Form
            onSubmit={this.handleSubmit}
            onError={this.handleError}
            submitLabel={props.submitLabel}
            onCancel={this.onClose}
            cancelLabel={props.cancelLabel}
            moreButtons={props.moreButtons}
            isInsideModal={true}
          >
            {errorMessage && <Alert type={'danger'}>{errorMessage}</Alert>}
            {props.fields.map(this.renderFields)}
            {errorMessage && props.fields.length > 5 ? <Alert type={'danger'}>{errorMessage}</Alert> : null}
          </Form>
        </ModalFullContent>
      )
    } else if (props.type === 'filter' || props.type === 'action') {
      return (
        <Modal
          visible={props.visible}
          onClose={this.onClose}
          title={props.title}
          closeOnFocusOut={false}
          customModalBody={true}
          width={50}
        >
          <Form
            onSubmit={this.handleSubmit}
            onError={this.handleError}
            submitLabel={props.submitLabel}
            onCancel={this.onClose}
            cancelLabel={props.cancelLabel}
            moreButtons={props.moreButtons}
            isInsideModal={true}
          >
            {errorMessage && <Alert type={'danger'}>{errorMessage}</Alert>}
            {props.fields.map(this.renderFields)}
            {errorMessage && props.fields.length > 5 ? <Alert type={'danger'}>{errorMessage}</Alert> : null}
          </Form>
        </Modal>
      )
    }
    return null
  }

  private updateFilterModal = () => {
    this.setState({
      data: this.props.data || {},
      error: undefined,
      fieldRefs: this.props.fields.reduce(
        (acc, fieldset) => ({
          ...acc,
          ...getInputFieldsRefs(fieldset.fields),
        }),
        {}
      ),
      relFields: this.props.fields.reduce(appendRelFields, {}),
    })
  }

  private onClose(update?: boolean) {
    this.setState({
      data: this.props.data || {},
      error: undefined,
      fieldRefs: this.props.fields.reduce(
        (acc, fieldset) => ({
          ...acc,
          ...getInputFieldsRefs(fieldset.fields),
        }),
        {}
      ),
      relFields: this.props.fields.reduce(appendRelFields, {}),
    })
    this.props.onClose(update)
  }

  private async handleSubmit() {
    consoleLog('hanldeSubmit')
    try {
      const partialData: FormData = this.state.data
      const data = {
        ...partialData,
        lastActionId: getDataFromObject( 'data.lastAction.id',this.props, null),
      }
      await this.props.onSubmit(data)
      this.onClose(true)
    } catch (error) {
      if (error instanceof Error && error.name === 'FetchError') {
        this.setState({ error: error as FetchError })
      }
    }
  }

  private handleError(customError?: FetchError) {
    this.setState({
      error: {
        name: this.props.t(customError ? customError.name : 'chronoframe.dueDates.formField.validationError.title'),
        statusCode: 400,
        message: this.props.t(
          customError ? customError.message : 'chronoframe.dueDates.formField.validationError.description'
        ),
        errors: customError
          ? Object.keys(customError.errors).reduce((acc, key) => {
              acc[key] = [this.props.t(customError.errors[key])]
              return acc
            }, {})
          : Object.keys(this.getInvalidRefs()).reduce((acc, key) => {
              acc[key] = [this.props.t('chronoframe.dueDates.formField.validationError.invalidField')]
              return acc
            }, {}),
      },
    })
  }

  private getInvalidRefs() {
    return Object.keys(this.state.fieldRefs).reduce((acc: FormFieldsMap<React.RefObject<any>>, inputName: string) => {
      const input = this.state.fieldRefs[inputName]
      if (input && input.current && input.current.checkValidity && !input.current.checkValidity()) {
        acc[inputName] = input
      } else if (
        input &&
        input.current &&
        input.current.input &&
        input.current.input.checkValidity &&
        !input.current.input.checkValidity()
      ) {
        acc[inputName] = input
      } // DatePicker
      return acc
    }, {})
  }

  private handleInputChange(slug: string, value: any, template?: boolean) {
    const relFields: FieldsetField[] = []
    // tslint:disable-next-line:no-shadowed-variable
    const pushRelatedField = (slug: string) => {
      if (this.state.relFields[slug]) {
        relFields.push(...this.state.relFields[slug])
        this.state.relFields[slug].forEach(
          /* tslint:disable:no-string-literal */
          field => field['slug'] && pushRelatedField(field['slug'])
        )
      }
    }
    ;[slug].forEach(pushRelatedField)

    this.setState(
      currentState => {
        let data = {
          ...currentState.data,
          ...parseFieldValue(slug, value, currentState.data),
        }
        let errors = currentState.error ? { ...currentState.error.errors } : {}
        if (errors[slug]) {
          delete errors[slug]
        }
        if (relFields.length) {
          data = relFields.reduce(clearRelFields, data)
          // @ts-ignore
          errors = relFields.reduce(clearRelFields, errors)
        }
        if (template && this.props.submitTemplate) {
          this.props
            .submitTemplate(stringifyData(data))
            .then((additionalData: FormData | undefined) => {
              // tslint:disable-next-line:no-unused-expression
              additionalData &&
                this.setState(cs => ({
                  ...currentState,
                  data: Object.keys(additionalData).reduce(
                    (acc, key) => parseFieldValue(key, additionalData[key], acc),
                    cs.data
                  ),
                }))
            })
            .catch(console.warn) // tslint:disable-line
        }
        return {
          ...currentState,
          error:
            errors && Object.keys(errors)
              ? {
                  ...(currentState.error as FetchError),
                  message: '', // hide error message on input change
                  errors,
                }
              : undefined,
          data,
        }
      },
      () => {
        if (this.state.data.automaticRenewal === false) {
          const data = this.state.data
          data.repetitionRule = null
          this.setState({
            data,
          })
        }
        if (
          !(
            this.state.data &&
            this.state.data.automaticRenewal &&
            this.state.data.repetitionRule &&
            this.state.data.repetitionRule.method === 'FromDueDate' &&
            this.state.data.repetitionRule.period === 'months'
          ) &&
          this.state.data.repetitionRule &&
          this.state.data.repetitionRule.dayOfMonth
        ) {
          const data = this.state.data
          delete data.repetitionRule.dayOfMonth
          this.setState({ data })
        }
      }
    )
  }

  private handleUploadFiles(file: File, remove?: boolean) {
    const attachments = manageArray(this.state.data.attachments || [], file, remove)
    this.setState(currentState => ({
      ...currentState,
      data: {
        ...currentState.data,
        attachments,
      },
    }))
  }

  private getInputValue(slug: string) {
    return getInputValueFromData(slug, this.state.data)
  }

  private renderFields(fieldset: Fieldset) {
    return (
      <GenericFieldset
        key={fieldset.fieldset}
        refs={this.state.fieldRefs}
        fields={fieldset.fields}
        errors={(this.state.error && this.state.error.errors) || undefined}
        hiddenFields={fieldset.hiddenFields}
        requiredFields={fieldset.requiredFields}
        getInputValue={this.getInputValue}
        onInputChange={this.handleInputChange}
        handleUploadFiles={this.handleUploadFiles}
        pageConfigUpdateData={this.props.pageConfigUpdateData}
        type={(this.props.type === 'create' || this.props.type === 'modify') ? this.props.type : undefined}
      />
    )
  }
}

export default FormModal
