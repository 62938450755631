import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button } from '@mv-submodules/inplant-components-fe'
import FormModal from '../../widgets/TaskFormModal/FormModal'
import { FormData, Fieldset } from '../../../../types/pageForm'
import { addValueInObject, getDataFromObject } from '../../../../../inplant-components-fe/mvfunctions/helpers'

interface Props {
  visible: boolean
  fields: Fieldset[]
  onClose: () => void
  onSubmit: (filters?: FormData) => Promise<any>
  data?: FormData
  // t: TranslationFunction
  updateFilterModalValue: boolean,
  pageConfigUpdateData?: (destination?: string) => any
}

const TaskListAdvancedFilters = (props: Props & WithTranslation) => {
  const t = props.t
  const handleReset = () => {
    props.onSubmit()
    props.onClose()
  }

  const handleSubmit = (filters: FormData) => {
    const renamedFilters: FormData = { ...props.data }
    if (getDataFromObject('pageSlug',filters)) {
      addValueInObject(renamedFilters, 'pageSlug', filters.pageSlug)
    }
    if (getDataFromObject('target',filters) && Object.values(getDataFromObject('target',filters)).some(property => property !== undefined)) {
      addValueInObject(renamedFilters, 'target', filters.target)
    }
    if (getDataFromObject('destination',filters)) {
      addValueInObject(renamedFilters, 'destinationArray', [filters.destination])
    }
    if (getDataFromObject('customProperties.ambito',filters)) {
      addValueInObject(renamedFilters, 'customProperties.ambitoArray', [getDataFromObject('customProperties.ambito',filters)])
    }
    if (getDataFromObject('customProperties.categoria',filters)) {
      addValueInObject(renamedFilters, 'customProperties.categoriaArray', [getDataFromObject('customProperties.categoria',filters)])
    }
    if (getDataFromObject('customProperties.sottoCategoria',filters )) {
      addValueInObject(renamedFilters, 'customProperties.sottoCategoriaArray', [getDataFromObject('customProperties.sottoCategoria',filters)])
    }
    if (getDataFromObject('customProperties.sito',filters)) {
      addValueInObject(renamedFilters, 'customProperties.sitoArray', [getDataFromObject('customProperties.sito',filters)])
    }
    if (getDataFromObject('customProperties.costCenter',filters)) {
      addValueInObject(renamedFilters, 'customProperties.costCenterArray', [getDataFromObject('customProperties.costCenter',filters)])
    }
    return props.onSubmit(renamedFilters)
  }

  const handleRehydration = (filters: FormData) => {
    const renamedFilters: FormData = {}
    if (getDataFromObject('pageSlug',filters)) {
      addValueInObject(renamedFilters, 'pageSlug', filters.pageSlug)
    }
    if (getDataFromObject('target',filters)) {
      addValueInObject(renamedFilters, 'target', filters.target)
    }
    if (getDataFromObject('destinationArray',filters)) {
      addValueInObject(renamedFilters, 'destination', filters.destinationArray[0])
    }
    if (getDataFromObject('customProperties.ambitoArray',filters)) {
      addValueInObject(renamedFilters, 'customProperties.ambito', getDataFromObject('customProperties.ambitoArray',filters)[0])
    }
    if (getDataFromObject('customProperties.categoriaArray',filters)) {
      addValueInObject(renamedFilters, 'customProperties.categoria', getDataFromObject('customProperties.categoriaArray',filters)[0])
    }
    if (getDataFromObject('customProperties.sottoCategoriaArray',filters)) {
      addValueInObject(renamedFilters, 'customProperties.sottoCategoria', getDataFromObject('customProperties.sottoCategoriaArray',filters)[0])
    }
    if (getDataFromObject('customProperties.sitoArray',filters)) {
      addValueInObject(renamedFilters, 'customProperties.sito', getDataFromObject('customProperties.sitoArray',filters)[0])
    }
    if (getDataFromObject('customProperties.costCenterArray',filters)) {
      addValueInObject(renamedFilters, 'customProperties.costCenter', getDataFromObject('customProperties.costCenterArray',filters)[0])
    }
    return renamedFilters
  }

  return (
    <FormModal
      {...props}
      title={t('chronoframe.tasks.table.advancedFilters')}
      submitLabel={t('chronoframe.tasks.table.applyFilters')}
      cancelLabel={t('chronoframe.tasks.table.cancel')}
      onSubmit={handleSubmit}
      moreButtons={
        <Button variant="danger" type="button" onClick={() => handleReset()} label={t('chronoframe.tasks.table.resetFilters')} />
      }
      data={handleRehydration(props.data || {})}
      updateFilterModalValue={props.updateFilterModalValue}
      type="filter"
    />
  )
}

export default withTranslation()(TaskListAdvancedFilters)
