import * as moment from 'moment'
import { Moment } from 'moment'
import { Workshift } from '../../types/workshift'
import { HHHmmssFromMilliseconds } from '../../functions/shared'
import { getWorkshiftTotalTimes } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/workshift'

interface GeneralData {
  data: any
  fetching: boolean
  error: boolean
}

export const updateDateFilter = (dateStart: Moment, dateEnd: Moment, isManual = false) => {
  return {
    type: 'PLANTANALYSIS_COMMON_DATE_FILTER',
    payload: {
      dateStart,
      dateEnd,
    },
    isManual,
  }
}

export const resetWorkshiftFilter = (workshift?: Workshift) => {
  return {
    type: 'PLANTANALYSIS_COMMON_WORKSHIFT_RESET',
    payload: workshift,
  }
}

export const updateWorkshiftFilter = (workshift: number, workshifts: GeneralData | null) => {
  let start: null | string = null
  // @ts-ignore
  let startIndex = 0
  let end: null | string = null
  // @ts-ignore
  let endIndex = 0
  let endDailyIndex = 0
  const pauses: Array<{ start: string; end: string }> = []
  let pauseStart: null | string = null
  // @ts-ignore
  // UNUSED const firstWorkshiftEnded: boolean = false
  let workshiftEnd: false | number = false

  if (workshift && workshifts && workshifts.data && workshifts.data.values) {
    if (workshift === 99) {
      const dailyData = getDailyView(workshifts.data)
      if (dailyData) {
        start = dailyData.start
        end = dailyData.end
        endDailyIndex = dailyData.endIndex

        workshifts.data.values.forEach((v: Array<number | string>, i: number, a: Array<Array<number | string>>) => {
          if (endDailyIndex > 0 && i === endDailyIndex) {
            return
          }
          if (
            pauseStart &&
            (v[1] !== 0 || // end pause
              i + 1 === a.length) // end of data
          ) {
            pauses.push({
              start: pauseStart, // is moment string formatted
              end: moment(v[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string,
            })
            pauseStart = null
          } else if (!pauseStart && v[1] === 0 && i < a.length) {
            pauseStart = moment(v[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
          }
        })

        if (pauseStart) {
          pauses.push({
            start: pauseStart, // is moment string formatted
            end: moment(workshifts.data.values[0][0]).format('YYYY-MM-DDT23:59:59.0000Z') as string,
          })
        }
      }
    } else {
      workshifts.data.values.forEach((v: Array<number | string>, i: number, a: Array<Array<number | string>>) => {
        if (
          pauseStart &&
          (v[1] !== 0 || // end pause
            i + 1 === a.length) // end of data
        ) {
          pauses.push({
            start: pauseStart, // is moment string formatted
            end: moment(v[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string,
          })
          pauseStart = null
        } else if (!pauseStart && v[1] === 0 && i < a.length) {
          pauseStart = moment(v[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
        }

        if (!start && v[1] === workshift) {
          start = moment(v[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
          startIndex = i
        }

        if (start && !end) {
          workshiftEnd = getWorkshiftEnd(workshifts.data.values, i + 1, workshift + 1)

          if (workshiftEnd) {
            end = moment(a[workshiftEnd][0]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
            endIndex = workshiftEnd
          } else if (i + 1 === a.length && moment(v[0]).isSame(moment(), 'day')) {
            end = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
            endIndex = i
          } else {
            end = null // moment(start).format('YYYY-MM-DDT23:59:59.9999Z') as string
          }
        }
      })

      if (pauseStart) {
        pauses.push({
          start: pauseStart, // is moment string formatted
          end: moment(workshifts.data.values[0][0]).format('YYYY-MM-DDT23:59:59.0000Z') as string,
        })
      }
    }
  }

  return {
    type: 'PLANTANALYSIS_COMMON_WORKSHIFT_FILTER',
    payload: start
      ? ({
          start,
          end,
          name: `workshift.${workshift}`,
          value: workshift,
          h: end ? moment.utc(moment(end).diff(moment(start))).format('HH[h] mm[m]') : null,
          pause:
            pauses
              .reduce((acc: number, current: { start: string; end: string }) => {
                return acc + moment.duration(moment(current.end).diff(moment(current.start))).asMinutes()
              }, 0)
              .toFixed(0) + 'm',
          pauses: pauses.map(e => ({
            start: parseInt(moment(e.start).format('X'), 10),
            end: parseInt(moment(e.end).format('X'), 10),
          })),
        } as Workshift)
      : null,
  }
}

export const getDailyView = (workshifts: { values: any } | null) => {
  let start: null | string = null
  let startIndex = 0
  let end: null | string = null
  let endIndex = 0
  let firstWorkshiftEnded: boolean = false
  let workshiftRestarted: boolean = false
  let slotStart: null | string = null
  let slotId: string | null | number

  const pauses: Array<{ start: string; end: string }> = []
  const slots: Array<{ start: string; end: string; id: string | number | null }> = []
  let pauseStart: null | string = null
  let workshiftNextStart: false | number = false
  let totals: {[k: number]: number} = {}

  const formatDate = (m: moment.Moment): string => {
    return m.format('YYYY-MM-DDTHH:mm:ss.SSSSZ') as string
  }

  if (workshifts && workshifts.values) {
    const values = workshifts.values

    values.forEach((v: Array<number | string>, i: number, a: Array<Array<number | string>>) => {
      if (workshiftRestarted) {
        return
      }

      if (slots.length > 0 && firstWorkshiftEnded && v[1] === 1) {
        workshiftRestarted = true
      }

      if (!start && v[1] === 1) {
        start = formatDate(moment(v[0])) // was only string
        startIndex = i
        slotStart = formatDate(moment(v[0]))
        slotId = 1
      }

      if (start && !slotStart && v[1] !== 0) {
        slotStart = formatDate(moment(v[0]))
        slotId = v[1]
      }

      if (slotStart && v[1] === 0) {
        slots.push({
          start: slotStart, // is moment string formatted
          end: formatDate(moment(v[0])),
          id: slotId,
        })
        slotStart = null
        slotId = null
      }

      if (
        pauseStart &&
        (v[1] !== 0 || // end pause
          i + 1 === a.length) // end of data
      ) {
        pauses.push({
          start: pauseStart, // is moment string formatted
          end: formatDate(moment(v[0])),
        })
        pauseStart = null
      } else if (!pauseStart && v[1] === 0 && i < a.length) {
        pauseStart = formatDate(moment(v[0]))
      }

      if (start && !end) {
        if (!firstWorkshiftEnded && v[1] > 1) {
          // changed workshift, now it's 2 or more
          firstWorkshiftEnded = true
          workshiftNextStart = getWorkshiftEnd(values, i)
        }

        if (!workshiftNextStart && i + 1 === a.length) {
          // end of array
          if (v[1] === 0) {
            end = formatDate(moment(v[0]))
          } else if (moment(v[0]).isSame(moment(), 'day')) {
            end = formatDate(moment())
          } else {
            end = moment(values[values.length - 1][0]).format('YYYY-MM-DDT23:59:59.9999Z') as string
          }
          endIndex = i

          if (slotStart) {
            slots.push({
              start: slotStart, // is moment string formatted
              end: formatDate(moment(v[0])),
              id: slotId,
            })
          }
        } else if (firstWorkshiftEnded) {
          if (workshiftRestarted || (workshiftNextStart && workshiftNextStart === i)) {
            endIndex = i
          }

          if (endIndex > 0) {
            end = formatDate(moment(values[endIndex][0]))
          }
        }
      }
    })

    if (pauseStart) {
      pauses.push({
        start: pauseStart, // is moment string formatted
        end: moment(workshifts.values[0][0]).format('YYYY-MM-DDT23:59:59.0000Z') as string,
      })
    }

    totals = getWorkshiftTotalTimes(values)
  }

  return start && end
    ? ({
        start,
        end,
        startIndex,
        endIndex,
        name: `workshift.day`,
        value: 99,
        h: HHHmmssFromMilliseconds(moment.utc(moment(end).diff(moment(start))).milliseconds()),
        pause:
          pauses
            .reduce((acc: number, current: { start: string; end: string }) => {
              return acc + moment.duration(moment(current.end).diff(moment(current.start))).asMinutes()
            }, 0)
            .toFixed(0) + 'm',
        pauses: pauses.map(e => ({
          start: parseInt(moment(e.start).format('X'), 10),
          end: parseInt(moment(e.end).format('X'), 10),
        })),
        slots: slots.map(e => ({
          id: e.id,
          start: parseInt(moment(e.start).format('X'), 10),
          end: parseInt(moment(e.end).format('X'), 10),
        })),
        totals
      } as Workshift)
    : null
}

export const getWorkshiftEnd = (
  workshifts: Array<[string, number]>,
  fromIndex: number,
  workshift?: number
): false | number => {
  if (!workshifts || workshifts.length === 0) {
    return false
  }

  let index: false | number = false
  let firstZero: false | number = false
  const nextWorkshiftToFind = workshift || 1
  // get day end based on first workshift requested
  const dayEnd = moment(workshifts[0][0]).set({ hours: 23, minutes: 59, seconds: 59 })

  workshifts.forEach((w, i) => {
    // don't work for nothing, we already found it
    if (index !== false || i < fromIndex) {
      return
    }

    // last workshift ends when
    // - a new cycle starts
    if (w[1] === nextWorkshiftToFind || (nextWorkshiftToFind > 1 && w[1] === 1 && moment(w[0]).isAfter(dayEnd))) {
      index = i
    }

    // - or when we have the first pause before new cycle, so
    // no new cycle started
    if (index === false) {
      // we have a pause and it's the first in row
      if (firstZero === false && w[1] === 0) {
        firstZero = i
      }
      // we have a workshift > 1, let's reset the pause's counter
      else if (firstZero !== false && w[1] !== 0) {
        firstZero = false
      }
    }
  })
  // if we have a pause after last valid workshift return it, otherwise return the first "1" workshift found
  return firstZero || index
}
