import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import TitechDetail from './components/views/TitechDetail/TitechDetailPageView'
import TitechList from './components/views/TitechList/TitechListPageView'
import TitechSetup from './components/views/TitechSetup/TitechSetupPageView'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes'

const routes: RouteProps = {
  children: [
    {
      component: TitechList,
      exact: true,
      i18nkey: 'titech.navigation.list',
      path: '/titech',
      visible: true,
    },
    {
      component: TitechSetup,
      exact: true,
      i18nkey: 'titech.navigation.setup',
      path: '/titech/setup',
      visible: true,
    },
    {
      component: TitechDetail,
      path: '/titech/:id',
      visible: false,
    },
  ],
  i18nkey: 'titech.navigation.titech',
  icon: faEye,
  path: '/titech',
  visible: true,
}

export default routes
