import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { RowInfo, SortingRule } from 'react-table'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { applyParams, fetchData } from '../../../../redux/actions/titechList'
import { DispatchFetchListProps, ListParams } from '../../../../types/store'
import {
  Titech,
  TitechSecurityLabels,
} from '../../../../types/titech'
import { PlantConfig } from '../../../../types/plant'
import * as moment from 'moment'
import { Filters, PageHeader, Table, TableRowButtonGroup } from '@mv-submodules/inplant-components-fe'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'

type OwnProps = RouteComponentProps<any> & WithTranslation

interface OwnState extends ListParams {
  data: Titech[],
  isFetching: boolean,
  fetchErrors: boolean,
  page: number
}


const mapStateToProps = (store: any): ListParams & PlantConfig => {
  return {
    plant: store.plantSelector,
    isMultiPlant:
      store.config &&
      store.config.plantSelector &&
      store.config.plantSelector.isMultiPlant,
    ...store.titech.titechList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFetchListProps<Titech> => ({
  fetchData: (params, plant) => fetchData(params, plant)(dispatch),
  applyParams: params => dispatch(applyParams(params)),
})

type Props = OwnProps & ListParams & DispatchFetchListProps<Titech> & PlantConfig

class TitechListPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      isFetching: false,
      fetchErrors: false,
      page: props.page || 1,
      filtered: [],
      pageSize: props.pageSize || 10,
      search: props.search,
    }

    this.applyTableParams = this.applyTableParams.bind(this)
    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
    this.handleTableSortedChange = this.handleTableSortedChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public componentDidMount() {
    this.fetchData((this.props.isMultiPlant && this.props.plant && this.props.plant.plant) || undefined)
  }

  public componentWillReceiveProps(props: Props) {
    if (
      this.props.isMultiPlant &&
      this.props.plant &&
      props.plant &&
      props.plant.plant !== this.props.plant.plant
    ) {
      this.fetchData(props.plant.plant)
    }
  }

  private fetchData(plant: string | undefined) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props.fetchData(
        undefined,
        plant,
      ).then((data) => {
        this.setState({ data })
      })
        .catch((error) => {
          console.log(error) //tslint:disable-line
          this.setState({ fetchErrors: true })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  public render() {
    const { t, pageSize } = this.props
    const { data, page, isFetching } = this.state
    const titechs = this.filterData(data)
    const filters: FilterComponent[] = [{
      id: 'titech-list-search',
      value: this.state.search || '',
      type: 'SearchInput',
      disabled: this.state.isFetching,
      label: t('titech.list.searchPlaceholder'),
      onChange: (name, value) => this.handleSearchChange(value && value as string || undefined),
    }]
    return (
      <div className="mv4iot-fe-titech mv4iot-fe-titech__list">
        <PageHeader title={t('titech.list.title')}/>
        <div className="content">
          <Filters fields={filters}/>

          <Table
            data={!isFetching ? titechs : undefined}
            columns={[
              {
                Header: t('titech.detail.codeAbbr'),
                accessor: 'code',
                width: 100,
              },
              {
                Header: t('titech.detail.model'),
                accessor: 'modelName',
              },
              {
                Cell: ({ original }: RowInfo) => {
                  if (!original.numberOfLogInLast24h) {
                    return <div className="text-center severity-column">-</div>
                  } else {
                    return (
                      <div
                        className={`severity-column max-severity-${
                          original.maxSeverityValue
                        }`}
                      >
                        {t('titech.list.numberOfLogInLast24h', {
                          count: original.numberOfLogInLast24h,
                        })}
                        <span>
                          {original.maxSeverityCount}{' '}
                          {t(
                            `titech.detail.log.severityLevel.${
                              TitechSecurityLabels[String(original.maxSeverityValue)]
                            }`,
                          )}
                        </span>
                      </div>
                    )
                  }
                },
                Header: t('titech.list.numberOfLogsInLast24h'),
                accessor: 'numberOfLogInLast24h',
                width: 150,
              },
              {
                Cell: ({ original }: RowInfo) => (
                  <div
                    className={
                      'last-check-column ' +
                      (original.hasLastCheckWarning() ? 'text-danger' : '')
                    }
                  >
                    <div>
                      {original.lastCheckDate
                        ? original.lastCheckDate.format('DD/MM/YYYY')
                        : '-'}
                    </div>
                    <div>
                      {original.lastCheckDate &&
                      original.lastCheckDate.format('HH:mm:ss')}
                    </div>
                  </div>
                ),
                Header: t('titech.detail.lastCheck'),
                accessor: 'lastCheckDate',
              },
              {
                Cell: ({ original }: RowInfo) =>
                  original.lastRecipe && (
                    <div>
                      <div>{original.lastRecipe.name}</div>
                      <div>
                        {original.lastRecipe.date &&
                        original.lastRecipe.date.format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                      </div>
                    </div>
                  ),
                Header: t('titech.detail.lastRecipe'),
                accessor: 'lastRecipe.date',
              },
              {
                Cell: ({ original }: RowInfo) => (
                  <div
                    className={
                      original.hasLastCalibrationWarning() ? 'text-danger' : ''
                    }
                  >
                    <div>
                      {original.lastCalibrationDate
                        ? original.lastCalibrationDate.format('DD/MM/YYYY')
                        : '-'}
                    </div>
                    <div>
                      {original.lastCalibrationDate &&
                      original.lastCalibrationDate.format('HH:mm:ss')}
                    </div>
                  </div>
                ),
                Header: t('titech.detail.lastCalibration'),
                accessor: 'lastCalibrationDate',
              },
              {
                Cell: ({ original }: RowInfo) => (
                  <div
                    className={
                      'next-calibration-column ' +
                      (original.hasNextCalibrationWarning()
                        ? 'text-danger'
                        : '')
                    }
                  >
                    <div>
                      {original.nextCalibrationDate
                        ? original.nextCalibrationDate.format('DD/MM/YYYY')
                        : '-'}
                    </div>
                    <div>
                      {original.nextCalibrationDate &&
                      original.nextCalibrationDate.format('HH:mm:ss')}
                    </div>
                  </div>
                ),
                Header: t('titech.detail.nextCalibration'),
                accessor: 'nextCalibrationDate',
              },
              {
                Cell: ({ original }: RowInfo) => (
                  <TableRowButtonGroup buttons={[{
                    variant: 'secondary-alternate',
                    icon: "eye",
                    onClick: () => {
                      if (this.props.isMultiPlant && this.props.plant) {
                        window.open(
                          `http://${
                            this.props.plant.plant
                          }.inplant.live/titech/${original.id}`,
                          '_blank',
                        )
                      } else {
                        this.props.history.push(`titech/${original.id}`)
                      }
                    },
                  }]}
                  />
                ),
                Header: null,
                filterable: false,
                resizable: false,
                sortable: false,
              },
            ]}
            showPagination={true}
            showPaginationBottom={true}
            noDataText={t('titech.list.noData')}
            ofText={t('titech.list.page.of')}
            rowsText={t('titech.list.page.rows')}
            pageText={t('titech.list.page.page')}
            isFetching={this.state.isFetching}
            onPageChange={this.handleTablePageChange}
            onPageSizeChange={this.handleTablePageSizeChange}
            //  onSortedChange={this.handleTableSortedChange}
            page={page - 1}
            manualIsFetching={true}
            pageSize={pageSize}
            defaultSorted={[
              {
                id: 'code',
                desc: false,
              },
            ]}
            getTrProps={(rowState: any, rowInfo: any) => {
              const classes: string[] = []

              if (rowInfo) {
                const original = rowInfo.row._original

                if (
                  original.nextCalibrationDate &&
                  moment(original.nextCalibrationDate).isSame(new Date(), 'day')
                ) {
                  classes.push('next-calibration-today')
                }

                if (original.isCalibrationExpired) {
                  classes.push('calibration-expired')
                }

                if (
                  original.maxSeverityValue &&
                  (original.maxSeverityValue === 100 ||
                    original.maxSeverityValue === 50) &&
                  original.maxSeverityCount > 0
                ) {
                  classes.push('high-severity-counts')
                }

                if (
                  !original.lastCheckDate ||
                  original.lastCheckDate === '0001-01-01T00:00:00'
                ) {
                  classes.push('last-check-null')
                }
              }

              return {
                className: classes.join(' '),
              }
            }}
          />
        </div>
      </div>
    )
  }

  private applyTableParams(params: ListParams) {
    this.props.applyParams(params)
  }

  private filterData(
    data: Titech[]): Titech[] {
    const { search } = this.state
    if (!search || !data) {
      return data
    }
    const q = search.toLowerCase()
    return data.filter((titech: Titech) =>
      ['code', 'modelName'].reduce(
        (check: boolean, key: string) =>
          check || !!(titech[key] && titech[key].toLowerCase().match(q)),
        false,
      ),
    )
  }

  private handleTablePageChange(page: number) {
    this.setState({ page: page + 1 }, () => this.applyTableParams({
      sorted: this.props.sorted,
      search: this.props.search,
      page: page + 1,
      pageSize: this.props.pageSize,
      filtered: this.props.filtered,
    }))
  }

  private handleTablePageSizeChange(pageSize: number) {
    this.applyTableParams({ page: 1, pageSize })
  }

  private handleTableSortedChange(sorted: SortingRule[]) {
    // this.applyTableParams({ sorted })
    console.log(sorted) //tslint:disable-line
  }

  private handleSearchChange(search: string | undefined) {
    this.setState({
      search,
    }, () => {
      this.applyTableParams({
        sorted: this.props.sorted,
        search,
        page: this.props.page,
        pageSize: this.props.pageSize,
        filtered: this.props.filtered,
      })
    })
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(TitechListPageView)),
)
