import { AssetStatus, AssetFile } from '../types/asset'
import i18n from 'i18next'
import * as React from 'react'
import { Badge } from '@mv-submodules/inplant-components-fe'
import { BadgeType } from '@mv-submodules/inplant-components-fe/ui/components/Badge/Badge'
import FetchWrapper from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper'
export const API = () => FetchWrapper.getInstance('file')

export const getAssetsStatus = (): AssetStatus[] => ['active', 'deactivated', 'deactivation-to-validate', 'creation-to-validate', 'documents-to-validate']

export const renderStatus = (status: string, subValue?: string) => {
  let statusType: BadgeType = 'neutral'
  switch (status) {
    case 'active':
    case 'done':
    case 'all-tasks-done':
      statusType = 'positive'
      break
    case 'creation-to-validate':
    case 'documents-to-validate':
    case 'deactivation-to-validate':
    case 'freezing-to-validate':
    case 'scrapping-to-validate':
    case 'selling-to-validate':
    case 'replace-with-scrapping-to-validate':
    case 'replace-with-deactivation-to-validate':
    case 'to-do':
    case 'postponed':
    case 'overdue':
    case 'to-validate':
      statusType = 'warning'
      break
    case 'deactivated':
      statusType = 'negative'
  }
  return (<Badge type={statusType} value={i18n.t(`assetManager.status.${status}`)} subvalue={subValue}/>)
}

export const downloadDocument = async (assetFile: AssetFile) => {
  const query = `${assetFile.url}`
  return API().request(query, undefined, true)

}