// * -------------------------------- NPM --------------------------------------
import React from 'react'

/**
 * info alignment: https://getbootstrap.com/docs/4.0/layout/grid/#alignment
 * xs = default
 */

interface KeyValuePair<T> {
  key: string
  value: T | undefined
}

interface ColumnProps {
  children: any
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  formGroup?: boolean
}

class Column extends React.Component<ColumnProps> {
  public render() {
    const optionsSize: Array<KeyValuePair<number>> = [
      {
        key: '',
        value: this.props.xs,
      },
      {
        key: '-sm',
        value: this.props.sm,
      },
      {
        key: '-md',
        value: this.props.md,
      },
      {
        key: '-lg',
        value: this.props.lg,
      },
      {
        key: '-xl',
        value: this.props.xl,
      },
    ]

    const renderColumnOption = (): string => {
      return optionsSize
        .map(({ key, value }) => renderColumnName(key, value))
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim()
    }

    const renderColumnName = (propertyName: string, value: number | undefined): string => {
      return value ? `col${propertyName}-${value}` : ''
    }

    const noOptionSet = (): string => {
      return optionsSize.every(({ value }) => value === undefined) ? 'col' : ''
    }

    return (
      <div
        className={`${(this.props.formGroup && 'form-group') || ''} ${noOptionSet()} ${renderColumnOption()}  mv-col`}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Column
