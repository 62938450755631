import { Dispatch } from 'redux'
import { AnyAction } from 'redux'
import { PlantSelectorItem } from '../models'
import { logoutUser } from '../../../../../auth/index'
import FetchWrapper from '../../../../../functions/fetch-wrapper'

const API = () => FetchWrapper.getInstance('auth')

export const plantSelectorItemsFetchHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'PLANTSELECTOR_FETCH_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const plantSelectorItemsFetchIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PLANTSELECTOR_FETCH_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const plantSelectorItemsFetchSuccess = (items: PlantSelectorItem[]): AnyAction => {
  return {
    type: 'PLANTSELECTOR_FETCH_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const plantSelectorItemsFetchData = () => {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    dispatch(plantSelectorItemsFetchIsLoading(true))
    const state = getState();
    const {plant} = state.plantSelector;
    const itemsPromise = API().request('/model/plants')

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as PlantSelectorItem[]
        dispatch(plantSelectorItemsFetchSuccess(items))
        dispatch(plantSelectorItemsFetchIsLoading(false))
        if(!plant || plant === '') {
          dispatch(plantSelectorSetPlant((window as any).MULTI_PLANT_DEFAULT || process.env.REACT_APP_MULTI_PLANT_DEFAULT || null))
        }
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        dispatch(plantSelectorItemsFetchHasErrored(true))
      })
  }
}

// ----------------------------------------------------

export const plantSelectorSetPlant = (item: string): AnyAction => {
  return {
    type: 'PLANTSELECTOR_SET_PLANT',
    plant: item,
  }
}
