// * -------------------------------- NPM --------------------------------------
import React, {  useEffect, useMemo } from 'react'
import { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { AlignItems, Column, Direction, Fit } from '../../Flex/Flex'
import SelectComponent from './SelectComponent'
import useGenericInputHook, { Controls } from '../../../../functions/hooks/useGenericInputHook'
import { GlobalProps, OtherInputProps } from '../types'
import { mvDate } from '../../../../functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../services/translation'

/**
 * !Don't use this component, Use only Input
 *
 * This component expose a @callback onChange that return the current value and the state of the input
 *
 * Use the props @param controls to create the controls that the input need to satisfy to be valid
 *
 * @param initialTime default option for the select
 *
 * @param startTime startTime for range time selectable
 * @param endTime endTime for range time selectable
 */

export interface IDiscreteTimeComponents extends GlobalProps<'time'> {
  timeType: 'discrete'
  onTimeChange: (time: Date) => void

  initialTime: Date

  startTime: Date
  endTime: Date
  minuteDiscreteInterval: number
  controls?: Controls<Date>

  showLabels?: boolean
}

const DiscreteTimeComponent = (props: IDiscreteTimeComponents & OtherInputProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const inputHook = useGenericInputHook(props.controls?.map(c => ({ control: () => c.control(time), error: c.error })))

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { startTime, endTime, minuteDiscreteInterval, onTimeChange } = props
  const translation = useComponentsTranslation()
  const base = 'components.input.discreteTime'

  const getPossibleTimes = useMemo(() => generateTimeValue(),[])

  // !the value returned
  const [time, setTime] = useState<Date>(props.initialTime)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  
  function generateTimeValue() {
    let counter = startTime
    const allPossibleHours: Record<number, {hour: {label: string}, minutes: Array<{
      value: string,
      label: string
    }>}> = {}
    while(mvDate.isSameOrBeforeHour(counter, endTime)) {
      let currentCounter = mvDate.toDate(counter)
      currentCounter = mvDate.setMinutes(currentCounter, 0)
      Object.assign(allPossibleHours, {
        [currentCounter.getTime()]: {
          hour: {
            label: mvDate.format(currentCounter, 'HH'),
          },
          minutes: generateMinutesNew(currentCounter, mvDate.addHours(counter, 1)) 
        }
      })
      counter = mvDate.addHours(counter, 1)
    }
    return allPossibleHours
  }
    
  function generateMinutesNew(internalStartTime: Date, endInternalTime: Date) {
    let internalCounter = internalStartTime
    const internalPossibleTime = []
    while (
      mvDate.isSameOrBeforeMinute(internalCounter, endInternalTime) &&
      mvDate.isSameOrBeforeHour(internalCounter, endTime)
    ) {
      internalPossibleTime.push({
        label: mvDate.format(internalCounter, 'mm'),
        value: internalCounter.getTime().toString()
      })
      internalCounter = mvDate.addMinutes(internalCounter, minuteDiscreteInterval)
    }
    return internalPossibleTime
  }

  const getOptionForMinutes = () => {
    const currentHour = Object.entries(getPossibleTimes).find(([key,value]) => key === mvDate.setMinutes(props.initialTime, 0).getTime().toString())
    if (currentHour !== undefined) {
      const [_key,value] = currentHour
      return value.minutes.map(val => ({label: val.label, value: val.value}))
    }
    return []
  } 

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------

  useEffect(() => {
    onTimeChange(time)
    inputHook.retry()
  }, [time])

  useEffect(() => {
    props.onChangeState?.(inputHook.state)
  }, [inputHook.state])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs -----------------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <Flex direction={Direction.row} alignItems={AlignItems.end} fit={Fit.oneLine}>
      <Column>
        {(props.showLabels && <label>{translation.t(`${base}.hours.label`)}</label>) || null}
        <SelectComponent
          type={'singleSelect'}
          name={props.name}
          readonly={props.readonly}
          validity={props.validity}
          onBlur={props.onBlur}
          kind={'select'}
          onChange={value => {
            setTime(mvDate.toDate(Number(value)))
          }}
          options={{
            defaultOption: {
              value: mvDate.setMinutes(props.initialTime, 0).getTime().toString(),
              disable: false,
            },
            items: Object.entries(getPossibleTimes).map(([key, value]) => ({value: key, label: value.hour.label})),
          }}
        />
      </Column>

      <Column>
        {(props.showLabels && <label>{translation.t(`${base}.minutes.label`)}</label>) || null}
        <SelectComponent
          type={'singleSelect'}
          name={props.name}
          readonly={props.readonly}
          validity={props.validity}
          kind={'select'}
          onChange={value => {
            setTime(mvDate.toDate(Number(value)))
          }}
          options={{
            defaultOption: {
              value: props.initialTime.getTime().toString(),
              disable: false,
            },
            items: getOptionForMinutes(),
          }}
        />
      </Column>
    </Flex>
  )
}

export default DiscreteTimeComponent
