// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from '../Button/Button'
import PrintButton from '../Button/PrintButton'
import { ButtonTypes, ButtonVariants } from '../Button/types'
import { Icon } from '../../../services/icon'

interface Props {
  buttons: Array<{
    disabled?: boolean
    icon?: Icon
    id?: string
    isLoading?: boolean
    label?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    printButton?: boolean
    type?: ButtonTypes
    variant?: ButtonVariants
  }>
}

class TableRowButtonGroup extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <div className="table-row-button-group text-right">
        {this.props.buttons.map((button, index) => {
          if (!button.printButton && !button.variant) {
            return null
          }
          if (button.printButton) {
            return (
              <PrintButton
                key={`${button.id || button.label}${index * Math.random()}`}
                onClick={button.onClick}
                isPrinting={button.isLoading}
                disabled={button.disabled}
                size="btn-sm"
              />
            )
          }
          return (
            <Button
              key={`${button.id || button.label}${index * Math.random()}`}
              variant={button.variant!}
              label={button.label}
              icon={button.icon}
              onClick={button.onClick}
              disabled={button.disabled}
              size="sm"
            />
          )
        })}
      </div>
    )
  }
}

export default TableRowButtonGroup
