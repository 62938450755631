import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../../../../../inplant-components-fe'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Flex, { StretchColumn } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'
import { AlignSelf } from '../../../../../inplant-components-fe/ui/components/MVFlex/FlexItem'
import Input from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { fetchUpdatePassword } from '../../../../redux/actions/changePassword'

interface FormData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePassword = () => {
  const userId: string | undefined = useSelector((state: any) => state?.auth?.user?.uuid)

  const { t } = useComponentsTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const base = 'user.changePassword'

  const [isLoading, setIsLoading] = useState(false)

  const [formData, setFormData] = useState<FormData>({ confirmPassword: '', newPassword: '', oldPassword: '' })

  const updatePassword = async () => {
    setIsLoading(true)
    fetchUpdatePassword(
      userId || '',
      formData.oldPassword,
      formData.newPassword,
      formData.confirmPassword
    )(dispatch)
      .then(() => {
        history.goBack()
      }).catch(e => {
          consoleLog(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!userId) {
    return <></>
  }

  return (
    <div className="inplant-user">
      <PageHeader title={t(`${base}.title`)} />
      <StretchColumn className="content">
        <Flex>
          <Input
            grow={1}
            kind={'input'}
            type={'password'}
            label={t(`${base}.currentPassword.label`)}
            placeholder={t(`${base}.currentPassword.placeholder`)}
            initialValue={formData.oldPassword}
            onChange={value => setFormData(prev => ({ ...prev, oldPassword: value }))}
          />
          <Input
            grow={1}
            kind={'input'}
            type={'password'}
            label={t(`${base}.newPassword.label`)}
            placeholder={t(`${base}.newPassword.placeholder`)}
            initialValue={formData.newPassword}
            onChange={value => setFormData(prev => ({ ...prev, newPassword: value }))}
          />
          <Input
            grow={1}
            kind={'input'}
            type={'password'}
            label={t(`${base}.newPassword.labelConfirm`)}
            placeholder={t(`${base}.newPassword.placeholderConfirm`)}
            initialValue={formData.confirmPassword}
            onChange={value => setFormData(prev => ({ ...prev, confirmPassword: value }))}
          />
        </Flex>
        <Button
          isDisable={isLoading}
          isLoading={isLoading}
          semantic={'primary'}
          label={t(`${base}.save`)}
          onClick={updatePassword}
          alignSelf={AlignSelf.end}
        />
      </StretchColumn>
    </div>
  )
}

export default ChangePassword
