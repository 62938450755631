import { Dispatch, ActionCreator } from 'redux'
import { PlantStatus } from '../reducers/plantStatus'
import FetchWrapper from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper'

export const API = () => FetchWrapper.getInstance('model')

export const STATUS_FETCHING_LOADING = 'STATUS_FETCHING_LOADING'
export const STATUS_FETCHING_COMPLETE = 'STATUS_FETCHING_COMPLETE'
export const STATUS_FETCHING_ERROR = 'STATUS_FETCHING_ERROR'

interface InplantsStatusAreFetching {
  type: typeof STATUS_FETCHING_LOADING
}

interface UpdateInplantsStatusSuccess {
  type: typeof STATUS_FETCHING_COMPLETE,
  data: PlantStatus[]
}

interface UpdateInplantsStatusFailure {
  type: typeof STATUS_FETCHING_ERROR
  error: Error
}

const inplantsStatusAreFetching: ActionCreator<InplantsStatusAreFetching> = (): InplantsStatusAreFetching => ({
  type: STATUS_FETCHING_LOADING,
})

const updateInplantsStatusSuccess:ActionCreator<UpdateInplantsStatusSuccess> = (data: PlantStatus[]): UpdateInplantsStatusSuccess => ({
  type: STATUS_FETCHING_COMPLETE,
  data,
})

const updateInplantsStatusFailure:ActionCreator<UpdateInplantsStatusFailure> = (error: Error): UpdateInplantsStatusFailure => ({
  type: STATUS_FETCHING_ERROR,
  error,
})

export const fetchInplantsStatus = () => async (dispatch: Dispatch) => {
  dispatch(inplantsStatusAreFetching())
  try {
    const result: PlantStatus[] = await API().request('/plants-status', {
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
    })
    dispatch(updateInplantsStatusSuccess(result))
    return result
  } catch (error) {
    dispatch(updateInplantsStatusFailure(error))
    throw error
  }
}

export type InplantStatusActions = InplantsStatusAreFetching | UpdateInplantsStatusSuccess | UpdateInplantsStatusFailure
