const it = {
  status: {
    title: 'Stato impianti',
    columns: {
      plantName: 'IMPIANTO',
      status: 'STATUS',
      lastConnection: 'ULTIMA CONNESSIONE',
      latency: 'TEMPO ULTIMO PING',
    },
    statuses: {
      optimal: 'OTTIMALE',
      sufficient: 'ACCETTABILE',
      slow: 'LENTA',
      unstable: 'INSTABILE',
      offline: 'OFFLINE',
    },
    packageLoss: 'Pacchetti persi: ',
    noDataText: 'Nessun impianto presente',
    ofText: 'di',
    rowsText: 'righe',
  },
}

export default it
