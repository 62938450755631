import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { PlantSelectorState } from '../../state'
import { plantSelectorItemsFetchData, plantSelectorSetPlant } from '../../actions'
import { PlantSelectorItem } from '../../models'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import './_PlantSelector.scss'

interface PlantSelectorDispatchProps {
  plantSelectorItemsFetchData: () => Promise<PlantSelectorItem>
  plantSelectorSetPlant: ( plant: string ) => void
}

const mapStateToProps = ( state: any ): PlantSelectorState => {
  return {
    hasErrored: state.plantSelector.hasErrored,
    isLoading: state.plantSelector.isLoading,
    items: state.plantSelector.items,
    plant: state.plantSelector.plant,
    isMultiPlant: state.config.plantSelector && state.config.plantSelector.isMultiPlant && state.config.plantSelector.isMultiPlant === 'true',
    multiPlantAcl: state.config.plantSelector && state.config.plantSelector.multiPlantAcl,
    multiPlantDefault: state.config.plantSelector && state.config.plantSelector.multiPlantDefault || null,
  }
}

const mapDispatchToProps = ( dispatch: any ): PlantSelectorDispatchProps => {
  return {
    plantSelectorItemsFetchData: () => dispatch(plantSelectorItemsFetchData()),
    plantSelectorSetPlant: ( plant: string ) => dispatch(plantSelectorSetPlant(plant)),
  }
}

interface OwnState {
  plant: null | string
  plantsAcl: {[k: string]: string[]}
}

type Props = WithTranslation & PlantSelectorDispatchProps & PlantSelectorState

class PlantSelectorWidget extends React.Component<Props, OwnState> {
  constructor( props: Props ) {
    super(props);

    this.state = {
      plant: this.props.multiPlantDefault,
      plantsAcl: this.props.multiPlantAcl && isJSON(this.props.multiPlantAcl)
        ? JSON.parse(this.props.multiPlantAcl)
        : null,
    };

    this.handleSelectorChange = this.handleSelectorChange.bind(this)
  }

  public componentDidMount(): void {
    if (this.props.isMultiPlant) {
      this.props.plantSelectorItemsFetchData()
    }
    const urlComponent = window.location.pathname.split('/')[1];
    const multiPlantAcl = this.props.multiPlantAcl && isJSON(this.props.multiPlantAcl) && JSON.parse(this.props.multiPlantAcl)
    if (this.props.isMultiPlant && this.props.plant && multiPlantAcl && multiPlantAcl[urlComponent] && !multiPlantAcl[urlComponent].includes(this.props.plant)){
      this.setState({
        plant: multiPlantAcl[urlComponent][0]
      });
      this.props.plantSelectorSetPlant(multiPlantAcl[urlComponent][0])
      const fromPlant = this.props.items && this.props.items.find(i => i.name === this.props.plant)
      const toPlant = this.props.items && this.props.items.find(i => i.name === multiPlantAcl[urlComponent][0])

      alert(this.props.t('plantSelectorWidget.plantChangedToAllowed', {
        fromPlant: this.props.t(`plantSelectorWidget.options.${this.props.plant}`, {
          defaultValue: fromPlant ? fromPlant.description : this.props.plant
        }),
        toPlant: this.props.t(`plantSelectorWidget.options.${multiPlantAcl[urlComponent][0]}`, {
          defaultValue: toPlant ? toPlant.description : multiPlantAcl[urlComponent][0]
        })
      }))
    }
  }


  public render() {
    const plant = this.props.plant && this.props.plant || undefined;
    const urlComponent = window.location.pathname.split('/')[1];
    const multiPlantAcl = this.state.plantsAcl;

    return !multiPlantAcl || multiPlantAcl && multiPlantAcl[urlComponent] ?
      <div className="plant-selector-widget">
        {
          this.props.isMultiPlant &&
          <select
            className={'plant-select custom-select form-control-sm text-uppercase' + (plant ? ' has-selection' : '')}
            onChange={this.handleSelectorChange}
            value={plant || undefined}
          >
            <option disabled={!!plant} value="">{this.props.t('plantSelectorWidget.options.null')}</option>
            {
              this.props.items &&
              this.props.items.map(( item: PlantSelectorItem ) => {
                  const isDisabled = multiPlantAcl && multiPlantAcl[urlComponent] && multiPlantAcl[urlComponent].indexOf(item.name) === -1;
                  return (<option
                    key={item.name + item.date}
                    value={item.name}
                    className={(plant && plant === item.name ? 'bg-warning' : '')}
                    disabled={isDisabled}
                    title={isDisabled ? this.props.t('plantSelectorWidget.options.disabled'): undefined}
                  >{
                    this.props.t('plantSelectorWidget.options.' + item.name.toLocaleLowerCase(), {
                      defaultValue: (
                        item.description && item.description !== '' ?
                          item.description + ' (' + item.name + ')' :
                          item.name
                      )
                    })
                  }</option>)
                }
              )
            }
          </select>
        }
      </div> : null
  }

  private handleSelectorChange( event: React.ChangeEvent<HTMLSelectElement> ) {
    this.setState({
      plant: event.currentTarget.value
    });
    this.props.plantSelectorSetPlant(event.currentTarget.value)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PlantSelectorWidget))
