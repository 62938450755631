import * as React from 'react'
import {Button} from '@mv-submodules/inplant-components-fe'
import GenericFieldset from './GenericFieldset'
import {getInputValueFromData} from './utils'
import {RowField, InputField, EmptyField} from '../../../../types/pageForm'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface Props {
  slug: string
  fields: Array<InputField | EmptyField | RowField>
  errors?: { [field: string]: string[] }
  hiddenFields?: string[]
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
}

interface OwnState {
  values: any[]
}

class GroupedFieldset extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      values: []
    }
    this.addNewRow = this.addNewRow.bind(this)
    this.removeRow = this.removeRow.bind(this)
    this.getInputValue = this.getInputValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  public static getDerivedStateFromProps(props: Props) {
    const values = props.getInputValue(props.slug) || []
    return {
      values: values.length ? values : [{}]
    }
  }

  public render() {
    const props = this.props
    const {values} = this.state
    return (
      <React.Fragment>
        {values.map((data, index) => (
          <Row key={index} verticalAlignment={"center"} spacing={{vertical: false, horizontal: false}}>
            <GenericFieldset
              {...props}
              getInputValue={this.getInputValue(index)}
              onInputChange={this.handleInputChange(index)}
              className="col subset"
              fields={props.fields}
            />
            <Row verticalAlignment={'end'}>
              <Column xs={1}>
                <div className={"mt-2"}>
                  <Button variant="secondary-alternate" type="button"
                          onClick={() => this.removeRow(index)} disabled={index === 0}
                          icon={"minus-circle"}/>
                </div>
              </Column>
            </Row>
          </Row>
        ))}
        <Button variant="secondary" type="button" onClick={() => this.addNewRow()} icon={"plus"} spacing={{horizontal:false}}/>
      </React.Fragment>
    )
  }

  private getInputValue(index: number) {
    return (inputSlug: string) => {
      if (inputSlug.includes(this.props.slug)) {
        const slug = inputSlug.replace(`${this.props.slug}.`, '')
        return getInputValueFromData(slug, this.state.values[index])
      } else {
        /* tslint:disable:no-string-literal */
        const field = this.props &&
          this.props.fields &&
          this.props.fields[0] &&
          this.props.fields[0]['fields'] &&
          this.props.fields[0]['fields'].find((f: any) => f['relSlug'] === inputSlug)

        return field ? this.props.getInputValue(field['relSlug']) : undefined
      }
    }
  }

  private handleInputChange(index: number) {
    return (inputSlug: string, value: any, inputTemplate?: boolean) => {
      const slug = inputSlug.replace(`${this.props.slug}.`, '')
      const {values} = this.state
      this.props.onInputChange(this.props.slug, [
        ...values.slice(0, index),
        {
          ...(values[index] || {}),
          [slug]: value
        },
        ...values.slice(index + 1),
      ], inputTemplate)
    }
  }

  private addNewRow() {
    // here duplicate inputs
    this.props.onInputChange(this.props.slug, [...this.state.values, {}])
  }

  private removeRow(index: number) {
    this.props.onInputChange(this.props.slug, [...this.state.values.slice(0, index), ...this.state.values.slice(index + 1)])
  }
}


export default GroupedFieldset
