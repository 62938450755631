// * -------------------------------- NPM --------------------------------------
import React from 'react'
import ReactNotification, { store } from 'react-notifications-component'

// * -------------------------------- MODULE --------------------------------------
import { getUniqueId } from '../../../functions/helpers/stringHelper'
import { Icon } from '../../../services/icon'
import Button from '../Buttons/Button'
import { ButtonVariants } from '../Buttons/types'
import IconComponent from '../Icon/Icon'
import Text from '../Text/Text'
import { setAnimations } from '../../../functions/animations'

type NotyTypes = 'success' | 'danger' | 'info' | 'warning'
type Position = 'top-right' | 'top-center' | 'top-full'
type Id = string
export type OnNewUniqueNotification = 'removePrevious' | 'maintainPrevious'

/**
 * maintain the current notification displayed. the key is the slug passed as props, the value is the uniqueId generated from the notification component
 */
interface UniqueNotification {
  id: Id
}

const storeUniqueNotification: Record<string, UniqueNotification | undefined> = {}

export type NotificationProps = Partial<{
  title: string
  message: string | React.ReactNode
  actions: React.ReactNode
  dismissOnClick: boolean
  duration: number | 'infinity'
  pauseDismissOnHover?: boolean
  showCloseIcon: boolean
  appendNotificationOn?: 'top' | 'bottom'
  position: Position
  width: number | 'fit-content'
  uniqueNotification: boolean,
  slug: string,
  onNewUniqueNotification: OnNewUniqueNotification
  onRemoval: (id: string, removedBy: any) => void
}> & { type: NotyTypes }

const icons: Record<NotyTypes, Icon> = {
  success: 'check-circle',
  info: 'info-circle',
  danger: 'exclamation-circle',
  warning: 'exclamation-circle',
}

const positions: Record<NotyTypes, Position> = {
  info: 'top-center',
  success: 'top-center',
  danger: 'top-right',
  warning: 'top-right',
}

const animations: Record<NotyTypes, { in: string; out: string }> = {
  info: { in: setAnimations(['zoomIn']), out: setAnimations(['zoomOut']) },
  success: { in: setAnimations(['zoomIn']), out: setAnimations(['zoomOut']) },
  danger: {
    in: setAnimations(['slideInRight']),
    out: setAnimations(['slideOutRight']),
  },
  warning: {
    in: setAnimations(['slideInRight']),
    out: setAnimations(['slideOutRight']),
  },
}

const oneDayInSeconds = 86400

/**
 * Default with duration = 500 and appended on top
 */
const addNotification = (
  {
    duration = 5000,
    showCloseIcon = true,
    width = 350,
    slug = '',
    uniqueNotification = false,
    ...props
  }: NotificationProps): Id => {
  const { type, dismissOnClick, pauseDismissOnHover, appendNotificationOn, onRemoval } = props
  const id = getUniqueId()

  // return the id of the previous notification
  if (uniqueNotification && props.onNewUniqueNotification === 'maintainPrevious' && storeUniqueNotification[slug]) {
    return storeUniqueNotification[slug]?.id || ''
  }

  if (uniqueNotification) {
    removePreviousUniqueNotification(slug)
    setUniqueNotification(slug, id)
  }

  const anim = animations[type]
  store.addNotification({
    id,
    container: props.position || positions[type],
    content: <Notification {...props} showCloseIcon={showCloseIcon} id={id} />,
    insert: appendNotificationOn,
    animationIn: [anim.in],
    animationOut: [anim.out],
    width: typeof width === 'number' ? width : undefined,
    onRemoval: (onRemovalId, removeBy) => {
      onRemoval?.(onRemovalId, removeBy)
      if(uniqueNotification) {
        removeUniqueNotification(slug)
      }
    },
    dismiss: {
      click: dismissOnClick,
      duration: typeof duration === 'number' ? duration : oneDayInSeconds,
      pauseOnHover: pauseDismissOnHover,
      waitForAnimation: true,
    },
  })

  return id
}

const removeUniqueNotification = (slug: string) => {
  delete storeUniqueNotification[slug]
}

const removePreviousUniqueNotification = (slug: string) => {
  const previousNoty = storeUniqueNotification[slug]
  if (previousNoty) {
    removeNotification(previousNoty.id)
  }
}

const setUniqueNotification = (slug: string, id: string) => {
  storeUniqueNotification[slug] = { id }
}

const Notification = (props: NotificationProps & { id: string }) => {
  const { title, message, type, showCloseIcon, id, actions } = props
  const base = 'notification__custom'

  const renderContent = () => {
    if (!message) {
      return
    }
    if (typeof message === 'string') {
      return <Text text={message} />
    }
    return message
  }

  const remove = () => {
    store.removeNotification(id)
  }

  return (
    <div className={`${base} ${base}--${type}`}>
      <div className={`${base}__icon ${base}__icon--${type}`}>
        <IconComponent size={'2x'} icon={icons[type]} />
      </div>
      {(title || message) && (
        <div className={`${base}__content`}>
          {title && <Text text={`|**${title}**|`} size={'lg'} />}
          {renderContent()}
        </div>
      )}
      {actions && <div className={`${base}__actions`}>{actions}</div>}
      {showCloseIcon && (
        <div className={`${base}__close`}>
          <Button onClick={remove} variant={ButtonVariants.flat} icon={'times'} semantic='secondary' />
        </div>
      )}
    </div>
  )
}

/**
 * uniqueNotification are per slug
 * @param props
 */
export const showNotification = (props: NotificationProps) => addNotification(props)

export const removeNotification = (id: Id) => store.removeNotification(id)

export default ReactNotification
