import * as React from 'react'
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ChartStatusBadges, parseInfluxResponse } from '../../../../functions/shared'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import SingleMeasure from '../SingleMeasure/SingleMeasure'

interface OwnState {
  isLoading: boolean
  isLoadingMean: boolean
  fetchErrors: boolean
  fetchErrorsMean: boolean
  data: any[]
  dataMean: any[]
}

interface StateProps {
  /*dateFilterStart: moment.Moment
  dateFilterEnd: moment.Moment*/
  workshift: Workshift | null
  plant: any | null
}

interface OwnProps {
  component: {
    id: string
    name: string
    nodeType: string
  }
  measures: {
    [k: string]: {
      domain?: [string | number, string | number]
      referenceLines?: number[]
      lineColor?: string
      showMean?: boolean
      unit?: string
      footerMeasures?: Array<{
        operation: 'sum' | 'mean'
        measure: string
        i8nLabel: string
        condition: string
        unit?: string
      }>
      footerCounters?: Array<{
        limit: number
        operator: '=' | '<' | '>' | '<=' | '>=' | '<>'
        i8nLabel: string
        showAbsolute?: boolean
        showPercent?: boolean
      }>
    }
  }
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  /*dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd, */
  workshift: state.plantAnalysis.common.workshift,
  plant: state.plantSelector || null,
})

class MultiSeriesLineCharts extends React.Component<Props, OwnState> {
  // @ts-ignore
  private mounted: boolean
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: false,
      isLoadingMean: false,
      fetchErrors: false,
      fetchErrorsMean: false,
      data: [],
      dataMean: [],
    }

    this._getData = this._getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this._getData()
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.workshift &&
      (!prevProps.workshift ||
        prevProps.workshift.start !== this.props.workshift.start ||
        prevProps.workshift.end !== this.props.workshift.end)
    ) {
      this._getData()
    }
  }

  public render() {
    const { t, measures, component, workshift } = this.props
    const { isLoading, fetchErrors, data } = this.state

    const noData = !isLoading && !fetchErrors && data.length === 0
    const isReady = !isLoading && !fetchErrors && data.length > 0

    const measureDataKeys = (isReady && data.map(s => s.name)) || []

    const ReferenceLabel = (props: any) => {
      const { textAnchor, value, fontSize, viewBox, dy } = props
      const x = viewBox.width + viewBox.x + 12
      const y = viewBox.y + 4
      return (
        <text x={x} y={y} className="recharts-text recharts-label" textAnchor={textAnchor}>
          <tspan fontSize={fontSize || 12} x={x} dy={dy}>
            {value}
          </tspan>
        </text>
      )
    }

    if (!measures) {
      return (
        <div className={'alert alert-warning w-100 col-sm-6 mx-auto alert-local '}>
          {t('plantAnalysis.invalidChartConfiguration')}
        </div>
      )
    }

    const footerMean = (footerData: any[], key: string, label: string, unit?: string) => {
      const measure = footerData && footerData.find(e => e.name === key)

      if (!measure) {
        return null
      }
      return (
        <div className={'col-md-3'}>
          <h6 className="generic-sub-title">
            {label}: {measure.data[0].mean_measure.toFixed(2)}
            {unit}
          </h6>
        </div>
      )
    }

    let ticks: any[] = []
    if (workshift && data && data.length > 0) {
      const diffTime = Math.ceil(moment.duration(moment(workshift.end).diff(moment(workshift.start))).asMinutes())
      const startMoment = moment(workshift.start)

      ticks = Array.from(
        {
          length: Math.ceil(diffTime / 60) * 2,
        },
        (_, hour) => {
          return startMoment.clone().add(30 * hour, 'minute')
        }
      )
      const minLimit = moment(workshift.start).subtract(5, 'minute')
      const maxLimit = moment(workshift.end).add(5, 'minute')

      ticks.forEach((time: moment.Moment | null, i) => {
        if (!time || time.isBefore(minLimit) || time.isAfter(maxLimit)) {
          ticks[i] = null
        }
      })

      ticks = ticks.filter(e => e).map(e => e.utc().valueOf()) // remove nulls and set utc/format
    }

    return (
      <>
        <div className="row multi-series-line-chart">
          {ChartStatusBadges('multi-series-line-chart', isLoading, fetchErrors, noData, t)}

          <div className="col-12">
            <div className="row">
              {isReady &&
                Object.keys(measures).map((m, mi) => {
                  const measureProps = measures[m]
                  if (measureDataKeys.includes(component.id + '_' + m)) {
                    const localData = data.find(s => s.name === component.id + '_' + m)
                    if (localData) {
                      localData.data.forEach((l: { time: string | number; measure: any }) => {
                        l.time = moment(l.time)
                          .utc()
                          .valueOf()
                      })
                      return (
                        <div className="col-12 multi-series-line-chart__single" key={component.id + '_' + mi}>
                          <h5 className={'generic-sub-title text-left'}>
                            {t('plantAnalysis.multiLineChartsLabels.trend') +
                              ' ' +
                              t('plantAnalysis.multiLineChartsLabels.' + m)}
                          </h5>
                          <ResponsiveContainer width="100%" height={180}>
                            <LineChart
                              data={localData.data}
                              margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
                              barCategoryGap={0}
                            >
                              <YAxis
                                label={measureProps.unit}
                                domain={measureProps.domain || ['auto', 'auto']}
                                // minTickGap={measureProps.unit === '%' ? 10 : undefined}
                                scale={'linear'}
                                allowDataOverflow={true}
                                yAxisId={'left'}
                              />

                              <YAxis label={''} allowDataOverflow={true} yAxisId={'right'} orientation={'right'} />
                              <XAxis
                                dataKey="time"
                                scale={'time'}
                                tickFormatter={e => moment(e).format('HH:mm')}
                                interval={'preserveStart'}
                                type={'number'}
                                domain={[
                                  workshift
                                    ? moment(workshift.start)
                                        .utc()
                                        .valueOf()
                                    : 'dataMin',
                                  workshift
                                    ? moment(workshift.end)
                                        .clone()
                                        .add(5, 'minute')
                                        .utc()
                                        .valueOf()
                                    : 'dataMax',
                                ]}
                                ticks={ticks}
                                tickCount={0}
                              />
                              <CartesianGrid strokeDasharray="3 3" />

                              <Line
                                name={'' + t('plantAnalysis.multiLineChartsLabels.' + m)}
                                dataKey={'measure'}
                                dot={false}
                                stroke={measureProps.lineColor}
                                animationDuration={0}
                                yAxisId={'left'}
                              />

                              {measureProps.referenceLines &&
                                measureProps.referenceLines.map((r, ri) => (
                                  <ReferenceLine
                                    y={r}
                                    key={ri}
                                    label={<ReferenceLabel value={r} />}
                                    stroke="red"
                                    isFront={true}
                                    yAxisId={'left'}
                                  />
                                ))}

                              <Tooltip
                                labelFormatter={v => t('plantAnalysis.labels.time') + ': ' + moment(v).format('HH:mm')}
                                animationDuration={0}
                                formatter={value => (typeof value === 'number' ? value.toFixed(0) : value)}
                                separator={': '}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                          <div className="row row-footer-measures pl-5 mt-2">
                            {measureProps.showMean &&
                              this.state.dataMean &&
                              footerMean(
                                this.state.dataMean,
                                component.id + '_' + m,
                                t('plantAnalysis.multiLineChartsLabels.mean.' + m),
                                measureProps.unit
                              )}

                            {measureProps.footerMeasures &&
                              this.props.workshift &&
                              this.props.workshift.start &&
                              this.props.workshift.end &&
                              measureProps.footerMeasures.map((fm, fi) => (
                                <SingleMeasure
                                  key={fi}
                                  t={t}
                                  dateStart={this.props.workshift!.start}
                                  dateEnd={this.props.workshift!.end}
                                  plant={this.props.plant}
                                  nodeId={component.id}
                                  {...fm}
                                />
                              ))}
                          </div>
                        </div>
                      )
                    }
                  }
                  return false
                })}
            </div>
          </div>
        </div>
      </>
    )
  }

  private async _getData() {
    const { component, plant, workshift, measures } = this.props

    const plantQueryString = plant && plant.plant !== '' ? 'plant=' + plant.plant + '&' : ''

    if (measures && plant && plant.plant && workshift) {
      this.setState({
        fetchErrors: false,
        data: [],
        isLoading: true,
      })
      const startDateObj = moment(workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`

      const query =
        `${plantQueryString}q=SELECT * FROM ${Object.keys(measures)
          .map(m => component.id + '_' + m)
          .join(',')}` + queryEnd

      const queryMean =
        `${plantQueryString}q=SELECT mean(*) FROM ${Object.keys(measures)
          .map(m => component.id + '_' + m)
          .join(',')}` + queryEnd

      try {
        API().request(`/query?${query}`, {
          signal: this.abortController.signal,
        })
          .then((results: any) => {
            if (this.mounted) {
              this.setState({
                fetchErrors: false,
                data: parseInfluxResponse(results),
                isLoading: false,
              })
            }
          })
          .catch((e: any) => {
            console.log(e) // tslint:disable-line
            if (this.mounted) {
              this.setState({
                fetchErrors: true,
                data: [],
                isLoading: false,
              })
            }
          })

        API().request(`/query?${queryMean}`, {
          signal: this.abortController.signal,
        })
          .then((results: any) => {
            if (this.mounted) {
              this.setState({
                fetchErrorsMean: false,
                dataMean: parseInfluxResponse(results),
                isLoadingMean: false,
              })
            }
          })
          .catch((e: any) => {
            console.log(e) // tslint:disable-line
            if (this.mounted) {
              this.setState({
                fetchErrorsMean: true,
                dataMean: [],
                isLoadingMean: false,
              })
            }
          })
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log(error) // tslint:disable-line
        if (this.mounted) {
          this.setState({
            fetchErrors: true, // mean?
            data: [],
            isLoading: false,
          })
        }
        throw error
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(MultiSeriesLineCharts))
