import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import {
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  parseResponseData,
  parseResponseSeries,
  populateManAutoLocSec,
} from '../../../../../functions/series'
import { API } from '../../../../../redux/actions'
import { AreaSection, GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import moment from 'moment'
import {
  getAreaPauses,
  getAreaSections,
  HHHmmssFromMilliseconds,
  Loader,
  reduceWarnAreas,
} from '../../../../../functions/shared'
import { flatData } from '../../../../../functions/model'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog, logger } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { modelFetchData } from '../../../../../redux/actions/model'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'

export type VecoplantTypes = 'test'

export const map = {
  vDailyPlantTimeReport:
    'time, morning_delay_duration,  morning_delay_events, morning_delay_perc, empty_duration, empty_events, empty_perc, pause_delay_duration, pause_delay_events, pause_delay_perc,bulky_duration, bulky_events, bulky_perc, other_duration, other_events, other_perc, runningTime_duration, runningTime_perc',
  vDailyHaltCauses:
    'time, ManualStop_duration, ManualStop_events, ManualStop_perc, DelayedStart_duration,  DelayedStart_events,  DelayedStart_perc, Alarm_duration, Alarm_events, Alarm_perc, OpenGate_duration, OpenGate_events, OpenGate_perc, ' +
    'Clog_duration, Clog_events, Clog_perc, BunkerFull_duration, BunkerFull_events, BunkerFull_perc, Empty_duration, Empty_events, Empty_perc, Pause_duration, Pause_events, Pause_perc, Emergency_duration, Emergency_events, Emergency_perc,PaidBreak_duration,PaidBreak_events, PaidBreak_perc, Unhandled_duration,Unhandled_events,Unhandled_perc,dummy',
  vDailyManualStops:
    'time, ManSftyLock_duration, ManSftyLock_events, ManHmiStp_duration, ManHmiStp_events, ManSelClean_duration, ManSelClean_events, ManHmiPause_duration, ManHmiPause_events, ManLoadRec_duration, ManLoadRec_events, ManDevStp_duration, ManDevStp_events, None_duration, None_events,dummy',
  vDailyUnjustifiedStops:
    'time, dummy, unjustified_duration, unjustified_events, unjustified_perc, other_duration, other_events, other_perc',
  vDailyJustifiedBy:
    'time, dummy, justified_by_system_duration, justified_by_system_events, justified_by_system_perc, justified_by_users_duration, justified_by_users_events, justified_by_users_perc',
}

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: VecoplantTypes[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workshift: any
}

interface OwnState {
  vecoplant: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataVecoplant: PieData | null
  fetching: boolean
  error: boolean
  tooltipData: any
  showTooltip: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
}

type Props = StateProps & OwnProps & DispatchProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
})

interface DispatchProps {
  modelFetchData: (plant?: string) => Promise<{ model: any; plant?: string }>
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    modelFetchData: (plant?: string) => dispatch(modelFetchData(plant)),
  }
}

class VecoplantView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      vecoplant: {},
      fetching: false,
      error: false,
      dataVecoplant: null,
      showTooltip: true,
      tooltipData: null,
      data: null,
      filteredData: [],
      mergedData: [],
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workshift?.value !== prevProps.workshift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curThrustPresser = curData.thrustPresser !== undefined ? curData.thrustPresser : '--'
        // const curAbsorption = curData.absorption !== undefined ? curData.absorption : '--'
        const curSpeedPercent = curData.speedPercent !== undefined ? curData.speedPercent : '--'
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            thrustPresser: curThrustPresser,
            // absorption: curAbsorption,
            speedPercent: curSpeedPercent,
            thrustPresserColor: COLORS.vecoplant.generalGraph.thrustPresser,
            // absorptionColor: COLORS.vecoplant.generalGraph.absorption,
            speedPercentColor: COLORS.vecoplant.generalGraph.speedPercent,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public render() {
    const { t, hiddenCharts, pauses } = this.props
    const { fetching, error } = this.state

    const warnPoints: AreaSection[] | undefined =
      ([{}] as [{}]) && getAreaSections(this.state.filteredData, 'warnArea').filter(e => e.value === 100)
    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), undefined, COLORS.pauses)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <div className="halts-graphs page-break-before-always">
        {!fetching && error && (
          <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
            {t('plantAnalysis.fetchErrors')}
          </div>
        )}
        {!error && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('test')) &&
              (this.state.fetching ? (
                <Row>
                  <Loader />
                </Row>
              ) : (
                <Column>
                  <Row>
                    <div className={`col-md-12 col-sm-12 col-lg-12 `}>
                      <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.absorption.title')}</h3>
                      <Row>
                        <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                          <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                              data={this.state.filteredData}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseMove={this.handleMouseMove}
                              onMouseLeave={this.handleMouseLeave}
                              barCategoryGap={0}
                            >
                              <XAxis
                                key={'line1'}
                                hide={false}
                                dataKey="time"
                                label={''}
                                height={30}
                                axisLine={true}
                                tickLine={false}
                                tick={true}
                                interval={'preserveStartEnd'}
                                minTickGap={50}
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                type={'category'}
                              />
                              <CartesianGrid stroke="#f5f5f5" />

                              {warnAreas}
                              {warnPauses}
                              <Tooltip content={VecoplantView.nullTooltipContent} />
                              <YAxis
                                yAxisId={'percentage'}
                                domain={[0, 100]}
                                axisLine={false}
                                tickFormatter={val => parseInt(val, 10)}
                              >
                                <Label value={'%'} position={'insideTopLeft'} />
                              </YAxis>
                              <Line
                                dataKey="thrustPresser"
                                stroke={COLORS.vecoplant.generalGraph.thrustPresser}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'percentage'}
                              />
                              <Line
                                dataKey="speedPercent"
                                stroke={COLORS.vecoplant.generalGraph.speedPercent}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'percentage'}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                        <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                          {this.state.showTooltip && this.state.tooltipData !== null && (
                            <ul className="side-tooltip">
                              <li>
                                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                <br />
                                <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                              </li>

                              {this.state.tooltipData.thrustPresser !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.thrustPresser')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{ backgroundColor: `${this.state.tooltipData.thrustPresserColor}` }}
                                  />
                                  <span>{this.state.tooltipData.thrustPresser} %</span>
                                </li>
                              )}

                              {this.state.tooltipData.speedPercent !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.speedPercent')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{ backgroundColor: `${this.state.tooltipData.speedPercentColor}` }}
                                  />
                                  <span>{this.state.tooltipData.speedPercent} %</span>
                                </li>
                              )}
                              <li>
                                <strong>{this.props.t('plantAnalysis.labels.pauses')}</strong>{' '}
                                <span
                                  className="label-color label-color-line label-color-line-right"
                                  style={{ backgroundColor: COLORS.pauses }}
                                />
                              </li>
                            </ul>
                          )}
                        </div>
                      </Row>
                      <div className="d-none d-print-inline-flex">
                        <Row verticalAlignment="center">
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <span
                              className="label-color-square"
                              style={{ backgroundColor: COLORS.vecoplant.generalGraph.absorption }}
                            />
                            <span>{t('plantAnalysis.labels.thrustPresser')}</span>
                          </span>
                        </Row>
                        <Row verticalAlignment="center">
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <span
                              className="label-color-square"
                              style={{ backgroundColor: COLORS.vecoplant.generalGraph.speedPercent }}
                            />
                            <span>{t('plantAnalysis.labels.speedPercent')}</span>
                          </span>
                        </Row>
                        <Row verticalAlignment="center">
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <span
                              className="label-color-square"
                              style={{ backgroundColor: COLORS.vecoplant.generalGraph.thrustPresser }}
                            />
                            <span>{t('plantAnalysis.labels.absorption')}</span>
                          </span>
                        </Row>
                      </div>
                    </div>
                  </Row>
                  <div className={`col-lg-12 col-md-12 col-sm-12 chart-events-pie--data`}>
                    <div>
                      <span className='mx-5'>
                        <b>
                          {this.props.t('plantAnalysis.vecoplant.vecoplanTable.number') + ': '}
                        </b>
                          {this.state.data &&
                            this.state.data.recapData[0] &&
                            this.state.data.recapData[0].stops_count}
                      </span>
                      <span>
                        <b>
                          {this.props.t('plantAnalysis.vecoplant.vecoplanTable.time') + ': '}
                        </b>
                          {this.state.data &&
                            this.state.data.recapData[0] &&
                            HHHmmssFromMilliseconds(
                              (this.state.data.recapData[0].stop_seconds as number) * 1000,
                              false,
                              true,
                              false
                            )}
                      </span>
                    </div>
                  </div>
                </Column>
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let thrustPresser: any = []
      /* let absorption: any = [] */
      let speedPercent: any = []
      const mergedData: any[] = []
      if (this.state.data) {
        /* absorption = this.populateSingleData('absorption') */
        thrustPresser = this.populateSingleData('thrustPresser')
        speedPercent = this.populateSingleData('speedPercent')
      }
      hydrateData(
        {
          thrustPresser,
          /* absorption, */
          speedPercent,
        },
        mergedData,
        undefined,
        0
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(['thrustPresser', /* 'absorption', */ 'speedPercent'], mergedData, this.state)
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private formatData(data: any) {
    return [
      data.columns
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {}),
    ]
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const startDate = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate = workshift?.end ? mvDate.getDateFromString(workshift.end.toString()).toISOString() : endOfDay
      const VecoplantSeries: VecoplantTypes[] = ['test']
      const filteredSeries = VecoplantSeries.filter(
        v => !this.props.hiddenCharts || !this.props.hiddenCharts.includes(v)
      )

      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      const queries = filteredSeries.map(
        e =>
          `${plantQueryString}q=SELECT ${map[e] ? map[e] : '*'} FROM ` +
          `"${e + (processing ? '_' + processing : '')}"` +
          queryEnd
      )

      try {
        let recapGraphQuery
        try {
          recapGraphQuery = flatData(this.props.model?.data.model.content).filter(
            n => n.nodeType === 'SG2_PLANT_TRITURATORE'
          )
        } catch (error) {
          await this.props
            .modelFetchData(plant)
            .then(({ model }) => {
              recapGraphQuery = flatData(model.content).filter(n => n.nodeType === 'SG2_PLANT_TRITURATORE')
            })
            .catch(error => {
              logger('VecoplantView.tsx', 'getData fetch model', `Error on parsing result: ${error}`, 'server')
              this.setState({
                fetching: false,
                error: true,
              })
            })
        }
        const measureLabel = recapGraphQuery && recapGraphQuery[0].id
        const recapDataLabel = `vVecoplan`
        const queryStart = `SELECT "measure" FROM `
        const queryStartAll = `SELECT * FROM `

        const thrustPresser = queryStart + `"${measureLabel}_RAM_FEED_PERCENT"` + queryEnd
        // const absorption = queryStart + `"${measureLabel}_MAIN_DRIVERS_CURRENT"` + queryEnd
        const speedPercent = queryStart + `"${measureLabel}_DRIVE1_SPEED_PERCENT_calc"` + queryEnd
        const recapQuery = queryStartAll + `${recapDataLabel}` + queryEndShift

        const dataThrustPresser = API().request(`/query?${plantQueryString}q=` + thrustPresser, {
          signal: this.abortController.signal,
        })
        // const dataAbsorption = API().request(`/query?${plantQueryString}q=` + absorption, {signal: this.abortController.signal})
        const dataSpeedPercent = API().request(`/query?${plantQueryString}q=` + speedPercent, {
          signal: this.abortController.signal,
        })
        const recapData = API().request(`/query?${plantQueryString}q=` + recapQuery, {
          signal: this.abortController.signal,
        })

        this.setState({
          fetching: true,
          error: false,
        })

        await Promise.all([
          dataThrustPresser,
          // dataAbsorption,
          dataSpeedPercent,
          recapData,
        ])
          .then(
            ([
              dataThrustPresserResult,
              // dataAbsorptionResult,
              dataSpeedPercentResult,
              recapDataResult,
            ]) => {
              if (this.mounted) {
                const recapDataResultObject =
                  typeof recapDataResult === 'string' ? JSON.parse(recapDataResult) : recapDataResult
                this.setState(
                  {
                    data: Object.assign({}, this.state.data, {
                      thrustPresser: { data: parseResponseData(dataThrustPresserResult) },
                      // absorption: { data: parseResponseData(dataAbsorptionResult) },
                      speedPercent: { data: parseResponseData(dataSpeedPercentResult) },
                      recapData: this.formatData(recapDataResultObject.results[0].series[0] || {}),
                    }),
                  },
                  () => {
                    this.constructData()
                  }
                )
              }
            }
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            logger('VecoplantView.tsx', 'getData parsingResult', `Error on parsing result: ${error}`, 'server')
            this.setState({
              vecoplant: {},
              fetching: false,
              error: true,
            })
          })

        await Promise.all(queries.map(q => API().request(`/query?${q}`))).then(results => {
          const out = {}
          filteredSeries.forEach((e, i) => {
            out[e] = parseResponseSeries(e, results[i])
          })
          this.setState({
            vecoplant: out,
            fetching: false,
            error: false,
          })
        })
      } catch (error:any) {
        logger('VecoplantView.tsx', 'getData', `Error on parsing result: ${error}`, 'server')
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          vecoplant: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VecoplantView))
