import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import {
  extractResponseData,
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  populateManAutoLocSec,
} from '../../../../../functions/series'
import { AreaSection, GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import moment from 'moment'
import { getAreaSections, Loader, reduceWarnAreas } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { API } from '../../../../../redux/actions'

export type VecoplantTypes = 'test'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: VecoplantTypes[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  literPerHourTrend: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataLiterPerHourTrend: PieData | null
  fetching: boolean
  error: boolean
  tooltipData: any
  showTooltip: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift
})

class LiterPerHourTrendView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      literPerHourTrend: {},
      fetching: false,
      error: false,
      dataLiterPerHourTrend: null,
      showTooltip: true,
      tooltipData: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: []
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value || 
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const currValve1 = curData.valve1 !== undefined ? curData.valve1 : '--'
        const currValve2 = curData.valve2 !== undefined ? curData.valve2 : '--'
        const currValve1Thr = curData.valve1Thr !== undefined ? curData.valve1Thr : '--'
        const currValve2Thr = curData.valve2Thr !== undefined ? curData.valve2Thr : '--'

        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            valve1: currValve1,
            valve1Thr: currValve1Thr,
            valve2: currValve2,
            valve2Thr: currValve2Thr,
            valve1Color: COLORS.scrubber.literHourTrend.valve1,
            valve1ThrColor: COLORS.scrubber.literHourTrend.valve1Thr,
            valve2Color: COLORS.scrubber.literHourTrend.valve2,
            valve2ThrColor: COLORS.scrubber.literHourTrend.valve2Thr,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    const warnPoints: AreaSection[] | undefined = ([{}] as [{}]) &&
      getAreaSections(this.state.filteredData, 'warnArea').filter(e => e.value === 100)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <div className="halts-graphs">
        {!fetching && error && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.trendLiterHour')}</h3>
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}
        {
          !fetching &&
          !error &&
          (this.state.data && (this.state.data.length === 0) || !this.state.data) &&
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.trendLiterHour')}</h3>
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
          </>
        }
        {!error &&   (
          <React.Fragment>
              {(!hiddenCharts || !hiddenCharts.includes('test')) &&
                (this.state.fetching ? (
                  <Row>
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.trendLiterHour')}</h3>
                    <Loader />
                  </Row>
                ) : this.state.data && (
                  <>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.trendLiterHour')}</h3>
                  <div className='mv-col col-md-12 col-sm-12 col-lg-12'>
                    <Row>
                      <div
                        className={`col-md-10 col-sm-10 col-lg-10`}
                      >
                        <Row>
                          <div className='mv-col col-md-10 col-sm-10 col-xl-11 print-full-width'>
                            <ResponsiveContainer width="100%" height={400}>
                              <LineChart
                                data={this.state.filteredData}
                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseMove={this.handleMouseMove}
                                onMouseLeave={this.handleMouseLeave}
                                barGap={0}
                                barCategoryGap={0}
                              >
                                <XAxis
                                  key={'line1'}
                                  hide={false}
                                  dataKey="time"
                                  label={''}
                                  height={30}
                                  axisLine={true}
                                  tickLine={false}
                                  tick={true}
                                  interval={'preserveStartEnd'}
                                  minTickGap={50}
                                  domain={['dataMin', 'dataMax']}
                                  tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                  type={'category'}
                                />
                                <CartesianGrid stroke="#f5f5f5" />

                                {warnAreas}
                                <Tooltip content={LiterPerHourTrendView.nullTooltipContent} />
                                <YAxis orientation={'left'} yAxisId={'%'} axisLine={false} tickFormatter={val => parseInt(val, 10)}/>
                                <Line
                                  dataKey="valve1"
                                  stroke={COLORS.scrubber.literHourTrend.valve1}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'%'}
                                />
                                <Line
                                  dataKey="valve2"
                                  stroke={COLORS.scrubber.literHourTrend.valve2}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'%'}
                                />
                                <Line
                                  dataKey="valve1Thr"
                                  stroke={COLORS.scrubber.literHourTrend.valve1Thr}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'%'}
                                />
                                <Line
                                  dataKey="valve2Thr"
                                  stroke={COLORS.scrubber.literHourTrend.valve2Thr}
                                  dot={false}
                                  strokeWidth={2}
                                  type="step"
                                  isAnimationActive={false}
                                  yAxisId={'%'}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>
                          <div className='mv-col col-md-2 col-sm-2 col-xl-1 d-print-none'>
                            {this.state.showTooltip && this.state.tooltipData !== null && (
                              <ul className="side-tooltip">
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                  <br />
                                  <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                                </li>

                                {this.state.tooltipData.valve1 !== null && (
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.scrubber.literPerHourTrend.valve1')}</strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.valve1Color}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.valve1).toFixed(2)} l/h</span>
                                  </li>
                                )}

                                {this.state.tooltipData.valve2 !== null && (
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.scrubber.literPerHourTrend.valve2')}</strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.valve2Color}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.valve2).toFixed(2)} l/h</span>
                                  </li>
                                )}

                                {this.state.tooltipData.valve1Thr !== null && (
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.scrubber.literPerHourTrend.valve1Thr')}</strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.valve1ThrColor}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.valve1Thr).toFixed(2)} l/h</span>
                                  </li>
                                )}

                                {this.state.tooltipData.valve2Thr !== null && (
                                  <li>
                                    <strong>{this.props.t('plantAnalysis.scrubber.literPerHourTrend.valve2Thr')}</strong>
                                    <br />
                                    <span
                                      className="label-color label-color-line"
                                      style={{ backgroundColor: `${this.state.tooltipData.valve2ThrColor}` }}
                                    />
                                    <span>{parseFloat(this.state.tooltipData.valve2Thr).toFixed(2)} l/h</span>
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        </Row>
                      </div>
                      <div className={`col-lg-2 col-sm-2 col-md-2 chart-events-pie--data`}>
                        <table
                          className={'table table-borderless table-sm w-100 mw-300 font-size-12 table-first-column-color'}
                        >
                          <thead>
                            <tr>
                              <th />
                              <th className="text-right">{t('plantAnalysis.scrubber.literPerHourTrend.total')}</th>
                              <th className="text-right">{t('plantAnalysis.scrubber.literPerHourTrend.average')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data && this.state.data.waterTrend &&
                            ['f305_flw01', 'f305_flw02'].map(bufferKey => {
                                  const total = Object.entries(this.state.data.waterTrend[0]).find(([keyFilter,valueFilter]) => keyFilter.includes('count') && keyFilter.includes(bufferKey))
                                  const average = Object.entries(this.state.data.waterTrend[0]).find(([keyFilter,valueFilter]) => keyFilter.includes('average') && keyFilter.includes(bufferKey))
                                  return (
                                <tr key={bufferKey}>
                                  <td>
                                    <span style={{ whiteSpace: 'nowrap'}}>
                                    <span className="label-color-square" style={{ backgroundColor: this.getColorByKey(bufferKey) }} />
                                      <span>
                                        {t(`plantAnalysis.lines.waterDrainValve.valves.${bufferKey}`)}
                                      </span>
                                    </span>
                                  </td>
                                  <td className="text-right">{total && parseFloat((total[1] as number).toString()).toFixed(2)}</td>
                                  <td className="text-right">{average && parseFloat((average[1] as number).toString()).toFixed(2)}</td>
                                </tr>
                            )})}
                          </tbody>
                        </table>
                      </div>
                      </Row>
                    </div>
                  </>
                ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  private getColorByKey(key:string){
    switch (true) {
      case key === 'f305_flw01' || key.includes('f305_flw01'):
        return COLORS.scrubber.literHourTrend.valve1
      case key === 'f305_flw02' || key.includes('f305_flw02'):
        return COLORS.scrubber.literHourTrend.valve2
      default:
        return COLORS.scrubber.literHourTrend.valve2
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let valve1: any = []
      let valve2: any = []
      let valve1Thr: any = []
      let valve2Thr: any = []
      const mergedData: any[] = []
      if (this.state.data) {
        valve1 = this.populateSingleData('valve1')
        valve2 = this.populateSingleData('valve2')
        valve1Thr = this.populateSingleData('valve1Thr')
        valve2Thr = this.populateSingleData('valve2Thr')
      }
      hydrateData(
        {
          valve1,
          valve2,
          valve1Thr,
          valve2Thr
        },
        mergedData,
        undefined,
        0
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(
        ['valve1', 'valve2', 'valve1Thr', 'valve2Thr'],
        mergedData,
        this.state
      )
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          fetching: false,
          error: false,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private generateKey(data: Record<string,any>) {
    this.setState({
      keyCollection: ['f305_flw01', 'f305_flw02']
    })
  }

  private formatData(data: any) {
    const resultData = data.columns
    .map((value: any, index: number) => {
      if (value !== 'time' && value !== 'shift') {
        return {
          [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
          // [value]: data.values[0][index]
        }
      }
    })
    .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
    this.generateKey(resultData)
    return [
      resultData
    ]
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift

    if(workshift) {

    const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

    const startOfDay = mvDate.format(mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())), 'yyyy-MM-dd HH:mm:ss')  
    const startDate = workshift?.start ? mvDate.getDateFromString(workshift.start.toString()).toISOString() : startOfDay    

    const endOfDay = mvDate.format(mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())), 'yyyy-MM-dd HH:mm:ss') 
    const endDate = workshift?.end ? mvDate.getDateFromString(workshift.end.toString()).toISOString() : endOfDay 

    const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
    const queryEndShiftTime = ` WHERE time <= '${startDate}' ORDER BY desc LIMIT 1`
    const queryEndShift = ` WHERE shift = ${workshift.value !== 99 ? workshift.value : 0} AND time >= '${startOfDay}' AND time <= '${endOfDay}'`


    try {

      const valve1Label = 'IBSGN5PII66HBLZD_ACT_FLW01'
      const valve2Label = 'IBSGN5PII66HBLZD_ACT_FLW02'
      const valve1ThrLablel = 'IBSGN5PII66HBLZD_TSHLD_01'
      const valve2ThrLablel = 'IBSGN5PII66HBLZD_TSHLD_02'
      const waterTrandLabel = 'vWaterTrend'

      const queryStart = `SELECT "measure" FROM `
      const queryWaterTrend = `SELECT * FROM `

      const valve1VAlues = queryStart + `"${valve1Label}"` + queryEnd
      const valve1Thr = queryStart + `"${valve1ThrLablel}"` + queryEnd
      const valve1ThrBeforeShift = queryStart + `"${valve1ThrLablel}"` + queryEndShiftTime
      const valve2Thr = queryStart + `"${valve2ThrLablel}"` + queryEnd
      const valve2ThrBeforeShift = queryStart + `"${valve2ThrLablel}"` + queryEndShiftTime

      const valve2VAlues = queryStart + `"${valve2Label}"` + queryEnd

      const waterTrendValue = queryWaterTrend + `${waterTrandLabel}` + queryEndShift

      const dataValve1Values = API().request(`/query?${plantQueryString}q=` + valve1VAlues, {signal: this.abortController.signal})
      const dataValve1ThrValues = API().request(`/query?${plantQueryString}q=` + valve1Thr, {signal: this.abortController.signal})
      const dataValve1ThrNeforeShiftValues = API().request(`/query?${plantQueryString}q=` + valve1ThrBeforeShift, {signal: this.abortController.signal})
      const dataValve2ThrValues = API().request(`/query?${plantQueryString}q=` + valve2Thr, {signal: this.abortController.signal})
      const dataValve2ThrNeforeShiftValues = API().request(`/query?${plantQueryString}q=` + valve2ThrBeforeShift, {signal: this.abortController.signal})
      const dataValve2Values = API().request(`/query?${plantQueryString}q=` + valve2VAlues, {signal: this.abortController.signal})
      const dataWaterTrendValue = API().request(`/query?${plantQueryString}q=` + waterTrendValue, {signal: this.abortController.signal})

      this.setState({
        fetching: true,
        error: false,
      })

      Promise.all([
        dataValve1Values,
        dataValve2Values,
        dataWaterTrendValue,
        dataValve1ThrValues,
        dataValve1ThrNeforeShiftValues,
        dataValve2ThrValues,
        dataValve2ThrNeforeShiftValues
      ]).then(([
        dataValve1ValuesResult,
        dataValve2ValuesResult,
        dataWaterTrendValueResult,
        dataValve1ThrValuesResult,
        dataValve1ThrNeforeShiftValuesResult,
        dataValve2ThrValuesResult,
        dataValve2ThrNeforeShiftValuesResult
      ]) => {
        if (this.mounted) {
          const dataAWaterTrendValueResultObject = typeof dataWaterTrendValueResult === 'string' ? JSON.parse(dataWaterTrendValueResult) : dataWaterTrendValueResult
          try {
            const valveThr1Object = [[
                    startDate,
                    extractResponseData(dataValve1ThrNeforeShiftValuesResult).length > 0
                      ? extractResponseData(dataValve1ThrNeforeShiftValuesResult)[0][1]
                      : 0,
                  ],
                  ...extractResponseData(dataValve1ThrValuesResult),
                  [
                    endDate,
                    extractResponseData(dataValve1ThrNeforeShiftValuesResult).length > 0
                      ? extractResponseData(dataValve1ThrNeforeShiftValuesResult)[0][1]
                      : 0,
                  ],]
            const valveThr2Object = [[
                    startDate,
                    extractResponseData(dataValve2ThrNeforeShiftValuesResult).length > 0
                      ? extractResponseData(dataValve2ThrNeforeShiftValuesResult)[0][1]
                      : 0,
                  ],
                  ...extractResponseData(dataValve2ThrValuesResult),
                  [
                    endDate,
                    extractResponseData(dataValve2ThrNeforeShiftValuesResult).length > 0
                      ? extractResponseData(dataValve2ThrNeforeShiftValuesResult)[0][1]
                      : 0,
                  ],]
            this.setState({
              data: Object.assign({}, this.state.data, {
                valve1Thr: { data: valveThr1Object},
                valve2Thr: { data: valveThr2Object},
                valve1: { data: extractResponseData(dataValve1ValuesResult) },
                valve2: { data: extractResponseData(dataValve2ValuesResult) },
                waterTrend: this.formatData(dataAWaterTrendValueResultObject.results[0].series[0] || {}),
              }),
            }, () => {
              this.constructData()
            })
          } catch (error) {
            this.setState({
              data: null,
              literPerHourTrend: {},
              fetching: false,
              error: false,
            })
          }
        }
      }).catch(error => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          literPerHourTrend: {},
          fetching: false,
          error: true,
        })
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        literPerHourTrend: {},
        fetching: false,
        error: true,
      })
    }
  }
}
}

export default connect(mapStateToProps)(withTranslation()(LiterPerHourTrendView))
