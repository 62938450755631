import React from 'react'
import Flex from '../Flex/Flex'
import { ButtonSemantics } from './types'
import ButtonFactory, { ButtonFactoryProps } from './ButtonFactory'
import { IFlexItem } from '../Flex/FlexItem'

interface OmittedParams extends IFlexItem {
  semantic: string
}

export type ChildGroupButton<OtherOmissions = {}> = ButtonFactoryProps<OmittedParams & OtherOmissions>

export interface GroupButtonProps {
  semantic: ButtonSemantics
  buttons: ChildGroupButton[]
}

const ButtonGroup = (props: GroupButtonProps) => {
  const { buttons, semantic } = props
  const gbtn = 'mv-btn-group'
  return (
    <Flex className={gbtn} spaceSize="none">
      {buttons.map((item, index) => {
        if (item.action === 'group') {
          // no recursive component
          console.warn(" no recursive component") //tslint:disable-line
          return null
        }
        return <ButtonFactory key={index} {...item} semantic={semantic} />
      })}
    </Flex>
  )
}

export default ButtonGroup
