import { AssetsListFilters, ListPagination } from '../../types/asset'
import {
  ASSETS_LIST_DEACTIVATED_SET_FILTERS, ASSETS_LIST_DEACTIVATED_SET_PAGINATION,
  ASSETS_LIST_SET_FILTERS,
  ASSETS_LIST_SET_PAGINATION, ASSETS_SET_COUNT_TO_VALIDATE, AssetsListActions,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'

const initialState:AssetsListState = {
  filters: {
    searchString: null,
    costCenter: null,
    status: null,
    plant: null
  },
  pagination: {
    pageNumber: 1,
    pageSize: 50
  },
  deactivatedList: {
    filters: {
      searchString: null,
      costCenter: null,
      status: null,
      plant: null
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50
    },
  },
  sidebarCounters: [
    {
      pageSlug: "list-to-validate",
      color: "danger",
      count: 0
    }
  ]
}

export interface AssetsListState {
  filters: AssetsListFilters,
  pagination: ListPagination,
  sidebarCounters: Array<{pageSlug: string, color: string, count: number}>
  deactivatedList: {
    filters: AssetsListFilters,
    pagination: ListPagination
  }
}

type AssetsListReducer = (
  state: AssetsListState,
  action: AssetsListActions,
) => AssetsListState

export const assetsList: AssetsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSETS_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.params,
      }
    case ASSETS_LIST_SET_PAGINATION:
      return {
        ...state,
        pagination: action.params
      }
    case ASSETS_SET_COUNT_TO_VALIDATE:
      return {
        ...state,
        sidebarCounters: [{
          pageSlug: "list-to-validate",
          color: "danger",
          count: action.count
        }]
      }
    case ASSETS_LIST_DEACTIVATED_SET_FILTERS:
      return {
        ...state,
        deactivatedList: {
          ...state.deactivatedList,
          filters: action.params
        }
      }
    case ASSETS_LIST_DEACTIVATED_SET_PAGINATION:
      return {
        ...state,
        deactivatedList: {
          ...state.deactivatedList,
          pagination: action.params
        }
      }
    default:
      return state
  }
}