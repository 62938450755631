const en = {
  plantAnalysis: {
    noDataAvailable: 'No data for selected time range',
    noNodesSelected: 'Please select nodes from "Personalizza vista"',
    invalidDateRangeForThisChart: 'Visibile only in single day selection',
    loading: 'Requesting data...',
    fetchErrors: 'Error fetching data, please retry o contact administrator',
    noNodes: 'No available nodes for this plant',
    dateChangedDayBefore: 'Warning!\nDate will be changed from {{fromDate}} to {{toDate}}',
    noChartData: 'No available data for this chart',
    invalidChartConfiguration: 'Invalid node configuration',
    mean: 'Mean',
    noOverviewLayout: 'No layout available for this plant',
    navigation: {
      plantAnalysis: 'Plant Analysis',
      plantAnalysisGeneral: 'Overview',
      plantAnalysisList: 'List',
    },
    formats: {
      timeHHMM: '{{hh}} hours {{mm}} min',
      timeMMSS: '{{mm}} min {{ss}} sec',
      timeHH: '{{hh}} hours',
      timeMM: '{{mm}} min',
      timeSS: '{{ss}} sec',
    },
    dailyTable: {
      time: 'Time',
      realTime: 'Real',
      budgetTime: 'Budget',
      deltaTime: 'Delta',
      workshift: 'workshift',
      status: 'Workshift validity',
    },
    modal: {
      title: 'Settings',
      close: 'Close',
      save: 'Save',
      showViewSettings: 'View setup',
    },
    settings: {
      main: {
        title: 'General',
      },
      nodes: {
        title: 'Nodes',
        all: 'All',
      },
      fields: {
        soglia_deviazione: 'Deviazione',
        sforamento_assorbimento_vent: 'Limite Assorb. Aspir.',
        sforamento_assorbimento_nastri: 'Limite Assorb. Nastri',
        limite_amperaggio: 'Limite Assorb.',
        velocita_riferimento: 'Velocità rif.',
      },
    },
    actions: {
      selectDateText: 'Select ...',
      selectWorkshiftText: 'Select workshift ...',
      exportDetails: 'Export detailed report',
      print: 'PRINT',
    },
    labels: {
      aspired: 'Aspired',
      assorbimento: 'Current',
      assorbimentoMassimo: 'Max. Abs',
      assorbimentoMinimo: 'Min. Abs.',
      auto: 'Auto',
      avanti: 'Av',
      avantiIndietro: 'Av/Ind',
      bagBreaker: 'BagGraph Breaker',
      bagGrap: {
        recipe: 'Working Mode',
      },
      beltGraph: {
        recipe: 'Operating Mode',
      },
      bagGraphMeasureDistributionBar: {
        low: '<= {{min}}%',
        mid: 'Marcia canonica',
        high: '>= {{max}}%',
        title: 'Distribuzione velocità',
        lowerEqualThen: '<=',
        higherEqualThen: '>=',
        percLimit: '{{symbol}} {{value}} %',
      },
      bunker: {
        incorrectData: 'Warning: check data values',
        header: {
          LAST_BUK_CLEAN1: 'Last cleaning cycle 1',
          LAST_BUK_CLEAN2: 'Last cleaning cycle 2',
          LAST_BUK_CLEAN3: 'Last cleaning cycle 3',
          LAST_BUK_GATE_OPN1: 'Last opening 1',
          LAST_BUK_GATE_OPN2: 'Last opening 2',
          LAST_BUK_GATE_OPN3: 'Last opening 3',
        },
        data: {
          bar1_1: 'No pieno',
          bar1_2: 'Pieno',
          bar2_1: 'Inverter',
          bar2_2: 'Diretta',
          bar3_1: 'Manuale',
          bar3_2: 'Automatico',
          bar3_3: 'Locale',
          bar3_4: 'Sezionato',
          bar3_x: '--',
        },
      },
      cicli: 'Cycles',
      currentValue: 'Value',
      driveBypass: 'Drv/Byp.',
      duration: 'Duration',
      general: 'General Data',
      indietro: 'Ind',
      inverterDiretta: 'Inv/Dir',
      limit: 'Limit',
      localeSezionato: 'Loc/Sec',
      locali: 'Loc',
      manAutoLocSec: 'Command',
      manuale: 'Man',
      marciaFermo: 'Run/Stop',
      mean: 'Mean',
      nopienoPieno: 'Full',
      performance: 'Performance',
      plantH: 'Workshift duration',
      pauses: 'Pauses',
      recipe: 'Recipe',
      reference: 'Ref.',
      ricetta: 'Recipe',
      setVelocita: 'Speed',
      sforamentoAssorbimento: 'Over Absorption',
      start: 'Start',
      temperatura: 'Temperature',
      time: 'Time',
      total: 'Total',
      workType: 'Work Type',
      zonaAspirato: 'Asp. Zone',
      workshift: {
        start: 'Start',
        end: 'End',
        pause: 'Pause',
        resume: 'Resume',
        running: 'In progress',
        notEnded: 'Not ended',
      },
      power: 'Power',
      position: 'Position',
      tempMelt: 'Temp Melt',
      torque: 'Torque'
    },
    options: {
      workshift: {
        undefined: '',
        1: 'First workshift',
        2: 'Second workshift',
        3: 'Third workshift',
        99: 'Day',
      },
    },
    graph1: {
      title: 'General data',
      subTitle: 'Performance Coefficient',
      percManAutoLocSec: 'Percentage Commands',
      percAspirated: 'Percentage aspirated',
    },
    graph2: {
      title: 'Conveyor belt under inverted reverser',
      subTitle: 'Speed - Absorption',
    },
    graph3: {
      title: 'Bunker',
      subTitle: '',
    },
    graph4: {
      title: 'Fan',
      subTitle: '',
    },
    help: {
      brush: 'Discover how to navigate graphs',
    },
    legendTitles: {
      manaut: 'MAN/AUTO',
      avind: 'AV/IND',
      aspi: 'ASPI',
      invdir: 'INV/DIR',
      nopienoPieno: 'FULL',
      marciaFermo: 'RUN/STOP',
      manualeAutomatico: 'COMMAND',
      recipeNum: 'WORKING MODE',
      recipe: 'RECIPE',
      driveBypass: 'DRV/BYP',
      localeSezionato: 'LOC/SEC',
      globalRicetta: 'RECIPE',
      zonaAspirato: 'ASPI. ZONE',
      manAutoLocSec: 'COMMAND',
    },
    lavorazioneAttuale: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
    },
    ricettaAttuale: {
      default: 'Recipe ',
      1: 'CIT NORMALE',
      2: 'CIT SENZA BUNKER M360',
      3: 'FLUSSO C',
      4: 'ALLUMINIO',
      5: '300 INTASATO',
      6: '318 INTASATO',
      7: '321 INTASATO',
      8: '345 INTASATO',
      9: 'RIPASSO MPO',
      10: 'CIT NORMALE',
      11: 'CIT SENZA FINE',
      12: '327 INTASATO',
      13: 'PULIZIA',
    },
    legend: {
      manaut: {
        0: 'AUT',
        1: 'MAN',
      },
      manAutoLocSec: {
        0: 'Manual',
        1: 'Automatic',
        2: 'Local',
        3: 'Sectioned',
        undefined: 'Undefined',
        sec: 'Sectioned',
        loc: 'Local',
        auto: 'Automatic',
        man: 'Manual',
      },
      avind: {
        0: 'IN',
        1: 'AV',
      },
      deviazione: {
        0: 'LESS THEN ',
        1: 'MORE THEN ',
      },
      aspi: {
        SI: 'YES',
        NO: 'NO',
      },
      invdir: {
        0: 'INVERTER',
        1: 'DIRECT',
      },
      nopienoPieno: {
        0: 'NO FULL',
        1: 'FULL',
      },
      marciaFermo: {
        0: 'STOP',
        1: 'RUN',
      },
      manualeAutomatico: {
        0: 'MAN',
        1: 'AUTO',
      },
      recipeNum: {
        1: 'mix',
        2: 'multi',
        3: 'mono',
        4: 'paper',
        5: 5,
        10: 10,
      },
      globalRicetta: {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
        13: '13',
      },
      driveBypass: {
        0: 'drive',
        1: 'by-pass',
      },
      localeSezionato: {
        0: 'local',
        1: 'sectioned',
      },
      zonaAspirato: {
        0: 'disabled',
        1: 'enabled',
      },
      workshift: 'Workshift',
      aspiredZone: {
        BeltOnAspOnPerc: 'Belt and Asp. ON',
        BeltOnAspOffPerc: 'Belt ON - Asp. OFF',
        BeltOffPerc: 'Belt OFF'
      },
      plantHaltsTimes: {
        0: "Impianto spento",
        1: "Impianto acceso",
        2: "Impianto termina lavorazione",
        10: "Carta",
        11: "Plastica",
        12: "Multimateriale",
        13: "Alluminio",
        14: "PET",
        15: "Plastica CIT",
        16: "Iron",
        17: "Plastica Qualità",
        18: "Tetrapack"
      }
    },
    halts: {
      title: 'Fermi',
      labels: {
        events: 'Eventi',
        time: 'Tempo',
      },
      plantTimeReport: {
        title: 'Apertura Impianto - Riepilogo Fermi',
        legends: {
          plantRunning: 'Impianto in funzione',
          haltOtherCauses: 'Fermo altre cause',
          haltBulky: 'Fermo ingombranti',
          manualStops: 'Svuotamento',
          totalWorkingTime: 'Totale tempo lavorato',
          standardWorkingTime: 'Tempo impianto standard',
        },
      },
      bulkyStopImpact: {
        title: 'Impatto fermi ingombranti',
        lables: {
          haltOtherCauses: 'Fermo altre cause',
          haltBulky: 'Fermo ingombranti',
          haltTotalTime: 'Tempo totale fermi',
        },
      },
      haltCauses: {
        title: 'Cause di fermo',
      },
      bulkyStops: {
        title: 'Dettagli causa fermo: Fermo ingombranti',
      },
      openGates: {
        title: 'Dettagli causa fermo: Cancello aperto',
      },
      manualStops: {
        title: 'Dettagli causa fermo: Arresto manuale',
      },
      assetsCausingClogging: {
        title: 'Cespiti che hanno causato intasamento'
      },
      processing: {
        'general': 'Totale',
        '10': 'Carta',
        '11': 'Plastica',
        '12': 'Multimateriale',
        '13': 'Alluminio',
      },
      chartLabels: {
        Alarm: 'Anomalia',
        bulky: 'Fermo ingombranti',
        Button: 'Pulsante',
        Clog: 'Intasamento',
        Emergency: 'Emergenza',
        Empty: 'Svuotamento impianto',
        empty: 'Svuotamento',
        ManDevStp: 'Manual stop device (see details)',
        ManHmiPause: 'Pause cycle auto from HMI',
        ManHmiStp: 'Stop cycle auto from HMI',
        ManLoadRec: 'Change receipe from HMI in auto cycle',
        ManOther: 'Other',
        ManSelAuto: 'Selected auto RCB2',
        ManSelClean: 'Selected clean RCB2',
        ManSftyLock: 'Safety key not present',
        ManStopOther: 'Altro',
        ManualStop: 'Arresto manuale',
        None: 'None',
        OpenGate: 'Cancello aperto',
        other: 'Fermo altre cause',
        Pause: 'Fermi Ingombranti',
        plantStandardWorkingTime: 'Tempo impianto standard',
        runningTime: 'Impianto in funzione',
        totalHaltsTime: 'Totale tempo fermi',
        totalWorkingTime: 'Totale tempo lavorato',
        Unhandled: 'Causa non definita',
      },
    },
    multiLineChartsLabels: {
      trend: 'Trend',
      MAIN_TORQUE: 'MAIN TORQUE',
      PISTON_POS_1: 'PISTON',
      SCND_LIMIT_TORQUE_ALARM: 'SCND',
      TEMP_MELT: 'TEMPERATURE MELT',
      BELT_SPD: 'DOSATORE',
      mean: {
        MAIN_TORQUE: 'Workshift mean torque',
        TEMP_MELT: 'Mean temperature melt',
        BELT_SPD: 'Mean belt speed',
        PISTON_POS_1: 'Mean piston opening',
      },
      footerLabels: {
        alarmsSecondLimit: '2nd LIMIT ALARMS',
        mainTorqueZero: 'HALTS (TORQUE 0)',
        stopCount: 'HALTS Num.',
        stopDuration: 'HALTS Duration',
        noData: 'n.d.',
      },
    },
    titech: {
      problems: '{{count}} problem',
      problems_plural: '{{count}} problems',
      parameterChanges: '{{count}} parameter changes',
      lastRecipe: 'Last recipe:',
      lastCheck: 'Last check:',
      mostUsedRecipe: 'most used ({{count}}%)',
      calibration: {
        status: {
          calibrated: 'Calibrated',
          expired: 'Expired',
          calibrationToday: 'Calibration Today',
          lastCheckNull: 'Not Detected',
        },
      },
    },
    bearings: {
      title: 'Bearings Temperature',
      noWarnings: 'There aren\'t temperature warnings',
      threshold: 'Threshold',
      warning: 'warning',
      stop: 'stop',
      intervalsNumber: 'Intervals N°',
      maxIntervalDuration: 'Max interval duration',
      totalTime: 'Total time'
    }
  },
  dateRangePicker: {
    labels: {
      yesterday: 'Yesterday',
      week: 'Week',
      month: 'Month',
    },
  },
}

export default en
