import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  generateElementsArrayForTableRowLayout, generateTableRowFromArrayOfElements,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/generateTableRowLayout'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface OwnProps {
  data: any
}

type Props = OwnProps & WithTranslation

const BunkerGraphHeaderPrint = (props: Props) => {

  const labels = [
    ['LAST_BUK_CLEAN1', 'LAST_BUK_CLEAN2', 'LAST_BUK_CLEAN3'],
    ['LAST_BUK_GATE_OPN1', 'LAST_BUK_GATE_OPN2', 'LAST_BUK_GATE_OPN3'],
  ]

  if (!props.data || (Object.keys(props.data).length === 0 && props.data.constructor === Object)) {
    return null
  }

  function generateLayout(label: string[][]) {
    const elements = label.map((l, i) => generateListWrapper(l, i))
    return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 3))
  }

  function generateListWrapper(section: string[], labelIndex: number) {
    return <TableColumnWrapper col={4} className={'bunker-graph-header'}>
      <ul>
        {section.map(generateSingleElementList)}
      </ul>
    </TableColumnWrapper>

  }

  function generateSingleElementList(l: string, index: number) {
    if (!props.data || (Object.keys(props.data).length === 0 && props.data.constructor === Object)) {
      return null
    }
    const data = props.data?.find((d: any) => d[0] && d[0].name === l)
    return (
      <li key={index}>
        <span className='label'>
          {props.t('plantAnalysis.labels.bunker.header.' + l, { defaultValue: l })}
        </span>
        <span className='value'>
          {data &&
            data[0] &&
            data[0].values &&
            data[0].values[0] &&
            moment.utc(data[0].values[0][1]).format('DD/MM/YYYY HH:mm:ss')}
        </span>
      </li>
    )
  }

  return (
    <TableRowFullWrapper>
      <TableWrapperUtilities>
        {generateLayout(labels)}
      </TableWrapperUtilities>
    </TableRowFullWrapper>
  )
}

export default withTranslation()(BunkerGraphHeaderPrint)
