import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { APITitech } from '.'
import { plantQuery } from '../../functions/model'

export const titechIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_TITECH_IS_FETCHING',
  }
}

export const titechFetchSuccess = (titech: any): AnyAction => {
  return {
    type: 'PLANTANALYSIS_TITECH_FETCH_SUCCESS',
    payload: titech,
  }
}

export const titechFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_TITECH_FETCH_ERROR',
    payload: error,
  }
}

export const titechFetchData = (
  dateFilterStart: string, // moment.Moment
  abortSignal: AbortSignal
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  return async (dispatch, getState) => {
    const plantQueryString = plantQuery(getState)

    dispatch(titechIsFetching())
    try {
      const data = await APITitech().request(`/titech/overview?${plantQueryString}date=${dateFilterStart}`, {
        signal: abortSignal,
      })
      dispatch(titechFetchSuccess(data))
      return data
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(titechFetchError(error))
      throw error
    }
  }
}
