import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../../constants'
import { HHHmmssFromMilliseconds, Loader } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { parseData } from '../../../../../functions/series'
import moment from 'moment'
import GraphLineBarWithTimeline from '../../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelineView'
import {
  composeData,
  fillTime,
  populateSingleDataFrom,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableRowWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

export type ScrubberStatusType = 'scrubberStatus'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: ScrubberStatusType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  scrubberState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataScrubberState: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class ScrubberStatusView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      keyCollection: [],
      scrubberState: {},
      fetching: false,
      error: false,
      dataScrubberState: null,
      data: null,
      filteredData: [],
      mergedData: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private renderLabelStatus(scrubberLabel: string) {
    return (
      this.state.data &&
      this.state.keyCollection
        .filter(scrubberId => scrubberId.includes(scrubberLabel))
        .map((scrubberId, bufferIndex) => (
          <Row key={scrubberId}>
            {this.state.data &&
              Object.entries(this.state.data?.bufferRecap[0])
                .filter(([b, value]) => b.includes(scrubberId) && b.includes('seconds'))
                .map(([key, value], index) => {
                  if (key.includes('inactive')) {
                    return (
                      <div className='mx-5'>
                        <span key={index}>
                          <b>
                            {this.props.t('plantAnalysis.scrubber.scrubberStatus.inactiveTime')}
                            {HHHmmssFromMilliseconds((value as number) * 1000, false, true, false)}
                          </b>
                        </span>
                      </div>
                    )
                  } else {
                    return (
                      <div className='mx-5'>
                        <span key={index}>
                          <b>
                            {this.props.t('plantAnalysis.scrubber.scrubberStatus.activeTime')}
                            {HHHmmssFromMilliseconds((value as number) * 1000, false, true, false)}
                          </b>
                        </span>
                      </div>
                    )
                  }
                })}
          </Row>
        ))
    )
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    return (
      <TableRowWrapper>
        <WithConditionWrapper condition={!fetching && error}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.activeDeactivate')}</h3>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </WithConditionWrapper>
        <WithConditionWrapper condition={!error}>
          <WithConditionWrapper condition={!hiddenCharts || !hiddenCharts.includes('scrubberStatus')}>
            <WithConditionWrapper
              condition={!this.state.fetching}
              onError={
                <>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.activeDeactivate')}</h3>
                  <Loader />
                </>
              }>
              <WithConditionWrapper condition={this.state.data}>
                <TableColumnWrapper col={1}>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.scrubber.activeDeactivate')}</h3>
                  <img src={'https://inplant-public.s3.eu-west-1.amazonaws.com/mulino.png'} height={250} />
                </TableColumnWrapper>
                <TableColumnWrapper col={11}>
                  <WithConditionWrapper
                    condition={!this.state.data?.scrubber1StatusNoData}
                    onError={
                      <>
                        <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.scrubber1ActiveStatus')}</h5>
                        <div className='alert alert-warning w-100 col-sm-6 mx-auto d-print-block'>
                          {t('plantAnalysis.noDataAvailable')}
                        </div>
                      </>
                    }>
                    <GraphLineBarWithTimeline
                      topMargin={true}
                      entry={'scrubber1Status'}
                      colorsId={'activeStatus'}
                      filteredData={this.state.filteredData}
                      i18nTitle={'plantAnalysis.scrubber.scrubberStatus.scrubbers.scrubber1'}
                      lineHeight={60}
                    />
                  </WithConditionWrapper>
                  {this.renderLabelStatus('F303')}
                  <WithConditionWrapper
                    condition={!this.state.data?.scrubber2StatusNoData}
                    onError={
                      <>
                        <h5 className={'ml-5'}>{t('plantAnalysis.plantStatus.scrubber2ActiveStatus')}</h5>
                        <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                          {t('plantAnalysis.noDataAvailable')}
                        </div>
                      </>
                    }
                  >
                    <GraphLineBarWithTimeline
                      topMargin={true}
                      entry={'scrubber2Status'}
                      colorsId={'activeStatus'}
                      filteredData={this.state.filteredData}
                      i18nTitle={'plantAnalysis.scrubber.scrubberStatus.scrubbers.scrubber2'}
                      lineHeight={60}
                    />
                  </WithConditionWrapper>
                  {this.renderLabelStatus('F304')}
                  <span style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className='label-color-square mx-3'
                        style={{ backgroundColor: COLORS.scrubber.scrubberStatus.active }}
                      />
                      <span>{t('plantAnalysis.scrubber.scrubberStatus.active')}</span>
                    </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className='label-color-square mx-3'
                      style={{ backgroundColor: COLORS.scrubber.scrubberStatus.inactive }}
                    />
                    <span>{t('plantAnalysis.scrubber.scrubberStatus.inactive')}</span>
                  </span>
                </TableColumnWrapper>
              </WithConditionWrapper>
            </WithConditionWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </TableRowWrapper>
    )
  }

  private constructData() {
    try {
      const scrubber1Status = populateSingleDataFrom(this.state.data?.scrubber1Status?.data, true)
      const scrubber2Status = populateSingleDataFrom(this.state.data?.scrubber2Status?.data, true)

      let mergedData = composeData(
        {
          scrubber1Status,
          scrubber2Status,
        },
      )

      const start = moment(this.props.workshift.start.toString()).unix()
      const end = moment(this.props.workshift.end.toString()).unix()

      mergedData = fillTime(
        mergedData,
        start,
        end,
      )
      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private generateKey(data: Record<string, any>) {
    const keys = Object.keys(data)
    const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
    const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
    this.setState({
      keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
    })
  }

  private formatData(data: any) {
    try {
      const resultData = data.columns
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
              // [value]: data.values[0][index],
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
      this.generateKey(resultData)
      return [resultData]
    } catch (error) {
      consoleLog('formatData error', error)
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const startDate = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const endDate = workshift?.end
        ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
        : endOfDay

      const queryEndShift = ` WHERE ${
        workshift.value !== 99 ? `shift = ${workshift.value} AND ` : ''
      } time >= '${startDate}' AND time <= '${endDate}'`
      const queryEndShiftWithDayShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`


      try {
        const queryStart = `SELECT * FROM `

        const recapScrubber = `vScrubberActiveInActiveSeconds`
        const scrubber1StatusLabel = `vScrubberS1ActiveTimeLine`
        const scrubber2StatusLabel = `vScrubberS2ActiveTimeLine`

        const bufferRecapQuery = queryStart + recapScrubber + queryEndShiftWithDayShift
        const buffer1StateQueryShift = queryStart + scrubber1StatusLabel + queryEndShift
        const buffer2StateQueryShift = queryStart + scrubber2StatusLabel + queryEndShift

        const dataBufferRecap = API().request(`/query?${plantQueryString}q=` + bufferRecapQuery, { signal: this.abortController.signal })
        const dataBuffer1StateUpdate = API().request(`/query?${plantQueryString}q=` + buffer1StateQueryShift, { signal: this.abortController.signal })
        const dataBuffer2StateUpdate = API().request(`/query?${plantQueryString}q=` + buffer2StateQueryShift, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([dataBufferRecap, dataBuffer1StateUpdate, dataBuffer2StateUpdate])
          .then(([dataBufferStateResult, dataBuffer1StateUpdateResult, dataBuffer2StateUpdateResult]) => {
            if (this.mounted) {
              const dataBufferStateResultObject =
                typeof dataBufferStateResult === 'string' ? JSON.parse(dataBufferStateResult) : dataBufferStateResult
              const dataBuffer1StateUpdateResultObject =
                typeof dataBuffer1StateUpdateResult === 'string'
                  ? JSON.parse(dataBuffer1StateUpdateResult)
                  : dataBuffer1StateUpdateResult
              const dataBuffer2StateUpdateResultObject =
                typeof dataBuffer2StateUpdateResult === 'string'
                  ? JSON.parse(dataBuffer2StateUpdateResult)
                  : dataBuffer2StateUpdateResult
              try {
                const parsedDataBuffer1Status = parseData(dataBuffer1StateUpdateResultObject)
                const parsedDataBuffer2Status = parseData(dataBuffer2StateUpdateResultObject)
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    bufferRecap: this.formatData(dataBufferStateResultObject.results[0].series[0]),
                    scrubber1Status: { data: parsedDataBuffer1Status },
                    scrubber1StatusNoData: parsedDataBuffer1Status.length === 0,
                    scrubber2Status: { data: parsedDataBuffer2Status },
                    scrubber2StatusNoData: parsedDataBuffer2Status.length === 0,
                  }),
                  fetching: false,
                  error: false,
                }, () => {
                  this.constructData()
                })
              } catch (error) {
                this.setState({
                  scrubberState: {},
                  fetching: false,
                  data: null,
                })
              }
            }
          })
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              scrubberState: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          scrubberState: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(ScrubberStatusView))
