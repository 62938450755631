import * as React from 'react'
import SideBarNavItem from './SideBarNavItem'
import SideBarOverlay from './SideBarOverlay'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import VersionChecker from '../VersionChecker/components/VersionChecker'
import { RoutePropsCoreWithLegacy } from '../../../../types/routes'


export interface OwnState {
  isOpen: boolean
}

interface SideBarProps {
  loginSuccessPath: string
  routes: RoutePropsCoreWithLegacy[]
}

export class SideBar extends React.Component<SideBarProps, OwnState> {
  constructor(props: SideBarProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  private sideBarToggle = (open = false) => {
    this.setState({ isOpen: open })
  }

  public render() {
    return (
      <>
        <div id="sideBar" className="sidebar">
          {this.state.isOpen && <SideBarOverlay isOpen={this.state.isOpen} sideBarToggle={this.sideBarToggle} />}
          <div className={`sidebar-panel ${this.state.isOpen ? 'is-open' : ''}`}>
            <div className={'sidebar-topbar'}>
              <Link className="sidebar-logo" to={this.props.loginSuccessPath}>
                <img
                  src="/logo.png"
                  className="d-inline-block align-middle"
                  height="30"
                  title="logo"
                  onClick={() => this.sideBarToggle(false)}
                />
              </Link>
            </div>
            <aside>
              <ul className={'sidebar-menu'}>
                {this.props.routes.map((route: RoutePropsCoreWithLegacy, idx: number) => (
                  <SideBarNavItem key={`route-${idx}`} sideBarToggle={this.sideBarToggle} {...route} />
                ))}
              </ul>
            </aside>
            <VersionChecker/>
            <div id="widget-area-sidebar-bottom" className="d-none d-lg-block" />
          </div>
        </div>
        <button
          type="button"
          className={`btn btn-lg btn-link sidebar-toggler ${this.state.isOpen ? 'active' : ''}`}
          onClick={() => this.sideBarToggle(!this.state.isOpen)}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </>
    )
  }
}

export default SideBar
