import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { HaltsSeries, mapColumns } from '../../../../../redux/actions/halts'
import EventsPiePrint, { PieData } from '../../charts/EventsPie/EventsPiePrint'
import EventsVerticalChartPrint from '../../charts/EventsVerticalChart/EventsVerticalChartPrint'
import { Workshift } from '../../../../../types/workshift'
import { GeneralData } from '../../../../../types/measure'
import * as moment from 'moment'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import { parseResponseData, parseResponseSeries } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/redux/actions'
import { ChartPlaceholder } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { HaltSerie } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/hatls'
import { connect } from 'react-redux'
import PlantHaltsTimeline from '../PlantHaltsTimeline'
import { consoleLog } from '@mv-submodules/inplant-components-fe/mvfunctions/logs'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import {
  TableColumnWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  generateElementsArrayForTableRowLayout, generateTableRowFromArrayOfElements,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/generateTableRowLayout'

export type PlantHaltTypes =
  | 'vDailyPlantTimeReport'
  | 'vDailyBulkyStopImpact'
  | 'vDailyHaltCauses'
  | 'vDailyBulkyStops'
  | 'vDailyOpenGates'
  | 'vDailyManualStops'
  | 'vDailyUnjustifiedStops'
  | 'vDailyJustifiedBy'
  | 'assetsCausingClogging'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: PlantHaltTypes[]
  showTimeline?: boolean
}

interface OwnState {
  halts: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  haltsTimes: Array<Array<string | number>>
  dataClogging: PieData | null
  fetching: boolean
  fetchingClogging: boolean
  error: boolean
  errorClogging: boolean
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  // halts: state.plantAnalysis.halts,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
})

class PlantHaltsView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      halts: {},
      haltsTimes: [],
      dataClogging: null,
      fetching: false,
      fetchingClogging: false,
      error: false,
      errorClogging: false,
    }

    this.getHalts = this.getHalts.bind(this)
    this.getHaltsTimes = this.getHaltsTimes.bind(this)
    this.getSeriesData = this.getSeriesData.bind(this)
    this.getDataClogging = this.getDataClogging.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getHalts(this.props.processing)
    this.getDataClogging(this.props.processing)
    if (this.props.showTimeline) {
      this.getHaltsTimes()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getHalts(this.props.processing)
        this.getDataClogging(this.props.processing)
        if (this.props.showTimeline) {
          this.getHaltsTimes()
        }
      }
    }
  }

  public render() {
    const {
      isDateFilterRange,
      dateFilterStart,
      dateFilterEnd,
      showTimeline,
    } = this.props
    const { fetching, error, haltsTimes } = this.state

    /***** MOCK *****/
    const colors: Array<{ id: string; color: string }> = [
      {
        id: 'bulky',
        color: '#7986cb',
      },
      {
        id: 'empty',
        color: '#feebae',
      },
      {
        id: 'other',
        color: '#dde9e9',
      },
      {
        id: 'runningTime',
        color: '#c6f5bc',
      },
      {
        id: 'morning_delay',
        color: '#720806',
      },
      {
        id: 'pause_delay',
        color: '#fb9606',
      },
    ]
    /***** END MOCK *****/

    return (
      <>
        <WithConditionWrapper condition={!fetching && error}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        </WithConditionWrapper>
        <WithConditionWrapper condition={!!showTimeline}>
          <WithConditionWrapper
            condition={!isDateFilterRange}
            onError={
              <div className='col-12 chart-placeholder mb-5'>
                {ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
              </div>
            }
          >
            <PlantHaltsTimeline data={haltsTimes} dateStart={dateFilterStart} dateEnd={dateFilterEnd} />
          </WithConditionWrapper>
        </WithConditionWrapper>
        <WithConditionWrapper condition={!error}>
          {this.generatePieGraphLayout([
            this.renderVDailyPlantTimeReport(colors),
            this.renderVDailyBulkyStopImpact(),
            this.renderAssetsCausingClogging(colors),
            this.renderVDailyUnjustifiedStops(),
            this.renderVDailyJustifiedBy(),
          ])}
          {this.generateHorizontalGraphLayout([
            this.renderVDailyHaltCauses(),
            this.renderVDailyBulkyStops(),
            this.renderVDailyOpenGates(),
            this.renderVDailyManualStops(),
          ])}
        </WithConditionWrapper>
      </>
    )
  }

  private generatePieGraphLayout(elements: JSX.Element[]) {
    return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 2))
  }

  private generateHorizontalGraphLayout(elements: JSX.Element[]) {
    return generateTableRowFromArrayOfElements(generateElementsArrayForTableRowLayout(elements, 2))
  }

  private renderVDailyPlantTimeReport(colors: Array<{ id: string; color: string }>) {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyBulkyStopImpact')}
    >
      <h5>{this.props.t('plantAnalysis.halts.plantTimeReport.title')}</h5>
      <EventsPiePrint
        colors={colors}
        data={this.getSeriesData('vDailyPlantTimeReport')}
        days={this.props.days}
        fetching={this.state.fetching}
        totalsLabel={this.props.t('plantAnalysis.halts.chartLabels.totalWorkingTime')}
        totalsReference={
          this.props.standardTime && this.props.standardTime !== 0 && this.props.days
            ? {
              label: this.props.t('plantAnalysis.halts.chartLabels.plantStandardWorkingTime'),
              seconds: this.props.standardTime * this.props.days,
            }
            : undefined
        }
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyBulkyStopImpact() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyBulkyStopImpact')}
    >
      <h5>{this.props.t('plantAnalysis.halts.bulkyStopImpact.title')}</h5>
      <EventsPiePrint
        colors={[
          { id: 'bulky', color: '#7986CB' },
          { id: 'other', color: '#dde9e9' },
        ]}
        data={this.getSeriesData('vDailyBulkyStopImpact')}
        days={this.props.days}
        fetching={this.state.fetching}
        totalsLabel={this.props.t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderAssetsCausingClogging(colors: Array<{ id: string; color: string }>) {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('assetsCausingClogging')}
    >
      <h5>{this.props.t('plantAnalysis.halts.assetsCausingClogging.title')}</h5>
      <EventsPiePrint
        colors={colors}
        data={this.state.dataClogging}
        days={this.props.days}
        fetching={this.state.fetchingClogging}
        hideTime={true}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyUnjustifiedStops() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyUnjustifiedStops')}
    >
      <h5>{this.props.t('plantAnalysis.halts.dailyUnjustifiedStops.title')}</h5>
      <EventsPiePrint
        data={this.getSeriesData('vDailyUnjustifiedStops')}
        days={this.props.days}
        colors={[
          { id: 'unjustified', color: '#fbc106' },
          { id: 'other', color: '#dde9e9' },
        ]}
        fetching={this.state.fetching}
        chartLabels={'dailyUnjustifiedStops.labels'}
        totalsLabel={this.props.t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyJustifiedBy() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyJustifiedBy')}
    >
      <h5>{this.props.t('plantAnalysis.halts.dailyJustifiedBy.title')}</h5>
      <EventsPiePrint
        chartLabels={'dailyJustifiedBy.labels'}
        colors={[
          { id: 'justified_by_users', color: '#c6f5bc' },
          { id: 'justified_by_system', color: '#720806' },
        ]}
        data={this.getSeriesData('vDailyJustifiedBy')}
        days={this.props.days}
        fetching={this.state.fetching}
        totalsLabel={this.props.t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyHaltCauses() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyHaltCauses')}
    >
      <h5>{this.props.t('plantAnalysis.halts.haltCauses.title')}</h5>
      <EventsVerticalChartPrint
        colorBar={'#4DB6AC'}
        data={this.getSeriesData('vDailyHaltCauses')}
        fetching={this.state.fetching}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyBulkyStops() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyBulkyStops')}
    >
      <h5>{this.props.t('plantAnalysis.halts.bulkyStops.title')}</h5>
      <EventsVerticalChartPrint
        colorBar={'#7986CB'}
        data={this.getSeriesData('vDailyBulkyStops')}
        fetching={this.state.fetching}
        labelPrefix={this.props.t('plantAnalysis.halts.chartLabels.Button') + ' '}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyOpenGates() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyOpenGates')}
    >
      <h5>{this.props.t('plantAnalysis.halts.openGates.title')}</h5>
      <EventsVerticalChartPrint
        colorBar={'#BA68C8'}
        data={this.getSeriesData('vDailyOpenGates')}
        fetching={this.state.fetching}
        hideZero={true}
      />
    </TableColumnWrapperWithCondition>
  }

  private renderVDailyManualStops() {
    return <TableColumnWrapperWithCondition
      className={'px-4 py-3'}
      col={'half'}
      condition={!this.props.hiddenCharts || !this.props.hiddenCharts.includes('vDailyManualStops')}
    >
      <h5>{this.props.t('plantAnalysis.halts.manualStops.title')}</h5>
      <EventsVerticalChartPrint
        colorBar={'#64B5F6'}
        data={this.getSeriesData('vDailyManualStops')}
        hideZero={false}
        shortLabels={true}
      />
    </TableColumnWrapperWithCondition>
  }

  private getSeriesData(id: HaltSerie) {
    if (id === 'vDailyHaltCauses') {
      consoleLog(this.state.halts && this.state.halts[id])
    }
    return (this.state.halts && this.state.halts[id]) || null
  }

  /**
   * Fetches data for all Halts charts
   * @param processing
   * @private
   */
  private async getHalts(processing?: number) {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd } = this.props
    const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
    const startDate = moment(dateFilterStart)
      .set({ h: 0, m: 0, s: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate = moment(dateFilterEnd)
      .set({ h: 23, m: 59, s: 59 })
      // .add(fetchDayAfter ? 1 : 0, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    const filteredSeries = HaltsSeries.filter(s => !this.props.hiddenCharts || !this.props.hiddenCharts.includes(s))
    const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
    const queries = filteredSeries.map(
      e =>
        `${plantQueryString}q=SELECT ${mapColumns[e] ? mapColumns[e] : '*'} FROM ` +
        `"${e + (processing ? '_' + processing : '')}"` +
        queryEnd,
    )

    this.setState({
      halts: {},
      fetching: true,
      error: false,
    })

    try {
      Promise.all(queries.map(q => API().request(`/query?${q}`))).then(results => {
        const out = {}
        filteredSeries.forEach((e, i) => {
          out[e] = parseResponseSeries(e, results[i])
        })
        this.setState({
          halts: out,
          fetching: false,
          error: false,
        })
      })
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        halts: {},
        fetching: false,
        error: true,
      })
    }
  }

  /**
   * Fetches data for timeline
   * @private
   */
  private async getHaltsTimes() {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd } = this.props
    const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
    const startDate = moment(dateFilterStart)
      .set({ h: 0, m: 0, s: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate = moment(dateFilterEnd)
      .set({ h: 23, m: 59, s: 59 })
      // .add(fetchDayAfter ? 1 : 0, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
    const query = `${plantQueryString}q=SELECT * from PLANT_STATE_AND_MATERIAL ` + queryEnd

    this.setState({
      haltsTimes: [],
      fetching: true,
      error: false,
    })

    try {
      const dataSrc = await API().request(`/query?${query}`)
      const haltsTimes = parseResponseData(dataSrc)

      this.setState({
        haltsTimes,
        fetching: false,
        error: false,
      })
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        haltsTimes: [],
        fetching: false,
        error: true,
      })
    }
  }

  /**
   * Fetches data for assets causing clogging chart
   * @private
   */
  private async getDataClogging(processing?: number) {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd, hiddenCharts } = this.props

    if (!hiddenCharts || !hiddenCharts.includes('assetsCausingClogging')) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startDate = moment(dateFilterStart)
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDate = moment(dateFilterEnd)
        .set({ h: 23, m: 59, s: 59 })
        // .add(fetchDayAfter ? 1 : 0, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const query =
        `${plantQueryString}q=SELECT * from vDailyAssetsCausingClogging${processing ? '_' + processing : ''} ` +
        queryEnd

      this.setState({
        fetchingClogging: true,
        errorClogging: false,
      })

      try {
        const dataClogging = await API().request(`/query?${query}`, { signal: this.abortController.signal })

        if (this.mounted) {
          this.setState({
            dataClogging: PlantHaltsView.prepareCloggingData(JSON.parse(dataClogging)),
            fetchingClogging: false,
            errorClogging: false,
          })
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log(error) // tslint:disable-line
        this.setState({
          fetchingClogging: false,
          errorClogging: true,
        })
      }
    }
  }

  /**
   * Prepares data for EventsPie component
   *
   * @summary Needed since EventsPie was designed to get data from InfluxDB series
   * @private
   * @param dataSrc
   */

  private static prepareCloggingData(dataSrc: {
    results?: Array<{
      series?: Array<{
        columns: string[]
        name: string
        values: Array<[string, number, string]>
      }>
    }>
  }) {
    const columns: string[] = []
    const values: number[] = []
    let data: any[] = []
    let parsedData = {}
    const temp = {}
    let sum = 0

    /**
     * dataSrc is from influx, every row contains a stringified json string.
     * We decode it and creates an object with key => value
     */
    if (
      dataSrc.results &&
      dataSrc.results[0] &&
      dataSrc.results[0].series &&
      dataSrc.results[0].series[0] &&
      dataSrc.results[0].series[0].values
    ) {
      data = dataSrc.results[0].series[0].values.map(d => JSON.parse(d[2]))

      if (data) {
        parsedData = data.reduce((acc, val) => {
          if (val && Array.isArray(val)) {
            val?.forEach(
              (v: {
                Asset: {
                  Id: string
                  Name: string
                }
                ClogCount: string
                Code: string
              }) => {
                acc[v.Asset.Name] =
                  (v.ClogCount ? parseInt(v.ClogCount, 10) : 0) +
                  (!acc.hasOwnProperty(v.Asset.Name) ? 0 : acc[v.Asset.Name])
              },
            )
          }

          return acc
        }, {})
      }
    }

    /**
     * We create a new object where the key is <measure name>_events and contains clogging data
     * We also sum all values for percentage calculation
     */
    Object.keys(parsedData).forEach(d => {
      temp[d + '_events'] = parsedData[d]
      sum += parsedData[d]
    })

    /**
     * We add <measure name>_perc key, value is from <measure name>_events / summed events
     */
    Object.keys(parsedData).forEach(d => {
      temp[d + '_perc'] = sum === 0 ? 0 : parsedData[d] / sum
    })

    /**
     * Populating columns and values arrays
     */
    Object.keys(temp).forEach(k => {
      columns.push(k)
      values.push(temp[k])
    })

    /**
     * Data formatted for EventsPie chart
     */
    const output: PieData = {
      name: 'assetsCausingClogging',
      columns,
      values: [values],
    }

    return output
  }
}

export default connect(mapStateToProps)(withTranslation()(PlantHaltsView))
