import * as React from 'react'
import { HHHmmssFromMilliseconds } from '../../../../../functions/shared'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Configurations } from '../../../../../redux/reducers/configurations'
import { connect } from 'react-redux'
import { defaultPlantAnalysisGeneralSettings } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/settings'

export interface MeasureDistributionBarData {
  perc: number
  duration: number
  chartLabel?: string
  color: string
  key: string
  value?: number | string
}

interface OwnProps {
  data: MeasureDistributionBarData[]
  customSettingsFieldsCode?: "308*" 
}

interface StateProps {
  plant: any | null
  configurations: null | Configurations
}


const mapStateToProps = ( state: any ): StateProps => ({
  plant: state.plantSelector || null,
  configurations: state.plantAnalysis.configurations,
})

type Props = OwnProps & WithTranslation & StateProps

class MeasureDistributionBarView extends React.PureComponent<Props> {
  constructor( props: Props ) {
    super(props)

    this.getReferenceValueFromSettings = this.getReferenceValueFromSettings.bind(this)
    this.getCurrentConfiguration = this.getCurrentConfiguration.bind(this)
  }

  public render() {
    const { data, t } = this.props

    return (
      <div className="row">
        <div className="col-md-6 mt-2">
          <div className="measure-distribution-graph-container mt-4">
            {data.map(( measureDistributionBarData: MeasureDistributionBarData, i ) => (
              <div
                key={i}
                style={{
                  width: `${measureDistributionBarData.perc}%`,
                  backgroundColor: measureDistributionBarData.color,
                }}
                className="measure-distribution-graph-element"
              />
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <table className={'table table-borderless table-sm w-100 mw-300 font-size-12'}>
            <thead>
            <tr>
              <th/>
              <th className="text-right">%</th>
              <th className="text-right">{t('plantAnalysis.halts.labels.time')}</th>
            </tr>
            </thead>

            <tbody>
            {data.map(datum => (
              <tr key={`key_${datum.key}`}>
                <td>
                  <span className="label-color-square" style={{ backgroundColor: datum.color }}/>{' '}
                  {t(`plantAnalysis.labels.${datum.chartLabel ? datum.chartLabel + '.' : ''}${datum.key}`, {
                    defaultValue: datum.key,
                    value: this.getReferenceValueFromSettings(datum.key)
                  })}
                </td>
                <td className="text-right">{datum.perc ? datum.perc.toFixed(2) : 0}</td>
                <td className="text-right">{HHHmmssFromMilliseconds(datum.duration * 1000, false, true, true)}</td>
                <td/>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  private getCurrentConfiguration() {
    const { configurations, plant } = this.props
    return (
      (configurations && configurations.data && configurations.data && plant && configurations.data[plant.plant]) ||
      defaultPlantAnalysisGeneralSettings
    )
  }

  private getReferenceValueFromSettings(key: string) {
    let out: string | number | null = null
    const configuration = this.getCurrentConfiguration()
    let additionalSlug = ''
    if (this.props.customSettingsFieldsCode) {
      additionalSlug = this.props.customSettingsFieldsCode
    }

    switch (key) {
      case 'low':
        out = configuration && configuration.settingsFields && configuration.settingsFields[`soglia_under_speed${additionalSlug}`] || null
        break

      case 'high':
        out = configuration && configuration.settingsFields && configuration.settingsFields[`soglia_over_speed${additionalSlug}`] || null
        break

      default:
        break
    }

    return out
  }
}

export default connect(
  mapStateToProps, {},
)(withTranslation()(MeasureDistributionBarView))
