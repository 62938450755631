import {Fieldset, FieldsetField, FormData} from '../../types/pageForm'
import { DueDatesConfig } from '../../types/pageConfig'
import {DueDatesOptions, Target} from '../../types/chronoTasks'
import * as moment from 'moment'

// configurazione dei filedset standard


export const pageConfigReconciliation = (
  config: DueDatesConfig
): DueDatesConfig => {
  const serializeFields = (fieldsetArrays?: Fieldset[]) => (acc: Fieldset[], fieldset: Fieldset): Fieldset[] => {
    switch (fieldset.fieldset) {
      case 'dueDates':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                {
                  slug: 'effectiveFromDate',
                  type: 'date',
                },
                {
                  slug: 'dueAtDate',
                  type: 'date',
                  min: moment().format('YYYY-MM-DD'),
                },
              ],
            },
          ],
        })
        break
      // cespiti
      case 'assets':
        const existDestination = fieldsetArrays && fieldsetArrays.reduce((existField: boolean, fieldSet: Fieldset) => existField ||
          fieldSet.fields.reduce((resultFieldset: boolean, fieldSetField: FieldsetField) => resultFieldset || findCustomField(fieldSetField, 'destination'), false), false)
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'group',
              slug: 'target',
              fields: [
                {
                  type: 'row',
                  fields: [
                    {
                      slug: 'target.group',
                      type: 'select',
                      relSlug: existDestination ? 'destination' : undefined,
                      relType: existDestination ? 'disabled' : undefined,
                      multiple: false,
                      options: config.values.assetGroups!.map(value => ({
                        value: value.id,
                        label: value.name,
                      })),
                    },
                    {
                      slug: 'target.asset',
                      type: 'select',
                      multiple: false,
                      relSlug: 'target.group',
                      relType: 'options',
                      options: config.values.assets!.map(value => ({
                        value: value.id,
                        label: value.name,
                        rel: value.groups,
                      })),
                    },
                    // data scadenza per cespite
                    // currently commented since relSlug seems to not work properly
                    // {
                    //   slug: "target.dueDate",
                    //   type: "date",
                    //   relSlug:"splitTargets",
                    //   relType: "visible"
                    // }
                  ],
                },
              ],
            },
          ],
        })
        break
      case 'visibilityAndAssignee':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                {
                  slug: 'rolesWithVisibility',
                  type: 'checkbox',
                  multiple: true,
                  inline: true,
                  required: true,
                  options: config.values.rolesForVisibility!.map(value => ({
                    value: value.code,
                    label: value.name,
                  })),
                }

              ],

            },
            {
              type: 'row',
              fields: [
                {
                  slug: 'rolesWithAction',
                  type: 'checkbox',
                  multiple: true,
                  inline: true,
                  required: true,
                  options: config.values.rolesForVisibility!.map(value => ({
                    value: value.code,
                    label: value.name,
                  })),
                },
              ],

            },
            {
              type: 'row',
              fields: [
                {
                  slug: 'inChargeUser',
                  type: 'select',
                  multiple: false,
                  options: config.values.usersForInCharge!.map(value => ({
                    value: value.id,
                    label: value.displayName,
                    rel: value.roles,
                  })),
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        })
        break
      case 'validation':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                // @ts-ignore
                {
                  slug: 'mustBeValidated',
                  type: 'checkbox',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  slug: 'rolesWithValidation',
                  type: 'checkbox',
                  multiple: true,
                  inline: true,
                  required: true,
                  relSlug: 'mustBeValidated',
                  relType: 'visible',
                  options: config.values.rolesForValidation!.map(value => ({
                    value: value.code,
                    label: value.name,
                  })),
                },
              ],
            },
          ],
        })
        break
      case 'automaticRenewal':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                // @ts-ignore
                {
                  slug: 'automaticRenewal',
                  type: 'checkbox',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'automaticRenewal',
              relSlug: 'automaticRenewal',
              relType: 'visible',
              required: true,
            },
          ],
        })
        break
      case 'noticeMessage':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                // @ts-ignore
                {
                  slug: 'noticeMessage',
                  type: 'checkbox',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              relSlug: 'noticeMessage',
              relType: 'visible',
              fields: [
                {
                  slug: 'notificationDaysAdvance',
                  type: 'number',
                  min: 1,
                  required: true,
                },
                {
                  type: 'empty',
                  size: 8,
                },
              ],
            },
          ],
        })
        break
      // @ts-ignore
      case 'description':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                {
                  slug: 'description',
                  type: 'textarea',
                  rows: 5,
                },
              ],
            },
          ],
        })
        break
      case 'splitTargets':
        acc.push({
          ...fieldset,
          fields: [
            // {
            //   type: 'row',
            //   fields: [
            //     {
            //       slug: 'dueAtDate',
            //       type: 'date',
            // min: moment().format('YYYY-MM-DD'),
            //     },
            //   ],
            // },
            {
              type: 'row',
              fields: [
                // @ts-ignore
                {
                  slug: 'splitTargets',
                  type: 'checkbox',
                },
              ],
            },
          ],
        })
        break
      case 'attachments':
        acc.push({
          ...fieldset,
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'attachments',
                  relType: 'visible',
                  relSlug: 'attachments',
                  slug: 'attachments',
                },
              ],
            },
          ],
        })
        break
      default:
        acc.push(fieldset)
        break
    }
    return acc
  }

  const manipulateTableColumns = () => {
    const columns = config.tableColumns
    if (config.batchActions && config.batchActions.length > 0){
      columns.unshift({
        slug: ['batchActions'],
        sortable: false,
        type: ['batchActions'],
      })
    }
    columns.push({
      slug: ['tableGroupsButton'],
      sortable: false,
      type: ['tableGroupsButton']
    })
    return columns
  }

  const formFields = config.formFields.reduce(serializeFields(), [])
  const tableAdvancedFilters = config.tableAdvancedFilters.reduce(
    serializeFields(config.tableAdvancedFilters),
    [],
  )
  const tableColumns = manipulateTableColumns()
// TODO handle multiple actions
  return {
    ...config,
    formFields,
    tableAdvancedFilters,
    tableColumns
  }
}

export const pageDetailToForm = (data: FormData, options?: DueDatesOptions) => {
  return Object.keys(data).reduce((acc: FormData, key: string) => {
    switch (key) {
      case 'status':
      case 'assigneeUser':
      case 'inChargeUser':
      case 'costCenter':
        return {
          ...acc,
          [key]: data[key] && data[key].id,
        }
      // Parsed in GenericFieldset
      // case "rolesWithValidation":
      //   return {
      //     ...acc,
      //     [key]: data[key] && data[key][0]
      //   }
      case 'target':
        return {
          ...acc,
          [key]:
          data.target &&
          data.target.map(({asset, group}: Target) => ({
            asset,
            group:
              group ||
              (options &&
              options.assets &&
              options.assets.find(({id}) => id === asset) ? options.assets.find(({id}) => id === asset)!.groups[0] : undefined),
          })),
        }
      default:
        return {
          ...acc,
          [key]: data[key],
        }
    }
  }, {})
}

const findCustomField = (fieldset: FieldsetField, searchedField: string) => {
  if (!fieldset) {
    return false
  }
  /* tslint:disable:no-string-literal */
  if (fieldset['slug'] === searchedField) {
    return true
  }
  if (fieldset['fields']) {
    return fieldset['fields'].reduce((existField: boolean, fieldSetField: FieldsetField) => existField || findCustomField(fieldSetField, searchedField), false)
  }
  return false
}

export const pageConfigUpdateFormFields = (config: DueDatesConfig) => {
  const res = pageConfigReconciliation(config)
  return {
    formFields: res.formFields,
    tableAdvancedFilters: res.tableAdvancedFilters,
  }
}
