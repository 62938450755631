import { getContainer } from '../../mvlabs-components-fe/services/binding'
import initIcons from './icons'
import initStateManagement from './stateManagement'
import initTranslation from './translations'

const initServices = () => {
  const container = getContainer()

  initTranslation(container)
  initIcons(container)
  initStateManagement(container)

  return container
}

export default initServices
