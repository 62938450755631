// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import InvalidFeedback from "./InvalidFeedback";

interface Props {
  checked: boolean
  className?: string
  disabled?: boolean
  error?: string
  id?: string
  label: string
  multiple?: boolean
  name?: string
  onChange: (currentTarget: HTMLInputElement) => void
  readOnly?: boolean
  required?: boolean
  value?: string
}

// @TODO REFACTORING: error support

class Checkbox extends React.Component<Props> {
  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this.inputRef = React.createRef()
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLabelClick = this.handleLabelClick.bind(this)
  }

  public render() {
    const props = this.props
    return (

      <div key={`${props.name}+${props.checked}`} className='form-check'>
        <input
          checked={!!props.checked}
          className={props.className}
          disabled={props.disabled}
          id={props.id}
          multiple={props.multiple}
          name={props.name}
          onChange={this.handleInputChange}
          readOnly={props.readOnly}
          ref={this.inputRef}
          required={props.required}
          type='checkbox'
          value={props.value}
        />
        <label onClick={this.handleLabelClick} className='form-check-label pl-1'>
          {props.label}
        </label>
        {props.error && <InvalidFeedback error={props.error} />}
      </div>
    )
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange(event.currentTarget)
  }

  private handleLabelClick() {
    const currentTarget = this.inputRef.current!
    currentTarget.checked = !currentTarget.checked
    this.props.onChange(currentTarget)
  }
}

export default Checkbox
