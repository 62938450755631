import { combineReducers} from 'redux'
import { assetsList } from './assetsList'
import { groupsList} from './groupsList'

const assetManagerReducer = combineReducers({
  assetsList,
  groupsList
})

export type AssetManagerReducer = ReturnType<typeof assetManagerReducer>
export default  assetManagerReducer
