import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { DueDatesOptions } from '../../types/chronoTasks'
import { FormData } from '../../types/pageForm'
import { API } from './index'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { consoleLog } from '../../../mvlabs-components-fe/functions/logs'

export enum Action {
  create = '/create',
  edit = '/edit'
}

export const submitData = (data: FormData,action?:Action) => async (dispatch: Dispatch) => {
  consoleLog('submitData')
  return API().request(`/chrono-frame/chrono-tasks${action ? action : Action.create}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Content-Language': 'it-IT',
    },
    body: JSON.stringify(data),
  }).then((result: FormData) => result)
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const submitTemplate = (data: FormData, options: DueDatesOptions) => async (dispatch: Dispatch) => {
  try {
    const result: FormData = await API().request('/chrono-frame/chrono-task-templates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
      body: JSON.stringify(data),
    })
    // const result: FormData = { 'dueAtDate': '2019-12-19', 'repetitionRule.days': 111 } // TEMPORARY!!!
    return result
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}
