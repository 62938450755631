import { AnyAction } from 'redux'

const titechInitialState: any = {
  data: null,
  error: false,
  fetching: false,
}

export const titech = (state = titechInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_TITECH_IS_FETCHING':
      return {
        ...state,
        data: null,
        fetching: true,
        error: false,
      }
    case 'PLANTANALYSIS_TITECH_FETCH_SUCCESS':
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
      }
    case 'PLANTANALYSIS_TITECH_FETCH_ERROR':
      return {
        ...state,
        fetching: false,
        error: true,
      }

    default:
      return state
  }
}

export default titech
