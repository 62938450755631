import { combineReducers, AnyAction } from 'redux'
import { brandingManager, BrandingManagerStore } from './branding'
import store from 'src/store/configureStore'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import { settingsManager, SettingsStore } from './settings'

const coreReducersMap = {
  brandingManager,
  settingsManager,
}

const coreControlReducers = combineReducers(coreReducersMap)
export default coreControlReducers

export interface CoreReducers {
  brandingManager: BrandingManagerStore
  settingsManager: SettingsStore
}


// --------------------- REDUX for others modules --------------------------
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

/**
 * @template R = return type
 * @template E = external parameters
 */
export type AppAction<R, E = unknown> = ThunkAction<R, RootState, E, AnyAction>

export const state: () => RootState = () => store.getState()

// -------------------- Action to retrieve storage data
export const APP_PERSIST = 'persist/REHYDRATE'

export interface AppManagerPersist {
  type: typeof APP_PERSIST
  payload: RootState
}
