// * -------------------------------- MODULE --------------------------------------
import { LightNode, NodeData, ProcessedNodeData } from './types'

export const getNodeFromString = (root: NodeData, nodeId: string): LightNode | undefined => {
  if (root.id === nodeId) {
    return { id: root.id, label: root.label }
  }
  return root.children?.map(c => getNodeFromString(c, nodeId)).find(el => el !== undefined)
}

export const isNodeInTree = (root: NodeData, node: { label: string; id: string }) => {
  return getNodeFromString(root, node.id)
}

type Id = string

export const getAllChildren = (node: ProcessedNodeData): Record<Id, LightNode> => {
  let result: Record<Id, LightNode> = {}
  if(node.children !== undefined) {
    node.children?.forEach(c => {
      const lightNode: LightNode = {
        id: c.id,
        label: c.label 
      }
      result = {...result, [c.id]: lightNode, ...getAllChildren(c)}
    })
  }
  return result
}

export const removeItemFrom = (list: LightNode[], item: LightNode): LightNode[] => {
  return list.filter(el => el.id !== item.id)
}

export const areSameTree = (tree1?: NodeData, tree2?: NodeData): boolean => {
  if (!tree1 || !tree2) {
    return false
  }
  return (
    tree1.id === tree2.id &&
    tree1.children?.length === tree2.children?.length &&
    (tree1.children !== undefined
      ? tree1.children.reduce((acc: boolean, curr, index) => areSameTree(curr, tree2.children?.[index]) && acc, true)
      : true)
  )
}
