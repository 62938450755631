import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  LegendPayload,
  Line, ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { getHaltsData, HHHmmssFromMilliseconds, Loader } from '../../../../../functions/shared'

interface PieData {
  name: string
  columns: string[]
  values: any[]
}

interface OwnProps {
  data: PieData | null
  colorBar?: string
  colorLine?: string
  fetching?: boolean
  hideZero?: boolean
  labelPrefix?: string
  shortLabels?: boolean
}


interface EventType {
  key: string
  events: number
  duration: number
  perc: number
}

type Props = OwnProps & WithTranslation

class EventsVerticalChartView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  private static getDataElement(id: string, data: EventType[]) {
    return data.find(d => d.key === id)
  }

  public render() {
    const { t, data, colorBar, colorLine, hideZero } = this.props

    if (!data || !data.values || !data.values[0] || data.values[0].length < 1) {
      return <div className="alert alert-warning w-100  d-print-block">{t('plantAnalysis.noDataAvailable')}</div>
    }

    if(this.props.fetching) {
      return <div className='alert alert-secondary w-100 col-sm-6 mx-auto rpp-graph-fetch-loading alert-local'>
        {this.props.t('plantAnalysis.loading')}
        <Loader />
      </div>
    }

    const values = getHaltsData(data, undefined, hideZero)

    if(values.length === 0 || values.every(v => v.events === 0)) {
      return <div className="alert alert-warning w-100 d-print-block">{t('plantAnalysis.noDataAvailable')}</div>
    }

    const translatedTick = (props: { x?: any; y?: any; stroke?: any; payload?: any; index?: number }) => {
      const { x, y, payload } = props
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={4} dx={0} textAnchor="end" fontSize={12}>
            <tspan>
              {this.props.shortLabels && (
                <title>
                  {t('plantAnalysis.halts.chartLabelsTooltip.' + payload.value, { defaultValue: payload.value })}
                </title>
              )}
              {this.props.labelPrefix}
              {t('plantAnalysis.halts.chartLabels.' + payload.value, { defaultValue: payload.value })}
            </tspan>
          </text>
        </g>
      )
    }

    const customTick = (props: { x?: any; y?: any; stroke?: any; payload?: any; index?: number }) => {
      const { x, y, payload, index } = props
      const dataElement = EventsVerticalChartView.getDataElement(payload.value, values)
      if (!dataElement) {
        return null
      }

      return (
        <g transform={`translate(${x},${y})`}>
          {index === 0 && (
            <g>
              <text x={0} y={0} dy={-30} dx={35} textAnchor="end">
                <tspan fontWeight="bold">{t('plantAnalysis.halts.labels.events')}</tspan>
              </text>
              <text x={0} y={0} dy={-30} dx={115} textAnchor="end">
                <tspan fontWeight="bold">{t('plantAnalysis.halts.labels.time')}</tspan>
              </text>
            </g>
          )}

          <text x={0} y={0} dy={4} dx={35} textAnchor="end" fontSize={12} className={'font-monospace-roboto'}>
            {dataElement.events}
          </text>

          <text x={0} y={0} dy={4} dx={115} textAnchor="end" fontSize={12} className={'font-monospace-roboto'}>
            {HHHmmssFromMilliseconds(dataElement.duration * 1000, false, true, true)}
          </text>
        </g>
      )
    }

    const totals = values.reduce(
      (acc: { events: number; duration: number }, curr: { events: number; duration: number }) => {
        return {
          events: acc.events + curr.events,
          duration: acc.duration + curr.duration,
        }
      },
      { events: 0, duration: 0 }
    )

    const renderColorfulLegendText = (value?: LegendPayload['value']) => {
      return (
        <span className="legend-counter">
          {value}{' '}
          <small>
            ( {value === 'duration' ? HHHmmssFromMilliseconds(totals[value] * 1000, false, true, true) : totals[value]}{' '}
            )
          </small>
        </span>
      )
    }

    return (
      <div className="row chart-events-vertical">
        <div className="col-md-12 col-sm-12 col-lg-12 chart-events-vertical--chart">
          <ResponsiveContainer width={"100%"} height={250}>
            <ComposedChart
              layout="vertical"
              width={800}
              height={250}
              data={
                // sorting numeric and alpha
                values.filter(v => v.events !== 0).sort((a, b) =>
                  parseInt(a.key, 10).toString() === a.key
                    ? parseInt(a.key, 10) < parseInt(b.key, 10)
                    ? -1
                    : 1
                    : a.key < b.key
                    ? -1
                    : 1
                )
              }
              margin={{
                top: 30,
                right: 20,
                bottom: 20,
                left: 20,
              }}
              barCategoryGap={7}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" hide={true} xAxisId={'big'} />
              <XAxis type="number" hide={true} xAxisId={'small'} />

              <YAxis
                dataKey="key"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={customTick}
                yAxisId={'right'}
                orientation={'right'}
                width={150}
                interval={0}
              />
              <YAxis
                dataKey="key"
                type="category"
                interval={0}
                yAxisId={'left'}
                orientation={'left'}
                width={120}
                tick={translatedTick}
              />

              <Bar
                dataKey="duration"
                barSize={15}
                fill={colorBar || '#4DB6AC'}
                yAxisId={'left'}
                xAxisId={'big'}
                isAnimationActive={false}
              />
              <Line
                dataKey="events"
                stroke={colorLine || '#BA68C8'}
                yAxisId={'left'}
                xAxisId={'small'}
                dot={{ stroke: colorLine || '#BA68C8', strokeWidth: 2 }}
                isAnimationActive={false}
              />
              <Legend margin={{ top: 80 }} height={46} verticalAlign={'bottom'} formatter={renderColorfulLegendText} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default withTranslation()(EventsVerticalChartView)
