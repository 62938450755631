import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { Select, Input } from '@mv-submodules/inplant-components-fe'
import { FormFieldsMap } from '../../../../types/pageForm'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnProps {
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
  required?: boolean
  errors?: { [field: string]: string[] }
  refs?: FormFieldsMap<React.RefObject<any>>
}

export type Props = OwnProps & WithTranslation

class AutomaticRenewalField extends React.Component<Props, {}> {
  public dayOfMonthRef: any = null

  constructor(props: Props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getFieldError = this.getFieldError.bind(this)
    this.dayOfMonthRef = React.createRef()
  }

  public componentDidMount() {
    // On component creation triggers inputChange to set the first selected values of selects components
    if (!this.props.getInputValue('repetitionRule.method')){
        this.props.onInputChange('repetitionRule.method', 'FromDueDate')
    }
    if (!this.props.getInputValue('repetitionRule.method')){
      this.props.onInputChange('repetitionRule.period', 'days')
    }
  }

  public componentDidUpdate() {
    if (
      // triggers onInputChange when dayOfMonth field is mounted/filled
      this.props.getInputValue('repetitionRule.method') === 'FromDueDate' &&
      this.props.getInputValue('repetitionRule.period') === 'months' &&
      this.dayOfMonthRef.current &&
      this.dayOfMonthRef.current.value !== '' &&
      this.dayOfMonthRef.current.value !==
      this.props.getInputValue('repetitionRule.dayOfMonth')
    ) {
      this.props.onInputChange(
        'repetitionRule.dayOfMonth',
        this.dayOfMonthRef.current.value
      )
    }
  }

  public render() {
    const t = this.props.t
    const props = this.props
    const method = this.props.getInputValue('repetitionRule.method')
    const period = this.props.getInputValue('repetitionRule.period')
    return (
      <Row spacing={{vertical: false, horizontal: false}}>
        <Column>
          <Select
            label={t(`chronoframe.dueDates.formField.repetitionRule.method`)}
            ref={props.refs && props.refs['repetitionRule.method']}
            id="repetitionRule.method"
            name="repetitionRule.method"
            required={props.required}
            options={{
              items: [
                {
                  value: 'FromDueDate',
                  label: t(
                    `chronoframe.dueDates.formField.repetitionFromDueDate`
                  ),
                },
                {
                  value: 'FromDoneDate',
                  label: t(
                    `chronoframe.dueDates.formField.repetitionFromDoneDate`
                  ),
                },
              ]
            }}
            value={props.getInputValue('repetitionRule.method')}
            onChange={this.handleInputChange('repetitionRule.method')}
            error={this.getFieldError('repetitionRule.method')}
          />
        </Column>
        <Column>
          <Select
            label={t(`chronoframe.dueDates.formField.repetitionRule.period`)}
            ref={props.refs && props.refs!['repetitionRule.period']}
            id="repetitionRule.period"
            name="repetitionRule.period"
            required={props.required}
            options={{
              items: [
                {
                  value: 'days',
                  label: t(`chronoframe.dueDates.formField.repetitionRule.days`),
                },
                {
                  value: 'months',
                  label: t(
                    `chronoframe.dueDates.formField.repetitionRule.months`
                  ),
                },
              ]
            }}
            value={props.getInputValue('repetitionRule.period')}
            onChange={this.handleInputChange('repetitionRule.period')}
            error={this.getFieldError('repetitionRule.period')}
          />
        </Column>
        {period === 'days' && (
          <Column>
            <Input
              label={t(
                `chronoframe.dueDates.formField.repetitionRule.daysNumber`
              )}
              ref={props.refs && props.refs['repetitionRule.days']}
              id="repetitionRule.count"
              name="repetitionRule.count"
              type="number"
              required={props.required}
              min={1}
              value={props.getInputValue('repetitionRule.count')}
              onChange={this.handleInputChange('repetitionRule.count')}
              error={this.getFieldError('repetitionRule.count')}
            />
          </Column>
        )}
        {period === 'months' && (
          <Column>
            <Input
              label={t(
                `chronoframe.dueDates.formField.repetitionRule.monthsNumber`
              )}
              ref={props.refs && props.refs['repetitionRule.count']}
              id="repetitionRule.count"
              name="repetitionRule.count"
              type="number"
              required={props.required}
              min={1}
              value={props.getInputValue('repetitionRule.count')}
              onChange={this.handleInputChange('repetitionRule.count')}
              error={this.getFieldError('repetitionRule.count')}
            />
          </Column>
        )}
        {method === 'FromDueDate' && period === 'months' && (
          <Column>
            <Input
              label={t(
                `chronoframe.dueDates.formField.repetitionRule.dayOfMonth`
              )}
              ref={this.dayOfMonthRef}
              id="repetitionRule.dayOfMonth"
              name="repetitionRule.dayOfMonth"
              type="number"
              required={props.required}
              min={1}
              max={31}
              value={
                props.getInputValue('repetitionRule.dayOfMonth')
                  ? props.getInputValue('repetitionRule.dayOfMonth')
                  : props.getInputValue('dueAtDate')
                  ? props.getInputValue('dueAtDate').split('-')[2]
                  : props.getInputValue('repetitionRule.dayOfMonth')
              }
              onChange={this.handleInputChange('repetitionRule.dayOfMonth')}
              error={this.getFieldError('repetitionRule.dayOfMonth')}
            />
          </Column>
        )}
      </Row>
    )
  }

  private handleInputChange(slug: string, template?: boolean) {
    return (value: any) => {
      if (slug === 'repetitionRule.period' && value === 'days') {
        this.props.onInputChange('repetitionRule.dayOfMonth', null)
        return this.props.onInputChange(slug, value, template)
      } else if (slug === 'repetitionRule.dayOfMonth') {
        return value > 31
          ? this.props.onInputChange(slug, 31, template)
          : this.props.onInputChange(slug, value, template)
      } else {
        return this.props.onInputChange(slug, value, template)
      }
    }
  }

  private getFieldError(slug: string) {
    return (
      (this.props.errors &&
        this.props.errors[slug] &&
        this.props.errors[slug][0]) ||
      undefined
    )
  }
}

export default AutomaticRenewalField
