import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { COLORS } from '../../../../../constants'
import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries, Loader } from '../../../../../functions/shared'
import BottomLegend from '../../BottomLegend/BottomLegend/BottomLegendView'
import BunkerBarStack3 from '../../BunkerBar/BunkerBarStack3View'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'

interface OwnState {
  components: NodeComponent[]
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  measures: string[]
  component: any
  data: Array<{ [key: string]: number }>
  isFetching: boolean
  fetchErrors: boolean
  configuration: PlantAnalysisGeneralSettings
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class BunkerGraphRowView extends React.Component<Props, OwnState> {
  public render() {
    const { model, days } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isFetching
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const colors = {
      bar1_1: COLORS.nopienoPieno[0],
      bar1_2: COLORS.nopienoPieno[1],
      bar2_1: COLORS.invdir[0],
      bar2_2: COLORS.invdir[1],
      bar3_1: COLORS.manAutoLocSec[0],
      bar3_2: COLORS.manAutoLocSec[1],
      bar3_3: COLORS.manAutoLocSec[2],
      bar3_4: COLORS.manAutoLocSec[3], // @todo verificare corrispondenza codici/valori
    }
    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        ['PLANT_DATA_BNK', 'PLANT_DATA_BNK_INVERTER'].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code)
    )

    /*  DEMO  @todo misure vere */
    const unloaded = [
      {
        workshift: 1,
        qta: 2,
      },
      {
        workshift: 2,
        qta: 32,
      },
      {
        workshift: 3,
        qta: 22,
      },
    ]
    /* / DEMO */

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto alert-local">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.props.isFetching && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto alert-local">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.props.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 mb-3 page-break-inside-avoid">
            <div className="row row-legends pb-1">
              <div className="col-md-1" />
              <div className="col-md-3">
                <BottomLegend row={true} labels={['nopienoPieno']} hideTitle={true} />
              </div>
              <div className="col-md-3">
                <BottomLegend row={true} labels={['invdir']} hideTitle={true} />
              </div>
              <div className="col-md-5">
                <BottomLegend row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </div>
            </div>
          </div>

          {componentsToRender.map(
            (c: ModelNodeData) =>
              (this.props.configuration.nodes === {} ||
                (this.props.configuration.nodes[c.code] && this.props.configuration.nodes[c.code] === true)) && (
                <BunkerBarStack3
                  data={getInfluxMultiSeries(this.props.data, c.code)}
                  days={days || 1}
                  name={c.label}
                  id={c.code}
                  unloaded={unloaded}
                  colors={colors}
                  key={c.code}
                  hideInverterDiretta={c.nodeType === 'PLANT_DATA_BNK'}
                />
              )
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BunkerGraphRowView)))
