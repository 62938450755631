import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import * as qs from 'qs'
import { DispatchFetchListAction, DispatchSubmitDataAction } from '../../types/store'
import {
  PlantAsset,
  PlantHalt,
  PlantHaltCause,
  PlantHaltFilters,
  PlantHalts,
  PlantHaltsProps,
} from '../../types/plantHalts'
import { API } from './index'
import moment from 'moment'

export const isFetching = ( params?: PlantHaltFilters ): AnyAction => ({
  type: 'PLANT_HALT_LIST_IS_FETCHING',
  params,
})

export const fetchSuccess = ( data: PlantHalts ): AnyAction => ({
  type: 'PLANT_HALT_LIST_FETCH_SUCCESS',
  data,
})

export const fetchError = ( error: Error ): AnyAction => ({
  type: 'PLANT_HALT_LIST_FETCH_ERROR',
  error,
})

export const fetchSuccessCounterUnjustified = ( data: number ): AnyAction => ({
  type: 'PLANT_HALT_FETCH_UNJUSTIFIED_COUNTER',
  data,
})

export const fetchDataMulti = (
  isMultiplant: boolean,
  plant: any | null,
  confirmed: boolean,
  abortController: AbortController,
  params?: PlantHaltFilters,
) => async ( dispatch: Dispatch ) => {
  dispatch(isFetching(params))

  try {
    let filters = params
    if (!confirmed && params){
      filters = JSON.parse(JSON.stringify(params))
      delete filters!.from
      delete filters!.to
    }
    const result: PlantHaltsProps = await API().request(
      `/halts?${qs.stringify(filters)}`,
      {
        signal: abortController.signal,
        ...(isMultiplant && plant && plant.plant ? {
          headers: {
            'X-Inplant-PlantCode': plant.plant,
          }
        } : {})
      }
    )
    const halts: PlantHalt[] = result.halts.map(raw => new PlantHalt(raw))
    const data: PlantHalts = Object.assign(result, { halts })
    dispatch(fetchSuccess(data))
    return data
  } catch (error:any) {
    if (!(error.message.indexOf('abort') > -1)){
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      } else {
        dispatch(fetchError(error))
      }
    }
    throw error
  }
}

export const fetchData: DispatchFetchListAction<PlantHalts,
  PlantHaltFilters> = ( params?: PlantHaltFilters ) => async dispatch => {
  dispatch(isFetching(params))

  try {
    const result: PlantHaltsProps = await API().request(
      `/halts?${qs.stringify(params)}`,
    )
    const halts: PlantHalt[] = result.halts.map(raw => new PlantHalt(raw))
    const data: PlantHalts = Object.assign(result, { halts })
    dispatch(fetchSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(fetchError(error))
    }
    throw error
  }
}

export const fetchCounterUnjustified = (
  isMultiplant: boolean,
  plant: any | null,
) => async ( dispatch: Dispatch ) => {
  try {
    const result = await API().request(
      '/halts/unjustifiedCount',
      isMultiplant && plant && plant.plant
        ? {
          headers: {
            'X-Inplant-PlantCode': plant.plant,
          },
        }
        : undefined,
    )
    dispatch(fetchSuccessCounterUnjustified(result.unjustifiedCount as number))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(fetchError(error))
    }
    throw error
  }
}

export const applyParams = ( params: PlantHaltFilters ): AnyAction => ({
  type: 'PLANT_HALT_LIST_APPLY_PARAMS',
  params,
})

export const isFetchingCauses = (): AnyAction => ({
  type: 'PLANT_HALT_CAUSES_IS_FETCHING',
})

export const fetchCausesSuccess = ( causes: PlantHaltCause[] ): AnyAction => ({
  type: 'PLANT_HALT_CAUSES_FETCH_SUCCESS',
  causes,
})

export const fetchCausesError = ( error: Error ): AnyAction => ({
  type: 'PLANT_HALT_CAUSES_FETCH_ERROR',
  error,
})

export const fetchCauses: DispatchFetchListAction<PlantHaltCause[]> = () => async dispatch => {
  dispatch(isFetchingCauses())

  try {
    const causes = await API().request('/halts/causes')
    dispatch(fetchCausesSuccess(causes))
    return causes
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(fetchCausesError(error))
    }
    throw error
  }
}

/*
UNUSED

interface ModelSubcomponent {
  enabled: "Enabled";
  level: string;
  subcomponents: ModelSubcomponent[];
  data: {
    documents: any[];
    id: string;
    label: string;
  };
}

interface ModelResponse {
  content: ModelSubcomponent;
  version: string;
} */

export const isFetchingAssets = (): AnyAction => ({
  type: 'PLANT_ASSETS_IS_FETCHING',
})

export const fetchAssetsSuccess = ( assets: PlantAsset[] ): AnyAction => ({
  type: 'PLANT_ASSETS_FETCH_SUCCESS',
  assets,
})

export const fetchAssetsError = ( error: Error ): AnyAction => ({
  type: 'PLANT_ASSETS_FETCH_ERROR',
  error,
})

export const fetchAssets = ( isMultiplant: boolean, plant: any | null ) => async (
  dispatch: Dispatch,
): Promise<PlantAsset[]> => {
  dispatch(isFetchingAssets())

  try {
    if (
      isMultiplant &&
      (!plant ||
        plant === '' ||
        !plant.plant ||
        (plant.plant && plant.plant === ''))
    ) {
      const err = new Error('Wrong plant name')
      dispatch(fetchError(err))
      return []
    } else {
      const assets: PlantAsset[] = await API().request(
        '/model/assets' +
        (isMultiplant ? '?plantName=' + plant.plant + '&' : '?') +
        'module=inplant_halt',
      )
      dispatch(fetchAssetsSuccess(assets))
      return assets
    }
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(fetchAssetsError(error))
    }
    throw error
  }
}

export const isSubmitting = (): AnyAction => ({
  type: 'PLANT_HALT_IS_SUBMITTING',
})

export const submitSuccess = ( data: PlantHalt ): AnyAction => ({
  type: 'PLANT_HALT_SUBMIT_SUCCESS',
  data,
})

export const submitError = ( error: Error ): AnyAction => {
  return {
    type: 'PLANT_HALT_SUBMIT_ERROR',
    error,
  }
}
/*
export const submitData: DispatchSubmitDataAction<PlantHalt> = (
  data: PlantHalt,
  version: number
) => async dispatch => {
  dispatch(isSubmitting());

  try {
    await API.request(`/halts/${data.id}/confirm`, {
      body: JSON.stringify({
        asset: data.asset || null,
        notes: data.notes || null,
        HaltType: data.causeKey && String(data.causeKey.split('/')[0]) || null,
        HaltDetails: data.causeKey && String(data.causeKey.split('/')[1]) || null
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    dispatch(submitSuccess(data));
    return data;
  } catch (error) {
    dispatch(submitError(error));
    throw error;
  }
};*/

export const updateData: DispatchSubmitDataAction<PlantHalt> = (
  data: PlantHalt,
) => async dispatch => {
  dispatch(isSubmitting())

  try {
    const dataToSend: any = {
      asset: data.asset || null,
      notes: data.notes || null,
      type: data.causeKey && parseInt(data.causeKey.split('/')[0], 10),
      detail: data.causeKey && parseInt(data.causeKey.split('/')[1], 10),
    }
    /*const error = new Error
        dispatch(submitError(error));
        throw error;*/

    await API().request(`/halts/${data.id}/update`, {
      body: JSON.stringify(dataToSend),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    dispatch(submitSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(submitError(error))
    }
    throw error
  }
}

export const exportData = (
  filters: PlantHaltFilters,
  isMultiplant: boolean,
  plant: any | null,
) => async ( dispatch: Dispatch ) => {
  /*
  UNUSED
  const from = filters.from
    ? moment().format("yyyy-MM-dd")
    : moment().format("yyyy-MM-dd");
  const to = filters.to ? moment().format("yyyy-MM-dd") : from;
  */
  try {
    const result: string = await API().request(
      `/halts/export?${qs.stringify(filters)}`,
      {
        method: 'GET',
        ...(isMultiplant && plant && plant.plant
          ? {
            headers: {
              'X-Inplant-PlantCode': plant.plant,
            },
          }
          : {}),
      },
    )
    return download(result, 'export_' + moment().format('YYYYMMDD') + '.csv')
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(fetchError(error))
    }
    throw error
  }
}


const download = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string,
) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    (window.navigator as any).msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
