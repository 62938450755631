import { PlantSelectorState, initialPlantSelectorState } from '../state'
import { AnyAction } from 'redux'

export function plantSelector(state: PlantSelectorState = initialPlantSelectorState, action: AnyAction) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'PLANTSELECTOR_FETCH_HAS_ERRORED': {
      newState.hasErrored = action.hasErrored
      return newState
    }

    case 'PLANTSELECTOR_FETCH_IS_LOADING': {
      newState.isLoading = action.isLoading
      return newState
    }

    case 'PLANTSELECTOR_FETCH_SUCCESS': {
      newState.items = action.items
      return newState
    }

    case 'PLANTSELECTOR_SET_PLANT': {
      newState.plant = action.plant
      return newState
    }

    default:
      return state
  }
}
