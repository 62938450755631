import { RouteProps } from '../../inplant-core-fe/types'
import userRoutes from '../../inplant-user-fe/ui/routes'
import { generateRoutes } from '../../inplant-chronoframe-fe'
// import { filterRoutes } from '../../inplant-core-fe/ui/routes'
import { routes as titechRoutes } from '../../inplant-titech-fe-iblu'
import { routes as plantStatusRoutes } from '../../inplant-plantstatus-fe'
import PlantHaltList from '../../inplant-fermiimpianto-fe-iblu/ui/components/views/PlantHaltList/PlantHaltListPageView'
import { routes as assetManagerRoutes } from '../../inplant-asset-manager-fe'
import { mapRoutePropsLegacyToRoutePropsCoreWithLegacy } from '../../inplant-coreadapter-fe/functions/routeAdapter'
import attachInplantWrapperTo from '../../inplant-coreadapter-fe/ui/components/widgets/InplantWrapper/InplantWrapperHOC'
import { RoutePropsCoreWithLegacy, RoutePropsLegacy } from '../../inplant-coreadapter-fe/types/routes'
import { routeOnlyGeneral } from '../../inplant-plantanalysis-fe-iblu/ui/routes'

const userCan = (roles: string[] | boolean, userRoles: string[]) => {
  if (typeof roles === 'boolean') {
    return roles
  }
  return userRoles.filter(value => -1 !== roles.indexOf(value)).length > 0
}

const composeRoutes = (moduleRoutes: RoutePropsLegacy[], userRoles: string[]) => {
  return moduleRoutes.map(routeGroup => {
    const aclRoute = routeGroup
    switch (routeGroup.path) {
      case '/checklist/models-assets':
        aclRoute.visible = userCan(true, userRoles)
        break
      case '/mcs/today':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAG'],
          userRoles
        )
        break
      case '/maintenance/calendar':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
          userRoles
        )
        break
      case '/scadenziario/manager':
        aclRoute.visible = userCan(['DID', 'ASPP', 'REA', 'RA'], userRoles)
        break
      case 'http://sg.inplant.hubme.in/manutenzioni-beni/da-fare/':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN'],
          userRoles
        )
        break
    }

    // root's childs visibility, should include all child acls
    if (routeGroup.children) {
      aclRoute.children = routeGroup.children.map((childRoute: RoutePropsLegacy) => {
        switch (childRoute.path) {
          case '/checklist/models-assets':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/models':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS',
              ],
              userRoles
            )
            break

          case '/checklist/personal-archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS',
              ],
              userRoles
            )
            break

          case '/checklist/validations':
            childRoute.visible = userCan(['RCP', 'RPROD', 'MAG', 'RMAG', 'ASPP', 'ASQ'], userRoles)
            break

          case '/checklist/reset-asset-hours':
            childRoute.visible = userCan(['RSACP', 'SACP', 'RCP', 'UTM'], userRoles)
            break

          case '/checklist/gia':
            childRoute.visible = userCan(['RMAG', 'RPROD', 'VRPROD', 'SACP', 'RSACP', 'DID', 'REA', 'MAG'], userRoles)
            break

          case '/mcs/today':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAG'],
              userRoles
            )
            break

          case '/maintenance/calendar':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles
            )
            break

          case '/maintenance/calendarize':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles
            )
            break

          case '/maintenance/overdue-anomalies':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
              userRoles
            )
            break

          case '/maintenance/anomalies':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
              userRoles
            )
            break

          case '/maintenance/archive':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles
            )
            break
          case '/scadenziario/manager':
            childRoute.visible = userCan(['RQ', 'RA', 'ASPP', 'RSCOMM', 'SCOMM', 'SVA', 'UTM'], userRoles)
            break

          // Hide titech setup route in Visor
          case '/titech/setup':
            childRoute.visible = false
          case '/titech':
            childRoute.visible = false
        }
        return childRoute
      })
    }
    return aclRoute
  })
}

// maintenanceRoutes.i18nkey = 'maintenance.navigation.title'
/*
if (config.maintenance.pages) {
  maintenanceRoutes.path = `/maintenance/${config.maintenance.pages[0].pagePath}`

  if (maintenanceRoutes.children) {
    maintenanceRoutes.children = []

    maintenanceRoutes.children.push({
      path: '/maintenance/scheduled-job/:scheduledJobId',
      component: ScheduledJobDetailComponent,
      visible: false,
    })

    maintenanceRoutes.children.push({
      path: '/maintenance/target/*',
      component: TargetComponent,
      visible: false,
    })

    config.maintenance.pages.map(page => {
      maintenanceRoutes.children!.push({
        path: `/maintenance/${page.pagePath}`,
        i18nkey: page.i18nkey,
        visible: true,
        exact: true,
        component: page.component,
      })
    })
  }
}
*/
/*
const emergencyRoute: RouteProps = {
  path: 'http://sg.inplant.hubme.in/squadra-emergenza/index?v2=true',
  icon: faCaretRight,
  i18nkey: 'additionalRoutes.emergency',
  visible: true,
  external: true,
}

const maintenanceRoute: RouteProps = {
  path: 'http://sg.inplant.hubme.in/manutenzioni-beni/da-fare/',
  icon: faCaretRight,
  i18nkey: 'additionalRoutes.maintenance',
  visible: true,
  external: true,
}
*/

const plantHaltsRoutes: RouteProps = {
  path: '/plant-halts',
  i18nkey: 'plantHalts.navigation.plantHalts',
  icon: 'stop-circle',
  visible: true,
  component: PlantHaltList,
  /* children: [
    {
      path: '/plant-halts',
      i18nkey: 'plantHalts.navigation.plantHaltsList',
      exact: true,
      component: PlantHaltList,
      visible: true,
    },
  ], */
}


export const aclRoutes = (sroutes: RoutePropsCoreWithLegacy[], forbiddenActions: string[], loginSuccessPath: string) =>
  sroutes.filter(r => r)

export const aclRedirect = (loginSuccessPath: string) => loginSuccessPath

const routes: (userRoles: string[]) => RoutePropsCoreWithLegacy[] = userRoles => {
  const r = [
    ...composeRoutes(
      [
        attachInplantWrapperTo(routeOnlyGeneral),
        attachInplantWrapperTo(titechRoutes),
        attachInplantWrapperTo(plantStatusRoutes),
        attachInplantWrapperTo(
          generateRoutes('scadenziario', [
            'ongoing',
            'overdue',
            'user-tasks',
            'to-validate',
            'archive',
            'rejected',
            'manager',
          ])
        ),
        attachInplantWrapperTo(plantHaltsRoutes),
        attachInplantWrapperTo(assetManagerRoutes),
        attachInplantWrapperTo(userRoutes),
        // checklistRoutes,
        // mcsRoutes,
        // maintenanceRoutes,
        // emergencyRoute,
        // maintenanceRoute,
      ],
      userRoles
    ).map(mapRoutePropsLegacyToRoutePropsCoreWithLegacy),
  ]
  return r
}

export default routes
