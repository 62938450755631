import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { withTranslation, WithTranslation } from 'react-i18next'
import TableRowWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

interface OwnProps {
  active: string[]
  component: any
  toggleCollapse: (id: string) => void
  subtitle?: string | null
  isMulti?: boolean
  manuallyOpened?: boolean
  zeroPadding?: boolean
}

// UNUSED export const ComponentTypes = {}

type Props = OwnProps & WithTranslation

class GraphWrapperPrint extends React.Component<Props> {
  public render() {
    const { /*isMulti, zeroPadding*/ } = this.props
    const isActive =
      this.props.manuallyOpened ||
      (this.props.active && this.props.active.length > 0 && this.props.active.indexOf(this.props.component.id) > -1)

    return (
      <>
        <TableRowWrapper className={'section-title-divider'} key={this.props.component.id}>
          <TableColumnWrapper className={'pt-4 pb-3'} col={'full'}>
            <div id={this.props.component.id} className={'graph-header ' + (isActive ? 'is-collapsed' : '')}>
              <h3 onClick={() => this.props.toggleCollapse(this.props.component.id)} className={'w-100'}>
                {this.props.active !== this.props.component.id ? (
                  <FontAwesomeIcon icon={faCaretUp} />
                ) : (
                  <FontAwesomeIcon icon={faCaretDown} />
                )}{' '}
                {this.props.component.label}
                {this.props.component.nodeType &&
                  <small className={'float-right'}>{this.props.component.nodeType}</small>}
              </h3>
            </div>
          </TableColumnWrapper>
          {/*<TableColumnWrapper
            col={'full'}
            className={
              'graph-container ' +
              this.props.component.id + '__container' +
              (isActive ? ' show' : '') +
              (isMulti ? ' graph-wrapper-multi' : '') +
              (zeroPadding ? ' pt-4 pb-2' : '')
            }
          >
            {this.props.subtitle && (
              <h4 className='col-md-12 mb-4'>{this.props.t('plantAnalysis.' + this.props.subtitle)}</h4>
            )}
          </TableColumnWrapper>
        */}
        </TableRowWrapper>
        {isActive && this.props.children}
      </>
    )
  }
}

export default withTranslation()(GraphWrapperPrint)
