import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Steps from '@mv-submodules/inplant-components-fe/ui/components/Steps/Steps'
import { Button, PageHeader } from '@mv-submodules/inplant-components-fe'
import CopyTasksAssetsList from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/CopyTasksAssetsList'
import {
  AssetDetail, AssetsBatchTask,
  AssetsListElement,
  AssetTaskForCopy, CopyTasksBody,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { copyTasksToAssets, fetchDetailAsset } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import CopyTasksAssetTasks from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/CopyTasksAssetTasks'
import CopyTasksSummary from '@mv-submodules/inplant-asset-manager-fe/ui/components/views/CopyTasks/CopyTasksSummary'
import moment from 'moment'

export interface AssetsTasksCopyForm {
  [k: string]: {
    form: AssetsBatchTask,
    taskData: AssetTaskForCopy
  }
}

interface OwnState {
  assetListElement?: AssetsListElement
  assetDetail?: AssetDetail
  isFetchingAssetDetail: boolean
  fetchErrorsAssetDetail: boolean
  activeStep: number
  selectedTasks: AssetTaskForCopy[]
  submitting: boolean,
  assetsTask?: {
    [k: string]: {
      form: AssetsBatchTask,
      taskData: AssetTaskForCopy
    },
  }
}

interface StateProps {
  forbiddenActions: string[]
}

const mapStateToProps = (store: any): StateProps => {
  return {
    forbiddenActions: store.auth.user.forbiddenActions,
  }
}

type Props = RouteComponentProps & WithTranslation & StateProps

class CopyTask extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      fetchErrorsAssetDetail: false,
      isFetchingAssetDetail: false,
      activeStep: 1,
      selectedTasks: [],
      submitting: false
    }
    this.handleStepClick = this.handleStepClick.bind(this)
    this.handleRowClickAsset = this.handleRowClickAsset.bind(this)
    this.fetchDetailAsset = this.fetchDetailAsset.bind(this)
    this.handleSelectTasks = this.handleSelectTasks.bind(this)
    this.handleSelectAllTasks = this.handleSelectAllTasks.bind(this)
    this.updateAssetsTasks = this.updateAssetsTasks.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] //tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list-active')
    }
    this.fetchDetailAsset(assetId)
  }

  public render() {
    const { t }= this.props
    const { assetListElement, activeStep, selectedTasks, submitting } = this.state
    return (
      <div className="inplant-asset-manager-fe copy-tasks">
        <Steps
          steps={[
            {
              cliccable: !submitting,
              label: t('assetManager.actions.selectAssetsToCopyTasks'),
            },
            {
              cliccable: activeStep > 2 && !submitting,
              label: t('assetManager.actions.copyTasks'),
            },
            {
              cliccable: false,
              label: t('assetManager.actions.summary'),
            },
          ]}
          onclick={this.handleStepClick}
          curranteStep={activeStep}
        />
        <PageHeader title={activeStep === 1 ? t('assetManager.labels.selectAssetsToCopyTasks')
          : activeStep === 2 ? t('assetManager.labels.selectAssetsToCopyTasks') : t('assetManager.labels.destinationAsset')}/>
        <div className="content">
          {activeStep === 1 && (
            <CopyTasksAssetsList
              filters={{
                status: null,
                costCenter: null,
                plant: null,
                searchString: null,
              }}
              pagination={{
                pageSize: 10,
                pageNumber: 0,
                sortDirection: 'position',
                sortField: 'asc',
              }}
              handleRowAssetClick={this.handleRowClickAsset}
            />
          )}
          {activeStep === 2 && assetListElement && (
            <>
              <CopyTasksAssetTasks
                assetId={assetListElement.id}
                selectedTasks={selectedTasks}
                handleSelectTasks={this.handleSelectTasks}
                handleSelectAllTasks={this.handleSelectAllTasks}
              />
              <div className="bottom-line-table">
                <p className="font-weight-bold pt-0 pb-0 mt-0 mb-0 mr-4">
                  {selectedTasks.length} {t('assetManager.labels.selectedTasks')}
                </p>
                <Button
                  variant="primary"
                  onClick={() => this.handleStepClick(3)}
                  label={t('assetManager.actions.forward')}
                  disabled={selectedTasks.length === 0} />
              </div>
            </>
          )}
          {activeStep === 3 && this.state.assetsTask && (
            <form onSubmit={this.handleSubmit}>
              <CopyTasksSummary
                isFetching={this.state.isFetchingAssetDetail}
                selectedTasks={selectedTasks}
                assetDetail={this.state.assetDetail}
                fetchErrors={this.state.fetchErrorsAssetDetail}
                assetsTask={this.state.assetsTask}
                updateAssetsTasks={this.updateAssetsTasks}
              />
              <div className="bottom-line-table">
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={submitting}
                  label={t('assetManager.actions.confirm')}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }

  private fetchDetailAsset(id: string) {
    if (!this.state.isFetchingAssetDetail) {
      this.setState({
        isFetchingAssetDetail: true,
        fetchErrorsAssetDetail: false,
      })
      fetchDetailAsset(id)
        .then((assetDetail) => {
          this.setState({ assetDetail })
        })
        .catch((error: FetchError) => {
          this.setState({
            fetchErrorsAssetDetail: true,
          })
        })
        .finally(() => {
          this.setState({ isFetchingAssetDetail: false })
        })
    }
  }

  private handleRowClickAsset(assetListElement: AssetsListElement) {
    this.setState({ assetListElement, activeStep: 2, assetsTask: undefined})
  }

  private handleStepClick(index: number) {
    if (index === 1) {
      this.setState({
        assetListElement: undefined,
        activeStep: 1,
        assetsTask: undefined,
        selectedTasks: []
      })
    }
    if (index === 2){
      this.setState({
        activeStep: 2
      })
    }
    if (index === 3) {
      const assetsTask = this.state.selectedTasks.reduce((tasks: AssetsTasksCopyForm, task) => {
        tasks[task.id] = {
          form: {
            creationDate: moment().format('YYYY-MM-DD'),
            taskId: task.id,
            dueAtDate: !task.recurrent && moment().format('YYYY-MM-DD') || undefined
          },
          taskData: task,
        }
        return tasks
      }, {})
      this.setState({
        activeStep: 3,
        assetsTask,
      })
    }
  }

  private handleSelectTasks(task: AssetTaskForCopy) {
    let { selectedTasks } = this.state
    if (selectedTasks.find(t => t.id === task.id)) {
      selectedTasks = selectedTasks.filter(t => t.id !== task.id)
    } else {
      selectedTasks.push(task)
    }
    this.setState({ selectedTasks })
  }

  private handleSelectAllTasks(tasks: AssetTaskForCopy[]){
    let { selectedTasks } = this.state
    if (selectedTasks.length === tasks.length){
      selectedTasks = []
    }
    else {
      selectedTasks = tasks
    }
    this.setState({selectedTasks})
  }

  private updateAssetsTasks(assetsTask: AssetsTasksCopyForm){
    this.setState({assetsTask})
  }

  private handleSubmit(event: React.FormEvent){
    event.preventDefault()
    if (this.state.assetsTask && this.state.assetDetail && this.state.assetListElement){
     this.setState({submitting: true})
      const data: CopyTasksBody = {
        sourceAssetId: this.state.assetListElement.id,
        tasks: Object.keys(this.state.assetsTask).reduce((acc: AssetsBatchTask[], key) => {
          acc.push({
            ...this.state.assetsTask![key].form
          })
          return acc
        }, [])
      }
      copyTasksToAssets(this.state.assetDetail.id, data)
        .then(() => {
          this.props.history.push(`/asset-manager/detail-asset/${this.state.assetDetail!.id}`)
        })
        .catch((e: FetchError) => {
          console.log(e) //tslint:disable-line
        })
    }
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    {},
  )
  (withTranslation()(CopyTask)),
)