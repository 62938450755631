import { ListParams } from '../../types/store'

const initialState: ListParams = {
  pageSize: 10,
  page: 1,
}

export const titechSetup = (
  state = initialState,
  action: {type: string, [k: string]: any}
) => {
  switch (action.type) {
    case 'TITEC_SETUP_APPLY_PARAMS':
      return {
        ...state,
        ...action.params,
      }
    default:
      return state
  }
}
