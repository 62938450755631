import { AnyAction } from 'redux'
// import * as moment from 'moment'

const nastroInitialState: any = {
  assorbimento: {
    data: null,
    fetching: false,
    error: undefined,
  },
  assorbimentoConsigliato: {
    data: null,
    fetching: false,
    error: undefined,
  },
  avantiIndietro: {
    data: null,
    fetching: false,
    error: undefined,
  },
  invertedDiretta: {
    data: null,
    fetching: false,
    error: undefined,
  },
  manualeAutomatico: {
    data: null,
    fetching: false,
    error: undefined,
  },
  setVelocita: {
    data: null,
    fetching: false,
    error: undefined,
  },
}

export const nastro = (state = nastroInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_NASTRO_STATUS_IS_FETCHING':
      return {
        ...state,
        assorbimento: {
          fetching: true,
          error: undefined,
        },
        assorbimentoConsigliato: {
          fetching: true,
          error: undefined,
        },
        avantiIndietro: {
          fetching: true,
          error: undefined,
        },
        invertedDiretta: {
          fetching: true,
          error: undefined,
        },
        manualeAutomatico: {
          fetching: true,
          error: undefined,
        },
        setVelocita: {
          fetching: true,
          error: undefined,
        },
      }
    case 'PLANTANALYSIS_NASTRO_STATUS_FETCH_SUCCESS':
      return {
        ...state,
        assorbimento: {
          data: action.payload.assorbimento,
          fetching: false,
          error: undefined,
        },
        assorbimentoConsigliato: {
          data: action.payload.assorbimentoConsigliato,
          fetching: false,
          error: undefined,
        },
        avantiIndietro: {
          data: action.payload.avantiIndietro,
          fetching: false,
          error: undefined,
        },
        invertedDiretta: {
          data: action.payload.invertedDiretta,
          fetching: false,
          error: undefined,
        },
        manualeAutomatico: {
          data: action.payload.manualeAutomatico,
          fetching: false,
          error: undefined,
        },
        setVelocita: {
          data: action.payload.setVelocita,
          fetching: false,
          error: undefined,
        },
      }
    case 'PLANTANALYSIS_NASTRO_STATUS_FETCH_ERROR':
      return {
        ...state,
        assorbimento: {
          fetching: false,
          error: action.payload,
        },
        assorbimentoConsigliato: {
          fetching: false,
          error: action.payload,
        },
        avantiIndietro: {
          fetching: false,
          error: action.payload,
        },
        invertedDiretta: {
          fetching: false,
          error: action.payload,
        },
        manualeAutomatico: {
          fetching: false,
          error: action.payload,
        },
        setVelocita: {
          fetching: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default nastro
