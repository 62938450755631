import { logger } from './logs'

export const download = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string
) => {

  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    (window.navigator as any).msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    downloadDataURL(blobURL, filename)
  }
}

export const downloadDataURL = async (dataURL: string | Blob | MediaSource, filename: string): Promise<boolean> => {
  try {
    const url = typeof dataURL === 'string' ? dataURL : window.URL.createObjectURL(dataURL)

    return new Promise((resolve, reject) => {
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = url
      tempLink.download = filename
      if (typeof tempLink.download === 'undefined') {
        tempLink.target = '_blank'
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(url)
      resolve(true)
    })
  } catch (e) {
    logger(`download.ts`, `downloadDataURL`, JSON.stringify(e))
    return false
  }
}

export const handleDownloadImages = (images: Array<{ imageURL: string; fileName: string }>) => {
  images.forEach(img => downloadDataURL(img.imageURL, img.fileName))
}

export const delay = (msDelay: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, msDelay)
  })
}
