import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../../constants'
import { Loader } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import { parseData } from '../../../../../functions/series'
import moment from 'moment'
import GraphLineBarWithTimeline from '../../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelineView'
import {
  composeData,
  fillTime,
  populateSingleDataFrom,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'

export type BufferLineType = 'bufferLine'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: BufferLineType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  bufferState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataBufferState: PieData | null
  fetching: boolean
  error: boolean
  tooltipData: any
  showTooltip: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
})

class LineBufferView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      bufferState: {},
      fetching: false,
      error: false,
      dataBufferState: null,
      showTooltip: true,
      tooltipData: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: [],
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData(this.props.processing)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curThrustPresser = curData.thrustPresser !== undefined ? curData.thrustPresser : '--'
        const curAbsorption = curData.absorption !== undefined ? curData.absorption : '--'
        const curSpeedPercent = curData.speedPercent !== undefined ? curData.speedPercent : '--'
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            thrustPresser: curThrustPresser,
            absorption: curAbsorption,
            speedPercent: curSpeedPercent,
            thrustPresserColor: COLORS.vecoplant.generalGraph.thrustPresser,
            absorptionColor: COLORS.vecoplant.generalGraph.absorption,
            speedPercentColor: COLORS.vecoplant.generalGraph.speedPercent,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    return (
      <div className='halts-graphs'>
        {!fetching && error && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
            <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}
        {!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data) && (
          <Row>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
          </Row>
        )}
        {!error && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('bufferLine')) &&
              (this.state.fetching ? (
                <Row>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
                  <Loader />
                </Row>
              ) : (
                this.state.data && (
                  <div
                    className={`col-md-12 col-sm-12 col-lg-12 halts-graphs halts-graphs__vertical`}
                  >
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
                    <div className={'graph-spacing column-break-before'}>
                      <Row>
                        <img src={'https://inplant-public.s3.eu-west-1.amazonaws.com/dec-bluair.png'} height={250} />
                        <Column md={10} lg={10} sm={10}>
                          <h5 className={'w-100 mt-3'}>
                            {this.props.t('plantAnalysis.lines.linesStatus.buffers.buffer1')}
                          </h5>
                          {this.state.data.buffer1StateNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer1State'}
                              colorsId={'activeStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer1'}
                              lineHeight={60}
                              hideTitle={true}
                              // hideTimeline={true}
                            />
                          )}
                          {this.state.data.buffer1FillNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer1Fill'}
                              colorsId={'fillStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer1'}
                              lineHeight={60}
                              hideTitle={true}
                              // hideTimeline={true}
                            />
                          )}
                          <h5 className={'w-100 mt-3'}>
                            {this.props.t('plantAnalysis.lines.linesStatus.buffers.buffer2')}
                          </h5>
                          {this.state.data.buffer2StateNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer2State'}
                              colorsId={'activeStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer2'}
                              lineHeight={60}
                              hideTitle={true}
                            />
                          )}
                          {this.state.data.buffer2FillNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer2Fill'}
                              colorsId={'fillStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer2'}
                              lineHeight={60}
                              hideTitle={true}
                            />
                          )}
                          <h5 className={'w-100 mt-3'}>
                            {this.props.t('plantAnalysis.lines.linesStatus.buffers.buffer5')}
                          </h5>
                          {this.state.data.buffer5StateNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer5State'}
                              colorsId={'activeStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer5'}
                              lineHeight={60}
                              hideTitle={true}
                            />
                          )}
                          {this.state.data.buffer5FillNoData ? (
                            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                              {t('plantAnalysis.noDataAvailable')}
                            </div>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer5Fill'}
                              colorsId={'fillStatus'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.lines.linesStatus.buffers.buffer5'}
                              lineHeight={60}
                              hideTitle={true}
                            />
                          )}
                          {this.state.data && (
                            <Column>
                              <Row>
                                <div className='d-flex d-print-inline-flex'>
                                  <Row verticalAlignment='center'>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                      <span
                                        className='label-color-square'
                                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.active }}
                                      />
                                      <span>{t('plantAnalysis.lines.linesStatus.active')}</span>
                                    </span>
                                  </Row>
                                  <Row verticalAlignment='center'>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                      <span
                                        className='label-color-square'
                                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.inactive }}
                                      />
                                      <span>{t('plantAnalysis.lines.linesStatus.inactive')}</span>
                                    </span>
                                  </Row>
                                  <Row verticalAlignment='center'>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                      <span
                                        className='label-color-square'
                                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.empty }}
                                      />
                                      <span>{t('plantAnalysis.lines.linesStatus.empty')}</span>
                                    </span>
                                  </Row>
                                  <Row verticalAlignment='center'>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                      <span
                                        className='label-color-square'
                                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.full }}
                                      />
                                      <span>{t('plantAnalysis.lines.linesStatus.full')}</span>
                                    </span>
                                  </Row>
                                </div>
                              </Row>
                            </Column>
                          )}
                        </Column>
                      </Row>
                    </div>
                  </div>
                )
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  private constructData() {
    try {
      const buffer1State = populateSingleDataFrom(this.state.data?.buffer1State?.data, true)
      const buffer1Fill = populateSingleDataFrom(this.state.data?.buffer1Fill?.data, true)
      const buffer2State = populateSingleDataFrom(this.state.data?.buffer2State?.data, true)
      const buffer2Fill = populateSingleDataFrom(this.state.data?.buffer2Fill?.data, true)
      const buffer5State = populateSingleDataFrom(this.state.data?.buffer5State?.data, true)
      const buffer5Fill = populateSingleDataFrom(this.state.data?.buffer5Fill?.data, true)

      let mergedData = composeData(
        {
          buffer1State,
          buffer1Fill,
          buffer2State,
          buffer2Fill,
          buffer5State,
          buffer5Fill,
        },
      )

      const start = moment(this.props.workShift.start.toString()).unix()
      const end = moment(this.props.workShift.end.toString()).unix()

      mergedData = fillTime(
        mergedData,
        start,
        end,
      )


      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workShift
    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const startOfShift = workshift.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const endOfShift = workshift.end
        ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
        : endOfDay

      const queryEndShift = ` WHERE ${
        workshift && workshift.value !== 99 ? `shift = ${workshift.value} AND ` : ''
      } time >= '${startOfShift}' AND time <= '${endOfShift}'`

      try {
        const queryStart = `SELECT * FROM `
        const buffer1StateLabel = `vBufferF019ActiveStateTimeLine`
        const buffer1FillLabel = `vBufferF019AFullStateTimeLine`
        const buffer2StateLabel = `vBufferF028ActiveStateTimeLine`
        const buffer2FillLabel = `vBufferF028AFullStateTimeLine`
        const buffer5StateLabel = `vBufferF057ActiveStateTimeLine`
        const buffer5FillLabel = `vBufferF057AFullStateTimeLine`

        const buffer1StateLabelQuery = queryStart + buffer1StateLabel + queryEndShift
        const buffer1FillLabelQuery = queryStart + buffer1FillLabel + queryEndShift
        const buffer2StateLabelQuery = queryStart + buffer2StateLabel + queryEndShift
        const buffer2FillLabelQuery = queryStart + buffer2FillLabel + queryEndShift
        const buffer5StateLabelQuery = queryStart + buffer5StateLabel + queryEndShift
        const buffer5FillLabelQuery = queryStart + buffer5FillLabel + queryEndShift

        const dataBuffer1StateLabel = API().request(`/query?${plantQueryString}q=` + buffer1StateLabelQuery, { signal: this.abortController.signal })
        const dataBuffer1FillLabel = API().request(`/query?${plantQueryString}q=` + buffer1FillLabelQuery, { signal: this.abortController.signal })
        const dataBuffer2StateLabel = API().request(`/query?${plantQueryString}q=` + buffer2StateLabelQuery, { signal: this.abortController.signal })
        const dataBuffer2FillLabel = API().request(`/query?${plantQueryString}q=` + buffer2FillLabelQuery, { signal: this.abortController.signal })
        const dataBuffer5StateLabel = API().request(`/query?${plantQueryString}q=` + buffer5StateLabelQuery, { signal: this.abortController.signal })
        const dataBuffer5FillLabel = API().request(`/query?${plantQueryString}q=` + buffer5FillLabelQuery, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataBuffer1StateLabel,
          dataBuffer1FillLabel,
          dataBuffer2StateLabel,
          dataBuffer2FillLabel,
          dataBuffer5StateLabel,
          dataBuffer5FillLabel,
        ])
          .then(
            ([
               dataBuffer1StateLabelResult,
               dataBuffer1FillLabelResult,
               dataBuffer2StateLabelResult,
               dataBuffer2FillLabelResult,
               dataBuffer5StateLabelResult,
               dataBuffer5FillLabelResult,
             ]) => {
              if (this.mounted) {
                const parsedDataBuffer1StateLabelResult =
                  typeof dataBuffer1StateLabelResult === 'string'
                    ? JSON.parse(dataBuffer1StateLabelResult)
                    : dataBuffer1StateLabelResult
                const parsedDataBuffer1FillLabelResult =
                  typeof dataBuffer1FillLabelResult === 'string'
                    ? JSON.parse(dataBuffer1FillLabelResult)
                    : dataBuffer1FillLabelResult
                const parsedDataBuffer2StateLabelResult =
                  typeof dataBuffer2StateLabelResult === 'string'
                    ? JSON.parse(dataBuffer2StateLabelResult)
                    : dataBuffer2StateLabelResult
                const parsedDataBuffer2FillLabelResult =
                  typeof dataBuffer2FillLabelResult === 'string'
                    ? JSON.parse(dataBuffer2FillLabelResult)
                    : dataBuffer2FillLabelResult
                const parsedDataBuffer5StateLabelResult =
                  typeof dataBuffer5StateLabelResult === 'string'
                    ? JSON.parse(dataBuffer5StateLabelResult)
                    : dataBuffer5StateLabelResult
                const parsedDataBuffer5FillLabelResult =
                  typeof dataBuffer5FillLabelResult === 'string'
                    ? JSON.parse(dataBuffer5FillLabelResult)
                    : dataBuffer5FillLabelResult
                try {
                  const parsedDataBuffer1State = parseData(parsedDataBuffer1StateLabelResult)
                  const parsedDataBuffer1Fill = parseData(parsedDataBuffer1FillLabelResult)
                  const parsedDataBuffer2State = parseData(parsedDataBuffer2StateLabelResult)
                  const parsedDataBuffer2Fill = parseData(parsedDataBuffer2FillLabelResult)
                  const parsedDataBuffer5State = parseData(parsedDataBuffer5StateLabelResult)
                  const parsedDataBuffer5Fill = parseData(parsedDataBuffer5FillLabelResult)
                  this.setState({
                    data: Object.assign({}, this.state.data, {
                      buffer1StateNoData: parsedDataBuffer1State.length === 0,
                      buffer1State: { data: parsedDataBuffer1State },
                      buffer1FillNoData: parsedDataBuffer1Fill.length === 0,
                      buffer1Fill: { data: parsedDataBuffer1Fill },
                      buffer2StateNoData: parsedDataBuffer2State.length === 0,
                      buffer2State: { data: parsedDataBuffer2State },
                      buffer2FillNoData: parsedDataBuffer2Fill.length === 0,
                      buffer2Fill: { data: parsedDataBuffer2Fill },
                      buffer5StateNoData: parsedDataBuffer5State.length === 0,
                      buffer5State: { data: parsedDataBuffer5State },
                      buffer5FillNoData: parsedDataBuffer5Fill.length === 0,
                      buffer5Fill: { data: parsedDataBuffer5Fill },
                    }),
                    fetching: false,
                    error: false,
                  }, () => {
                    this.constructData()
                  })
                } catch (error) {
                  this.setState({
                    data: null,
                    bufferState: {},
                    error: false,
                    fetching: false,
                  })
                }
              }
            },
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              bufferState: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          bufferState: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(LineBufferView))
