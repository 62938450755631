export class ThemeType {
  private name: string

  public static light = new ThemeType('light')
  public static dark = new ThemeType('dark')

  constructor(name: string) {
    this.name = name
  }

  public toString() {
    return this.name
  }
}

export interface Theme {
  themeFile: string
  themeName: string
  themeType: string
}

export const defaultTheme: Theme = {
  themeFile: 'index-default-light.css',
  themeName: 'default',
  themeType: 'light',
}

export class ThemeImpl {
  private _themeFile: string
  private _themeName: string
  private _themeType: ThemeType

  public get themeFile() {
    return this._themeFile
  }

  public get themeName() {
    return this._themeName
  }

  public get themeType() {
    return this._themeType
  }

  public isDark = (): boolean => {
    return this._themeType === ThemeType.dark
  }

  public isLight = (): boolean => {
    return !this.isDark()
  }

  public get interface(): Theme {
    return { themeFile: this._themeFile, themeName: this._themeName, themeType: this.themeName }
  }

  constructor(theme?: Partial<Theme>) {
    this._themeFile = theme?.themeFile || defaultTheme.themeFile
    this._themeName = theme?.themeName || defaultTheme.themeName
    this._themeType = ThemeType[theme?.themeType || defaultTheme.themeType] || ThemeType.light
  }
}

interface ThemingFetchTime {
  onLogin?: boolean
  onBaseURL?: boolean
}
const themingFetchTimeEnv = (window as any).THEMING_FETCH_TIME || process.env.REACT_APP_THEMING_FETCH_TIME || '{}'
const themeListEnv = (window as any).THEMING_FILE_LIST || process.env.REACT_APP_THEMING_FILE_LIST || '[]'

export const themingFetchTime: ThemingFetchTime = JSON.parse(themingFetchTimeEnv)

// necessary to render the light/dark button and dropdown (change theme) button
export const themeList: ThemeImpl[] = (JSON.parse(themeListEnv) as Theme[]).map(th => new ThemeImpl(th))
