import * as React from 'react'
import { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ChartPlaceholder, ChartStatusBadges } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import { parseResponseSingleData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import { convertSecondsToString } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/time'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableColumnWrapper, {
  MAX_TABLE_COLUMN, TableColumnWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import TableRowWrapper
  , {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface BearingItem {
  AssetName: string
  BearingNumber: number
  WarningThresold: number
  StopThresold: number
  TotalTimeOverWarning: number
  TotalTimeOverStop: number
  IntervalsOverWarning: number
  IntervalsOverStop: number
  MaxDurationWarning: number
  MaxDurationStop: number
  statusWarning?: boolean
  statusStop?: boolean
}

interface OwnProps {
  dateEnd: string
  dateStart: string
  days: number
  plant: any | null
}

interface FetchStatus {
  fetching: boolean
  error: boolean
}

type Props = OwnProps & WithTranslation

const BearingsGraphMainView = (props: Props) => {
  const [status, setStatus] = useState<FetchStatus>({
    fetching: false,
    error: false,
  })

  const [data, setData] = useState<BearingItem[]>([])

  const getData = async () => {
    setStatus({ fetching: true, error: false })
    const plantQuery = props.plant ? 'plant=' + props.plant.plant + '&' : ''
    const query = `SELECT measure FROM vBearingsTemperatures WHERE time >= '${props.dateStart}' AND time <= '${props.dateEnd}'`

    try {
      const bearingsData = await API().request(`/query?${plantQuery}q=${query}`)
      const parsedData = parseResponseSingleData(bearingsData)
      if (parsedData && parsedData[1]) {
        setData(parsedData && parsedData[1] ? JSON.parse(parsedData[1]) : [])
      }
      setStatus({ fetching: false, error: false })
    } catch (e) {
      setStatus({ fetching: false, error: true })
    }
  }

  useEffect(() => {
    if (!status.fetching && !status.error) {
      getData()
    }
  }, [props.dateEnd, props.dateStart, props.days, props.plant.plant])

  const itemStatusStop = (item: BearingItem) => !!(item.IntervalsOverStop || item.MaxDurationStop || item.TotalTimeOverStop)
  const itemStatusWarning = (item: BearingItem) => !!(item.IntervalsOverWarning || item.MaxDurationWarning || item.TotalTimeOverWarning)

  const { fetching, error } = status
  const { t } = props

  const itemsToShow = data ? data.map(i => {
    i.statusWarning = itemStatusWarning(i)
    i.statusStop = itemStatusStop(i)
    return i
  }).filter(i => i.statusWarning || i.statusStop) : []

  const noData = !fetching && !error && (itemsToShow === [])

  return (
    <>
      {ChartStatusBadges('multi-series-line-chart', fetching, error, noData, t)}
      <WithConditionWrapper condition={!error && !fetching}>
        {generateBrearing(itemsToShow)}
      </WithConditionWrapper>
      <WithConditionWrapper condition={itemsToShow.length === 0 && !fetching && !error}>
        {ChartPlaceholder(t('plantAnalysis.bearings.noWarnings'))}
      </WithConditionWrapper>
    </>
  )

  function generateBrearing(bearings: BearingItem[]) {
    return generateBearingSingleElement(generateLayoutStructure(bearings))
  }

  function generateBearingSingleElement(items: BearingItem[][]) {
    const result: JSX.Element[] = []
    items.forEach(item => {
      result.push(
        <TableRowWrapper className={'py-2'}>
          {item.map(i => generateBox(i))}
        </TableRowWrapper>)
    })
    return result
  }

  function generateLayoutStructure(bearings: BearingItem[]) {
    const result: BearingItem[][] = []
    let numberOfElement = 0
    let numberOfRow = 0
    bearings.forEach(item => {
      const itemDimension = item.statusStop && item.statusWarning ? 4 : 2
      if (numberOfElement + itemDimension <= MAX_TABLE_COLUMN) {
        numberOfElement = numberOfElement + itemDimension
      } else {
        numberOfRow = numberOfRow + 1
        numberOfElement = 0
      }
      if (!result[numberOfRow]) {
        result.push([])
      }
      result[numberOfRow].push(item)
    })
    return result
  }

  function generateBox(item: BearingItem) {
    return <TableColumnWrapper
      col={item.statusStop && item.statusWarning ? 4 : 2}
      key={item.AssetName + item.BearingNumber}
      className={
        `auto-dimention mb-4 p-2 ${(item.statusStop ? 'status-2' : '') + ' ' + (item.statusWarning ? 'status-1' : '')}`
      }
    >
      <div
        className={`single-bearing ${(item.statusStop ? 'status-2' : '') + ' ' + (item.statusWarning ? 'status-1' : '')} `}>
        <TableWrapperUtilities
          className={`content-wrapper rouded-boders ${(item.statusStop ? 'status-2__background' : '') + ' ' + (item.statusWarning ? 'status-1__background' : '')}`}
        >
          <TableRowFullWrapper>
            <h3>{item.AssetName}{item.BearingNumber ? ' - ' + item.BearingNumber : ''}</h3>
          </TableRowFullWrapper>
          <TableRowWrapper className={'py-2'}>
            <TableColumnWrapperWithCondition
              col={item.statusStop && item.statusWarning ? 'half' : 'full'}
              className={'auto-dimention text-center'}
              condition={item.statusStop !== undefined && item.statusStop}>
              <div className='single-bearing__status stop w-100'>
                <div>
                <span>{t('plantAnalysis.bearings.threshold')}
                  <strong> {t('plantAnalysis.bearings.stop')}</strong> {item.StopThresold} °C</span>
                  <span>{t('plantAnalysis.bearings.totalTime')}
                    <strong> {t('plantAnalysis.bearings.stop')}</strong><br /><strong
                      className='value'>{convertSecondsToString(item.TotalTimeOverStop * 60, t)}</strong></span>
                  <span>{t('plantAnalysis.bearings.intervalsNumber')}<br /><strong
                    className='value'>{item.IntervalsOverStop}</strong></span>
                  <span>{t('plantAnalysis.bearings.maxIntervalDuration')}<br /><strong
                    className='value'>{convertSecondsToString(item.MaxDurationStop * 60, t)}</strong></span>
                </div>
              </div>
            </TableColumnWrapperWithCondition>
            <TableColumnWrapperWithCondition
              col={item.statusStop && item.statusWarning ? 'half' : 'full'}
              className={'auto-dimention text-center'}
              condition={item.statusWarning !== undefined && item.statusWarning}>
              <div className='single-bearing__status warning w-100'>
                <div>
                  <span>Soglia <strong>allerta</strong> {item.WarningThresold} °C</span>
                  <span>{t('plantAnalysis.bearings.totalTime')} <strong>allerta</strong><br /><strong
                    className='value'>{convertSecondsToString(item.TotalTimeOverWarning * 60, t)}</strong></span>
                  <span>{t('plantAnalysis.bearings.intervalsNumber')}<br /><strong
                    className='value'>{item.IntervalsOverWarning}</strong></span>
                  <span>{t('plantAnalysis.bearings.maxIntervalDuration')}<br /><strong
                    className='value'>{convertSecondsToString(item.MaxDurationWarning * 60, t)}</strong></span>
                </div>
              </div>
            </TableColumnWrapperWithCondition>
          </TableRowWrapper>
        </TableWrapperUtilities>
      </div>
    </TableColumnWrapper>
  }

}

export default withTranslation()(BearingsGraphMainView)
