import {
  MaintenanceMacroArea,
  MaintenanceSection,
  MaintenanceComponent,
  MaintenanceTarget,
  MaintenanceType,
} from './maintenance'
import * as moment from 'moment'

export interface MaintenanceJobParams {
  'id': string
  'target': MaintenanceTarget
  'macroarea'?: MaintenanceMacroArea
  'section'?: MaintenanceSection
  'component'?: MaintenanceComponent
  'type': MaintenanceType
  'operation': string
  'periodicity': number,
  'hours': number
  'notes': string
  'previousJob': NextPrevJobParams
  'nextJob': NextPrevJobParams
}

export interface MaintenanceJobsStateProps {
  fetching: boolean,
  error: Error | null
  data: MaintenanceJob[]
}

export enum MaintenanceJobPeriodicity {
  '1_DAY' = 1,
  '1_WEEK' = 7,
  '2_WEEKS' = 14,
  '1_MONTH' = 30,
  '2_MONTHS' = 60,
  '3_MONTHS' = 90,
  '6_MONTHS' = 180,
  '1_YEAR' = 365,
  '2_YEARS' = 730,
}

export interface NextPrevJobParams {
  plannedDate: string
}

export class NextPrevJob {
  public dueDate: moment.Moment

  constructor(params: NextPrevJobParams) {
    this.dueDate = moment(params.plannedDate)
  }
}

export class MaintenanceJob {
  public id: string
  public target: MaintenanceTarget
  public macroarea?: MaintenanceMacroArea | null
  public section?: MaintenanceSection | null
  public component?: MaintenanceComponent | null
  public type: MaintenanceType

  public operation: string
  public periodicity?: number
  public hours?: number
  public notes: string
  public previousJob?: NextPrevJob | null
  public nextJob?: NextPrevJob | null

  constructor(params: MaintenanceJobParams) {
    this.id = params.id
    this.target = params.target
    this.macroarea = (params.macroarea) ? params.macroarea : null
    this.section = (params.section) ? params.section : null
    this.component = (params.component) ? params.component : null
    this.type = params.type
    this.operation = params.operation
    this.periodicity = params.periodicity
    this.hours = params.hours
    this.notes = params.notes || ''
    this.previousJob = (params.previousJob) ? new NextPrevJob(params.previousJob) : null
    this.nextJob = (params.nextJob) ? new NextPrevJob(params.nextJob) : null
  }
}
