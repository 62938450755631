import * as React from 'react'
import BarStack3Print from '../charts/BarStack3/BarStack3Print'
import { withTranslation, WithTranslation } from 'react-i18next'
import TableColumnWrapper from '../PrintUtilities/TableColumnWrapper'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'

export interface UnloadItem {
  workshift: number
  qta: number
}

interface OwnProps {
  colors: any
  name: string
  id: string
  unloaded: UnloadItem[]
  data: any
  days: number
  hideInverterDiretta?: boolean
}

type Props = OwnProps & WithTranslation

class BunkerBarStack3Print extends React.PureComponent<Props> {
  public render() {
    const { days, data } = this.props

    if (data) {
      const dataPieno: any = data.find((e: any) => e.name === 'vBunkerFull')
      const dataDiretta: any = data.find((e: any) => e.name === 'vBunkerBypass')

      const dataAuto: any = data.find((e: any) => e.name === 'vBunkerAuto')
      const dataMan: any = data.find((e: any) => e.name === 'vBunkerManual')
      const dataLoc: any = data.find((e: any) => e.name === 'vBunkerLocal')
      const dataSez: any = data.find((e: any) => e.name === 'vBunkerSectioned')

      const roundToTwo = (num: number) => num // +(Math.round(parseFloat(num + "e+4"))  + "e-4")

      const dataDiff =
        dataMan &&
        dataAuto &&
        dataLoc &&
        dataSez &&
        Math.abs(dataMan.value / days + dataAuto.value / days + dataLoc.value / days + dataSez.value / days - 100)
      const isInvalid = dataDiff && dataDiff > 0.5

      return (
        <>
          <TableColumnWrapper col={1} className={'bunker-row-item'}>
            <div className='bunker-row-item-wrapper'>
              <h3 className='mt-3'>{this.props.name}</h3>
              <div className={'graph ' + (isInvalid ? 'is-invalid' : '')}>
                <BarStack3Print
                  data={[
                    {
                      name: this.props.name,
                      bar1_1: dataPieno !== undefined ? 100 - roundToTwo(dataPieno.value) / days : false,
                      bar1_2: dataPieno !== undefined ? roundToTwo(dataPieno.value) / days : false,
                      bar2_1: this.props.hideInverterDiretta
                        ? 0
                        : dataDiretta !== undefined
                          ? 100 - roundToTwo(dataDiretta.value) / days
                          : false,
                      bar2_2: this.props.hideInverterDiretta
                        ? 0
                        : dataDiretta !== undefined
                          ? roundToTwo(dataDiretta.value) / days
                          : false,
                      bar3_1: dataMan !== undefined ? roundToTwo(dataMan.value) / days : false,
                      bar3_2: dataAuto !== undefined ? roundToTwo(dataAuto.value) / days : false,
                      bar3_3: dataLoc !== undefined ? roundToTwo(dataLoc.value) / days : false,
                      bar3_4: dataSez !== undefined ? roundToTwo(dataSez.value) / days : false,
                      bar3_x: isInvalid && dataDiff,
                    },
                  ]}
                  colors={this.props.colors}
                />
              </div>
            </div>
            <WithConditionWrapper condition={false && this.props.unloaded}>
              <ul className='unloaded'>
                {this.props.unloaded.map(u => (
                  <li key={u.workshift}>
                    {u.workshift}° {this.props.t('plantAnalysis.legend.workshift')}: {u.qta}
                  </li>
                ))}
              </ul>
            </WithConditionWrapper>
          </TableColumnWrapper>
        </>
      )
    }
    return null
  }
}

export default withTranslation()(BunkerBarStack3Print)
