import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { FileUploader } from '@mv-submodules/inplant-components-fe'
import { API } from '../../../../redux/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
import { File } from '../../../../types/pageForm'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnProps {
  onInputChange: (slug: string, value: any, template?: boolean) => void
  handleUploadFiles: (file: any, remove?: boolean) => void
  fieldValue: object | null
  canSend?: (state: boolean) => void
}

export type Props = OwnProps & WithTranslation

export default class FileUploaderField extends React.Component<Props, File> {
  constructor(props: Props) {
    super(props)
    this.upload = this.upload.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.state = {
      id: null,
      name: null,
      description: '',
      originalFileName: null,
    }
    if (this.props.fieldValue !== null) {
      // if there is already a file configure this.state with the existent file
      // since this component isn't just a presentation component is convenient to inject
      // the props in the state to manipulate them safely
      this.state = {
        ...this.state,
        ...this.props.fieldValue,
      }
    }
  }

  private upload(data: FormData) {
    // this.props.canSend(false) tells the form above to disable send button
    // tslint:disable-next-line:no-unused-expression
    this.props.canSend && this.props.canSend(false)
    // API.request wraps a fetch-wrapper istance previously configured so it handles authentication on his own
    return API().request('/chrono-frame/attachments', {
      method: 'POST',
      body: data,
    })
  }

  public onSuccess(result: { id: string; name: string; originalFileName: string }) {
    this.setState({
      id: result.id,
      originalFileName: result.originalFileName, // the name of the file in the filesystem of the uploader user
      name: result.name || 'filePlaceholderName.jpg', // the name of the file returned by the server
    })
    // tslint:disable-next-line:no-unused-expression
    this.props.canSend && this.props.canSend(true) // this.props.canSend(true) enabled the send button again
    this.props.handleUploadFiles(this.state) // to change this name since ids collides with another field in the post data object
  }

  public onError(result: Response) {
    // decide what to do
    // tslint:disable-next-line:no-console
    console.warn(result)
  }

  private deleteAttachment() {
    const toRemove = { ...this.state } // clone this.state to mantain a reference of the id to remove
    this.setState({
      id: null,
      name: null,
    })
    this.props.handleUploadFiles(toRemove, true) // second parameter is needed to tell the function to remove the 'file'
  }

  private handleDescriptionChange(event: any) {
    // TODO: debounce this function

    // this.setState callback used to be sure that the state that is passed to the handleUploadFiles function is the same as the last state
    this.setState({ description: event.currentTarget.value }, () => {
      this.props.handleUploadFiles(this.state)
    })
  }

  public render() {
    const t = this.props.t
    return (
      <React.Fragment>
        {this.state.id ? ( // if there is the file
          <React.Fragment>
            <Column >
              <span className="text-secondary">
                <FontAwesomeIcon icon={faFile} size="sm" />
                &emsp;{this.state.name || this.state.id}
              </span>
            </Column>
            < Column xs={6}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    {t('chronoframe.dueDates.detail.description')}
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('chronoframe.dueDates.detail.description')}
                  value={this.state.description}
                  onChange={this.handleDescriptionChange}
                />
              </div>
            </Column>
            <Column>
              <span
                className="btn btn-outline-danger"
                onClick={this.deleteAttachment}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
              </span>
            </Column>
          </React.Fragment>
        ) : (
          <Column>
            <FileUploader
              buttonText={t('chronoframe.dueDates.detail.upload')}
              upload={this.upload}
              onSuccess={this.onSuccess}
              onError={this.onError}
              buttonClass={'btn-secondary'}
              maxFileSize={40000000} // 40MB gateway limit. TODO: get it from the director
            />
          </Column>
        )}
      </React.Fragment>
    )
  }
}
