import { Dispatch } from 'redux'
import { API } from '.'
import FetchError from '../../../inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { logoutUser } from '../../../inplant-coreadapter-fe/redux/actions/auth'

export const fetchUpdatePassword = (
  userId: string,
  oldPassword: string,
  password: string,
  confirmPassword: string
) => async (dispatch: Dispatch): Promise<void> => {
  return API()
    .request(`/manage/users/${userId}/password`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, password, confirmPassword }),
    })
    .then((user:any) => user)
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}
