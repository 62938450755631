// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import IconComponent from '../MVIcon/Icon'
import WithLoader from '../Utils/withLoader'
import { ButtonTypes, ButtonVariants, DataBalloonPos, renderButtonClass } from './types'
import { Icon } from '../../../services/icon'
import { Margin, renderMargin, renderTextVariants, TextVariants } from '../Utils/utils'

export interface ButtonDefinition {
  type?: ButtonTypes
  label?: string
  icon?: Icon
  iconColor?: string
  variant: ButtonVariants
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  isLoading?: boolean
  size?: 'sm' | 'lg'
  iconSize?: 'sm' | 'lg'
  dataDismiss?: string
  ariaLabel?: string
  title?: string
  spacing?: Margin
  textVariant?: TextVariants
  blockLevel?: boolean
  dataBalloonPos?: DataBalloonPos
}

class Button extends React.Component<ButtonDefinition> {
  constructor(props: ButtonDefinition) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  private handleOnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  public render() {
    const {
      icon,
      iconColor,
      label,
      type,
      variant,
      disabled,
      isLoading,
      size,
      dataDismiss,
      ariaLabel,
      spacing,
      title,
      textVariant,
      blockLevel,
      iconSize,
      dataBalloonPos,
    } = this.props
    return (
      <button
        type={type || 'button'}
        disabled={disabled || isLoading}
        onClick={this.handleOnClick}
        aria-label={ariaLabel}
        data-dismiss={dataDismiss}
        className={`${renderButtonClass(variant, size)} ${renderMargin(spacing)} ${
          (blockLevel && 'btn-block') || ''
        } mv-button`}
        title={title}
        data-balloon-pos={dataBalloonPos && dataBalloonPos !== 'none' ? dataBalloonPos : undefined}
      >
        <WithLoader isLoading={isLoading} iconColor={iconColor}>
          {icon && (
            <IconComponent icon={icon} className={`${(label && 'mr-1') || ''}`} color={iconColor} size={iconSize} />
          )}
          {(label && <span className={renderTextVariants(textVariant)}>{label}</span>) || null}
        </WithLoader>
      </button>
    )
  }
}

export default Button
