import { AnyAction } from 'redux'
// import * as moment from 'moment'

const generalInitialState: any = {
  lavorazione: {
    data: {},
    fetching: false,
    error: false,
  },
  ricetta: {
    data: {},
    fetching: false,
    error: false,
  },
}

export const general = (state = generalInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_GENERAL_STATUS_IS_FETCHING':
      return {
        ...state,
        lavorazione: {
          fetching: true,
          error: false,
        },
        ricetta: {
          fetching: true,
          error: false,
        },
      }
    case 'PLANTANALYSIS_GENERAL_STATUS_FETCH_SUCCESS':
      return {
        ...state,
        lavorazione: {
          data: action.payload.lavorazione,
          fetching: false,
          error: false,
        },
        ricetta: {
          data: action.payload.ricetta,
          fetching: false,
          error: false,
        },
      }
    case 'PLANTANALYSIS_GENERAL_STATUS_FETCH_ERROR':
      return {
        ...state,
        lavorazione: {
          fetching: false,
          error: true,
          data: {},
        },
        ricetta: {
          fetching: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default general
