export default function duration(durationInSeconds: number): string {
  const hours = parseInt(`${durationInSeconds / 3600}`, 10);
  const minutes = parseInt(`${(durationInSeconds - hours * 3600) / 60}`, 10);
  const seconds = durationInSeconds % 60;

  return durationInSeconds
    ? `${hours}:${minutes < 10 ? 0 : ""}${minutes}:${
      seconds < 10 ? 0 : ""
    }${seconds}`
    : "--:--:--";
}
