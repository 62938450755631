import { PlantSelectorItem } from '../models'

export interface PlantSelectorState {
  hasErrored: boolean
  isLoading: boolean
  items: PlantSelectorItem[]
  plant: string | null
  isMultiPlant: boolean
  multiPlantAcl: undefined | string
  multiPlantDefault: null | string
}

export const initialPlantSelectorState: PlantSelectorState = {
  hasErrored: false,
  isLoading: false,
  items: [],
  plant: (window as any).MULTI_PLANT_DEFAULT || process.env.REACT_APP_MULTI_PLANT_DEFAULT || null,
  isMultiPlant: false,
  multiPlantAcl: undefined,
  multiPlantDefault: null
}
