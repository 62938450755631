import { AnyAction } from 'redux'

const modelInitialState: any = {
  data: null,
  error: false,
  fetching: false,
}

export const model = (state = modelInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_MODEL_IS_FETCHING':
      return {
        ...state,
        data: null,
        fetching: true,
        error: false,
      }
    case 'PLANTANALYSIS_MODEL_FETCH_SUCCESS':
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
      }
    case 'PLANTANALYSIS_MODEL_FETCH_ERROR':
      return {
        ...state,
        fetching: false,
        error: true,
      }

    //  DEFAULT

    default:
      return state
  }
}

export default model
