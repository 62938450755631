// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import Autocomplete from 'react-autocomplete'

interface Props {
  id?: string
  value: string
  label?: string
  disabled?: boolean
  placeholder?: string
  onChange: (value: string | null) => void
  options: Option[],
  labelClassName?: string
  menuClassName?: string
}

interface Option {
  label: string,
  value: string
}

interface OwnState {
  value: string
}

class SearchableSelect extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: '',
    }

    this.filterItems = this.filterItems.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps && nextProps.value){
      this.setState({value: nextProps.value})
    }
  }

  public render() {
    const props = this.props
    return (
      <div className="form-group">
        {props.label && <label className={props.labelClassName || ''}>{props.label}</label>}
        <Autocomplete
          wrapperProps={{ className: '', style: {} }}
          value={props.value || this.state.value}
          inputProps={{ id: props.id, className: 'form-control' }}
          items={props.options.filter(this.filterItems)}
          getItemValue={(item: Option) => item.value}
          onSelect={(value: string) => props.onChange(value)}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ value: target.value })
            props.onChange(target.value)
          }}
          renderItem={(item: Option) => (
            <div className="item" key={`item_${JSON.stringify(item)}`} style={{paddingBottom: '0.5rem', cursor: 'pointer'}}>
              {item.label}
            </div>
          )}
          renderMenu={(items: any[], value: string) => (
            <div style={{backgroundColor: '#e9ecef', padding: '0.5rem'}}>
              {items.length === 0 ? (
                <div className="item">No matches for {value}</div>
              ) : items}
            </div>
          )}
        />
      </div>
    )
  }

  private filterItems(option: Option) {
    return option.label.toUpperCase().replace(' ', '').includes(this.state.value.toUpperCase().replace(' ', '')) || this.state.value.length === 0
  }
}

export default SearchableSelect