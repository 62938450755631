import { combineReducers, ReducersMapObject } from 'redux'
import { titechList } from './titechList'
import { titechSetup } from './titechSetup'

const reducersMap: ReducersMapObject = {
  titechList,
  titechSetup,
}

export default combineReducers(reducersMap)
