import React from 'react'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import WithConditionHOC
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

interface Props {
  children: React.ReactNode
  className?: string
}

const TableRowWrapper = (props: Props) => {

  const { children, className } = props

  return <tr className={`align-top-table ${className ? className : ''}`}>
    {children}
  </tr>
}

export default TableRowWrapper

interface TableRowFullWrapperProps extends Props {
  columnClassName?: string
}

export const TableRowFullWrapper = (props: TableRowFullWrapperProps) => {
  const { children, className, columnClassName } = props

  return (
    <TableRowWrapper className={className}>
      <TableColumnWrapper className={columnClassName} col={'full'}>
        {children}
      </TableColumnWrapper>
    </TableRowWrapper>
  )
}

export const TableRowWrapperWithInnerTable = (props: Props) => {
  const { children } = props

  return <TableRowWrapper>
    <TableWrapperUtilities>
      {children}
    </TableWrapperUtilities>
  </TableRowWrapper>

}

export const TableRowWrapperWithCondition = WithConditionHOC(TableRowWrapper)
export const TableRowFullWrapperWithCondition = WithConditionHOC(TableRowFullWrapper)