// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { TableProps } from 'react-table'

// * -------------------------------- NPM --------------------------------------
import IconComponent from '../MVIcon/Icon'

interface Props extends TableProps {
  pageText: string
  ofText: string
  rowsText: string
  isFetching: boolean
  leftComponent?: React.ReactNode
}

interface OwnState {
  page: string
  pageSizes: number[]
}

export const pageSizes = [10, 15, 25, 50, 100, 300]

class PaginationComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    if (props.pageSize && !pageSizes.includes(props.pageSize)) {
      pageSizes.push(props.pageSize)
      pageSizes.sort((a: number, b: number) => a - b)
    }

    this.state = {
      page: `${(props.page || 0) + 1}`,
      pageSizes,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
  }

  /*
    public getDerivedStateFromProps(
      props: TableProps,
      currentState: OwnState,
    ): OwnState {
      return {
        ...currentState,
        page: `${(props.page || 0) + 1}`,
      }
    }*/

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (this.props.page !== nextProps.page) {
      this.setState({ page: nextProps.page! + 1 + '' })
    }
  }

  public render() {
    const props = this.props
    return props.pages ? (
      <div
        className={`-pagination ${
          props.leftComponent !== undefined ? 'justify-content-space-between' : 'justify-content-end'
        }`}
      >
        {this.props.leftComponent !== undefined && this.props.leftComponent}
        <div className="d-flex">
          <div className="d-flex justify-content-right align-items-center">
            {props.pageText && <span className="mr-1">{props.pageText}</span>}
            <input
              className="form-control ml-1 mr-1"
              type="number"
              onChange={this.handlePageChange}
              min={1}
              max={props.pages}
              value={parseInt(this.state.page, 10)}
              style={{ maxWidth: 70 }}
              disabled={props.isFetching}
            />
            <span className="ml-1" style={{ flex: 'none' }}>
              {props.ofText} {props.pages}
            </span>
          </div>

          <div className="ml-3 d-flex justify-content-right align-items-center">
            <select
              className="form-control"
              onChange={this.handlePageSizeChange}
              value={props.pageSize}
              disabled={props.isFetching}
            >
              {this.state.pageSizes.map((rows: number) => (
                <option key={rows} value={rows}>
                  {rows} {props.rowsText}
                </option>
              ))}
            </select>
          </div>

          <div className="btn-group ml-4" role="group">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => {
                const value: number = parseInt(this.state.page, 10) - 2
                this.setState({ page: value + '' }, () => props.onPageChange!(value))
              }}
              // minus 1 because in manual false we start from 1
              disabled={!(parseInt(this.state.page, 10) - 1) || props.isFetching}
            >
              <IconComponent icon={'chevron-left'} className="text-muted" />
            </button>
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => {
                const value: number = parseInt(this.state.page, 10)
                this.setState({ page: value + '' }, () => props.onPageChange!(value))
              }}
              disabled={parseInt(this.state.page, 10)! === props.pages || props.isFetching}
            >
              <IconComponent icon={'chevron-right'} className="text-muted" />
            </button>
          </div>
        </div>
      </div>
    ) : null
  }

  private handlePageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = parseInt(event.currentTarget.value, 10)
    const value: number = inputValue - 1 || 0
    const pages: number = this.props.pages!
    const page = value < pages ? value : pages
    if (inputValue) {
      this.props.onPageChange!(page)
    }
  }

  private handlePageSizeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const pageSize: number = parseInt(event.currentTarget.value, 10)
    this.setState({ page: String(1) }, () => this.props.onPageSizeChange!(pageSize, 1))
  }
}

export default PaginationComponent
