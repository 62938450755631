// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import {
  Margin,
  Padding,
  SpaceType,
  generateSpace,
} from '../../../functions/bootstrapUtility'

interface Props {
  margin?: Margin
  padding?: Padding
}

const Line = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { padding,margin } = props

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <hr
      className={`mv-line ${(margin && generateSpace(SpaceType.margin, margin)) || ''} ${(padding &&
        generateSpace(SpaceType.padding, padding)) ||
        ''}`}
    />
  )
}

export default Line
