import { API } from '.'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import * as moment from 'moment'
import { isMoment } from 'moment'
import { getDailyView } from './common'
import { plantQuery } from '../../functions/model'
import { getCutoff } from '../../functions/shared'
import genericMeasureFetch from './genericMeasureFetch'
// import { resetWorkshiftFilter, updateWorkshiftFilter } from './common'

export const workshiftStatusIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_WORKSHIFT_IS_FETCHING',
  }
}

export const workshiftStatusFetchSuccess = (dataWorkshiftSrc: any): AnyAction => {
  const fullDay = getDailyView(dataWorkshiftSrc)

  return {
    type: 'PLANTANALYSIS_WORKSHIFT_FETCH_SUCCESS',
    payload: {
      workshifts: dataWorkshiftSrc,
      fullDay,
    },
  }
}

export const workshiftStatusFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_WORKSHIFT_FETCH_ERROR',
    payload: error,
  }
}

export const workshiftFetchData = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  withGateway: boolean
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  return async (dispatch, getState) => {
    dispatch(workshiftStatusIsFetching())
    const plantQueryString = plantQuery(getState)

    try {
      const startDate = dateStart
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDate = dateEnd
        .add(1, 'day') // workshift may end the day after
        .set({ h: 12, m: 0, s: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const chartName = 'CURRENT_WORK_SHIFT'
      const queryWorkshift =
        `SELECT "measure" FROM ${chartName}` + ` WHERE time >= '${startDate}' AND time <= '${endDate}'`

      let dataWorkshift
      let dataWorkshiftJson
      let output: any
      if (withGateway) {
        dataWorkshiftJson = await genericMeasureFetch(
          chartName,
          getState().plantSelector.plant,
          'influxResponse',
          startDate,
          endDate,
        )
        output = dataWorkshiftJson
      } else {
        dataWorkshift = await API().request(`/query?${plantQueryString}q=` + queryWorkshift)
        dataWorkshiftJson = JSON.parse(dataWorkshift)
        output =
          dataWorkshiftJson.results[0] && dataWorkshiftJson.results[0].series
            ? dataWorkshiftJson.results[0].series[0]
            : null
      }


      dispatch(workshiftStatusFetchSuccess(output))

      return Promise.all([output])
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(workshiftStatusFetchError(error))
      throw error
    }
  }
}

/***********/

export const workshiftValidityStatusIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_WORKSHIFTVALIDITY_IS_FETCHING',
  }
}

export const workshiftValidityStatusFetchSuccess = (dataWorkshiftValiditySrc: any): AnyAction => {
  return {
    type: 'PLANTANALYSIS_WORKSHIFTVALIDITY_FETCH_SUCCESS',
    payload: {
      workshiftsValidity: dataWorkshiftValiditySrc,
    },
  }
}

export const workshiftValidityStatusFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_WORKSHIFTVALIDITY_FETCH_ERROR',
    payload: error,
  }
}

export const workshiftValidityFetchData = (
  withGateway: boolean,
  dateFilterSrc: moment.Moment,
  fetchDayAfter: boolean | undefined,
  cutoff: string | undefined
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  return async (dispatch, getState) => {
    dispatch(workshiftValidityStatusIsFetching())
    const plantQueryString = plantQuery(getState)

    try {
      const dateFilter = isMoment(dateFilterSrc) ? dateFilterSrc : moment(dateFilterSrc)
      const dateFilterClone = dateFilter.clone()

      const startDateSrc = dateFilter
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateSrc = dateFilterClone
        .set({ h: 23, m: 59, s: 59 })
        // .add(fetchDayAfter ? 1 : 0, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      let startDateStr = startDateSrc
      let endDateStr = endDateSrc

      if (cutoff) {
        const cutoffTime = getCutoff(cutoff, startDateSrc)
        if (cutoffTime) {
          const { startDate, endDate } = cutoffTime
          startDateStr = startDate
          endDateStr = endDate
        }
      }

      const queryWorkshiftValidity = `SELECT last(measure) FROM /PLANT_SHIFT(.*)_ENAB/ WHERE time < '${endDateStr}' AND measure = FALSE`
      let dataWorkshiftValidity: any
      let output

      if (withGateway) {
        /* /PLANT_SHIFT(.*)_ENAB/ */
        dataWorkshiftValidity = await genericMeasureFetch(
          'plantShiftEnab',
          getState().plantSelector.plant,
          'influxResponse',
          undefined,
          endDateStr,
        )
        output = dataWorkshiftValidity.values.map(
          (s: any) => {
            return {
              name: dataWorkshiftValidity.name,
              status: !(
                s.values &&
                s.values[0] &&
                s.values[0][0] &&
                moment(s.values[0][0]).isBetween(moment(startDateStr), moment(endDateStr))
              ),
            }
          },
        )
      } else {
        dataWorkshiftValidity = await API().request(`/query?${plantQueryString}q=` + queryWorkshiftValidity)
        const dataWorkshiftValidityJson = typeof dataWorkshiftValidity === 'string' ? JSON.parse(dataWorkshiftValidity) : dataWorkshiftValidity
        output =
          dataWorkshiftValidityJson.results[0] && dataWorkshiftValidityJson.results[0].series
            ? dataWorkshiftValidityJson.results[0].series.map(
              (s: { name: string; columns: string[]; values: Array<[string, boolean]> }) => {
                return {
                  name: s.name,
                  status: !(
                    s.values &&
                    s.values[0] &&
                    s.values[0][0] &&
                    moment(s.values[0][0]).isBetween(moment(startDateStr), moment(endDateStr))
                  ),
                }
              },
            )
            : null

      }

      dispatch(workshiftValidityStatusFetchSuccess(output))

      return Promise.all([output])
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(workshiftValidityStatusFetchError(error))
      throw error
    }
  }
}
