import { Dispatch } from 'redux'
import { API } from '.'
import { logoutUser } from '../../../inplant-coreadapter-fe/auth'
import Configuration from '../../types/configuration'

export const fetchConfiguration = (abortController?: AbortController) => async (
  dispatch: Dispatch
): Promise<Configuration> => {
  try {
    return API().request('/halts/config/manager', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Content-Language': 'it-IT',
      },
      signal: abortController?.signal,
    })
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}
