import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetDetail,
  AssetValidateActionAvailabilty,
  RequestDisableAssetForm,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {Loader, PageHeader, DateInput, TextArea, Link, Alert, Button} from '@mv-submodules/inplant-components-fe'
import {
  checkDeactivationAvailability,
  deactivateAsset,
  fetchDetailAsset,
  validateAssetDeactivation,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import moment from 'moment'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Radio from '@mv-submodules/inplant-components-fe/ui/components/Input/Radio'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnState {
  data: RequestDisableAssetForm
  assetDetail?: AssetDetail
  deactivationAvailability?: AssetValidateActionAvailabilty
  isFetching: boolean
  isFetchingDeactivationAvailabilty: boolean
  fetchErrors: boolean
  isSubmitting: boolean
}

// Dismettere => Rottama scrap
// Accontanare => Sospendi deactivate

type Props = RouteComponentProps & WithTranslation

class DisableAssetPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: {
        date: moment().format('YYYY-MM-DD'),
        action: 'scrap',
        note: '',
      },
      isFetching: false,
      fetchErrors: false,
      isSubmitting: false,
      isFetchingDeactivationAvailabilty: false,
    }
    this.fetchAssetDetail = this.fetchAssetDetail.bind(this)
    this.fetchDeactivationAvailabilty = this.fetchDeactivationAvailabilty.bind(this)
    this.goBackAssetDetail = this.goBackAssetDetail.bind(this)
    this.goToAssetArchive = this.goToAssetArchive.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] //tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list-active')
    }
    this.fetchAssetDetail(assetId)
  }

  public render() {
    const { t } = this.props
    const {
      isFetching,
      isFetchingDeactivationAvailabilty,
      deactivationAvailability,
      isSubmitting,
      fetchErrors,
      assetDetail,
      data,
    } = this.state
    return (
      <div className="inplant-asset-manager-fe">
        {isFetching && <Loader />}
        {!isFetching && fetchErrors && (
          <Alert type={'danger'}>
            {t('assetManager.fetchErrors')}
          </Alert>
        )}
        {!isFetching && !fetchErrors && assetDetail && (
          <form onSubmit={this.onSubmit}>
            {/*Header Section*/}
            <PageHeader
              title={t('assetManager.navigation.disableAsset')}
              backButton={true}
              backButtonOnClick={() => this.props.history.goBack()}
              rightButtons={
                <>
                  <Button variant={"secondary-alternate"} onClick={this.goBackAssetDetail} label={t('assetManager.actions.cancel')}/>
                  <button
                    className="btn btn-primary ml-2"
                    type="submit"
                    disabled={isSubmitting || isFetchingDeactivationAvailabilty || (deactivationAvailability && !deactivationAvailability.allowed && (assetDetail.status === 'deactivation-to-validate' ||
                      assetDetail.status === 'selling-to-validate' || assetDetail.status === 'scrapping-to-validate' || assetDetail.status === 'freezing-to-validate'))}>
                    {isSubmitting || isFetchingDeactivationAvailabilty ? (
                      <FontAwesomeIcon icon={faCircleNotch} color={'#ffffff'} spin={true} />
                    ) : (
                        t('assetManager.actions.save')
                      )}
                  </button>
                </>
              }
            />
            {/*End Header Section*/}
            <div className="content asset-detail">
              {deactivationAvailability && (!deactivationAvailability.allowed || deactivationAvailability.showMessage) && (
                <Alert  type={ (assetDetail.status === 'deactivation-to-validate' || assetDetail.status === 'scrapping-to-validate'
                || assetDetail.status === 'selling-to-validate') ? 'danger' : 'primary'}>
                  {deactivationAvailability.message.mainText}
                  {deactivationAvailability.message.subElements && (
                    <ul>
                      {deactivationAvailability.message.subElements.map(subelement => (
                        <li>
                          <Link theme="dark" onClick={() => this.props.history.push(subelement.link || '')}
                                label={subelement.text}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </Alert>

              )}
              <Row>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.machineCode')}</label>
                  <p>
                    <strong>{assetDetail.assetCode || '-'}</strong>
                  </p>
                </Column>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.plantCode')}</label>
                  <p>
                    <strong>{assetDetail.positionCode}</strong>
                  </p>
                </Column>
                <Column md={3}>
                  <label className="small-label-readonly">{t('assetManager.forms.assetDescription')}</label>
                  <p>
                    <strong>{assetDetail.assetDescription}</strong>
                  </p>
                </Column>
              </Row>
              <Row >
                <Column md={3}>
                  <div className="form-group mb-0">
                    <DateInput
                      label={t('assetManager.forms.endDate')}
                      name="date"
                      value={data.date}
                      max={undefined}
                      onChange={value => this.handleInputChange(undefined, { value: value as string, name: 'date' })}
                      required={true}
                      disabled={
                        assetDetail.status === 'deactivation-to-validate' ||
                        assetDetail.status === 'scrapping-to-validate' ||
                        assetDetail.status === 'freezing-to-validate'
                      }
                      id="form-endDate"
                    />
                  </div>
                </Column>
                {assetDetail.status !== 'deactivation-to-validate' &&
                  assetDetail.status !== 'scrapping-to-validate' &&
                  assetDetail.status !== 'selling-to-validate' &&
                  assetDetail.status !== 'freezing-to-validate' && (
                    <Column md={6}>
                      <Radio
                        name="action"
                        value={data.action}
                        onChange={(value) => this.handleInputChange(undefined, { value, name: 'action' })}
                        labelPosition="RIGHT"
                        options={{
                          items: [
                            {
                              label: t('assetManager.forms.scrap'),
                              value: 'scrap',
                              id: 'actionScrap',
                            },
                            {
                              label: t('assetManager.forms.sell'),
                              value: 'sell',
                              id: 'actionSell',
                            },
                            {
                              label: t('assetManager.forms.freeze'),
                              value: 'freeze',
                              id: 'actionFreeze',
                            },

                          ],
                        }}
                      />

                    </Column>
                  )}
              </Row>
              <Row >
                <Column md={9} formGroup={true} >
                    <TextArea
                      label={t('assetManager.forms.notes')}
                      id="form-asset-notes"
                      value={data.note}
                      name="note"
                      onChange={value => this.handleInputChange(undefined, { value: value as string, name: 'note' })}
                    />
                </Column>
              </Row>
            </div>
          </form>
        )}
      </div>
    )
  }

  private async fetchAssetDetail(assetId: string) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      try {
        const assetDetail: AssetDetail = await fetchDetailAsset(assetId)
        this.setState({ assetDetail })
        if (assetDetail.deactivatedOn) {
          this.setState({
            data: {
              ...this.state.data,
              date: moment(assetDetail.deactivatedOn).format('YYYY-MM-DD'),
            },
          })
        }
        if (assetDetail.status === 'deactivation-to-validate' || assetDetail.status === 'scrapping-to-validate' ||
          assetDetail.status === 'freezing-to-validate' || assetDetail.status === 'selling-to-validate') {
          this.fetchDeactivationAvailabilty(assetId)
        }
      } catch (error) {
        this.setState({ fetchErrors: true })
        console.log('error', error)//tslint:disable-line
      } finally {
        this.setState({ isFetching: false })
      }
    }
  }

  private async fetchDeactivationAvailabilty(assetId: string) {
    if (!this.state.isFetchingDeactivationAvailabilty) {
      this.setState({ isFetchingDeactivationAvailabilty: true })
      try {
        const deactivationAvailability: AssetValidateActionAvailabilty = await checkDeactivationAvailability(assetId)
        this.setState({ deactivationAvailability, isFetchingDeactivationAvailabilty: false })
      } catch (e) {
        this.setState({ fetchErrors: true })
        console.log('error', e)//tslint:disable-line
      }
    }
  }

  private goBackAssetDetail() {
    if (this.state.assetDetail) {
      // this.props.history.push(`/asset-manager/detail-asset/${this.state.assetDetail.id}`)
      this.props.history.goBack()
    }
  }

  private goToAssetArchive() {
    // this.props.history.push('/asset-manager/list-archive')
    this.props.history.goBack()
  }

  private handleInputChange(
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    state?: { value: string; name: string },
  ) {
    const { name, value } = event ? event.target : state!
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    })
  }

  private async onSubmit(event: React.FormEvent) {
    event.preventDefault()
    if (this.state.assetDetail) {
      this.setState({ isSubmitting: true })
      try {
        if (this.state.assetDetail.status === 'deactivation-to-validate' || this.state.assetDetail.status === 'scrapping-to-validate' || this.state.assetDetail.status === 'freezing-to-validate' || this.state.assetDetail.status === 'selling-to-validate') {
          await validateAssetDeactivation(this.state.assetDetail.id, this.state.data.note)
          this.goToAssetArchive()
        } else {
          await deactivateAsset(this.state.assetDetail.id, this.state.data)
          this.goBackAssetDetail()
        }
      } catch (e) {
        console.log(e) //tslint:disable-line
      } finally {
        this.setState({ isSubmitting: false })
      }
    }
  }
}

export default withRouter<any, any>(withTranslation()(DisableAssetPageView))
