import * as React from 'react'
import * as moment from 'moment'
import { withTranslation, WithTranslation } from 'react-i18next'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../../functions/shared'
import genericMeasureFetch from '../../../../../redux/actions/genericMeasureFetch'
import { connect } from 'react-redux'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  showTimeline?: boolean
}

interface OwnState {
  result:
    | {
        columns: Record<string, string>
        value: Array<Record<string, any>>
      }
    | undefined
  isFetching: boolean
  fetchErrors: boolean
}

const cleanState: OwnState = {
  result: undefined,
  isFetching: false,
  fetchErrors: false,
}

interface StateProps {
  plant: any | null
  dateFilterStart: string
  dateFilterEnd: string
}

const keyOrder = ['recipeName', 'totalBales', 'totalTons', 'avgWeight']

const mapStateToProps = (state: any): StateProps => ({
  plant: state.plantSelector || null,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
})

type Props = OwnProps & StateProps & WithTranslation

class RppIdealTable extends React.Component<Props, OwnState> {
  // @ts-ignore
  private mounted: boolean
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = cleanState
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.state.isFetching &&
      nextProps &&
      this.props &&
      !nextProps.plant.isLoading &&
      (nextProps.plant.plant !== this.props.plant.plant ||
        (nextProps.dateFilterStart && nextProps.dateFilterStart !== this.props.dateFilterStart))
    ) {
      this.setState(cleanState, () => this.getData(this.props))
    }
  }

  public render() {
    const { t } = this.props
    const { isFetching, fetchErrors, result } = this.state
    const noData = !isFetching && !fetchErrors && result === undefined
    const columnsName = result?.columns && Object.entries(result.columns).filter(([key, value]) => value !== 'time')
    const timeSlug = result?.columns && Object.entries(result.columns).find(([key, value]) => value === 'time')
    const values = result?.value ?? []

    const totalBales = values.reduce(
      (acc, curr) =>
        acc +
          Object.entries(curr).find(
            c => columnsName?.filter(v => v[1] === 'totalBales').find(v => v[0] === c[0])?.[0]
          )?.[1] ?? 0,
      0
    )
    const totalTons = values.reduce(
      (acc, curr) =>
        acc +
          Object.entries(curr).find(
            c => columnsName?.filter(v => v[1] === 'totalTons').find(v => v[0] === c[0])?.[0]
          )?.[1] ?? 0,
      0
    )

    return (
      <React.Fragment>
        {isFetching && (
          <div className="alert alert-secondary w-100 col-sm-6 mx-auto rpp-graph-fetch-loading alert-local">
            {this.props.t('plantAnalysis.loading')}
            <Loader />
          </div>
        )}
        {noData && (
          <div className="alert alert-warning w-100 col-sm-6 mx-auto rpp-graph-fetch-warning alert-local">
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        )}
        {!isFetching && fetchErrors && (
          <div className="alert alert-danger w-100 col-sm-6 mx-auto bagspeed-graph-fetch-error alert-local">
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        )}
        {!noData && (
          <table className={'table table-borderless w-50 mw-300 font-size-12 table-first-column-color table-striped'}>
            <thead>
              <tr>
                {columnsName
                  ?.sort((a, b) => keyOrder.indexOf(a[1] as any) - keyOrder.indexOf(b[1] as any))
                  .map(([, value], index) => (
                    <th key={index} className="text-right">
                      {t(`plantAnalysis.rppTableIdeal.${value}`)}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {values.map((value, index) => {
                return (
                  <tr key={index}>
                    {Object.entries(value)
                      .filter(([key]) => timeSlug?.[0] !== key)
                      .sort((a, b) => {
                        const aKey = columnsName?.find(v => v[0] === a[0])
                        const bKey = columnsName?.find(v => v[0] === b[0])
                        return keyOrder.indexOf(aKey?.[1] as any) - keyOrder.indexOf(bKey?.[1] as any)
                      })
                      .map(([vKey, v], vIndex) => {
                        return (
                        <td className={'text-right'} key={`v-${vIndex}`}>
                          {`${v.toString()}${result?.columns[vKey] === 'totalTons' || result?.columns[vKey] === 'avgWeight' ? ' kg' : ''}`}
                        </td>
                      )
                      })}
                  </tr>
                )
              })}
              <tr>
                <td className={'text-right'}>
                  <b>{t(`plantAnalysis.rppTableIdeal.totals`)}</b>
                </td>
                <td className={'text-right'}>{`${totalBales}`}</td>
                <td className={'text-right'}>{`${totalTons} kg`}</td>
              </tr>
            </tbody>
          </table>
        )}
      </React.Fragment>
    )
  }

  private async getData(props: Props) {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd } = this.props
    const startDate = moment(dateFilterStart)
      .set({ h: 0, m: 0, s: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate = moment(dateFilterEnd)
      .set({ h: 23, m: 59, s: 59 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    this.setState({
      isFetching: true,
      fetchErrors: false,
    })

    try {
      Promise.all([genericMeasureFetch(`vPressReport`, plant, 'normal', startDate, endDate)]).then(([response]) => {
        if (response.columns && response.value) {
          this.setState({
            result: {
              columns: response.columns ?? {},
              value: response.value ?? [],
            },
            isFetching: false,
            fetchErrors: false,
          })
          return
        }
        this.setState({
          result: undefined,
          isFetching: false,
          fetchErrors: false,
        })
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isFetching: false,
        fetchErrors: true,
      })
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(RppIdealTable))
