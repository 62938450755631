import { AnyAction } from 'redux'
import { defaultWorkshiftNumber } from '../../types/workshift'
import * as moment from 'moment'

const commonInitialState: any = {
  dateFilterStart: moment(),
  dateFilterEnd: moment(),
  workshift: defaultWorkshiftNumber,
  isManual: false,
  isDateFilterRange: false,
}

export const common = (state = commonInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_COMMON_DATE_FILTER':
      const days =
        action.payload.dateStart &&
        action.payload.dateEnd &&
        Math.abs(action.payload.dateStart.diff(action.payload.dateEnd, 'days'))

      return {
        ...state,
        dateFilterStart: action.payload.dateStart.utc().format('YYYY-MM-DD'),
        dateFilterEnd: action.payload.dateEnd.utc().format('YYYY-MM-DD'),
        workshift: null,
        isManual: action.isManual,
        isDateFilterRange: days && days > 0,
        days: days + 1,
      }

    case 'PLANTANALYSIS_COMMON_WORKSHIFT_FILTER':
      return {
        ...state,
        workshift: action.payload,
      }

    case 'PLANTANALYSIS_COMMON_WORKSHIFT_RESET':
      return {
        ...state,
        workshift: action.payload || null,
      }

    default:
      return state
  }
}

export default common
