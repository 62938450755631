import * as moment from 'moment'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { RowInfo, SortingRule } from 'react-table'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import {
  applyParams,
  exportData,
  fetchAssets,
  fetchCauses,
  fetchCounterUnjustified,
  fetchData,
  fetchDataMulti,
} from '../../../../redux/actions/plantHalts'
import { ListDispatchProps, ListParams, ListStoreSlice } from '../../../../types/store'
import {
  PlantAsset,
  PlantHalt,
  PlantHaltCause,
  PlantHaltCauseType,
  PlantHaltFilters,
  PlantHaltListParams,
  PlantHalts,
} from '../../../../types/plantHalts'
import PlantHaltModal from '../../widgets/PlantHaltModal/PlantHaltModal'
import duration from '../../../../functions/duration'
import { isJSON } from '../../../../functions/shared'
import { Button, Filters, Loader, PageHeader, Table } from '@mv-submodules/inplant-components-fe'
import { FilterComponent, filterType } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column' // @TODO CHECK WHY SO
import UnitSelector from '../../widgets/UnitSelector/UnitSelector'
import Configuration, { UnitValue } from '../../../../types/configuration'
import { fetchConfiguration } from '../../../../redux/actions/manager'

interface OwnState extends ListParams {
  assets: PlantAsset[]
  causes: PlantHaltCause[]
  causeTypes: PlantHaltCauseType[]
  data: PlantHalt[]
  debounce: number | null
  detailData: PlantHalt | null
  showDetailModal: boolean
  // detailsModalAction: string | null
  causeKey?: string | null
  causeType?: string | null
  from?: moment.Moment | null
  to?: moment.Moment | null
  bulky?: boolean
  code?: number | null
  confirmed: boolean
  onlyUnjustified?: boolean
  searchString?: string | null
  enableActions: boolean
  configuration?: Configuration
  unitSelected?: UnitValue
  zoneSelected?: string
  isLoadingConfiguration: boolean
}

interface DispatchProps extends ListDispatchProps<PlantHalts, PlantHaltFilters> {
  fetchAssets: (isMultiplant: boolean, plant: string | null) => Promise<PlantAsset[]>
  fetchDataMulti: (
    isMultiplant: boolean,
    plant: string | null,
    confirmed: boolean,
    abortController: AbortController,
    params?: any
  ) => Promise<PlantHalts>
  fetchCauses: () => Promise<PlantHaltCause[]>
  exportData: (filters: PlantHaltFilters, isMultiplant: boolean, plant: string | null) => Promise<void>
  fetchCounterUnjustified: (isMultiplant: boolean, plant: string | null) => void
  fetchConfiguration: () => Promise<Configuration>
}

interface StateProps {
  config: { enabledActions?: string; typeOfInplant?: string } | null
  plant: any | null
  isMultiPlant: boolean
}

const mapStateToProps = (store: any): ListStoreSlice<PlantHalts, PlantHaltListParams> & StateProps => {
  return {
    config: store.config.plantHalts || null,
    plant: store.plantSelector || null,
    isMultiPlant:
      store.config.plantSelector &&
      store.config.plantSelector.isMultiPlant &&
      store.config.plantSelector.isMultiPlant === 'true',
    ...store.plantHalts.plantHalts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchAssets: (isMultiPlant: boolean, plant: any | null) => fetchAssets(isMultiPlant, plant)(dispatch),
  fetchData: params => fetchData(params)(dispatch),
  fetchDataMulti: (isMultiPlant: boolean, plant: any | null, confirmed: boolean, abortController, params: any) =>
    fetchDataMulti(isMultiPlant, plant, confirmed, abortController, params)(dispatch),
  fetchCauses: () => fetchCauses()(dispatch),
  applyParams: params => dispatch(applyParams(params)),
  exportData: (filters: PlantHaltFilters, isMultiPlant: boolean, plant: any | null) =>
    exportData(filters, isMultiPlant, plant)(dispatch),
  fetchCounterUnjustified: (isMultiplant: boolean, plant: any | null) =>
    fetchCounterUnjustified(isMultiplant, plant)(dispatch),
  fetchConfiguration: () => fetchConfiguration()(dispatch),
})

type Props = ListStoreSlice<PlantHalts, PlantHaltListParams> &
  DispatchProps &
  StateProps &
  WithTranslation &
  RouteComponentProps

class PlantHaltListPageView extends React.Component<Props, OwnState> {
  private mounted: boolean = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    /*
    UNUSED
    const noDatesSelected =
      !props.params || (!props.params.to && !props.params.from)
    */
    const confirmed = props.match.path !== '/plant-halts/unchecked'
    this.state = {
      assets: [],
      causes: [],
      causeTypes: [],
      data: this.filterData(props.data && props.data.halts, props.params && props.params.search),
      debounce: null,
      detailData: null,
      page: (props.params && props.params.page) || 1,
      pageSize: (props.params && props.params.pageSize) || 50,
      showDetailModal: false,
      // detailsModalAction: null,
      causeKey: (props.params && props.params.causeType && props.params.causeKey) || null,
      causeType: (props.params && props.params.causeType) || null,
      from:
        props.params && props.params.from && moment(props.params.from)
          ? moment(props.params.from)
          : props.isMultiPlant
          ? moment().subtract(1, 'days')
          : moment(),
      to: props.params && props.params.to && moment(props.params.to) ? moment(props.params.to) : moment(),
      bulky: (props.params && props.params.bulky) || false,
      code: null,
      confirmed,
      onlyUnjustified: false,
      searchString: '',
      enableActions: false,
      isLoadingConfiguration: false,
    }

    this.applyTableParams = this.applyTableParams.bind(this)
    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
    this.handleTableSortedChange = this.handleTableSortedChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleCauseTypeChange = this.handleCauseTypeChange.bind(this)
    this.handleCauseKeyChange = this.handleCauseKeyChange.bind(this)
    this.handleAllHalts = this.handleAllHalts.bind(this)
    this.handleBulkyChange = this.handleBulkyChange.bind(this)
    this.handleOnlyUnjustified = this.handleOnlyUnjustified.bind(this)
    this.handleFromDateChange = this.handleFromDateChange.bind(this)
    this.handleToDateChange = this.handleToDateChange.bind(this)
    this.openDetailModal = this.openDetailModal.bind(this)
    this.closeDetailModal = this.closeDetailModal.bind(this)
  }

  public async componentDidMount() {
    this.mounted = true
    try {
      this.setState({ isLoadingConfiguration: true })

      const filters: PlantHaltFilters = this.getTableParams(this.state)
      await this.props.fetchDataMulti(
        this.props.isMultiPlant,
        this.props.plant,
        this.state.confirmed,
        this.abortController,
        filters
      )

      await this.props.fetchCounterUnjustified(this.props.isMultiPlant, this.props.plant)

      this.props
        .fetchConfiguration()
        .then(configuration => {
          const defaultUnitSelected =
            configuration?.units?.enable && configuration.units.values && configuration.units.values.length > 0
              ? configuration.units.values[0]
              : undefined

          if (this.mounted) {
            this.setState({ configuration }, () => {
              if (defaultUnitSelected !== undefined) {
                this.handleChangeUnit(defaultUnitSelected)
              }
            })
          }
        })
        .catch(() => undefined)
        .finally(() => {
          this.setState({ isLoadingConfiguration: false })
        })

      Promise.all([
        this.props.fetchAssets(this.props.isMultiPlant, this.props.plant).catch(() => []),
        this.props.fetchCauses().catch(() => []),
      ])
        .then(([assets, causes]) => {
          const causeTypes: PlantHaltCauseType[] = ((causes as PlantHaltCause[]).filter(
            (v: PlantHaltCause, i: number, a: PlantHaltCause[]) =>
              a.findIndex((t: PlantHaltCause) => t.type === v.type) === i
          ) as PlantHaltCause[]).map(value => ({
            type: value.type,
            name: value.name,
          }))

          if (this.mounted) {
            this.setState({
              assets,
              causes,
              causeTypes,
            })
          }
        })
        .finally(() => this.setState({ enableActions: true }))
    } catch (error) {
      console.warn('error', error) // tslint:disable-line
    }
  }

  public componentWillReceiveProps(props: Props) {
    if (props.plant && this.props.plant !== props.plant) {
      this.setState({ zoneSelected: undefined }, () => {
        const filters: PlantHaltFilters = this.getTableParams(this.state)
        this.props.fetchDataMulti(props.isMultiPlant, props.plant, this.state.confirmed, this.abortController, filters)
        this.props.fetchCounterUnjustified(props.isMultiPlant, props.plant)
      })

      Promise.all([this.props.fetchAssets(props.isMultiPlant, props.plant).catch(() => [])]).then(([assets]) => {
        this.setState({
          assets,
        })
      })
    }

    if (props.data && this.props.data !== props.data) {
      this.setState({
        data: this.filterData(props.data.halts, props.params && props.params.search),
      })
    }
  }

  public componentWillUnmount() {
    if (this.mounted) {
      this.abortController.abort()
    }
    this.mounted = false
  }

  private getAvailableZones = () => {
    if (this.state.configuration?.units?.enable) {
      return (
        this.state.configuration?.zones?.values
        ?.filter(zone => zone.unitId === this.state.unitSelected?.id)
          .map(zone => ({
            value: zone.id,
            label: zone.name,
          })) ?? []
      )
    }
    if (this.props.isMultiPlant && this.props?.plant?.plant) {
      const plant = this.props?.plant?.plant
      return (
        this.state.configuration?.zones?.values
        ?.filter(zone => zone.unitId === plant)
          .map(zone => ({
            value: zone.id,
            label: zone.name,
          })) ?? []
      )
    }

    return []
  }

  public render() {
    const { isFetching, t } = this.props
    const { data, assets, causes, causeTypes, detailData } = this.state
    const { page, pageSize } = this.state
    const title =
      this.props.match.path !== '/plant-halts/unchecked'
        ? t('plantHalts.navigation.plantHalts')
        : `${t('plantHalts.navigation.plantHalts')}: ${t('plantHalts.navigation.plantHaltsToCheck').toLowerCase()}`

    const enabledActions =
      this.props.config && this.props.config.enabledActions && isJSON(this.props.config.enabledActions)
        ? JSON.parse(this.props.config.enabledActions)
        : []

    const typeOfInplant = this.props.config && this.props.config.typeOfInplant ? this.props.config.typeOfInplant : 'v2'

    const isPagePlantHalts = this.props.match.path === '/plant-halts'

    const causesDetails =
      this.state.causeType && causes.filter(cause => cause.detail && `${cause.type}` === this.state.causeType)

    const availableZones = this.getAvailableZones()

    const filterElements: FilterComponent[] = [
      ...((isPagePlantHalts && [
        {
          name: '',
          value: this.state.from ? this.state.from.format('YYYY-MM-DD') : null,
          id: 'filter-from',
          type: 'Date' as filterType,
          label: this.props.t('plantHalts.list.filter.from'),
          onChange: (name: string, value: string | number | null) =>
            this.handleFromDateChange((value && new Date(value as string)) || null),
          max: (this.state.to && this.state.to.format('YYYY-MM-DD')) || moment().format('YYYY-MM-DD'),
        },
        {
          name: '',
          value: this.state.to ? this.state.to.format('YYYY-MM-DD') : null,
          id: 'filter-to',
          type: 'Date' as filterType,
          label: this.props.t('plantHalts.list.filter.to'),
          onChange: (name: string, value: string | number | null) =>
            this.handleToDateChange((value && new Date(value as string)) || null),
          min: (this.state.from && this.state.from.format('YYYY-MM-DD')) || undefined,
          max: moment().format('YYYY-MM-DD'),
        },
      ]) ||
        []),
      ...(availableZones.length > 0
        ? [
            {
              name: '',
              value: this.state.zoneSelected || '',
              id: 'filter-zone',
              label: this.props.t('plantHalts.list.filter.zone'),
              type: 'Select' as filterType,
              onChange: (name: string, value: string | number | null) => {
                this.handleZoneChange(value as string)
              },
              options: {
                items: availableZones,
                defaultOptionLabel: '--',
              },
            },
          ]
        : []),
      {
        name: '',
        value: this.state.causeType || '',
        id: 'filter-cause',
        type: 'Select' as filterType,
        label: this.props.t('plantHalts.list.filter.cause'),
        onChange: (name: string, value: string | number | null) =>
          this.handleCauseTypeChange((value && (value as string)) || ''),
        options: {
          items: causeTypes.map(cause => ({
            value: cause.type.toString(),
            label: cause.name,
          })),
          defaultOptionLabel: this.props.t('plantHalts.list.filter.allCauses'),
        },
      },
      ...((typeOfInplant === 'v2' && [
        {
          name: '',
          value: this.state.causeKey || '',
          id: 'filter-cause-details',
          type: 'Select' as filterType,
          label: this.props.t('plantHalts.list.filter.causeDetails'),
          disabled:
            !causesDetails ||
            causesDetails.length === 0 ||
            causesDetails.filter(value => !value.key.includes('/-1')).length === 0,
          onChange: (name: string, value: string | number | null) =>
            this.handleCauseKeyChange((value && (value as string)) || ''),
          options: {
            items:
              (causesDetails &&
                causesDetails
                  .filter(cause => cause.detail && `${cause.type}` === this.state.causeType)
                  .map(cause => ({
                    value: cause.key || '',
                    label: cause.detail || '',
                  }))) ||
              [],

            defaultOptionLabel:
              !causesDetails || causesDetails.length === 0
                ? t('plantHalts.list.filter.noCauses')
                : t('plantHalts.list.filter.allDetails'),
          },
        },
      ]) ||
        []),
      ...((isPagePlantHalts && [
        {
          name: '',
          value: this.state.searchString || '',
          id: '',
          type: 'SearchInput' as filterType,
          label: this.props.t('plantHalts.list.searchPlaceholder'), // label = placeholder ?
          onChange: (name: string, value: string | number | null) =>
            this.handleSearchChange((value && (value as string)) || ''),
          throttle: 500,
        },
      ]) ||
        []),
    ]

    if (this.state.isLoadingConfiguration) {
      return <Loader />
    }

    return (
      <div className="mv4iot-fe-plant-halts">
        <PageHeader
          title={title}
          rightButtons={
            isPagePlantHalts ? (
              <Button
                variant="secondary"
                onClick={() =>
                  this.props.exportData(this.getTableParams(this.state), this.props.isMultiPlant, this.props.plant)
                }
                label={t('plantHalts.list.exportData')}
              />
            ) : (
              undefined
            )
          }
        />
        <PlantHaltModal
          assets={assets}
          data={detailData as PlantHalt}
          onClose={this.closeDetailModal}
          show={this.state.showDetailModal && !!detailData}
          action={
            detailData && !detailData.confirmedBy && detailData.cause && detailData.cause.requiresValidation
              ? 'confirm'
              : 'update'
          }
          causes={causes || []}
          typeOfInplant={typeOfInplant}
        />

        <div className="content">
          <UnitSelector
            units={this.state.configuration?.units ?? { enable: false, values: [] }}
            handleChangeUnit={this.handleChangeUnit}
            unitSelected={this.state.unitSelected}
          />
          <Filters fields={filterElements} />
          <Row spacing={{ horizontal: false, vertical: false }}>
            <Column md={4}>
              {this.props.data ? (
                <>
                  {isPagePlantHalts && (
                    <div>
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {`${t('plantHalts.list.timePaused')} [${
                            this.props.data.numPaused ? this.props.data.numPaused : '-'
                          }]`}
                          :
                        </span>
                        <span>{duration(this.props.data.timePaused)}</span>
                      </div>
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {`${t('plantHalts.list.manMorningDelay')} [${
                            this.props.data.totalManMorningDelay ? this.props.data.totalManMorningDelay : '-'
                          }]`}
                          :
                        </span>
                        <span>{duration(this.props.data.timeManMorningDelay)}</span>
                      </div>
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {`${t('plantHalts.list.manPauseDelay')} [${
                            this.props.data.totalManPauseDelay ? this.props.data.totalManPauseDelay : '-'
                          }]`}
                          :
                        </span>
                        <span>{duration(this.props.data.timeManPauseDelay)}</span>
                      </div>
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {`${t('plantHalts.list.empties')} [${
                            this.props.data.numEmpty ? this.props.data.numEmpty : '-'
                          }]`}
                          :
                        </span>
                        <span>{duration(this.props.data.timeEmpty)}</span>
                      </div>
                    </div>
                  )}
                  <div className="plant-halts-info mb-3">
                    {isPagePlantHalts ? (
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {`${t('plantHalts.list.timeHalted')} [${
                            this.props.data.numHalted ? this.props.data.numHalted : '-'
                          }]`}
                          :
                        </span>
                        <span>{duration(this.props.data.timeHalted)}</span>
                      </div>
                    ) : (
                      <>
                        <div>
                          <span className="font-weight-bold text-muted mr-2">{t('plantHalts.list.totalHalts')}:</span>
                          <span>{this.props.data.numHalted ? this.props.data.numHalted : '-'}</span>
                        </div>
                        <div>
                          <span className="font-weight-bold text-muted mr-2">
                            {t('plantHalts.list.timeHaltTotal')}:
                          </span>
                          <span>{duration(this.props.data.timeHalted)}</span>
                        </div>
                      </>
                    )}
                    {isPagePlantHalts && (
                      <>
                        <div>
                          <span className="font-weight-bold text-muted mr-2">
                            {`${t('plantHalts.list.timeTotal')} [${
                              this.props.data.totalNum ? this.props.data.totalNum : '-'
                            }]`}
                            :
                          </span>
                          <span>{duration(this.props.data.totalTime)}</span>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </Column>
            <Column md={4}>
              {this.props.data && isPagePlantHalts && (
                <div>
                  {(!this.state.to ||
                    (this.state.to && this.state.to.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD'))) &&
                    !this.props.isMultiPlant && (
                      <div>
                        <span className="font-weight-bold text-muted mr-2">
                          {t('plantHalts.list.totalUnjustified')}:
                        </span>
                        <span className="is-unjustified">
                          {this.props.data.totalUnjustified ? this.props.data.totalUnjustified : '-'}
                        </span>
                      </div>
                    )}
                  {(!this.state.from ||
                    (this.state.from && this.state.from.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))) && (
                    <div>
                      <span className="font-weight-bold text-muted mr-2">
                        {t('plantHalts.list.totalUnjustifiedPast')}:
                      </span>
                      <span className="is-unjustified-past">
                        {this.props.data.totalUnjustifiedPast ? this.props.data.totalUnjustifiedPast : '-'}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Column>
          </Row>
          {isPagePlantHalts && (
            <Row spacing={{ horizontal: false, vertical: false }}>
              <div style={{ marginLeft: 15 }}>
                <label>Mostra: </label>
              </div>
              <div className="form-check ml-3">
                <input
                  className="form-check-input"
                  type="radio"
                  id="filter-view-allhalts"
                  onChange={this.handleAllHalts}
                  checked={this.state.bulky && !this.state.onlyUnjustified}
                />
                <label htmlFor="filter-view-allhalts" className="form-check-label">
                  {t('plantHalts.list.filter.allhalts')}
                </label>
              </div>
              <div className="form-check ml-3">
                <input
                  className="form-check-input"
                  type="radio"
                  id="filter-view-nojustified"
                  onChange={this.handleOnlyUnjustified}
                  checked={this.state.onlyUnjustified}
                />
                <label htmlFor="filter-view-nojustified" className="form-check-label">
                  {t('plantHalts.list.filter.notJustified')}
                </label>
              </div>
              <div className="form-check ml-3">
                <input
                  className="form-check-input"
                  type="radio"
                  id="filter-hide-bulky"
                  onChange={this.handleBulkyChange}
                  checked={!this.state.bulky}
                />
                <label htmlFor="filter-hide-bulky" className="form-check-label">
                  {t('plantHalts.list.filter.bulky')}
                </label>
              </div>
            </Row>
          )}
          <Table
            data={!isFetching && data ? data : undefined}
            pageSize={pageSize}
            pageText={t('plantHalts.list.page.label')}
            rowsText={t('plantHalts.list.page.rows')}
            ofText={t('plantHalts.list.page.of')}
            columns={[
              {
                Cell: ({ original }: RowInfo) => (
                  <>
                    <div className="font-weight-bold">{original.from.format('HH:mm DD/MM/YYYY')}</div>
                    <div>{original.to.format('HH:mm DD/MM/YYYY')}</div>
                  </>
                ),
                Header: t('plantHalts.detail.time'),
                accessor: 'from',
                maxWidth: 160,
              },
              {
                Header: t('plantHalts.detail.duration'),
                accessor: 'duration',
                maxWidth: 90,
              },
              {
                Cell: ({ original }: RowInfo) =>
                  original.cause ? (
                    <>
                      <Tooltip
                        trigger="click"
                        position="top-start"
                        arrow={true}
                        title={`${original.cause.key}/${original.code}`}
                      >
                        <div className="font-weight-bold">{original.cause.name}</div>
                        {typeOfInplant === 'v2' && original.cause.showDetailInTable && (
                          <div className="text-muted">{original.cause.detail}</div>
                        )}
                      </Tooltip>
                    </>
                  ) : null,
                Header: t('plantHalts.detail.cause'),
                accessor: 'cause.name',
                maxWidth: 350,
              },
              {
                Cell: ({ original }: RowInfo) => {
                  const assetData = (original.asset && original.asset.name) || original.assetFromCode

                  return assetData || ''
                },
                Header: t('plantHalts.detail.asset'),
                id: 'assetName',
                accessor: row =>
                  row && row.asset && row.asset.name
                    ? row.asset.name
                    : row && row.assetFromCode
                    ? row.assetFromCode
                    : '',
                //               show: isPagePlantHalts,
                width: 330,
              },
              {
                Cell: ({ original }: RowInfo) =>
                  original.notes ? (
                    original.notes.length > 200 ? (
                      <Tooltip trigger="mouseenter click" position="top" arrow={true} title={original.notes}>
                        {original.notes.substr(0, 200) + (original.notes.length > 200 ? '...' : '')}
                      </Tooltip>
                    ) : (
                      original.notes
                    )
                  ) : null,
                Header: t('plantHalts.detail.notes'),
                accessor: 'notes',
                className: 'pl-4',
                filterable: false,
                resizable: false,
                sortable: false,
              },
              {
                Cell: ({ original }: RowInfo) => {
                  const action =
                    enabledActions.includes('update') && !original.isUnjustified
                      ? 'update'
                      : enabledActions.includes('confirm')
                      ? 'confirm'
                      : false

                  const classToUse: string = original.causeKey.startsWith('-1/')
                    ? 'unSupportedCauseText'
                    : original.isUnjustifiedPast
                    ? 'isUnjustifiedPast'
                    : ''

                  return original.isHaltReadonly ? (
                    original.isUnjustifiedPast ? (
                      <p className={classToUse}>{t('plantHalts.list.notMotived')}</p>
                    ) : original.confirmedBy && original.confirmedBy.id !== 'system' ? (
                      <p className={classToUse}>{original.confirmedBy.name}</p>
                    ) : null
                  ) : (
                    !this.props.isMultiPlant && action && (
                      <Button
                        variant={action === 'confirm' ? 'warning' : 'secondary'}
                        label={t('plantHalts.list.action.' + action)}
                        onClick={this.openDetailModal(original, action)}
                        isLoading={!this.state.enableActions}
                      />
                    )
                  )
                },
                Header: '',
                filterable: false,
                resizable: false,
                sortable: false,
                width: 120,
              },
            ]}
            className="-striped -highlight"
            isFetching={this.props.isFetching}
            manualIsFetching={true}
            minRows={data.length ? 1 : 4}
            noDataText={t('plantHalts.list.noData')}
            onPageChange={this.handleTablePageChange}
            onPageSizeChange={this.handleTablePageSizeChange}
            page={page - 1}
            getTrProps={(rowState: any, rowInfo: any) => {
              const haltUnjustified =
                rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original.isUnjustified
                  ? 'halt-is-unjustified'
                  : ''
              const haltUnjustifiedPast =
                rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original.isUnjustifiedPast
                  ? 'halt-is-unjustified-past'
                  : ''
              const unSupportedCause =
                rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original.causeKey.startsWith('-1/')
                  ? 'unSupportedCause'
                  : ''
              return {
                className: `${haltUnjustified} ${haltUnjustifiedPast} ${
                  unSupportedCause && haltUnjustified ? 'unSupportedCauseUnJustified' : unSupportedCause
                }`,
              }
            }}
          />
        </div>
      </div>
    )
  }

  private async applyTableParams(state: OwnState) {
    const filters: PlantHaltFilters = this.getTableParams(state)
    this.props.applyParams!(filters)
    await this.props.fetchDataMulti(
      this.props.isMultiPlant,
      this.props.plant,
      this.state.confirmed,
      this.abortController,
      filters
    )
  }

  private getTableParams(state: OwnState): PlantHaltFilters {
    return [
      'causeKey',
      'causeType',
      'from',
      'to',
      'confirmed',
      'bulky',
      'code',
      'onlyUnjustified',
      'searchString',
      'plant',
      'zone',
    ].reduce((acc, key) => {
      const value = state[key]
      if (key === 'plant' && state.unitSelected) {
        return { ...acc, plant: state.unitSelected.id }
      }
      if (key === 'zone' && state.zoneSelected) {
        return { ...acc, zone: state.zoneSelected }
      }
      if (key === 'confirmed' || key === 'bulky') {
        return value === false ? { ...acc, [key]: value } : acc
      } else if (value && key === 'onlyUnjustified') {
        return { ...acc, [key]: value }
      } else if (!value) {
        return acc
      } else if (key === 'from' || key === 'to') {
        return { ...acc, [key]: value.format('YYYY-MM-DD') }
      } else if (key === 'causeType') {
        return { ...acc, type: value }
      } else if (key === 'code') {
        return value === 999 ? { ...acc, [key]: value } : acc
      } else {
        return { ...acc, [key]: value }
      }
    }, {})
  }

  private filterData(data: PlantHalt[] | null, search: string | undefined): PlantHalt[] {
    if (!data) {
      return []
    } else if (!search) {
      return data
    } else {
      const q = search.toLowerCase()
      return data.filter(
        (plantHalt: PlantHalt) =>
          // ['source'].reduce(
          //   (check: boolean, key: string) =>
          //     check || !!(plantHalt[key] && plantHalt[key].toLowerCase().match(q)),
          //   false
          // )
          !!(
            (plantHalt.asset && plantHalt.asset.name && plantHalt.asset.name.toLowerCase().match(q)) ||
            (plantHalt.assetFromCode && plantHalt.assetFromCode.toLowerCase().match(q))
          )
      )
    }
  }

  private handleTablePageChange(page: number) {
    this.setState({ page: page + 1 })
  }

  private handleTablePageSizeChange(pageSize: number) {
    this.setState({ page: 1, pageSize })
  }

  private handleTableSortedChange(sorted: SortingRule[]) {
    this.setState({ sorted })
  }

  private handleSearchChange(searchString: string) {
    this.setState(
      currentState => {
        return { ...currentState, page: 1, searchString }
      },
      () => this.applyTableParams(this.state)
    )
  }

  private handleCauseTypeChange(causeType: string) {
    this.setState(currentState => {
      const state = { ...currentState, causeType, causeKey: null }
      this.applyTableParams(state)
      return state
    })
  }

  private handleZoneChange = (value: string | undefined) => {
    this.setState(currentState => {
      const state: OwnState = { ...currentState, zoneSelected: value }
      this.applyTableParams(state)
      return state
    })
  }

  private handleCauseKeyChange(causeKey: string) {
    this.setState(currentState => {
      const state = { ...currentState, causeKey }
      this.applyTableParams(state)
      return state
    })
  }

  private handleAllHalts(qevent: React.ChangeEvent<HTMLInputElement>) {
    const bulky: boolean = true
    const onlyUnjustified: boolean = false
    this.setState(currentState => {
      const state = { ...currentState, page: 1, bulky, onlyUnjustified }
      this.applyTableParams(state)
      return state
    })
  }

  private handleOnlyUnjustified(event: React.ChangeEvent<HTMLInputElement>) {
    const bulky: boolean = true
    const onlyUnjustified: boolean = true
    this.setState(currentState => {
      const state = { ...currentState, page: 1, bulky, onlyUnjustified }
      this.applyTableParams(state)
      return state
    })
  }

  private handleBulkyChange(event: React.ChangeEvent<HTMLInputElement>) {
    const bulky: boolean = false
    const onlyUnjustified: boolean = false
    this.setState(currentState => {
      const state = { ...currentState, page: 1, bulky, onlyUnjustified }
      this.applyTableParams(state)
      return state
    })
  }

  private handleFromDateChange(date: Date | null) {
    this.setState(currentState => {
      const state = { ...currentState, page: 1, from: date ? moment(date) : null }
      this.applyTableParams(state)
      return state
    })
  }

  private handleToDateChange(date: Date | null) {
    this.setState(currentState => {
      const state = { ...currentState, page: 1, to: date ? moment(date) : null }
      this.applyTableParams(state)
      return state
    })
  }

  private handleChangeUnit = (value: UnitValue | undefined) => {
    this.setState(currentState => {
      const state: OwnState = { ...currentState, unitSelected: value, zoneSelected: undefined }
      this.applyTableParams(state)
      return state
    })
  }

  // private handleDatepickerOnInput(key: string) {
  //   return (event: React.FocusEvent<HTMLInputElement>) => {
  //     const value = event.currentTarget.value
  //
  //     if (this.state.debounce) clearTimeout(this.state.debounce)
  //     const debounce = setTimeout(() => {
  //       const match = value.match(
  //         /^([0-3]?[0-9])[\/|-]([0-1]?[0-9])[\/|-]([0-9]{4})$/
  //       )
  //       if (match) {
  //         const date = moment()
  //           .set('year', parseInt(match[3], 10))
  //           .set('month', parseInt(match[2], 10) - 1)
  //           .set('date', parseInt(match[1], 10))
  //         // this.setState({
  //         //   [key]: date.format('YYYY-MM-DD')
  //         // })
  //       }
  //     }, 300)
  //     this.setState({
  //       debounce,
  //       [key]: value
  //     })
  //   }
  // }

  private openDetailModal(data: PlantHalt, action: string) {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      this.setState({
        showDetailModal: true,
        detailData: data,
        // detailsModalAction: action
      })
    }
  }

  private closeDetailModal() {
    this.setState({
      showDetailModal: false,
      detailData: null,
      // detailsModalAction: null
    })
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlantHaltListPageView))
)
