import React, {Component} from 'react'
import {Input} from '@mv-submodules/inplant-components-fe'
import {withTranslation, WithTranslation} from 'react-i18next'
import {API} from '@mv-submodules/inplant-titech-fe-iblu/redux/actions'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import {logoutUser} from '@mv-submodules/inplant-coreadapter-fe/auth'
import {popupNotification} from '@mv-submodules/inplant-coreadapter-fe/functions/notification'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import {FormModal} from "@mv-submodules/inplant-components-fe/ui/components/Modal/Modal";

interface OwnState {
  isValid: boolean,
  fields: { [k: string]: string | number }
  validateError: boolean
  validateLoading: boolean
  submitError: boolean
  submitLoading: boolean
  errors: { [k: string]: string[] }
}

interface OwnProps {
  titechId: string | null
  onClose: () => void
}

const defaultStatus = {
  isValid: false,
  fields: {},
  validateError: false,
  validateLoading: false,
  errors: {},
  submitError: false,
  submitLoading: false
}

type Props = OwnProps & WithTranslation;

class TitechSetupModal extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);

    this.state = defaultStatus

    this.handleCheckConnection = this.handleCheckConnection.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.checkConnection = this.checkConnection.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    const {titechId} = this.props
    if (titechId && titechId !== prevProps.titechId) {
      API().request(`/titech/setup/${titechId}`)
        .then((fields: { [k: string]: string | number }) => {
          fields.id = String(this.props.titechId)
          this.setState({
            fields
          })
        })
    }
  }

  public render() {
    const {isValid} = this.state
    const {titechId, t} = this.props

    return (
      <FormModal
        visible={!!titechId}
        onClose={this.handleClose}
        title={t('titech.setup.modal.title')}
        width={50}
        closeLabel={t('titech.setup.modal.close')}
        additionalFooterButtons={[
          {
            label: t('titech.setup.modal.checkConnection'),
            type: 'button',
            variant: 'primary',
            onClick: this.handleCheckConnection,
            disabled: this.state.validateLoading || this.state.submitLoading,
            isLoading: this.state.validateLoading
          }
        ]}
        onSubmit={this.handleSave}
        submitButton={{
          label: t('titech.setup.modal.save'),
          disabled: !isValid || this.state.validateLoading || this.state.submitLoading,
          isLoading: this.state.submitLoading
        }}
      >
        <Row formRow={true}>
          <Column md={3} formGroup={true}>
            <Input
              type="text"
              id="inputAssetCode"
              value={this.state.fields && ("assetCode" in this.state.fields && this.state.fields.assetCode) ? this.state.fields.assetCode : undefined}
              readOnly={true}
              label={t('titech.setup.assetCode')}
              error={this.state.errors && this.state.errors.assetCode && this.state.errors.assetCode.join('<br/>')}
            />
          </Column>
          <Column md={4} formGroup={true}>
            <Input
              type="text"
              id="inputModel"
              value={this.state.fields && ("model" in this.state.fields && this.state.fields.model) ? this.state.fields.model : undefined}
              readOnly={true}
              label={t('titech.setup.model')}
              error={this.state.errors && this.state.errors.model && this.state.errors.model.join('<br/>')}
            />
          </Column>
        </Row>
        <Row formRow={true}>
          <Column formGroup={true} md={5}>
            <Input
              type="text"
              id="inputIpAddress"
              value={this.state.fields && ("ipAddress" in this.state.fields && this.state.fields.ipAddress) ? this.state.fields.ipAddress : undefined}
              required={true}
              label={t('titech.setup.ipAddress')}
              onChange={value => this.handleInputChange(value, 'ipAddress')}
              error={this.state.errors && this.state.errors.ipAddress && this.state.errors.ipAddress.join('<br/>')}
            />
          </Column>
        </Row>
        <Row formRow={true}>
          <Column md={4}>
            <Input
              type="text"
              id="inputUsername"
              value={this.state.fields && ("username" in this.state.fields && this.state.fields.username) ? this.state.fields.username : undefined}
              required={true}
              label={t('titech.setup.username')}
              onChange={value => this.handleInputChange(value, 'username')}
              error={this.state.errors && this.state.errors.username && this.state.errors.username.join('<br/>')}
            />
          </Column>
          <Column formGroup={true} md={4}>
            <Input
              type="text"
              id="inputPassword"
              value={this.state.fields && ("password" in this.state.fields && this.state.fields.password) ? this.state.fields.password : undefined}
              required={true}
              label={t('titech.setup.password')}
              onChange={value => this.handleInputChange(value, 'password')}
              error={this.state.errors && this.state.errors.password && this.state.errors.password.join('<br/>')}
            />
          </Column>
        </Row>
        <Row formRow={true}>
          <Column md={4} formGroup={true}>
            <Input
              type="text"
              id="inputLogFileFolder"
              value={this.state.fields && ("logFileFolder" in this.state.fields && this.state.fields.logFileFolder) ? this.state.fields.logFileFolder : undefined}
              required={true}
              label={t('titech.setup.logFileFolder')}
              onChange={value => this.handleInputChange(value, 'logFileFolder')}
              error={this.state.errors && this.state.errors.logFileFolder && this.state.errors.logFileFolder.join('<br/>')}
            />
          </Column>
          <Column md={4} formGroup={true}>
            <Input
              type="text"
              id="inputRecipeFileName"
              value={this.state.fields && ("recipeFileName" in this.state.fields && this.state.fields.recipeFileName) ? this.state.fields.recipeFileName : undefined}
              required={true}
              label={t('titech.setup.recipeFileName')}
              onChange={value => this.handleInputChange(value, 'recipeFileName')}
              error={this.state.errors && this.state.errors.recipeFileName && this.state.errors.recipeFileName.join('<br/>')}
            />
          </Column>
        </Row>
        <Row formRow={true}>
          <Column md={4} formGroup={true}>
            <Input
              type="text"
              id="inputCalibrationFrequency"
              value={this.state.fields && ("calibrationFrequency" in this.state.fields && this.state.fields.calibrationFrequency) ? this.state.fields.calibrationFrequency : undefined}
              required={true}
              label={t('titech.setup.calibrationFrequency')}
              onChange={value => this.handleInputChange(value, 'calibrationFrequency')}
              error={this.state.errors && this.state.errors.calibrationFrequency && this.state.errors.calibrationFrequency.join('<br/>')}
            />
          </Column>
          <Column md={4} formGroup={true}>
            <Input
              type="text"
              id="inputBeltId"
              value={this.state.fields && ("beltId" in this.state.fields && this.state.fields.beltId) ? this.state.fields.beltId : undefined}
              label={t('titech.setup.beltId')}
              onChange={value => this.handleInputChange(value, 'beltId')}
              error={this.state.errors && this.state.errors.beltId && this.state.errors.beltId.join('<br/>')}
            />
          </Column>
        </Row>
      </FormModal>
    );
  }

  private handleCheckConnection() {
    if (this.state.fields) {
      this.checkConnection()
    }
  }

  private handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({submitError: false, submitLoading: true, errors: {}})

    API().request(
      `/titech/setup`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          ...this.state.fields,
          ...{calibrationFrequency: this.state.fields.calibrationFrequency && parseInt(String(this.state.fields.calibrationFrequency), 10) || null}
        })
      }
    )
      .then((fields: Array<{ [k: string]: string | number }>) => {
        popupNotification({
          type: 'success',
          content: this.props.t('titech.setup.notifications.saved')
        })
        this.setState({submitError: false, submitLoading: false, isValid: true},
          () => this.handleClose()
        )
      })
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        popupNotification({
          type: 'error',
          content: this.props.t('titech.setup.notifications.notSaved')
        })
        this.setState({submitError: true, submitLoading: false, errors: error.errors})
      })
  }

  private handleInputChange(value: string | number, name: string) {
    const fields = Object.assign({}, this.state.fields)
    fields[name] = value

    this.setState({
      fields
    })
  }

  private checkConnection() {
    this.setState({validateError: false, validateLoading: true, errors: {}, isValid: false})
    API().request(
      `/titech/validate`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(this.state.fields)
      }
    )
      .then((fields: Array<{ [k: string]: string | number }>) => {
        this.setState({validateError: false, validateLoading: false, isValid: true})
        popupNotification({
          type: 'success',
          content: this.props.t('titech.setup.notifications.valid')

        })
      })
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }

        popupNotification({
          type: 'warning',
          content: this.props.t('titech.setup.notifications.invalid')
        })
        this.setState({validateError: true, validateLoading: false, errors: error.errors, isValid: false})
      })
  }

  private handleClose() {
    this.setState(defaultStatus, () => {
      this.props.onClose()
    })
  }
}

export default withTranslation()(TitechSetupModal)
