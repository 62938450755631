import { TYPES, useInjection } from './binding'
import { StateManagerServiceError } from './error'

export type IDispatch<T = any> = (object: T) => any
type IUseDispatch<T = any> = () => IDispatch<T>

export type IGetState<State = any, Selected = any> = (state: State) => Selected
type IUseGetState<State = any, Selected = any> = (selector: IGetState<State, Selected>) => Selected

const message =
  'useComponentDispatch() required a state manager service, init it in the application root tree using ContainerProvider'

export function useComponentDispatch<State>(): IDispatch {
  const identifier = TYPES.StateManagerService
  try {
    return useInjection<StateManagerService<State>>(identifier).componentDispatch()
  } catch (error) {
    throw new StateManagerServiceError(message)
  }
}

export function useGetState<State, R = any>(selector: IGetState): R {
  const identifier = TYPES.StateManagerService

  try {
    return useInjection<StateManagerService<State>>(identifier).getState(selector)
  } catch (error) {
    throw new StateManagerServiceError(message)
  }
}

interface IStateManagerService<State, Actions = any> {
  getState: IUseGetState<State>
  componentDispatch: IUseDispatch<Actions>
}

export class StateManagerService<State, Actions = any> implements IStateManagerService<State> {
  private _getState: IUseGetState<State>
  public get getState(): IUseGetState<State> {
    return this._getState
  }

  private _componentDispatch: IUseDispatch<Actions>
  public get componentDispatch(): IUseDispatch<Actions> {
    return this._componentDispatch
  }

  constructor(getState: IUseGetState<State>, componentDispatch: IUseDispatch<Actions>) {
    this._getState = getState
    this._componentDispatch = componentDispatch
  }
}
