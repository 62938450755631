// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

export interface BreadcrumbsItemProps {
  href?: string,
  label: string,
  clickCallback?: ( e: React.MouseEvent<HTMLSpanElement> ) => void,
  data?: {
    id: string
  }
}

export interface BreadcrumbsProps {
  path: BreadcrumbsItemProps[]
}

class Breadcrumbs extends React.Component<BreadcrumbsProps, any> {
  public render() {
    const breadcrumbs: React.ReactNode[] = []
    const currentPath: string[] = []

    this.props.path.forEach(( element: any, i: number ) => {
      currentPath.push(element.data && element.data.id ? element.data.id : null)
      breadcrumbs.push(
        element.href ?
        <a
          className={'level-' + i}
          data-depth={i}
          data-path={currentPath.join('.')}
          key={i}
          href={element.href}
          onClick={e => element.clickCallback && element.clickCallback(e) || null}
        >
          {element.label}
        </a> :
        <span
          className={'level-' + i}
          data-depth={i}
          data-path={currentPath.join('.')}
          key={i}
          onClick={e => element.clickCallback && element.clickCallback(e) || null}
        >
          {element.label}
        </span>
      )
    })

    return (
      <div className="breadcrumbs" id="breadcrumbs">
        {breadcrumbs}
      </div>
    )
  }
}

export default Breadcrumbs
