import { AnyAction } from 'redux'

export interface Configurations {
  [k: string]: Configuration
}

export interface Configuration {
  data: null | Configurations
  error: boolean
  savingError: boolean
  fetching: boolean
  saving: boolean
}

const configurationInitialState: Configuration = {
  data: null,
  error: false,
  savingError: false,
  fetching: false,
  saving: false,
}

export const configurations = (state = configurationInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'PLANTANALYSIS_CONFIGURATION_IS_SAVING':
      return {
        ...state,
        saving: action.payload,
      }
    case 'PLANTANALYSIS_CONFIGURATION_SAVE_SUCCESS':
      return {
        ...state,
        data: action.payload,
        saving: false,
        savingError: false,
      }
    case 'PLANTANALYSIS_CONFIGURATION_SAVE_ERROR':
      return {
        ...state,
        saving: false,
        savingError: true,
      }

    default:
      return state
  }
}

export default configurations
