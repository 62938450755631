// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

export interface RadioOptions {
    defaultOption?: string,
    disableDefaultOption?: boolean,
    items: Array<{
      label: string,
      value: string,
      id: string
    }>
  }

interface Props {
    disabled?: boolean
    id?: string
    labelPosition?: "TOP" | "BOTTOM" | "LEFT" | "RIGHT"
    name?: string
    isInForm?: boolean
    onChange: (value: string) => void
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    options: RadioOptions
    readOnly?: boolean
    value: string
}

class Radio extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  public render() {
    const props = this.props
    return (
        <div className={`row ${props.isInForm && 'mb-3' || ''}`}>
            {props.options.items.map(option => (
            <div className={"form-radio form-radio-inline form-radio-" + ((props.labelPosition === "BOTTOM" || props.labelPosition === "TOP") && "column" || "") }>
                {
                    (props.labelPosition && (props.labelPosition === "TOP" || props.labelPosition === "LEFT")) && (
                        <label className={"form-radio-label form-radio-label-" + (props.labelPosition === "TOP" && "top" || props.labelPosition === "LEFT" && "left" || "")} htmlFor={option.id}>
                            {option.label}
                        </label>
                    )
                }
                <input
                    type="radio"
                    name= {props.name}
                    id={option.id}
                    value={option.value}
                    checked={props.value === option.value}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    onChange={this.handleInputChange}
                    onClick={this.handleClick}
                />
                {
                    (!props.labelPosition || (props.labelPosition === "BOTTOM" || props.labelPosition === "RIGHT")) && (
                        <label className={"form-radio-label form-radio-label-" + (props.labelPosition === "BOTTOM" && "bottom" || props.labelPosition === "RIGHT" && "right" || "") } htmlFor={option.id}>
                            {option.label}
                        </label>
                    )
                }

            </div>
          ))}
        </div>
    )
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if(this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }

  private handleClick(event: React.MouseEvent<HTMLInputElement>) {
    if(this.props.onClick) {
      this.props.onClick(event)
    }
  }
}

export default Radio
