// * -------------------------------- NPM --------------------------------------
import _uniqueId from 'lodash/uniqueId'

export const numberOfWords = (value: string): number => {
  if (value !== undefined && value !== '' && value !== null) {
    return value.split(' ').filter(f => f !== '').length
  } else {
    return 0
  }
}

export const getUniqueId = _uniqueId

