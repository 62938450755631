const iconsLibrary: Record<string, JSX.Element> = {}
export const addVidimIcon = (name: string, element: JSX.Element) => {
  iconsLibrary[name] = element
}

interface Props {
  iconName: string
}

const VidimIcon = (props: Props) => {
  return iconsLibrary[props.iconName] ?? null
}

export default VidimIcon
