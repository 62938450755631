// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

interface Props {
  id?: string
  label: string
  name?: string
  value?: string
  disabled?: boolean
  lang?: string
  readOnly?: boolean
  multiple?: boolean
  required?: boolean
  onChange: (files: FileList) => void
}

class FileInput extends React.Component<Props> {
  // private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    // this.inputRef = React.createRef()
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  public render() {
    const props = this.props
    return (
      <>
        <input
          id={props.id}
          // ref={this.inputRef}
          type="file"
          lang={props.lang}
          name={props.name}
          className="custom-file-input"
          value={props.value}
          disabled={props.disabled}
          multiple={props.multiple}
          onChange={this.handleInputChange}
        />
        <label className="custom-file-label">{props.label}</label>
      </>
    )
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist()
    this.props.onChange(event.target.files || new FileList())
  }
}

export default FileInput
