// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Column from './Column'
import { Margin, renderMargin } from '../Utils/utils'

/**
 * info alignment: https://getbootstrap.com/docs/4.0/layout/grid/#alignment
 */
interface RowProps {
  horizontalAlignment?: 'start' | 'center' | 'end' | 'around' | 'between'
  verticalAlignment?: 'start' | 'center' | 'end' | 'flex-end'
  children: Array<React.ReactElement<Column> | null | false> | React.ReactElement<Column | null | false> | null | false
  formRow?: boolean
  spacing?: Margin
  noGutters?: boolean
  flex?: boolean
}

const Row = (props: RowProps) => {
  const renderFormRow = (): string => {
    if (props.formRow) {
      return 'form-'
    }
    if (props.flex) {
      return 'd-flex flex-'
    }
    return ''
  }

  return (
    <div
      className={`${renderFormRow()}row ${
        props.horizontalAlignment ? `justify-content-${props.horizontalAlignment}` : ''
      } ${props.verticalAlignment ? `align-items-${props.verticalAlignment}` : ''} ${renderMargin(props.spacing)} ${
        (props.noGutters && 'no-gutters') || ''
      } mv-row`}
    >
      {props.children}
    </div>
  )
}

export default Row
