import * as React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {updateData} from '../../../../redux/actions/plantHalts'
import {PlantAsset, PlantHalt, PlantHaltCause} from '../../../../types/plantHalts'
import {APIError} from '../../../../types/api'
import FrequentNotesBox, {FrequentNoteItem} from '../FrequentNotesBox/FrequentNotesBox'
import {isJSON} from '../../../../functions/shared'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { FormModal } from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'

interface OwnProps {
  assets: PlantAsset[]
  causes: PlantHaltCause[]
  // causes: PlantHaltCause[]
  data: PlantHalt
  onClose: () => void
  show: boolean
  action: string
  typeOfInplant?: string | null
}

interface OwnState {
  data: PlantHalt
  haltType: null | string
  isSubmitting: boolean
  causeTypes: Array<{ type: string; name: string; isTypeReadOnly: boolean }>
  errors: null | {
    name: string
    status: number
    errors: {
      [k: string]: string[]
    };
  };
}

interface DispatchProps {
  updateData: (data: PlantHalt) => Promise<PlantHalt>
}

interface StateProps {
  submitErrors: null | APIError
  config: {
    enabledActions?: string;
    typeOfInplant?: string;
    frequentsNotes?: string;
  } | null
}

const mapStateToProps = (store: any): StateProps => {
  return {
    submitErrors: store.plantHalts.plantHalts.submitErrors,
    config: store.config.plantHalts || null,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateData: (data: PlantHalt) => updateData(data)(dispatch),
})

type Props = OwnProps & DispatchProps & StateProps & WithTranslation

class PlantHaltModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: props.data,
      haltType:
        props.data && props.data.causeKey
          ? props.data.causeKey.split('/')[0]
          : '',
      causeTypes: Object.values(
        (this.props.causes as PlantHaltCause[]).reduce(
          (acc, {type, name}: PlantHaltCause) => ({
            ...acc,
            [type]: {type, name},
          }),
          {},
        ),
      ),
      isSubmitting: false,
      errors: null,
    }

    this.handleCauseTypeChange = this.handleCauseTypeChange.bind(this)
    this.handleCauseDetailChange = this.handleCauseDetailChange.bind(this)
    this.handleAssetChange = this.handleAssetChange.bind(this)
    this.handleNotesChange = this.handleNotesChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  public componentWillReceiveProps(props: Props) {
    if (props.data && this.props.data !== props.data) {
      this.setState({
        data: props.data,
        errors: null,
        haltType:
          props.data && props.data.causeKey
            ? props.data.causeKey.split('/')[0]
            : '',
        causeTypes: Object.values(
          (this.props.causes as PlantHaltCause[]).reduce(
            (acc, {type, name, isTypeReadOnly}: PlantHaltCause) => ({
              ...acc,
              [type]: {type, name, isTypeReadOnly},
            }),
            {},
          ),
        ),
      })
    }
  }

  public render() {
    const {
      t,
      assets,
      onClose,
      show,
      action,
      causes,
      // submitErrors,
      config,
    } = this.props
    const {data, haltType, errors} = this.state
    const requiresAsset =
      (data && data.cause && data.cause.requiresAsset) || false
    const requiresNote: boolean = data && data.requiresNote

    const causeDetails =
      (this.state.haltType &&
        causes
          .filter(
            cause =>
              cause.detail && `${cause.type}` === String(this.state.haltType),
          )
          .map(cause => (
            <option key={cause.key} value={cause.key}>
              {cause.detail}
            </option>
          ))) ||
      []

    const getFieldErrors = (field: string) =>
      errors && errors.errors && errors.errors[field]

    const frequentNotes: Array<string | FrequentNoteItem> | false =
      config &&
      config.frequentsNotes &&
      isJSON(config.frequentsNotes) &&
      JSON.parse(config.frequentsNotes)


    return show ? (
      <FormModal
        title={
          t('plantHalts.detail.action.' + action) +
          ' ' +
          t('plantHalts.detail.title')
        }
        onClose={onClose}
        visible={show}
        width={50}
        onSubmit={this.handleSubmit}
        closeLabel={t('plantHalts.modal.cancel')}
        submitButton={{label: t('plantHalts.modal.save'),variant:"primary",isLoading: this.state.isSubmitting}}
      >
          <div className="plant-halt-modal-info">
            <Row spacing={{horizontal:false}}>
              <Column xs={6}>
              <span className="font-weight-bold text-muted mr-2">
                {t('plantHalts.detail.from')}:
              </span>
                <span>{data.from.format('HH:mm DD/MM/YYYY')}</span>
              </Column>
              <Column xs={6}>
              <span className="font-weight-bold text-muted mr-2">
                {t('plantHalts.detail.to')}:
              </span>
                <span>{data.to.format('HH:mm DD/MM/YYYY')}</span>
              </Column>
              <Column xs={6}>
              <span className="font-weight-bold text-muted mr-2">
                {t('plantHalts.detail.duration')}:
              </span>
                <span>{data.duration}</span>
              </Column>
            </Row>
          </div>
          <div className="plant-halt-modal-fields">
            <div className="form-group">
              <label htmlFor="form-cause">
                {t('plantHalts.detail.cause')}
                {!(
                  this.props.data.cause && this.props.data.cause.isTypeReadOnly
                ) ? (
                  <span className="text-danger ml-1"> *</span>
                ) : (
                  ':'
                )}
              </label>
              {!(
                this.props.data.cause && this.props.data.cause.isTypeReadOnly
              ) ? (
                <>
                  <select
                    className={
                      'form-control ' +
                      (getFieldErrors('type') ? 'is-invalid' : '')
                    }
                    id="form-cause"
                    value={haltType || undefined}
                    onChange={this.handleCauseTypeChange}
                    required={true}
                  >
                    {this.state.causeTypes.filter(
                      cause => `${cause.type}` === '-1',
                    ).length === 0 && (
                      <option key={'null'} value="">
                        ---
                      </option>
                    )}
                    {this.state.causeTypes.map(
                      ({type, name, isTypeReadOnly}) =>
                        !isTypeReadOnly && (
                          <option key={type} value={type}>
                            {name}
                          </option>
                        ),
                    )}
                  </select>
                  <div className="invalid-feedback ml-2">
                    {getFieldErrors('type')}
                  </div>
                </>
              ) : (
                <span>{data.cause ? ` ${data.cause.name}` : ''}</span>
              )}
            </div>

            {this.props.typeOfInplant &&
            this.props.typeOfInplant === 'v2' &&
            (causeDetails &&
            causeDetails.length > 0 &&
            (data.cause &&
              (this.props.data.cause &&
                !this.props.data.cause.isDetailReadOnly)) ? (
              <div className="form-group">
                <label htmlFor="form-cause-details">
                  {t('plantHalts.detail.causeDetails')}
                  <span className="text-danger ml-1"> *</span>
                </label>
                <select
                  className={
                    'form-control ' +
                    (getFieldErrors('detail') ? 'is-invalid' : '')
                  }
                  id="form-cause-details"
                  value={data.causeKey || undefined}
                  onChange={this.handleCauseDetailChange}
                  required={true}
                >
                  <option key={'null'} value="">
                    ---
                  </option>
                  {causeDetails}
                </select>
                <div className="invalid-feedback ml-2">
                  {getFieldErrors('detail')}
                </div>
              </div>
            ) : (
              data.cause &&
              data.cause.detail && (
                <div className="form-group">
                  <label htmlFor="form-cause-details">
                    {t('plantHalts.detail.causeDetails')}:
                  </label>
                  <span> {data.cause.detail}</span>
                </div>
              )
            ))}

            {data &&
            data.cause &&
            data.cause.isAssetReadOnly &&
            data.asset === null ? null : (
              <div className="form-group">
                <label htmlFor="form-asset">
                  {t('plantHalts.detail.asset')}
                  {requiresAsset &&
                  (!(data.cause && data.cause.isAssetReadOnly) ? (
                    <span className="text-danger ml-1"> *</span>
                  ) : (
                    ':'
                  ))}
                </label>
                {!(data.cause && data.cause.isAssetReadOnly) ? (
                  <>
                    <select
                      onChange={this.handleAssetChange}
                      className={
                        'form-control ' +
                        (getFieldErrors('asset') ? 'is-invalid' : '')
                      }
                      id="form-asset"
                      value={data.asset ? data.asset.id : ''}
                      required={requiresAsset}
                      disabled={
                        (data.cause && data.cause.isAssetReadOnly) || false
                      }
                    >
                      {!data.asset && (
                        <option disabled={true} value="">
                          ---
                        </option>
                      )}
                      {data.asset && <option value="">---</option>}

                      {assets.map(({id, name}) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback ml-2">
                      {getFieldErrors('asset')}
                    </div>
                  </>
                ) : (
                  <span>
                    {data.asset && data.cause ? ` ${data.asset.name}` : ''}
                  </span>
                )}
              </div>
            )}

            {frequentNotes &&
            frequentNotes.length > 0 && (
              <FrequentNotesBox
                suggestedNotes={frequentNotes}
                disabled={frequentNotes.includes(
                  (data.notes &&
                    data.notes.replace(/(^[\s]+|[\s]+$)/g, '')) ||
                  '',
                )}
                setNote={(note: string) =>
                  this.handleNotesChange(undefined, note)
                }
              />
            )}

            <div className="form-group">
              <label htmlFor="form-notes">
                {t('plantHalts.detail.notes')}
                {requiresNote && <span className="text-danger ml-1">*</span>}
              </label>
              <textarea
                className={
                  'form-control ' +
                  (getFieldErrors('notes') ? 'is-invalid' : '')
                }
                id="form-notes"
                value={data.notes || ''}
                onChange={this.handleNotesChange}
                rows={5}
                required={requiresNote}
              />
              <div className="invalid-feedback ml-2">
                {getFieldErrors('notes')}
              </div>
            </div>
          </div>
      </FormModal>
    ) : null
  }

  private handleCauseTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const haltType = event.currentTarget.value
    const filteredCauses = this.props.causes.filter(
      c => String(c.type) === haltType,
    )
    if (haltType && filteredCauses) {
      // single cause, set also details
      this.setState(currentState => ({
        ...currentState,
        data: {
          ...currentState.data,
          cause: filteredCauses[0],
          causeKey: filteredCauses[0].key,
          type: filteredCauses[0].type,
          asset: null,
        },
        haltType,
      }))
    } else {
      // we need details to select cause, use state
      this.setState({
        haltType,
      })
    }
  }

  private handleCauseDetailChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const filteredCauses = this.props.causes.filter(
      c => String(c.key) === event.currentTarget.value,
    )
    if (event.currentTarget.value && filteredCauses) {
      this.setState(currentState => ({
        ...currentState,
        data: {
          ...currentState.data,
          cause: filteredCauses[0],
          causeKey: filteredCauses[0].key,
          type: filteredCauses[0].type,
        },
      }))
    }
  }

  private handleAssetChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const asset = this.props.assets.find(
      (item: PlantAsset) => event.currentTarget.value === item.id,
    )!
    this.setState(currentState => ({
      ...currentState,
      data: {
        ...currentState.data,
        asset,
      },
    }))
  }

  private handleNotesChange(
    event?: React.ChangeEvent<HTMLTextAreaElement>,
    note?: string,
  ) {
    const notes = (event && event.currentTarget.value) || note || ''
    this.setState(currentState => ({
      ...currentState,
      data: {
        ...currentState.data,
        notes,
      },
    }))
  }

  private handleClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.props.onClose()
  }

  private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({isSubmitting: true})
    try {
      await this.props.updateData({
        ...this.state.data,
        notes: this.state.data.notes.replace(/(^[\s]+|[\s]+$)/g, ''),
      })
      this.setState({
        errors: null,
        isSubmitting: false,
      })
      this.props.onClose()
    } catch (e) {
      this.setState({
        errors: this.props.submitErrors,
        isSubmitting: false,
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PlantHaltModal))
