import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  CheckInput,
  CheckList,
  Input,
  DateInput,
  Select,
  TextArea,
} from '@mv-submodules/inplant-components-fe'
import AutomaticRenewalField from '../TaskFields/AutomaticRenewalField'
import FileUploaderFields from '../TaskFields/FileUploaderFields'
import GroupedFieldset from './GroupedFieldset'
import { FieldsetField, CheckListField, RelatedOption, FormFieldsMap, File, SimpleOption } from '../../../../types/pageForm'

interface OwnProps {
  fields: FieldsetField[]
  hiddenFields?: string[]
  requiredFields?: string[]
  refs?: FormFieldsMap<React.RefObject<any>>
  errors?: { [field: string]: string[] }
  className?: string
  childrenClass?: string
  type?: 'create' | 'modify'
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
  handleUploadFiles?: (file: File, remove?: boolean) => void
  pageConfigUpdateData?: (destination?: string) => any
}

type Props = OwnProps & WithTranslation

interface State {
  disableToggles: any[]
}

class GenericFieldset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    let newDisableToggles: any[] = []
    this.props.fields.forEach(field => {
      /* tslint:disable:no-string-literal */
      if (field['createDisableToggle']) {
        newDisableToggles = [
          ...newDisableToggles,
          {
            slug: (field as any).slug,
            visible: false,
          },
        ]
      }
    })
    this.state = {
      disableToggles: newDisableToggles,
    }
  }
  public render() {
    const props = this.props
    const fieldRefs = props.refs
    // const handleInputChange = this.handleInputChange.bind(this)
    const fields =
      props.hiddenFields && props.hiddenFields.length
        ? props.fields.filter(
        field =>
          !(
            field.hasOwnProperty('slug') &&
            props.hiddenFields!.includes(field['slug'])
          )
        )
        : props.fields
      
    return (
      <div className={props.className || 'fieldset'}>
        {fields.map((field, index) => {
          let fieldValue = field['slug'] && props.getInputValue(field['slug'])
          // if (fieldValue && field['slug'] === 'rolesWithValidation') {
          //   fieldValue = fieldValue[0]
          // } // VALUE WRAPPED INTO AN ARRAY
          const relValue = field['relSlug'] && props.getInputValue(field['relSlug'])
          const isVisible = field['relType'] !== 'visible' || !!relValue


          const fieldError =
            (props.errors &&
              props.errors[field['slug']] &&
              props.errors[field['slug']][0]) ||
            undefined
          switch (field.type) {
            case 'row':
              return (
                isVisible && (
                  <GenericFieldset
                    {...props}
                    key={index}
                    fields={field.fields}
                    className="row"
                    childrenClass="col"
                  />
                )
              )
            case 'group':
              return (
                isVisible && (
                  <GroupedFieldset
                    {...props}
                    key={index}
                    slug={field.slug}
                    fields={field.fields}
                  />
                )
              )
            case 'select':
              const options =
                field.relSlug && field.relType === 'options'
                  ? (field.options as RelatedOption[]).filter(option =>
                    relValue != null && option.rel instanceof Array
                      ? option.rel.includes(relValue)
                      : option.rel === relValue
                  )
                  : field.options
              if (
                field.relSlug &&
                !options.find(
                  ({value}: RelatedOption | SimpleOption) => value === fieldValue
                )
              ) {
                fieldValue = null
              }
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Select
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      options={{
                      //  defaultOption: !((field as any).slug === 'target.group') && '-' || undefined,
                        defaultOption: '-',
                        disableDefaultOption: false,
                        items: options
                      }}
                      value={fieldValue}
                      required={
                        field.required ||
                        (!!props.requiredFields &&
                          props.requiredFields.includes(field.slug))
                      }
                      disabled={
                        (window.location.pathname.split('/').length > 3 && // DISABILITO IMPIANTO E CENTRO DI COSTO IN MODIFICA
                          field.slug === 'destination' && props.type === 'modify')
                          ? true
                          : !!(
                            field.relSlug &&
                            (field.relType === 'options' ||
                              field.relType === 'disabled') &&
                            !relValue
                          )
                      }
                      readOnly={field.readOnly}
                      onChange={this.handleInputChange(
                        field.slug,
                        field.template,
                        this.props.pageConfigUpdateData
                      )}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'textarea':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <TextArea
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={
                        field.required ||
                        (!!props.requiredFields &&
                          props.requiredFields.includes(field.slug))
                      }
                      disabled={
                        !!(
                          field.relSlug &&
                          field.relType === 'disabled' &&
                          !relValue
                        )
                      }
                      readOnly={field.readOnly}
                      rows={field.rows}
                      onChange={this.handleInputChange(
                        field.slug,
                        field.template
                      )}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'number':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Input
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={
                        field.required ||
                        (!!props.requiredFields &&
                          props.requiredFields.includes(field.slug))
                      }
                      disabled={
                        !!(
                          field.relSlug &&
                          field.relType === 'disabled' &&
                          !relValue
                        )
                      }
                      readOnly={field.readOnly}
                      type={field.type}
                      min={field.min}
                      max={field.max}
                      onChange={this.handleInputChange(
                        field.slug,
                        field.template
                      )}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'text':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Input
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={
                        field.required ||
                        (!!props.requiredFields &&
                          props.requiredFields.includes(field.slug))
                      }
                      disabled={
                        !!(
                          field.relSlug &&
                          field.relType === 'disabled' &&
                          !relValue
                        )
                      }
                      readOnly={field.readOnly}
                      onChange={this.handleInputChange(
                        field.slug,
                        field.template
                      )}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'date':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <DateInput
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={
                        field.required ||
                        (!!props.requiredFields &&
                          props.requiredFields.includes(field.slug))
                      }
                      disabled={
                        this.state.disableToggles.find(
                          el => el.slug === field.slug
                        )
                          ? !this.state.disableToggles.find(
                          el => el.slug === field.slug
                          ).visible
                          : !!(
                            field.relSlug &&
                            field.relType === 'disabled' &&
                            !relValue
                          )
                      }
                      readOnly={field.readOnly}
                      onChange={this.handleInputChange(
                        field.slug,
                        field.template
                      )}
                      min={field.min}
                      max={field.max}
                      isClearable={
                        this.state.disableToggles.find(
                          el => el.slug === field.slug
                        )
                          ? this.state.disableToggles.find(
                          el => el.slug === field.slug
                          ).visible
                          : !!(
                            field.relSlug &&
                            field.relType === 'disabled' &&
                            !relValue
                          )
                      }
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'checkbox':
              if (field.hasOwnProperty('options')) {
                const op =
                  field.relSlug && field.relType === 'options'
                    ? (field['options'] as RelatedOption[]).filter(
                    option => relValue != null && option.rel === relValue
                    )
                    : field['options']
                if (
                  field.relSlug &&
                  !op.find(
                    ({value}: RelatedOption | SimpleOption) => {
                      if(Array.isArray(fieldValue)) {
                        return fieldValue.includes(value)
                      }
                      return value === fieldValue
                    }
                  )
                ) {
                  fieldValue = null
                }
                return (
                  isVisible && (
                    <div key={field.slug} className={props.childrenClass}>
                      <CheckList
                        label={this.renderLabel(field.slug)}
                        id={field.slug}
                        name={field.slug}
                        value={fieldValue}
                        ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                        required={
                          field.required ||
                          (!!props.requiredFields &&
                            props.requiredFields.includes(field.slug))
                        }
                        disabled={
                          !!(
                            field.relSlug &&
                            field.relType === 'disabled' &&
                            !relValue
                          )
                        }
                        readOnly={field.readOnly}
                        multiple={field.multiple}
                        inline={(field as CheckListField).inline}
                        inlineClassName="col col-md-1"
                        options={op}
                        onChange={this.handleInputChange(
                          field.slug,
                          field.template
                        )}
                        error={fieldError}
                      />
                    </div>
                  )
                )
              } else {
                return (
                  isVisible && (
                    <div key={field.slug} className={props.childrenClass}>
                      <CheckInput
                        label={this.renderLabel(field.slug)}
                        id={field.slug}
                        name={field.slug}
                        value={fieldValue}
                        disabled={
                          !!(
                            field.relSlug &&
                            field.relType === 'disabled' &&
                            !relValue
                          )
                        }
                        readOnly={field.readOnly}
                        onChange={this.handleInputChange(
                          field.slug,
                          field.template
                        )}
                        error={fieldError}
                      />
                    </div>
                  )
                )
              }
            case 'automaticRenewal':
              return (
                isVisible && (
                  <AutomaticRenewalField
                    {...props}
                    required={field.required}
                    key="automaticRenewal"
                  />
                )
              )
            case 'destinationField':
              return <div/>
            // disabled since it's still in development
            //     // destinationFiled wraps a Select component with added behaviour at the value change
            //     return <DestinationField
            //     key='destinationField'
            //     {...props}
            //     {...field}
            //     ref={fieldRefs ? fieldRefs[field.slug] : undefined}
            //     onChange={handleInputChange(field.slug, field.template)}
            //     required={field.required || !!props.requiredFields && props.requiredFields.includes(field.slug)}
            //     disabled={!!(field.relSlug && (field.relType === 'options' || field.relType === 'disabled') && !relValue)}
            //     fieldValue={fieldValue}></DestinationField>
            case 'attachments':
              return (
                <FileUploaderFields
                  handleUploadFiles={this.props.handleUploadFiles!}
                  key="fileUploaderKey"
                  fieldValue={fieldValue}
                  {...props}
                />
              )
            case 'button':
              return (
                isVisible && (
                  <div
                    key={field.slug}
                    className={props.childrenClass + ' formButton'}
                  >
                    <button
                      className={field.color ? `btn btn-${field.color}` : 'btn'}
                      onClick={() => {
                        if (field.toggleDisableOf) {
                          const newDisableToggles = this.state.disableToggles
                          const i = newDisableToggles.findIndex(
                            el => el.slug === field.toggleDisableOf
                          )
                          newDisableToggles[i].visible = !newDisableToggles[i]
                            .visible
                          this.setState({
                            disableToggles: newDisableToggles,
                          })
                        }
                      }}
                      disabled={
                        field.toggleDisableOf &&
                        this.state.disableToggles.find(
                          el => el.slug === field.toggleDisableOf
                        )
                          ? this.state.disableToggles[
                            this.state.disableToggles.findIndex(
                              el => el.slug === field.toggleDisableOf
                            )
                            ].visible
                          : false
                      }
                    >
                      {field.label}
                    </button>
                  </div>
                )
              )
            case 'alert':
              return (
                isVisible && (
                  <div
                    key={field.slug}
                    className={props.childrenClass + ' formAlert'}>
                    <div className={field.color ? `alert-${field.color}` : ''}>
                      {this.renderLabel(field.slug)}
                    </div>
                  </div>
                )
              )
            case 'empty':
              return (
                <div
                  key={index}
                  className={
                    field.size ? `col-${field.size}` : props.childrenClass
                  }
                />
              )
            default:
              return <div key={index} className={props.childrenClass}/>
          }
        })}
      </div>
    )
  }

  private handleInputChange = (
    slug: string,
    template?: boolean,
    pageConfigUpdateData?: any
  ) => {
    if (slug === 'destination' && pageConfigUpdateData) {
      return (value: any) => {
        pageConfigUpdateData(value)
        this.props.onInputChange(slug, value, template)
      }
    }
    return (value: any) =>
      {
        return slug === 'rolesWithValidation'
        ? this.props.onInputChange(slug, value, template) // VALUE WRAPPED INTO AN ARRAY
        : this.props.onInputChange(slug, value, template)}
  }

  private renderLabel(slug: string) {
    const t = this.props.t
    return t(`chronoframe.dueDates.formField.${slug}`)
  }
}

export default withTranslation()(GenericFieldset)
