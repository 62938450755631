import * as React from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'

export interface BarData {
  name: string
  bar1_1: number | boolean
  bar1_2: number | boolean
  bar2_1: number | boolean
  bar2_2: number | boolean
  bar3_1: number | boolean
  bar3_2: number | boolean
  bar3_3: number | boolean
  bar3_4: number | boolean
  bar3_x: number | undefined | false
}

interface BarDataColor {
  [k: string]: string
}

interface OwnProps {
  data: BarData[]
  colors: BarDataColor
}

type Props = OwnProps & WithTranslation

class BarStack3View extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)

    this.tooltipContent = this.tooltipContent.bind(this)
  }

  public render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={150}
          height={300}
          data={this.props.data}
          margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
          barCategoryGap={0}
        >
          <YAxis label={undefined} hide={true} domain={[0, 100]} />
          {Object.keys(this.props.data[0])
            .filter(e => e !== 'name')
            .map((key, index) => {
              if (key !== 'bar3_x' || this.props.data[0].bar3_x) {
                return (
                  <Bar
                    key={'bar-' + index}
                    dataKey={key}
                    stackId={key.substr(0, 4)}
                    fill={this.props.colors[key]}
                    isAnimationActive={false}
                  />
                )
              }
              return false
            })}
          <Tooltip content={this.tooltipContent} cursor={false} offset={10} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  private tooltipContent({ active, payload }: { active: boolean; payload: any[]; label: any }) {
    if (active && payload[0]) {
      const data = payload[0].payload
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
          <div
            className="recharts-default-tooltip"
            style={{
              margin: 0,
              padding: 10,
              backgroundColor: 'rgb(255, 255, 255)',
              border: '1px solid rgb(204, 204, 204)',
              whiteSpace: 'nowrap',
            }}
          >
            <p className="recharts-tooltip-label time-label" style={{ margin: 0 }}>
              {/*data['bar3_x'] ? <strong>{this.props.t('plantAnalysis.labels.bunker.incorrectData')}</strong> : ''*/}
            </p>
            {Object.keys(data)
              .filter(l => l !== 'name' && !(l === 'bar3_x' && !data[l]))
              .map((l: string) => (
                <p
                  key={l}
                  className={'recharts-tooltip-l l-' + l}
                  style={{ margin: 0, color: this.props.colors[l] }}
                >
                  {' ' + this.props.t('plantAnalysis.ls.bunker.data.' + l, { defaultValue: l })}:{' '}
                  <span className={'tooltip-value'}>{Number.parseFloat(data[l]).toFixed(2)} %</span>
                </p>
              ))}
          </div>
        </div>
      )
    }

    return null
  }
}

export default withTranslation()(BarStack3View)
