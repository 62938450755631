import React, { ChangeEvent, useEffect, /*useEffect,*/ useState } from 'react'
import LineGraph from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/views/DedicatedGraph/_LineGraph'
import MillsGraph from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/views/DedicatedGraph/_MillsGraph'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import DateRangePickerComponent
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/DateRangePicker/DateRangePickerComponent'
import * as moment from 'moment/moment'
import { WithTranslation, withTranslation } from 'react-i18next'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'


const workShift = {
  '1': {
    startHour: 6,
    startMinute: 0,
    endHour: 14,
    endMinute: 0,
  },
  '2': {
    startHour: 14,
    startMinute: 0,
    endHour: 22,
    endMinute: 0,
  },
  '3': {
    startHour: 22,
    startMinute: 0,
    endHour: 30,
    endMinute: 0,
  },
  '99': {
    startHour: 6,
    startMinute: 0,
    endHour: 30,
    endMinute: 0,
  },
}

const DecicatedGraphPageView = (props: WithTranslation) => {

  const [date, setDate] = useState<[moment.Moment, moment.Moment]>([moment().subtract('day', 1).startOf('day'), moment().subtract('day', 1).endOf('day')])

  return <div className={'mv4iot-fe-plant-analysis'}>
    <div className={'content'}>
      <TimeAndWorkshiftComponent
        {...props}
        onDateChange={setDate}
      />
      <LineGraph
        title={'Linea 1'}
        startDate={date[0].toISOString()/*'2022-12-06T16:00:00.000Z'*/}
        endDate={date[1].toISOString()/*'2022-12-07T05:00:00.000Z'*/}
        measure={{
          torque: 'IBSGN5PHK0N0DCJP_MT_DEC_TORQUE',
          speed: 'IBSGN5PHK0N0DCJP_SPEED_NASTRO',
          closeOpen: 'IBSGN5PHK0N0DCJP_MT_DEC_ACT_POS',
        }}
      />
      <LineGraph
        title={'Linea 2'}
        startDate={date[0].toISOString()/*'2022-12-06T16:00:00.000Z'*/}
        endDate={date[1].toISOString()/*'2022-12-07T05:00:00.000Z'*/}
        measure={{
          torque: 'IBSGN5PHK0OBEPNL_MT_DEC_TORQUE',
          speed: 'IBSGN5PHK0OBEPNL_SPEED_NASTRO',
          closeOpen: 'IBSGN5PHK0OBEPNL_MT_DEC_ACT_POS',
        }} />
      <LineGraph
        title={'Linea 5'}
        startDate={date[0].toISOString()/*'2022-12-06T16:00:00.000Z'*/}
        endDate={date[1].toISOString()/*'2022-12-07T05:00:00.000Z'*/}
        measure={{
          torque: 'IBSGN5PKA065B3AF_MT_DEC_TORQUE',
          speed: 'IBSGN5PKA065B3AF_SPEED_NASTRO',
          closeOpen: 'IBSGN5PKA065B3AF_MT_DEC_ACT_POS',
        }} />
      <MillsGraph
        title={'Mulino 1'}
        startDate={date[0].toISOString()/*'2022-12-06T16:00:00.000Z'*/}
        endDate={date[1].toISOString()/*'2022-12-07T05:00:00.000Z'*/}
        measure={{
          absorption: 'IBSGN5PHL2L16TOG-1_ACT_CUR',
        }} />
    </div>
  </div>
}

interface TimeAndWorkshiftComponentProps extends WithTranslation {
  onDateChange: (changedDate: [moment.Moment, moment.Moment]) => void
}

const TimeAndWorkshiftComponent = (props: TimeAndWorkshiftComponentProps) => {

  const { t, onDateChange } = props

  const [currentWorkshiftKey, setCurrentWorkshitKey] = useState<string>('99')
  const [date, setDate] = useState<[moment.Moment, moment.Moment]>([moment().subtract('day', 1).startOf('day'), moment().subtract('day', 1).endOf('day')])

  function updateDateWithWorkShift(changeDate: [moment.Moment, moment.Moment]): [moment.Moment, moment.Moment] {
    const result = [
      moment(changeDate[0].toDate())
        .add('h', workShift[currentWorkshiftKey].startHour)
        .add('m', workShift[currentWorkshiftKey].startMinute),
      moment(changeDate[1].toDate())
        .add('h', workShift[currentWorkshiftKey].endHour)
        .add('m', workShift[currentWorkshiftKey].endMinute),
    ]

    onDateChange(result as [moment.Moment, moment.Moment])

    return result as [moment.Moment, moment.Moment]
  }

  function handleWorkshiftChange(workshift: ChangeEvent<HTMLSelectElement>) {
    setCurrentWorkshitKey(workshift.currentTarget.value)
  }

  useEffect(() => {
    setDate(prev => (updateDateWithWorkShift([prev[0].startOf('day'), prev[0].startOf('day')])))
  }, [currentWorkshiftKey])

  return <Row horizontalAlignment={'end'}>
    <Column >
      <Row horizontalAlignment={'end'}>
    <DateRangePickerComponent
      onApply={(startDate: moment.Moment, endDate: moment.Moment) => {
        setDate(updateDateWithWorkShift([
          startDate.startOf('day'),
          endDate.startOf('day'),
        ]))
      }}
      singleDatePicker={true}
      showDropdowns={true}
      startDate={date[0]}
      endDate={date[0]}
      maxDate={moment().subtract('day', 1)}
    />
    </Row>
      <Row horizontalAlignment={'end'}>
    <select style={{width:'min-content'}}
      onChange={handleWorkshiftChange}
      className='form-control mt-2'
      value={currentWorkshiftKey}
    >
      <option value={undefined}>{t('plantAnalysis.actions.selectWorkshiftText')}</option>
      {Object.keys(workShift).map((e: string) => (
        <option key={e} value={e}>
          {t('plantAnalysis.options.workshift.' + e)}
        </option>
      ))}
    </select>
      </Row>
    </Column>
  </Row>
}

export default withTranslation()(DecicatedGraphPageView)
