import * as React from 'react'
import {
  Column,
  ComponentPropsGetter0,
  ComponentPropsGetterR,
} from 'react-table'
import { ListParams } from '../../../../types/store'
import { Table } from '@mv-submodules/inplant-components-fe'

interface OwnProps {
  columns: Column[]
  data: any[]
  isFetching: boolean
  noDataText: string
  ofText: string
  rowsText: string
  pageText: string
  rows?: number
  getTrProps?: ComponentPropsGetter0 | ComponentPropsGetterR
}

type OwnState = ListParams

type Props = OwnProps

class SimpleTable extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      page: 1,
      pageSize: props.rows || 50,
    }

    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
  }

  public render() {
    const { isFetching, data } = this.props
    const { page, pageSize } = this.state

    return (
      <Table
        data={!isFetching ? data : undefined}
        columns={this.props.columns}
        isFetching={this.props.isFetching}
        onPageChange={this.handleTablePageChange}
        onPageSizeChange={this.handleTablePageSizeChange}
        minRows={data.length ? 1 : 4}
        page={page - 1}
        pageSize={pageSize}
        showPaginationBottom={true}
        noDataText={this.props.noDataText}
        ofText={this.props.ofText}
        rowsText={this.props.rowsText}
        pageText={this.props.pageText}
      />
    )
  }

  private handleTablePageChange(page: number) {
    this.setState({ page: page+1 })
  }

  private handleTablePageSizeChange(pageSize: number) {
    this.setState({ page: 1, pageSize })
  }
}

export default SimpleTable
