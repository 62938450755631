import { TYPES, useInjection } from './binding'
import { TranslationServiceError } from './error'

/**
 * @throws {TranslationServiceError}
 */
export function useComponentsTranslation() {
  const identifier = TYPES.TranslationService

  try {
    return useInjection<ITranslationService>(identifier)
  } catch (error) {
    throw new TranslationServiceError(
      'useTranslation() required a translation service, init it in the application root tree using ContainerProvider'
    )
  }
}

export interface ITranslationService {
  t(s?: string, options?: Object): string
}

export class TranslationService {
  private _translator: ITranslationService

  constructor(translator: ITranslationService) {
    this._translator = translator
  }

  public t = (s?: string, options?: Object): string => {
    return this._translator.t(s ? s : '', options)
  }
}
