import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { TableParams } from '../../types/table'
import { Titech, TitechProps } from '../../types/titech'
import { API } from './index'
import { ListParams } from '@mv-submodules/inplant-titech-fe-iblu/types/store'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

export const fetchData = (params?: ListParams, plant?: string):
  ((dispatch: Dispatch) => Promise<Titech[]>) => (dispatch: Dispatch) => API().request(
  `/titech${plant && plant !== '' ? '?plant=' + plant : ''}`,
).then((data: TitechProps[]) => data.map(raw => new Titech(raw)))
  .catch((error: FetchError) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  })

export const applyParams = (params: TableParams): AnyAction => {
  return {
    type: 'TITEC_LIST_APPLY_PARAMS',
    params,
  }
}
