// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { TableProps } from 'react-table'

// * -------------------------------- MODULE -----------------------------------
import IconComponent from "../MVIcon/Icon";

const LoadingComponent = (props: TableProps) => (
  <div className={`-loading${props.loading ? ' -active' : ''}`}>
    <div className="-loading-inner">
      <IconComponent icon={"circle-notch"} spin={true} size="2x" className="text-muted" />
    </div>
  </div>
)

export default LoadingComponent
