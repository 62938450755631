import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { getHaltsData, HHHmmssFromMilliseconds, Loader } from '../../../../../functions/shared'

export interface PieData {
  name: string
  columns: string[]
  values: any[]
}

interface PieDataColor {
  id: string
  color: string
}

interface OwnProps {
  data: PieData | null
  days: number
  colors: PieDataColor[]
  hideTime?: boolean
  fetching?: boolean
  totalsLabel?: string
  totalsReference?: {
    seconds?: number
    label: string
  }
  chartLabels?: string
}

const fallBackColors: string[] = [
  "#ff9a91",
  "#fff37b",
  "#4aa130",
  "#7dff9b",
  "#2d4cc2",
  "#00b0ff",
  "#ff60ff",
  "#9beafb",
  "#ffdb79",
  "#095aaa",
  "#0aa285",
  "#6dd673",
  "#ac3b3b",
  "#c4dd8b",
  "#0382fc",
  "#87294d",
  "#941d3c",
  "#fff474",
  "#c2815c",
]

type Props = OwnProps & WithTranslation

class EventsPieView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  private static getColor(id: string, colors: PieDataColor[], index?: number) {
    const color = colors.find(c => c.id === id)
    return (color && color.color) ||
      (index !== undefined && fallBackColors[index]) ||
      undefined
  }

  public render() {
    const { fetching, t, data, colors, totalsLabel, totalsReference, days, hideTime } = this.props

    if (!fetching && (!data || !data.values || !data.values[0] || data.values[0].length < 1)) {
      return <div className='alert alert-warning w-100'>{t('plantAnalysis.noDataAvailable')}</div>
    }

    const values = data ? getHaltsData(data, days) : []

    const totalTime = values.reduce(
      (acc, current) => acc + (typeof current.duration === 'number' ? current.duration : 0),
      0
    )
    const chartLabels = this.props.chartLabels ? this.props.chartLabels : 'chartLabels'

    if(this.props.fetching) {
      return <div className='alert alert-secondary w-100 col-sm-6 mx-auto rpp-graph-fetch-loading alert-local'>
        {this.props.t('plantAnalysis.loading')}
        <Loader />
      </div>
    }

    return (
      <div className="row chart-events-pie">
        <div className="col-md-4 chart-events-pie--chart">
          <ResponsiveContainer width={150} height={150}>
            <PieChart width={150} height={150}>
              <Pie
                data={values}
                labelLine={false}
                outerRadius={70}
                fill="#8884d8"
                dataKey="perc"
                cx={75}
                cy={75}
                isAnimationActive={false}
              >
                {values.map((entry, index) => {
                  const color = EventsPieView.getColor(entry.key, colors, index)
                  return <Cell key={`cell-${index}`} fill={color} />
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="col-md-8 chart-events-pie--data">
          <table className={'table table-borderless table-sm w-100 mw-400 font-size-12 table-first-column-color'}>
            <thead>
              <tr>
                <th />
                <th className="text-right">%</th>
                <th className="text-right">{t('plantAnalysis.halts.labels.events')}</th>
                {
                  !hideTime && <th className="text-right">{t('plantAnalysis.halts.labels.time')}</th>
                }
              </tr>
            </thead>

            <tbody>
              {values.map((datum, index) => (
                <tr key={datum.key}>
                  <td>
                    <span
                      className="label-color-square"
                      style={{ backgroundColor: EventsPieView.getColor(datum.key, colors, index) }}
                    /><span>{t(`plantAnalysis.halts.${chartLabels}.${datum.key}`, { defaultValue: datum.key })}</span>
                  </td>
                  <td className="text-right">{datum.perc ? datum.perc.toFixed(2) : 0}</td>
                  <td className="text-right">{datum.events || ''}</td>
                  {
                    !hideTime &&
                    <>
                      <td className="text-right">{HHHmmssFromMilliseconds(datum.duration * 1000, false, true) || ''}</td>
                      <td />
                    </>
                  }
                </tr>
              ))}
              {totalsLabel && (
                <tr key={'total'} className="font-bold">
                  <td>
                    <span className="label-color-square" style={{ backgroundColor: 'transparent' }} /><span>{totalsLabel}</span>
                  </td>
                  <td />
                  <td />
                  {
                    !hideTime &&
                    <>
                      <td className="text-right">{HHHmmssFromMilliseconds(totalTime * 1000, false, true)|| '--'}</td>
                      <td>
                        {totalsReference && totalsReference.seconds && (
                          <span className="text-warning" data-total={totalTime} data-ref={totalsReference.seconds}>
                            {HHHmmssFromMilliseconds(totalTime * 1000 - totalsReference.seconds * 1000, false, true)|| '--'}
                          </span>
                        )}
                      </td>
                    </>
                  }
                </tr>
              )}

              {totalsReference && (
                <tr key={'total-reference'}>
                  <td>
                    <span className="label-color-square" style={{ backgroundColor: 'transparent' }} /><span>{totalsReference.label || '--'}</span>
                  </td>
                  <td />
                  <td />
                  {
                    !hideTime &&
                      <>
                        <td className="text-right">{totalsReference.seconds ? (HHHmmssFromMilliseconds(totalsReference.seconds * 1000, false, true) || '--') : ''}</td>
                        <td />
                      </>
                  }
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withTranslation()(EventsPieView)
