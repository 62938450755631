import * as React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {WithTranslation, withTranslation} from 'react-i18next'
import {groupDetail} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/groupsList'
import {Button, DropdownButton, Loader, PageHeader} from '@mv-submodules/inplant-components-fe'
import AssetsTable from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetsTable/AssetsTable'
import {
  AssetsListElement,
  AssetsListFilters,
  AssetStatus,
  CostCenter,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {getAssetsStatus} from '@mv-submodules/inplant-asset-manager-fe/functions/shared'
import {GroupDetail} from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import {FetchSitesGrousModulesResponse} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import {fetchSitesGroupsModules} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import {DropdownAction} from '@mv-submodules/inplant-components-fe/ui/components/Button/DropdownButton'

interface OwnState {
  selectedAssets: string[],
  data?: GroupDetail,
  assets: AssetsListElement[],
  assetStatus: AssetStatus[],
  isFetching: boolean,
  filters: AssetsListFilters,
  plants: Array<{ code: string, name: string }>
  costCenters: Array<{ plant: string, costCenters: CostCenter[] }>
  groupId: string
}

type Props = RouteComponentProps & WithTranslation

class AssetGroupDetailPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedAssets: [],
      isFetching: false,
      filters: {
        costCenter: null,
        searchString: null,
        status: null,
        plant: null,
      },
      assetStatus: [],
      assets: [],
      plants: [],
      costCenters: [],
      groupId: ''
    }

    this.applyFiltersTable = this.applyFiltersTable.bind(this)
    this.updateSelectedAsset = this.updateSelectedAsset.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.goToGroupUpdate = this.goToGroupUpdate.bind(this)
  }

  public componentDidMount() {
    const groupId = this.props.match.params['groupId'] //tslint:disable-line
    if (!groupId) {
      this.props.history.push('/asset-manager/groups')
    }
    const assetStatus: AssetStatus[] = getAssetsStatus()
    this.setState({assetStatus, groupId}, this.fetchData)
  }

  public render() {
    const {t} = this.props
    const {isFetching, filters, assetStatus, data, selectedAssets, assets, plants, costCenters} = this.state
    const actions: DropdownAction[] = [
      {label: t('assetManager.actions.updateGroup'), onClick: this.goToGroupUpdate},
//      { label: t('assetManager.actions.disableGroup'), onClick: () => null },
    ]

    return (
      <div className="inplant-asset-manager-fe">
        {isFetching ? (
            <Loader/>
          )
          :
          (
            <>
              <PageHeader title={data && data.name || ''}
                          backButton={true}
                          backUrl={'/asset-manager/groups'}
                          rightButtons={
                            <>
                              <DropdownButton actions={actions} alignRight={true} variant={"primary-alternate"}>
                                {t('assetManager.actions.actions')}
                              </DropdownButton>
                              {/*
                                <button className="btn btn-primary ml-2" type="button">
                                {t('assetManager.actions.addAsset')}
                              </button>
                              */}
                            </>
                          }
              />
              <div className="content">
                {selectedAssets && selectedAssets.length > 0 && (
                  <div className="header-asset-detail-recap mb-5">
                    <p className="mb-0 mt-0">
                      <strong>{`${selectedAssets.length} ${t('assetManager.navigation.asset')}`}</strong></p>
                    <Button variant={"primary"} label={t('assetManager.actions.removeFromGroup')}/>
                  </div>
                )}
                { /* Table Section*/}
                <AssetsTable
                  data={!isFetching && data ? assets : []}
                  pages={0}
                  assetStatus={assetStatus}
                  filters={filters}
                  isFetching={isFetching}
                  updateFilters={this.applyFiltersTable}
                  hiddenCols={['groups', 'select']}
                  selectedAssets={selectedAssets}
                  updateSelectedAsset={this.updateSelectedAsset}
                  plants={plants}
                  costCenters={costCenters}
                  manual={false}
                  sortableCols={['status', 'assetCode', 'positionCode', 'assetDescription', 'costCenter.name', 'activatedOn']}
                />
                { /* End Table Section*/}
                {selectedAssets && selectedAssets.length > 0 && (
                  <div className="footer-asset-detail-recap mt-5">
                    <button className="btn btn-primary" type="button">
                      {t('assetManager.actions.removeFromGroup')}
                    </button>
                  </div>
                )}
              </div>
            </>
          )
        }
      </div>
    )
  }

  private async fetchData() {
    if (!this.state.isFetching) {
      this.setState({isFetching: true})
      const data: GroupDetail = await groupDetail(this.state.groupId)
      const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
      const plantsArray: Array<{ code: string, name: string }> = sitesData.sites.reduce((acc: Array<{ code: string, name: string }>, sites) => {
        acc = acc.concat(sites.plants.reduce((plants: Array<{ code: string, name: string }>, plant) => {
          plants.push({
            code: plant.code,
            name: plant.name,
          })
          return plants
        }, []))
        return acc
      }, [])
      const costCenters: Array<{ plant: string, costCenters: CostCenter[] }> = sitesData.sites.reduce((acc: Array<{ plant: string, costCenters: CostCenter[] }>, site) => {
        return acc.concat(site.plants.reduce((acc2: Array<{ plant: string, costCenters: CostCenter[] }>, plant) => {
          acc2.push({
            plant: plant.code,
            costCenters: plant.costCenters
          })
          return acc2
        }, []))
      }, [])
      this.setState({data, assets: data.assets, isFetching: false, plants: plantsArray, costCenters})
    }
  }

  private applyFiltersTable(filters: AssetsListFilters) {
    if (this.state.data) {
      const {assets} = this.state.data
      const {searchString, status, costCenter, plant} = filters

      this.setState({
        filters,
        assets: assets.filter((asset: AssetsListElement) => (!searchString || (asset.assetDescription + asset.positionCode + asset.assetCode).toUpperCase().includes(searchString.toUpperCase())) &&
          (!status || status === asset.status) && (!costCenter || costCenter === asset.costCenter.code) && (!plant || asset.plant.code === plant),
        )
      })
    }
  }

  private updateSelectedAsset(value: string) {
    let selectedAssets = this.state.selectedAssets
    if (value === 'all') {
      if (selectedAssets.length === this.state.data!.assets.length) {
        selectedAssets = []
      } else {
        selectedAssets = this.state.data!.assets.reduce((acc: string[], asset: AssetsListElement) => {
          acc.push(asset.id)
          return acc
        }, [])
      }
    } else {
      if (selectedAssets.includes(value)) {
        selectedAssets.splice(selectedAssets.indexOf(value), 1)
      } else {
        selectedAssets.push(value)
      }
    }
    this.setState({selectedAssets})
  }

  private goToGroupUpdate() {
    this.props.history.push(`/asset-manager/groups/update/${this.state.groupId}`)
  }
}

export default withRouter<any, any>((withTranslation())(AssetGroupDetailPageView))