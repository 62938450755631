import { NullPie, RowLegend } from '../../../../../functions/shared'
import PieSmallView from '../../charts/PieSmall/PieSmallView'
import * as React from 'react'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import Bars from '../../charts/Bars/BarsView'

interface OwnProps {
  data: any
  days: number
  name: string
  id: string
  currentOverrun: any
  currentLimit: null | string
  node: any
}

type Props = OwnProps & WithTranslation

class FanGraphRowItemView extends React.PureComponent<Props> {
  public render() {
    const { days } = this.props
    const dataAuto: any = this.props.data.find((e: any) => e.name === 'vAspiratorAuto')
    const dataMan: any = this.props.data.find((e: any) => e.name === 'vAspiratorManual')
    const dataLoc: any = this.props.data.find((e: any) => e.name === 'vAspiratorLocal')
    const dataSez: any = this.props.data.find((e: any) => e.name === 'vAspiratorSectioned')

    const dataArresto: any = this.props.data.find((e: any) => e.name === 'vAspiratorStop')
    const dataDiretta: any = this.props.data.find((e: any) => e.name === 'vAspiratorBypass')

    const currentOverrun = this.props.currentOverrun ? parseInt(this.props.currentOverrun, 10) : null
    const currentLimit = this.props.currentLimit ? parseInt(this.props.currentLimit, 10) : null

    const currentValue =
      currentOverrun && currentLimit && currentOverrun > currentLimit
        ? (((currentOverrun - currentLimit) / currentLimit) * 100).toFixed(2) + '%'
        : 0

    return (
      <div className="fan-row-item col-12 mt-3 page-break-inside-avoid">
        <div className="row">
          <div className="col-12">
            <h3>{RowLegend(this.props.name)}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            {dataArresto !== undefined && (
              <PieSmallView
                data={[
                  { name: 'true', value: dataArresto.value / days },
                  { name: 'false', value: 100 - dataArresto.value / days },
                ]}
                colors={[
                  { id: 'false', color: COLORS.marciaFermo[1] },
                  { id: 'true', color: COLORS.marciaFermo[0] },
                ]}
              />
            )}

            {dataDiretta === undefined && <NullPie />}
          </div>
          <div className="col-md-3">
            {this.props.node && this.props.node.nodeType.indexOf('_INVERTER') > -1 && dataDiretta !== undefined && (
              <PieSmallView
                data={[
                  { name: 'true', value: dataDiretta.value / days },
                  { name: 'false', value: 100 - dataDiretta.value / days },
                ]}
                colors={[
                  { id: 'false', color: COLORS.driveBypass[0] },
                  { id: 'true', color: COLORS.driveBypass[1] },
                ]}
              />
            )}
            {dataDiretta === undefined && <NullPie />}
          </div>

          <div className="col-md-3">
            {dataAuto !== undefined && dataMan !== undefined && dataLoc !== undefined && (
              <Bars
                data={[
                  {
                    man: dataMan.value ? parseFloat(dataMan.value.toFixed(2)) / days : 0,
                    auto: dataAuto.value ? parseFloat(dataAuto.value.toFixed(2)) / days : 0,
                    loc: dataLoc.value ? parseFloat(dataLoc.value.toFixed(2)) / days : 0,
                    sec: dataSez.value ? parseFloat(dataSez.value.toFixed(2)) / days : 0,
                  },
                ]}
                colors={{
                  man: COLORS.manAutoLocSec[0],
                  auto: COLORS.manAutoLocSec[1],
                  loc: COLORS.manAutoLocSec[2],
                  sec: COLORS.manAutoLocSec[3],
                }}
              />
            )}
          </div>
          <div className="col-md-2 pt-4">
            {currentValue !== 0 &&
              RowLegend(
                'Sforamento Assorbimento',
                currentValue,
                null,
                <React.Fragment>
                  <strong>{this.props.t('plantAnalysis.labels.mean')}</strong>: {currentOverrun}
                  <br />
                  <strong>{this.props.t('plantAnalysis.labels.limit')}</strong>: {currentLimit}
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(FanGraphRowItemView)
