import * as React from 'react'
import { MeasureDistributionBarData } from '../../charts/MeasureDistributionBar/MeasureDistributionBarView'
import { withTranslation, WithTranslation } from 'react-i18next'
import MeasureDistributionBarPrint
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/charts/MeasureDistributionBar/MeasureDistributionBarPrint'

interface OwnProps {
  data: MeasureDistributionBarData[]
  customSettingsFieldsCode?: '308*'
}

type Props = OwnProps & WithTranslation

class BagGraphMeasureDistributionView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return <React.Fragment>{this.props.data && <MeasureDistributionBarPrint {...this.props} />}</React.Fragment>
  }
}

export default withTranslation()(BagGraphMeasureDistributionView)
