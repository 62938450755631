import * as React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {Button} from "@mv-submodules/inplant-components-fe";

interface OwnProps {
  suggestedNotes: Array<string | FrequentNoteItem>;
  setNote: (note: string) => void;
  disabled: boolean;
}

export interface FrequentNoteItem {
  value: string
  icon?: IconProp
}


type Props = OwnProps & WithTranslation;

class FrequentNotesBox extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const {suggestedNotes, t, setNote, disabled} = this.props
    return (
      <div className="box-suggested-notes">
        <div className="header-box-suggested-notes">
          <p>{t('plantHalts.modal.frequentsNotes')}</p>
        </div>
        {suggestedNotes && suggestedNotes.map((note, index) => {
            const noteValue = typeof note === 'object' ? note.value : note
            const noteIcon = typeof note === 'object' && note.icon ?
              <FontAwesomeIcon icon={note.icon} size={'2x'}/> : null

            return (<div
              key={`suggested_note_${typeof note === 'object' ? note.value : note}`}
              className={`${
                suggestedNotes.length - 1 === index
                  ? 'box-suggested-note-last-div'
                  : ''
              }`}
            >
              <div className="list-suggested-note">
                <p className={`${disabled ? 'selected-note' : ''}`}>{noteIcon}{noteValue}</p>
                <Button
                  variant={"secondary"}
                  onClick={() => setNote(noteValue + ' ')}
                  disabled={disabled}
                  label={t('plantHalts.list.action.select')}
                />
              </div>
              {!(suggestedNotes.length - 1 === index) && (
                <hr className="divider-suggested-note"/>
              )}
            </div>)
          }
        )}

      </div>
    )
  }
}

export default withTranslation()(FrequentNotesBox)