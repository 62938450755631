import { logoutUser } from '../../redux/actions'
import { Dispatch } from 'redux'

export interface ErrorResponse extends Response {
  statusCode: number
  message: string
  detail: string
  errors?: { [key: string]: string[] }
  title?: string
}

export default class FetchError extends Error {
  public statusCode: number
  public errors: { [key: string]: string[] }
  public headers?: Record<string, string>

  constructor(message: string, detail: string, statusCode: number, errors?: any, headers?: Record<string, string>) {
    super(detail || message)
    this.name = 'FetchError'
    this.statusCode = statusCode
    this.errors = errors
    this.headers = headers
  }
}

/**
 * Manage the possible request errors that required a standard action
 */
export const manageRequestErrors = (error: unknown, dispatch: Dispatch, requestWithAuth: boolean = true) => {
  // manage errors
  if (error instanceof Error) {
    if (error.name === 'FetchError') {
      manageFetchErrors(error as FetchError, dispatch, requestWithAuth)
    }
  }
}

// manage fetch errors
const manageFetchErrors = (error: FetchError, dispatch: Dispatch, requestWithAuth: boolean = true) => {
  if (error.statusCode === 401 && requestWithAuth) {
    dispatch(logoutUser())
  }
}
