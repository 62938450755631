import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Modal, TextArea } from '@mv-submodules/inplant-components-fe'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'

interface OwnState {
  text: string
}

interface StateProps {
  onSubmit: (text: string) => void
  closeModal: () => void
  visible: boolean
  isSubmitting: boolean
}

type Props = StateProps & WithTranslation

class ModalAddNoteAsset extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      text: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (this.props && nextProps && this.props.visible !== nextProps.visible) {
      this.setState({ text: '' })
    }
  }

  public render() {
    const { visible, t, isSubmitting } = this.props
    const { text } = this.state
    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={visible}
          closeLabel={t('assetManager.actions.cancel')}
          onClose={this.props.closeModal}
          title={t('assetManager.modal.title.addNote')}
          width={50}
          additionalFooterButtons={[{
            type: 'submit',
            variant: 'primary' as ButtonVariants,
            isLoading: isSubmitting,
            label: t('assetManager.actions.save'),
          }]}
        >
          <TextArea
            label={t('assetManager.forms.notes')}
            value={text}
            name={'text'}
            onChange={value => this.handleInputChange({ value })}
            required={true}
          />
        </Modal>
      </form>
    )
  }

  private onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.props.onSubmit(this.state.text)
  }

  private handleInputChange(state: { value: string }) {
    const text = state.value as string
    this.setState({ text })
  }
}

export default withTranslation()(ModalAddNoteAsset)
