import * as React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {AssetFileUpload} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import {assetDocumentTypes} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import {Button, Input, Checkbox} from '@mv-submodules/inplant-components-fe'
import Select, {SelectOptions} from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

// import { SelectOptions } from '@mv-submodules/inplant-components-fe/components/Input/Select'

interface StateProps {
  file: AssetFileUpload
  index: number
  removeDocument: (index: number) => void
  updateFile: (file: AssetFileUpload, index: number) => void
  editable: boolean
}

type Props = StateProps & WithTranslation

interface SourceType {
  name: string;
  code?: string | undefined;
  id?: string | undefined;
}

class FileUpdate extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.updateFile = this.updateFile.bind(this)
  }

  public render() {
    const {t, file, index} = this.props
    return (
      <Row verticalAlignment={'center'} >
        <Column md={3}>
          <label>
            <strong>{file.name}</strong>
          </label>
        </Column>
        <Column md={3} formGroup={true}>

          <Input
            label={t('assetManager.forms.description')}
            type={'text'}
            id={'form-file-description'}
            required={true}
            name={'description'}
            onChange={value => this.updateFile(undefined, {value: value as string, name: 'description'})}
            value={file.description}
            disabled={!this.props.editable}
          />

        </Column>
        <Column md={3}>
          <div className={"mt-2"}>
            <Select
              onChange={(value) => this.updateFile(undefined, {value: value as string, name: 'type'})}
              value={file.type}
              name='type'
              disabled={!this.props.editable}
              options={this.getSelectOptions(assetDocumentTypes)}
            />
          </div>
        </Column>
        <Column md={2}>
          <div className='form-group items-center'>
            <div className='form-check'>
              <Checkbox
                onChange={e => this.updateFile(undefined, {name: 'isInHeadquarter', event: e as HTMLInputElement})}
                checked={file.isInHeadquarter || false}
                name='isInHeadquarter'
                id={`isInHeadquarter_document`}
                disabled={!this.props.editable}
                label={t('assetManager.forms.inHeadquarter')}
              />
            </div>
          </div>
        </Column>
        <Column md={1} formGroup={true}>

          <Button variant='danger-alternate' disabled={!this.props.editable}
                  onClick={() => this.props.removeDocument(index)} icon={"trash"}/>

        </Column>
      </Row>
    )
  }

  private updateFile(
    event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    state?: { value?: string; name: string; event?: HTMLInputElement },
  ) {
    const prop: string = event ? event.target.name : state!.name
    let {file} = this.props
    file = {
      ...file,
      [prop]:
        prop === 'isInHeadquarter'
          ? this.props.file && !this.props.file.isInHeadquarter
          : event
          ? event.target.value
          : state?.value ? state?.value : event,
    }
    this.props.updateFile(file, this.props.index)
  }


  private getSelectOptions(sources: Array<SourceType | string>, defaultOption?: string): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }
    sources.map(source => {
      if (typeof source === 'string') {
        options.items.push({
          label: this.props.t(`assetManager.forms.${source}`),
          value: source as string,
        })
      }
    })
    return options
  }

}

export default withTranslation()(FileUpdate)
