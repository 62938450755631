// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE -----------------------------------
import IconComponent from '../MVIcon/Icon'

interface Props {
  onSubmit?: () => Promise<any>
  onCancel?: () => void
  upload?: (formD: FormData) => Promise<any> // callback that must be passed from 'above' that handles the upload phase since it needs fetch-wrapper instance from the right module
  onError?: (error: Response) => void // callback that must be passed from above that handles the failed upload callback
  onSuccess?: (result: any) => void // callback to pass up the ids
  children?: React.ReactNode // default react.props.children
  testingSuccesful?: boolean // if true it shows the succesful upload UI
  testingFailure?: boolean // if true it shows the failed upload UI
  testDelay?: number // used to delay success/failure while testing
  buttonText: string // used for translated text in the upload button
  buttonClass?: string // css class to use for button appearance, defaults to btn-primary
  maxFileSize?: number // maximum file size allowed in bytes
}

interface OwnState {
  uploading: boolean // the file is beign uploaded
  response: {} | null // the file has been succesful uploaded, here it's stored the result ID
  uploadError: any // there was an error while uploading, da rivedere errore come viene buttato fuori
  originalFileName: string
}

class FileUploader extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      uploading: false,
      response: null,
      uploadError: '',
      originalFileName: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  private handleChange(files: any) {
    if (files) {
      if (
        !this.props.maxFileSize ||
        (files.item(0) && files.item(0).size < this.props.maxFileSize)
      ) {
        const formD = new FormData()

        // it works both for a single file and for multiple files selected
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < files.length; i++) {
          // tslint:disable-line prefer-for-of
          formD.append(files[i].name, files[i])
        }

        this.setState({
          uploading: true,
          originalFileName: files[0].name, // now it passes only the first file name
        })

        if (this.props.testingSuccesful) {
          // condition to test succesful upload, these portion of code can be safely removed when testing with enzyme(or similar) is implemented
          setTimeout(() => {
            this.setState({
              uploading: false,
              response: { name: 'test id', id: '12345678' },
            })
          }, this.props.testDelay || 1000)
        } else if (this.props.testingFailure) {
          // condition to test failed upload, read the same as 'testingSuccesful' comment
          setTimeout(() => {
            this.setState({ uploadError: 'errore upload', uploading: false })
          }, this.props.testDelay || 1000)
        } else {
          // if not in testing check if there is the upload function and the error function, otherwise throw an error
          // here it's the actual code that works in normal condition
          if (!this.props.upload) {
            throw new Error(
              'the upload function needs to be provided if not testing the UI'
            )
          }
          this.props.upload(formD).then(
            response => {
              this.setState({
                uploading: false,
                response,
              })
              if (!this.props.onSuccess) {
                throw new Error(
                  'the on success function is required to pass above the resultant ids'
                )
              }
              this.props.onSuccess({ ...response, ...this.state })
            },
            error => {
              if (!this.props.onError) {
                throw new Error(
                  'the onError function is required to behave when there is an upload error'
                )
              }
              this.props.onError(error)
              this.setState({ uploadError: error, uploading: false })
            }
          )
        }
      } else {
        this.setState({ uploadError: 'FILE TROPPO PESANTE', uploading: false })
      }
    }
  }

  private whatToRender(state: OwnState, props: Props) {
    // hides the ugly default upload interface
    const inputStyle = {
      height: 0,
      width: 0,
      opacity: 0,
    }

    // fixes position to align to other elements since label has a default margin bottom that needs to be overwrited
    const labelStyle = {
      marginBottom: 0,
    }

    // if the component is uploading show a rolling circle
    if (state.uploading) {
      return (
        <button
          className={
            'btn ' +
            (this.props.buttonClass ? this.props.buttonClass : 'btn-primary')
          }
        >
          <IconComponent icon={"circle-notch"} spin={true} size="sm" />
        </button>
      )
    } else if (state.response !== null) {
      // if the image has been succesfully uploaded show the resultant ID
      // do not use if you want to remove the uploaded button from a component that is above
      // use
      //   {this.state.result ?
      //     <React.Fragment>
      //      {what to show}
      //    </React.Fragment> :
      //     <FileUploader
      //         buttonText={t('maintenance.dueDates.detail.upload')}
      //         upload={this.upload}
      //         onSuccess={this.onSuccess}
      //         onError={this.onError}
      //     >
      //     </FileUploader>
      // }
      return <React.Fragment>{props.children}</React.Fragment>
    } else if (state.uploadError) {
      // if there was an error while uploading
      return (
        <button
          className={
            'btn ' +
            (this.props.buttonClass ? this.props.buttonClass : 'btn-primary')
          }
        >
          <IconComponent icon={"exclamation-triangle"} size="sm" />{' '}
          {state.uploadError}
        </button>
      )
    } else {
      // if I'm in the initial state show the upload button
      return (
        <React.Fragment>
          <input
            type="file"
            id="uploadInput"
            className="fileUploaderInputClass"
            style={inputStyle}
            onChange={e => this.handleChange(e.target.files)}
          />
          <label
            htmlFor="uploadInput"
            className={
              'btn ' +
              (this.props.buttonClass ? this.props.buttonClass : 'btn-primary')
            }
            style={labelStyle}
          >
            <IconComponent icon={"file-upload"} size="sm" />{' '}
            {this.props.buttonText}
          </label>
        </React.Fragment>
      )
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.whatToRender(this.state, this.props)}
      </React.Fragment>
    )
  }
}

export default FileUploader
