const en = {
  status: {
    title: 'Plants status',
    columns: {
      plantName: 'PLANT',
      status: 'STATUS',
      lastConnection: 'LAST CONNECTION',
      latency: 'LAST PING',
    },
    statuses: {
      optimal: 'OPTIMAL',
      sufficient: 'ACCEPTABLE',
      slow: 'SLOW',
      unstable: 'UNSTABLE',
      offline: 'OFFLINE',
    },
    packageLoss: 'Lost packets: ',
    noDataText: 'No plant available',
    ofText: 'of',
    rowsText: 'rows',
  },
}

export default en
