import { getContainer, TYPES } from '../../mvlabs-components-fe/services/binding'
import { StateManagerService } from '../../mvlabs-components-fe/services/stateManager'
import { RootState, useAppDispatch, useAppSelector } from '../redux/reducers'

function initStateManagement(container: ReturnType<typeof getContainer>) {
  container
    .bind<StateManagerService<RootState>>(TYPES.StateManagerService)
    .toConstantValue(new StateManagerService<RootState>(useAppSelector, useAppDispatch))
}

export default initStateManagement
