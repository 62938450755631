// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

export interface Props {
  content: any
  children: React.ReactNode
}

const CssTooltip = (props: Props) => (
  <div
    className="data-tooltips"
    data-title={props.content}
  >
    {props.children}
  </div>
)

export default CssTooltip
