import React from "react"

const vidimIcon = (
  <svg id="id" width="114" height="89" viewBox="0 0 114 89" xmlns="http://www.w3.org/2000/svg">
    <path
      className="logo-base"
      d="M94.5 33.31L65.03 58.28L56.94 65.16V65.21L56.92 65.19L48.78 58.28L19.34 33.28L0.640015 49.78L29.72 74.47L37.85 81.35L46.38 88.59H56.94H67.46L75.98 81.35L84.09 74.47L113.18 49.78L94.5 33.31Z"
    />
    <path className="logo-left" d="M56.94 0.169998V51.38L56.92 51.4L27.29 26.24L56.89 0.139999L56.94 0.169998Z" />
    <path className="logo-right" d="M86.52 26.27L56.94 51.38V0.169998L86.52 26.27Z" />
  </svg>
)

export default vidimIcon
