import * as React from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import * as moment from 'moment'
import { DateRangePicker, isInclusivelyAfterDay, SingleDatePicker } from 'react-dates'

export interface Preset {
  text: string
  start: moment.Moment
  end: moment.Moment
}

interface OwnState {
  endDate: null | moment.Moment
  errorMessage: null | string
  focusedInput: null | 'endDate' | 'startDate'
  focusedSingleInput: boolean | null
  startDate: null | moment.Moment
}

interface Props {
  anchorDirection?: 'left' | 'right'
  endDate: any
  endDateOffset?: number
  maxDate?: any
  numberOfMonths?: number
  onApply: Function
  renderCalendarInfo?: () => string | JSX.Element
  showDropdowns: boolean
  showInputs?: boolean
  singleDatePicker: boolean
  startDate: any
  presets?: Preset[]
}

class DateRangePickerComponent extends React.Component<Props, OwnState> {
  constructor( props: Props ) {
    super(props)

    this.state = {
      errorMessage: null,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      focusedInput: null,
      focusedSingleInput: false,
    }

    this.onDatesChange = this.onDatesChange.bind(this)
    this.onSingleDatesChange = this.onSingleDatesChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
    this.onSingleFocusChange = this.onSingleFocusChange.bind(this)
    this.getMaxDate = this.getMaxDate.bind(this)
  }

  public componentWillReceiveProps( nextProps: Readonly<Props>, nextContext: any ): void {
    const { startDate, endDate } = this.state
    if (startDate !== nextProps.startDate || endDate !== nextProps.endDate) {
      this.setState({
        startDate: nextProps.startDate,
        endDate: nextProps.endDate,
      })
    }
  }
  
  public render(): React.ReactNode {
    const { numberOfMonths, anchorDirection, singleDatePicker } = this.props
    const { errorMessage, focusedInput, focusedSingleInput, startDate, endDate } = this.state

    const renderCalendarInfo = errorMessage ? () => <div>{errorMessage}</div> : () => this.renderDatePresets()

    return (
      <div className="date-range-picker">
        <div style={{ height: '100%' }}>
          {singleDatePicker ? (
            <SingleDatePicker
              id={'datesinglepicker-date'}
              anchorDirection={anchorDirection || 'right'}
              onDateChange={this.onSingleDatesChange}
              onFocusChange={this.onSingleFocusChange}
              focused={focusedSingleInput || false}
              date={startDate}
              // renderCalendarInfo={renderCalendarInfo}
              orientation={'horizontal'}
              isOutsideRange={( day: moment.Moment ) => isInclusivelyAfterDay(day, moment().add(1, 'day')) || false}
              small={true}
              // allow single day selection
              numberOfMonths={numberOfMonths || 2}
              displayFormat={'DD/MM/YYYY'}
            />
          ) : (
            <DateRangePicker
              anchorDirection={anchorDirection || 'right'}
              startDateId={'daterangepicker-date_start'}
              endDateId={'daterangepicker-date_end'}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              renderCalendarInfo={renderCalendarInfo}
              orientation={'horizontal'}
              // maxDate={maxDate}
              isOutsideRange={( day: moment.Moment ) =>
                (startDate && isInclusivelyAfterDay(day, startDate.clone().add(30, 'days'))) ||
                (startDate && day.isBefore(startDate.clone().subtract(1, 'day')) && focusedInput === 'endDate') ||
                isInclusivelyAfterDay(day, moment()) ||
                false
              }
              small={true}
              // allow single day selection
              minimumNights={0}
              numberOfMonths={numberOfMonths || 2}
              displayFormat={'DD/MM/YYYY'}
            />
          )}
        </div>
      </div>
    )
  }

  private onSingleDatesChange( date: moment.Moment | null ) {
    this.setState({
      startDate: date,
      endDate: date,
      focusedSingleInput: false,
    })

    this.props.onApply(date, date)
  }

  private onDatesChange( { startDate, endDate, isPreset }: { startDate: moment.Moment | null; endDate: moment.Moment | null; isPreset?: boolean } ) {
    this.setState({
      startDate,
      endDate,
    })

    // avoid data fetch on startDate selection
    if (startDate && endDate && (this.state.focusedInput === 'endDate' || isPreset)) {
      this.props.onApply(startDate, endDate)
    }
  }

  private onFocusChange( focusedInput: null | 'startDate' | 'endDate' ) {
    this.setState(
      {
        focusedInput,
      },
      () => {
        if (focusedInput === 'endDate') {
          this.setState({
            endDate: null,
          })
        }
      },
    )
  }

  private onSingleFocusChange( focusedSingleInput: any ) {
    this.setState({
      focusedSingleInput: focusedSingleInput.focused,
    })
  }

  private getMaxDate() {
    const { startDate } = this.state
    return (startDate && startDate.add(7, 'days')) || undefined
  }

  private renderDatePresets() {
    const { presets } = this.props

    if (!presets) {
      return <div className="inplant-daterangepicker-presets no-presets"/>
    }

    return (
      <div className="inplant-daterangepicker-presets">
        {presets.map(( { text, start, end } ) => {
          return (
            <button
              key={text}
              type="button"
              data-start={start.format('YYYY-MM-DD')}
              data-end={end.format('YYYY-MM-DD')}
              onClick={() => this.onDatesChange({
                startDate: start,
                endDate: end,
                isPreset: true
              })}
            >
              {text}
            </button>
          )
        })}
      </div>
    )
  }
}

export default DateRangePickerComponent
