import React from "react"
import { useTranslation } from "react-i18next"
import { Units, UnitValue } from "../../../../types/configuration"

interface Props {
  units: Units
  unitSelected?: UnitValue
  handleChangeUnit: (unit:UnitValue) => void
}

const UnitSelector = (props: Props) => {
  const { t } = useTranslation()
  const {units,unitSelected,handleChangeUnit} = props

  if (! units.enable || units.values === undefined || units.values.length <= 1 ) {
    return null
  }

  return (
    <div className="unit-selector d-inline-block mb-2">
      <label className="mr-2">{t('plantHalts.unit')}</label>
      <div className="btn-group" role="group">
        {units.values.map(unit => {
          return (
            <button
              key={unit.id}
              type="button"
              className={`btn btn-outline-secondary ${unitSelected?.id === unit.id ? 'active' : ''}`}
              onClick={() => handleChangeUnit(unit)}
            >
              {unit.name}
            </button>
          )
        })}
      </div>
    </div>
  )
}


export default UnitSelector