import * as React from 'react'
import * as moment from 'moment'
import { WithTranslation, withTranslation } from 'react-i18next'
import FormModal from '../../widgets/TaskFormModal/FormModal'
import { FormData, Fieldset } from '../../../../types/pageForm'

interface OwnProps {
  visible: boolean
  action: string | null
  onClose: (update?: boolean) => void
  onSubmit: (data: FormData) => Promise<any>
  data?: FormData
}

export type Props = OwnProps & WithTranslation

const TaskActionModal = (props: Props) => {
  const t = props.t

  // react.fragment is needed to fix the instantiation of an empty formModal, react behaviour is broken somewhere

  if (!props.visible) {
    return null
  }

  return props.action ? (
    <FormModal
      {...props}
      title={t(
        `chronoframe.dueDates.detail.action.${props.action}`,
      )}
      data={serializeData(props.action, props.data)}
      fields={getFields(props.action)}
      submitLabel={t('chronoframe.tasks.form.save')}
      cancelLabel={t('chronoframe.tasks.form.cancel')}
      type="action"
    />
  ) : (
    <React.Fragment/>
  )
}

function serializeData(action: string | null, data?: FormData) {
  return action && data
    ? {
      ...data,
      // date: action === 'done' ? moment().format('YYYY-MM-DD') : undefined,
      date:
        action === 'validate' && data.doneAtDate
          ? data.doneAtDate
          : undefined,
    }
    : undefined
}

function getFields(action: string | null): Fieldset[] {
  // configures what to output in the modal, components are imported from GenericFieldset
  switch (action) {
    case 'done':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'date',
                  slug: 'date',
                  required: true,
                  max: moment().format('YYYY-MM-DD'),
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'alert',
                  slug: 'dateAlert',
                  color: 'secondary',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'attachments',
                },
              ],
            },
          ],
        },
      ]
    case 'done-batch':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'date',
                  slug: 'date',
                  required: true,
                  max: moment().format('YYYY-MM-DD'),
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'alert',
                  slug: 'dateAlert',
                  color: 'secondary',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        },
      ]
    case 'add-note':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'attachments',
                },
              ],
            },
          ],
        },
      ]
    case 'reschedule':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'date',
                  slug: 'date',
                  required: true,
                },
                {
                  type: 'empty',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                  required: true
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        },
      ]
    case 'reject':
    case 'cancel':
    case 'validation':
    case 'acknowledge':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        },
      ]
    case 'undo':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        },
      ]
    case 'validate':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'date',
                  slug: 'date',
                  required: true,
                }
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'attachments',
                },
              ],
            },
          ],
        },
      ]
    case 'validate-batch':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'date',
                  slug: 'date',
                  required: true,
                }
              ],
            },
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
              ],
            },
          ],
        },
      ]
    case 'fail-validation':
      return [
        {
          fieldset: 'custom',
          fields: [
            {
              type: 'row',
              fields: [
                {
                  type: 'textarea',
                  slug: 'notes',
                },
                {
                  type: 'empty',
                },
              ],
            },
          ],
        },
      ]
    default:
      return []
  }
}

export default withTranslation()(TaskActionModal)
