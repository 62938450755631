import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as moment from 'moment'
import {
  AttachmentDownloadButton,
  AttachmentImage,
  Badge,
  Button,
  Link,
  MainPageContent,
  PageHeader,
} from '@mv-submodules/inplant-components-fe'
import TaskFormModal from '../../widgets/TaskFormModal/TaskFormModal'
import TaskActionModal from './TaskActionModal'
import TaskTitle from '../../widgets/TaskFields/TaskTitle'
/*
import TaskAssigneeField from '../../widgets/TaskFields/TaskAssignee'
*/
import { getInputValueFromData } from '../../widgets/TaskFormModal/utils'
import { pageConfig, pageConfigUpdateData } from '../../../../redux/actions/pageConfig'
import { fetchDetail } from '../../../../redux/actions/pageDetail'
import { Action, submitData, submitTemplate } from '../../../../redux/actions/pageForm'
import { assignTask, changeStatus } from '../../../../redux/actions/pageActions'
import { DueDatesConfig } from '../../../../types/pageConfig'
import { ActionLog, ChronoTask, DueDatesOptions, LinkAttachment, StatusInfo, User } from '../../../../types/chronoTasks'
import { FormData } from '../../../../types/pageForm'
import { DetailField, DetailFieldset } from '../../../../types/pageDetail'
import { PageList } from '../../../../types/pageList'
import { Dispatch } from 'redux'
import TabulatedText from '@mv-submodules/inplant-components-fe/ui/components/Text/TabulatedText'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'
import { getBadgeType } from '@mv-submodules/inplant-chronoframe-fe/functions/shared'
import { API_FILE } from '@mv-submodules/inplant-chronoframe-fe/redux/actions'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

interface OwnState {
  pageSlug: string
  showFormModal: boolean
  showActionModal: boolean
  previousPath?: string
  showModalCreation: boolean
  actions: Array<{
    label: string
    onClick: () => void
  }>
  currentAction: string | null
  statusInfo: StatusInfo | null
  positionInFilteredList: number
  taskId: string | null
  taskIsFetching: boolean
  taskFetchingError: boolean
  chronoTask?: ChronoTask
  configAvailableActions: string[]
  modalPageConfig?: DueDatesConfig
}

interface StateProps<T> extends DueDatesConfig {
  pageSlug: string
  currentUser: User
  forbiddenActions: string[]
  isConfigLoading: boolean
  detailTitle: DetailField[]
  detailFields: DetailFieldset[]
  taskIsUpdating: boolean
  formData: T
  pageList: PageList
  configAvailableActions: string[]
}

interface DispatchProps<T> {
  submitData: (data: FormData, action: Action) => Promise<FormData>
  submitNewCreation: (data: FormData) => Promise<FormData>
  submitTemplate: (
    data: FormData,
    options: DueDatesOptions,
  ) => Promise<FormData>
  assignTask: (assignee: User, taskId: string) => Promise<void>
  changeStatus: (
    status: string,
    data: FormData) => Promise<void>
  pageConfig: (pageSlug: string) => Promise<DueDatesConfig>
  fetchDetail: (id: string, options: DueDatesOptions) => Promise<T>
  pageConfigUpdateData: (pageSlug: string, destination: string) => any
}

type Props<T> = OwnProps & StateProps<T> & DispatchProps<T> & WithTranslation

const mapStateToProps = ({ chronoframe, auth }: any) => {
  return {
    pageSlug: chronoframe.pageConfig.pageSlug,
    currentUser: { ...auth.user, id: auth.user.uuid },
    forbiddenActions: auth.user.forbiddenActions,
    isConfigLoading: chronoframe.pageConfig.isFetching,
    ...chronoframe.pageConfig.data,
    taskIsFetching: chronoframe.pageDetail.isFetching,
    taskIsUpdating: chronoframe.pageDetail.isUpdating,
    taskData: chronoframe.pageDetail.data,
    formData: chronoframe.pageDetail.formData,
    pageList: chronoframe.pageList,
    configAvailableActions: chronoframe.pageConfig.availableActions,
    modalPageConfig: chronoframe.modalPageConfig
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
): DispatchProps<ChronoTask> => ({
  submitData: ({ ...data }, action) => submitData({ ...data }, action)(dispatch),
  submitNewCreation: (data) => submitData(data)(dispatch),
  submitTemplate: (data, options) => submitTemplate(data, options)(dispatch),
  assignTask: (assignee, taskId) => assignTask(assignee, [taskId])(dispatch),
  changeStatus: (status, data) => changeStatus(status, data)(dispatch),
  // changeStatus: async (status, data, id, options) => {
  //   await changeStatus(status, data, [id])(dispatch)
  //   await fetchDetail(id, options)(dispatch) // I need to update the view with correct embedded values
  // },
  pageConfig: pageSlug => pageConfig(pageSlug)(dispatch),
  fetchDetail: (id, options) => fetchDetail(id, options)(dispatch),
  pageConfigUpdateData: (pageSlug: string, destination: string) => pageConfigUpdateData(pageSlug, destination)(dispatch),
})

class TaskDetailPageView extends React.Component<Props<ChronoTask>, OwnState> {
  constructor(props: Props<ChronoTask>) {
    super(props)
    this.state = {
      pageSlug: this.getPageSlug(),
      showFormModal: false,
      showActionModal: false,
      showModalCreation: false,
      actions: [],
      currentAction: null,
      statusInfo: null,
      positionInFilteredList: -1,
      taskId: null,
      taskIsFetching: false,
      taskFetchingError: false,
      configAvailableActions: props.availableActions,
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchTaskDetail = this.fetchTaskDetail.bind(this)
    this.handleFetchTaskDetail = this.handleFetchTaskDetail.bind(this)
    this.toggleFormModal = this.toggleFormModal.bind(this)
    this.toggleActionModal = this.toggleActionModal.bind(this)
    this.renderFieldset = this.renderFieldset.bind(this)
    this.renderField = this.renderField.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTemplateSubmit = this.handleTemplateSubmit.bind(this)
    this.getInputValue = this.getInputValue.bind(this)
    this.getInputValue = this.getInputValue.bind(this)
    this.getBackUrl = this.getBackUrl.bind(this)
    /*
        this.hasPermission = this.hasPermission.bind(this)
    */
    this.handleGoToTask = this.handleGoToTask.bind(this)
    this.handleSubmitNewCreation = this.handleSubmitNewCreation.bind(this)
    this.getDataAllowedForNewCreation = this.getDataAllowedForNewCreation.bind(this)
  }

  public async componentDidMount() {
    try {
      if (this.props.pageSlug !== this.state.pageSlug) {
        await this.props.pageConfig(this.state.pageSlug).then((config) => {
          this.setState({ configAvailableActions: config.availableActions })
        })
      }
      this.fetchData()
    } catch (error) {
      console.warn(error) // tslint:disable-line
    }
  }

  public async componentDidUpdate(prevProps: Readonly<Props<ChronoTask>>, prevState: Readonly<OwnState>, snapshot?: any) {
    // debugger //tslint:disable-line
    if (prevProps.match.params.id && this.props.match.params.id && prevProps.match.params.id !== this.props.match.params.id) {
      try {
        this.setState({
          pageSlug: this.getPageSlug(),
          showFormModal: false,
          showActionModal: false,
          actions: [],
          currentAction: null,
          statusInfo: null,
          positionInFilteredList: -1,
          taskId: null,
          taskIsFetching: false,
          taskFetchingError: false,
          modalPageConfig: undefined
        })
        if (this.props.pageSlug !== this.state.pageSlug) {
          await this.props.pageConfig(this.state.pageSlug).then((config) => {
            this.setState({ configAvailableActions: config.availableActions })
          })
        }
        this.fetchData()
      } catch (error) {
        console.warn(error) // tslint:disable-line
      }
    }
  }

  private refreshPageConfigData = (destination?: string) => {
    if(destination) {
      this.props.pageConfigUpdateData(this.state.pageSlug, destination)
        .then((modalPageConfig: DueDatesConfig) => {
          this.setState({modalPageConfig})
        })
        .catch((error: any) => {
          console.log(error) //tslint:disable-line
        })
    }
  }

  public render() {
    const t = this.props.t
    const {
      showFormModal,
      showActionModal,
      showModalCreation,
      actions,
      currentAction,
    } = this.state
    const data = this.state.chronoTask
    const isLoading = !this.props.detailFields || this.props.isConfigLoading
    const isFetching = this.state.taskIsFetching
    const detailErrors = this.state.taskFetchingError
    const isUpdating = this.props.taskIsUpdating
    const title = data && !isFetching && !isLoading && TaskTitle({ data, fields: this.props.detailTitle }) || ''
    const canDoActions = !isFetching && actions.length > 0
    return (
      <>
        {detailErrors && !isFetching && !isLoading && (
          <>
            <PageHeader
              title=''
              backButton={true}
              backUrl={this.getBackUrl()}
            />
            <div className='alert alert-danger w-100 col-sm-6 mx-auto task-detail-fetch-errors mt-5'>
              <h2>{t('chronoframe.errors.somethingGoneWrong')}</h2>
              <p>{t('chronoframe.errors.contactOurTeam')}</p>
            </div>
          </>
        )}
        {
          !isLoading && !isFetching && !detailErrors && (
            <>
              <PageHeader
                title={title}
                backButton={true}
                backUrl={this.getBackUrl()}
                subtitleButtons={[
                  ...(canDoActions && actions.map(action => ({
                    variant: 'secondary-alternate' as ButtonVariants,
                    onClick: () => action.onClick(),
                    label: action.label,
                    disabled: isUpdating,
                  })) || []),
                ]}
              />
              {showModalCreation && <TaskFormModal
                visible={showModalCreation}
                title={t('chronoframe.dueDates.newDueDatesFrom', { name: title })}
                data={{
                  ...this.getDataAllowedForNewCreation(data),
                  inChargeUser: data && data.inChargeUser && data.inChargeUser.id || '',
                }}
                fields={this.props.formFields}
                onClose={() => this.setState({ showModalCreation: false })}
                onSubmit={this.handleSubmitNewCreation}
                submitTemplate={this.handleTemplateSubmit}
                pageConfigUpdateData={this.refreshPageConfigData}
                type={'create'}
              />}

              <TaskFormModal
                visible={showFormModal}
                title={title}
                data={{ ...data, inChargeUser: data && data.inChargeUser && data.inChargeUser.id || '' }}
                fields={this.props.formFields.filter(element => {
                  return element.fieldset !== 'splitTargets'
                })}
                onClose={this.toggleFormModal}
                onSubmit={this.handleSubmit}
                submitTemplate={this.handleTemplateSubmit}
                type={'modify'}
              />
              {/* TaskActionModal is the modal opened from the dropdown menu*/}
              <TaskActionModal
                visible={showActionModal}
                action={currentAction}
                data={data}
                onClose={(update) => this.toggleActionModal(undefined, update)}
                onSubmit={this.changeStatus}
              />
            </>
          )
        }
        <MainPageContent loading={isLoading || isFetching}>
          {!isLoading && !isFetching && !detailErrors && this.state.chronoTask && (
            <>
              {this.props.detailFields.map(this.renderFieldset)}
              <div
                className='mt-4'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {this.state.positionInFilteredList > 0 && (
                  <Button
                    variant='secondary'
                    spacing={{ horizontal: false }}
                    onClick={async () => {
                      await this.props.history.replace(
                        `${window.location.pathname
                          .split('/')
                          .slice(
                            0,
                            window.location.pathname.split('/').length - 1,
                          )
                          .join('/')}/${
                          this.props.pageList.tableFilteredIds[
                          this.state.positionInFilteredList - 1
                            ]
                        }`,
                      )
                      await this.props.fetchDetail(
                        window.location.pathname.split('/')[
                        window.location.pathname.split('/').length - 1
                          ],
                        this.props.values,
                      )
                    }}
                    label={t('chronoframe.dueDates.detail.prevTask')}
                  />
                )}
                {this.state.positionInFilteredList >= 0 &&
                this.state.positionInFilteredList <
                this.props.pageList.tableFilteredIds.length - 1 && (
                  <Button
                    //        className="btn btn-info mr-2 next-task"
                    spacing={{ horizontal: false }}
                    variant='secondary'
                    onClick={async () => {
                      await this.props.history.replace(
                        `${window.location.pathname
                          .split('/')
                          .slice(
                            0,
                            window.location.pathname.split('/').length - 1,
                          )
                          .join('/')}/${
                          this.props.pageList.tableFilteredIds[
                          this.state.positionInFilteredList + 1
                            ]
                        }`,
                      )
                      await this.props.fetchDetail(
                        window.location.pathname.split('/')[
                        window.location.pathname.split('/').length - 1
                          ],
                        this.props.values,
                      )
                    }}
                    label={t('chronoframe.dueDates.detail.nextTask')}
                  />
                )}
              </div>
            </>
          )}
        </MainPageContent>
      </>
    )
  }

  private async fetchData() {
    if (!this.state.taskIsFetching) {
      this.setState({ taskIsFetching: true })
      this.fetchTaskDetail()
        .then(result => {
          this.handleFetchTaskDetail(result)
        })
        .catch((e) => {
          this.setState({ taskFetchingError: true })
          console.warn(e) //tslint:disable-line
        })
        .finally(() => {
          this.setState({ taskIsFetching: false })
        })
    }
  }

  private fetchTaskDetail() {
    return this.props.fetchDetail(
      this.props.match.params.id,
      this.props.values,
    )
      .then((taskData) => taskData)
  }

  // TODO
  private async handleFetchTaskDetail(taskData: ChronoTask) {
    this.setState({ chronoTask: taskData })
    if (taskData.destination) {
      await this.props.pageConfigUpdateData(
        this.getPageSlug(),
        taskData.destination,
      )
    }
    this.setState(
      {
        actions: this.getStatusActions(
          taskData.availableActions,
        ),
        positionInFilteredList: this.props.pageList.tableFilteredIds.findIndex(
          (rowId: string) => rowId === this.props.match.params.id,
        ),

      },
      () => {
        taskData.actionLog.forEach(action => {
          if (action.type === taskData.status) {
            const newStatusInfo: StatusInfo = {
              matchingAction: action.notes,
              author: action.user,
            }
            this.setState({ statusInfo: newStatusInfo })
          }
        })
      },
    )
  }

  private toggleFormModal(update?: boolean) {
    this.setState(currentState => ({
      showFormModal: !currentState.showFormModal,
    }))
  }

  private toggleActionModal(action?: string, update?: boolean) {
    this.setState(currentState => ({
      currentAction: action || null,
      showActionModal: !currentState.showActionModal,
    }))
  }

  private renderFieldset(fieldset: DetailFieldset, index: number) {
    // this function is used to map data to visual items in the detail page
    const t = this.props.t
    switch (fieldset.fieldset) {
      case 'detail':
        return (
          <TabulatedText
            title={t('chronoframe.dueDates.detail.details')}
            data={fieldset.fields!.map((field, i) => ({
              label: t(`chronoframe.dueDates.detail.${field.slug}`),
              value: this.renderField(field),
            }))}
          />
        )
      case 'visibilityAndAssignee':
        return (
          <TabulatedText
            data={[
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('inChargeUser')) && [{
                label: t(`chronoframe.dueDates.detail.inChargeUser`),
                value: this.renderField({
                  slug: 'inChargeUser.displayName',
                  type: 'text',
                }),
              }] || []),
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('plantId')) && [{
                label: t(`chronoframe.dueDates.detail.plantId`),
                value: this.renderField({
                  slug: 'plantId',
                  type: 'text',
                }),
              }] || []),
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('assigneeUser')) && [{
                label: t(`chronoframe.dueDates.detail.assigneeUser`),
                value: this.renderField({
                  slug: 'assigneeUser',
                  type: 'assignee',
                }),
              }] || []),
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('rolesWithVisibility')) && [{
                label: t(`chronoframe.dueDates.detail.rolesWithVisibility`),
                value: this.renderField({
                  slug: 'rolesWithVisibility',
                  type: 'array',
                }),
              }] || []),
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('rolesWithAction')) && [{
                label: t(`chronoframe.dueDates.detail.rolesWithAction`),
                value: this.renderField({
                  slug: 'rolesWithAction',
                  type: 'array',
                }),
              }] || []),
              ...((!fieldset.hiddenFields ||
                !fieldset.hiddenFields.includes('mustBeValidated')) && [{
                label: t(`chronoframe.dueDates.detail.mustBeValidated`),
                value: `${this.renderField({
                  slug: 'mustBeValidated',
                  type: 'boolean',
                })} - ${this.state.chronoTask &&
                this.state.chronoTask.rolesWithValidation &&
                this.state.chronoTask.rolesWithValidation.length > 0 && this.renderField({
                  slug: 'rolesWithValidation',
                  type: 'array',
                }) || ''}`,
              }] || []),
            ]}
          />
        )
      case 'attachments':
      case 'doneAttachments':
      case 'validationAttachments':
        const fieldSet = fieldset.fieldset
        const attachments: LinkAttachment[] =
          this.getInputValue(fieldSet) || []
        return attachments.length ? (
          <div key={`${index}/${fieldSet}`} className='mt-5'>
            <h4 className='h5 text-uppercase mb-2'>
              {t(`chronoframe.dueDates.detail.${fieldSet}`)}
            </h4>
            <div>
              {attachments.map(attachment => (
                <div key={attachment.id} className='mb-2'>
                  {(attachment.type === 'image/png' ||
                    attachment.type === 'image/jpeg' ||
                    attachment.type === 'image/gif') && (
                    <div>
                      <AttachmentDownloadButton
                        fetchWrapper={API_FILE()}
                        className='btn btn-link download-detail-attachment'
                        path={attachment.link}
                        fileName={attachment.name}
                      >
                        <AttachmentImage
                          fetchWrapper={API_FILE()}
                          className='attachment-photo-image'
                          path={`${attachment.link}`}
                          src={`${attachment.link}`}
                          alt={attachment.description}
                          style={{ width: '100%', display: 'block' }}
                        />
                      </AttachmentDownloadButton>
                      <div className='d-flex'>
                        <div className='text-dark m-2'>{attachment.name}</div>
                        {attachment.description && (
                          <div className='text-dark m-2'>
                            {attachment.description}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {attachment.type !== 'image/png' &&
                  attachment.type !== 'image/jpeg' &&
                  attachment.type !== 'image/gif' && (
                    <div className=' mb-5 d-flex' style={{ textAlign: 'left' }}>
                      <AttachmentDownloadButton
                        fetchWrapper={API_FILE()}
                        className='btn btn-link download-detail-attachment'
                        path={attachment.link}
                        fileName={attachment.name}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          size='1x'
                          className='text-secondary'
                        />
                        <span className='m-2'>{attachment.name}</span>
                      </AttachmentDownloadButton>
                      {attachment.description && (
                        <div className='text-dark m-2'>
                          {attachment.description}
                        </div>
                      )}
                    </div>
                  )}
                  {attachment.position && (
                    <span className='ml-3'>
                      <span className='text-muted'>
                        {t('chronoframe.dueDates.detail.attachmentPosition')}:
                      </span>{' '}
                      {attachment.position}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null
      case 'actionHistory':
        const actionHistory = this.getInputValue('actionLog') || []
        return actionHistory.length ? (
          <div key={index} className='mt-5'>
            <h4
              className='h5 text-uppercase mb-2 pb-2'
              style={{ borderBottom: '1px solid lightgrey' }}
            >
              {t('chronoframe.dueDates.detail.actionHistory')}
            </h4>
            <div className='table'>
              {actionHistory.map((action: ActionLog) => {
                const date = moment(action.date)
                const showDateTime = action.date.length > 10
                const markedDate = action.userMarkedDate !== null ? (' il ' + moment(action.userMarkedDate).format('DD/MM/YYYY')) : ''
                return (
                  <div
                    key={action.date}
                    className='pb-2 mb-2'
                    style={{ borderBottom: '1px solid lightgrey' }}
                  >
                    <div>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        fontWeight: 600,
                        textDecoration: action.isUndone
                          ? 'line-through'
                          : 'none',
                      }}
                      className='mr-3'
                    >
                      {action.type === 'Edited' && (
                        <>{t('chronoframe.dueDates.detail.edited') + markedDate}</>
                      )}
                      {action.type === 'Created' && (
                        <>{t('chronoframe.dueDates.detail.created') + markedDate}</>
                      )}
                      {action.type === 'Done' && (
                        <>{t('chronoframe.dueDates.detail.doneDate') + markedDate}</>
                      )}
                      {action.type === 'Rescheduled' && (
                        <>{t('chronoframe.dueDates.detail.rescheduled') + markedDate}</>
                      )}
                      {action.type === 'Acknowledged' && (
                        <>{t('chronoframe.dueDates.detail.acknowledged') + markedDate}</>
                      )}
                      {/*{action.type === 'Canceled' && (
                        <>{t('chronoframe.dueDates.detail.canceled') + markedDate}</>
                      )}
                      {action.type === 'Rejected' && (
                        <>{t('chronoframe.dueDates.detail.rejected') + markedDate}</>
                      )}*/}
                      {action.type === 'Validated' && (
                        <>{t('chronoframe.dueDates.detail.validated') + markedDate}</>
                      )}
                      {action.type === 'ValidationFailed' && (
                        <>{t('chronoframe.dueDates.detail.validationFailed') + markedDate}</>
                      )}
                      {action.type === 'AddNote' && (
                        <>{t(`chronoframe.dueDates.detail.addNote`) + markedDate}</>
                      )}
                      {/*I HAD TO KEEP THIS NO GOOD LOGIC BECAUSE IT'S USED IN OTHERS POINTS, BUT I WOULD LIKE TO CHANGE IT ASAP*/}
                      {action.type === 'AddAttachments' && (
                        <>{t(`chronoframe.dueDates.detail.addAttachments`) + markedDate}</>
                      )}
                      {action.type === 'AddNoteWithAttachments' && (
                        <>{t(`chronoframe.dueDates.detail.addNoteWithAttachments`) + markedDate}</>
                      )}
                    </span>
                      {action.notes && (
                        <span className='font-italic'>{`"${action.notes}"`}</span>
                      )}
                    </div>
                    <div>
                      <span className='mr-5'>{action.user}</span>
                      <span className='mr-2'>
                      {date.format('DD/MM/YYYY')}
                    </span>
                      {showDateTime && (<span>{date.format('HH:mm')}</span>)}
                    </div>
                    {this.state.chronoTask && this.state.chronoTask.actionToUndo &&
                    this.state.chronoTask.actionToUndo.id === action.id && (
                      <Button
                        variant='secondary-alternate'
                        size='sm'
                        label={t('chronoframe.dueDates.detail.cancelAction')}
                        onClick={() => this.toggleActionModal('undo')}
                      />
                    )}
                    {action.isUndone && (
                      <div>
                      <span className='font-italic'>
                        {t('chronoframe.dueDates.detail.canceledAction')} -{' '}
                        {action.undoneDate
                          ? moment(action.undoneDate!).format('DD/MM/YYYY')
                          : ''}{' '}
                        {action.undoneNotes ? '- ' + action.undoneNotes : ''}
                      </span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        ) : null
      default:
        return null
    }
  }

  private renderField(field: DetailField) {
    const t = this.props.t
    switch (field.type) {
      case 'text':
        const value = this.getInputValue(field.slug)
        return !value
          ? '-'
          : Array.isArray(value)
            ? value.map((row: string, index: number) => (
              <div key={index}>{row}</div>
            ))
            : value
      case 'number':
        return this.getInputValue(field.slug) || '-'
      case 'array':
        const array = this.getInputValue(field.slug)
        return array && array.length ? array.join(', ') : '-'
      case 'boolean':
        return t(
          `chronoframe.dueDates.detail.${
            this.getInputValue(field.slug) ? 'yes' : 'no'
          }`,
        )
      case 'date':
        const date = this.getInputValue(field.slug)
        if (field.slug === 'effectiveFromDate' && date && this.state.chronoTask && this.state.chronoTask.parentTask && this.state.chronoTask.parentTask.uuid) {
          return (<>
              {`${moment(date).format('DD/MM/YYYY')} `}
              <Link
                onClick={() => this.handleGoToTask(this.state.chronoTask!.parentTask!.uuid)}
                label={this.props.t('chronoframe.scheduledJobDetail.fromRenewal').toLowerCase()}
              />
            </>
          )
        }
        if (field.slug === 'dueAtDate' && date && this.state.chronoTask && this.state.chronoTask.childTask && this.state.chronoTask.childTask.uuid) {
          return (<>
              {`${moment(date).format('DD/MM/YYYY')} `}
              <Link
                onClick={() => this.handleGoToTask(this.state.chronoTask!.childTask!.uuid)}
                label={this.props.t('chronoframe.scheduledJobDetail.renewal').toLowerCase()}
              />
            </>
          )
        }
        return date ? moment(date).format('DD/MM/YYYY') : '-'
      case 'checkmark-icon':
        return (
          <span><FontAwesomeIcon icon={faCheckCircle}
                                 color={`${this.getInputValue(field.slug) ? 'green' : 'lightgray'}`} /></span>
        )
      case 'status':
        const statusId = this.getInputValue(field.slug)
        const status = this.props.values.statuses.find(
          ({ id }) => id === statusId,
        ) || { name: '–' }
        return (
          <Badge
            type={getBadgeType(statusId)}
            value={t(`chronoframe.status.${status.name.toLowerCase()}`)}
            subvalue={this.state.statusInfo && `${this.state.statusInfo.matchingAction ? `"${this.state.statusInfo.matchingAction}" / ` : ''}${this.state.statusInfo.author}` || undefined}
          />
        )
      // logic never used
      /*case 'assignee':
        const canAssignToMe = this.hasPermission(
          'operational.chronoTask.assignToMe',
        )
        const canAssignTo = this.hasPermission(
          'operational.chronoTask.assignTo',
        )
        const assignee = this.getInputValue(field.slug)
        const taskId = this.getInputValue('id')
        const currentUser = {
          ...this.props.currentUser,
          displayName: t('chronoframe.dueDates.me'),
        }
        const assignableUsers = canAssignTo
          ? [currentUser]
          : canAssignToMe
            ? [currentUser]
            : undefined
        const handleTaskAssignment = (assigneeUser: User) =>
          this.props.assignTask(assigneeUser, taskId).catch(console.warn) // tslint:disable-line
        return (
          <TaskAssigneeField
            assignee={assignee}
            assignable={canAssignTo}
            assignableUsers={assignableUsers}
            onClick={handleTaskAssignment}
            // assigneeLabel={t(`chronoframe.dueDates.tableList.${canAssignToMe ? 'assignToMe' : 'assignTo'}`)}
            assigneeLabel={t('chronoframe.dueDates.tableList.assignTo')}
          />
        )*/
      case 'assets':
        const assets = this.getInputValue(field.slug)
        if (!assets) {
          return '-'
        }
        if (field.slug === 'target') {
          const extendedTargets = this.props && this.state.chronoTask && this.state.chronoTask.extendedTarget
          return assets.map((asset: any, i: number) => {
            const group = !asset.asset && extendedTargets && extendedTargets[asset.group]
            return (
              <React.Fragment key={i}>
                {group && Object.keys(group).length > 0 ? (
                  <div>
                    <span><b>{asset.description}: </b></span>
                    {Object.keys(group).map((key: string) => group[key]).join(', ') + '.'}
                  </div>
                ) : (
                  <div>
                    {asset.description}
                  </div>
                )}
              </React.Fragment>
            )
          })
        }
        return (<></>)
      case 'renewal':
        const renewal = this.getInputValue(field.slug)
        return this.getInputValue('automaticRenewal') ? (
          <>
            <span>
              {t('chronoframe.dueDates.detail.renewalPeriod') +
              (renewal.count && renewal.period.toLowerCase() === 'days'
                ? ` ${renewal.count} ${
                  renewal.count === 1
                    ? t('chronoframe.dueDates.detail.day')
                    : t('chronoframe.dueDates.detail.days')
                }
            `
                : renewal.count && renewal.period.toLowerCase() === 'months'
                  ? ` ${renewal.count} ${
                    renewal.count === 1
                      ? t('chronoframe.dueDates.detail.month')
                      : t('chronoframe.dueDates.detail.months')
                  }
          `
                  : '') +
              (renewal.method === 'FromDueDate'
                ? ` ${t('chronoframe.dueDates.detail.fromDueDate')}`
                : ` ${t('chronoframe.dueDates.detail.fromDoneDate')}`) +
              (renewal.dayOfMonth
                ? `${t('chronoframe.dueDates.detail.dayOfMonth')} ${
                  renewal.dayOfMonth
                }`
                : '')}
            </span>
            {this.state.chronoTask && this.state.chronoTask.parentTask && (
              <span>
                <Button
                  variant={'primary'}
                  size={'sm'}
                  onClick={async () => {
                    await this.props.history.replace(
                      `${window.location.pathname
                        .split('/')
                        .slice(
                          0,
                          window.location.pathname.split('/').length - 1,
                        )
                        .join('/')}/${this.state.chronoTask!.parentTask!.uuid}`,
                    )
                    await this.props.fetchDetail(
                      this.state.chronoTask!.parentTask!.uuid,
                      this.props.values,
                    )
                  }}
                  label={t('chronoframe.dueDates.formField.previousRepetition')}
                />
              </span>
            )}
          </>
        ) : (
          <span>{t('chronoframe.dueDates.detail.no')}</span>
        )
      default:
        // tslint:disable-next-line:no-console
        console.warn('Field type', field.type, 'not supported')
        return '---'
    }
  }

  private async handleSubmitNewCreation(data: FormData) {
    return this.props.submitNewCreation(data).then(result => {
        const { id } = result[0]
        this.props.history.push(this.props.history.location.pathname.split('/').slice(0, -1).join('/') + `/${id}`)
        return result
      },
    )
  }

  private getDataAllowedForNewCreation(data: ChronoTask | undefined): FormData | undefined {
    if (data !== undefined) {
      const newData:FormData = { ...data }
      delete newData.id
      delete newData.attachments
      delete newData.dueAtDate
      delete newData.effectiveFromDate
      return newData
    }
  }

  private async handleSubmit(data: FormData) {
    return this.props.submitData({ ...data, chronoTaskId: data.id }, Action.edit)
      .then(result => this.fetchTaskDetail()
        .then(taskDetail => {
          this.handleFetchTaskDetail(taskDetail)
          return result
        }))
  }


  private handleTemplateSubmit(data: FormData) {
    return this.props.submitTemplate(data, this.props.values)
  }

  private getInputValue(slug: string) {
    return getInputValueFromData(slug, this.state.chronoTask)
  }

  private async changeStatus(data: FormData) {
    if (this.state.chronoTask) {
      const dataToSend = {
        ...data,
        chronoTaskId: data.id,
        assigneeUser: this.state.chronoTask.assigneeUser,
        inChargeUser: this.state.chronoTask.inChargeUser,
        target: this.state.chronoTask.target,
        actionIds: [
          this.state.chronoTask.actionToUndo
            ? this.state.chronoTask.actionToUndo.id
            : null,
        ],
        actionId: this.state.chronoTask.actionToUndo
          ? this.state.chronoTask.actionToUndo.id
          : null,
        lastActionId: this.getInputValue('lastAction')
          ? this.getInputValue('lastAction').id
          : null,
      }
      return this.props.changeStatus(
        this.state.currentAction!,
        dataToSend,
      ).then(() => {
        this.fetchData()
      })
    }
  }

  private getPageSlug() {
    return window.location.pathname.split('/')[2]
  }

  private getBackUrl() {
    return window.location.pathname.replace(
      `/${this.props.match.params.id}`,
      '',
    )
  }

  private async handleGoToTask(id: string) {
    await this.props.history.push(`/scadenziario/${this.state.pageSlug}/${id}`)
  }

  private createNewFromThis() {
    this.setState({ showModalCreation: true })
  }

  /*private hasPermission(...actions: string[]) {
    // TEMPORARY!!!
    // add actions here
    const forbiddenActions =
      this.props.pageSlug === 'manager'
        ? [
          'scadenziario.operational.chronoTask.assignToMe', // assegna a me
          'scadenziario.operational.chronoTask.assignTo', // assegna a qualcun'altro
          'scadenziario.operational.chronoTask.reschedule', // rischedula
          // 'scadenziario.operational.chronoTask.done', // era commentato,
          'scadenziario.operational.chronoTask.validated', // valida
        ]
        : [
          'scadenziario.manager.chronoTask.create', // crea task
          'scadenziario.manager.chronoTask.edit', // modifica task
          'scadenziario.manager.chronoTask.reject', // rifiuta task ?
          'scadenziario.manager.chronoTask.cancel', // cancella task
          'scadenziario.manager.chronoTask.undo', // undo task ? ultimi 3 aggiunti da testare
          'scadenziario.manager.chronoTask.validate', // valida task
          'scadenziario.manager.chronoTask.failValidation', // validazione fallita ?
        ]

    return !actions.some(action =>
      forbiddenActions.includes(`scadenziario.${action}`),
    )
    // return !actions.some(action => this.props.forbiddenActions.includes(`scadenziario.${action}`))
  }*/


  private getStatusActions(actions: string[]) {
    const availableActions: Array<{
      label: string
      onClick: () => void
    }> = []

    actions.forEach((action) => {
      if (this.state.configAvailableActions.includes(action)) {
        availableActions.push({
          label: this.props.t(`chronoframe.tasks.actions.${action}`),
          onClick: () => action === 'create'
            ? this.createNewFromThis()
            : action === 'edit'
              ? this.toggleFormModal()
              : this.toggleActionModal(action),
        })
      }
    })
    return availableActions
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(TaskDetailPageView)),
)
