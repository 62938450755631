const en = {
  assetManager: {
    navigation: {
      asset: 'Asset',
      assetList: 'Asset list',
    },
  },
}

export default en
