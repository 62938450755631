import * as moment from "moment";
import { ListParams } from "./store";
import duration from "../functions/duration";

type PlantHaltId = string;

export interface PlantHaltCause {
  type: number;
  key: string;
  name: string;
  detail: string | null;
  isBulky: boolean;
  requiresAsset: boolean;
  requiresNote: boolean;
  requiresValidation: boolean;
  isTypeReadOnly: boolean;
  isDetailReadOnly: boolean;
  isAssetReadOnly: boolean;
  showDetailInTable: boolean;
}

export interface PlantHaltCauseType {
  type: number;
  name: string;
}

export interface PlantAsset {
  id: string;
  name: string;
}

export interface PlantHaltFilters {
  source?: string;
  assetId?: string;
  type?: string;
  causeKey?: string;
  causeType?: string;
  from?: string;
  to?: string;
  bulky?: boolean;
  code?: number;
  confirmed?: boolean;
  onlyUnjustified?: boolean;
  searchString?: string;
}

export type PlantHaltListParams = ListParams & PlantHaltFilters;

export interface PlantHaltsProps {
  timeHalted: number;
  numHalted: number;
  timePaused: number;
  numPaused: number;
  totalUnjustified: number;
  totalUnjustifiedPast: number;
  timeEmpty: number;
  numEmpty: number;
  timeManMorningDelay: number;
  totalManMorningDelay: number;
  timeManPauseDelay: number;
  totalManPauseDelay: number;
  totalTime: number;
  totalNum: number;
  halts: PlantHaltProps[];
}

export interface PlantHalts {
  timeHalted: number;
  numHalted: number;
  timePaused: number;
  numPaused: number;
  totalUnjustified: number;
  totalUnjustifiedPast: number;
  timeEmpty: number;
  numEmpty: number;
  timeManMorningDelay: number;
  totalManMorningDelay: number;
  timeManPauseDelay: number;
  totalManPauseDelay: number;
  totalTime: number;
  totalNum: number;
  halts: PlantHalt[];
}

export interface PlantHaltProps {
  id: PlantHaltId;
  from: string;
  to: string;
  duration: number;
  causeKey: string;
  asset: PlantAsset | null;
  notes: string;
}

export interface PlantHaltsConfirmedBy {
  id: string;
  name: string;
}

export interface SidebarCounter {
  pageSlug?: string;
  color?: string;
  count?: number;
  alwaysVisible?: boolean;
}

export class PlantHalt {
  // ts ignores since the constructor populates all attributes
  // @ts-ignore
  public id: PlantHaltId;
  // @ts-ignore
  public from: moment.Moment;
  // @ts-ignore
  public to: moment.Moment;
  // @ts-ignore
  public duration: string;
  // @ts-ignore
  public type: number;
  // @ts-ignore
  public causeKey: string;
  // @ts-ignore
  public cause: PlantHaltCause | null;
  // @ts-ignore
  public asset: PlantAsset | null;
  // @ts-ignore
  public assetFromCode: string | null;
  // @ts-ignore
  public notes: string;
  // @ts-ignore
  public code: string;
  // @ts-ignore
  public isAssetReadOnly: boolean;
  // @ts-ignore
  public confirmedBy: PlantHaltsConfirmedBy | null;
  // @ts-ignore
  public confirmedDate: string | null;
  // @ts-ignore
  public requiresValidation: boolean | null;
  // @ts-ignore
  public isHaltReadonly: boolean | null;
  // @ts-ignore
  public requiresNote: boolean;
  // @ts-ignore
  public isUnjustified: boolean;
  // @ts-ignore
  public isUnjustifiedPast: boolean;

  constructor(data: PlantHaltProps | PlantHalt | object) {
    Object.keys(data).forEach(key => {
      if (
        !data[key] &&
        key !== "isUnjustified" &&
        key !== "requiresNote" &&
        key !== "isUnjustifiedPast"
      ) {
        this[key] = null;
      } else if (key === "from" || key === "to") {
        this[key] = moment(data[key]);
      } else if (key === "duration" && typeof data[key] === "number") {
        this[key] = duration(data[key] as number);
      } else {
        this[key] = data[key];
      }
    });
  }
}
