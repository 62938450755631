import * as React from 'react'
import * as moment from 'moment'
import { withTranslation, WithTranslation } from 'react-i18next'
import { API } from '../../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { isJSON, Loader } from '../../../../../functions/shared'
import {
  TableRowFullWrapperWithCondition, TableRowWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'

interface OwnProps {
  config: {
    widgetName: string
    tableName: string
    hideSummaryTable?: boolean
  }
  dateEnd: string
  dateStart: string
  plant: any | null
  slotHours: number
}

interface RppData {
  key: string
  parentKey: string
  datetime: string[]
  totalWeight: number
  weight: number[]
  totalBales: number
  bales: number[]
  perc: number
}

interface ReportsTotals {
  datetime: string
  perc: number
  weight: number
  bales: number
}

interface RppTotals {
  perc: number
  weight: number
  bales: number
  reports: ReportsTotals[]
}

interface RppTotalsByCat {
  key: string
  perc: number
  specs: boolean
}

interface OwnState {
  rppData: RppData[]
  rppTotals?: RppTotals
  rppTotalsByCat: RppTotalsByCat[]
  citWeight: number
  citBales: number
  isFetching: boolean
  fetchErrors: boolean
}

const cleanState = {
  rppData: [],
  rppTotals: undefined,
  rppTotalsByCat: [],
  citWeight: 0,
  citBales: 0,
  isFetching: false,
  fetchErrors: false,
}

type Props = OwnProps & WithTranslation

class RppGraphMainSG2View extends React.Component<Props, OwnState> {
  // @ts-ignore
  private mounted: boolean
  private abortController: AbortController = new AbortController()
  private thRppTableHeight: number[] = []

  constructor(props: Props) {
    super(props)
    this.state = cleanState
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.state.isFetching &&
      nextProps &&
      this.props &&
      !nextProps.plant.isLoading &&
      (nextProps.plant.plant !== this.props.plant.plant ||
        (nextProps.dateStart && nextProps.dateStart !== this.props.dateStart))
    ) {
      this.setState(cleanState, () => this.getData(this.props))
    }
  }

  public render() {
    const { config, t, slotHours } = this.props
    const { rppData, rppTotals, rppTotalsByCat, isFetching, fetchErrors, citWeight } = this.state
    const RppTable = (props: { style?: any }) => {
      return (<>
        <table
          style={props.style}
          className='table table-borderless table-sm table-responsive-sm w-full rpp-graph-specs sm-device table-striped'
        >
          <thead>
          <tr className='rpp-graph-specs-tr'>
            <th>{t('plantAnalysis.labels.rppGraph.valueProd')}</th>
            <th className='text-right'>
              {rppTotalsByCat
                .reduce((val: number, current: RppTotalsByCat) => {
                  if (current.specs) {
                    val += current.perc
                  }
                  return val
                }, 0)
                .toFixed(2)}{' '}
              %
            </th>
          </tr>
          </thead>
          <tbody>
          {rppTotalsByCat.map((rppTotalByCat, idx) => (
            <tr key={`rppTotalsByCat-${idx}`}>
              <td className={`bg-rpp bg-rpp-${rppTotalByCat.key}`}>
                {t(`plantAnalysis.labels.rppGraph.keys.${rppTotalByCat.key}`, {
                  defaultValue: rppTotalByCat.key,
                })}
              </td>
              <td className={`text-right bg-rpp-last bg-rpp-${rppTotalByCat.key}`}>
                {rppTotalByCat.perc.toFixed(2)} %
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </>)
    }
    const noData =
      !isFetching && !fetchErrors && (rppData.length === 0 || rppTotals === undefined || rppTotalsByCat.length === 0)
    return (
      <>
        <TableRowFullWrapperWithCondition condition={isFetching}>
          <div className='alert alert-secondary w-100 col-sm-6 mx-auto rpp-graph-fetch-loading alert-local'>
            {this.props.t('plantAnalysis.loading')}
            <Loader />
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={noData}>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto rpp-graph-fetch-warning alert-local'>
            {this.props.t('plantAnalysis.noDataAvailable')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition condition={!isFetching && fetchErrors}>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bagspeed-graph-fetch-error alert-local'>
            {this.props.t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowWrapperWithCondition condition={!isFetching && !noData && !fetchErrors && rppTotals !== undefined}>
          <TableColumnWrapper col={'half'} className={'rpp-row-item px-3'}>
            <table className='table table-borderless table-md w-auto rpp-graph-main table-striped'>
              <thead ref={e => (this.thRppTableHeight[0] = e ? e.offsetHeight : 0)}>
              <tr>
                <th />
                <th className='text-right'>%</th>
                <th className='text-right'>{t('plantAnalysis.labels.rppGraph.weight')}</th>
                <th className='text-right'>{t('plantAnalysis.labels.rppGraph.numBales')}</th>
                {rppTotals && rppTotals.reports.map((rppTotal, idx) => (
                  <th className='text-right' key={`th-reports-rpp-${idx}`}>
                    <p className='th-inline-first-p'>
                      {t('plantAnalysis.labels.rppGraph.report', { num: idx + 1 })}
                    </p>
                    <p className='th-inline-second-p'>{moment(rppTotal.datetime).format('HH:mm')}</p>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              <tr ref={e => (this.thRppTableHeight[1] = e ? e.offsetHeight : 0)}>
                <td>{t('plantAnalysis.labels.rppGraph.averageWeightHourSG2')}</td>
                <td />
                <td className='text-right'>
                  {rppTotals && parseFloat(((rppTotals.weight - citWeight) / slotHours).toFixed(3)).toLocaleString('it')}{' '}
                  {t('plantAnalysis.labels.rppGraph.tonHour')}
                </td>
                <td />
                {rppTotals?.reports.map((rpp, idx) => (
                  <td className='text-right' key={`td-empty-average-weight-hour-rpp-bales-${idx}`} />
                ))}
              </tr>
              <tr ref={e => (this.thRppTableHeight[2] = e ? e.offsetHeight : 0)}>
                <td>{t('plantAnalysis.labels.rppGraph.totalWeight')}</td>
                <td />
                <td className='text-right'>
                  <b>
                    {rppTotals?.weight.toLocaleString('it')} {t('plantAnalysis.labels.rppGraph.ton')}
                  </b>
                </td>
                <td />
                {rppTotals?.reports.map((rpp, idx) => (
                  <td className='text-right' key={`td-rpp-rpoerts-weight-${idx}`}>
                    {rpp.weight.toLocaleString('it')} kg
                  </td>
                ))}
              </tr>
              <tr ref={e => (this.thRppTableHeight[3] = e ? e.offsetHeight : 0)}>
                <td>{t('plantAnalysis.labels.rppGraph.totalBales')}</td>
                <td />
                <td />
                <td className='text-right'>
                  <b>{rppTotals?.bales}</b>
                </td>
                {rppTotals?.reports.map((rpp, idx) => (
                  <td className='text-right' key={`td-rpp-rpoerts-bales-${idx}`}>
                    {rpp.bales}
                  </td>
                ))}
              </tr>
              <tr className='tr-material-second-header'>
                <td>{t('plantAnalysis.labels.rppGraph.material')}</td>
                <td className='text-right'>%</td>
                <td className='text-right'>{t('plantAnalysis.labels.rppGraph.weight')}</td>
                <td className='text-right'>{t('plantAnalysis.labels.rppGraph.numBales')}</td>
                {rppTotals?.reports.map((rppTotal, idx) => (
                  <td className='text-right' key={`th-reports-rpp-${idx}`}>
                    {t('plantAnalysis.labels.rppGraph.report', { num: idx + 1 })}
                  </td>
                ))}
              </tr>
              {rppData.map((rpp, idx) => (
                <tr key={`td-rpp-data-${idx}-${rpp.key}`} className='tr-body-data-mapped'>
                  <td className={`bg-rpp bg-rpp-${rpp.parentKey}`}>
                    {t(`plantAnalysis.labels.rppGraph.keys.${rpp.key}`, { defaultValue: rpp.key })}
                  </td>
                  <td className='text-right'>
                    <b>{`${rpp.perc.toFixed(2)}%`}</b>
                  </td>
                  <td className='text-right'>
                    <b>{`${rpp.totalWeight.toLocaleString('it')} kg`}</b>
                  </td>
                  <td className='text-right'>
                    <b>{rpp.totalBales}</b>
                  </td>
                  {rpp.bales.map((baleNum, idxB) => (
                    <td
                      className={`text-right ${idxB === rpp.bales.length - 1 &&
                      `bg-rpp-last bg-rpp-${rpp.parentKey}`}`}
                      key={`td-bales-material-${idxB}-${idx}`}
                    >
                      {baleNum}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </TableColumnWrapper>
          <WithConditionWrapper condition={!config.hideSummaryTable}>
            <TableColumnWrapper col={'half'} className={'rpp-row-item px-3'}>
              <RppTable style={{ marginTop: this.thRppTableHeight.reduce((acc, val) => acc + val, 0) }} />
              <RppTable />
            </TableColumnWrapper>
          </WithConditionWrapper>
        </TableRowWrapperWithCondition>
      </>
    )
  }

  private async getData(props: Props) {
    if (!this.state.isFetching && props && props.dateStart && props.dateEnd) {
      this.setState({ isFetching: true })
      const mockedConfig = {
        cpl: {
          cit: ['ctl', 'cta', 'ctc', 'cte'],
          specs: true,
        },
        film: {
          cit: ['film-manual', 'film-special', 'film-aggregate'],
          specs: true,
        },
        mpr: {
          cit: ['mpr'],
          specs: true,
        },
        mpoc: {
          cit: ['mpoc'],
          specs: true,
        },
        ipp: {
          cit: ['ipp'],
          specs: true,
        },
        vpet: {
          cit: ['vpet'],
          specs: true,
        },
        ips: {
          cit: ['ips'],
          specs: true,
        },
        rpo: {
          cit: ['rpo'],
          specs: true,
        },
        flex: {
          cit: ['flex-manual', 'flex-c', 'flex-normal', 'flex-np', 'flex-ballistic', 'flex-sifted'],
          specs: false,
        },
        plasmix: {
          cit: ['plasmix-bulky', 'plasmix-end-line', 'plasmix-extra', 'plasmix-fine'],
          specs: false,
        },
      }
      try {
        const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''
        const query = `SELECT * FROM ${props.config.tableName} WHERE time >= '${moment(props.dateStart)
          .tz('Europe/Rome')
          .format('YYYY-MM-DD HH:mm:ss')}' and time <= '${moment(props.dateEnd)
            .tz('Europe/Rome')
            .format('YYYY-MM-DD HH:mm:ss')}'`
        const result = await API().request(`/query?${plantQuery}q=${query}`, {
          signal: this.abortController.signal,
        })
        const json = isJSON(result) && JSON.parse(result)
        const dataJson =
          json &&
          json.results &&
          json.results[0] &&
          json.results[0].series &&
          json.results[0].series[0]
        const columns = dataJson && dataJson.columns
        const values = dataJson && dataJson.values
        const keys: string[] = Array.from(
          new Set(
            columns.reduce((acc: string[], current: string) => {
              const key = current.split('_')[0]
              if (!['time', 'dummy'].includes(key)) {
                acc.push(key)
              }
              return acc
            }, []),
          ),
        )
        if (!(keys && columns && values)) {
          throw new Error('Invalid data')
        }
        let totalWeight = 0
        let totalBales = 0

        const totalReports: { [k: string]: ReportsTotals } = {}
        const rppData: RppData[] = keys.reduce((acc: RppData[], key: string) => {
          let parentKey: string
          Object.entries(mockedConfig).forEach(value => {
            if (value[1].cit.includes(key)) {
              parentKey = value[0]
            }
          })
          const rppdata: RppData = values.reduce(
            (rpp: RppData, current: any[]) => {
              const time = current[columns.indexOf('time')]
              if (!totalReports[time]) {
                totalReports[time] = {
                  datetime: time,
                  bales: 0,
                  perc: 0,
                  weight: 0,
                }
              }
              const indexOfBales = columns.indexOf(`${key}_bales`)
              const indexOfWeight = columns.indexOf(`${key}_weight`)
              const weight = current[indexOfWeight]
              const bales = current[indexOfBales]
              rpp.weight.push(weight)
              rpp.bales.push(bales)
              rpp.totalBales += bales
              rpp.totalWeight += weight
              rpp.datetime.push(time)
              rpp.parentKey = parentKey
              totalWeight += weight
              totalBales += bales
              totalReports[time].weight += weight
              totalReports[time].bales += bales

              if (key === 'cit') {
                this.setState({
                  citWeight: weight / 1000,
                  citBales: bales,
                })
              }
              return rpp
            },
            {
              key,
              datetime: [],
              totalWeight: 0,
              totalBales: 0,
              bales: [],
              weight: [],
              perc: 0,
            },
          )
          acc.push(rppdata)
          return acc
        }, [])
        rppData.forEach(rpp => (rpp.perc = (rpp.totalWeight * 100) / totalWeight))
        const rppTotalsByCat: RppTotalsByCat[] = Object.entries(
          Object.keys(mockedConfig).reduce((acc: { [k: string]: RppTotalsByCat }, key: string) => {
            if (!acc[key]) {
              acc[key] = {
                key,
                perc: 0,
                specs: mockedConfig[key].specs,
              }
            }
            acc[key].perc = mockedConfig[key].cit.reduce((total: number, k: string) => {
              const rpp = rppData.find(obj => obj.key === k)
              total += rpp ? rpp.perc : 0
              return total
            }, 0)
            return acc
          }, {}),
        ).reduce((acc: RppTotalsByCat[], current) => {
          acc.push(current[1] as RppTotalsByCat)
          return acc
        }, [])
        const rppTotals: RppTotals = {
          perc: 100,
          weight: totalWeight / 1000,
          bales: totalBales,
          reports: Object.keys(totalReports).reduce((reports: ReportsTotals[], key: string) => {
            reports.push(totalReports[key])
            return reports
          }, []),
        }
        if (!(rppData && rppTotalsByCat && rppTotals)) {
          throw new Error('Invalid data')
        }
        if (this.mounted) {
          this.setState({
            rppData: rppData.filter(rpp => rpp.totalBales > 0 && rpp.totalWeight > 0 && rpp.perc > 0),
            rppTotals,
            rppTotalsByCat,
            isFetching: false,
            fetchErrors: false,
          })
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isFetching: false,
            fetchErrors: error.name === 'FetchError',
            rppData: [],
            rppTotalsByCat: [],
            rppTotals: undefined,
          })
        }
      }
    }
  }
}

export default withTranslation()(RppGraphMainSG2View)
