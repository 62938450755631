import * as React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { HHHmmssFromMilliseconds, secondsToHMS } from '../../../../../functions/shared'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Configurations } from '../../../../../redux/reducers/configurations'
import { connect } from 'react-redux'
import { defaultPlantAnalysisGeneralSettings } from '../../../../../types/settings'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'
import TableRowWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

export interface PieDataItem {
  perc: number
  duration: number
  chartLabel?: string
  color: string
  key: string
}

interface OwnProps {
  data: PieDataItem[]
  hideLabels?: boolean
  totalsLabel?: string
  totalsReference?: {
    seconds: number
    label: string
  }
  customSettingsFieldsCode?: '308*'
}

interface StateProps {
  plant: any | null
  configurations: null | Configurations
}

const mapStateToProps = (state: any): StateProps => ({
  plant: state.plantSelector || null,
  configurations: state.plantAnalysis.configurations,
})

type Props = OwnProps & WithTranslation & StateProps

class PercentsPieView extends React.PureComponent<Props> {
  public render() {
    const { data, hideLabels, t, totalsLabel, totalsReference } = this.props

    if (data.length < 1) {
      return null
    }

    const totalTime = data.reduce((acc, current) => acc + current.duration, 0)
    const formatTime = (value: number) => HHHmmssFromMilliseconds(value, false, true, true)

    return (
      <TableWrapperUtilities>
        <TableRowWrapper>
          <TableColumnWrapper col={'half'}>
            <TableWrapperUtilities>
              <TableRowWrapper>
                <TableColumnWrapper col={'half'}>
                  <ResponsiveContainer width={150} height={150} className='recharts-responsive-container mx-auto'>
                    <PieChart width={150} height={150}>
                      <Pie
                        data={data}
                        labelLine={false}
                        outerRadius={70}
                        fill='#8884d8'
                        dataKey='perc'
                        cx={75}
                        cy={75}
                        isAnimationActive={false}
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color && entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </TableColumnWrapper>
                <TableColumnWrapper col={'half'}>
                  <table
                    className={'table table-borderless table-sm w-100 mw-300 font-size-12 table-first-column-color'}>
                    <thead>
                    <tr>
                      <th className={'auto-dimention'} />
                      <th className='text-right auto-dimention'>%</th>
                      <th className='text-right auto-dimention'>{t('plantAnalysis.halts.labels.time')}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {data.map(datum => (
                      <tr key={datum.key}>
                        <td className={'auto-dimention'}>
                          <span className='label-color-square' style={{ backgroundColor: datum.color }} />
                          {!hideLabels && (
                            <span>
                              {t(`plantAnalysis.labels.${datum.chartLabel ? datum.chartLabel + '.' : ''}${datum.key}`, {
                                defaultValue: this.getReferenceValueFromSettings(datum.key),
                              })}
                            </span>
                          )}
                        </td>
                        <td className='text-right auto-dimention'>{datum.perc ? datum.perc.toFixed(2) : 0}</td>
                        <td className='text-right auto-dimention'>{formatTime(datum.duration * 1000)}</td>
                        <td className={'auto-dimention'} />
                      </tr>
                    ))}
                    {totalsLabel && (
                      <tr key={'total'} className='font-bold'>
                        <td className={'auto-dimention'}>
                          <span className='label-color-square'
                                style={{ backgroundColor: 'transparent' }} /> {totalsLabel}
                        </td>
                        <td className={'auto-dimention'} />
                        <td className={'auto-dimention'} />
                        <td className='text-right auto-dimention'>{secondsToHMS(totalTime)}</td>
                        <td className={'auto-dimention'}>
                          {totalsReference && totalsReference.seconds && (
                            <span className='text-warning' data-total={totalTime} data-ref={totalsReference.seconds}>
                        {formatTime((totalTime - totalsReference.seconds) * 1000)}
                      </span>
                          )}
                        </td>
                      </tr>
                    )}

                    {totalsReference && (
                      <tr key={'total-reference'}>
                        <td className={'auto-dimention'}>
                          <span className='label-color-square' style={{ backgroundColor: 'transparent' }} />{' '}
                          {totalsReference.label}
                        </td>
                        <td className={'auto-dimention'} />
                        <td className={'auto-dimention'} />
                        <td className='text-right auto-dimention'>{formatTime(totalsReference.seconds * 1000)}</td>
                        <td className={'auto-dimention'} />
                      </tr>
                    )}
                    </tbody>
                  </table>

                </TableColumnWrapper>
              </TableRowWrapper>
            </TableWrapperUtilities>
          </TableColumnWrapper>
        </TableRowWrapper>
      </TableWrapperUtilities>
    )
  }

  private getCurrentConfiguration() {
    const { configurations, plant } = this.props
    return (
      (configurations && configurations.data && configurations.data && plant && configurations.data[plant.plant]) ||
      defaultPlantAnalysisGeneralSettings
    )
  }

  private getReferenceValueFromSettings(key: string) {
    let out: string | number | null = null
    const configuration = this.getCurrentConfiguration()
    let additionalSlug = ''
    if (this.props.customSettingsFieldsCode) {
      additionalSlug = this.props.customSettingsFieldsCode
    }
    switch (key) {
      case 'under':
      case 'over':
        out =
          (configuration &&
            configuration.settingsFields &&
            configuration.settingsFields[`soglia_deviazione${additionalSlug}`]) ||
          null
        break
      default:
        break
    }

    return out
  }
}

export default connect(mapStateToProps, {})(withTranslation()(PercentsPieView))
