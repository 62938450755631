export interface NodeComponent {
  id: string
  type: string
  label: string
}

export interface NodeMeasures {
  [k: string]: string[]
}

export interface GeneralData {
  data: any
  fetching: boolean
  error: boolean
}
// @todo UNUSED
export const componentsMeasures: NodeMeasures = {
  PLANT_DATA_BELT: ['BS_LOC', 'BS_MAN', 'BS_AUTO', 'BS_DIR'],
  PLANT_DATA_BELT_INVERTER: [
    'BS_LOC',
    'BS_MAN',
    'BS_AUTO',
    'BS_BYP_SEL',
    'BS_DRV_SEL',
    'BS_DIR',
    'WS_SET_SPD',
    'WS_ACT_CURR',
  ],
  PLANT_DATA_BNK_INVERTER: [
    'BS_LOC',
    'BS_MAN',
    'BS_AUTO',
    'BS_BYP_SEL',
    'BS_DRV_SEL',
    'BS_DISCH_RUN',
    'BS_MAT_LEV_FULL_DLY',
  ],
  PLANT_DATA_ASP: [
    'BS_LOC',
    'BS_MAN',
    'BS_AUTO',
    'BS_BYP_SEL',
    'BS_DRV_SEL',
    'WS_SET_SPD',
    'WS_ACT_CURR',
    'WS_ACT_TEMP',
  ],
  PLANT_DATA_BRT: ['BS_LOC', 'BS_SERV', 'BS_AUTO', 'WS_PER_NUM', 'WS_WRK_MODE', 'WS_CYC_ACT'],
  PLANT_DATA_ROT_SCR: [
    'BS_LOC',
    'BS_MAN',
    'BS_AUTO',
    'BS_BYP_SEL',
    'BS_DRV_SEL',
    'BS_CLEAN',
    'WS_SET_SPD',
    'WS_ACT_CURR',
  ],
  PLANT_DATA_OPT: ['BS_LOC', 'BS_MAN', 'BS_AUTO'],
  PLANT_DATA_INTERF: ['BS_LOC', 'BS_MAN', 'BS_AUTO'],
}

export const SectionNodeTypes = {
  aspiratori: ['PLANT_DATA_ASP', 'PLANT_DATA_ASP_INVERTER'],
  nastri: [
    'PLANT_DATA_BLT',
    'PLANT_DATA_BELT',
    'PLANT_DATA_BELT_INVERTER',
    'PLANT_DATA_ROT_SCR',
    'PLANT_DATA_ROT_SCR_INVERTER',
  ],
  bunker: ['PLANT_DATA_BNK_INVERTER', 'PLANT_DATA_BNK'],
}

export interface ModelNode {
  data: ModelNodeData
  subcomponents: ModelNode[]
}

export interface ModelNodeData {
  nodeType: string
  code: string
  id: string
  label: string
}
