import { AssetsListFilters, ListPagination } from '../../types/asset'
import { API } from './index'
import {
  FetchAssetsListResponse,
  FetchDeactivatedAssetsListResponse,
  FetchSitesGrousModulesResponse,
} from '../../types/fetchData'
import { Dispatch, ActionCreator } from 'redux'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'


export const ASSETS_LIST_SET_FILTERS = 'ASSETS_LIST_SET_FILTERS'
export const ASSETS_LIST_SET_PAGINATION = 'ASSETS_LIST_SET_PAGINATION'
export const ASSETS_LIST_DEACTIVATED_SET_FILTERS = 'ASSETS_LIST_DEACTIVATED_SET_FILTERS'
export const ASSETS_LIST_DEACTIVATED_SET_PAGINATION = 'ASSETS_LIST_DEACTIVATED_SET_PAGINATION'
export const ASSETS_SET_COUNT_TO_VALIDATE = 'ASSETS_SET_COUNT_TO_VALIDATE'

interface ApplyListSetFilters {
  type: typeof ASSETS_LIST_SET_FILTERS
  params: AssetsListFilters
}

interface ApplyAssetsListPagination {
  type: typeof ASSETS_LIST_SET_PAGINATION
  params: ListPagination
}

interface ApplyAssetsListDeactivatedFilters {
  type: typeof ASSETS_LIST_DEACTIVATED_SET_FILTERS
  params: AssetsListFilters
}

interface ApplyAssetsListDeactivatedPagination {
  type: typeof ASSETS_LIST_DEACTIVATED_SET_PAGINATION
  params: ListPagination
}

interface SetCountToValidate {
  type: typeof ASSETS_SET_COUNT_TO_VALIDATE
  count: number
}

export const fetchAssetsList = async (pageType: string, abortController: AbortController, filters?: AssetsListFilters, pagination?: ListPagination) => {

  const queryParams = pagination && Object.keys(pagination).reduce((acc: string[], key: string) => {
    if (pagination[key]) {
      acc.push(`${key}=${pagination[key]}`)
    }
    return acc
  }, []).join('&')

  const filtersObj = filters ? Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {}) : {}
  const query = `/assets/${pageType}${queryParams ? `?${queryParams}` : ''}`

  const results: FetchAssetsListResponse = await API().request(query, {
    signal: abortController.signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        ...filtersObj,
      },
    ),
  })
  return results
}

export const exportData = (pageType: string, filters?: AssetsListFilters) => (dispatch: Dispatch) => {
  const filtersObj = filters ? Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {}) : {}
  const query = `/export/assets/${pageType}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        ...filtersObj,
      },
    ),
  }, undefined, undefined, ['content-disposition']).then((result: any) => {
    return result
  })
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const fetchDeactivatedAssetListPaginated = async (abortController: AbortController, filters?: AssetsListFilters, pagination?: ListPagination) => {
  const queryParams = pagination && Object.keys(pagination).reduce((acc: string[], key: string) => {
    if (pagination[key]) {
      acc.push(`${key}=${pagination[key]}`)
    }
    return acc
  }, []).join('&')

  const filtersObj = filters ? Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {}) : {}
  const query = `/assets/list-deactivated-assets${queryParams ? `?${queryParams}` : ''}`

  const results: FetchDeactivatedAssetsListResponse = await API().request(query, {
    signal: abortController.signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        ...filtersObj,
      },
    ),
  })
  return results
}


export const fetchDeactivatedAssetListForPlant = async (plantCode: string) => {
  const query = `/assets/deactivated-assets-for-plant/${plantCode}`
  return API().request(query)
}

export const fetchDeactivatedAssetsList = () => {
  return API().request('/assets/deactivated-assets')
}

export const applyAssetsListFilters: ActionCreator<ApplyListSetFilters> = (params: AssetsListFilters): ApplyListSetFilters => ({
  type: ASSETS_LIST_SET_FILTERS,
  params,
})

export const applyAssetsListPagination: ActionCreator<ApplyAssetsListPagination> = (params: ListPagination): ApplyAssetsListPagination => ({
  type: ASSETS_LIST_SET_PAGINATION,
  params,
})

export const applyAssetsListDeactivatedFilters: ActionCreator<ApplyAssetsListDeactivatedFilters> = (params: AssetsListFilters): ApplyAssetsListDeactivatedFilters => ({
  type: ASSETS_LIST_DEACTIVATED_SET_FILTERS,
  params,
})

export const applyAssetsListDeactivatedPagination: ActionCreator<ApplyAssetsListDeactivatedPagination> = (params: ListPagination): ApplyAssetsListDeactivatedPagination => ({
  type: ASSETS_LIST_DEACTIVATED_SET_PAGINATION,
  params,
})

export const setCountToValidate: ActionCreator<SetCountToValidate> = (count: number): SetCountToValidate => ({
  type: ASSETS_SET_COUNT_TO_VALIDATE,
  count,
})

export const fetchSitesGroupsModules = async () => {
  const query = '/assets/page-params'
  const results: FetchSitesGrousModulesResponse = await API().request(query)
  return results
}

export const fetchCountToValidate = (): ((dispatch: Dispatch) => Promise<any>) => {
  return async (dispatch: Dispatch) => {
    const query = '/assets/count-to-validate'
    try {
      const { count }: { count: number } = await API().request(query)
      dispatch(setCountToValidate(count))
    } catch (e) {
      console.log(e) //tslint:disable-line
    }
  }
}

export type  AssetsListActions =
  ApplyListSetFilters
  | ApplyAssetsListPagination
  | ApplyAssetsListDeactivatedFilters
  | ApplyAssetsListDeactivatedPagination
  | SetCountToValidate