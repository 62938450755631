import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { Moment } from 'moment'
import { COLORS } from '../../../../constants'
import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import { Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader, populateSingleData } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '../../../../functions/series'
import FanGraphMain from './FanGraphMain'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  dataReady: boolean
  data: { [key: string]: Measure }
  last: any
  lastAfter: any
  isCollapsed: boolean
  brush1Start: number
  brush1End: number
  brush1StartInd: number
  brush1EndInd: number
  mergedData: undefined | any[]
  filteredData: any[]
  startDate: string | number
  endDate: string | number
}

interface OwnProps {
  toggleCollapse: (id: string) => void
  active: string
  component: any
  inverter: boolean
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  dateFilterStart: Moment
  workshift: Workshift
  plant: string | null
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  plant: state.plantSelector.plant,
})

const cleanState = {
  isLoading: false,
  fetchErrors: false,
  dataReady: false,
  isCollapsed: false,
  data: {
    assorbimento: {
      data: [],
      min: 0,
      max: 0,
    },
    /*inverterDiretta: {
      data: [],
      min: 0,
      max: 0,
    },*/
    manuale: {
      data: [],
      min: 0,
      max: 0,
    },
    setVelocita: {
      data: [],
      min: 0,
      max: 0,
    },
    temperatura: {
      data: [],
      min: 0,
      max: 0,
    },
    marciaFermo: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  last: null,
  lastAfter: null,
  brush1Start: 0,
  brush1End: 0,
  brush1StartInd: 0,
  brush1EndInd: 0,
  mergedData: [],
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
}

type Props = StateProps & OwnProps & WithTranslation

class FanGraphs extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = cleanState

    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce(this.handleBrush1.bind(this),10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props).then(() => {
        this.setState({ isLoading: false })
      })
    }
  }

  public componentWillUnmount() {
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps).then(() => {
        this.setState({ isLoading: false })
      })
    } else {
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(cleanState)

      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const { inverter, t, workshift } = this.props
    const { mergedData, isLoading, dataReady, fetchErrors, filteredData } = this.state
    const isReady = mergedData !== undefined && mergedData.length > 0 && !isLoading
    const noData = mergedData !== undefined && mergedData.length === 0 && !isLoading

    return (
      <React.Fragment>
        <div className="row">
          {isLoading && !dataReady ? (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {t('plantAnalysis.loading')}
              <Loader />
            </div>
          ) : noData && dataReady ? (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
          ) : null}

          {fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto fan-graph-fetch-errors">
              {t('plantAnalysis.fetchErrors')}
            </div>
          )}
          {isReady && (
            <FanGraphMain
              data={mergedData}
              filteredData={filteredData}
              pauses={(workshift && workshift.pauses) || []}
            />
          )}
        </div>

        {isReady && (
          <GraphLineBarTime
            paddingRight={70}
            topMargin={true}
            entry={'manAutoLocSec'}
            colorsId={'manAutoLocSec'}
            filteredData={filteredData}
            i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
          />
        )}

        {isReady && inverter && (
          <GraphLineBarTime
            paddingRight={70}
            topMargin={true}
            entry={'driveBypass'}
            colorsId={'driveBypass'}
            filteredData={filteredData}
            i18nTitle={'plantAnalysis.labels.driveBypass'}
          />
        )}

        {isReady && <GraphTimelineView data={filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && !inverter && <BottomLegend labels={['manAutoLocSec']} />}

        {isReady && inverter && <BottomLegend labels={['manAutoLocSec', 'driveBypass']} />}

        {isReady && inverter && (
          <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1} paddingRight={70}>
            <Line
              dataKey="assorbimento"
              type="step"
              stroke={COLORS.assorbimento}
              dot={false}
              isAnimationActive={false}
            />
          </GraphBrush>
        )}

        {isReady && inverter && <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} paddingRight={60} />}
      </React.Fragment>
    )
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true, fetchErrors: false, dataReady: false })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const measureLabel = props.component.id

      /*
      const reg = new RegExp(/\[(.*)\] (.*)/);
      const measureDetails = props.component.data.label.match(reg);

      const measureLabel = measureDetails[2] + ' ' + measureDetails[1];
       */

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`
      /*const queryLastAfterStart = ` SELECT first("measure") AS "last_measure" FROM "`
      const queryLastAfterEnd = `" WHERE time > '${endDate}' LIMIT 1`*/

      const queryAssorbimento = queryStart + measureLabel + '_WS_ACT_CURR' + queryEnd
      // const queryInverterDiretta = queryStart + measureLabel + '_BS_DRV_SEL' + queryEnd
      const queryDriveBypass = queryStart + measureLabel + '_BS_BYP_SEL' + queryEnd
      const queryManuale = queryStart + measureLabel + '_BS_MAN' + queryEnd
      const queryAutomatico = queryStart + measureLabel + '_BS_AUTO' + queryEnd
      const queryLocaleSezionato = queryStart + measureLabel + '_BS_LOC' + queryEnd
      const querySetVelocita = queryStart + measureLabel + '_WS_SET_SPD' + queryEnd
      const queryTemperatura = queryStart + measureLabel + '_WS_ACT_TEMP' + queryEnd
      const queryMarciaFermo = queryStart + measureLabel + '_BS_DISCH_RUN' + queryEnd
      const queryLastAssorbimento = queryLastStart + measureLabel + '_WS_ACT_CURR' + queryLastEnd
      // const queryLastInverterDiretta = queryLastStart + measureLabel + '_BS_DRV_SEL' + queryLastEnd
      const queryLastDriveBypass = queryLastStart + measureLabel + '_BS_BYP_SEL' + queryLastEnd
      const queryLastManuale = queryLastStart + measureLabel + '_BS_MAN' + queryLastEnd
      const queryLastAutomatico = queryLastStart + measureLabel + '_BS_AUTO' + queryLastEnd
      const queryLastLocaleSezionato = queryLastStart + measureLabel + '_BS_LOC' + queryLastEnd
      const queryLastSetVelocita = queryLastStart + measureLabel + '_WS_SET_SPD' + queryLastEnd
      const queryLastTemperatura = queryLastStart + measureLabel + '_WS_ACT_TEMP' + queryLastEnd
      const queryLastMarciaFermo = queryLastStart + measureLabel + '_BS_DISCH_RUN' + queryLastEnd
      /*const queryLastAfterAssorbimento = queryLastAfterStart + measureLabel + '_WS_ACT_CURR' + queryLastAfterEnd
      // const queryLastAfterInverterDiretta = queryLastAfterStart + measureLabel + '_BS_DRV_SEL' + queryLastAfterEnd
      const queryLastAfterDriveBypass = queryLastAfterStart + measureLabel + '_BS_BS_BYP_SEL' + queryLastAfterEnd
      const queryLastAfterManuale = queryLastAfterStart + measureLabel + '_BS_MAN' + queryLastAfterEnd
      const queryLastAfterAutomatico = queryLastAfterStart + measureLabel + '_BS_AUTO' + queryLastAfterEnd
      const queryLastAfterLocaleSezionato = queryLastAfterStart + measureLabel + '_BS_LOC' + queryLastAfterEnd
      const queryLastAfterSetVelocita = queryLastAfterStart + measureLabel + '_WS_SET_SPD' + queryLastAfterEnd
      const queryLastAfterTemperatura = queryLastAfterStart + measureLabel + '_WS_ACT_TEMP' + queryLastAfterEnd
      const queryLastAfterMarciaFermo = queryLastAfterStart + measureLabel + '_BS_DISCH_RUN' + queryLastAfterEnd*/

      const dataAssorbimentoSrc = await API().request(`/query?${plantQuery}q=` + queryAssorbimento)
      // const dataInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryInverterDiretta)
      const dataDriveBypassSrc = await API().request(`/query?${plantQuery}q=` + queryDriveBypass)
      const dataManualeSrc = await API().request(`/query?${plantQuery}q=` + queryManuale)
      const dataAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryAutomatico)
      const dataLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLocaleSezionato)
      const dataSetVelocitaSrc = await API().request(`/query?${plantQuery}q=` + querySetVelocita)
      const dataTemperaturaSrc = await API().request(`/query?${plantQuery}q=` + queryTemperatura)
      const dataMarciaFermoSrc = await API().request(`/query?${plantQuery}q=` + queryMarciaFermo)
      const dataLastAssorbimentoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAssorbimento)
      // const dataLastInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastInverterDiretta)
      const dataLastDriveBypassSrc = await API().request(`/query?${plantQuery}q=` + queryLastDriveBypass)
      const dataLastManualeSrc = await API().request(`/query?${plantQuery}q=` + queryLastManuale)
      const dataLastAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAutomatico)
      const dataLastLocaleSezionatoSrc = await API().request(`/query?${plantQuery}q=` + queryLastLocaleSezionato)
      const dataLastSetVelocitaSrc = await API().request(`/query?${plantQuery}q=` + queryLastSetVelocita)
      const dataLastTemperaturaSrc = await API().request(`/query?${plantQuery}q=` + queryLastTemperatura)
      const dataLastMarciaFermoSrc = await API().request(`/query?${plantQuery}q=` + queryLastMarciaFermo)
      /*const dataLastAfterAssorbimentoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterAssorbimento)
      // const dataLastAfterInverterDirettaSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterInverterDiretta)
      const dataLastAfterDriveBypassSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterDriveBypass)
      const dataLastAfterManualeSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterManuale)
      const dataLastAfterAutomaticoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterAutomatico)
      const dataLastAfterLocaleSezionatoSrc = await API().request(
        `/query?${plantQuery}q=` + queryLastAfterLocaleSezionato
      )
      const dataLastAfterSetVelocitaSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterSetVelocita)
      const dataLastAfterTemperaturaSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterTemperatura)
      const dataLastAfterMarciaFermoSrc = await API().request(`/query?${plantQuery}q=` + queryLastAfterMarciaFermo)*/

      Promise.all([
        dataAssorbimentoSrc,
        // dataInverterDirettaSrc,
        dataManualeSrc,
        dataAutomaticoSrc,
        dataLocaleSezionatoSrc,
        dataSetVelocitaSrc,
        dataTemperaturaSrc,
        dataMarciaFermoSrc,
        dataLastAssorbimentoSrc,
        // dataLastInverterDirettaSrc,
        dataLastManualeSrc,
        dataLastAutomaticoSrc,
        dataLastLocaleSezionatoSrc,
        dataLastSetVelocitaSrc,
        dataLastTemperaturaSrc,
        dataLastMarciaFermoSrc,
        dataDriveBypassSrc,
        dataLastDriveBypassSrc,
        /*dataLastAfterDriveBypassSrc,
        dataLastAfterManualeSrc,
        dataLastAfterAutomaticoSrc,*/
      ]).then(() => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            assorbimento: { data: parseResponseData(dataAssorbimentoSrc) },
            // inverterDiretta: { data: parseResponseData(dataInverterDirettaSrc) },
            driveBypass: { data: parseResponseData(dataDriveBypassSrc) },
            manuale: { data: parseResponseData(dataManualeSrc) },
            automatico: { data: parseResponseData(dataAutomaticoSrc) },
            localeSezionato: { data: parseResponseData(dataLocaleSezionatoSrc) },
            setVelocita: { data: parseResponseData(dataSetVelocitaSrc) },
            temperatura: { data: parseResponseData(dataTemperaturaSrc) },
            marciaFermo: { data: parseResponseData(dataMarciaFermoSrc) },
          }),
          last: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(dataLastAssorbimentoSrc),
            // inverterDiretta: parseResponseSingleData(dataLastInverterDirettaSrc),
            driveBypass: parseResponseSingleData(dataLastDriveBypassSrc),
            manuale: parseResponseSingleData(dataLastManualeSrc),
            automatico: parseResponseSingleData(dataLastAutomaticoSrc),
            localeSezionato: parseResponseSingleData(dataLastLocaleSezionatoSrc),
            setVelocita: parseResponseSingleData(dataLastSetVelocitaSrc),
            temperatura: parseResponseSingleData(dataLastTemperaturaSrc),
            marciaFermo: parseResponseSingleData(dataLastMarciaFermoSrc),
          }),
          /* lastAfter: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(dataLastAfterAssorbimentoSrc),
            // inverterDiretta: parseResponseSingleData(dataLastAfterInverterDirettaSrc),
            driveBypass: parseResponseSingleData(dataLastAfterDriveBypassSrc),
            manuale: parseResponseSingleData(dataLastAfterManualeSrc),
            automatico: parseResponseSingleData(dataLastAfterAutomaticoSrc),
            localeSezionato: parseResponseSingleData(dataLastAfterLocaleSezionatoSrc),
            setVelocita: parseResponseSingleData(dataLastAfterSetVelocitaSrc),
            temperatura: parseResponseSingleData(dataLastAfterTemperaturaSrc),
            marciaFermo: parseResponseSingleData(dataLastAfterMarciaFermoSrc),
          }),*/
          startDate: startDateObj.unix(),
          endDate: endDateObj.unix(),
          fetchErrors: false,
        })

        this.prepareData()
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private prepareData() {
    try {
      let assorbimento: any = []
      // let inverterDiretta: any = []
      let driveBypass: any = []
      let manuale: any = []
      let automatico: any = []
      let setVelocita: any = []
      let temperatura: any = []
      let marciaFermo: any = []
      const mergedData: any[] = []
      let localeSezionato: any[] = []

      if (this.state.data) {
        assorbimento = populateSingleData('assorbimento', this.state.data, false, 0.1)
        driveBypass = populateSingleData('driveBypass', this.state.data, true)
        manuale = populateSingleData('manuale', this.state.data, true)
        automatico = populateSingleData('automatico', this.state.data, true)
        setVelocita = populateSingleData('setVelocita', this.state.data)
        temperatura = populateSingleData('temperatura', this.state.data)
        marciaFermo = populateSingleData('marciaFermo', this.state.data, true)
        localeSezionato = populateSingleData('localeSezionato', this.state.data, true)

        hydrateData(
          {
            assorbimento,
            // inverterDiretta,
            driveBypass,
            manuale,
            automatico,
            setVelocita,
            temperatura,
            marciaFermo,
            localeSezionato,
          },
          mergedData,
          'assorbimento',
          0
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) { return -1 }
          if (a.time > b.time) { return 1 }
          return 0
        })

        hydrateTimeData(
          [
            'assorbimento',
            // 'inverterDiretta',
            'manuale',
            'automatico',
            'setVelocita',
            'temperatura',
            'marciaFermo',
            'driveBypass',
            'localeSezionato',
          ],
          mergedData,
          this.state
        )

        populateManAutoLocSec(mergedData)

        interpolateTime(mergedData, moment(this.props.workshift.start).unix(), moment(this.props.workshift.end).unix())

        this.setState({
          dataReady: true,
          brush1StartInd: 0,
          brush1EndInd: mergedData.length - 1,
          mergedData,
          filteredData: mergedData.slice(0, mergedData.length - 1),
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex + 1) : [],
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(FanGraphs)))
