import * as React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Button, Input, Loader, PageHeader} from '@mv-submodules/inplant-components-fe'
import {
  createGroup,
  groupDetail as fetchGroupDetail,
  updateGroup,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/groupsList'
import {Group} from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface OwnState {
  name: string
  isSubmitting: boolean
  groupId?: string
  isFetching: boolean
  fetchError: boolean
}

type Props = RouteComponentProps & WithTranslation

class AssetGroupFormPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      name: '',
      isSubmitting: false,
      isFetching: false,
      fetchError: false,
    }

    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToGroupList = this.goToGroupList.bind(this)
    this.fetchDataGroup = this.fetchDataGroup.bind(this)
  }

  public componentDidMount() {
    const groupId = this.props.match.params['groupId'] //tslint:disable-line
    if (groupId) {
      this.setState({groupId}, () => this.fetchDataGroup(groupId))
    }
  }

  public render() {
    const {t} = this.props
    const {name, isSubmitting, groupId, isFetching, fetchError} = this.state
    const {handleChangeInput, goToGroupList, handleSubmit} = this
    return (
      <div className='inplant-asset-manager-fe'>
        {isFetching && <Loader/>}
        {!isFetching && !fetchError && (
          <form onSubmit={handleSubmit}>
            {/* Header Section*/}
            <PageHeader
              title={groupId ? t('assetManager.navigation.updateGroup') : t('assetManager.navigation.createNewGroup')}
              rightButtons={
                <>
                  <Button
                    variant={"primary-alternate"}
                    type='button'
                    disabled={isSubmitting}
                    onClick={goToGroupList}
                    label={t('assetManager.actions.cancel')}
                  />
                  <Button variant={"primary"} type='submit' disabled={isSubmitting}
                          label={t('assetManager.actions.save')} isLoading={isSubmitting}/>

                </>
              }
            />
            {/* End Header Section*/}
            {/* Content Section*/}
            <div className='content'>
              <Row>
                <Column md={4} formGroup={true}>
                  <Input
                    id='form-group'
                    label={t('assetManager.forms.groupName')}
                    value={name}
                    onChange={value => handleChangeInput(value as string)}
                    name='name'
                  />
                </Column>
              </Row>
            </div>
            {/* End Content Section*/}
          </form>
        )}
      </div>
    )
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({isSubmitting: true})
    const {name} = this.state
    if (this.state.groupId) {
      updateGroup(name, this.state.groupId)
        .then(() => {
          this.setState({isSubmitting: false})
          this.props.history.push(`/asset-manager/groups/detail/${this.state.groupId}`)
        })
        .catch((error: any) => {
          this.setState({isSubmitting: false})
          console.log(error) //tslint:disable-line
        })
    } else {
      createGroup(name)
        .then((result: Group) => {
          this.setState({isSubmitting: false})
          this.goToGroupList()
        })
        .catch((error: any) => {
          this.setState({isSubmitting: false})
          console.log(error) //tslint:disable-line
        })
    }
  }

  private handleChangeInput(value: string) {
    const name = value
    this.setState({name})
  }

  private async fetchDataGroup(id: string) {
    if (!this.state.isFetching) {
      try {
        const groupDetail: Group = await fetchGroupDetail(id)
        this.setState({name: groupDetail.name})
      } catch (e) {
        console.log(e) //tslint:disable-line
        this.setState({fetchError: false})
      } finally {
        this.setState({isFetching: false})
      }
    }
  }

  private goToGroupList() {
    this.props.history.push('/asset-manager/groups')
  }
}

export default withRouter<any, any>(withTranslation()(AssetGroupFormPageView))
