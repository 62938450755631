export const boldStartEndRegExp = new RegExp(/\*{2}/g)
export const boldStringRegExp = new RegExp(/\*{2}.+\*{2}/g)

export const italicStringRegExp = new RegExp(/\*.+\*/g)
export const italicStartEndRegExp = new RegExp(/\*/g)

export const linkStartEndRegExp = new RegExp(/\*{3}/g)
export const linkStringRegExp = new RegExp(/\*{3}.+\*{3}/g)

export const underlineStringRegExp = new RegExp(/_{2}.+_{2}/g)
export const underlineStartEndRegExp = new RegExp(/_{2}/g)

export const strikeStringRegExp = new RegExp(/~.+~/g)
export const strikeStartEndRegExp = new RegExp(/~/g)

export const boldStrikeEndRegExp = new RegExp(/~\*{2}/g)
export const boldStrikeStartRegExp = new RegExp(/\*{2}~/g)
export const boldStrikeStringRegExp = new RegExp(/\*{2}~.+~\*{2}/g)

export const backSpaceStringRegExp = new RegExp(/\n/g)

export const metaChar = new RegExp(/\|/)

export const emailRegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const phoneRegExp = new RegExp(/^[+]*[(]?[0-9]{1,3}[)]?[-\s./0-9]*$/g);

export const uuidRegExp = new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi)
