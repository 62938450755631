import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import {
  extractResponseData,
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  populateManAutoLocSec,
} from '../../../../../functions/series'
import { AreaSection, GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import GraphTimelineView from '../../GraphTimeline/GraphTimelineView'
import moment from 'moment'
import { getAreaSections, Loader, reduceWarnAreas } from '../../../../../functions/shared'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { API } from '../../../../../redux/actions'
import GraphBrush from '../../GraphBrush/GraphBrush'

export type VecoplantTypes = 'test'

interface OwnProps {
  isDateFilterRange?: boolean
  // standardTime: number
  processing?: number
  hiddenCharts?: VecoplantTypes[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  // date: string | number
  // workShift: any
}

interface OwnState {
  bufferFill: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataBufferFill: PieData | null
  fetching: boolean
  error: boolean
  tooltipDataBuffer1: any
  tooltipDataBuffer2: any
  tooltipDataBuffer5: any
  showTooltip: boolean
  data: any
  filteredData: any[]
  filteredDataBuffer1: any[]
  filteredDataBuffer2: any[]
  filteredDataBuffer5: any[]
  mergedData: any[]
  brushBuffer1StartInd: number
  brushBuffer1EndInd: number
  brushBuffer5StartInd: number
  brushBuffer5EndInd: number
  brushBuffer2StartInd: number
  brushBuffer2EndInd: number
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: Workshift
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class BufferFillDetail extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      bufferFill: {},
      fetching: false,
      error: false,
      dataBufferFill: null,
      showTooltip: true,
      tooltipDataBuffer1: null,
      tooltipDataBuffer2: null,
      tooltipDataBuffer5: null,
      data: null,
      filteredData: [],
      filteredDataBuffer1: [],
      filteredDataBuffer2: [],
      filteredDataBuffer5: [],
      mergedData: [],
      brushBuffer1StartInd: 0,
      brushBuffer1EndInd: 0,
      brushBuffer2StartInd: 0,
      brushBuffer2EndInd: 0,
      brushBuffer5StartInd: 0,
      brushBuffer5EndInd: 0,
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props?.workshift?.value !== prevProps?.workshift?.value
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
      tooltipDataBuffer1: null,
      tooltipDataBuffer2: null,
      tooltipDataBuffer5: null,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipDataBuffer1: null,
      tooltipDataBuffer2: null,
      tooltipDataBuffer5: null,
    })
  }

  private handleMouseMove(args: any, bufferSlug: 'buffer1' | 'buffer2' | 'buffer5') {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const currbuffer1Threshold = curData.buffer1Threshold !== undefined ? curData.buffer1Threshold : '--'
        const currBuffer1VAlues = curData.buffer1VAlues !== undefined ? curData.buffer1VAlues : '--'
        const currBuffer2Threshold = curData.buffer2Threshold !== undefined ? curData.buffer2Threshold : '--'
        const currBuffer2VAlues = curData.buffer2VAlues !== undefined ? curData.buffer2VAlues : '--'
        const currBuffer5Threshold = curData.buffer5Threshold !== undefined ? curData.buffer5Threshold : '--'
        const currBuffer5VAlues = curData.buffer5VAlues !== undefined ? curData.buffer5VAlues : '--'

        switch (bufferSlug) {
          case 'buffer1':
            this.setState({
              showTooltip: true,
              tooltipDataBuffer1: {
                time: curData.time,
                buffer1Threshold: currbuffer1Threshold,
                buffer1VAlues: currBuffer1VAlues,
                buffer1ThresholdColor: COLORS.vecoplant.fillBuffer.limit,
                buffer1VAluesColor: COLORS.vecoplant.fillBuffer.buffer1,
              },
              tooltipDataBuffer2: null,
              tooltipDataBuffer5: null,
            })
            break
          case 'buffer2':
            this.setState({
              showTooltip: true,
              tooltipDataBuffer2: {
                time: curData.time,
                buffer2Threshold: currBuffer2Threshold,
                buffer2VAlues: currBuffer2VAlues,
                buffer2ThresholdColor: COLORS.vecoplant.fillBuffer.limit,
                buffer2VAluesColor: COLORS.vecoplant.fillBuffer.buffer2,
              },
              tooltipDataBuffer1: null,
              tooltipDataBuffer5: null,
            })
            break
          case 'buffer5':
            this.setState({
              showTooltip: true,
              tooltipDataBuffer5: {
                time: curData.time,
                buffer5Threshold: currBuffer5Threshold,
                buffer5VAlues: currBuffer5VAlues,
                buffer5ThresholdColor: COLORS.vecoplant.fillBuffer.limit,
                buffer5VAluesColor: COLORS.vecoplant.fillBuffer.buffer5,
              },
              tooltipDataBuffer1: null,
              tooltipDataBuffer2: null,
            })
            break
          default:
            break
        }
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  private handleBrush1(args: any, bufferSlug: 'buffer1' | 'buffer2' | 'buffer5') {
    switch (bufferSlug) {
      case 'buffer1':
        this.setState({
          brushBuffer1StartInd: args.startIndex,
          brushBuffer1EndInd: args.endIndex,
          filteredDataBuffer1: this.state.filteredData
            ? this.state.filteredData.slice(args.startIndex, args.endIndex + 1)
            : [],
        })
        break
      case 'buffer2':
        this.setState({
          brushBuffer2StartInd: args.startIndex,
          brushBuffer2EndInd: args.endIndex,
          filteredDataBuffer2: this.state.filteredData
            ? this.state.filteredData.slice(args.startIndex, args.endIndex + 1)
            : [],
        })
        break
      case 'buffer5':
        this.setState({
          brushBuffer5StartInd: args.startIndex,
          brushBuffer5EndInd: args.endIndex,
          filteredDataBuffer5: this.state.filteredData
            ? this.state.filteredData.slice(args.startIndex, args.endIndex + 1)
            : [],
        })
        break
      default:
        break
    }
  }

  public render() {
    const { t, hiddenCharts } = this.props
    const { fetching, error } = this.state

    const warnPoints: AreaSection[] | undefined =
      ([{}] as [{}]) && getAreaSections(this.state.filteredData, 'warnArea').filter(e => e.value === 100)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <div className="halts-graphs page-break-before-always">
        {!fetching && error && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferFillTitle')}</h3>
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}
        {!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data) && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferFillTitle')}</h3>
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
          </>
        )}
        {!error && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('test')) &&
              (this.state.fetching ? (
                <Row>
                  <Loader />
                </Row>
              ) : (
                this.state.data && (
                  <Row>
                    <div className={`col-md-12 col-sm-12 col-lg-12`}>
                      <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.buffer1FillTitle')}</h3>
                      <Row>
                        <div className={`col-md-10 col-sm-10 col-lg-10 print-full-width`}>
                          <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                              data={this.state.filteredDataBuffer1}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseMove={data => this.handleMouseMove(data, 'buffer1')}
                              onMouseLeave={this.handleMouseLeave}
                              barGap={0}
                              barCategoryGap={0}
                            >
                              <XAxis
                                hide={true}
                                dataKey="time"
                                label={''}
                                height={30}
                                axisLine={false}
                                tickLine={false}
                                interval={'preserveStartEnd'}
                                minTickGap={50}
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                type={'category'}
                              />
                              <CartesianGrid stroke="#f5f5f5" />

                              {warnAreas}
                              <Tooltip content={BufferFillDetail.nullTooltipContent} />
                              <YAxis
                                orientation={'left'}
                                yAxisId={'meters'}
                                axisLine={false}
                                tickFormatter={val => parseInt(val, 10)}
                              />
                              <Line
                                dataKey="buffer1Threshold"
                                stroke={COLORS.vecoplant.fillBuffer.limit}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                              <Line
                                dataKey="buffer1VAlues"
                                stroke={COLORS.vecoplant.fillBuffer.buffer1}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                          <GraphTimelineView
                            data={this.state.filteredDataBuffer1}
                            colWidth={12}
                            xAxisNumber={true}
                            preserveStartEnd={true}
                          />
                          <GraphBrush
                            data={this.state.filteredData}
                            brushOnChange={data => this.handleBrush1(data, 'buffer1')}
                            colWidth={12}
                          >
                            <Line
                              dataKey="buffer1VAlues"
                              type="step"
                              stroke={COLORS.vecoplant.fillBuffer.buffer1}
                              dot={false}
                              isAnimationActive={false}
                            />
                          </GraphBrush>
                          <GraphTimelineView data={this.state.filteredData} xAxisNumber={false} colWidth={12} />
                        </div>
                        <Column md={2}>
                          {this.state.showTooltip && this.state.tooltipDataBuffer1 !== null && (
                            <ul className="side-tooltip">
                              <li>
                                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                <br />
                                <span>{moment.unix(this.state.tooltipDataBuffer1.time).format('HH:mm')}</span>
                              </li>

                              {this.state.tooltipDataBuffer1.buffer1VAlues !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferVAlues')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{ backgroundColor: `${this.state.tooltipDataBuffer1.buffer1VAluesColor}` }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer1.buffer1VAlues).toFixed(2)} m</span>
                                </li>
                              )}

                              {this.state.tooltipDataBuffer1.buffer1Threshold !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferThreshold')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{
                                      backgroundColor: `${this.state.tooltipDataBuffer1.buffer1ThresholdColor}`,
                                    }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer1.buffer1Threshold).toFixed(2)} m</span>
                                </li>
                              )}
                            </ul>
                          )}
                        </Column>
                      </Row>
                      <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.buffer2FillTitle')}</h3>
                      <Row>
                        <div className={`col-md-10 col-sm-10 col-lg-10 print-full-width`}>
                          <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                              data={this.state.filteredDataBuffer2}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseMove={data => this.handleMouseMove(data, 'buffer2')}
                              onMouseLeave={this.handleMouseLeave}
                              barGap={0}
                              barCategoryGap={0}
                            >
                              <XAxis
                                hide={true}
                                dataKey="time"
                                label={''}
                                height={30}
                                axisLine={false}
                                tickLine={false}
                                interval={'preserveStartEnd'}
                                minTickGap={50}
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                type={'category'}
                              />
                              <CartesianGrid stroke="#f5f5f5" />

                              {warnAreas}
                              <Tooltip content={BufferFillDetail.nullTooltipContent} />
                              <YAxis
                                orientation={'left'}
                                yAxisId={'meters'}
                                axisLine={false}
                                tickFormatter={val => parseInt(val, 10)}
                              />
                              <Line
                                dataKey="buffer2Threshold"
                                stroke={COLORS.vecoplant.fillBuffer.limit}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                              <Line
                                dataKey="buffer2VAlues"
                                stroke={COLORS.vecoplant.fillBuffer.buffer2}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                          <GraphTimelineView
                            data={this.state.filteredDataBuffer2}
                            colWidth={12}
                            xAxisNumber={true}
                            preserveStartEnd={true}
                          />
                          <GraphBrush
                            data={this.state.filteredData}
                            brushOnChange={data => this.handleBrush1(data, 'buffer2')}
                            colWidth={12}
                          >
                            <Line
                              dataKey="buffer2VAlues"
                              type="step"
                              stroke={COLORS.vecoplant.fillBuffer.buffer2}
                              dot={false}
                              isAnimationActive={false}
                            />
                          </GraphBrush>
                          <GraphTimelineView data={this.state.filteredData} xAxisNumber={false} colWidth={12} />
                        </div>
                        <Column md={2}>
                          {this.state.showTooltip && this.state.tooltipDataBuffer2 !== null && (
                            <ul className="side-tooltip">
                              <li>
                                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                <br />
                                <span>{moment.unix(this.state.tooltipDataBuffer2.time).format('HH:mm')}</span>
                              </li>

                              {this.state.tooltipDataBuffer2.buffer2VAlues !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferVAlues')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{ backgroundColor: `${this.state.tooltipDataBuffer2.buffer2VAluesColor}` }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer2.buffer2VAlues).toFixed(2)} m</span>
                                </li>
                              )}

                              {this.state.tooltipDataBuffer2.buffer2Threshold !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferThreshold')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{
                                      backgroundColor: `${this.state.tooltipDataBuffer2.buffer2ThresholdColor}`,
                                    }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer2.buffer2Threshold).toFixed(2)} m</span>
                                </li>
                              )}
                            </ul>
                          )}
                        </Column>
                      </Row>
                      <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.buffer5FillTitle')}</h3>
                      <Row>
                        <div className={`col-md-10 col-sm-10 col-lg-10 print-full-width`}>
                          <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                              data={this.state.filteredDataBuffer5}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseMove={data => this.handleMouseMove(data, 'buffer5')}
                              onMouseLeave={this.handleMouseLeave}
                              barGap={0}
                              barCategoryGap={0}
                            >
                              <XAxis
                                hide={true}
                                dataKey="time"
                                label={''}
                                height={30}
                                axisLine={false}
                                tickLine={false}
                                interval={'preserveStartEnd'}
                                minTickGap={50}
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                                type={'category'}
                              />
                              <CartesianGrid stroke="#f5f5f5" />

                              {warnAreas}
                              <Tooltip content={BufferFillDetail.nullTooltipContent} />
                              <YAxis
                                orientation={'left'}
                                yAxisId={'meters'}
                                axisLine={false}
                                tickFormatter={val => parseInt(val, 10)}
                              />
                              <Line
                                dataKey="buffer5Threshold"
                                stroke={COLORS.vecoplant.fillBuffer.limit}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                              <Line
                                dataKey="buffer5VAlues"
                                stroke={COLORS.vecoplant.fillBuffer.buffer5}
                                dot={false}
                                strokeWidth={2}
                                type="step"
                                isAnimationActive={false}
                                yAxisId={'meters'}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                          <GraphTimelineView
                            data={this.state.filteredDataBuffer5}
                            colWidth={12}
                            xAxisNumber={true}
                            preserveStartEnd={true}
                          />
                          <GraphBrush
                            data={this.state.filteredData}
                            brushOnChange={data => this.handleBrush1(data, 'buffer5')}
                            colWidth={12}
                          >
                            <Line
                              dataKey="buffer5VAlues"
                              type="step"
                              stroke={COLORS.vecoplant.fillBuffer.buffer5}
                              dot={false}
                              isAnimationActive={false}
                            />
                          </GraphBrush>
                          <GraphTimelineView data={this.state.filteredData} xAxisNumber={false} colWidth={12} />
                        </div>
                        <Column md={2}>
                          {this.state.showTooltip && this.state.tooltipDataBuffer5 !== null && (
                            <ul className="side-tooltip">
                              <li>
                                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                                <br />
                                <span>{moment.unix(this.state.tooltipDataBuffer5.time).format('HH:mm')}</span>
                              </li>

                              {this.state.tooltipDataBuffer5.buffer5VAlues !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferVAlues')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{ backgroundColor: `${this.state.tooltipDataBuffer5.buffer5VAluesColor}` }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer5.buffer5VAlues).toFixed(2)} m</span>
                                </li>
                              )}

                              {this.state.tooltipDataBuffer5.buffer5Threshold !== null && (
                                <li>
                                  <strong>{this.props.t('plantAnalysis.labels.bufferThreshold')}</strong>
                                  <br />
                                  <span
                                    className="label-color label-color-line"
                                    style={{
                                      backgroundColor: `${this.state.tooltipDataBuffer5.buffer5ThresholdColor}`,
                                    }}
                                  />
                                  <span>{parseFloat(this.state.tooltipDataBuffer5.buffer5Threshold).toFixed(2)} m</span>
                                </li>
                              )}
                            </ul>
                          )}
                        </Column>
                      </Row>
                    </div>
                  </Row>
                )
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  /*   private getBufferFromKey(key: string) {
    switch (key) {
      case 'buffer_f055':
        return this.props.t('plantAnalysis.vecoplant.bufferState.buffers.buffer_f055')
      case 'buffer_f026':
        return this.props.t('plantAnalysis.vecoplant.bufferState.buffers.buffer_f026')
      case 'buffer_f017':
        return this.props.t('plantAnalysis.vecoplant.bufferState.buffers.buffer_f017')
      default:
        return '--'
    }
  }
 */
  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let buffer1Threshold: any = []
      let buffer1VAlues: any = []
      let buffer2Threshold: any = []
      let buffer2VAlues: any = []
      let buffer5Threshold: any = []
      let buffer5VAlues: any = []
      const mergedData: any[] = []
      if (this.state.data) {
        buffer1Threshold = this.populateSingleData('buffer1Threshold')
        buffer1VAlues = this.populateSingleData('buffer1VAlues')
        buffer2Threshold = this.populateSingleData('buffer2Threshold')
        buffer2VAlues = this.populateSingleData('buffer2VAlues')
        buffer5Threshold = this.populateSingleData('buffer5Threshold')
        buffer5VAlues = this.populateSingleData('buffer5VAlues')
      }
      hydrateData(
        {
          buffer1Threshold,
          buffer1VAlues,
          buffer2Threshold,
          buffer2VAlues,
          buffer5Threshold,
          buffer5VAlues,
        },
        mergedData,
        undefined,
        0
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(
        ['buffer1Threshold', 'buffer1VAlues', 'buffer2Threshold', 'buffer2VAlues', 'buffer5Threshold', 'buffer5VAlues'],
        mergedData,
        this.state
      )
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          mergedData,
          filteredData: mergedData,
          filteredDataBuffer1: mergedData,
          filteredDataBuffer2: mergedData,
          filteredDataBuffer5: mergedData,
          fetching: false,
          error: false,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  /*   private getColorFromKey(key: string) {
    switch (true) {
      case key.includes('buffer_f017'):
        return COLORS.vecoplant.fillBuffer.buffer1
      case key.includes('buffer_f026'):
        return COLORS.vecoplant.fillBuffer.buffer2
      case key.includes('buffer_f055'):
        return COLORS.vecoplant.fillBuffer.buffer5
      default:
        return COLORS.vecoplant.bufferLine.empty
    }
  } */

  // private formatData(data: any) {
  //   return [
  //     data.columns
  //       .map((value: any, index: number) => {
  //         if (value !== 'time' && value !== 'shift') {
  //           return {
  //             [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
  //             // [value]: data.values[0][index]
  //           }
  //         }
  //       })
  //       .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {}),
  //   ]
  // }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    // const workshift = this.props.workshift

    if (this.props.workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.workshift.start.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const startDate = this.props.workshift.start
        ? mvDate.getDateFromString(this.props.workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.workshift.end.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate = this.props.workshift.end
        ? mvDate.getDateFromString(this.props.workshift.end.toString()).toISOString()
        : endOfDay
      // const queryEndShift = ` WHERE shift = ${
      //   this.props.workshift.value !== 99 ? this.props.workshift.value : 0
      // } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryEndShiftTime = ` WHERE time <= '${startDate}' ORDER BY desc LIMIT 1`

      try {
        const bufferMeasure1Label = 'IBSGN5PHK0LR8VII'
        const bufferMeasure2Label = 'IBSGN5PHK0N72MZJ'
        const bufferMeasure3Label = 'IBSGN5PK9QL0FEJQ'
        // const fillingBufferRecapLabel = 'vFillingBuffers'

        const queryStart = `SELECT "measure" FROM `
        // const queryStartFillingBuffer = `SELECT * FROM `

        const buffer1Threshold = queryStart + `"${bufferMeasure1Label}_MAT_LEV_INT_STA"` + queryEnd
        const buffer1ThresholdBeforeShift = queryStart + `"${bufferMeasure1Label}_MAT_LEV_INT_STA"` + queryEndShiftTime
        const buffer1VAlues = queryStart + `"${bufferMeasure1Label}_MAT_ACT_LEV_calc"` + queryEnd

        const buffer2Threshold = queryStart + `"${bufferMeasure2Label}_MAT_LEV_INT_STA"` + queryEnd
        const buffer2ThresholdBeforeShift = queryStart + `"${bufferMeasure2Label}_MAT_LEV_INT_STA"` + queryEndShiftTime
        const buffer2VAlues = queryStart + `"${bufferMeasure2Label}_MAT_ACT_LEV_calc"` + queryEnd

        const buffer5Threshold = queryStart + `"${bufferMeasure3Label}_MAT_LEV_INT_STA"` + queryEnd
        const buffer5ThresholdBeforeShift = queryStart + `"${bufferMeasure3Label}_MAT_LEV_INT_STA"` + queryEndShiftTime
        const buffer5VAlues = queryStart + `"${bufferMeasure3Label}_MAT_ACT_LEV_calc"` + queryEnd

        // const fillingBufferRecap = queryStartFillingBuffer + `${fillingBufferRecapLabel}` + queryEndShift

        const dataBuffer1Threshold = API().request(`/query?${plantQueryString}q=` + buffer1Threshold, {
          signal: this.abortController.signal,
        })
        const dataBuffer1ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + buffer1ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataBuffer1VAlues = API().request(`/query?${plantQueryString}q=` + buffer1VAlues, {
          signal: this.abortController.signal,
        })
        const dataBuffer2Threshold = API().request(`/query?${plantQueryString}q=` + buffer2Threshold, {
          signal: this.abortController.signal,
        })
        const dataBuffer2ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + buffer2ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataBuffer2VAlues = API().request(`/query?${plantQueryString}q=` + buffer2VAlues, {
          signal: this.abortController.signal,
        })
        const dataBuffer5Threshold = API().request(`/query?${plantQueryString}q=` + buffer5Threshold, {
          signal: this.abortController.signal,
        })
        const dataBuffer5ThresholdBeforeShift = API().request(
          `/query?${plantQueryString}q=` + buffer5ThresholdBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataBuffer5VAlues = API().request(`/query?${plantQueryString}q=` + buffer5VAlues, {
          signal: this.abortController.signal,
        })
        // const dataFillingBufferRecap = API().request(`/query?${plantQueryString}q=` + fillingBufferRecap, {
        //   signal: this.abortController.signal,
        // })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataBuffer1Threshold,
          dataBuffer1ThresholdBeforeShift,
          dataBuffer1VAlues,
          dataBuffer2Threshold,
          dataBuffer2ThresholdBeforeShift,
          dataBuffer2VAlues,
          dataBuffer5Threshold,
          dataBuffer5ThresholdBeforeShift,
          dataBuffer5VAlues,
          // dataFillingBufferRecap,
        ])
          .then(
            ([
              dataBuffer1ThresholdResult,
              dataBuffer1ThresholdBeforeShiftResult,
              dataBuffer1VAluesResult,
              dataBuffer2ThresholdResult,
              dataBuffer2ThresholdBeforeShiftResult,
              dataBuffer2VAluesResult,
              dataBuffer5ThresholdResult,
              dataBuffer5ThresholdBeforeShiftResult,
              dataBuffer5VAluesResult,
              // dataFillingBufferRecapResult,
            ]) => {
              if (this.mounted) {
                try {
                  // const dataFillingBufferRecapResultObject =
                  //   typeof dataFillingBufferRecapResult === 'string'
                  //     ? JSON.parse(dataFillingBufferRecapResult)
                  //     : dataFillingBufferRecapResult
                  const buffer1ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataBuffer1ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer1ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                    ...extractResponseData(dataBuffer1ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataBuffer1ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer1ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                  ]
                  const buffer2ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataBuffer2ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer2ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                    ...extractResponseData(dataBuffer2ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataBuffer2ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer2ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                  ]
                  const buffer5ThresholdAgrValue = [
                    [
                      startDate,
                      extractResponseData(dataBuffer5ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer5ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                    ...extractResponseData(dataBuffer5ThresholdResult),
                    [
                      endDate,
                      extractResponseData(dataBuffer5ThresholdBeforeShiftResult).length > 0
                        ? extractResponseData(dataBuffer5ThresholdBeforeShiftResult)?.[0]?.[1]
                        : 0,
                    ],
                  ]

                  this.setState(
                    {
                      data: Object.assign({}, this.state.data, {
                        buffer1Threshold: { data: buffer1ThresholdAgrValue },
                        buffer1VAlues: { data: extractResponseData(dataBuffer1VAluesResult) },
                        buffer2Threshold: { data: buffer2ThresholdAgrValue },
                        buffer2VAlues: { data: extractResponseData(dataBuffer2VAluesResult) },
                        buffer5Threshold: { data: buffer5ThresholdAgrValue },
                        buffer5VAlues: { data: extractResponseData(dataBuffer5VAluesResult) },
                        // fillingBufferRecap: this.formatData(
                        //   dataFillingBufferRecapResultObject.results?.[0].series?.[0]
                        // ),
                      }),
                    },
                    () => {
                      this.constructData()
                    }
                  )
                } catch (error) {
                  this.setState({
                    bufferFill: {},
                    fetching: false,
                    error: false,
                    data: null,
                  })
                }
              }
            }
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              bufferFill: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          bufferFill: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(BufferFillDetail))
