import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../../constants'
import { Loader } from '../../../../../functions/shared'
import { parseData } from '../../../../../functions/series'
import moment from 'moment'
import GraphLineBarWithTimelinePrint from '../../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelinePrint'
import {
  composeData,
  fillTime,
  populateSingleDataFrom,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import {
  TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'

export type BufferStateType = 'bufferState'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: BufferStateType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  bufferState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataBufferState: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
})

class BufferStateView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      bufferState: {},
      fetching: false,
      error: false,
      dataBufferState: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData(this.props.processing)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    return (
      <>
        <TableRowFullWrapperWithCondition condition={!fetching && error}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
          <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
            {t('plantAnalysis.fetchErrors')}
          </div>
        </TableRowFullWrapperWithCondition>
        <TableRowFullWrapperWithCondition
          condition={!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data)}>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
          <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
        </TableRowFullWrapperWithCondition>
        <WithConditionWrapper condition={!error}>
          <WithConditionWrapper condition={!hiddenCharts || !hiddenCharts.includes('bufferState')}>
            <WithConditionWrapper condition={!this.state.fetching} onError={
              <>
                <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
                <Loader />
              </>
            }>
              <TableRowFullWrapperWithCondition columnClassName={'py-3'} condition={this.state.data}>
                <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
                <WithConditionWrapper condition={!this.state.data?.buffer1NoData} onError={<>
                  <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer1')}</h5>
                  <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                    {t('plantAnalysis.noDataAvailable')}
                  </div>
                </>}>
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'buffer1'}
                    colorsId={'bufferState'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer1'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper condition={!this.state.data?.buffer2NoData} onError={<>
                  <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer2')}</h5>
                  <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                    {t('plantAnalysis.noDataAvailable')}
                  </div>
                </>}>
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'buffer2'}
                    colorsId={'bufferState'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer2'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <WithConditionWrapper condition={!this.state.data?.buffer5NoData} onError={<>
                  <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer5')}</h5>
                  <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                    {t('plantAnalysis.noDataAvailable')}
                  </div>
                </>}>
                  <GraphLineBarWithTimelinePrint
                    topMargin={true}
                    entry={'buffer5'}
                    colorsId={'bufferState'}
                    filteredData={this.state.filteredData}
                    i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer5'}
                    lineHeight={60}
                  />
                </WithConditionWrapper>
                <div className='-print-inline-flex'>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square mx-3'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.loadable }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.loadable')}</span>
                        </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square mx-3'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.notLoadable }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.notLoadable')}</span>
                        </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square mx-3'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.emptying }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.emptying')}</span>
                        </span>

                </div>
              </TableRowFullWrapperWithCondition>
            </WithConditionWrapper>
          </WithConditionWrapper>
        </WithConditionWrapper>
      </>
    )
  }

  private constructData() {
    try {
      const buffer1 = populateSingleDataFrom(this.state.data?.buffer1?.data, false, true)
      const buffer2 = populateSingleDataFrom(this.state.data?.buffer2?.data, false, true)
      const buffer5 = populateSingleDataFrom(this.state.data?.buffer5?.data, false, true)


      let mergedData = composeData({
        buffer1,
        buffer2,
        buffer5,
      })

      const start = moment(this.props.workShift.start.toString()).unix()
      const end = moment(this.props.workShift.end.toString()).unix()

      mergedData = fillTime(
        mergedData,
        start,
        end,
      )

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  /*   private getTraductionFromBufferId(key: string) {
      return this.props.t(`plantAnalysis.vecoplant.bufferState.buffers.${key}`)
    } */

  private generateKey(data: Record<string, any>) {
    const keys = Object.keys(data)
    const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
    const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
    this.setState({
      keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
    })
  }

  private formatData(data: any) {
    const resultData = data.columns
      .map((value: any, index: number) => {
        if (value !== 'time' && value !== 'shift') {
          return {
            [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
          }
        }
      })
      .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
    this.generateKey(resultData)
    this.setState({
      data: {
        ...this.state.data,
        bufferRecap: [resultData],
      },
    })
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workShift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const startOfShift = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const endShift = workshift?.end
        ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
        : endOfDay

      const queryEnd = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`
      const queryEndWithShift = ` WHERE ${
        workshift && workshift.value !== 99 ? `shift = ${workshift.value} AND ` : ''
      } time >= '${startOfShift}' AND time <= '${endShift}'`

      try {

        const queryStart = `SELECT * FROM `
        const buffer1Label = `vBufferF017LoadState`
        const buffer2Label = `vBufferF026LoadState`
        const buffer5Label = `vBufferF055LoadState`
        const bufferRecap = `vStateBuffersThresholdsResults`

        const buffer1Query = queryStart + buffer1Label + queryEndWithShift
        const buffer2Query = queryStart + buffer2Label + queryEndWithShift
        const buffer5Query = queryStart + buffer5Label + queryEndWithShift
        const bufferRecapQuery = queryStart + bufferRecap + queryEnd

        const dataBuffer1Query = API().request(`/query?${plantQueryString}q=` + buffer1Query, { signal: this.abortController.signal })
        const dataBuffer2Query = API().request(`/query?${plantQueryString}q=` + buffer2Query, { signal: this.abortController.signal })
        const dataBuffer5Query = API().request(`/query?${plantQueryString}q=` + buffer5Query, { signal: this.abortController.signal })
        const dataBufferRecapQuery = API().request(`/query?${plantQueryString}q=` + bufferRecapQuery, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([dataBufferRecapQuery, dataBuffer1Query, dataBuffer2Query, dataBuffer5Query]).then(
          ([dataBufferRecapResult, dataBuffer1QueryResult, dataBuffer2QueryResult, dataBuffer5QueryResult]) => {
            if (this.mounted) {
              const parsedDataBuffer1QueryResult =
                typeof dataBuffer1QueryResult === 'string' ? JSON.parse(dataBuffer1QueryResult) : dataBuffer1QueryResult
              const parsedDataBuffer2QueryResult =
                typeof dataBuffer2QueryResult === 'string' ? JSON.parse(dataBuffer2QueryResult) : dataBuffer2QueryResult
              const parsedDataBuffer5QueryResult =
                typeof dataBuffer5QueryResult === 'string' ? JSON.parse(dataBuffer5QueryResult) : dataBuffer5QueryResult
              const dataBufferRecapResultObject =
                typeof dataBufferRecapResult === 'string' ? JSON.parse(dataBufferRecapResult) : dataBufferRecapResult
              const parsedDataBuffer1 = parseData(parsedDataBuffer1QueryResult)
              const parsedDataBuffer2 = parseData(parsedDataBuffer2QueryResult)
              const parsedDataBuffer5 = parseData(parsedDataBuffer5QueryResult)
              try {
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    buffer1NoData: parsedDataBuffer1.length === 0,
                    buffer1: { data: parsedDataBuffer1 },
                    buffer2NoData: parsedDataBuffer2.length === 0,
                    buffer2: { data: parsedDataBuffer2 },
                    buffer5NoData: parsedDataBuffer5.length === 0,
                    buffer5: { data: parsedDataBuffer5 },
                  }),
                  fetching: false,
                  error: false,
                }, () => {
                  this.constructData()
                })
              } catch (error) {
                this.setState({
                  filteredData: [],
                  fetching: false,
                  error: false,
                })
              }
              try {
                this.formatData(dataBufferRecapResultObject.results[0].series[0])
              } catch (error) {
                this.setState({
                  bufferState: {},
                  fetching: false,
                  error: false,
                  data: null,
                })
              }

            }
          },
        )
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          bufferState: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(BufferStateView))
