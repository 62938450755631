import * as React from 'react'
import {WithTranslation} from 'react-i18next'
import FileUploaderField from './FileUploaderField'
import {File} from '../../../../types/pageForm'
import {manageArray} from '../../../../functions/utils'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

interface OwnProps {
    onInputChange: (slug: string, value: any, template?: boolean) => void
    handleUploadFiles: (file: any, remove?: boolean) => void
    fieldValue: object[]
    canSend?: (state: boolean) => void
}

export type Props = OwnProps & WithTranslation

export default class FileUploaderFields extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props)
        this.handleUploadFiles = this.handleUploadFiles.bind(this)
        // newFile contains a new instance of the FileUploaderFiled that is needed to create a ne uploader component.
        // This thing is guaranteed by changing key with a new one to tell React that is a new element
        // The fileRefs is needed just to mimic the mapping of the files, other things are managed by props.handleUploadFiles
        this.state = {
            fileRefs: this.props.fieldValue || [],
            newFile: React.createElement(FileUploaderField, {
                ...this.props,
                fieldValue: null,
                key: Date.now(),
                handleUploadFiles: this.handleUploadFiles
            }, null)
        }
    }

    private handleUploadFiles(file: File, remove?: boolean) {
        // for reference see comments in the constructor
        if (remove) {
            this.setState({
                fileRefs: manageArray(this.state.fileRefs, file, remove)
            })
        } else {
            this.setState({
                fileRefs: manageArray(this.state.fileRefs, file),
                newFile: React.createElement(FileUploaderField, {
                    ...this.props,
                    fieldValue: null,
                    key: Date.now(),
                    handleUploadFiles: this.handleUploadFiles
                }, null)
            })
        }
        this.props.handleUploadFiles(file, remove)
    }

    public render() {
        let childs;
        // if true populate childs with a fileUploadField for every file that has been prevoiusly uploaded
        if (Array.isArray(Array.from(this.state.fileRefs)) && Array.from(this.state.fileRefs).length > 0) {
            // @ts-ignore
            childs = <div>{Array.from(this.state.fileRefs).map((elem: File) => (
                <Row key={elem.id!}>
                    <FileUploaderField
                        key={elem.id!}
                        {...this.props}
                        fieldValue={elem}
                        handleUploadFiles={this.handleUploadFiles}
                    />
                </Row>
            ))}</div>
        }
        return <div>
            {childs}
            {this.state.newFile}
        </div>

    }
}
