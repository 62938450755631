import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { fetchAssetsTaskForCopy } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import { AssetTaskForCopy } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { Checkbox, Table } from '@mv-submodules/inplant-components-fe'
import { RowInfo } from 'react-table'
import * as moment from 'moment'
import { renderStatus } from '@mv-submodules/inplant-asset-manager-fe/functions/shared'

interface OwnState {
  isFetching: boolean
  fetchErrors: boolean
  tasks: AssetTaskForCopy[]
}

interface StateProps {
  assetId: string
  selectedTasks: AssetTaskForCopy[]
  handleSelectTasks: (task: AssetTaskForCopy) => void
  handleSelectAllTasks: (tasks: AssetTaskForCopy[]) => void
}

type Props = WithTranslation & StateProps

class CopyTasksAssetTasks extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isFetching: false,
      fetchErrors: false,
      tasks: [],
    }
    this.fetchAssetTaskDetail = this.fetchAssetTaskDetail.bind(this)
  }

  public componentDidMount() {
    this.fetchAssetTaskDetail()
  }

  public render() {
    const { t, selectedTasks } = this.props
    const { tasks, isFetching } = this.state
    return (
      <Table
        data={tasks}
        className="mb-5"
        columns={[
          {
            Cell: ({ original }: RowInfo) => (
              <div
                className="rt-td-cell"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => this.props.handleSelectTasks(original)}
              >
                <Checkbox
                  // type="checkbox"
                  checked={!!selectedTasks.find(task => task.id === original.id)}
                  readOnly={true}
                  // tslint:disable-next-line: no-empty
                  onChange={() => {
                  }}
                  label=""
                />
              </div>
            ),
            Header: () => (
              <Checkbox
                // type="checkbox"
                checked={selectedTasks.length === tasks.length && tasks.length > 0}
                onChange={() => this.props.handleSelectAllTasks(tasks)}
                readOnly={true}
                label=""
              />
            ),
            sortable: false,
            width: 80,
            maxWidth: 80,
          },
          {
            Cell: ({ original }: RowInfo) => (original.date && moment(original.date).format('DD/MM/YYYY')) || '',
            Header: t('assetManager.table.header.deadline'),
            accessor: 'date',
            sortable: true,
            maxWidth: 150,
            id: 'date',
          },
          {
            Cell: ({ original }: RowInfo) => {
              const dueAtDate = original.date
              const deadlineDateDiff = moment(dueAtDate).diff(moment().startOf('day'), 'days')
              return (
                <div
                  className="rt-td-cell"
                >
                  {renderStatus(original.status, deadlineDateDiff === 0 ? t(`chronoframe.dueDates.tableList.today`) :
                    deadlineDateDiff === 1 ? t(`chronoframe.dueDates.tableList.tomorrow`) :
                      deadlineDateDiff === -1 ? t(`chronoframe.dueDates.tableList.yesterday`) :
                        deadlineDateDiff > 0 ? (
                          deadlineDateDiff < 10 ? `${deadlineDateDiff} ${t(
                            `chronoframe.dueDates.tableList.multipleDays`,
                          )}` : deadlineDateDiff < 32 ? `${deadlineDateDiff} ${t(
                            `chronoframe.dueDates.tableList.multipleDays`,
                          )}` : deadlineDateDiff < 366 ? `${Math.floor(deadlineDateDiff / 31)} ${
                            Math.floor(deadlineDateDiff / 31) === 1
                              ? t(`chronoframe.dueDates.tableList.singleMonth`)
                              : t(`chronoframe.dueDates.tableList.multipleMonths`)
                          }` : `${Math.floor(deadlineDateDiff / 365)} ${
                            Math.floor(deadlineDateDiff / 365) === 1
                              ? t(`chronoframe.dueDates.tableList.singleYear`)
                              : t(`chronoframe.dueDates.tableList.multipleYears`)
                          }`
                        ) : -deadlineDateDiff < 32 ? `${-deadlineDateDiff} ${t(
                          `chronoframe.dueDates.tableList.multipleDays`,
                        )}` : -deadlineDateDiff < 366 ? `${Math.floor(-deadlineDateDiff / 31)} ${
                          Math.floor(-deadlineDateDiff / 31) === 1
                            ? t(`chronoframe.dueDates.tableList.singleMonth`)
                            : t(`chronoframe.dueDates.tableList.multipleMonths`)
                        }` : `${Math.floor(-deadlineDateDiff / 365)} ${
                          Math.floor(-deadlineDateDiff / 365) === 1
                            ? t(`chronoframe.dueDates.tableList.singleYear`)
                            : t(`chronoframe.dueDates.tableList.multipleYears`)
                        }`)}
                </div>
              )
            },
            Header: t('assetManager.table.header.status'),
            accessor: 'status',
            maxWidth: 150,
            sortable: true,
            id: 'status',
          },
          {
            Cell: ({ original }: RowInfo) => original.taskId,
            Header: t('assetManager.table.header.id'),
            accessor: 'taskId',
            sortable: true,
            maxWidth: 175,
            id: 'taskId',
          },
          {
            Cell: ({ original }: RowInfo) => original.ambit || '',
            Header: t('assetManager.table.header.ambit'),
            accessor: 'ambit',
            sortable: true,
            maxWidth: 200,
            id: 'ambit',
          },
          {
            Cell: ({ original }: RowInfo) => original.category || '',
            Header: t('assetManager.table.header.category'),
            accessor: 'category',
            sortable: true,
            id: 'category',
          },
          {
            Cell: ({ original }: RowInfo) => original.subCategory || '',
            Header: t('assetManager.table.header.subCategory'),
            accessor: 'category',
            sortable: true,
            id: 'category',
          },
          {
            Cell: ({ original }: RowInfo) => original.activity || '',
            Header: t('assetManager.table.header.activity'),
            accessor: 'activity',
            sortable: true,
            id: 'activity',
          },
        ]}
        isFetching={isFetching}
        manualIsFetching={true}
        noDataText={t(`assetManager.table.noData`)}
        ofText={t('assetManager.table.of')}
        pageText={t('assetManager.table.page')}
        //  onRowClick={this.props.clickAssetTask ? this.props.clickAssetTask : undefined}
        rowsText={t('assetManager.table.rows')}
        manual={false}
        pages={0}
        pageSize={10}
        showPagination={true}
      />
    )
  }

  private fetchAssetTaskDetail() {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      fetchAssetsTaskForCopy(this.props.assetId)
        .then(tasks => {
          this.setState({
            tasks,
          })
        })
        .catch((error: FetchError) => {
          console.log(error) //tslint:disable-line
          this.setState({
            fetchErrors: true,
          })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }
}

export default withTranslation()(CopyTasksAssetTasks)
