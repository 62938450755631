// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

interface Props {
  label: string
  cliccable: boolean
  index: number
  onclick?: (id: number) => void
  showEndLine: boolean
  active: boolean
}

class Step extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <div onClick={() => {
        if (this.props.onclick && this.props.cliccable){
          this.props.onclick(this.props.index)
        }
      }} style={{cursor: this.props.cliccable ? 'pointer' : 'default'}}>
        <span className={`dot ${this.props.active ? 'active' : ''}`}>{this.props.index}</span>
        <span className="label">{this.props.label}</span>
        {this.props.showEndLine && (
          <div className="hr"/>
        )}
      </div>
    )
  }
}

export default Step