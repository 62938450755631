import { GroupDetail, GroupsListFilters } from '../../types/groups'
import { FetchGroupsListResponse } from '../../types/fetchData'
import {API} from './index'
import { ActionCreator } from 'redux'
import { ListPagination } from '@mv-submodules/inplant-asset-manager-fe/types/asset'

export const GROUPS_LIST_SET_FILTERS = 'GROUPS_LIST_SET_FILTERS'
export const GROUPS_LIST_SET_PAGINATION = 'GROUPS_LIST_SET_PAGINATION'

export interface  ApplyGroupsListFilters {
  type: typeof GROUPS_LIST_SET_FILTERS,
  params: GroupsListFilters
}

export interface  ApplyGroupsListPagination {
  type: typeof GROUPS_LIST_SET_PAGINATION,
  params: ListPagination
}

export const fetchGroupsList = async (filters?: GroupsListFilters, pagination?: ListPagination) => {
  const queryParams = pagination && Object.keys(pagination).reduce((acc: string[], key:string) => {
    if (pagination && pagination[key]){
      acc.push(`${key}=${pagination[key]}`)
    }
    return acc
  },[]).join("&")

  const query = `/groups/list?${queryParams}`

  const results: FetchGroupsListResponse = await API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      searchString: filters && filters.searchString || null
    })
  })
  return results
}

export const applyGroupsListFilters: ActionCreator<ApplyGroupsListFilters> = (params: GroupsListFilters): ApplyGroupsListFilters => ({
  type: GROUPS_LIST_SET_FILTERS,
  params
})

export const applyGroupsListPagination: ActionCreator<ApplyGroupsListPagination> = (params: ListPagination): ApplyGroupsListPagination => ({
  type: GROUPS_LIST_SET_PAGINATION,
  params
})

export const createGroup = (name: string) => {
  const query = '/groups/create'

  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name})
  })
}

export const updateGroup = (name: string, id: string) => {
  const query = `/groups/update/${id}`

  return API().request(query, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name})
  })
}

export const groupDetail = async (groupdId: string) => {
  const query = `/groups/${groupdId}`

  const result: GroupDetail = await API().request(query, {
    method: 'GET'
  })

  return result
}

export type  GroupListAction = ApplyGroupsListFilters | ApplyGroupsListPagination