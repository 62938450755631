// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import DropdownButton, { DropdownActions } from '../Button/DropdownButton'
import HtmlTooltip from '../Tooltip/HtmlTooltip'
import { ButtonVariants } from '../Button/types'

interface Props {
  actionsText: string
  ofText: string
  rowsText: string
  actions: DropdownActions
  selectedItems?: number
  disabled?: boolean
  items?: number
  tooltipContent?: any
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
  labelPosition?: 'left' | 'right'
  variant?: ButtonVariants
  disableLight?: boolean
}

const ActionDropdown = (props: Props) => {
  return (
    <div>
      {(props.items && props.items > 0 && props.labelPosition && props.labelPosition === 'left' && (
        <span className="mr-2">
          {props.selectedItems} {props.ofText} {props.items} {props.rowsText}
        </span>
      )) ||
        null}
      {props.tooltipContent && props.disabled ? (
        <HtmlTooltip
          content={props.tooltipContent}
          position={props.tooltipPosition || 'top'}
          target={
            <DropdownButton
              variant={props.variant}
              actions={props.actions}
              disabled={!props.selectedItems || props.disabled}
              spacing={{ horizontal: false }}
              disableLight={props.disableLight}
            >
              {props.actionsText}
            </DropdownButton>
          }
        />
      ) : (
        <DropdownButton
          variant={props.variant}
          actions={props.actions}
          disabled={!props.selectedItems || props.disabled}
          spacing={{ horizontal: false }}
          disableLight={props.disableLight}
        >
          {props.actionsText}
        </DropdownButton>
      )}
      {(props.items && props.items > 0 && (!props.labelPosition || props.labelPosition === 'right') && (
        <span className="ml-2">
          {props.selectedItems} {props.ofText} {props.items} {props.rowsText}
        </span>
      )) ||
        null}
    </div>
  )
}

export default ActionDropdown
