import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { GeneralStore } from '../../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../../types/workshift'
import { getInfluxMultiSeries, Loader } from '../../../../../functions/shared'
import BottomLegend from '../../BottomLegend/BottomLegend/BottomLegendView'
import { PlantAnalysisGeneralSettings } from '../../../../../types/settings'
import FanGraphRowItem from '../FanGraphRowItem/FanGraphRowItemView'
import { NodeComponent, ModelNodeData } from '../../../../../types/chronograph'
import { GeneralData } from '../../../../../types/measure'
import { flatData } from '../../../../../functions/model'
import { DateRangeProps } from '../../../../../types/workshift'

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  last: any
  lastAfter: any
  showTooltip: boolean
  tooltipData: any
  isCollapsed: boolean
  filteredData: any[]
  startDate: string | number
  endDate: string | number
  components: NodeComponent[]
  metrics: string[]
}

interface OwnProps {
  active: string
  component: any
  configuration: PlantAnalysisGeneralSettings
  data: Array<{ [key: string]: number }>
  dataCurrent: Array<{ [key: string]: number }>
  fetchErrors: boolean
  isLoading: boolean
  measures: string[]
  toggleCollapse: (id: string) => void
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  workshift: Workshift
  model: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  model: state.plantAnalysis.model,
})
/*
const cleanState = {
  isLoading: false,
  fetchErrors: false,
  showTooltip: false,
  tooltipData: null,
  isCollapsed: false,
  data: [],
  dataCurrent: [],
  last: null,
  lastAfter: null,
  filteredData: [],
  startDate: 'auto',
  endDate: 'auto',
  components: [],
  metrics: ['marciaFermo', 'driveBypass', 'manAutoLocSec'],
}*/

type Props = StateProps & OwnProps & Partial<DateRangeProps> & WithTranslation

class FanGraphRowView extends React.Component<Props, OwnState> {
  public render() {
    const { model, days } = this.props
    const noData = this.props.data !== undefined && this.props.data.length === 0 && !this.props.isLoading
    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const componentsToRender = nodes.filter(
      (c: ModelNodeData) =>
        ['PLANT_DATA_ASP', 'PLANT_DATA_ASP_INVERTER'].includes(c.nodeType) &&
        this.props.configuration &&
        this.props.configuration.nodes &&
        Object.keys(this.props.configuration.nodes).includes(c.code)
    )

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.props.isLoading && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.props.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto fan-grah-row-fetch-errors">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 page-break-inside-avoid">
            <div className="row row-legends pb-1">
              <div className="col-md-1" />
              <div className="col-md-3">
                <BottomLegend row={true} labels={['marciaFermo']} hideTitle={true} />
              </div>
              <div className="col-md-3">
                <BottomLegend row={true} labels={['driveBypass']} hideTitle={true} />
              </div>
              <div className="col-md-5">
                <BottomLegend row={true} labels={['manAutoLocSec']} hideTitle={true} />
              </div>
            </div>
          </div>

          {componentsToRender.map(
            (c: ModelNodeData) =>
              (this.props.configuration.nodes === {} ||
                (this.props.configuration.nodes[c.code] && this.props.configuration.nodes[c.code] === true)) && (
                <FanGraphRowItem
                  data={getInfluxMultiSeries(this.props.data, c.code)}
                  days={days || 1}
                  name={c.label}
                  id={c.code}
                  key={c.code}
                  node={c}
                  currentOverrun={getComponentCurrent(c.code, this.props.dataCurrent)}
                  currentLimit={this.props.configuration.settingsFields.sforamento_assorbimento_vent}
                />
              )
          )}
        </div>
      </React.Fragment>
    )
  }
}

const getComponentCurrent = (id: string, dataCurrent: any) => {
  let out
  if (dataCurrent && dataCurrent.length) {
    const current = dataCurrent.find((e: { name: string }) => e.name.indexOf(id) > -1)

    if (current && current.values && current.values[0]) {
      out = current.values[0][1]
    }
  }

  return out
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(FanGraphRowView)))
