import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface OwnProps {
  hideTitle?: boolean
  hideZero?: boolean
  labelColors: { [k: string]: string }
  name: string
  refLabel?: string | number | null
  row?: boolean
}

type Props = OwnProps & WithTranslation

class NamedBottomLegendView extends React.Component<Props> {
  public render() {
    const { hideTitle, labelColors, name, refLabel, row, t } = this.props

    return (
      <div className={'row row-legend row-legend-bottom ' + (row ? 'row-legend-row' : '')}>
        <div className='col-12'>
          {
            labelColors &&
            <>
              {!hideTitle && <h3>{t('plantAnalysis.legendTitles.' + name)}</h3>}
              <ul>
                {Object.keys(labelColors).map(( label: string, index: number ) => (
                  (
                    !this.props.hideZero ||
                    (this.props.hideZero && name !== "0")
                  ) && <li key={index}>
                      <span
                        className='label-color label-color-square'
                        style={{ backgroundColor: labelColors[label] }}
                      />
                    <span>
                        {label + (refLabel || '')}
                      </span>
                  </li>
                ))}
              </ul>
            </>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(NamedBottomLegendView)
