// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import { Icon } from '../../../services/icon'
import IconComponent from '../MVIcon/Icon'

export interface LinkDefinition {
  label?: string
  icon?: Icon
  iconPosition?: 'left' | 'right'
  onClick: () => void
  disabled?: boolean
  isLoading?: boolean
  theme?: 'normal' | 'light' | 'dark'
}

class Link extends React.Component<LinkDefinition> {
  constructor(props: LinkDefinition) {
    super(props)
    this.getIcon = this.getIcon.bind(this)
  }

  private getIcon() {
    return <IconComponent icon={this.props.icon!} className={`${(this.props.label && 'mr-1') || ''}`} />
  }

  public render() {
    const { label, icon, iconPosition, onClick, disabled, isLoading } = this.props
    return (
      <button type="button" disabled={disabled} onClick={onClick} className={`link ${this.props.theme || 'normal'}`}>
        {isLoading ? (
          <IconComponent icon={'circle-notch'} spin={true} />
        ) : (
          <>
            {icon && iconPosition !== 'right' && this.getIcon()}
            {label && <span>{label}</span>}
            {icon && iconPosition === 'right' && this.getIcon()}
          </>
        )}
      </button>
    )
  }
}

export default Link
