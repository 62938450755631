// * -------------------------------- NPM --------------------------------------
import { AnyAction, Dispatch } from 'redux'

// * -------------------------------- MODULE --------------------------------------
import { User, VidimCoreAuth } from '../../types/index'
import { themingFetchTime } from '../../types/theme'
import { changeTheme, changeAssets } from './branding'
import { API } from '.'
import { manageRequestErrors } from '../../functions/fetchWrapper/FetchError'

export const hasErrored = (error: Error): AnyAction => {
  return {
    type: 'AUTH_HAS_ERRORED',
    payload: error,
  }
}

export const isFetching = (): AnyAction => {
  return {
    type: 'AUTH_IS_FETCHING',
  }
}

export const success = (user: User): AnyAction => {
  return {
    type: 'AUTH_SUCCESS',
    payload: user,
  }
}

export const logoutUser = (): AnyAction => {
  localStorage.removeItem('jwt')
  localStorage.removeItem('counters')
  localStorage.removeItem('persist:inplant')
  return {
    type: 'AUTH_LOGOUT',
  }
}

export const loginUser = (username: string, password: string) => (dispatch: Function) => {
  dispatch(isFetching())
  API()
    .request('/core/auth/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
    .then(async (result: VidimCoreAuth) => {
      const resultUser = result.user
      const user = new User({
        uuid: resultUser.uuid,
        username: resultUser.userName,
        displayName: `${resultUser.firstName} ${resultUser.lastName}`,
        roles: resultUser.roles,
        aclRules: resultUser.aclRules
          .map(r => {
            if (r.includes('view.') || r.includes('nav.')) {
              return r
                .replace(new RegExp('_', 'g'), '/')
                .replace('view.', 'view./')
                .replace('nav.', 'nav./')
            }
            return r
          })
          .reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
        uiTheme: resultUser.uiTheme,
      })
      localStorage.setItem('jwt', result.token)

      // theme
      if (themingFetchTime && themingFetchTime.onLogin && user.branding.uiTheme) {
        await changeTheme(user.branding.uiTheme)(dispatch)
        await dispatch(changeAssets(user.branding.uiTheme))
      }

      // login
      // must be after changeTheme and changeAssets or a redirect can terminate the branding redux flow.
      dispatch(success(user))

      return
    })
    .catch((error: any) => {
      dispatch(hasErrored(error))
      return
    })
}

export const setMobileFlag = (): AnyAction => {
  return {
    type: 'AUTH_SET_MOBILE_FLAG',
  }
}

export const tokenAlive = () => (dispatch: Dispatch): void => {
  API()
    .request('/token-alive', undefined, false, true)
    .catch((e: any) => {
      manageRequestErrors(e, dispatch)
    })
}

export const loginUserByJwt = (jwt: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      localStorage.setItem('jwt', jwt)
      const userParams = await API().request('/user')
      const user = new User(userParams)
      dispatch(success(user))
      dispatch(setMobileFlag())
    } catch (error) {
      throw error
    }
  }
}
