// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { useComponentsTranslation } from '../../../services/translation'

// * -------------------------------- MODULE --------------------------------------
import Button from '../Buttons/Button'
import Text from '../Text/Text'
import { ButtonVariants } from '../Buttons/types'

/**
 * @param filtersSection: content above unselected list, render component that are used to filter elements
 * @param infoSection: show how many element are visible in the unselected list
 */
interface Props {
  title: string
  filtersSection?: React.ReactNode
  infoSection?: React.ReactNode
  childrenToSelect: React.ReactNodeArray
  childrenSelected: React.ReactNodeArray
  infoSelected?: Array<{ label: string; semantic: string }>
  onAction: (action: 'selectAll' | 'removeAll') => void
}

const SelectionBuilder = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const n = 'components.selectionBuilder'
  const { t } = useComponentsTranslation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={'mv-selection-builder'}>
      <div className={'mv-sb__top'}>
        <div className={'mv-sb__selection'}>
          <Text text={`|**${props.title}**|`} />
          {props.filtersSection}
        </div>
        <div className={'mv-sb__selected'}>
          <Text text={`|**${t(`${n}.selected`)}**|`} />
          {props.infoSelected?.map(el => (
            <div className={'summary-element'} key={el.semantic}>
              <p>
                <span className={`summary-element__${el.semantic}-square`} />
                {el.label}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mv-sb__bottom">
        <div className="selection__elements">
          <div className="elements__info">
            {props.infoSection}
            <Button
              isDisable={props.childrenToSelect.length <= 0}
              variant={ButtonVariants.flat}
              semantic={'primary'}
              label={t(`${n}.selectAll`)}
              onClick={() => props.onAction('selectAll')}
            />
          </div>
          <div className="elements__list">{props.childrenToSelect}</div>
        </div>
        <div className="selected__elements">
          <div className="elements__unselectall">
            {(props.childrenSelected.length > 0 && (
              <Button
                variant={ButtonVariants.flat}
                semantic={'primary'}
                label={t(`${n}.removeAll`)}
                onClick={() => props.onAction('removeAll')}
              />
            )) ||
              null}
          </div>
          <div className="elements__list">{props.childrenSelected}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SelectionBuilder)
